import React, { Fragment, useEffect } from 'react';
import "../../assets/scss/etm_login.scss";
import LoadScreen from "../../components/Utils/LoadScreen";
import { API } from '../../helpers/axios';

const VerifyAccount = ( match ) => {
    
    useEffect(() => {
        verifyByToken();
    }, []);

    const verifyByToken = async () => {
        if(match.location.pathname.length > 0){
            const data  = match.location.pathname.split('/');
            const token  = data[2];
            if(token !== null){
                const res = await API.put('/register/verifyAccount', { token });
                if (res.data.state) {
                    window.location.href = res.data.url;
                } else {
                    window.close();
                }
            }
        }
    }

    return (
        <Fragment>
            <div className="mt-5 pt-5">
                <LoadScreen text="Verificando cuenta..."/>
            </div>
        </Fragment>
  );
}

export default (VerifyAccount);
