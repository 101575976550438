import React, { useEffect, useState } from 'react';

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { validateRol, capitalize, getLocalStorage } from '../../helpers/etmmeetUtils';

//i18n
import { withNamespaces } from 'react-i18next';
import { Fragment } from 'react';
import { API } from '../../helpers/axios';

const SidebarContent = (props) => {

    const ls = getLocalStorage();
    const [dashboardMenu, setDashboardMenu] = useState([]);
    const [makeChallenges, setMakeChallenges] = useState(false);

    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);

    useEffect(() => {
        getDashboardByUser();
        canMakeChallenges();
    }, []);

    const getDashboardByUser = async () => {
        const res = await API.get('/getDashboardByUser', { params: { user_id: ls.id } });
        if (res.data.state) {
            let list = [];
            res.data.data.map((e, i) => {
                list.push(
                    <li key={i}>
                        <Link to={`/dashboard/${e.HASH}`} className="waves-effect">
                            <span><i className="fas fa-chart-bar align-middle mr-2"></i>{props.t(`${capitalize(e.COMPANY)}`)}</span>
                        </Link>
                    </li>
                );
            })
            setDashboardMenu(list);
        }
    }

    const canMakeChallenges = async () => {
        const res = await API.get('/profile/getCanMakeChallenges', { params: { token: ls.token } });
        if (res.data.state) {
            setMakeChallenges(true);
        } else {
            setMakeChallenges(false);
        }
    }

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (

        <React.Fragment>
            <div id="sidebar-menu" className="step-tour-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menú')}  </li>
                    <li>
                        <Link to="/home" className="waves-effect">
                            <span><i className="fas fa-home align-middle mr-2"></i>{props.t('Inicio')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className="waves-effect">
                            <span><i className="fas fa-user align-middle mr-2"></i>{props.t('Mi Perfil')}</span>
                        </Link>
                    </li>
                    {
                        (validateRol(['INVERSIONISTA', 'MENTOR', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR'])) ?
                            <li>
                                <Link to="/giveHours" className="waves-effect">
                                    <span><i className="fas fa-handshake align-middle mr-2"></i>{props.t('Donar Tiempo')}</span>
                                </Link>
                            </li> : ""
                    }
                    {
                        (validateRol(['INVERSIONISTA', 'MENTOR', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR'])) ?
                            <li>
                                <Link to="/givenTime" className="waves-effect">
                                    <span><i className="fas fa-clipboard-list align-middle mr-2"></i>{props.t('Tiempo Donado')}</span>
                                </Link>
                            </li> : ""
                    }
                    <li>
                        <Link to="/requests" className="waves-effect step-tour-inbox">
                            <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Mis Solicitudes')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/confirmed" className="waves-effect">
                            <span><i className="fas fa-calendar-alt align-middle mr-2"></i>{props.t('Reuniones Confirmadas')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/requests_history" className="waves-effect">
                            <span><i className="fas fa-book align-middle mr-2"></i>{props.t('Historial de Reuniones')}</span>
                        </Link>
                    </li>
                    {
                        (makeChallenges) ?
                            <li>
                                <Link to="/challenges" className="waves-effect">
                                    <span><i className="fas fa-university align-middle mr-2"></i>{props.t('Nuestros Desafíos')}</span>
                                </Link>
                            </li>
                            : ""
                    }
                    <li>
                        <Link to="/challenges_searchfor" className="waves-effect">
                            <span><i className="fas fa-chess align-middle mr-2"></i>{props.t('Desafíos')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/meet" className="waves-effect step-tour-meet">
                            <span><i className="fas fa-laptop align-middle mr-2"></i>{props.t('Sala de Espera')}</span>
                        </Link>
                    </li>

                    {
                        (dashboardMenu.length > 0) ?
                            <Fragment>
                                <hr />
                                <li className="menu-title">{props.t('Dashboard')}</li>
                                {dashboardMenu}
                            </Fragment>
                            : ""
                    }

                    {
                        (validateRol(['ADMINISTRADOR'])) ?
                            <Fragment>
                                <hr />
                                <li className="menu-title">{props.t('Administrador')}  </li>
                                <li>
                                    <Link to="/admin_pending" className="waves-effect">
                                        <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Gestionar Horas Solicitadas')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin_confirm" className="waves-effect">
                                        <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Gestionar Horas Confirmadas')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin_panel" className="waves-effect">
                                        <span><i className="fas fa-chart-bar align-middle mr-2"></i>{props.t('Panel Administrador')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin_export" className="waves-effect">
                                        <span><i className="fas fa-cloud-download-alt align-middle mr-2"></i>{props.t('Exportar Bases')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin_user" className="waves-effect">
                                        <span><i className="fas fa-users align-middle mr-2"></i>{props.t('Panel Usuarios')}</span>
                                    </Link>
                                </li>
                            </Fragment>
                            : ""
                    }
                    <hr />
                    {/* <li>
                            <Link to="/change_profile" className="waves-effect">
                                <span><i className="fas fa-id-badge align-middle mr-2"></i>{props.t('Cambiar Perfil')}</span>
                            </Link>
                        </li>   
                        <li>
                            <Link to="/password" className="waves-effect">
                                <span><i className="fas fa-key align-middle mr-2"></i>{props.t('Cambiar Contraseña')}</span>
                            </Link>
                        </li> */}
                    <li>
                        <Link to="/logout" className="waves-effect red_menu">
                            <span><i className="fas fa-power-off align-middle mr-2"></i>{props.t('Cerrar Sesión')}</span>
                        </Link>
                    </li>
                    {/* <li>
                            <Link to="/#" className="waves-effect">
                                <i className="bx bx-home-circle"></i><span className="badge badge-pill badge-info float-right">03</span>
                                <span>{props.t('Dashboards') }</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/dashboard">{props.t('Default') }</Link></li>
                            </ul>
                        </li> */}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withNamespaces()(SidebarContent));


