import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Input } from "reactstrap";
import "../../assets/scss/etm.scss";
import { API } from '../../helpers/axios';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage, swal_etm } from "../../helpers/etmmeetUtils";
import logo from "../../assets/images/etmmeet/general/logo_first_tusday_negro.png";

const Accreditor_identifier = (props) => {
    const ls = getLocalStorage('accreditor');

    const [identifier, setIdentifier] = useState('');

    const identifyAccreditingUser = async () => {
        if (identifier === '') {
            swal_etm('No escribiste ningún código de acreditador')
        } else {
            props.setLoading(true)
            const res = await API.get('/controlAccess/identifyAccreditingUser', { params: { identifier } });
            if (res.data.state) {
                startAccreditation(res.data.usuario)
            } else {
                swal_etm(res.data.msg)
            }
            props.setLoading(false)
        }
    }

    const startAccreditation = (data) => {
        localStorage.removeItem('accreditor');
        localStorage.setItem('accreditor', JSON.stringify(data));
        props.setAccreditingUser(data)
    }

    const keyPressIdentifyAccreditingUser = async (e) => {
        if(e.key === 'Enter'){
            identifyAccreditingUser();
        }
    }

    return (
        <Fragment>
            <Card className="mt-5">
                <div className="text-center m-3">
                    <img src={logo} alt="" className="img-fluid" width="70%" />
                </div>
                <CardTitle className="mt-3 ml-3  mr-3 mb-0 text-center">
                    Ingresa tu código de acreditador
                </CardTitle>
                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Input
                                    type="text"
                                    className="form-control etm_register_input"
                                    name="identifier"
                                    id="identifier"
                                    placeholder="Ingresa tu código de acreditador acá"
                                    required
                                    value={identifier}
                                    onChange={(e) => (setIdentifier(e.target.value))}
                                    onKeyPress={ (e) => keyPressIdentifyAccreditingUser(e) }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Button
                                block
                                color="primary"
                                onClick={() => identifyAccreditingUser()}
                            >
                                Ingresar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default Accreditor_identifier;