import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { API } from '../../helpers/axios';

const AvatarDefault = props => {

    const [activeClass, setActiveClass] = useState((props.activeClass === undefined) ? 'profile_register_active' : props.activeClass);
    const [borderRadius, setBorderRadius] = useState((props.borderRadius === undefined) ? 'rounded-circle' : props.borderRadius);
    const [sizeLg, setSizeLg] = useState((props.sizeLg === undefined) ? 3 : props.sizeLg);
    const [sizeMd, setSizeMd] = useState((props.sizeMd === undefined) ? 6 : props.sizeMd);
    const [sizeSm, setSizeSm] = useState((props.sizeSm === undefined) ? 12 : props.sizeSm);
    const [textAligne, setTextAligne] = useState((props.textAligne === undefined) ? 'text-center' : props.textAligne);
    const [sizeImg, setSizeImg] = useState((props.sizeImg === undefined) ? 'w-50' : props.sizeImg);

    const [listAvatarDefault, setListAvatarDefault] = useState([]);

    useEffect(() => {
        getAvatarDefault();
    }, []);

    const getAvatarDefault = async (e) => {
        const res = await API.get('/general/getAvatarDefault', {});

        if (res.data.avatarDefault.length > 0) {
            let list = [];
            res.data.avatarDefault.map((e, i) => {
                list.push(
                    <Col lg={sizeLg} md={sizeMd} sm={sizeSm} className="mb-2" id={`profile_${e.ID}`} key={i}>
                        <div className={`profile_register nav-link ${textAligne}`} id={e.ID} onClick={() => changeProfilepicture(e.ID, e.NAME, e.URL)}>
                            <img src={e.URL} alt="" className={`img-fluid ${sizeImg} ${borderRadius}`} /> <br />
                        </div>
                    </Col>
                );
            });
            setListAvatarDefault(list);
        }
    }

    const changeProfilepicture = (e, name, url) => {
        props.setAvar([url]);
        const elements = document.getElementsByClassName("nav-link");
        if (elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove(activeClass);
            }
        }
        document.getElementById(e).classList.add(activeClass);
    }

    return (
        <Row>
            {listAvatarDefault}
        </Row>
    );
};

export default AvatarDefault;