import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import team_etm from "../../assets/images/etmmeet/landing/team/team_etm.png";
import photo_daniel_daccarett from "../../assets/images/etmmeet/landing/team/dd.png";
import photo_nicolas_bove from "../../assets/images/etmmeet/landing/team/nb.png";
import photo_nicolas_uranga from "../../assets/images/etmmeet/landing/team/nu.png";
import photo_luis_espinoza from "../../assets/images/etmmeet/landing/team/le.png";
import photo_soledad_molina from "../../assets/images/etmmeet/landing/team/sm.png";
import photo_cristopher_coopman from "../../assets/images/etmmeet/landing/team/cc.png";
import etmday from "../../assets/images/etmmeet/landing/logo_etmday_negro.png";

const AboutUs = (props) => {

    const team = [{
        photo: photo_daniel_daccarett
        , name: "Daniel Daccarett"
        , title: "Co Fundador EtM"
        , linkedin: "https://www.linkedin.com/in/daniel-daccarett-imbarack-0b63b71b8/"
    }, {
        photo: photo_nicolas_bove
        , name: "Nicolás Bové"
        , title: "Director ejecutivo EtM"
        , linkedin: "https://www.linkedin.com/in/nicol%C3%A1s-bov%C3%A9-titelman-701a42182/"
    }, {
        photo: photo_nicolas_uranga
        , name: "Nicolás Uranga"
        , title: "Comunicaciones y Marketing"
        , linkedin: "https://www.linkedin.com/in/nicol%C3%A1s-uranga-ruiz-de-gamboa-a2433b158/"
    }, {
        photo: photo_soledad_molina
        , name: "Soledad Molina"
        , title: "Diseñadora Gráfica"
        , linkedin: "https://www.linkedin.com/in/soledad-molina-p%C3%A9rez-a38991219/"
    }, {
        photo: photo_luis_espinoza
        , name: "Luis Espinoza"
        , title: "DevOps"
        , linkedin: "https://www.linkedin.com/in/luis-espinoza-92711157/"
    }, {
        photo: photo_cristopher_coopman
        , name: "Cristopher Coopman"
        , title: "CTO & Project Manager"
        , linkedin: "https://www.linkedin.com/in/cristopher-coopman-reyes-259446102/"
    }];

    return (
        <Fragment>
            <Row className="aboutus pt-4 pb-5" id="aboutus">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center pt-3 mb-md-4 mb-3">
                            <p className="h1 aboutus_title text-white">¿Quiénes somos?</p>
                        </Col>
                        <Col md={5} sm={12} className="my-auto">
                            <div className="text-center">
                                <img className="aboutus_img img-fluid" src={team_etm} alt="pic1" />
                            </div>
                        </Col>
                        <Col md={7} sm={12}>
                            <p className="aboutus_body text-white mt-md-0 mt-4">
                                Somos una corporación sin fines de lucro que tiene como objetivo conectar al ecosistema de emprendimiento e innovación con grandes empresas, mentores/as
                                e inversionistas para trabajar colaborativamente para <b className="aboutus_strong">generar oportunidades, progreso y desarrollo social y económico </b>
                                para Chile y la región.<br /><br />
                                <b className="aboutus_strong">Emprende tu Mente</b> nace como la evolución del evento <b className="aboutus_strong">"Asado Emprendedores"</b> desde el año
                                2007. En este evento reunimos en un ambiente distendido a emprendedores, grandes empresas, mentores, inversionistas y diferentes tomadores de decisión
                                de todo el país para hacer <b className="aboutus_strong">networking.</b><br /><br />
                                ¿Quieres conocer más sobre este evento?
                            </p>
                            <div className="ml-md-3 mr-md-3 ml-5 mr-5">
                                <a href={"https://www.etmday.org/"} target="_blank">
                                    <button className="btn btn_etm_day etm_btn ">
                                        <img className="img-fluid" src={etmday} alt="pic1" width={"30%"} />
                                    </button>
                                </a>
                            </div>
                        </Col>
                        <Col xs={12} className="text-center mt-3 mb-3">
                            <p className="h1 aboutus_title text-white">Equipo</p>
                        </Col>

                        {/* PC VIEW */}
                        {
                            team.map((e, i) => {
                                return (
                                    <Col className="text-center aboutus_team" key={i}>
                                        <img className="aboutus_img_team img-fluid" src={e.photo} alt="pic1" />
                                        <p className="h5 text-white mt-2 mb-2"><b>{e.name}</b></p>
                                        <p className="h5 text-white mt-0 aboutus_jobtitle">{e.title}</p>
                                        <div className="aboutus_linkedin">
                                            <a href={e.linkedin} target="_blank">
                                                <i className="fab fa-linkedin fa-2x text-white"></i>
                                            </a>
                                        </div>
                                    </Col>
                                )
                            })
                        }

                        {/* MOBILE VIEW 1 */}
                        {
                            team.map((e, i) => {
                                return (
                                    <Col xs={12} className="aboutus_team_mobile" key={i}>
                                        <Row>
                                            {
                                                (i % 2 == 0) ?
                                                    <Fragment>
                                                        <Col xs={6} className="text-right mb-4">
                                                            <img className="aboutus_img_team img-fluid" src={e.photo} alt="pic1" />
                                                        </Col>
                                                        <Col xs={6} className="text-left mb-4 my-auto">
                                                            <p className="h5 text-white mb-0"><b>{e.name}</b></p>
                                                            <p className="h5 text-white mt-0 aboutus_jobtitle">{e.title}</p>
                                                            <div className="aboutus_linkedin">
                                                                <a href={e.linkedin} target="_blank">
                                                                    <i className="fab fa-linkedin fa-2x text-white"></i>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <Col xs={6} className="text-right mb-4 my-auto">
                                                            <p className="h5 text-white mb-0"><b>{e.name}</b></p>
                                                            <p className="h5 text-white mt-0 aboutus_jobtitle">{e.title}</p>
                                                            <div className="aboutus_linkedin">
                                                                <a href={e.linkedin} target="_blank">
                                                                    <i className="fab fa-linkedin fa-2x text-white"></i>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} className="text-left mb-4">
                                                            <img className="aboutus_img_team img-fluid" src={e.photo} alt="pic1" />
                                                        </Col>
                                                    </Fragment>
                                            }
                                        </Row>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </Row>
        </Fragment>
    );
}

export default (AboutUs);


