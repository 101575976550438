import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, InputGroup, CustomInput } from "reactstrap";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { swal_etm, cleanFacebook, cleanInstagram, cleanTwitter, pathS3, selectCustomStyle } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import UploadImageFile from "../Utils/UploadImageFile";
import AvatarDefault from "../Utils/AvatarDefault";
import ModalNewCompany from "./modalNewCompany";
import ModalNewJobtitle from "./modalNewJobtitle";
import ModalNewManagement from "./modalNewManagement";
import { useHistory } from "react-router-dom";
import { setSession } from '../../helpers/authUtils';

const Step4Expertos = (props) => {
    const history = useHistory();

    const asterisco = "";
    const chileData = { value: 81, label: "Chile" };

    const date = new Date();
    const yyyy = date.getFullYear();

    /*** BILLING YEAR ***/
    const billing_year = {
        year1: yyyy - 2
        , year2: yyyy - 1
    }

    const [loading, setLoading] = useState(false);
    const [showCompany, setShowCompany] = useState({ list: false, new: false });
    const [showJobTitle, setShowJobTitle] = useState(false);
    const [showManagement, setShowManagement] = useState(false);
    const [showRegions, setShowRegions] = useState(true);
    const [avatarType, setAvatarType] = useState(true);

    const [personalCompany, setPersonalCompany] = useState(null);
    const [personalJobTitle, setPersonalJobTitle] = useState(null);
    const [personalManagement, setPersonalManagement] = useState(null);
    const [personalTag, setPersonalTag] = useState(null);
    const [personalNickname, setPersonalNickname] = useState({ show: false, name: "" });
    const [personalDescription, setPersonalDescription] = useState("");
    const [personalRrss, setPersonalRrss] = useState({ facebook: "", instagram: "", twitter: "", youtube: "" });
    const [personalProfilePicture, setPersonalProfilePicture] = useState([]);

    const [interestAgeRange, setInterestAgeRange] = useState([]);
    const [interestInvestment, setInterestInvestment] = useState([]);
    const [interestBilling, setInterestBilling] = useState([]);
    const [interestInvoices, setInterestInvoices] = useState([]);
    const [interestCountry, setInterestCountry] = useState([chileData]);
    const [interestRegion, setInterestRegion] = useState(null);
    const [interestImpacts, setInterestImpacts] = useState(null);
    const [interestOds, setInterestOds] = useState(null);
    const [interestStages, setInterestStages] = useState([]);
    const [interestWomenLeader, setInterestWomenLeader] = useState(0);
    const [interestGenderEquality, setInterestGenderEquality] = useState(0);
    const [interestDiversityInclusion, setInterestDiversityInclusion] = useState(0);
    const [interestIndustries, setInterestIndustries] = useState(null);
    const [interestCommercialType, setInterestCommercialType] = useState(null);

    const [listAgeRange, setListAgeRange] = useState([]);
    const [listInvestment, setListInvestment] = useState([]);
    const [listBilling, setListBilling] = useState([]);
    const [listInvoices, setListInvoices] = useState([]);
    const [listCountries, setListCountries] = useState([]);
    const [listRegions, setListRegions] = useState([]);
    const [listImpacts, setListImpacts] = useState([]);
    const [listOds, setListOds] = useState([]);
    const [listTags, setListTags] = useState([]);
    const [listStages, setListStages] = useState([]);
    const [listIndustries, setListIndustries] = useState([]);
    const [listManagements, setListManagements] = useState([]);
    const [listJobTitles, setListJobTitles] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);
    const [listCommercialType, setListCommercialType] = useState([]);

    useEffect(() => {
        getCountries();
        getRegions();
        getIndustries();
        getCompanies();
        getJobTitles();
        getManagements();
        getImpacts();
        getOds();
        getTags();
        getAgeRange();
        getInvestment();
        getBillings();
        getInvoices();
        getProjectStage();
        getCommercialType();
    }, []);

    useEffect(() => {
        if (personalCompany !== null && personalCompany.value === 0) {
            setPersonalJobTitle(null);
            setPersonalManagement(null);
        }
    }, [personalCompany]);

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        let list = [];
        if (res.data.state) {
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS });
            });
        }
        setListCountries(list);
    }

    const getRegions = async (e) => {
        const res = await API.get('/general/getRegions');
        let list = [];
        if (res.data.state) {
            res.data.regions.map((e, i) => {
                list.push({ value: e.ID, label: e.REGION });
            });
            setListRegions(list);
        }
    }

    const getIndustries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
    }

    const getCompanies = async () => {
        const res = await API.get('/general/getCompanies');
        if (res.data.state) {
            let list = [];
            res.data.companies.map((e, i) => {
                list.push({ value: e.ID, img: e.FOTO, label: e.EMPRESA });
            });
            setListCompanies(list);
        }
    }

    const getJobTitles = async () => {
        const res = await API.get('/general/getJobTitles');
        if (res.data.state) {
            let list = [];
            res.data.jobTitles.map((e, i) => {
                list.push({ value: e.ID, label: e.CARGO });
            });
            setListJobTitles(list);
        }
    }

    const getManagements = async () => {
        const res = await API.get('/general/getManagements');
        if (res.data.state) {
            let list = [];
            res.data.managements.map((e, i) => {
                list.push({ value: e.ID, label: e.GERENCIA });
            });
            setListManagements(list);
        }
    }

    const getImpacts = async () => {
        const res = await API.get('/general/getImpacts');
        if (res.data.impacts.length > 0) {
            let list = [];
            res.data.impacts.map((e, i) => {
                list.push({ value: e.ID, label: e.IMPACTO });
            });
            setListImpacts(list);
        }
    }

    const getOds = async () => {
        const res = await API.get('/general/getOds');
        if (res.data.ods.length > 0) {
            let list = [];
            res.data.ods.map((e, i) => {
                list.push({ value: e.ID, label: e.ODS });
            });
            setListOds(list);
        }
    }

    const getTags = async () => {
        const res = await API.get('/general/getTags');
        if (res.data.tags.length > 0) {
            let list = [];
            res.data.tags.map((e, i) => {
                list.push({ value: e.ID, label: e.TAG });
            });
            setListTags(list);
        }
    }

    const getAgeRange = async () => {
        const res = await API.get('/general/getAgeRange');
        if (res.data.age.length > 0) {
            let list = [];
            res.data.age.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini age_rage" id={`age_rage_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")} />
                        <label htmlFor={`age_rage_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")}>{e.EDAD}</label>
                    </div>
                );
            });

            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini age_rage" id={`age_rage_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")} />
                    <label htmlFor={`age_rage_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListAgeRange(list);
        }
    }

    const getInvestment = async () => {
        const res = await API.get('/general/getInvestment');
        if (res.data.investment.length > 0) {
            let list = [];
            res.data.investment.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini investment" id={`investment_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "investment")} />
                        <label htmlFor={`investment_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "investment")}>{e.INVERSION}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini investment" id={`investment_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "investment")} />
                    <label htmlFor={`investment_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "investment")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListInvestment(list);
        }
    }

    const getBillings = async (e) => {
        const res = await API.get('/general/getBillings');

        if (res.data.billings.length > 0) {
            let list = [];
            res.data.billings.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini billing" id={`billing_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "billing")} />
                        <label htmlFor={`billing_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "billing")}>{e.FACTURACION}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini billing" id={`billing_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "billing")} />
                    <label htmlFor={`billing_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "billing")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListBilling(list);
        }
    }

    const getInvoices = async (e) => {
        const res = await API.get('/general/getInvoices');

        if (res.data.invoices.length > 0) {
            let list = [];
            res.data.invoices.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini invoices" id={`invoices_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")} />
                        <label htmlFor={`invoices_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")}>{e.FACTURAS}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini invoices" id={`invoices_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")} />
                    <label htmlFor={`invoices_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListInvoices(list);
        }
    }

    const getProjectStage = async () => {
        const res = await API.get('/general/getProjectStage');
        if (res.data.stage.length > 0) {
            let list = [];
            res.data.stage.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini stage" id={`stage_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "stage")} />
                        <label htmlFor={`stage_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "stage")}><b>{e.ETAPA}</b></label>
                        <Label className="m-2">
                            <i className="fas fa-arrow-right etm_text_blue mr-2"></i><span dangerouslySetInnerHTML={{ __html: e.TITULO_MENTOR }} ></span>
                        </Label>
                    </div>
                );
            });
            setListStages(list);
        }
    }

    const getCommercialType = async (e) => {
        const res = await API.get('/general/getCommercialType', {});

        if (res.data.types.length > 0) {
            let list = [];
            res.data.types.map((e, i) => {
                list.push({ value: e.ID, label: `${e.TIPO} (${e.DESCRIPCION})` });
            });
            setListCommercialType(list);
        }
    }

    const stepNumber = (e, title) => {
        return <Label className="h4"><span className="etm_onboarding_bg_step_number mr-2">{e}</span>{title}</Label>
    }

    const changeInterestCheckbox = (e, clase) => {
        if (e !== undefined) {
            let list = [];
            let handle = null;

            switch (clase) {
                case "age_rage":
                    list = interestAgeRange;
                    handle = setInterestAgeRange;
                    break;
                case "investment":
                    list = interestInvestment;
                    handle = setInterestInvestment;
                    break;
                case "billing":
                    list = interestBilling;
                    handle = setInterestBilling;
                    break;
                case "invoices":
                    list = interestInvoices;
                    handle = setInterestInvoices;
                    break;
                case "stage":
                    list = interestStages;
                    handle = setInterestStages;
                    break;
            }

            if (e === "TODOS") {
                const all = document.getElementById(`${clase}_all`);
                const elements = document.getElementsByClassName(clase);

                if (all.getAttribute("checked") === null) {
                    if (elements !== undefined && elements.length > 0) {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].setAttribute("checked", "checked");
                        }
                    }
                    handle(e);
                } else {
                    if (elements !== undefined && elements.length > 0) {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].removeAttribute('checked');
                        }
                    }
                    handle([]);
                }
            } else {
                if (list.includes(e)) {
                    list.splice(list.indexOf(e), 1);
                } else {
                    list.push(e);
                }
                handle(list);
            }
        }
    }

    const preSaveData = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);

        //  personalCompany.value = 0, url "", INDEPENDIENTE
        if (personalCompany === null) {
            swal_etm(`Indícanos si trabajas en alguna <b class="etm_text_blue">empresa</b> u <b class="etm_text_blue">organización</b>`);
            setLoading(false);
            return;
        } else {
            if (personalCompany.value > 0) {
                //  personalCompany.value = 0 no aplica
                if (personalJobTitle === null || personalJobTitle.value < 1) {
                    swal_etm(`Indícanos cuál es tu <b class="etm_text_blue">cargo</b> en <b class="etm_text_blue">${personalCompany.label}</b>`);
                    setLoading(false);
                    return;
                }

                //  personalCompany.value = 0 no aplica
                if (personalManagement === null || personalManagement.value < 1) {
                    swal_etm(`Indícanos a qué <b class="etm_text_blue">gerencia</b> perteneces en <b class="etm_text_blue">${personalCompany.label}</b>`);
                    setLoading(false);
                    return;
                }
            }
        }

        if (props.rol.name === "MENTOR") {
            if (interestStages.length < 1) {
                swal_etm(`Debes elegir al menos una <b class="etm_text_blue">etapa de emprendimientos</b> de tu interés`);
                setLoading(false);
                return;
            }
        } else {
            if (interestAgeRange !== "TODOS" && interestAgeRange.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">antigüedad de los emprendimientos</b> de tu interés`);
                setLoading(false);
                return;
            }

            if (interestInvestment !== "TODOS" && interestInvestment.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">inversión de los emprendimientos</b> de tu interés`);
                setLoading(false);
                return;
            }

            if (interestBilling !== "TODOS" && interestBilling.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">facturación de los emprendimientos</b> de tu interés`);
                setLoading(false);
                return;
            }

            // if(interestInvoices !== "TODOS" && interestInvoices.length < 1){
            //     swal_etm(`Debes elegir al menos 1 opción de cantidad de <b class="etm_text_blue">facturas de los emprendimientos</b> de tu interés`);
            //     setLoading(false);
            //     return;
            // }
        }

        if (interestIndustries === null || interestIndustries.length < 1) {
            swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">industria</b> de tu interés`);
            setLoading(false);
            return;
        }
        
        if (interestCommercialType === null || interestCommercialType.length < 1) {
            swal_etm(`Seleccion al menos 1 <b class="etm_text_blue">tipo de comercio</b> de tu interés`);
            setLoading(false);
            return;
        }

        if (interestCountry === null || interestCountry.length < 1) {
            swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">país de los emprendimientos</b> de tu interés`);
            setLoading(false);
            return;
        }

        //  solo considerar regiones para chile
        if (interestCountry.value === chileData.value) {
            if (interestRegion === null || interestRegion.length < 1) {
                swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">región de los emprendimientos</b> de tu interés`);
                setLoading(false);
                return;
            }
        }

        if (personalTag === null || personalTag.length < 1) {
            swal_etm(`Elige al menos <b class="etm_text_blue">1 etiqueta</b> para mejorar el match con tu perfil`);
            setLoading(false);
            return;
        }

        if (personalNickname.show === true && personalNickname.name.length < 2) {
            swal_etm(`Indícanos un <b class="etm_text_blue">apodo</b> o <b class="etm_text_blue">nickname</b> válido`);
            setLoading(false);
            return;
        }

        if (personalDescription.length < 15) {
            swal_etm(`Preséntate en al menos 15 caracteres`);
            setLoading(false);
            return;
        }

        if (personalProfilePicture === null || personalProfilePicture.length < 1) {
            swal_etm(`Debes seleccionar un <b class="etm_text_blue">Avatar</b> o subir una <b class="etm_text_blue">Foto de perdil</b>.`);
            setLoading(false);
            return;
        }

        postSaveData();
    }

    const postSaveData = async () => {

        const res = await API.post('/register/saveProyectExpert', {
            token: props.getToken()
            , personalCompany: personalCompany.value
            , personalJobTitle: (personalJobTitle !== null) ? personalJobTitle.value : ""
            , personalManagement: (personalManagement !== null) ? personalManagement.value : ""
            , personalTag
            , personalNickname: (personalNickname.show) ? personalNickname.name : props.data.name
            , personalDescription
            , personalRrss
            , personalProfilePicture: personalProfilePicture[0]
            , billing_year
            , interestAgeRange
            , interestInvestment
            , interestBilling
            // , interestInvoices
            , interestCountry
            , interestRegion
            , interestImpacts
            , interestOds
            , interestStages
            , interestWomenLeader
            , interestGenderEquality
            , interestDiversityInclusion
            , interestCommercialType
            , device: "PC"
            , interestIndustries
        });

        if (res.data.state) {
            swal_etm("Perfecto! Ya estamos listo!", "success");
            setTimeout(
                function () {
                    props.saveStep(4);
                    setSession(res.headers, res.data.user);
                    history.push('/home');
                }
                    .bind(this),
                3000
            );
        } else {
            swal_etm("Ups!", res.data.msg);
        }
        setLoading(false);
    }

    return (
        <Fragment>
            <Col className="mb-2 text-left" >
                <Container>
                    <Card className="animate__animated animate__fadeIn">
                        <CardBody>
                            <Label className="h5">
                                {
                                    (props.rol.name === "MENTOR") ?
                                        <Fragment>
                                            <p>Los <b className="etm_text_blue">MENTORES</b> y <b className="etm_text_blue">MENTORAS</b> pueden donar tiempo para reunirse con emprendedores libremente las veces que quieran.</p>
                                            <p>Cada reunión dura <b className="etm_text_blue">20 minutos</b> y finalizará automáticamente.<br />Al término, podrás elegir <b className="etm_text_blue">"Apadrinar"</b> al emprendedor y hacer un seguimiento de él o ella y su proyecto.</p>
                                            <p>Elige las sesiones y días que consideres y notificaremos al emprendedor para que se junten nuevamente.<br />Estas sesiones pueden durar hasta 1 hora cada una.</p>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    (props.rol.name === "INVERSIONISTA") ?
                                        <Fragment>
                                            <p>Los <b className="etm_text_blue">INVERSIONISTAS</b> pueden donar tiempo para reunirse con emprendedores libremente las veces que quieran.</p>
                                            <p>También puedes tener reuniones con otros <b className="etm_text_blue">Expertos</b> para generar alianzas estratégicas o comerciales.</p>
                                            <p>Cada reunión dura <b className="etm_text_blue">20 minutos</b> y finalizará automáticamente, por lo tanto, es muy importante que des algún dato de contacto al emprendedor para mantener la relación si el proyecto es de tu interés.</p>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    (props.rol.name === "GERENTE/EJECUTIVO") ?
                                        <Fragment>
                                            <p>Los <b className="etm_text_blue">GERENTES</b> o <b className="etm_text_blue">EJECUTIVOS</b> pueden donar tiempo para reunirse con emprendedores libremente las veces que quieran.</p>
                                            <p>También puedes tener reuniones con otros <b className="etm_text_blue">Expertos</b> para generar alianzas estratégicas o comerciales.</p>
                                            <p>Cada reunión dura <b className="etm_text_blue">20 minutos</b> y finalizará automáticamente, por lo tanto, es muy importante que des algún dato de contacto al emprendedor para mantener la relación si el proyecto es de tu interés.</p>
                                        </Fragment>
                                        : ""
                                }
                            </Label>
                            <Label className="h5">
                                <p className="mb-0">Para acceder a estas reuniones y hacer un buen match necesitamos conocer tus intereses, experiencias y necesidades.</p>
                                <p>A continuación configuraremos tu perfil de experto paso a paso. Será sólo 1 minuto más.</p>
                            </Label>
                            <Label className="h5">
                                <p className="etm_text_blue mb-0"><i className="fas fa-info-circle mr-2"></i>Esta información solo será compartida con los usuarios que tú solicites y jamás será expuesta publicamente</p>
                            </Label>
                        </CardBody>
                    </Card>

                    {/* EXPERTOS Y MENTOR */}
                    <Card className="animate__animated animate__fadeIn mt-5">
                        <CardBody>
                            {stepNumber(2, "Acerca de tu oficio")}
                            <Row className="mt-3 mb-5">
                                <Col md={12} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5">¿ Trabajas en alguna empresa u organización actualmente ?{asterisco}</Label>
                                        <br />
                                        <Label className="h5 mt-3 ml-5">
                                            <Input name="radioCompany" type="radio" onClick={(e) => (setShowCompany({ list: false, new: false }), setPersonalCompany({ value: 0, img: "", label: "INDEPENDIENTE" }))} value="INDEPENDIENTE" />{' '}No, soy independiente
                                        </Label>
                                        <br />
                                        <Label className="h5 ml-5">
                                            <Input name="radioCompany" type="radio" onClick={(e) => (setShowCompany({ list: true, new: false }), setPersonalCompany(null))} value="EMPRESA" />{' '}Si
                                        </Label>
                                        <br />
                                    </FormGroup>
                                    <FormGroup check hidden={!showCompany.list}>
                                        <br />
                                        <Label className="h5 animate__animated animate__fadeIn"><small>Elige una organización de la lista. Si no la encuentras puedes crear una nueva organización{asterisco}</small></Label>
                                        <br />
                                        <Select
                                            placeholder={"Selecciona una organización"}
                                            value={personalCompany}
                                            onChange={setPersonalCompany}
                                            options={listCompanies}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={140}
                                        />
                                        <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => (setShowCompany({ list: true, new: true }), setPersonalCompany(null))}>¿ No encuentras tu empresa u organización en la lista ? <b>Agrégala aquí +</b></Label>
                                    </FormGroup>
                                    {
                                        (showCompany.list && personalCompany !== null) ?
                                            <Fragment>
                                                <FormGroup check className="mt-4">
                                                    <Label className="h5 animate__animated animate__fadeIn">¿ Cuál es tu cargo en <span className="etm_text_blue">{(personalCompany !== null) ? personalCompany.label : ""}</span> ?</Label>
                                                    <br />
                                                    <Label className="h5 animate__animated animate__fadeIn"><small>Elige un cargo de la lista. Si no lo encuentras puedes crear un nuevo cargo{asterisco}</small></Label>
                                                    <br />
                                                    <Select
                                                        placeholder={"Selecciona un cargo"}
                                                        value={personalJobTitle}
                                                        onChange={setPersonalJobTitle}
                                                        options={listJobTitles}
                                                        className="etm_input_select"
                                                        styles={selectCustomStyle()}
                                                        noOptionsMessage={() => 'No hay mas opciones'}
                                                        maxMenuHeight={180}
                                                    />
                                                    <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={() => setShowJobTitle(true)}>¿ No encuentras tu cargo en la lista ? <b>Agrégalo aquí +</b></Label>
                                                </FormGroup>
                                                {
                                                    (personalJobTitle !== null) ?
                                                        <Fragment>
                                                            <FormGroup check className="mt-4">
                                                                <Label className="h5 animate__animated animate__fadeIn">¿ A qué gerencia perteneces dentro de <span className="etm_text_blue">{(personalCompany !== null) ? personalCompany.label : ""}</span> ?</Label>
                                                                <br />
                                                                <Label className="h5 animate__animated animate__fadeIn"><small>Elige una gerencia de la lista. Si no la encuentras puedes crear una nueva gerencia{asterisco}</small></Label>
                                                                <br />
                                                                <Select
                                                                    placeholder={"Selecciona una gerencia"}
                                                                    value={personalManagement}
                                                                    onChange={setPersonalManagement}
                                                                    options={listManagements}
                                                                    className="etm_input_select"
                                                                    styles={selectCustomStyle()}
                                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                                    maxMenuHeight={180}
                                                                />
                                                                <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={() => setShowManagement(true)}>¿ No encuentras tu gerencia en la lista ? <b>Agrégala aquí +</b></Label>
                                                            </FormGroup>
                                                        </Fragment>
                                                        : ""
                                                }
                                            </Fragment>
                                            : ""
                                    }
                                    <FormGroup check hidden={(personalCompany !== null) ? false : true} className="animate__animated animate__fadeIn">
                                        {
                                            (personalCompany !== null) ?
                                                <Fragment>
                                                    <br />
                                                    <Label className="h5 mt-3">{(personalCompany.label === "INDEPENDIENTE") ? `Perfecto, eres` : `Perfecto, trabajas en`}</Label>
                                                    <br />
                                                    <img src={personalCompany.img} alt="" className="w-25 fix_img ml-4" />
                                                    <br />
                                                    <Label className="h4 mt-3">
                                                        <i className="fas fa-arrow-right etm_text_blue mr-3"></i>
                                                        <b>{personalCompany.label} </b>
                                                        {
                                                            (personalCompany.label !== "INDEPENDIENTE" && personalJobTitle !== null && personalJobTitle.label.length > 0) ?
                                                                <Fragment>
                                                                    , tu cargo es <b>{personalJobTitle.label} </b>
                                                                </Fragment>
                                                                : ""
                                                        }
                                                        {
                                                            (personalCompany.label !== "INDEPENDIENTE" && personalManagement !== null && personalManagement.label.length > 0) ?
                                                                <Fragment>
                                                                    y perteneces a la gerencia de <b>{personalManagement.label}</b>
                                                                </Fragment>
                                                                : ""
                                                        }
                                                    </Label>
                                                </Fragment>
                                                : ""
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {
                        (props.rol.name === "MENTOR") ?
                            <Fragment>
                                <Card className="animate__animated animate__fadeIn mt-5">
                                    <CardBody>
                                        {stepNumber(3, "Etapas de emprendimientos")}
                                        <Row className="mt-3 mb-5">
                                            <Col md={12} className="mt-3">
                                                <FormGroup check>
                                                    <Label className="h5">
                                                        ¿ En qué etapa de los emprendimientos crees que puedes apoyarlos de mejor manera ?{asterisco} 
                                                        <br />
                                                        <small className="text-muted">( elige todas las opciones que quieras )</small>
                                                    </Label>
                                                    <br />
                                                    <br />
                                                    <div className="ml-4">
                                                        {listStages}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Fragment>
                            : ""
                    }


                    {/* EXPERTOS */}
                    <Card className="animate__animated animate__fadeIn mt-5">
                        <CardBody>
                            {
                                (props.rol.name === "MENTOR") ?
                                    stepNumber(4, "Datos para el match")
                                    : stepNumber(3, "Datos para el match")
                            }
                            <Row className="mt-3 mb-5">
                                {
                                    (props.rol.name === "MENTOR") ?
                                        ""
                                        : <Fragment>
                                            <Col md={12} className="mt-3">
                                                <FormGroup check>
                                                    <Label className="h5">
                                                        ¿ Cuál es la antigüedad de los emprendimientos de tu interés ?{asterisco} 
                                                        <br />
                                                        <small className="text-muted">( elige todas las opciones que quieras )</small>
                                                    </Label>
                                                    <br />
                                                    <div className="ml-4">
                                                        {listAgeRange}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <FormGroup check>
                                                    <Label className="h5">
                                                        ¿ Te interesa juntarte con emprendimientos con fondos públicos o privados ?{asterisco} 
                                                        <br />
                                                        <small className="text-muted">( elige todas las opciones que quieras )</small>
                                                    </Label>
                                                    <br />
                                                    <div className="ml-4">
                                                        {listInvestment}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <FormGroup check>
                                                    <Label className="h5">
                                                        ¿ Cuál es el rango de facturación de los emprendimientos de tu interés ?{asterisco} 
                                                        <br />
                                                        <small className="text-muted">( elige todas las opciones que quieras )</small>
                                                    </Label>
                                                    <br />
                                                    <div className="ml-4">
                                                        {listBilling}
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md={12} className="mt-3">
                                                <FormGroup check>
                                                    <Label className="h5">
                                                        ¿ Cuál es la cantidad de facturas emitidas de los emprendimientos de tu interés ?{ asterisco } 
                                                        <br />
                                                        <small className="text-muted">( elige todas las opciones que quieras )</small>
                                                    </Label>
                                                    <br/>
                                                    <div className="ml-4">
                                                        { listInvoices }
                                                    </div>
                                                </FormGroup>
                                            </Col> */}
                                        </Fragment>
                                }

                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Cuáles son las industrias de tu interés ?{asterisco} 
                                            <br />
                                            <small className="text-muted">( selecciona todas las industrias que quieras )</small>
                                        </Label>
                                        <br />
                                        <Select
                                            placeholder={"Selecciona industrias"}
                                            value={interestIndustries}
                                            onChange={setInterestIndustries}
                                            options={listIndustries}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={180}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Qué tipos de comercios te interesan ?{asterisco} 
                                            <br />
                                            <small className="text-muted">( selecciona todas las opciones que quieras )</small>
                                        </Label>
                                        <br />
                                        <Select
                                            placeholder={"Selecciona un tipo de comercio"}
                                            value={interestCommercialType}
                                            onChange={setInterestCommercialType}
                                            options={listCommercialType}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={180}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Cuáles países son de tu interés respecto a emprendimientos ?{asterisco} 
                                            <br />
                                            <small className="text-muted">( selecciona todos los países que quieras )</small>
                                        </Label>
                                        <br />
                                        <Select
                                            placeholder={"Selecciona países"}
                                            value={interestCountry}
                                            onChange={setInterestCountry}
                                            options={listCountries}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={180}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4" hidden={!showRegions}>
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Cuáles son las regiones de Chile de tu interés ?{asterisco} 
                                            <br />
                                            <small className="text-muted">( selecciona todas los regiones que quieras )</small>
                                        </Label>
                                        <br />
                                        <Select
                                            placeholder={"Selecciona regiones"}
                                            value={interestRegion}
                                            onChange={setInterestRegion}
                                            options={listRegions}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={100}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {/* EXPERTOS */}
                    <Card className="animate__animated animate__fadeIn mt-5">
                        <CardBody>
                            {
                                (props.rol.name === "MENTOR") ?
                                    stepNumber(5, "Propósito e impacto social")
                                    : stepNumber(4, "Propósito e impacto social")
                            }
                            <Row className="mt-3">
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te interesan proyectos con algún objetivo de <span className="etm_text_blue">Impacto Social</span> ? 
                                            <br />
                                            <small className="text-muted">( opcional. Elige todas las que consideres de la lista o puedes crear nuevas opciones al escribirlas. )</small>
                                        </Label>
                                        <br />
                                        <Creatable
                                            formatCreateLabel={e => `Crear nueva opción ${e}`}
                                            placeholder={"Elige algunas opciones"}
                                            value={interestImpacts}
                                            onChange={setInterestImpacts}
                                            options={listImpacts}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={150}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te interesan proyectos que tengan <span className="etm_text_blue">Objetivos de Desarrollo Sostenible</span> ( ODS ) ? 
                                            <br />
                                            <small className="text-muted">( opcional )</small>
                                        </Label>
                                        <br />
                                        <Select
                                            placeholder={"Elige algunas opciones"}
                                            value={interestOds}
                                            onChange={setInterestOds}
                                            options={listOds}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={150}
                                            isMulti
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te interesan proyectos liderados por alguna <span className="etm_text_blue">Mujer</span> ? 
                                            <br />
                                            <small className="text-muted">( opcional )</small>
                                        </Label>
                                        <br />
                                        <Label className="h5 mt-3 ml-5">
                                            <Input name="radioMujer" type="radio" onClick={(e) => setInterestWomenLeader(e.target.value)} value={0} />{' '}NO
                                        </Label>
                                        <br />
                                        <Label className="h5 ml-5">
                                            <Input name="radioMujer" type="radio" onClick={(e) => setInterestWomenLeader(e.target.value)} value={1} />{' '}SI
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te interesan proyectos con políticas de <span className="etm_text_blue">Equidad de Género</span> ? 
                                            <br />
                                            <small className="text-muted">( opcional )</small>
                                        </Label>
                                        <br />
                                        <Label className="h5 mt-3 ml-5">
                                            <Input name="radioEquidad" type="radio" onClick={(e) => setInterestGenderEquality(e.target.value)} value={0} />{' '}NO
                                        </Label>
                                        <br />
                                        <Label className="h5 ml-5">
                                            <Input name="radioEquidad" type="radio" onClick={(e) => setInterestGenderEquality(e.target.value)} value={1} />{' '}SI
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te interesan proyectos con políticas de <span className="etm_text_blue">Inclusión de Diversidad</span> ? 
                                            <br />
                                            <small className="text-muted">( opcional )</small>
                                        </Label>
                                        <br />
                                        <Label className="h5 mt-3 ml-5">
                                            <Input name="radioDiversidad" type="radio" onClick={(e) => setInterestDiversityInclusion(e.target.value)} value={0} />{' '}NO
                                        </Label>
                                        <br />
                                        <Label className="h5 ml-5">
                                            <Input name="radioDiversidad" type="radio" onClick={(e) => setInterestDiversityInclusion(e.target.value)} value={1} />{' '}SI
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {/* EXPERTOS */}
                    <Card className="animate__animated animate__fadeIn mt-5">
                        <CardBody>
                            {
                                (props.rol.name === "MENTOR") ?
                                    stepNumber(6, "Etiquetas o Experticias")
                                    : stepNumber(5, "Etiquetas")
                            }
                            <Row className="mt-3 mb-5">
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label className="h5">
                                            Para mejorar el match entre los expertos y los emprendimientos, indícanos tus experiencia a modo de <span className="etm_text_blue">etiquetas</span>. Esto funciona similar a los hashtag de las redes sociales y facilitará generar las reuniones.{asterisco}
                                            <br />
                                            <small className="text-muted">Elige al menos 1 etiqueta ya existente o crea nuevas si necesitas. Solo escribe la etiqueta y listo.</small>
                                        </Label>
                                        <br />
                                        <Creatable
                                            formatCreateLabel={e => `Crear etiqueta ${e}`}
                                            placeholder={"Elige tus etiquetas"}
                                            value={personalTag}
                                            onChange={(e) => (e !== null) ? ((e.length <= 10) ? setPersonalTag(e) : null) : setPersonalTag(e)}
                                            options={listTags}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={125}
                                            isMulti
                                        />
                                        <br />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {/* EXPERTOS */}
                    <Card className="animate__animated animate__fadeIn mt-5">
                        <CardBody>
                            {
                                (props.rol.name === "MENTOR") ?
                                    stepNumber(7, "Por último...")
                                    : stepNumber(6, "Por último...")
                            }
                            <Row className="mt-3 mb-3">
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Te gustaría usar un apodo o nickname en <b>EtM</b><i className="etm_text_blue">meet</i> ?{asterisco}
                                            <br />
                                            <small className="text-muted">Esto solo será para las comunicaciones que te enviemos y no será visible para otros usuarios</small>
                                            </Label>
                                        <br />
                                        <Label className="h5 mt-3 ml-5">
                                            <Input name="radioNickname" type="radio" onClick={(e) => setPersonalNickname({ show: false, name: "" })} value="SIN NICKNAME" />{' '}No, solo por mi Nombre
                                        </Label>
                                        <br />
                                        <Label className="h5 ml-5">
                                            <Input name="radioNickname" type="radio" onClick={(e) => setPersonalNickname({ show: true, name: "" })} value="CON NICKNAME" />{' '}Si
                                        </Label>
                                        <Row hidden={!personalNickname.show} className="animate__animated animate__fadeIn">
                                            <Col md={6}>
                                                <Input className="form-control etm_register_input" type="text" onChange={(e) => setPersonalNickname({ show: personalNickname.show, name: e.target.value })} placeholder="Ingresa tu apodo o nickname" />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5">
                                            Preséntate a tí mismo.{asterisco} 
                                            <br />
                                            <small className="text-muted">( máximo 400 caracteres. Esta descripción será parte de tu tarjeta visible para los otros usuarios. )</small>
                                        </Label>
                                        <br />
                                        <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setPersonalDescription(e.target.value)} placeholder="Ejemplo. Hola! Soy Alan el encargado del hacer el match ..." />
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5">
                                            ¿ Tienes redes sociales ? 
                                            <br />
                                            <small className="text-muted">( opcional, ingrésalas solo si quieres. Puedes ingresar las RRSS de tu proyecto )</small>
                                        </Label>
                                        <br />
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5"><small className="text-muted">Facebook</small></Label>
                                        <br />
                                        <InputGroup>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group"><i className="fab fa-facebook facebook"></i></span>
                                            </div>
                                            <Input onChange={(e) => setPersonalRrss({ facebook: cleanFacebook(e.target.value), instagram: personalRrss.instagram, twitter: personalRrss.twitter, youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Facebook" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5"><small className="text-muted">Instagram</small></Label>
                                        <br />
                                        <InputGroup>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group"><i className="fab fa-instagram instagram"></i></span>
                                            </div>
                                            <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: cleanInstagram(e.target.value), twitter: personalRrss.twitter, youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Instagram" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5"><small className="text-muted">Twitter</small></Label>
                                        <br />
                                        <InputGroup>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group"><i className="fab fa-twitter twitter"></i></span>
                                            </div>
                                            <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: personalRrss.instagram, twitter: cleanTwitter(e.target.value), youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Twitter" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <FormGroup check>
                                        <Label className="h5"><small className="text-muted">Youtube</small></Label>
                                        <br />
                                        <InputGroup>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group"><i className="fab fa-youtube youtube"></i></span>
                                            </div>
                                            <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: personalRrss.instagram, twitter: personalRrss.twitter, youtube: e.target.value })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Youtube" />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md={12} className="mt-4">
                                    <FormGroup check>
                                        <Label className="h5">Sube una foto de tí o elige uno de nuestros avatares.{asterisco}</Label>
                                        <br />
                                        <div className="text-center">
                                            <Label className="h5"><small className="mr-2">USAR AVATAR</small> | <small className="ml-2">CARGAR FOTO</small></Label>
                                            <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="" bsSize="lg" checked={avatarType} onChange={() => setAvatarType(!avatarType)} />
                                        </div>
                                        <div className="animate__animated animate__fadeIn text-center" hidden={avatarType}>
                                            <AvatarDefault setAvar={setPersonalProfilePicture} />
                                        </div>
                                        <div className="animate__animated animate__fadeIn text-center" hidden={!avatarType}>
                                            <UploadImageFile
                                                buttonText={"Click aquí para subir una foto"}
                                                singleImage={true}
                                                pathS3={pathS3("PROFILE")}
                                                maxFileNumber={1}
                                                handle={setPersonalProfilePicture}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className="animate__animated animate__fadeIn mt-5 mb-5">
                        <CardBody>
                            {
                                (props.rol.name === "MENTOR") ?
                                    stepNumber(8, "¡ Listo !")
                                    : stepNumber(7, "¡ Listo !")
                            }
                            <Row className="mt-3 mb-3">
                                <Col md={12}>
                                    <FormGroup check>
                                        <Label className="h4"><p>Estás a un solo paso de expandir tus redes y conectarte con pares improbables!</p></Label>
                                        <Label className="h5">
                                            <p className="etm_text_blue"><i className="fas fa-info-circle mr-2"></i>Todos los datos ingresados pueden ser modificados luego en el menú <b>Mi Perfil</b>.</p>
                                            {
                                                (props.rol.name === "MENTOR") ?
                                                    <p className="etm_text_blue"><i className="fas fa-info-circle mr-2"></i>Además, si quieres participar de reunones comerciales donde puedas ver y recibir ofertas de productos y servicios como un <b>Experto comercial</b>, puedes habilitar la opción <b>" Quiero ser Experto comercial "</b> cuando quieras desde el menú <b>Mi Perfil</b>.</p>
                                                    : <p className="etm_text_blue"><i className="fas fa-info-circle mr-2"></i>Además, si quieres ayudar a emprendedores con tu experiencia como <b>Mentor</b>, puedes habilitar la opción <b>" Quiero ser Mentor "</b> cuando quieras desde el menú <b>Mi Perfil</b>.</p>
                                            }

                                        </Label>
                                        <br />
                                        <LoadBtn className="btn-lg"
                                            handle={(e) => preSaveData(e)}
                                            loading={loading} title="¡ COMENZEMOS !" text="Guardando configuración..." />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </Col>

            <ModalNewCompany
                showCompany={showCompany}
                setShowCompany={setShowCompany}
                listIndustries={listIndustries}
                getCompanies={getCompanies}
                setPersonalCompany={setPersonalCompany}
            />
            <ModalNewJobtitle
                showJobTitle={showJobTitle}
                setShowJobTitle={setShowJobTitle}
                getJobTitles={getJobTitles}
                setPersonalJobTitle={setPersonalJobTitle}
            />
            <ModalNewManagement
                showManagement={showManagement}
                setShowManagement={setShowManagement}
                getManagements={getManagements}
                setPersonalManagement={setPersonalManagement}
            />
        </Fragment>
    );
}

export default (Step4Expertos);