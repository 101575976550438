import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Row, Col, FormGroup, Label, Input, Container } from "reactstrap";
import Select from 'react-select';
import { swal_etm, getLocalStorage, selectCustomStyle } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import ModalNewCompany from "../Onboarding/modalNewCompany";
import ModalNewJobtitle from "../Onboarding/modalNewJobtitle";
import ModalNewManagement from "../Onboarding/modalNewManagement";
import LoadScreen from "../Utils/LoadScreen";
import { useHistory } from "react-router-dom";

const OrganizationData = (props) => {
    const history = useHistory();
    const arrow = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;

    const [gettingData, setGettingData] = useState(true);
    const [loading, setLoading] = useState({ save: false, saveNext: false });
    const [showCompany, setShowCompany] = useState({ list: false, new: false });
    const [showJobTitle, setShowJobTitle] = useState(false);
    const [showManagement, setShowManagement] = useState(false);
    const [company, setCompany] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);
    const [management, setManagement] = useState(null);
    const [listIndustries, setListIndustries] = useState([]);
    const [listManagements, setListManagements] = useState([]);
    const [listJobTitles, setListJobTitles] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);

    useEffect(() => {
        getIndustries();
        getCompanies();
        getJobTitles();
        getManagements();
        getOrganizationData();
    }, []);

    useEffect(() => {
        if (company !== null && company.value === 0) {
            setJobTitle(null);
            setManagement(null);
        }
    }, [company]);

    const getIndustries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
    }

    const getCompanies = async () => {
        const res = await API.get('/general/getCompanies');
        if (res.data.state) {
            let list = [];
            res.data.companies.map((e, i) => {
                list.push({ value: e.ID, img: e.FOTO, label: e.EMPRESA });
            });
            setListCompanies(list);
        }
    }

    const getJobTitles = async () => {
        const res = await API.get('/general/getJobTitles');
        if (res.data.state) {
            let list = [];
            res.data.jobTitles.map((e, i) => {
                list.push({ value: e.ID, label: e.CARGO });
            });
            setListJobTitles(list);
        }
    }

    const getManagements = async () => {
        const res = await API.get('/general/getManagements');
        if (res.data.state) {
            let list = [];
            res.data.managements.map((e, i) => {
                list.push({ value: e.ID, label: e.GERENCIA });
            });
            setListManagements(list);
        }
    }

    const getOrganizationData = async () => {
        const res = await API.get('/profile/getOrganizationData', { params: { token: getLocalStorage().token } });
        if (res.data.state) {
            if (res.data.data.organizacion.id !== null) {
                setCompany({ value: res.data.data.organizacion.id, img: res.data.data.organizacion.img, label: res.data.data.organizacion.nombre });
                setShowCompany({ list: true, new: false });
            }

            if (res.data.data.cargo.id !== null) {
                setJobTitle({ value: res.data.data.cargo.id, label: res.data.data.cargo.nombre });
            }

            if (res.data.data.gerencia.id !== null) {
                setManagement({ value: res.data.data.gerencia.id, label: res.data.data.gerencia.nombre });
            }
        }
        setGettingData(false);
    }

    const save = (e) => {
        preSaveOrganizationData(e, false);
    }

    const saveContinue = (e) => {
        preSaveOrganizationData(e, true);
    }

    const preSaveOrganizationData = (e, nextStep) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading({
            save: (nextStep) ? false : true
            , saveNext: (nextStep) ? true : false
        });

        //  company.value = 0, url "", INDEPENDIENTE
        if (company === null) {
            swal_etm(`Indícanos si trabajas en alguna <b class="etm_text_blue">empresa</b> u <b class="etm_text_blue">organización</b>`);
            setLoading({ save: false, saveNext: false });
            return;
        } else {
            if (company.value > 0) {
                //  company.value = 0 no aplica
                if (jobTitle === null || jobTitle.value < 1) {
                    swal_etm(`Indícanos cuál es tu <b class="etm_text_blue">cargo</b> en <b class="etm_text_blue">${company.label}</b>`);
                    setLoading({ save: false, saveNext: false });
                    return;
                }

                //  company.value = 0 no aplica
                if (management === null || management.value < 1) {
                    swal_etm(`Indícanos a qué <b class="etm_text_blue">gerencia</b> perteneces en <b class="etm_text_blue">${company.label}</b>`);
                    setLoading({ save: false, saveNext: false });
                    return;
                }
            }
        }

        postSaveOrganizationData(nextStep);
    }

    const postSaveOrganizationData = async (nextStep) => {
        const res = await API.put("/profile/updateOrganizationData", {
            company: (company !== null) ? company.value : 0
            , jobTitle: (jobTitle !== null) ? jobTitle.value : 0
            , management: (management !== null) ? management.value : 0
            , token: getLocalStorage().token
        });

        if (res.data.state) {
            swal_etm(`Datos de mi organización actualizados!`, `success`);
            setLoading({ save: false, saveNext: false });

            setTimeout(function () {
                if (nextStep) {
                    history.push(`/profile/interest`);
                } else {
                    window.location.reload();
                }
            }, 1000);
        } else {
            swal_etm(res.data.msg);
            setLoading({ save: false, saveNext: false });
        }
    }

    return (
        <Fragment>
            {
                (gettingData) ?
                    <LoadScreen text="Obteniendo datos de organización..." className="mt-5" />
                    : <div className="animate__animated animate__fadeIn">
                        <Card className="mt-3 pb-5">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Datos de mi Organización</p>
                                    </Label>
                                    <br />
                                    <Label className="h5 mt-0 mb-0 pt-0 pb-0">
                                        <p className="text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Esta información sirve para que otros usuarios puedan encontrarte e identificarte facilmente para agendar reuniones contigo.</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Trabajas en alguna empresa u organización actualmente ?{props.asterisco}</Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioCompany" type="radio" onChange={(e) => (setShowCompany({ list: false, new: false }), setCompany({ value: 0, img: "", label: "INDEPENDIENTE" }))} value="INDEPENDIENTE" checked={(company !== null && company.value === 0) ? true : false} />{' '}No, soy independiente
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioCompany" type="radio" onChange={(e) => (setShowCompany({ list: true, new: false }), setCompany(null))} value="EMPRESA" checked={(company !== null && company.value !== 0) ? true : false} />{' '}Si
                                                </Label>
                                                <br />
                                            </FormGroup>
                                            <FormGroup check hidden={!showCompany.list}>
                                                <br />
                                                <Label className="h5 mt-2 animate__animated animate__fadeIn">Elige una organización de la lista. Si no la encuentras puedes crear una nueva organización{props.asterisco}</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una organización"}
                                                    value={company}
                                                    onChange={setCompany}
                                                    options={listCompanies}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={140}
                                                />
                                                <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => (setShowCompany({ list: true, new: true }), setCompany(null))}>¿ No encuentras tu empresa u organización en la lista ? <b>Agrégala aquí +</b></Label>
                                            </FormGroup>
                                            {
                                                (showCompany.list && company !== null) ?
                                                    <Fragment>
                                                        <FormGroup check className="mt-5">
                                                            <Label className="h5 animate__animated animate__fadeIn">{arrow}¿ Cuál es tu cargo en <span className="etm_text_blue">{(company !== null) ? company.label : ""}</span> ?</Label>
                                                            <br />
                                                            <Label className="h5 animate__animated animate__fadeIn"><small>Elige un cargo de la lista. Si no lo encuentras puedes crear un nuevo cargo{props.asterisco}</small></Label>
                                                            <br />
                                                            <Select
                                                                placeholder={"Selecciona un cargo"}
                                                                value={jobTitle}
                                                                onChange={setJobTitle}
                                                                options={listJobTitles}
                                                                className="etm_input_select"
                                                                styles={selectCustomStyle()}
                                                                noOptionsMessage={() => 'No hay mas opciones'}
                                                                maxMenuHeight={180}
                                                            />
                                                            <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={() => setShowJobTitle(true)}>¿ No encuentras tu cargo en la lista ? <b>Agrégalo aquí +</b></Label>
                                                        </FormGroup>
                                                        {
                                                            (jobTitle !== null) ?
                                                                <Fragment>
                                                                    <FormGroup check className="mt-5">
                                                                        <Label className="h5 animate__animated animate__fadeIn">{arrow}¿ A qué gerencia perteneces dentro de <span className="etm_text_blue">{(company !== null) ? company.label : ""}</span> ?</Label>
                                                                        <br />
                                                                        <Label className="h5 animate__animated animate__fadeIn"><small>Elige una gerencia de la lista. Si no la encuentras puedes crear una nueva gerencia{props.asterisco}</small></Label>
                                                                        <br />
                                                                        <Select
                                                                            placeholder={"Selecciona una gerencia"}
                                                                            value={management}
                                                                            onChange={setManagement}
                                                                            options={listManagements}
                                                                            className="etm_input_select"
                                                                            styles={selectCustomStyle()}
                                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                                            maxMenuHeight={180}
                                                                        />
                                                                        <Label className="etm_link_blue mt-2 ml-4 pointer" onClick={() => setShowManagement(true)}>¿ No encuentras tu gerencia en la lista ? <b>Agrégala aquí +</b></Label>
                                                                    </FormGroup>
                                                                </Fragment>
                                                                : ""
                                                        }
                                                    </Fragment>
                                                    : ""
                                            }
                                            <FormGroup check hidden={(company !== null) ? false : true} className="animate__animated animate__fadeIn">
                                                {
                                                    (company !== null) ?
                                                        <Fragment>
                                                            <br />
                                                            {
                                                                (company.label === "INDEPENDIENTE") ?
                                                                    <Fragment>
                                                                        <br />
                                                                        <Label className="h5 mt-3"><i className="fas fa-arrow-right etm_text_blue mr-3"></i>Soy <b>{company.label} </b></Label>
                                                                    </Fragment>
                                                                    : <Fragment>
                                                                        <Label className="h5 mt-3">Trabajo en</Label>
                                                                        <br />
                                                                        <img src={company.img} alt="" className="w-25 fix_img ml-4" />
                                                                        <br />
                                                                        <Label className="h4 mt-3">
                                                                            <i className="fas fa-arrow-right etm_text_blue mr-3"></i>
                                                                            <b>{company.label} </b>
                                                                            {
                                                                                (company.label !== "INDEPENDIENTE" && jobTitle !== null && jobTitle.label.length > 0) ?
                                                                                    <Fragment>
                                                                                        , mi cargo es <b>{jobTitle.label} </b>
                                                                                    </Fragment>
                                                                                    : ""
                                                                            }
                                                                            {
                                                                                (company.label !== "INDEPENDIENTE" && management !== null && management.label.length > 0) ?
                                                                                    <Fragment>
                                                                                        y pertenezco a la gerencia de <b>{management.label}</b>
                                                                                    </Fragment>
                                                                                    : ""
                                                                            }
                                                                        </Label>
                                                                    </Fragment>
                                                            }
                                                        </Fragment>
                                                        : ""
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Guardar" text="Actualizando..." handle={save} loading={loading.save} />
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Container>
                            <Row hidden>
                                <Col lg={4} md={2}></Col>
                                <Col lg={4} md={8}>
                                    <LoadBtn handle={save} loading={loading.save} className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={2}></Col>
                            </Row>
                            {/* <Row>
                                <Col lg={2} md={2}></Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={save} loading={ loading.save } className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={saveContinue} loading={ loading.saveNext } className="btn-lg" color="primary" familyIcon="fas fa" icon="check-circle" title="Guardar y continuar" text="Guardando..." />
                                </Col>
                                <Col lg={2} md={2}></Col>
                            </Row> */}
                        </Container>
                    </div>
            }

            <ModalNewCompany
                showCompany={showCompany}
                setShowCompany={setShowCompany}
                listIndustries={listIndustries}
                getCompanies={getCompanies}
                setPersonalCompany={setCompany}
            />
            <ModalNewJobtitle
                showJobTitle={showJobTitle}
                setShowJobTitle={setShowJobTitle}
                getJobTitles={getJobTitles}
                setPersonalJobTitle={setJobTitle}
            />
            <ModalNewManagement
                showManagement={showManagement}
                setShowManagement={setShowManagement}
                getManagements={getManagements}
                setPersonalManagement={setManagement}
            />
        </Fragment>
    );
}

export default (OrganizationData);