import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import "../../assets/scss/etm.scss";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/images/conecTime/logo_conectime.png";
import ModalEvaluation from '../../components/Meet/ModalEvaluation';
import { API } from '../../helpers/axios';
import { swal_etm } from "../../helpers/etmmeetUtils";

 const Evaluation = ( match ) => {
    const[showModal, setShowModal]  = useState(false);
    const[meetData, setMeetData]    = useState({});

    useEffect(() => { 
        getDataForEvaluation();
    }, []);
    
    const getDataForEvaluation = async () => {
        if(match.location.pathname.length > 0){
            const data  = match.location.pathname.split('/');
            const type  = data[2];
            const hash  = data[3];
            const res   = await API.post('/getDataForEvaluationByHash', { type, hash });
            if (res.data.state) {
                setMeetData(res.data.evaluation);
                setShowModal(true);
            } else {
                swal_etm(`<br><h5>Ya se has respondido esta evaluación.</p><p>Si quieres volver a la plataforma <b>EtM<i style="color: #1b9dd9;">meet</i></b> haz click <a href="/home"><b>aquí</b></a>, de lo contrario solo cierra esta página</h5>`, `success`);
            }
        }
    }
    
    return (
        <Fragment>
            <div className="etm_public_profile_body">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <ModalEvaluation showModal={ showModal } meetData={ meetData }/>
            </div>
        </Fragment>
    );
}

export default withRouter(Evaluation);