import React, { Fragment } from 'react';
import { Row, Col } from "reactstrap";
import LogoEtM from "../../assets/images/etmmeet/landing/logo_rojo.png";
import LogoDay from "../../assets/images/etmmeet/landing/logo_etmday.png";

const Footer = (props) => {
    return (
        <Fragment >
            <Row className="footer_landing pt-3 pb-3 pl-lg-5 pr-lg-5">
                <Col lg={2} md={2} xs={6} className="my-auto text-center">
                    <a href="https://etmday.org/" target="_blank">
                        <img className="footer_landing_img img-fluid mt-1 ml-xl-5 ml-md-3 ml-5" src={ LogoDay } alt="etmmeet"/>    
                    </a>
                </Col>
                <Col lg={2} md={2} xs={6} className="my-auto text-center">
                    <a href="https://emprendetumente.org/" target="_blank">
                        <img className="footer_landing_img img-fluid mt-1 mr-xl-5 ml-md-3" src={ LogoEtM } alt="etm"/>
                    </a>
                </Col>
                <Col lg={5} md={5} sm={12} className="my-auto text-md-left text-center">
                    <div className="mt-md-0 mt-4 ml-md-5 pl-lg-5">
                        <p className="h4 footer_landing_title text-white">Nuestros Contactos</p>
                        <a href="mailto:contacto@emprendetumente.org?subject=Contacto%20desde%20Landing%20|%20EtMmeet" target="_blank">
                            <p className="h5 footer_landing_subtitle text-white"><i className="far fa-envelope mr-2"/>contacto@emprendetumente.org</p>
                        </a>
                        <a href="https://wa.me/56937610371?text=Contacto%20desde%20Landing%20|%20EtMmeet" target="_blank">
                            <p className="h5 footer_landing_subtitle text-white"><i className="fas fa-phone text-white mr-2"/>+56 9 3761 0371</p>
                        </a>
                    </div>
                </Col>
                <Col lg={3} md={3} sm={12} className="my-auto text-md-left text-center">
                    <div>
                        <p className="h4 footer_landing_title text-white mt-md-0 mt-3">Conecta con nosotros</p>
                        <div className="d-flex text-left justify-content-md-start justify-content-center">
                            <a href="https://www.youtube.com/channel/UCifLlPpsPUVqkdubtrDKe7w" target="_blank"><i className="fab fa-youtube text-white fa-2x mr-3"></i></a>
                            <a href="https://www.instagram.com/emprendetumente_org/" target="_blank"><i className="fab fa-instagram text-white fa-2x mr-3"></i></a>
                            <a href="https://www.linkedin.com/company/40723905/admin/" target="_blank"><i className="fab fa-linkedin text-white fa-2x mr-3"></i></a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Footer);


