import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, UncontrolledTooltip } from "reactstrap";

import { withRouter } from "react-router-dom";
import { API } from '../../../helpers/axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import LoadScreen from "../../../components/Utils/LoadScreen";
import { success_msg } from "../../../helpers/etmmeetUtils";

 const ModalMeetings = ( props ) => {
    const { SearchBar } = Search;
    const[meetings, setMeetings] = useState([]);
    const[loading, setLoading] = useState(false);
    
    useEffect(() => { 
        getDataMeet(props.userId);
    }, [props.userId]);

    const handleModal = () => {
        props.setShowData(!props.showModal);
    }

    const getDataMeet = async ( userId ) => {
        if(userId > 0){
            setLoading(true);
            const res = await API.get("/getAdminDataMeetings", { params: { userId }});
            let list = [];
            if(res.data.state){
                res.data.data.map((e, i) => {
                    let estado = "";
                    switch(e.ESTADO){
                        case "CONFIRMADA":
                            estado = "bg-success";
                            break;
                        case "CANCELADA":
                            estado = "bg-warning";
                            break;
                        case "RECHAZADA":
                            estado = "bg-warning";
                            break;
                        case "REPORTADA":
                            estado = "bg-danger";
                            break;
                        case "TIEMPO DISPONIBLE":
                            estado = "bg-info";
                            break;
                    }
                    list.push({
                            id:                     i + 1
                        ,   estado:                 <span className={`text-white badge rounded-pill pl-3 pr-3 ${estado}`}>{ e.ESTADO }</span>
                        ,   dia:                    <Fragment>
                                                        { e.DIA }
                                                        <br/><br/>
                                                        { e.HORA }
                                                    </Fragment>
                        ,   plataforma:             e.PLATAFORMA
                        ,   url:                    <a href={ e.URL } target="_blank" className="etm_link_blue"><i className="fas fa-share mr-2"></i>Link</a>
                        ,   contraseña:                 (e.PASSWORD !== "") ?
                                                            <Fragment>
                                                                <span className="ml-2 pointer etm_link_blue" id={`copy_password_${i}`} onClick={() =>  (navigator.clipboard.writeText( e.PASSWORD ), success_msg('Contraseña copiada'))} ><i className="fas fa-copy mr-2"></i></span>
                                                                { e.PASSWORD }
                                                                <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target={`copy_password_${i}`}>Copiar constraseña </UncontrolledTooltip>
                                                            </Fragment>
                                                        :   ""
                        ,   foto_anfitrion:         <img src={ e.FOTO_ANFITRION } alt="" className="avatar-md rounded-circle fix_img"/>
                        ,   anfitrion:              e.ANFITRION
                        ,   perfil_anfitrion:       e.PERFIL_ANFITRION
                        ,   foto_solicitante:       (e.FOTO_SOLICITANTE !== "") ? <img src={ e.FOTO_SOLICITANTE } alt="" className="avatar-md rounded-circle fix_img"/> : ""
                        ,   solicitante:            e.SOLICITANTE
                        ,   perfil_solicitante:     e.PERFIL_SOLICITANTE
                    });
                });
            }
            setMeetings(list);
            setLoading(false);
        }
    }

    const columns = [{
            dataField:  'id'
        ,   text:       '#'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'foto_anfitrion'
        ,   text:       'FOTO ANFITRION'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'anfitrion'
        ,   text:       'ANFITRION'
        ,   headerStyle: () => {
                return { minWidth: '150px', backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'perfil_anfitrion'
        ,   text:       'PERFIL ANFITRION'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'foto_solicitante'
        ,   text:       'FOTO SOLICITANTE'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'solicitante'
        ,   text:       'SOLICITANTE'
        ,   headerStyle: () => {
                return { minWidth: '150px', backgroundColor: '#009fe3', color: '#FFF' };
            }
    }, {
            dataField:  'perfil_solicitante'
        ,   text:       'PERFIL SOLICITANTE'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'estado'
        ,   text:       'ESTADO'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'dia'
        ,   text:       'DIA HORA'
        ,   editable: false
        ,   headerStyle: () => {
                return { minWidth: '110px', backgroundColor: '#009fe3', color: '#FFF' };
            }
    }, {
            dataField:  'plataforma'
        ,   text:       'PLATAFORMA'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }, {
            dataField:  'url'
        ,   text:       'URL'
        ,   headerStyle: () => {
                return { minWidth: '70px', backgroundColor: '#009fe3', color: '#FFF' };
            }
    }, {
            dataField:  'contraseña'
        ,   text:       'CONSTRASEÑA'
        ,   headerStyle: () => {
                return { backgroundColor: '#009fe3', color: '#FFF'  };
            }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(`clicked on row with index: ${rowIndex}`);
        },
        onMouseEnter: (e, row, rowIndex) => {
          console.log(`enter on row with index: ${rowIndex}`);
        }
    };

    return (
        <Fragment>
            <Modal isOpen={ props.showModal } size="xl" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={12}>
                            {
                                (loading) ?
                                    <LoadScreen text="Cargando historial..."/>
                                :   
                                    <div className="table-responsive">
                                        <ToolkitProvider
                                            keyField="id"
                                            data={ meetings }
                                            columns={ columns }
                                            search
                                            >
                                            {
                                                props => (
                                                <div>
                                                    <h3 className="etm_text_blue"><i className="fas fa-book mr-2"></i>Historial de reuniones</h3>
                                                    <SearchBar placeholder="Buscar..." className="etm_input_text" { ...props.searchProps } />
                                                    <BootstrapTable 
                                                        keyField="id"
                                                        rowEvents={ rowEvents } 
                                                        pagination={ paginationFactory() }  
                                                        { ...props.baseProps } 
                                                    />
                                                </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                        </div>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleModal } size="md" className="etm_btn pl-3 pr-3"><i className="fas fa-times mr-2"></i>Volver</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalMeetings);