import React, { useEffect, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import Logo from "../../assets/images/conecTime/logo_conectime.png";
import LoadScreen from '../../components/Utils/LoadScreen';
import { isSafari, isIos } from "../../helpers/etmmeetUtils";

const QRDownloadApp = ( props ) => {

    const getWidth = () => {
        return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
        );
      }
      
    useEffect(() => {
        getApp();
    }, []);
    
    const getApp = () => {
        setTimeout(function () {
            if(isIos()){
                window.location.href = 'https://apps.apple.com/cl/app/etmmeet/id1581116330';
                return false;
            }else if(isSafari()){
                window.location.href = 'https://apps.apple.com/cl/app/etmmeet/id1581116330';
                return false;
            }else if(getWidth() >= 768){
                window.location.href = 'https://apps.apple.com/cl/app/etmmeet/id1581116330';
                return false;
            }else{
                window.location.href = 'https://play.google.com/store/apps/details?id=org.emprendetumente.meetmobile&hl=es_CL&gl=US';
                return false;
            }
        }, 1500);
    }
    
    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Col sm={12} className="pt-5 mt-5 text-center animate__animated animate__fadeInUp">
                            <LoadScreen text="Buscando aplicación para tu dispositivo..." />
                        </Col>
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(QRDownloadApp);