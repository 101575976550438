import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from "reactstrap";
import { API } from '../../helpers/axios';

import LoadScreen from "../../components/Utils/LoadScreen";
import GoogleDocsViewer from "react-google-docs-viewer";

const PdfViwer = ( props ) => {

    const[width, setWidth] = useState(`${window.screen.width}px`);
    const[url, setUrl] = useState("");
    const[loading, setLoading] = useState(true);

    useEffect(() => {
        getUrl();
    }, []);

    const getUrl = async () => {
        const res = await API.get("/getLineups");
        
        if(res.data.state){
            if(props.location.pathname.length > 0){
                const data  = props.location.pathname.split('/');
                const type  = data[2];
                if(type !== null && type !== undefined){
                    if(type === "principal"){
                        setUrl(res.data.data.principal);
                    }
                    if(type === "corfo"){
                        setUrl(res.data.data.corfo);
                    }
                    if(type === "carpa1"){
                        setUrl(res.data.data.carpa1);
                    }
                    if(type === "carpa2"){
                        setUrl(res.data.data.carpa2);
                    }
                    if(type === "carpa3"){
                        setUrl(res.data.data.carpa3);
                    }
                    if(type === "internacional"){
                        setUrl(res.data.data.internacional);
                    }
                }
            }
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div>
                <Row>
                    <Col>
                        {
                            (loading) ?
                                <LoadScreen text="Cargando Line Up..."/>
                            :   <GoogleDocsViewer
                                    width={ width }
                                    height="1000px"
                                    fileUrl={ url }
                                />
                        }
                    </Col>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(PdfViwer);