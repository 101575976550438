import React, { Fragment } from 'react';

const ExportBtn = ({ handle, loading, title, icon, className }) => {

    let clase = 'fas fa-' + icon;
    let btn_class = 'btn btn-success etm_btn ' + className;
    
    return (
        <Fragment>
            <div>
                <button onClick={handle} className={btn_class} type="submit" disabled={loading}>
                    {
                        loading ? <div className="text-white m-1"><div className="spinner-border spinner-border-sm" role="status" /> Generando...</div> : <div><i className={clase}></i> {title}</div>
                    }
                </button>
            </div>
        </Fragment>
    );
}

export default ExportBtn;

