import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import SliderExpert from "./SliderExpert";
import SliderStartup from "./SliderStartup";
import Experience from "./Experience";
import AnimatedNumber from 'react-animated-number';

import { API } from '../../helpers/axios';

const Testimonials = (props) => {
    const etmmeet = <span><b>EtM</b><i>meet</i></span>;
    const[countEmprendedores, setCountEmprendedores]    = useState(0);
    const[countExpertos, setCountExpertos]              = useState(0);
    const[countReuniones, setCountReuniones]            = useState(0);
    const[countEmpresas, setCountEmpresas]              = useState(0);
    const[countIndustrias, setCountIndustrias]          = useState(0);

    useEffect(() => {
        getEmprendedores();
        getExpertos();
        getReuniones();
        getEmpresas();
        getIndustrias();
    }, [])

    const formatNumber = ( type, value ) => {
        let number = value;
        if(type === "VALUE"){
            if(number > 999){
                let nf = new Intl.NumberFormat('en-US');
                nf = nf.format(number); // "1,234,567,890"
                nf = nf.replace(",", ".");
                return parseFloat(nf); 
            }else{
                return number;
            }
        }else{
            if(number > 999){
                return 3;
            }else{
                return 0;
            }
        }
    }

    const getEmprendedores = async () => {
        const res = await API.get('/getLandingEmprendedores');
        if(res.data.state){
            setCountEmprendedores(  <AnimatedNumber value={ formatNumber("VALUE", res.data.data) } formatValue={n => n.toFixed(formatNumber("FORMAT", res.data.data))} initialValue={ 0 } duration={ 2500 } style={{ transition: '2s ease-out' }} /> );
        }
    }

    const getExpertos = async () => {
        const res = await API.get('/getLandingExpertos');
        if(res.data.state){
            setCountExpertos(  <AnimatedNumber value={ formatNumber("VALUE", res.data.data) } formatValue={n => n.toFixed(formatNumber("FORMAT", res.data.data))} initialValue={ 0 } duration={ 2500 } style={{ transition: '2s ease-out' }} /> );
        }
    }

    const getReuniones = async () => {
        const res = await API.get('/getLandingReuniones');
        if(res.data.state){
            setCountReuniones(  <AnimatedNumber value={ formatNumber("VALUE", res.data.data) } formatValue={n => n.toFixed(formatNumber("FORMAT", res.data.data))} initialValue={ 0 } duration={ 2500 } style={{ transition: '2s ease-out' }} /> );
        }
    }

    const getEmpresas = async () => {
        const res = await API.get('/getLandingEmpresas');
        if(res.data.state){
            setCountEmpresas(  <AnimatedNumber value={ formatNumber("VALUE", res.data.data) } formatValue={n => n.toFixed(formatNumber("FORMAT", res.data.data))} initialValue={ 0 } duration={ 2500 } style={{ transition: '2s ease-out' }} /> );
        }
    }

    const getIndustrias = async () => {
        const res = await API.get('/getLandingIndustrias');
        if(res.data.state){
            setCountIndustrias(  <AnimatedNumber value={ formatNumber("VALUE", res.data.data) } formatValue={n => n.toFixed(formatNumber("FORMAT", res.data.data))} initialValue={ 0 } duration={ 2500 } style={{ transition: '2s ease-out' }} /> );
        }
    }

    return (
        <Fragment>
            <Row className="testimonials pt-5" id="testimonials">
                <Col xs={12}>
                    <Row className="testimonials_numbers">
                        <Col className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="testimonials_title">
                                { countEmprendedores }
                            </p>
                            <p className="testimonials_subtitle"><b>Emprendedoras/es registrados en { etmmeet }</b></p>
                        </Col>
                        <Col className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="testimonials_title">
                                { countExpertos }
                            </p>
                            <p className="testimonials_subtitle"><b>Expertas/os registrado en { etmmeet }</b></p>
                        </Col>
                        <Col className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="testimonials_title">
                                { countReuniones }
                            </p>
                            <p className="testimonials_subtitle"><b>Reuniones realizadas</b></p>
                        </Col>
                        <Col className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="testimonials_title">
                                { countEmpresas }
                            </p>
                            <p className="testimonials_subtitle"><b>Compañías conectan con emprendedoras/es</b></p>
                        </Col>
                        <Col className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="testimonials_title">
                                { countIndustrias }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Industrias diferentes</b></p>
                        </Col>
                    </Row>
                    <Row className="testimonials_numbers_mobile">
                        <Col md={6} sm={12} className="text-center mt-sm-3 mt-3">
                            <p className="h1 testimonials_title">
                                { countEmprendedores }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Emprendedoras/es registrados en { etmmeet }</b></p>
                        </Col>
                        <Col md={6} sm={12} className="text-center mt-sm-3 mt-3">
                            <p className="h1 testimonials_title">
                                { countExpertos }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Expertas/os registrado en { etmmeet }</b></p>
                        </Col>
                        <Col md={6} sm={12} className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="h1 testimonials_title">
                                { countReuniones }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Reuniones realizadas</b></p>
                        </Col>
                        <Col md={6} sm={12} className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="h1 testimonials_title">
                                { countEmpresas }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Compañías conectan con emprendedoras/es</b></p>
                        </Col>
                        <Col md={12} sm={12} className="text-center mt-sm-3 mt-3 ml-sm-0 ml-2 mr-sm-0 mr-2">
                            <p className="h1 testimonials_title">
                                { countIndustrias }
                            </p>
                            <p className="h5 testimonials_subtitle"><b>Industrias diferentes</b></p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className="text-center mt-5">
                    <p className="h1 testimonials_title_xl">¡La fórmula sí funciona!</p>
                </Col>
                <Col xs={12} className="text-center mt-3">
                    <Row>
                        <Col>
                            <p className="testimonials_between_line mb-5"><b>Revisa los testimonios de expertas/os<br/> que usan { etmmeet }</b></p>
                            <SliderExpert />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className="text-center pt-3 pb-5">
                    <p className="testimonials_between_line mb-5"><b>Revisa los testimonios de emprendedoras/es<br/> que usan { etmmeet }</b></p>
                    <SliderStartup />
                </Col>
                <Col xs={12} className="text-center pt-3 pb-5">
                    <Experience type={ "home" }/>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Testimonials);


