import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import SliderSponsor from "./SliderSponsor";
import SliderPartners from './SliderPartners';

const Sponsor = (props) => {
    return (
        <Fragment>
            <Row className="sponsor pt-5">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center mb-md-5 mb-3">
                            <p className="h1 sponsor_title text-white">Auspiciadores y Partners</p>
                        </Col>
                        <Col xs={12} className="text-center mb-5">
                            <SliderSponsor />
                            <hr className="sponsor_hr"/>
                            <SliderPartners />
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Fragment>
    );
}

export default (Sponsor);


