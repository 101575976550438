import React, { Fragment } from 'react';
import { Row, Col } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VicenteUrruticoechea from "../../assets/images/etmmeet/landing/testimonials/startup/VicenteUrruticoechea.png";
import CamilaGonzalez from "../../assets/images/etmmeet/landing/testimonials/startup/CamilaGonzalez.png";
import SantiagoBrunet from "../../assets/images/etmmeet/landing/testimonials/startup/SantiagoBrunet.png";
import MatiasGonzalez from "../../assets/images/etmmeet/landing/testimonials/startup/MatiasGonzalez.png";
import CeciliaUreta from "../../assets/images/etmmeet/landing/testimonials/startup/CeciliaUreta.png";
import DanielOrtuzar from "../../assets/images/etmmeet/landing/testimonials/startup/DanielOrtuzar.png";
import BenjaminGonzalez from "../../assets/images/etmmeet/landing/testimonials/startup/BenjaminGonzalez.png";
import { isMobile } from '../../helpers/etmmeetUtils';

const SliderStartups = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: (isMobile()) ? 1 : 2,
        slidesToScroll: 1,
        speed:  1000,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
          ),
          customPaging: i => (
            <div></div>
          )
    };

    return (
        <Fragment>
            <Slider {...settings} className="testimonials_slider">
                <div className="text-center carousel-item active">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Emprende tu Mente nos dio la oportunidad para poder entender la realidad de multinacionales, de pequeñas empresas, startups. Y eso nos ayudó para poder apuntar a una solución mucho más específica para ese tipo de dolores para ese tipo de clientes."
                            :   "Emprende tu Mente nos dio la oportunidad para poder entender la realidad de multinacionales, de pequeñas empresas, startups. Y eso nos ayudó para poder apuntar a una solución mucho más específica para ese tipo de dolores para ese tipo de clientes."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ VicenteUrruticoechea } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Vicente Urruticoechea</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Co Fundador & CEO de Boomsearch</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Iniciativas como esta son precisamente lo que necesitamos los emprendedores hoy en día. Esta plataforma nos permitirá ampliar nuestra red de contactos, conocimientos y negocios, dándonos la oportunidad de escalar nuestros negocios al siguiente nivel."
                            :   "Iniciativas como esta son precisamente lo que necesitamos los emprendedores hoy en día. Esta plataforma nos permitirá ampliar nuestra red de contactos, conocimientos y negocios, dándonos la oportunidad de escalar nuestros negocios al siguiente nivel."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ CamilaGonzalez } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Camila González</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Fundadora de Diseño Verde</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "EtMmeet ha sido una herramienta muy valiosa para poder conectar con empresas y personas del mundo corporativo, y para recibir feedback de mi startup. Sin duda, la plataforma, entrega un valor enorme al ecosistema emprendedor y lo seguiré usando."
                            :   "EtMmeet ha sido una herramienta muy valiosa para poder conectar con empresas y personas del mundo corporativo, y para recibir feedback de mi startup. Me ha permitido incluso conectar con potenciales clientes, los que no habría podido conseguir tan fácilmente con otros canales. Sin duda Emprende tu Mente entrega un valor enorme al ecosistema emprendedor y lo seguiré usando."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ SantiagoBrunet } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Santiago Brunet</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>CEO Breik</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "EtMmeet ha sido una tremenda herramienta para conocer gente extraordinaria y que está dispuesta a ayudar a emprendedores que buscan cumplir sus sueños.Recomiendo tremendamente EtMmeet, ya que permite generar conversaciones que pueden, en mayor o menor grado, cambiar el mundo a través del emprendimiento."
                            :   "EtMmeet ha sido una tremenda herramienta para conocer gente extraordinaria y que está dispuesta a ayudar a emprendedores que buscan cumplir sus sueños.Solo por mencionar dos ejemplos, hice una alianza con Teletón, a través de su Vicepresidente, Ademir Domic y conocí a  Daniel Vercelli, ex CEO de Coca- Cola Chile, quien ha sido un tremendo mentor.Recomiendo tremendamente EtMmeet, ya que permite generar conversaciones que pueden, en mayor o menor grado, cambiar el mundo a través del emprendimiento."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ MatiasGonzalez } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Matías González</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Fundador y CEO de Uclases</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Gracias a EtMmeet he tenido la oportunidad de tener reuniones con diversos gerentes de empresas de todo tamaño para ver oportunidades de negocio y alianzas comerciales.¡Muy buena herramienta para conectar emprendimientos que están entrando al mercado, dándoles la instancia para reunirse con potenciales clientes empresa!"
                            :   "Gracias a EtMmeet he tenido la oportunidad de tener reuniones con diversos gerentes de empresas de todo tamaño para ver oportunidades de negocio y alianzas comerciales. Hemos seguido en conversaciones con 5 gerentes y concretamos una alianza estratégica con el Banco Bci que nos ha abierto muchas puertas para Vortaas. ¡Muy buena herramienta para conectar emprendimientos que están entrando al mercado, dándoles la instancia para reunirse con potenciales clientes empresa!"
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ CeciliaUreta } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Cecilia Ureta</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Fundadora de Vortaas</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Increíble EtMmeet, he tenido más de diez reuniones con mentores extraordinarios, siempre abiertos a aportar y compartir su red de contactos. Estamos muy felices, ya que hace poco cerramos nuestro primer negocio 100% EtMmeet. Recomiendo EtMmeet a todos los emprendedores, yo le he sacado el jugo y me ha traído muchos beneficios."
                            :   "Increíble EtMmeet, he tenido más de diez reuniones con mentores extraordinarios, siempre abiertos a aportar y compartir su red de contactos. Estamos muy felices, ya que hace poco cerramos nuestro primer negocio 100% EtMmeet , donde Alfredo Zepeda fue nuestro impulsor para llevar el proyecto adelante. Recomiendo EtMmeet a todos los emprendedores, yo le he sacado el jugo y me ha traído muchos beneficios."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ DanielOrtuzar } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Daniel Ortuzar</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Socio Fundador de Bitua</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Estamos constantemente moviéndonos por EtMmeet, estamos muy contentos de lo útil que ha sido para nosotros la plataforma, no nos deja de sorprender la variedad de profesionales e industrias que encontramos semana a semana. Sin duda es una herramienta que seguiremos explotando."
                            :   "Estamos constantemente moviéndonos por EtMmeet, estamos muy contentos de lo útil que ha sido para nosotros la plataforma, no nos deja de sorprender la variedad de profesionales e industrias que encontramos semana a semana. Además tenemos la posibilidad de devolver nuestro granito de arena a quienes nos han pedido mentorías en temas de Machine learning o datascience. Sin duda es una herramienta que seguiremos explotando."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ BenjaminGonzalez } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Benjamín Gonzalez</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>My Future AI</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Slider>
        </Fragment>
    );
}

export default (SliderStartups);


