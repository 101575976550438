import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import "../../assets/scss/etm.scss";
import { API } from '../../helpers/axios';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadScreen from "../../components/Utils/LoadScreen";
import ModalFullData from "../../components/Admin/User/ModalFullData";
import ModalHistory from "../../components/Admin/User/ModalHistory";
import ModalMeetings from "../../components/Admin/User/ModalMeetings";
import ModalAdd from "../../components/Admin/User/ModalAdd";
import { success_msg } from "../../helpers/etmmeetUtils";

const AdminUser = () => {
    const { SearchBar } = Search;
    const ls = JSON.parse(localStorage.getItem('user'));

    const[loading, setLoading]                      = useState(false);
    const[showModalData, setShowModalData]          = useState(false);
    const[idModalData, setIdModalData]              = useState(0);
    const[showModalHistory, setShowModalHistory]    = useState(false);
    const[idModalHistory, setIdModalHistory]        = useState(0);
    const[showModalMeetings, setShowModalMeetings]  = useState(false);
    const[idModalMeetings, setIdModalMeetings]      = useState(0);
    const[showModalAdd, setShowModalAdd]            = useState(false);
    const[idModalAdd, setIdModalAdd]                = useState(0);
    const[users, setUsers]                          = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);
        const res = await API.get("/getUsers", { params: { user_id: ls.id } });
        let list = [];
        if(res.data.state){
            res.data.users.map((e, i) => {
                list.push({
                        id:             e.ID
                    ,   foto:           <img src={ e.FOTO } alt="" className="avatar-md rounded-circle fix_img" key={i}/>
                    ,   nombre:         e.NOMBRE
                    ,   rut:            e.RUT
                    ,   email_copy:     <Fragment>
                                            <span className="ml-2 pointer etm_link_blue" id={`copy_email_${i}`} onClick={() =>  (navigator.clipboard.writeText( e.EMAIL ), success_msg('Email copiado'))} ><i className="fas fa-copy mr-2"></i></span>
                                            <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target={`copy_email_${i}`}>Copiar email </UncontrolledTooltip>
                                        </Fragment>
                    ,   email:          e.EMAIL
                    ,   telefono_copy:  <Fragment>
                                            <span className="ml-2 pointer etm_link_blue" id={`copy_telefono_${i}`} onClick={() =>  (navigator.clipboard.writeText( e.TELEFONO ), success_msg('Teléfono copiado'))} ><i className="fas fa-copy mr-2"></i></span>
                                            <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target={`copy_telefono_${i}`}>Copiar teléfono </UncontrolledTooltip>
                                        </Fragment>
                    ,   telefono:       e.TELEFONO
                    ,   perfil:         e.PERFIL
                    ,   empresa:        e.EMPRESA
                    ,   cargo:          e.CARGO
                    ,   nota:           (e.NOTA) ? <label className="h5 text-white"><span className={`badge rounded-pill badge-soft-dark pl-3 pr-3 pt-2 pb-2`}>{ e.NOTA }</span></label> : ""
                    ,   industria:      e.INDUSTRIA
                    ,   administrador:  <label className="h5 text-white"><span className={`badge rounded-pill ${(e.ADMINISTRADOR === 'SI') ? `bg-info` : `badge-soft-dark` } pl-3 pr-3 pt-2 pb-2`}>{ e.ADMINISTRADOR }</span></label>
                    ,   fecha_creacion: e.FECHA_CREACION
                    ,   opciones:       <Fragment>
                                            <div className="d-flex justify-center">
                                                <Button color="primary" onClick={ () => (setIdModalData(e.ID), setShowModalData(!showModalData)) } className="etm_btn p-2 ml-2" id={`btn_1_${i}`}><i className="fas fa-address-book fa-2x"></i></Button>
                                                <Button color="success" onClick={ () => (setIdModalHistory(e.ID), setShowModalHistory(!showModalHistory)) } className="etm_btn p-2 ml-2" id={`btn_2_${i}`}><i className="fas fa-book fa-2x"></i></Button>
                                                <Button color="warning" onClick={ () => (setIdModalMeetings(e.ID), setShowModalMeetings(!showModalMeetings)) } className="etm_btn p-2 ml-2" id={`btn_3_${i}`}><i className="fas fa-calendar-alt fa-2x"></i></Button>
                                                <Button color="danger" onClick={ () => (setIdModalAdd(e.ID), setShowModalAdd(!showModalAdd)) } className="etm_btn p-2 ml-2" id={`btn_4_${i}`}><i className="fas fas fa-plus-circle fa-2x"></i></Button>
                                                <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target={`btn_1_${i}`}>Ver datos completos</UncontrolledTooltip>
                                                <UncontrolledTooltip innerClassName="bg-success" delay={0} placement="bottom" target={`btn_2_${i}`}>Historial de reuniones</UncontrolledTooltip>
                                                <UncontrolledTooltip innerClassName="bg-warning" delay={0} placement="bottom" target={`btn_3_${i}`}>Reuniones futuras</UncontrolledTooltip>
                                                <UncontrolledTooltip innerClassName="bg-danger" delay={0} placement="bottom" target={`btn_4_${i}`}>Crear reunión manual</UncontrolledTooltip>
                                            </div>
                                        </Fragment>
                });
            });
        }
        setUsers(list);
        setLoading(false);
    }

    const columns = [{
            dataField:  'id'
        ,   text:       'ID'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'foto'
        ,   text:       'FOTO'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'nombre'
        ,   text:       'NOMBRE'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'rut'
        ,   text:       'RUT'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'email_copy'
        ,   text:       ''
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'email'
        ,   text:       'EMAIL'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'telefono_copy'
        ,   text:       ''
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'telefono'
        ,   text:       'TELEFONO'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'perfil'
        ,   text:       'PERFIL'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'empresa'
        ,   text:       'EMPRESA'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'cargo'
        ,   text:       'CARGO'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'nota'
        ,   text:       'NOTA'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'industria'
        ,   text:       'INDUSTRIA'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'administrador'
        ,   text:       'ADMINISTRADOR'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'fecha_creacion'
        ,   text:       'FECHA CREACION'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }, {
            dataField:  'opciones'
        ,   text:       'OPCIONES'
        ,   headerStyle: () => {
            return { backgroundColor: '#009fe3', color: '#FFF' };
        }
    }];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Usuarios'} />
                    <ToastContainer />
                    <Row>
                        <Col lg="12" className="table-responsive">
                            {
                                (loading) ?
                                    <LoadScreen text="Cargando usuarios..."/>
                                :   <ToolkitProvider
                                        keyField="id"
                                        data={ users }
                                        columns={ columns }
                                        search
                                        >
                                        {
                                            props => (
                                            <div>
                                                <h3 className="etm_text_blue"><i className="fas fa-users mr-2"></i>Usuarios</h3>
                                                <SearchBar placeholder="Buscar..." className="etm_input_text" { ...props.searchProps } />
                                                <BootstrapTable 
                                                    keyField="id"
                                                    pagination={ paginationFactory() }  
                                                    { ...props.baseProps } 
                                                />
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ModalFullData showModal={ showModalData } setShowData={ setShowModalData } userId={ idModalData }/>
            <ModalHistory showModal={ showModalHistory } setShowData={ setShowModalHistory } userId={ idModalHistory }/>
            <ModalMeetings showModal={ showModalMeetings } setShowData={ setShowModalMeetings } userId={ idModalMeetings }/>
            <ModalAdd showModal={ showModalAdd } setShowData={ setShowModalAdd } userId={ idModalAdd }/>
        </React.Fragment>
    );
}

export default withNamespaces()(AdminUser);