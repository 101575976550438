import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardTitle, Button, FormGroup, Input, Label, Container } from "reactstrap";
import { API } from '../../helpers/axios';
import 'react-toastify/dist/ReactToastify.css';
import { swal_etm, validateRut } from "../../helpers/etmmeetUtils";
import logo from "../../assets/images/etmmeet/landing/logo_etmday_negro.png";

const ValidateExpert = (props) => {
    const [experRutEmail, setExperRutEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const buscarExperto = async () => {
        setLoading(true)
        let tipo = 'run'
        if (experRutEmail !== '') {
            if (!validateRut(experRutEmail)) {
                tipo = 'email'
            }

            const res = await API.get('/quickMeetings/validateExpert', { params: { type: tipo, runEmail: experRutEmail } });
            if (res.data.state) {
                localStorage.removeItem('speed_dating');
                localStorage.setItem('speed_dating', JSON.stringify(res.data.experto));
                props.setExperto(res.data.experto)
            } else {
                swal_etm(res.data.msg)
            }
        } else {
            swal_etm('Debes ingresar un RUT o correo válido')
        }
        setLoading(false)
    }

    return (
        <Fragment>
            <Container>
                <Card className="mt-3">
                    <div className="text-center m-3">
                        <img src={logo} alt="" className="img-fluid" width="70%" />
                    </div>
                    <CardTitle className="mt-3 ml-3  mr-3 mb-0 text-center">
                        <Label className="h3">
                            Ingresa con tu RUT o tu correo electrónico.
                        </Label>
                    </CardTitle>
                    <CardBody className="pt-0">
                        <Container>
                            <FormGroup className="mr-md-5 ml-md-5">
                                <Input
                                    type="text"
                                    className="etm_register_input"
                                    value={experRutEmail}
                                    onChange={(e) => setExperRutEmail(e.target.value)}
                                    placeholder="Rut sin puntos ni guión / Email"
                                />
                            </FormGroup>
                            <Button
                                block
                                color="primary"
                                onClick={() => buscarExperto()}
                                disabled={loading}
                            >
                                {
                                    (loading) ?
                                        <span><i className="fas fa-sync fa-spin mr-2"></i>Cargando...</span>
                                        :
                                        <span><i className="fas fa-share mr-2"></i>Ingresar</span>
                                }
                            </Button>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
}

export default ValidateExpert;