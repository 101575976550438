import React, { Fragment, useState, useEffect } from 'react';
import { Button, Row, Col, Label, Input, FormGroup, Card, CardBody } from "reactstrap";
import { API } from '../../helpers/axios';
import { getLocalStorage, capitalize, swal_etm, formatRut } from '../../helpers/etmmeetUtils';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import userDefault from "../../assets/images/conecTime/user_profile.png";

const ModalAttendeeData = (props) => {
    const ls = getLocalStorage()

    const [attendeeName, setAttendeeName] = useState('');
    const [attendeeLastName, setAttendeeLastName] = useState('');
    const [attendeeEmail, setAttendeeEmail] = useState('');
    const [attendeePhone, setAttendeePhone] = useState('');
    const [msg, setMsg] = useState('');
    const [data, setData] = useState({});

    useEffect(() => {
        if (props.section.data) {
            if (props.data !== undefined && props.data !== null) {
                setData(props.data)
            }
        }
    }, [props.section.data]);

    const closeSection = () => {
        setAttendeeName('')
        setAttendeeLastName('')
        setAttendeeEmail('')
        setAttendeePhone('')
        props.setSection({ scanner: true, data: false, list: false })
    }

    const test = async () => {
        const res = await API.get('/controlAccess/test');
        if (res.data.state) {
            swal_etm('works', 'success')
        } else {
            swal_etm('fail')
        }
    }

    const test2 = async () => {
        let xxx = "hola";
        const res = await API.post('/controlAccess/test');
        if (res.data.state) {
            swal_etm(xxx, 'success')
        } else {
            swal_etm('fail')
        }
    }

    const preRecordAttendance = () => {
        recordAttendance();
    }

    const recordAttendance = async () => {
        setMsg("1...");
        let unregistered = {
            run: props.attendeeRun
            , email: attendeeEmail
            , nombre: attendeeName
            , last_name: attendeeLastName
            , phone: attendeePhone
        }
        let datos = {
            isInvited: data.isInvited
            , isEtmMeet: data.isEtmMeet
            , accreditor: ls.TOKEN
            , user: data
            , unregistered
        }
        setMsg("2...");
        const res = await API.post('/controlAccess/putRecordAttendance', { datos });
        setMsg("3...");
        if (res.data.state) {
            swal_etm(res.data.msg, 'success')
        } else {
            swal_etm(res.data.msg)
        }
        setMsg("4...");
        closeSection()
    }

    return (
        <Fragment>
            <Card hidden={!props.section.data}>
                <CardBody>
                    <div xs={12} className="d-flex justify-content-end mr-2 ml-2 mt-2 mb-0">
                        <i className="fas fa-times fa-2x text-danger" onClick={() => closeSection()}></i>
                    </div>
                    {
                        (data.isEtmMeet !== 0 || data.isInvited !== 0) ?
                            <Row className="m-2 p-2">
                                <Col xs={12} className="d-flex justify-content-center">
                                    <div className="mb-2 mt-2">
                                        <img
                                            src={(data.foto === '') ? userDefault : data.foto}
                                            alt={data.nombre_completo}
                                            className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} className="text-center mt-2">
                                    <Label className="h5">
                                        {`${capitalize(data.nombre_completo)}`}
                                    </Label>
                                    <br />
                                    <Label className="h4">
                                        {props.colorByRol(data.rol)}
                                    </Label>
                                </Col>
                                {
                                    (data.rol === 'EMPRENDEDOR') ?
                                        <>
                                            <Col xs={12} className="d-flex my-auto">
                                                <Label className="h6 mt-3">
                                                    <b>Emprendimientos:</b>
                                                </Label>
                                            </Col>
                                            <Col xs={12} className="d-flex my-auto">
                                                <Label className="h6 mt-3">
                                                    {`${capitalize(data.emprendimientos)}`}
                                                </Label>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            {
                                                (data.empresa !== undefined && data.empresa !== null && data.empresa !== '') ?
                                                    <>
                                                        <Col xs={12} className="d-flex justify-content-center">
                                                            <div className="mb-2 mt-2">
                                                                <img
                                                                    src={data.empresa_foto}
                                                                    alt={data.empresa}
                                                                    className="img-thumbnail fix_img shadow-lg"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} className="d-flex justify-content-center">
                                                            <Label className="mt-3">
                                                                {`${capitalize(data.empresa)}`}
                                                            </Label>
                                                        </Col>
                                                    </>
                                                    : ""
                                            }
                                        </>
                                }
                                <Col xs={12}>
                                    <hr />
                                </Col>
                                <Col xs={12} className="mt-2 d-flex justify-content-around">
                                    <div xs={12} className=" mr-2 ml-2 mt-2 mb-0">
                                        <i
                                            className={`fas ${(data.isInvited !== 0) ?
                                                'fa-check-circle text-success'
                                                : 'fa-times-circle text-danger'
                                                } fa-lg mr-2`}
                                        ></i>
                                        <Label className="h5">Invitado</Label>
                                    </div>
                                    <div xs={12} className="mr-2 ml-2 mt-2 mb-0">
                                        <i className={`fas ${(data.isEtmMeet !== 0) ?
                                            'fa-check-circle text-success'
                                            : 'fa-times-circle text-danger'
                                            } fa-lg mr-2`}></i>
                                        <Label className="h5">Usuario <b>EtM</b><i className="etm_text_blue">meet</i></Label>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <Row className="m-2 p-2">
                                <Col xs={12} className="mb-3 text-center">
                                    <Label className="h4">
                                        <i className="fas fa-arrow-right etm_text_blue mr-2"></i>
                                        {props.textCard.title}
                                    </Label>
                                </Col>
                                <Col md={12}>
                                    <FormGroup className="ml-md-3 mr-md-3">
                                        <Label
                                            className="mb-0"
                                            for="run"
                                        >
                                            RUT
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control etm_register_input"
                                            name="run"
                                            id="run"
                                            placeholder="Ingresa su RUT"
                                            required
                                            value={props.attendeeRun}
                                            onChange={(e) => props.setAttendeeRun(formatRut(e.target.value))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="ml-md-3">
                                        <Label
                                            className="mb-0"
                                            for='nombre'
                                        >
                                            Nombre
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control etm_register_input"
                                            name="nombre"
                                            id="nombre"
                                            placeholder="Ingresa su Nombre"
                                            required
                                            value={attendeeName}
                                            onChange={(e) => setAttendeeName(e.target.value)}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="mr-md-3 ml-md-0">
                                        <Label
                                            className="mb-0"
                                            for='apellido'
                                        >
                                            Apellido
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control etm_register_input"
                                            name="apellido"
                                            id="apellido"
                                            placeholder="Ingresa su Apellido"
                                            required
                                            value={attendeeLastName}
                                            onChange={(e) => setAttendeeLastName(e.target.value)}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup className="ml-md-3 mr-md-3">
                                        <Label
                                            className="mb-0"
                                            for="email"
                                        >
                                            Correo electrónico
                                        </Label>
                                        <Input
                                            type="email"
                                            className="form-control etm_register_input"
                                            name="email"
                                            id="email"
                                            placeholder="Ingresa su Email"
                                            required
                                            value={attendeeEmail}
                                            onChange={(e) => setAttendeeEmail(e.target.value)}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="ml-md-3">
                                        <Label
                                            className="mb-0"
                                            for="telefono"
                                        >
                                            Teléfono
                                        </Label>
                                        <PhoneInput
                                            id="telefono"
                                            country={'cl'}
                                            value={attendeePhone}
                                            onChange={phone => setAttendeePhone(phone)}
                                            inputClass="form-control etm_input_phone mt-0"
                                            buttonClass="etm_input_phone_flag"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                    }
                    <Row>
                        <Col md={12} className="text-center">
                            <p>
                                {
                                    (msg.length > 0) ?
                                        <span>{msg}</span>
                                        : <span>-</span>
                                }
                            </p>
                        </Col>
                        <Col md={12}>
                            <Button
                                block
                                color="success"
                                className="btn btn-sm text-nowrap"
                                size="md"
                                name="registrar"
                                id="registrar"
                                onClick={() => preRecordAttendance()}
                            >
                                <i className="fas fa-edit mr-2"></i>
                                {props.textCard.btn}
                            </Button>
                        </Col>
                        <Col md={12}>
                            <Button
                                block
                                color="warning"
                                className="btn btn-sm text-nowrap"
                                size="md"
                                name="registrar"
                                id="registrar"
                                onClick={() => test()}
                            >
                                <i className="fas fa-edit mr-2"></i>
                                test 1 GET
                            </Button>
                        </Col>
                        <Col md={12}>
                            <Button
                                block
                                color="red"
                                className="btn btn-sm text-nowrap"
                                size="md"
                                name="registrar"
                                id="registrar"
                                onClick={() => test2()}
                            >
                                <i className="fas fa-edit mr-2"></i>
                                test 2 POST
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment >
    );
}

export default ModalAttendeeData;