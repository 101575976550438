import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Label } from "reactstrap";
import { swal_etm } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import emprendimiento from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import { setSession } from '../../helpers/authUtils';
import { useHistory } from "react-router-dom";

const Step3 = (props) => {

    const history = useHistory();

    const [roles, setRoles] = useState([]);
    const [rolInfo, setRolInfo] = useState([]);
    const [rol, setRol] = useState(1); //EMPRENDEDOR
    const [rolName, setRolName] = useState("EMPRENDEDOR"); //EMPRENDEDOR
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = async () => {
        const res = await API.get('/general/getRoles');
        if (res.data.state) {
            let listRoles = [];
            let listRolesInfo = [];
            res.data.roles.map((e, i) => {
                let profile = (i > 0) ? "profile_register" : "profile_register profile_register_active_white";
                let info = (i > 0) ? true : false;
                let img = "";

                switch (e.ROL) {
                    case 'EMPRENDEDOR':
                        img = emprendimiento;
                        break;
                    case 'MENTOR':
                        img = mentor;
                        break;
                    case 'INVERSIONISTA':
                        img = inversionista;
                        break;
                    default:
                        img = gerente;
                }

                listRoles.push(
                    <Col lg={3} md={6} sm={12} className="mb-2" id={`profile_${e.ID}`} key={i}>
                        <div className={profile + " nav-link text-center"} id={e.ID} onClick={() => changeRoleProfile(e.ID, e.ROL)}>
                            <img src={img} alt="" className="img-fluid w-50 rounded-circle" /> <br />
                            <Label className="h2 text-white animate__animated animate__fadeIn mt-4">{e.ROL.replace("/", " / ")}</Label>
                        </div>
                    </Col>
                );
                listRolesInfo.push(
                    <Col lg={12} className="text-center info" key={i} hidden={info} id={`info_${e.ID}`}>
                        <div className="ml-xl-5 mr-xl-5 pl-xl-5 pr-xl-5 ml-md-2 mr-md-2 pl-md-2 pr-md-2 ml-1 mr-1 pl-1 pr-1">
                            <Label className="h3 text-white text-center animate__animated animate__fadeIn"><span dangerouslySetInnerHTML={{ __html: e.DESCRIPCION }} ></span></Label>
                        </div>
                    </Col>
                );
            });

            setRoles(listRoles);
            setRolInfo(listRolesInfo);
        }
    }

    const changeRoleProfile = (e, name) => {
        setRol(e);
        setRolName(name);
        const elements = document.getElementsByClassName("nav-link");
        if (elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove("profile_register_active_white");
            }
        }
        document.getElementById(e).classList.add("profile_register_active_white");

        const info_elements = document.getElementsByClassName("info");
        if (info_elements.length > 0) {
            for (var i = 0; i < info_elements.length; i++) {
                info_elements[i].setAttribute("hidden", true);
            }
        }
        document.getElementById("info_" + e).hidden = false;
    }

    const saveRol = async () => {
        setLoading(true);
        const res = await API.post("/register/setUserRole", { 
            token: props.getToken(),
            role_id: rol,
            device: "PC"
        });

        if(res.data.state){
            setTimeout(function () { 
                props.saveStep(3);
                setSession(res.headers, res.data.user);
                history.push('/home');
            }, 2000);
        }else{
            swal_etm(res.data.msg);
        }
    }

    return (
        <Fragment>
            <Row>
                <Col className="bg_onboarding_right text-center" md={12}>
                    <Label className="h1 mt-5"><p className="animate__animated animate__fadeIn" > <b className="text-white">Elige tu perfil</b></p></Label>
                    <Row className="m-5 text-center">
                        {roles}
                    </Row>
                    <Row className="m-md-5 m-2">
                        {rolInfo}
                    </Row>
                    <Row className="m-5">
                        <Col className="text-center ml-md-5 mr-md-5 pl-md-5 pr-md-5">
                            <LoadBtn 
                                className="btn-lg etm_btn_white animate__animated animate__fadeIn"
                                color="white"
                                handle={ (e) => saveRol(e) }
                                loading={ loading }
                                title={ <b>¡ CONFIRMAR PERFIL <u>{ rolName }</u> !</b> }
                                text="Configurando perfil..."
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Step3);