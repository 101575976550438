import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Input } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Container from 'reactstrap/lib/Container';
import { API } from '../../helpers/axios';
import { Link } from 'react-router-dom';
import { capitalize, getNavigatorAgent } from "../../helpers/etmmeetUtils";
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Experience = (props) => {
    const [keyword, setKeyword]     = useState("");
    const [cards, setCards]         = useState([]);
    const [title, setTitle]         = useState("");
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        changeTitle();
    }, [props.type]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            search(keyword);
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [keyword]);

    let sliderToShow = 1;
    
    switch(getNavigatorAgent()){
        case "Android":
            sliderToShow = 1;
            break; 
        case "webOS":
            sliderToShow = 2;
            break; 
        case "iPhone":
            sliderToShow = 1;
            break; 
        case "iPod":
            sliderToShow = 1;
            break; 
        case "iPad":
            sliderToShow = 2;
            break; 
        case "BlackBerry":
            sliderToShow = 1;
            break; 
        case "PC":
            sliderToShow = 3;
            break; 
    }
    
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: sliderToShow,
        speed: 500,
        swipeToSlide: true,
        appendDots: dots => (
            <div></div>
          ),
          customPaging: i => (
            <div></div>
          )
    };

    const search = async ( key ) => {
        const res = await API.post('/searchLandingBanner', { key });
        setCards([]);
        if(res.data.state){
            let list = [];
            let cont = 0;
            res.data.result.map( async (e, i) => {
                let rol_img             = '';
                let rol                 = '';
    
                switch(e.PERFIL){
                    case "ADMINISTRADOR":
                        rol_img = gerente;
                        rol     = "Gerente-Ejecutivo";
                        break;
                    case "GERENTE/EJECUTIVO":
                        rol_img = gerente;
                        rol     = "Gerente-Ejecutivo";
                        break;
                    case "INVERSIONISTA":
                        rol_img = inversionista;
                        rol =    "Inversionista";
                        break;
                    case "MENTOR":
                        rol_img = mentor;
                        rol     = "Mentor";
                        break;
                    case "EMPRENDEDOR":
                        rol_img = emprendedor;
                        rol     = "Emprendedor";
                        break;
                }
                
                list.push(
                    <Col key={ cont++ } className={ (i < 1) ? "mb-3 mt-3 pt-2 animate__animated animate__fadeInUp carousel-item active" : "mb-3 mt-3 pt-2 animate__animated animate__fadeInUp carousel-item" }>
                        <div className="etm_card h-100">
                            <div className="profile-user-wid mb-2 text-center">
                                <img src={ e.FOTO } alt="" className="landing_profile_header_img_cards img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"/> 
                            </div>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={4}>
                                        <div className="profile-user-wid mt-2 text-center">
                                            <img src={ e.FOTO_EMPRESA } alt="" className="landing_profile_header_img_cards company_home fix_img"/>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <h5 className="text-truncate etm_text_blue etm_link_blue text-lg-left text-center">{ capitalize(e.NOMBRE) }</h5>
                                        {
                                            (e.EMPRESA !== null && e.EMPRESA !== "") ? <h6 className="text-truncate text-muted text-lg-left text-center">{ e.EMPRESA.toUpperCase() }</h6> : <h6 className="text-truncate text-white"><small>-</small></h6>
                                        }
                                        {
                                            (e.INDUSTRIA !== null && e.INDUSTRIA !== "") ? <h6 className="text-truncate text-muted text-lg-left text-center">{ e.INDUSTRIA.toUpperCase() }</h6> : <h6 className="text-truncate text-white"><small>-</small></h6>
                                        }
                                        {
                                            (e.CARGO !== null && e.CARGO !== "") ? <h6 className="text-truncate text-muted text-lg-left text-center">{ e.CARGO.toUpperCase() }</h6> : <h6 className="text-truncate text-white"><small>-</small></h6>
                                        }
                                    </Col>
                                    <Col lg={12}>
                                        <hr/>
                                        <Row>
                                            <Col xs={6} className="text-right">
                                                <div>
                                                    <img src={ rol_img } className="landing_profile_header_img_profile img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img"/>
                                                </div>
                                            </Col>
                                            <Col xs={6} className="text-left">
                                                <h6 className="text-truncate font-weight-medium"><b>Perfil</b></h6>
                                                <h6 className="text-truncate font-weight-medium">{ rol }</h6>
                                            </Col>
                                        </Row>
                                        <Col xs={12}>
                                            <Row className="mt-1">
                                                <Col xs={12} className="text-center">
                                                    <h6 className="text-muted"><span className="etm_badge_blue">Con tiempo disponible</span></h6>
                                                </Col>
                                            </Row>
                                            <hr/>
                                        </Col>
                                        <Col className="d-flex justify-content-center mb-2">
                                            <Link to='/login'><button className="btn btn-primary etm_btn pl-5 pr-5">Reunámonos!</button></Link>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </Col>
                );

                if(res.data.result.length === 1 || res.data.result.length === 2){
                    list.push(<Col key={ cont++ } className={ "mb-3 mt-3 pt-2 animate__animated animate__fadeInUp carousel-item" }></Col>);
                }

                if(res.data.result.length === 1){
                    list.push(<Col key={ cont++ } className={ "mb-3 mt-3 pt-2 animate__animated animate__fadeInUp carousel-item" }></Col>);
                }
            });
            setCards(list);
            setNoResults(false);
        }else{
            setNoResults(true);
        }
    } 
    
    const changeTitle = async ( key ) => {
        if(props.type === "experto"){
            setTitle(<span>¡Descubre cómo se verá tu perfil!</span>);
        }else if(props.type === "emprendedor"){
            setTitle(<span>¡Busca ahora mismo a los expertas/os que están en <b className="etm_text_blue">EtM</b><i>meet</i>!</span>);
        }else{
            setTitle(<span>Navega libremente y conoce a todos los expertas/os disponibles</span>);
        }
    }

    return (
        <Fragment>
            <Container>
                <Row className="pt-5 pb-5 landing_profile_header_experience">
                    <Col xs={12} className="text-center pb-3 pt-md-0 pt-5 animate__animated animate__fadeInLeft">
                        <p className="h1 landing_profile_header_title">Vive la experiencia</p>
                    </Col>
                    <Col xs={12} className="text-center animate__animated animate__fadeInLeft">
                        <p className="h2 landing_profile_header_subtitle_experience">
                            { title }
                        </p>
                    </Col>
                    <Col xs={12} className="text-center pt-3">
                        <div className="input-group">
                            <Input onChange={ (e) => setKeyword(e.target.value) } className="landing_profile_header_input" name="keyword" id="keyword" type="text" placeholder="Palabra clave"/>
                            <div className="input-group-prepend">
                                <span className="input-group-text landing_profile_header_input_group pointer etm_text_blue"><i className="fas fa-search fa-2x"></i></span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="text-center mt-3">
                        {
                            (noResults) ?
                                <h3 className="mt-2">No encontramos resultados para tu búsqueda. Intenta otra vez con otra palabra!<br/><i className="fas fa-frown fa-2x etm_text_blue mt-2"></i></h3>
                            :   <Slider {...settings} className="landing_profile_header_experience_slider">
                                    { cards }
                                </Slider>
                        }
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default withNamespaces()(Experience);