import React, { Fragment } from 'react';

const LoadBtn = ({ handle, loading, title, icon, familyIcon, className, color, text, block, disabled, outline }) => {

    if(color === undefined || color === null){
        color = "primary";
    }
    
    if(familyIcon === undefined || familyIcon === null){
        familyIcon = "fas fa";
    }

    let clase = "";

    if(icon !== undefined && icon !== null){
        if(familyIcon === undefined || familyIcon === null){
            clase = `fas fa-${icon} mr-2`;
        }else{
            clase = `${familyIcon}-${icon} mr-2`;
        }
    }
    
    if(block === undefined || block === null){
        block = `btn-block`;
    }else{
        block = (block) ? `btn-block` : ``;
    }
    
    if(outline === undefined || outline === null){
        outline = `etm_btn`;
    }else{
        color = (outline) ? `outline-${color}` : `${color}`;
        outline = (outline) ? `etm_btn_outline` : `etm_btn`;
    }

    let btn_class = `btn btn-${color} ${block} ${className} ${outline} `;
    
    return (
        <Fragment>
            <div>
                <button onClick={handle} className={btn_class} type="submit" disabled={(loading === true || disabled === true ) ? true : false}>
                    {
                        loading ? <Fragment><div className={`spinner-border spinner-border-sm m-1 mr-2 ${ (color !== "white") ? "text-white" : "" }`} role="status" />{ text }</Fragment> : <div><i className={clase}></i> {title}</div>
                    }
                </button>
            </div>
        </Fragment>
    );
}

export default LoadBtn;

