import React, { useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { swal_etm } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";

const ModalNewJobtitle = (props) => {

    const [loading, setLoading] = useState(false);
    const [newJobtitle, setNewJobtitle] = useState("");

    const preSave = () => {
        setLoading(true);
        if (newJobtitle.length <= 2) {
            swal_etm('Debes ingresar un nombre válido');
            setLoading(false);
            return;
        }
        save();
    }

    const save = async () => {
        const res = await API.post('/register/newJobtitle', { name: newJobtitle });
        if(res.data.state) {
            swal_etm(res.data.msg, 'success');
            props.getJobTitles();
            props.setPersonalJobTitle({ value: res.data.jobtitle.ID, label: res.data.jobtitle.CARGO });
            props.setShowJobTitle(false);
        }else{
            swal_etm(res.data.msg);
        }
        setLoading(false);
    }

    return (
        <Modal isOpen={ props.showJobTitle } size="lg" scrollable={true}>
            <ModalHeader>
                <FormGroup check>
                    <Label className="h3">Nuevo cargo</Label>
                </FormGroup>
            </ModalHeader>
            <ModalBody>
                <FormGroup check>
                    <Label className="h5">
                        <p className="mb-0 text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Crear un nuevo registro es bastante sencillo, sólo debes completar los siguientes datos y ya estará disponible.</p>
                    </Label>
                    <Label className="h5 mt-4">Nombre del cargo</Label>
                    <Input onChange={(e) => setNewJobtitle(e.target.value)} className="form-control etm_register_input" type="text" placeholder="Ingresa el nombre del cargo" />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setShowJobTitle(!props.showJobTitle)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                <LoadBtn className="pl-5 pr-5" color="primary" block={ false } title="Guardar nuevo registro" text="Guardando..." handle={ preSave } loading={loading}/>
            </ModalFooter>
        </Modal>
    );
}

export default (ModalNewJobtitle);