import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import "../../assets/scss/etm_landing.scss";
import Nav from "../../components/Landing/Nav";
import Header from "../../components/Landing/Header";
import HeaderProfile from "../../components/Landing/HeaderProfile";
import Testimonials from "../../components/Landing/Testimonials";
import AboutUs from "../../components/Landing/AboutUs";
import Sponsor from "../../components/Landing/Sponsor";
import Contact from "../../components/Landing/Contact";
import Footer from "../../components/Landing/Footer";
import Menu from "../../components/Landing/Menu";

const Landing = (props) => {
    const[menu, setMenu]            = useState(null);
    const[reference, setReference]  = useState('');

    useEffect(() => { 
        getReference();
    }, []);

    const getReference = async () => {
        let ref = "";
        if(props.location.search.length > 0){
            ref = props.location.search.substring(1, props.location.search.length).split('?');
            ref = ref[0];
        }else if(props.location.pathname.length > 0){
            ref = props.location.pathname.split('/');
            ref = ref[2];
        }
        setReference((ref === undefined) ? "" : ref);
    }

    return (
        <Fragment>
            <Nav menu={ menu } setMenu={ setMenu } reference={ reference }/>
            <Menu menu={ menu } setMenu={ setMenu }/>
            <Header reference={ reference }/>
            <HeaderProfile reference={ reference }/>
            <Row className="bg_testimonials_aboutus">
                <Col>
                    <Testimonials/>
                    <AboutUs/>
                </Col>
            </Row>
            <Sponsor/>
            <Contact/>
            <Footer reference={ reference }/>
        </Fragment>
    );
}

export default withNamespaces()(Landing);