import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import Logo from "../../assets/images/etmmeet/landing/logo_negro.png";

const Header = (props) => {
    return (
        <Fragment >
            <Row className="header_title mt-xl-3 mt-lg-0 mt-0 ">
                <Container className="header_body my-auto">
                    <Row className="justify-content-center ">
                        <Col lg={6} md={12} className="my-auto text-lg-left text-center animate__animated animate__zoomIn pt-lg-0 pt-md-3 pt-0">
                            <p className="h2 header_txt_title mt-lg-0 mt-5 pt-lg-0 pt-5 mt-0 pb-lg-0 pb-md-3 pb-3">Generamos oportunidades conectando al ecosistema del emprendimiento</p>
                            <p className="h5 header_txt_subtitle pb-lg-0 pb-3"><b>Agenda reuniones GRATIS y descubre redes</b></p>
                        </Col>
                        <Col lg={6} md={12} className="my-auto text-center animate__animated animate__fadeInRight">
                            <img className="header_img img-fluid mt-lg-0 mt-md-3 mt-5 pb-lg-0 pb-5 ml-md-5 ml-0" src={ Logo } alt="etmmeet"/>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Fragment>
    );
}

export default (Header);


