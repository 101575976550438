import React, { useState } from 'react';
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { swal_etm, pathS3, selectCustomStyle } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import UploadImageFile from "../Utils/UploadImageFile";

const ModalNewCompany = (props) => {

    const [loading, setLoading] = useState(false);
    const [newCompany, setNewCompany] = useState({ name: "", url: "" });
    const [newCompanyImg, setNewCompanyImg] = useState([]);
    const [newCompanyIndustry, setNewCompanyIndustry] = useState(null);

    const preSave = () => {
        setLoading(true);
        if (newCompany.name.length <= 3) {
            swal_etm('Debes ingresar un nombre válido');
            setLoading(false);
            return;
        }
        if (newCompany.name.url <= 10) {
            swal_etm('Debes ingresar un sitio web o link válido');
            setLoading(false);
            return;
        }
        if (newCompanyIndustry === null || newCompanyIndustry.value < 1) {
            swal_etm('Debes seleccionar una industria válida');
            setLoading(false);
            return;
        }
        save();
    }

    const save = async () => {
        const res = await API.post('/register/newCompany', {
            name: newCompany.name,
            url: newCompany.url,
            img: (newCompanyImg.length > 0) ? newCompanyImg[0] : "",
            industry: newCompanyIndustry.value
        });
        if (res.data.state) {
            swal_etm(res.data.msg, 'success');
            props.getCompanies();
            props.setPersonalCompany({ value: res.data.company.ID, img: res.data.company.FOTO, label: res.data.company.EMPRESA });
            props.setShowCompany({ list: props.showCompany.list, new: !props.showCompany.new });
            cleanData();
        } else {
            swal_etm(res.data.msg);
        }
        setLoading(false);
    }

    const cleanData = () => {
        setLoading(false)
        setNewCompany({ name: "", url: "" });
        setNewCompanyImg("");
        setNewCompanyIndustry(null);
    }

    return (
        <Modal isOpen={props.showCompany.new} size="xl" scrollable={true}>
            <ModalHeader>
                <FormGroup check>
                    <Label className="h3">Nueva empresa u organización</Label>
                </FormGroup>
            </ModalHeader>
            <ModalBody>
                <FormGroup check>
                    <Label className="h5">
                        <p className="mb-0 text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Crear un nuevo registro es bastante sencillo, sólo debes completar los siguientes datos y ya estará disponible.</p>
                    </Label>
                    <Label className="h5 mt-4">Nombre de la empresa u organización</Label>
                    <Input onChange={(e) => setNewCompany({ name: e.target.value, url: newCompany.url, img: newCompany.img })} className="form-control etm_register_input" type="text" placeholder="Ingresa el nombre de la organización" />
                </FormGroup>
                <FormGroup check>
                    <Label className="h5 mt-4"> ¿ A qué industria pertenece ?</Label>
                    <br />
                    <Select
                        placeholder={"Selecciona una categoría o industria"}
                        value={newCompanyIndustry}
                        onChange={setNewCompanyIndustry}
                        options={props.listIndustries}
                        className="etm_input_select"
                        styles={selectCustomStyle()}
                        noOptionsMessage={() => 'No hay mas opciones'}
                        maxMenuHeight={180}
                    />
                </FormGroup>
                <FormGroup check>
                    <Label className="h5 mt-4"> ¿ Cuál es el sitio web de tu organización ? <small>( Si no tienes sitio web, puedes colocar el link de alguna red social. )</small></Label>
                    <Input onChange={(e) => setNewCompany({ name: newCompany.name, url: e.target.value, img: newCompany.img })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu organización" />
                </FormGroup>
                <FormGroup check>
                    <Label className="h5 mt-4"> Ingresa el logo de tu empresa u organización <small>( opcional)</small></Label>
                    <div className="animate__animated animate__fadeIn text-center">
                        <UploadImageFile
                            buttonText={"Click aquí para subir una foto"}
                            pathS3={pathS3("COMPANY")}
                            singleImage={true}
                            maxFileNumber={1}
                            handle={setNewCompanyImg}
                        />
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setShowCompany({ list: props.showCompany.list, new: !props.showCompany.new })}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                <LoadBtn className="pl-5 pr-5" color="primary" block={false} title="Guardar nuevo registro" text="Guardando..." handle={preSave} loading={loading} />
            </ModalFooter>
        </Modal>
    );
}

export default (ModalNewCompany);