import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Input } from "reactstrap";
import { withRouter } from "react-router-dom";

 const ModalShareData = ( props ) => {

    const [showModal, setShowModal] = useState(false);
    const [urlZoom, setUrlZoom] = useState("");

    useEffect(() => { 
        setShowModal(props.showModal);
    }, [props.showModal]);

    useEffect(() => { 
        setUrl();
    }, [props.compatibilityUrl]);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const setUrl = () => {
        if(props.compatibilityUrl.length > 0){
            setUrlZoom(props.compatibilityUrl);
        }
    }
    
    return (
        <Fragment>
            <Modal isOpen={ showModal } size="md" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="p-4 text-center">
                                <h4 className="font-16 m-b-15">¿Estás seguro?</h4>
                                <h4 className="font-16 m-b-15 mt-3">Serás redirigido directamente a la reunión vía <span className="etm_text_blue"><b>Twilio.</b></span></h4>
                            </div>
                            <div className="p-1 text-center">
                                {/* <a href={urlZoom} className="mt-2 btn btn-primary btn-lg pl-3 pr-3"><i className="fas fa-video mr-2"></i>Abrir <b>Zoom</b></a> */}
                                <a href={urlZoom} className="mt-2 btn btn-primary btn-lg pl-3 pr-3"><i className="fas fa-video mr-2"></i>Ir a la reunión!</a>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <h5 className="etm_link_blue pointer" onClick={ handleModal }>Cerrar</h5>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalShareData);