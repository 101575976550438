import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label, Container, Button } from "reactstrap";
import { API } from '../../helpers/axios';
import {
    capitalize, cleanFacebook, cleanInstagram, cleanTwitter,
    cleanLinkedin, validateRol, swal_etm, getDate,
    spanishDate, getLocalStorage
} from '../../helpers/etmmeetUtils';
import LoadBtn from "../../components/Utils/LoadBtn";
import LoadScreen from "../../components/Utils/LoadScreen";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ModalReserveHours = (props) => {

    let nextFreeDay = new Date();
    let strikeDate = new Date();
    let endDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate());
    strikeDate.setDate(strikeDate.getDate());
    endDate.setMonth(endDate.getMonth() + 6)

    const [loading, setLoading] = useState({ data: true, btn: false });
    const [validateButton, setValidateButton] = useState(true);
    const [strikeClass, setStrikeClass] = useState("etm_calendar_strike_text");
    const [listHoursStart, setListHoursStart] = useState([]);
    const [hideStart, setHideStart] = useState(true);
    const [startTime, setStartTime] = useState("06:00");

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [days, setDays] = useState({
        L: false,
        M: false,
        X: false,
        J: false,
        V: false,
        S: false
    });
    const [numberOfMeets, setNumberOfMeets] = useState(1);
    const [selectedDuration, setSelectedDuration] = useState(null);

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    useEffect(() => {
        refresh();
        setStrikeClass("etm_calendar_strike_text");
    }, []);

    const handleAdd = () => {
        setDays({
            L: monday,
            M: tuesday,
            X: wednesday,
            J: thursday,
            V: friday,
            S: saturday
        })
        let dias = 0
        for (var element in days) {
            dias = (days[element]) ? dias + 1 : dias
        }

        if (numberOfMeets < dias) {
            swal_etm(`Los días seleccionados supera el número de reuniones. Deben ser maximo ${numberOfMeets} dias`);
            setLoading(false);
            return;
        }
        reserveHours()
    }

    const reserveHours = async () => {
        const datos = {
            date: getDate(selectedDate),
            hour: startTime,
            numberOfMeets: parseInt(numberOfMeets),
            days,
            duration: selectedDuration,
            meet_id: props.meetID 
        }
        console.log(datos)

        const res = await API.post('/meeting/expert/reserveHours', datos);
        console.log(res)
    }

    const refresh = () => {
        getHoursList();
    }

    const getHoursList = async (e) => {
        const res = await API.get('/getHoursList');
        setListHoursStart([]);
        let listStart = [];

        if (res.data.state) {
            res.data.hours.map((e, i) => {
                listStart.push(<option key={`start_${i}`} value={e.hour}> {e.hour} </option>);
            });
        }
        setListHoursStart(listStart);
    }

    const changeDay = (dia) => {
        cleanDay()
        if (getDate(dia) < getDate(nextFreeDay)) {
            swal_etm("Para donar tiempo se requieren al menos 48 horas de anticipación.");
            return;
        }
        const semana = ['D', 'L', 'M', 'X', 'J', 'V', 'S']
        let fecha = new Date(dia)
        selectDay(semana[fecha.getDay()], true)

        setHideStart(false);
    }

    const cleanDay = () => {
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
    }

    const selectDay = (day, set = null) => {
        switch (day) {
            case 'L':
                (set === null) ?
                    setMonday(!monday)
                    :
                    setMonday(set)
                break;
            case 'M':
                (set === null) ?
                    setTuesday(!tuesday)
                    :
                    setTuesday(set)
                break;
            case 'X':
                (set === null) ?
                    setWednesday(!wednesday)
                    :
                    setWednesday(set)
                break;
            case 'J':
                (set === null) ?
                    setThursday(!thursday)
                    :
                    setThursday(set)
                break;
            case 'V':
                (set === null) ?
                    setFriday(!friday)
                    :
                    setFriday(set)
                break;
            case 'S':
                (set === null) ?
                    setSaturday(!saturday)
                    :
                    setSaturday(set)
                break;
            default:
                console.log(`Valor no considerado: ${day}`)
                break;
        }
    }

    return (
        <Fragment>
            <Modal isOpen={props.modal} size={'xl'} scrollable={true} >
                <ModalHeader className="etm_modal_header">
                    <Label className="h4 ml-3 text-white">Aquí puedes coordinar una nueva reunión con <b>{props.userName}</b></Label>
                    <Label className="etm_modal_header_close_solicitud_xl pointer" onClick={() => props.setModal(false)}><i className="far fa-times-circle fa-lg"></i></Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    <Row>
                        <Col lg="6" className="mt-4">
                            <div className="calendar text-center">
                                <Label className="h5">Selecciona desde que días comenzarán las reuniones</Label>
                                {/* Controls */}
                                <div className="controls ml-2 mr-2">
                                    <div>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                        <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                                {/* Calendar head with day names */}
                                <div className="calendar_head">
                                    {
                                        shortDayNames.map(day => (
                                            <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                {spanishDate(day)}
                                            </div>
                                        ))
                                    }
                                </div>

                                {/* Calendar */}
                                <div className="calendar_body">
                                    {
                                        items.map(day => {
                                            const activeClass = !day.active ? "inactive" : "";
                                            let selectedClass = day.selected ? "selected" : "";
                                            const strike = (day.fullDate < strikeDate) ? strikeClass : "";
                                            return (
                                                <div
                                                    className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                    key={day.fullDate}
                                                    onClick={() => {
                                                        selectDate(day.fullDate);
                                                        setSelectedDate(day.fullDate);
                                                        changeDay(day.fullDate);
                                                    }}
                                                >
                                                    {day.date}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="mt-2">
                            <Row className="mt-3" hidden={hideStart}>
                                <Col lg={12} className="mb-3">
                                    <FormGroup >
                                        <Label className="h4">
                                            <b className="etm_ornate_text_3 mr-1">
                                                <i className="fas fa-calendar-alt"></i>
                                            </b><span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup >
                                                <Label className="h4">
                                                    <b className="etm_ornate_text_3 mr-3">
                                                        <i className="fas fa-clock"></i>
                                                    </b> 
                                                    ¿Desde qué hora?
                                                </Label>
                                                <Input type="select" name="start" onChange={(e) => setStartTime(e.target.value)} className="form-control etm_input_select" value={startTime}>
                                                    {listHoursStart}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={12} className="mt-3">
                                    <Row className="mt-3">
                                        <Col xs={12} className={`mt-2`}>
                                            <FormGroup>
                                                <Label className="h4">
                                                    <b className="etm_ornate_text_3 mr-3">
                                                        <i className="fas fa-calendar-alt"></i>
                                                    </b>
                                                    ¿Periodicidad de las reuniones?
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-center">
                                            <Row className="text-left">
                                                <Col md={3} hidden={!validateRol(['MENTOR', 'ADMINISTRADOR'])}>
                                                    <FormGroup >
                                                        <Label className="h4"><b className="etm_ornate_text_3 mr-3">Cantidad:</b> </Label>
                                                        <Input
                                                            type="select"
                                                            name="start"
                                                            onChange={(e) => setNumberOfMeets(e.target.value)}
                                                            className="form-control etm_input_select mt-2 mb-3"
                                                            value={numberOfMeets}
                                                        >
                                                            <option values={1}>1</option>
                                                            <option values={2}>2</option>
                                                            <option values={3}>3</option>
                                                            <option values={4}>4</option>
                                                            <option values={5}>5</option>
                                                            <option values={6}>6</option>
                                                            <option values={7}>7</option>
                                                        </Input>
                                                    </FormGroup>
                                                    <Label className="h5 text_muted"><small className="etm_ornate_text_3">* maximo 7 reuniones</small></Label>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3 pl-2">Días:</b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(monday) ? `active` : ``}`}
                                                        onClick={() => selectDay('L')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">L</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3"></b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(tuesday) ? `active` : ``}`}
                                                        onClick={() => selectDay('M')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">M</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3"></b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(wednesday) ? `active` : ``}`}
                                                        onClick={() => selectDay('X')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">X</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3"></b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(thursday) ? `active` : ``}`}
                                                        onClick={() => selectDay('J')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">J</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3"></b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(friday) ? `active` : ``}`}
                                                        onClick={() => selectDay('V')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">V</Label>
                                                    </div>
                                                </Col>
                                                <Col xs={2} sm={1} className="pl-1 pr-1">
                                                    <Label className="h4"><b className="etm_ornate_text_3 mr-3"></b> </Label>
                                                    <div
                                                        className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(saturday) ? `active` : ``}`}
                                                        onClick={() => selectDay('S')}
                                                    >
                                                        <Label className="h5 mt-2 pointer">S</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className="text-left mt-3">
                                            <FormGroup>
                                                <Label className="h4">
                                                    <i className="fas fa-clock etm_ornate_text_3 mr-2"></i>Elige la duración de la reunión<br />
                                                </Label>
                                                <br />
                                                <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 20) ? `active` : ``}`} onClick={() => setSelectedDuration(20)}>
                                                    <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>20 minutos</Label>
                                                </div>
                                                <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 30) ? `active` : ``}`} onClick={() => setSelectedDuration(30)}>
                                                    <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>30 minutos</Label>
                                                </div>
                                                <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 45) ? `active` : ``}`} onClick={() => setSelectedDuration(45)}>
                                                    <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>45 minutos</Label>
                                                </div>
                                                <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 60) ? `active` : ``}`} onClick={() => setSelectedDuration(60)}>
                                                    <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>1 hora</Label>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} className="text-left mt-5">
                                    <Label className="h6 text-muted"><i className="fas fa-globe-americas etm_ornate_text_3 mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                    <LoadBtn
                        className={`pl-5 pr-5 ${(validateButton) ? `btn-lg` : ``} `}
                        color="success"
                        block={false}
                        title="¡ Agendar Reuniones !"
                        icon="check-circle"
                        text="Creando solicitud..."
                        loading={loading.btn}
                        handle={handleAdd}
                        disabled={(validateButton) ? false : true}
                        outline={(validateButton) ? false : true}
                    />
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}


export default ModalReserveHours;