import React, { Fragment, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, Row, Col, UncontrolledTooltip, Label, Button } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { API } from '../../../helpers/axios';

const NotificationDropdown = (props) => {

	const user = JSON.parse(localStorage.getItem('user'));
	const [menu, setMenu] = useState(false);
	const [meet, setMeet] = useState(false);
	const [meetInfo, setMeetInfo] = useState(false);
	const [dataName1, setDataName1] = useState('');
	const [dataName2, setDataName2] = useState('');
	const [dataPicture, setDataPicture] = useState('');
	let count_notification = 3;

	useEffect(() => {
		getCurrentMeet();
		setInterval(function () {
			getCurrentMeet();
		}, 10 * 1000);
	}, []);

	const getCurrentMeet = async () => {
		const res = await API.get('/current_meet', { params: { user_id: user.id } });
		if (res.data.state) {
			setMeet(true);
			
			if(props.location.pathname !== '/meet'){
				setMeetInfo(true);
			}else{
				setMeet(false);
			}

			if(user.id === res.data.meet.user_id){
				setDataName1(res.data.meet.user_shortname);  
				setDataName2(res.data.meet.manager_shortname);
				setDataPicture(res.data.meet.manager_picture);
			}else{
				setDataName1(res.data.meet.manager_shortname);  
				setDataName2(res.data.meet.user_shortname);
				setDataPicture(res.data.meet.user_picture);
			}
		} else {
			setMeet(false);
			setMeetInfo(false);
			setDataName1('');  
			setDataName2('');
			setDataPicture('');
		}
	}

	return (
		<Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="dropdown d-inline-block"
				tag="li">
				<Link to="/meet" hidden={!meet} id="go_meet">
					<DropdownToggle
						className="btn header-item noti-icon waves-effect"
						tag="button" id="page-header-notifications-dropdown">
						<i className="fas fas fa-calendar-alt bx-tada mr-5 pr-5 text-warning"></i>
						<span className="badge badge-pill etm_bg_header_rol">¡Meet en Progreso!</span>
					</DropdownToggle>
				</Link>
				<UncontrolledTooltip
					innerClassName="bg-primary text-white"
					delay={0}
					placement="bottom"
					target="go_meet"
				> 
					Click aquí para acceder a la reunión!
				</UncontrolledTooltip>

				<DropdownToggle hidden={true}
					className="btn header-item noti-icon waves-effect"
					tag="button" id="page-header-notifications-dropdown">
					<i className="bx bx-bell bx-tada"></i>
					<span className="badge badge-danger badge-pill">{count_notification}</span>
				</DropdownToggle>
			</Dropdown>
			<div className="etm_popups_meet_now" hidden={ !meetInfo }>
				<Row>
					<Col lg={12}>
						<span className="badge badge-pill etm_bg_header_rol">¡Meet en Progreso!</span>
						<i className="fas fas fa-calendar-alt bx-tada mr-5 pr-5 text-warning"></i>
						<div className="text-center">
							<img src={dataPicture} className="avatar-sm rounded-circle mb-4 float-lg-none fix_img" alt="img" />
						</div>
						
					</Col>
					<Col lg={12}>
						<Label>Hola <b>{ dataName1 }!</b><br />Comenzó tu reunión con <b className="etm_text_blue">{ dataName2 }</b></Label>
					</Col>
					<Col lg={12}>
						<Link to="/meet">
							<Button color="primary" block={ true } size="lg" className="pt-1 pb-1"><i className="etm_btn fas fa-share"></i> Ir a la reunión</Button>
						</Link>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
}

export default withRouter(NotificationDropdown);