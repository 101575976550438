import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import { API } from '../../helpers/axios';
import { isMobile } from "../../helpers/etmmeetUtils";

const Logout = (props) => {
    const ls =JSON.parse(localStorage.getItem('user')); 

    const logout = async () => {
        let type = "PC";
        if(isMobile()){
            type = "MOBILE";
        }
        const res = await API.post('/logout', { user_id: ls.id, type});
        if(res.data.state){
            console.log(res.data.msg);
        }
        localStorage.clear();
        props.history.push('/login');
        window.location.reload(false);
    }

    useEffect(() => {
        logout();
    }, []);

    useEffect(() => {
        props.logoutUser(props.history);
    });

    return (
        <></>
    );
}

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);
