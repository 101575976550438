import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import { capitalize, cleanLinkedin, cleanFacebook, cleanInstagram, cleanTwitter } from "../../helpers/etmmeetUtils";
import LoadScreen from "../../components/Utils/LoadScreen";

const PublicProfile = ( props ) => {
    const[rend, setRend] = useState("");

    useEffect(() =>{
        renderize();
    }, [props.dataUser]);

    const renderize = () => {
        if(props.dataUser.name !== undefined && props.dataUser.name !== null){
            let startups = [];
            if(props.dataUser.startups !== undefined && props.dataUser.startups !== null){
                props.dataUser.startups.map((e, i) => {
                    startups.push(  
                        <Col key={i} className="text-center">
                            <Col lg={12}>
                                <div className="text-center">
                                    <h4 className="text-secondary"> { e.name } </h4>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="text-center">
                                    <h4 className="text-secondary">
                                        <a className="etm_text_blue pointer" href={`https://${e.web_page.replace("https://", "").replace("http://", "")}`} target="_blank">{e.web_page}</a>
                                    </h4>
                                </div>
                            </Col>
                            <Col lg={12} className="mb-3">
                                <div className="text-center">
                                    <h4 className="text-secondary"><small>{ e.description }</small></h4>
                                </div>
                            </Col>
                            <Col key={ i + 1 } className="text-center">
                                <img className="fix_img" src={ e.photo } alt="" width={ "20%" }/> 
                            </Col>
                        </Col>
                    );
                });
            }

            let rol_img = '';
            let rol = '';
            
            switch(props.dataUser.rol){
                case "INVERSIONISTA":
                    rol_img = inversionista;
                    rol     = "Inversionista";
                    break;
                case "MENTOR":
                    rol_img = mentor;
                    rol     = "Mentor";
                    break;
                case "EMPRENDEDOR":
                    rol_img = emprendedor;
                    rol     = "Emprendedor";
                    break;
                case "GERENTE/EJECUTIVO":
                    rol_img = gerente;
                    rol     = "Gerente-Ejecutivo";
                    break;
            }
            
            setRend(    
                <Row>
                    <Col xl={12} lg={12} className="text-right mb-3 mb-md-0">
                        <p className="text-muted mb-0 text-truncate">Ingresó el { props.dataUser.created_at }</p>
                    </Col>
                    <Col xl={6} lg={12}>
                        <div className="text-center">
                            <img src={ props.dataUser.profile_picture } alt="" className="rounded-circle public_profile_avatar fix_img mb-3"/>
                            <h1 className="etm_text_blue mb-3">{ capitalize(props.dataUser.name) } { capitalize(props.dataUser.last_name) }</h1>
                            <Row>
                                <Col xl={5} lg={12} md={12} xs={12} className="text-xl-right text-center">
                                    <img src={ props.dataUser.company.company_picture } alt="" className="etm_public_profile_company_logo fix_img"/> 
                                </Col>
                                <Col xl={7} lg={12} md={12} xs={12} className="text-xl-left text-center mt-2">
                                    <h5 className="etm_text_blue">{ props.dataUser.job_title }</h5>
                                    <h5 className="etm_text_blue">{ props.dataUser.company.company }</h5>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={6} lg={12}>
                        <Row>
                            <Col lg={3}></Col>
                            <Col lg={6}>
                                <Row className="etm_public_profile_indicators">
                                    {
                                        (props.dataUser.rol === "EMPRENDEDOR") ? 
                                            <Col>
                                                <Row>
                                                    <Col sm={ 12 } className="d-flex justify-content-center text-center mt-3">
                                                        <Card className="etm_public_profile_box_blue">
                                                            <CardBody className="text-center">
                                                                <h4 className="mb-0 text-white"> { (props.dataUser.startups.length >= 10) ? props.dataUser.startups.length : "0" + props.dataUser.startups.length } </h4>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm={ 12 } className="d-flex justify-content-center text-center">
                                                        <h6 className="font-weight-medium">Emprendimientos</h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        :   ""
                                    }
                                    {
                                        (props.dataUser.rol !== "EMPRENDEDOR") ? 
                                            <Col>
                                                <Row>
                                                    <Col sm={ 12 } className="d-flex justify-content-center text-center mt-3">
                                                        <Card className="etm_public_profile_box_cian">
                                                            <CardBody className="text-center">
                                                                <h4 className="mb-0 text-white"> { (props.dataUser.reuniones >= 10) ? props.dataUser.reuniones : "0" + props.dataUser.reuniones } </h4>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col sm={ 12 } className="d-flex justify-content-center text-center">
                                                        <h6 className="font-weight-medium">Reuniones Donadas</h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        :   ""
                                    }
                                </Row>
                            </Col>
                            <Col lg={3}></Col>
                        </Row>
                        <div className="pt-4 text-center">
                            <Row>
                                <Col lg={12} className="text-center mt-3">
                                    <label className="h5 text-muted">{ props.dataUser.description }</label>
                                </Col>
                                {
                                    (props.dataUser.rol === "MENTOR") ?
                                        (props.dataUser.expertise !== undefined && props.dataUser.expertise !== null) ?
                                        <Col lg={12} className="text-center mt-3">
                                            <h5 className="etm_text_blue">EXPERTIZ</h5>
                                            <h5 className="text-muted">{ props.dataUser.expertise }</h5>
                                        </Col>
                                        : ""
                                    : ""
                                }
                                <Col lg={12}>
                                    <ul className="list-inline mt-5 text-center">
                                        {
                                            (props.dataUser.rrss.facebook !== null && props.dataUser.rrss.facebook !== undefined && props.dataUser.rrss.facebook !== "") ?  
                                                <li className="list-inline-item mr-3">
                                                    <h4>
                                                        <a href={ cleanFacebook(props.dataUser.rrss.facebook) } target="_blank">
                                                            <i className="fab fa-facebook-square fa-2x facebook"></i>    
                                                        </a>
                                                    </h4>
                                                </li>
                                            :   ""
                                        }
                                        {
                                            (props.dataUser.rrss.instagram !== null && props.dataUser.rrss.instagram !== undefined && props.dataUser.rrss.instagram !== "") ?  
                                                <li className="list-inline-item mr-3">
                                                    <h4>
                                                        <a href={ cleanInstagram(props.dataUser.rrss.instagram) } target="_blank">
                                                            <i className="fab fa-instagram fa-2x instagram"></i>
                                                        </a>
                                                    </h4>
                                                </li> 
                                            :   ""
                                        }
                                        {
                                            (props.dataUser.rrss.linkedin !== null && props.dataUser.rrss.linkedin !== undefined && props.dataUser.rrss.linkedin !== "") ?  
                                                <li className="list-inline-item mr-3">
                                                    <h4>
                                                        <a href={ cleanLinkedin(props.dataUser.rrss.linkedin) } target="_blank">
                                                            <i className="fab fa-linkedin fa-2x linkedin"></i>
                                                        </a>
                                                    </h4>
                                                </li> 
                                            :   ""
                                        }
                                        {
                                            (props.dataUser.rrss.twitter !== null && props.dataUser.rrss.twitter !== undefined && props.dataUser.rrss.twitter !== "") ?  
                                                <li className="list-inline-item mr-3">
                                                    <h4>
                                                        <a href={ cleanTwitter(props.dataUser.rrss.twitter) } target="_blank">
                                                            <i className="fab fa-twitter-square fa-2x twitter"></i>
                                                        </a>
                                                    </h4>
                                                </li> 
                                            :   ""
                                        }
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <Row>
                            <hr className="lineGray"></hr>
                            <Col xs={6} className="text-right">
                                <div>
                                    <img src={rol_img} className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg" alt="img"/>
                                </div>
                            </Col>
                            <Col xs={6} className="text-left mt-3">
                                <h6 className="text-truncate font-weight-medium"><b>Perfil</b></h6>
                                <h6 className="text-truncate font-weight-medium">{rol}</h6>
                            </Col>
                        </Row>
                    </Col>
                    {
                        (props.dataUser.rol === "EMPRENDEDOR") ?
                            <Fragment>
                                <Col lg={12} className="mt-5 mb-3">
                                    <label className="h4 etm_text_blue">EMPRENDIMIENTOS</label>
                                </Col>
                                <Col lg={12}>
                                    <Row>
                                        { startups }
                                    </Row>
                                </Col>
                            </Fragment>
                        :   ""
                    }
                </Row>
            );
        }else{
            setRend(    
                <div>
                    <Row>
                        <Col>
                            <div className="text-center p-3 mt-5 mb-5">
                                <h1 className="text-primary mt-5 mb-5"> Este perfil no está disponible <i className="fas fa-frown"></i> </h1>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }
    } 

    return (
        <React.Fragment>
            <div className="overflow-hidden">
                <div className="etm_public_profile_bg">
                        <div className="p-1"></div>
                        <div className="m-3">
                            <Row>
                                <Col className="ml-sm-5 mr-sm-5">
                                    <Card className="text-center etm_public_profile_profile_border">
                                        <CardBody>
                                        {
                                            (props.loading) ?
                                                <LoadScreen text={ "Cargando perfil..." }/> 
                                            :   rend
                                        }
                                        </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default PublicProfile;
 