import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Label } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import ModalDonar from "../ModalDonar";
import { API } from '../../../helpers/axios';
import { capitalize, linkProfile, validateRol, cleanLinkedin, cleanFacebook, cleanInstagram, cleanTwitter, cleanYoutube, swal_etm, getLocalStorage } from "../../../helpers/etmmeetUtils";
import emprendedor from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../../assets/images/conecTime/design/Perfil-gerente.png";
import ModalSolicitarExperto from "../../../components/Home/Modal/ModalSolicitarExperto";
import ModalSolicitarEmprendedor from "../../../components/Home/Modal/ModalSolicitarEmprendedor";
import DrawCard from "./drawCard"

const Cards = (props) => {

    const [modalSolicitarEmprendedor, setModalSolicitarEmprendedor] = useState(false);
    const [modalSolicitarExperto, setModalSolicitarExperto] = useState(false);
    const [meetingType, setMeetingType] = useState('')
    const [expertToken, setExpertToken] = useState(null);
    const [cards, setCards] = useState([]);
    const [modalDonar, setModalDonar] = useState(false);
    const [dataModalDonar, setDataModalDonar] = useState({ id: "", name: "", shortname: "" });
    const [objetivo, setObjetivo] = useState("");
    const [emprendedorToken, setEmprendedorToken] = useState(null);
    const [startupId, setStartupId] = useState(null);

    useEffect(() => {
        if (props.dataSearch.length > 0) {
            drawCard()
        }
    }, [props.dataSearch])

    const toggleModalDonar = () => {
        setModalDonar(!modalDonar);
    }

    const drawCard = () => {
        let resultado = []
        props.dataSearch.map((v, i) => {
            resultado.push(
                <Col xl={4} key={i} className="mb-3 mt-3">
                    <DrawCard
                        datos={v.fields}
                        setExpertToken={setExpertToken}
                        setEmprendedorToken={setEmprendedorToken}
                        setStartupId={setStartupId}
                        setModalSolicitarEmprendedor={setModalSolicitarEmprendedor}
                        setModalSolicitarExperto={setModalSolicitarExperto}
                        modalDonar={modalDonar}
                        setModalDonar={setModalDonar}
                        toggleModalDonar={toggleModalDonar}
                        setDataModalDonar={setDataModalDonar}
                        setMeetingType={setMeetingType}
                        key={i}
                        index={i}
                    />
                </Col>
            )
        })

        setCards(resultado)
    }

    return (
        <Fragment>
            <Row className="mt-3">
                {cards}
            </Row>
            <ModalDonar
                modal={modalDonar}
                toggleModal={toggleModalDonar}
                token={getLocalStorage().token}
                dataManager={dataModalDonar}
                objetivo={objetivo}
            />
            <ModalSolicitarEmprendedor
                modal={(props.urlUserType === "EMPRENDEDOR" && props.urlUserToken !== null) ? true : modalSolicitarEmprendedor}
                setModal={setModalSolicitarEmprendedor}
                emprendedorToken={(props.urlUserType === "EMPRENDEDOR" && props.urlUserToken !== null) ? props.urlUserToken : emprendedorToken}
                startupId={(props.urlUserType === "EMPRENDEDOR" && props.urlUserToken !== null) ? 0 : startupId}
                setEmprendedorToken={setEmprendedorToken}
                setStartupId={setStartupId}
                meetingType={meetingType}
                setUrlUserType={props.setUrlUserType}
                setUrlUserToken={props.setUrlUserToken}
            />
            <ModalSolicitarExperto
                modal={(props.urlUserToken !== null && props.urlUserType === "EXPERTO") ? true : modalSolicitarExperto}
                setModal={setModalSolicitarExperto}
                expertToken={(props.urlUserType === "EXPERTO" && props.urlUserToken !== null) ? props.urlUserToken : expertToken}
                setExpertToken={setExpertToken}
                meetingType={meetingType}
                setUrlUserType={props.setUrlUserType}
                setUrlUserToken={props.setUrlUserToken}
            />
        </Fragment>
    );
}

export default withRouter(Cards);