import React, { Fragment, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Row, Col, Label, Container, Badge, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { API } from '../../../helpers/axios';
import { getLocalStorage } from '../../../helpers/etmmeetUtils';
import LoadScreen from "../../Utils/LoadScreen";
import logo from "../../../assets/images/etmmeet/general/logo_blanco.png";

const ModalChangeLog = (props) => {
    const ls = getLocalStorage()
    const [changelog, setChangelog] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        findChangelogs()
    }, []);

    useEffect(() => {
        if (open !== props.modal && props.type === 'last') {
            notSeeAgain()
            setOpen(true)
        }
    }, [props.modal]);

    const notSeeAgain = async () => {
        const res = await API.post('/general/notSeeAgainChangelog', { token: ls.token });
        if (res.data.state) {
            console.log(res.data)
        }
    }

    const findChangelogs = async () => {
        const res = await API.get('/general/getChangelog', { params: { type: props.type } });
        let list = []

        let i = 1
        if (res.data.changelog !== undefined && Object.keys(res.data.changelog).length > 0) {
            for (const version in res.data.changelog) {
                let html = []
                let nuevos = []
                let mejoras = []
                let correciones = []
                if (i > 1) {
                    html.push(<hr />)
                }
                html.push(<Label className={`h5 mt-2 mb-3 ${(i > 1) ? 'mt-3' : ''}`} key={i}>
                    <span className="p-2 mr-2 bg-light rounded text-primary"> Version {version}</span>  {res.data.changelog[version].FECHA}
                </Label>)
                if (res.data.changelog[version].nuevo !== undefined) {
                    res.data.changelog[version].nuevo.hito.forEach((hito, index) => {
                        nuevos.push(<li className="text_muted ml-3 mt-2" key={index}><i className="mdi mdi-circle-medium mr-2"></i><span dangerouslySetInnerHTML={{__html: hito }} /></li>)
                    })
                    html.push(
                        <ul className="list-unstyled mt-3" key={i}>
                            <h4>
                                <Badge className="pr-3 pl-3 etm_bg_blue3" pill={true}>
                                    <i className="fas fa-plus mr-2"></i>
                                    Nuevo{(nuevos.length > 1) ? 's' : ''}
                                </Badge>
                            </h4>
                            {nuevos}
                        </ul>
                    )
                }

                if (res.data.changelog[version].mejora !== undefined) {
                    res.data.changelog[version].mejora.hito.forEach((hito, index) => {
                        mejoras.push(<li className="text_muted ml-3 mt-2" key={index}><i className="mdi mdi-circle-medium mr-2"></i><span dangerouslySetInnerHTML={{__html: hito }} /></li>)
                    })
                    html.push(
                        <ul className="list-unstyled mt-3" key={i + 1}>
                            <h4>
                                <Badge className="pr-3 pl-3 etm_bg_blue1" pill={true}>
                                    <i className="fas fa-thumbs-up mr-2"></i>
                                    Mejora{(mejoras.length > 1) ? 's' : ''}
                                </Badge>
                            </h4>
                            {mejoras}
                        </ul>
                    )
                }

                if (res.data.changelog[version].correccion !== undefined) {
                    res.data.changelog[version].correccion.hito.forEach((hito, index) => {
                        correciones.push(<li className="text_muted ml-3 mt-2" key={index}><i className="mdi mdi-circle-medium mr-2"></i><span dangerouslySetInnerHTML={{__html: hito }} /></li>)
                    })
                    html.push(
                        <ul className="list-unstyled mt-3" key={i + 2}>
                            <h4>
                                <Badge className="pr-3 pl-3 etm_bg_blue2" pill={true}>
                                    <i className="fas fa-check mr-2"></i>
                                    Correccion{(correciones.length > 1) ? 'es' : ''}
                                </Badge>
                            </h4>
                            {correciones}
                        </ul>
                    )
                }
                list.push(html)
                i++
            }
        }
        setChangelog(list)
    }

    return (
        <Fragment>
            <Modal isOpen={props.modal} size={'lg'} scrollable={true} className="rounded">
                <div
                    xs={12}
                    className="text-center theme_bg opacity-50 ml-0 mr-0 mt-0 mb-3 pb-3 pt-3"
                    style={{
                        opacity: "0.8"
                    }}
                >
                    <img src={logo} className="img-fluid fix_img w-50" />
                </div>
                <ModalBody className='no_scrolling_div'>
                    <Container>
                        <Row>

                            <Col xs={8} >
                                <Label className="h4">Control de Cambios</Label>
                            </Col>
                            <Col xs={4} className="text-right">
                                <Button onClick={() => props.setModal(false)}> <i className="fas fa-times mr-2"></i> Cerrar </Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-start mt-3">
                            <Col lg={12} md={12} sm={12} className="modal_scrolling_div" >
                                {changelog}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>

            </Modal>
        </Fragment >
    );
}

export default ModalChangeLog;