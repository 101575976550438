import React, { Fragment, useState, useEffect } from 'react';
import { extendWith } from 'lodash';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MetisMenu from "metismenujs";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalBody, Row, Col } from "reactstrap";

// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import megamenuImg from "../../assets/images/megamenu-img.png";
// import logoLightPng from "../../assets/images/logo-light.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";

// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

//i18n
import { withNamespaces } from 'react-i18next';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";

import logo from "../../assets/images/conecTime/logo_full_blanco.png";
import Logo_small from "../../assets/images/conecTime/logo_blanco.png";
import Emprendedor from "../../assets/images/conecTime/design/Perfiles/PERFIL_EMPRENDEDOR.png";
import Gerente from "../../assets/images/conecTime/design/Perfiles/PERFIL_GERENTE.png";
import Inversionista from "../../assets/images/conecTime/design/Perfiles/PERFIL_INVERSIONISTA.png";
import Mentor from "../../assets/images/conecTime/design/Perfiles/PERFIL_MENTOR.png";
import Administrador from "../../assets/images/conecTime/design/Perfiles/PERFIL_ADMINISTRADOR.png";
import { capitalize, validateRol, isLogged, getLocalStorage } from '../../helpers/etmmeetUtils';
import Percentage from "../../components/Home/Percentage";
import { API } from '../../helpers/axios';

const Header = (props) => {

  isLogged(props.history);

  let iconoPerfil;
  const pathName = props.location.pathname;
  const ls = getLocalStorage();
  const [search, setsearch] = useState(false);
  const [megaMenu, setMegaMenu] = useState(false);
  const [menuPhoto, setMenuPhoto] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [dashboardMenu, setDashboardMenu] = useState([]);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [logoEvent, setLogoEvent] = useState("");
  const [makeChallenges, setMakeChallenges] = useState(false);

  useEffect(() => {
    getDashboardByUser();
    getEventImg();
    canMakeChallenges();
  }, []);

  const getDashboardByUser = async () => {
    const res = await API.get('/getDashboardByUser', { params: { user_id: ls.id } });
    if (res.data.state) {
      let list = [];
      res.data.data.map((e, i) => {
        list.push(
          <li key={i}>
            <Link to={`/dashboard/${e.HASH}`} className={(pathName === `/dashboard/${e.HASH}`) ? "waves-effect active" : "waves-effect"}>
              <span><i className="fas fa-chart-bar align-middle mr-2"></i>{props.t(`${capitalize(e.COMPANY)}`)}</span>
            </Link>
          </li>
        );
      })
      setDashboardMenu(list);
    }
  }

  const getEventImg = async () => {
    const res = await API.get('/getEventImg', { params: { user_id: ls.id } });
    if (res.data.state) {
      setLogoEvent(res.data.data.photo);
    }
  }

  const canMakeChallenges = async () => {
    const res = await API.get('/profile/getCanMakeChallenges', { params: { token: ls.token } });
    if (res.data.state) {
      setMakeChallenges(true);
    } else {
      setMakeChallenges(false);
    }
  }

  switch (ls.rol) {
    case "EMPRENDEDOR":
      iconoPerfil = Emprendedor;
      break;
    case "GERENTE/EJECUTIVO":
      iconoPerfil = Gerente;
      break;
    case "INVERSIONISTA":
      iconoPerfil = Inversionista;
      break;
    case "MENTOR":
      iconoPerfil = Mentor;
      break;
    case "ADMINISTRADOR":
      iconoPerfil = Administrador;
      break;
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="pb-1">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              {/* <Link to="/home" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link> */}

              <Link to="/home" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={Logo_small} alt="" height="20" /> */}

                </span>
                <span className="logo-lg">
                  <div className="d-flex justify-center">
                    <img src={logo} alt="" height="50" />
                    {
                      (logoEvent !== "") ? <img src={logoEvent} alt="" height="50" className="ml-3" /> : ""
                    }
                  </div>
                </span>
              </Link>
            </div>

            {/* <button aria-haspopup="true" className="etm_bars_btn btn header-item waves-effect step-tour-mobile dropdown-toggle" aria-expanded="false" onClick={() => { setMegaMenu(!megaMenu) }}><i className="fa fa-fw fa-bars"></i></button> */}
          </div>

          <Row className="w-100 ">
            {/* <Col xl={4} md={6} className=" pt-3"> */}
            <Col xs={12} className=" pt-3">
              {/* <div className="align-self-center d-flex etm_header_centered justify-content-sm-center align-item-sm-center justify-content-xl-end align-item-xl-end"> */}
              <div className="align-self-center d-flex etm_header_centered justify-content-sm-center align-item-sm-center">
                <img src={iconoPerfil} alt="" className="etm_header_logo_rol" />
                <h5 className="etm_text_blue text-center mt-2">
                  Bienvenido
                  <span className="text-white">{capitalize(ls.name)}</span>
                  <br />
                  <span className="etm_bg_header_rol">
                    <small>
                      {
                        capitalize(ls.rol[0])
                      }
                    </small>
                  </span>
                </h5>
                <NotificationDropdown />
                {/* <ProfileMenu /> */}
              </div>
            </Col>
            <Col xl={8} md={6} hidden>
              <div className="banner-header align-self-center d-flex etm_header_centered justify-content-center align-item-center">
                <a href="https://etmday.org/" target='_blank'>
                  <picture>
                    <source media="(max-width: 1272px)" srcSet="https://d58soum3omur3.cloudfront.net/images/banner-etmdayconcepcion_2.png" />
                    <img src="https://d58soum3omur3.cloudfront.net/images/banner-etmdayconcepcion_1.png" alt="EtMday Concepcion" />
                  </picture>
                </a>
              </div>
            </Col>
          </Row>

          <div className="d-flex text-center etm_header_centered" onClick={() => { setMegaMenu(!megaMenu) }}>
            <img src={ls.profile_picture} alt="" className="avatar-sm rounded-circle fix_img shadow-lg etm_avatar_menu_mobile" />
            <Modal isOpen={megaMenu} size={'lg'} scrollable={true} toggle={() => { setMegaMenu(!megaMenu) }}>
              <ModalBody>
                <Row>
                  <Col xs={12} className="text-right">
                    <span className="text-muted pointer" onClick={() => { setMegaMenu(!megaMenu) }}><u>Cerrar Menú<i className="fas fa-times align-middle ml-2 text-danger"></i></u></span>
                  </Col>
                  <Col xs={12}>
                    <ul className="list-unstyled megamenu-list" id="side-menu-etm">
                      <li className="text-center">
                        <img src={ls.profile_picture} alt="" className="avatar-md rounded-circle fix_img shadow-lg" />
                        <h5 className="etm_text_blue mb-0">{capitalize(ls.name)} {capitalize(ls.last_name)}</h5>
                        <h5 className="etm_text_blue mb-0">{capitalize(ls.rol[0])}</h5>
                        {/* <Percentage /> */}
                        <Row>
                          <Col xs={5}>
                            <div className="mt-3">
                              <Link to="/qr_profile" className="btn btn-primary btn-block text-white">QR <i className="fas fa-qrcode ml-1"></i></Link>
                            </div>
                          </Col>
                          <Col xs={7}>
                            <div className="mt-3">
                              <Link to="/profile" className="btn btn-primary btn-block text-white">Completar Perfil <i className="fas fa-arrow-right fa-beat ml-1"></i></Link>
                            </div>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12}>
                    <ul className="list-unstyled megamenu-list" id="side-menu-etm">
                      <li className="menu-title">{props.t('Menú')}  </li>
                      <li>
                        <Link to="/home" className={(pathName === '/home') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-home align-middle mr-2"></i>{props.t('Inicio')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/profile" className={(pathName === '/profile') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-user align-middle mr-2"></i>{props.t('Mi Perfil')}</span>
                        </Link>
                      </li>
                      {
                        (validateRol(['INVERSIONISTA', 'MENTOR', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR'])) ?
                          <li>
                            <Link to="/giveHours" className={(pathName === '/giveHours') ? "waves-effect active" : "waves-effect"}>
                              <span><i className="fas fa-handshake align-middle mr-2"></i>{props.t('Donar Tiempo')}</span>
                            </Link>
                          </li> : ""
                      }
                      {
                        (validateRol(['INVERSIONISTA', 'MENTOR', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR'])) ?
                          <li>
                            <Link to="/givenTime" className={(pathName === '/givenTime') ? "waves-effect active" : "waves-effect"}>
                              <span><i className="fas fa-clipboard align-middle mr-2"></i>{props.t('Tiempo Donado')}</span>
                            </Link>
                          </li> : ""
                      }
                      <li>
                        <Link to="/requests" className={(pathName === '/requests') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Mis Solicitudes')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/confirmed" className={(pathName === '/confirmed') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-calendar-alt align-middle mr-2"></i>{props.t('Reuniones Confirmadas')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/requests_history" className={(pathName === '/requests_history') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-book align-middle mr-2"></i>{props.t('Historial de Reuniones')} ${makeChallenges.toString()}</span>
                        </Link>
                      </li>
                      {
                        (makeChallenges) ?
                          <li>
                            <Link to="/challenges" className={(pathName === '/challenges') ? "waves-effect active" : "waves-effect"}>
                              <span><i className="fas fa-university align-middle mr-2"></i>{props.t('Nuestros Desafíos')}</span>
                            </Link>
                          </li>
                          : ""
                      }
                      <li>
                        <Link to="/challenges_searchfor" className={(pathName === '/challenges_searchfor') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-chess align-middle mr-2"></i>{props.t('Desafíos')}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/meet" className={(pathName === '/meet') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-laptop align-middle mr-2"></i>{props.t('Sala de Espera')}</span>
                        </Link>
                      </li>

                      {
                        (dashboardMenu.length > 0) ?
                          <Fragment>
                            <hr />
                            <li className="menu-title">{props.t('Dashboard')}</li>
                            {dashboardMenu}
                          </Fragment>
                          : ""
                      }

                      {
                        (validateRol(['ADMINISTRADOR'])) ?
                          <Fragment>
                            <hr />
                            <li className="menu-title">{props.t('Administrador')}  </li>
                            <li>
                              <Link to="/admin_pending" className={(pathName === '/admin_pending') ? "waves-effect active" : "waves-effect"}>
                                <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Gestionar Horas Solicitadas')}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin_confirm" className={(pathName === '/admin_confirm') ? "waves-effect active" : "waves-effect"}>
                                <span><i className="fas fa-inbox align-middle mr-2"></i>{props.t('Gestionar Horas Confirmadas')}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin_panel" className={(pathName === '/admin_panel') ? "waves-effect active" : "waves-effect"}>
                                <span><i className="fas fa-chart-bar align-middle mr-2"></i>{props.t('Panel Administrador')}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin_export" className={(pathName === '/admin_export') ? "waves-effect active" : "waves-effect"}>
                                <span><i className="fas fa-cloud-download-alt align-middle mr-2"></i>{props.t('Exportar Bases')}</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/admin_user" className={(pathName === '/admin_user') ? "waves-effect active" : "waves-effect"}>
                                <span><i className="fas fa-users align-middle mr-2"></i>{props.t('Panel Usuarios')}</span>
                              </Link>
                            </li>
                          </Fragment> : ""
                      }

                      <hr />
                      {/* <li>
                            <Link to="/change_profile" className={(pathName === '/change_profile') ? "waves-effect active" : "waves-effect"}>
                              <span><i className="fas fa-id-badge align-middle mr-2"></i>{props.t('Cambiar Perfil')}</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/password" className={(pathName === '/password') ? "waves-effect active" : "waves-effect"}>
                              <span><i className="fas fa-key align-middle mr-2"></i>{props.t('Cambiar Contraseña')}</span>
                            </Link>
                          </li> */}
                      <li>
                        <Link to="/logout" className={(pathName === '/logout') ? "waves-effect active" : "waves-effect"}>
                          <span><i className="fas fa-power-off align-middle mr-2"></i>{props.t('Cerrar Sesión')}</span>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className="text-right">
                    <span className="text-muted pointer" onClick={() => { setMegaMenu(!megaMenu) }}><u>Cerrar Menú<i className="fas fa-times align-middle ml-2 text-danger"></i></u></span>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>





            {/* <NotificationDropdown /> */}
            {/* <ProfileMenu /> */}
          </div>

        </div>
      </header>
    </React.Fragment >
  );
}
const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, { showRightSidebarAction, toggleLeftmenu, changeSidebarType })(withRouter(withNamespaces()(Header)));
