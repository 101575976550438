import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Row, Col, Label, Container, Button } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API } from '../../helpers/axios';
import { swal_etm, getLocalStorage, swal_etm_confirm } from "../../helpers/etmmeetUtils";
import LoadScreen from "../Utils/LoadScreen";
import ModalStartup from "./modalStartup";
// import ImageViewer from 'react-simple-image-viewer';

const StartupData = () => {

    const [startupsList, setStartupsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startupId, setStartupId] = useState(0);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        getStartupsData();
    }, []);

    useEffect(() => {
        if (!modal) {
            setStartupId(0)
        }
    }, [modal]);

    const getStartupsData = async () => {
        setLoading(true);
        const res = await API.get('/profile/getUserStartupsData', { params: { token: getLocalStorage().token } });
        let list = [];
        if (res.data.state) {
            res.data.startups.map((e, i) => {
                list.push({
                    id: i + 1
                    , foto: <div className="text-center"><img src={e.FOTO} alt="" className="img-fluid etm_img_border etm_sombra" key={i} /></div>
                    , nombre: <b className="h5 text-muted">{e.NOMBRE.toString().toUpperCase()}</b>
                    , industria: e.INDUSTRIA
                    , etapa: e.ETAPA
                    , sitio_web: e.SITIO_WEB
                    , opciones:
                        <Fragment>
                            <Button color="success" block={true} className="p-2 " onClick={() => editStartup(e.ID)}><i className="fas fa-edit fa-lg mr-2"></i>EDITAR</Button>
                            <Button color="danger" block={true} className="p-2" onClick={() => deleteStartup(e.ID)}><i className="fas fa-trash fa-lg mr-2"></i>ELIMINAR</Button>
                        </Fragment>
                });
            });
        }
        setStartupsList(list);
        setLoading(false);
    }

    const columns = [{
        dataField: 'id'
        , text: '#'
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'foto'
        , text: 'FOTO DE PORTADA'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'nombre'
        , text: 'NOMBRE DEL PROYECTO'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'industria'
        , text: 'INDUSTRIA'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'etapa'
        , text: 'ETAPA DEL PROYECTO '
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'sitio_web'
        , text: 'SITIO WEB'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'opciones'
        , text: 'OPCIONES'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }];

    const newStartup = async (id) => {
        setStartupId(0);
        setModal(true);
    }

    const editStartup = async (id) => {
        setStartupId(id);
        setModal(true);
    }

    const deleteStartup = async (id) => {
        await swal_etm_confirm(`Se eliminará este <span class="etm_text_blue">emprendimiento</span> y ya no estará disponible.`).then(async (result) => {
            const res = await API.delete('/profile/deleteStartup', { params: { startup_id: id } });
            if (res.data.state) {
                swal_etm(`Emprendimiento eliminado!`, `success`);
                getStartupsData();
            }
        }).catch((err) => {
            return false;
        });
    }

    return (
        <Fragment>
            <div className="animate__animated animate__fadeIn">
                <Card className="mt-3" id="perfilStartup">
                    <CardTitle className="mt-3 ml-3 mb-0 text-center">
                        <Container>
                            <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                <p>Mis Emprendimientos</p>
                            </Label>
                            <br />
                            <Label className="h5 mt-0 mb-0 pt-0 pb-0">
                                <p className="text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Crea, modifica o elimina tus emprendimientos aquí. Debes tener al menos 1 configurado para acceder a las reuniones comerciales.</p>
                            </Label>
                        </Container>
                        <hr />
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col lg="12" className="table-responsive">
                                {
                                    (loading) ?
                                        <LoadScreen text="Cargando emprendimientos..." />
                                        : <Fragment>
                                            <div className="text-right mt-0 pt-0 mb-3">
                                                <Button color="primary" className="etm_btn pt-2 pb-2 pl-5 pr-5 ml-2 btn-lg" onClick={() => newStartup()}><i className="fas fa-plus fa-lg mr-2"></i>AGREGAR NUEVO PROYECTO</Button>
                                            </div>
                                            <ToolkitProvider
                                                keyField="id"
                                                data={startupsList}
                                                columns={columns}
                                                search
                                            >
                                                {
                                                    props => (
                                                        <div>
                                                            <BootstrapTable
                                                                keyField="id"
                                                                pagination={paginationFactory()}
                                                                {...props.baseProps}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </Fragment>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <ModalStartup
                modal={modal}
                setModal={setModal}
                startupId={startupId}
                setStartupId={setStartupId}
                getStartupsData={getStartupsData}
            />
        </Fragment>
    );
}

export default (StartupData);