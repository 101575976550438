import React, { Fragment } from 'react';
import { Row, Col } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GabrielaSalvador from "../../assets/images/etmmeet/landing/testimonials/tdd/GabrielaSalvador.png";
import NelsonRojas from "../../assets/images/etmmeet/landing/testimonials/tdd/NelsonRojas.png";
import MarieAuteroeche from "../../assets/images/etmmeet/landing/testimonials/tdd/MarieAuteroeche.png";
import HansEben from "../../assets/images/etmmeet/landing/testimonials/tdd/HansEben.png";
import AlanJarry from "../../assets/images/etmmeet/landing/testimonials/tdd/AlanJarry.png";
import FlorenciaSepulveda from "../../assets/images/etmmeet/landing/testimonials/tdd/FlorenciaSepulveda.png";
import SebastianCastro from "../../assets/images/etmmeet/landing/testimonials/tdd/SebastianCastro.png";
import DanielVercelli from "../../assets/images/etmmeet/landing/testimonials/tdd/DanielVercelli.png";
import { isMobile } from '../../helpers/etmmeetUtils';

const SliderExpert = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: (isMobile()) ? 1 : 2,
        slidesToScroll: 1,
        speed:  1000,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
          ),
          customPaging: i => (
            <div></div>
          )
    };

    return (
        <Fragment>
            <Slider {...settings} className="testimonials_slider">
                <div className="text-center carousel-item active">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "A través del acompañamiento el emprendedor no está solo, y cuenta con el apoyo de experiencia desinteresada.  Es tremendamente valioso que las mentorías, se entreguen en plataformas preparadas para eso"
                            :   "Más del 70 % de los chilenos trabajan y viven de un emprendimiento, no basta solo con dar recursos, mucho más relevante es el acompañamiento, el ayudar a visibilizar a las pymes en las grandes empresas. A través del acompañamiento el emprendedor no está solo, y cuenta con el apoyo de experiencia desinteresada.  Es tremendamente valioso que las mentorías, se entreguen en plataformas preparadas para eso."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ GabrielaSalvador } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Gabriela Salvador</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Directora Ejecutiva Vantrust Capital</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "En un mundo que cambia tan rápida y aceleradamente, no somos capaces de hacerlo todo, y  lo mejor que se puede hacer como país, es colaborar, trabajar en equipo y enfrentar los desafíos de la mano de startups. EtMmeet es una plataforma de conexión que nos permitirá avanzar por este camino"
                            :   "En un mundo que cambia tan rápida y aceleradamente, no somos capaces de hacerlo todo, y  lo mejor que se puede hacer como país, es colaborar, es trabajar en equipo, enfrentar los desafíos que tiene Chile y el mundo, de la mano de startups, trabajando juntos. Para ello,  EtMmeet es una plataforma de conexión que nos permitirá avanzar por este camino."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ NelsonRojas } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Nelson Rojas</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Gerente General Caja Los Andes</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "EtMmeet es un espacio que habilita la conexión entre el mundo de las empresas/empresarios y los emprendedores y que juntos podamos aspirar a responder a los desafíos sociales y económicos actuales y futuros."
                            :   "La generación de un ecosistema amplio y una mirada colaborativa son necesarias para el desarrollo sostenible de los negocios, generar mayor bienestar y crecimiento del país. En este sentido, EtMmeet es un espacio que habilita la conexión entre el mundo de las empresas/empresarios y los emprendedores y que juntos podamos aspirar a responder a los desafíos sociales y económicos actuales y futuros."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ MarieAuteroeche } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Marie Auteroche</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Gerente Corporativo Transformación y Experiencia Caja Los Andes</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Emprende tu Mente ayuda a conectar pares con mayor afinidad o probabilidad de éxito, transformándose en una poderosa herramienta no solo para los emprendedores sino para todos los obsesivos en innovación."
                            :   "Si queremos que la innovación tenga un impacto exponencial la mejor manera es desarrollarla  en conjunto con distintos actores del ecosistema de innovación. Emprende tu Mente justamente ayuda a visibilizar este ecosistema pero más importante aún ayuda a conectar pares con mayor afinidad o probabilidad de éxito, transformándose en una poderosa herramienta no solo para los emprendedores sino para todos los obsesivos en innovación."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ HansEben } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Hans Eben</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>CEO Westfalia Fruits y Presidente del Circulo de Innovación de Icare</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Se sabe que no hay nada más potente que las redes y conexiones, para apoyar a los emprendedores y potenciar sus sueños. Para nosotros debería ser un deber el conectarnos y conectarlos dentro de nuestra “intranet” personal que hemos desarrollado con los años. En pos de este objetivo la plataforma EtMmeet cumple un rol clave."
                            :   "Se sabe que no hay nada más potente que las redes y conexiones, para apoyar a los emprendedores y potenciar sus sueños. Para nosotros debería ser un deber el conectarnos y conectarlos dentro de nuestra “intranet” personal que hemos desarrollado con los años. En pos de este objetivo la plataforma EtMmeet cumple un rol clave."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ AlanJarry } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Allan Jarry</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Managing Partner Dadneo</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "EtMmeet nos da acceso directo a conocer emprendedores e innovadores chilenos constantemente, tanto para hacer networking y entregar feedback, como para encontrar oportunidades de colaboración. La plataforma nos permite sistematizar el contacto constante con el ecosistema de forma simple, directa y amigable."
                            :   "EtMmeet nos da acceso directo a conocer emprendedores e innovadores chilenos constantemente, tanto para hacer networking y entregar feedback, como para encontrar oportunidades de colaboración. La plataforma nos permite sistematizar el contacto constante con el ecosistema de forma simple, directa y amigable."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ FlorenciaSepulveda } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Florencia Sepúlveda</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Ideation Lead de Wind Garage Copec</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "EtMmeet es una excelente forma de conocer Pymes y Emprendedores con soluciones innovadoras. Varios de nuestros partners estratégicos en Valorpyme.cl los hemos conocido utilizando la plataforma."
                            :   "EtMmeet es una excelente forma de conocer Pymes y Emprendedores con soluciones innovadoras. Varios de nuestros partners estratégicos en Valorpyme.cl los hemos conocido utilizando la plataforma."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ SebastianCastro } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Sebastián Castro</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Gerente Clientes Pyme y Emprendimiento Banco Bci</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="text-center carousel-item">
                    <p className="h5 ml-md-5 mr-md-5 ml-3 mr-3"><span className="testimonials_quote">“</span>
                        {
                            (isMobile()) ? 
                                "Reunirme con decenas de emprendedores a través de EtMmeet ha sido un privilegio, ya que me ha permitido explorar distintos proyectos en áreas muy diversas, en un ejercicio intelectual y de diálogo muy estimulante, energizante y enriquecedor. Recomiendo sumarse a EtMmeet"
                            :   "Reunirme con decenas de emprendedores a través de EtMmeet ha sido un privilegio, ya que me ha permitido explorar distintos proyectos en áreas muy diversas, en un ejercicio intelectual y de diálogo muy estimulante, energizante y enriquecedor. Tanto para emprendedores como para quienes tengan ganas de contribuir como mentores, recomiendo sumarse a EtMmeet."
                        }
                    <span className="h1 testimonials_quote_end">”</span></p>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <img className="testimonials_img img-fluid" src={ DanielVercelli } alt="pic1"/>    
                        </Col>
                        <Col xs={8} className="mt-3">
                            <div className="my-auto text-left">
                                <p className="h5 testimonials_title_slider mb-0">Daniel Vercelli</p>
                                <p className="h5 testimonials_subtitle_slider mt-0"><b>Ex CEO Coca-Cola Chile y Managing Partner de Manuia</b></p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Slider>
        </Fragment>
    );
}

export default (SliderExpert);


