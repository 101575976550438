import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col } from "reactstrap";

const Menu = (props) => {
    const[selectedMenu, setSelectedMenu] = useState(0);
    const[showMenu, setShowMenu] = useState(false);
    const[fadeIn, setFadeIn] = useState('');

    useEffect(() => {
        changeFadeIn();
    }, [props.menu]);
    
    useEffect(() => {
        if(props.selectedMenu === "experto"){
            setSelectedMenu(3);
        }
        if(props.selectedMenu === "emprendedor"){
            setSelectedMenu(2);
        }
    }, [props.selectedMenu]);

    const changeFadeIn = () => {
        if(props.menu === null) {
            setFadeIn('');
        }else if(props.menu) {
            setShowMenu(true);
            setFadeIn('animate__fadeInRight');
        }else{
            setFadeIn('animate__fadeOutRight');
        }
    }

    return (
        <Fragment>
            <Row className={`menu_landing animate__animated ${fadeIn}`} hidden={ !showMenu }>
                <Col xs={12} className="text-right">
                    <i className="fas fa-times text-white mt-md-3 mt-5 mr-5 mb-4 pointer" onClick={() => props.setMenu(!props.menu)}></i>
                </Col>
                <Col xs={12} className="pt-2 pb-2 mr-5 pl-0">
                    <div className={(selectedMenu === 1) ? `pt-2 pb-2 menu_landing_option menu_landing_active` : `pt-2 pb-2 menu_landing_option`} onClick={() => (setSelectedMenu(1), props.setMenu(!props.menu))}>
                        <a href="/landing#aboutus" className="ml-3">¿Quiénes somos?</a>
                    </div>
                    <div className={(selectedMenu === 2) ? `pt-2 pb-2 menu_landing_option menu_landing_active` : `pt-2 pb-2 menu_landing_option`} onClick={() => (setSelectedMenu(2), props.setMenu(!props.menu))}>
                        <a href="/landing_emprendedores" className="ml-3">Emprendedor/a</a>
                    </div>
                    <div className={(selectedMenu === 3) ? `pt-2 pb-2 menu_landing_option menu_landing_active` : `pt-2 pb-2 menu_landing_option`} onClick={() => (setSelectedMenu(3), props.setMenu(!props.menu))}>
                        <a href="/landing_expertos" className="ml-3">Experto/a</a>
                    </div>
                    <div className={(selectedMenu === 4) ? `pt-2 pb-2 menu_landing_option menu_landing_active` : `pt-2 pb-2 menu_landing_option`} onClick={() => (setSelectedMenu(4), props.setMenu(!props.menu))}>
                        <a href="/landing#testimonials" className="ml-3">Testimonios</a>
                    </div>
                    <div className={(selectedMenu === 5) ? `pt-2 pb-2 menu_landing_option menu_landing_active` : `pt-2 pb-2 menu_landing_option`} onClick={() => (setSelectedMenu(5), props.setMenu(!props.menu))}>
                        <a href="/landing#contact" className="ml-3">Contáctanos</a>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Menu);