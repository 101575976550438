import React, { Fragment, useState } from 'react';
import { FormGroup, Row, Col, Container } from "reactstrap";
import "../../assets/scss/etm.scss";
import { withRouter } from "react-router-dom";
import { API } from '../../helpers/axios';
import LogoNegro from "../../assets/images/conecTime/logo_full_negro.png";
import { capitalize } from "../../helpers/etmmeetUtils";

 const Banner = ( ) => {

    const [searchKey, setSearchKey] = useState(""); 
    const [loading, setLoading]     = useState(false);
    const [cards, setCards]         = useState([]);
  
    document.body.style.backgroundColor     = "transparent";
    document.body.style.backgroundRepeat    = "no-repeat";
    document.body.style.backgroundSize      = "cover";
    document.body.style.overflowX           = "hidden";

    const search = async (e) => {
        e.preventDefault();
        setLoading(true);
        searchPost();
    }

    const searchByKey = async (e) => {
        if(e.key === 'Enter'){
            setLoading(true);
            searchPost();
        }
    }

    const searchPost = async () => {
        const res = await API.post('/searchBanner', {key: searchKey});
        setCards([]);
        if(res.data.state){
            let list =  [];
            res.data.result.map((e, i) => {
                list.push(
                    <Col key={i} xl={4} lg={4} md={4} sm={4} xs={12} className="mt-3 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0">
                        <a href="https://etmmeet.org/" target="_blank">
                            <div className="profile-user-wid text-center">
                                <img src={ e.FOTO } alt="" className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg"/> 
                                <h6 className="mt-1 mb-0 etm_banner_text_1">{ capitalize(e.NOMBRE) }</h6>
                                <h6 className="mt-0 text-muted etm_banner_text_2">{ capitalize(e.PERFIL) }</h6>
                            </div>
                        </a>
                    </Col>
                );
            });
            setCards(list);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            <Row>
                <Col className="text-center" xs={12}>
                    <a href="https://etmmeet.org/" target="_blank">
                        <img src={ LogoNegro } alt="" className="img-fluid etm_public_banner_logo"/>    
                    </a>
                </Col>
            </Row>
            <Row className="mt-2 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0">
                <Col className="text-center" xs={12}>
                    <Container>
                        <FormGroup>
                            <p className="mt-2 mb-0 etm_banner_title">Reúnete con grandes empresas y empresarios!</p>
                            <div className="input-group">
                                <input name="input_key" className="form-control etm_input_text" type="text" disabled={ loading } onKeyPress={ (e) => searchByKey(e) } placeholder="Palabra clave" value={searchKey} onChange={(e) => setSearchKey(e.target.value)}/>
                                <div className="input-group-prepend">
                                    <button className="btn btn-primary etm_btn etm_input_group etm_text_blue" onClick={ (e) => search(e) } disabled={ loading }><i className={`mr-1 fas fa-${ (loading) ? "sync" : "search"}`}></i>{ (loading) ? "Buscando..." : "Buscar" }</button>
                                </div>
                            </div>
                        </FormGroup>
                    </Container>
                </Col>
                <Col className="text-center mt-3" xs={12}>
                    <Container>
                        <Row>
                            { cards }
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Fragment>
    );
}

export default withRouter(Banner);