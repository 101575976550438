import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND;
// const URL = "https://api-meet.emprendetumente.org/";

export const API = axios.create({ 
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const FormAPI = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const FormAPI_Conectime = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json'
    }
}); 

API.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

FormAPI.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

FormAPI_Conectime.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);