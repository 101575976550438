import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import "../../assets/scss/etm_login.scss";
import { API } from '../../helpers/axios';
import { swal_etm } from "../../helpers/etmmeetUtils";
import Step1 from "../../components/Onboarding/step1";
import Step2 from "../../components/Onboarding/step2";
import Step3 from "../../components/Onboarding/step3";
import Step4 from "../../components/Onboarding/step4";
import CreatedBy from "../../components/Utils/CreatedBy";

const Onboarding = (match) => {
    const history = useHistory();
    const etm = <Fragment><b>EtM</b><span className="etm_text_blue"><i>meet</i></span></Fragment>;
    const [data, setData] = useState({ name: "", email: "" });
    const [step, setStep] = useState(0);

    useEffect(() => {
        getUserByToken();
    }, []);

    const getToken = () => {
        if (match.location.pathname.length > 0) {
            const data = match.location.pathname.split('/');
            const token = data[2];
            return token;
        }
    }

    const getUserByToken = async () => {
        const res = await API.get('/register/getRegisteredUserByToken', { params: { token: getToken() } });
        if (res.data.state) {
            if (res.data.step > 4) {
                history.push(`/login`);
            } else {
                setData({
                    name: res.data.user.name
                    , email: res.data.user.email
                });
                setStep(res.data.step);
            }
        } else {
            setTimeout(
                function () {
                    history.push(`/sign-up`);
                }
                    .bind(this),
                1000
            );
        }
    }

    const saveStep = async (savingStep) => {
        const res = await API.post('/register/saveStep', { token: getToken(), step: savingStep });
        if (res.data.state) {
            setStep(savingStep + 1);
        } else {
            swal_etm(`Se ha producido un error al guardar el <b className="etm_text_blue">paso ${savingStep}</b>`, `error`);
        }
    }

    return (
        <Fragment>
            {
                (step === 0) ?
                    <Row>
                        <Col className="bg_onboarding_blue text-center h1" md={12}>
                            <div className="text-white mt-5">
                                <div className="spinner-border spinner-border-md m-1 mr-2 mt-5 text-white" role="status" /> Cargando...
                            </div>
                        </Col>
                    </Row>
                    : ""
            }
            {
                (step === 1) ?
                    <Step1 etm={etm} data={data} getUserByToken={getUserByToken} getToken={getToken} saveStep={saveStep} />
                    : ""
            }
            {
                (step === 2) ?
                    <Step2 etm={etm} data={data} getUserByToken={getUserByToken} getToken={getToken} saveStep={saveStep} />
                    : ""
            }
            {
                (step === 3) ?
                    <Step3 etm={etm} data={data} getUserByToken={getUserByToken} getToken={getToken} saveStep={saveStep} />
                    : ""
            }
            {
                (step === 4) ?
                    <Step4 etm={etm} data={data} getUserByToken={getUserByToken} getToken={getToken} saveStep={saveStep} />
                    : ""
            }

            {/* <CreatedBy full={ false }/> */}
        </Fragment>
    );
}

export default (Onboarding);
