import React from 'react';
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
    return (
        <React.Fragment>
            <footer>
            </footer>
        </React.Fragment>
    );
}

export default Footer;