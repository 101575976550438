import React, { useEffect, useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";

import Logo from "../../assets/images/conecTime/logo_conectime.png";

const ThankMessage = ( props ) => {
    const[msg, setMsg] = useState("");
    //  end_meeting
    //  qr_meeting_created

    useEffect(() => {
        getMessage();
    }, []);
    
    const getMessage = async () => {
        if(props.location.pathname.length > 0){

            if(props.location.pathname.length > 0){
                const data  = props.location.pathname.split('/');
                const type  = data[2];
                switch(type){
                    case "end_meeting":
                        setMsg(<h1>Gracias por haber participado en esta reunión <i className="fas fa-smile-wink etm_text_blue ml-2"></i></h1>);
                        break;
                    case "qr_meeting_created":
                        setMsg(<h1>¡Escanea más QR para tener más reuniones! <i className="fas fa-smile-wink etm_text_blue ml-2"></i></h1>);
                        break;
                    default:
                        setMsg(<h1>Gracias por participar en EtM<i>meet</i><i className="fas fa-smile-wink etm_text_blue ml-2"></i></h1>);
                        break;
                }
            }
        }
    }

    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Col sm={12} className="pt-5 mt-5 text-center animate__animated animate__fadeInUp">
                            { msg }
                        </Col>
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(ThankMessage);