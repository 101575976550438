import React from 'react';
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import "../../assets/scss/etm.scss";
// import FullRegister from "../../components/Register/FullRegister";
import Multistep from "../../components/Profile/multiStep";

const Profile = (match) => {
    return (
        <React.Fragment>
            <Breadcrumbs title={'Mi Perfil'} />
            <div className="page-content">
                <Container fluid>

                    <Row>
                        <Col xl="12">
                            <Multistep match={match} />
                        </Col>
                        {/* <Col xl="12">
                            <ShortProfile showBtn={ false }/>
                        </Col> */}
                        {/* <Col xl="12">
                            <FullRegister />
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(Profile);