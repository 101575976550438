import React, { useState, Fragment, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container, FormGroup, Form, Label, Input, Button } from "reactstrap";
import { useGoogleLogin } from 'react-google-login';
import { API } from '../../helpers/axios';
import { setSession } from '../../helpers/authUtils';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { validateEmail, isMobile, swal_etm } from "../../helpers/etmmeetUtils";
import LoadBtn from "../../components/Utils/LoadBtn";
import CreateBy from "../../components/Utils/CreatedBy";
import "../../assets/scss/etm_login.scss";
import logo from "../../assets/images/conecTime/logo_full_blanco.png";
import google from "../../assets/images/google.svg";
import icono_login from "../../assets/images/conecTime/design/Ilustracion-inicio.png";
import bg_image from "../../assets/images/conecTime/design/Fondo-azul.png";

const Login = ({ history }) => {
    const [pageHeight, SetPageHeight] = useState(window.innerHeight);
    const [reference, setReference] = useState('');
    const [enabledCoockies, setEnabledCoockies] = useState(true)

    useEffect(() => {
        getReference();
    }, []);

    const getReference = async () => {
        let referido = "";
        let ref = [];
        if (history.location.search.length > 0) {
            ref = history.location.search.substring(1, history.location.search.length).split('?');
            referido = `?t=${ref[0]}`;
        } else if (history.location.pathname.length > 0) {
            ref = history.location.pathname.split('/');
            if (ref[2] !== undefined && ref[2] !== null && ref[2] !== "") {
                referido = `?t=${ref[2]}`;
            }
        }
        if (history.location.state !== undefined && history.location.state.prevPath !== undefined && ['/sign-up', '/forgot-password'].includes(history.location.state.prevPath.split('?')[0])) {
            referido = `?${history.location.state.prevPath.split('?')[1]}`
        }
        setReference((referido === undefined) ? "" : referido);
    }

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        document.body.style.backgroundImage = "url(" + bg_image + ")";
        document.body.style.height = "100%";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.overflowX = "hidden";
        getVerify();
        return () => {
            document.body.style.backgroundImage = null;
            document.body.style.height = null;
            document.body.style.backgroundPosition = null;
            document.body.style.backgroundRepeat = null;
            document.body.style.backgroundSize = null;
            document.body.style.overflowX = null;
            document.body.style.overflowY = null;
            window.removeEventListener("resize", updateHeight);
        }
    }, [pageHeight]);

    const updateHeight = () => {
        SetPageHeight(window.innerHeight);
    };

    const getVerify = () => {
        const data = history.location.search;
        if (data.length > 1) {
            let account = data.replace('?account=', '');
            postVerify(account);
        }
    }

    const postVerify = async (account) => {
        const res = await API.post('/verifyAccount', { account });
        if (res.data.state) {
            swal("Cuenta Verificada!", "", "success");
        }
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [show, setShow] = useState(false);

    const handleLogin = (e) => {
        setLoading(true);
        e.preventDefault();

        if (!validateEmail(email)) {
            swal("Ups!", "Debe ingresar un correo válido.", "warning");
            setLoading(false);
            return false;
        }
        if (password.length < 3) {
            swal("Ups!", "Debe ingresar una Contraseña válida.", "warning");
            setLoading(false);
            return false;
        }
        postLogin({ email, password });
    }

    const postLogin = async ({ email, password }) => {
        let type = "PC";
        if (isMobile()) {
            type = "MOBILE";
        }
        const res = await API.post('/login', {
            email,
            password,
            type
        });
        setLoading(false);
        if (res.data.state) {
            setSession(res.headers, res.data.user);
            history.push('/home');
        } else {
            if (res.data.onboarding_url !== undefined && res.data.onboarding_url !== null) {
                history.push(`/onboarding/${res.data.onboarding_url}`);
            } else {
                swal("Ups!", res.data.msg, "warning");
            }
        }
    }

    const onSuccess = (res) => {
        setLoadingGoogle(true);
        console.log('Login Success: currentUser:', res.profileObj);
        postValidateLogin(res.profileObj.email);
    };

    const postValidateLogin = async (email) => {
        let type = "PC";
        if (isMobile()) {
            type = "MOBILE";
        }
        const res = await API.post('/google_login', {
            email,
            type
        });
        setLoadingGoogle(false);
        if (res.data.state) {
            setSession(res.headers, res.data.user);
            history.push('/home');
        } else {
            swal("Ups!", "Aún no te has registrado en EtMmeet", "warning");
        }
    }

    const onFailure = (res) => {
        console.log('Login failed: res:', res);
        if (res.error == 'popup_closed_by_user' && !enabledCoockies) {
            swal_etm(
                `Para ingresar con Google, Lea el siguiente artículo para permitir la cookie de terceros. 
                <a href="https://support.google.com/accounts/answer/61416?hl=es-419&co=GENIE.Platform%3DDesktop" target="_blank"> Activar Coockies </a>`,
                'warning',
                'Las cookies de terceros están bloqueadas'
            )
        } else if (res.error == 'popup_closed_by_user') {
            swal("Ups!", "Ha ocurrido un error, reintente tras vaciar la cache de su navegador, Login con Google incompatible con modo incógnito.", "warning");
        } else if (res.details === 'Cookies are not enabled in current environment.') {
            setEnabledCoockies(false)
        }

    };

    const { signIn, loaded } = useGoogleLogin({
        clientId: '1098245134369-aviophk4t3fuhndv1c9ir4k2jpndroju.apps.googleusercontent.com',
        onSuccess,
        onFailure,
        isSignedIn: false,
        accessType: 'offline'
    });

    return (
        <Fragment>
            <Row className="etm_no_scroll">
                <Col xl={5} className="etm_login_bg">
                    <Container className="mt-5 pt-lg-3 pt-3">
                        <Card className="ml-sm-5 mr-sm-5 bg-transparent ">
                            <div className="text-center">
                                <img src={logo} alt="" className="img-fluid" width="40%" />
                            </div>
                            <CardBody className="m-sm-5">
                                <Form className="etm_login">
                                    <div className="form-horizontal pb-5">
                                        <FormGroup>
                                            <Label className="text-white">Tu email</Label>
                                            <Input onChange={(e) => setEmail(e.target.value)} name="email" className="etm_login_input" type="email" required placeholder="Ingresa tu email" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="text-white">Tu contraseña</Label>
                                            <Input onChange={(e) => setPassword(e.target.value)} name="password" className="etm_login_input" type="password" required placeholder="Ingresa tu contraseña" />
                                        </FormGroup>
                                        <FormGroup className="mt-4">
                                            <LoadBtn handle={handleLogin} loading={loading} title="Ingresa a EtMmeet" />
                                            <br />
                                            <Button onClick={signIn} block={true} color="light" className="etm_btn waves-effect waves-light">
                                                {
                                                    loadingGoogle ? <div className="spinner-border spinner-border-sm text-white" role="status" /> :
                                                        <div>
                                                            <img src={google} style={{ width: 20, position: 'absolute', left: 10 }} alt="google login"></img>
                                                            <span className="buttonText">Ingresar con Google</span>
                                                        </div>
                                                }
                                            </Button>
                                        </FormGroup>
                                        <div className="mt-3 text-center">
                                            <Label className="h5"><Link to={`/forgot-password${reference}`} className="etm_link_white">¿Perdiste tu contraseña?</Link></Label>
                                        </div>
                                        <div className="mt-5 text-center">
                                            <Label className="h5 text-white">¿Aún no te integras a nuestra comunidad?</Label>
                                            <br />
                                            <Link to={`/sign-up${reference}`}><Button color="primary" size="lg" className="etm_btn pl-5 pr-5"><b>¡ REGÍSTRATE AQUÍ !</b></Button></Link>
                                        </div>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Container>
                    <CreateBy full={false} />
                </Col>
                <Col xl={7} className="text-center mt-5 etm_logo_hide_mobile">
                    <div className="mt-5">
                        <img src={icono_login} alt="" className="img-fluid w-50 mt-5" />
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Login);