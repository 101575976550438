import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

const Authmiddleware = ({
	component: Component,
	layout: Layout
}) => (
		<Route
			render={props => {
				// condición que indica si el usuario esta logueado o no
				if (!localStorage.getItem("auth") && props.location.pathname === '/home') {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}
				if (!localStorage.getItem("auth") && props.location.pathname !== '/login') {
					
					return (
						<Redirect to={{ pathname: "/landing", state: { from: props.location } }} />
					);
				}
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default withRouter(Authmiddleware);

