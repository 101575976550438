import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
    Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormGroup,
    Nav, NavItem, NavLink, TabContent, TabPane, Card, CardTitle, CardText, CardBody, Badge,
    Container
} from "reactstrap";
import UploadFiles from "../../Utils/UploadFiles";
import UploadImageFile from "../../Utils/UploadImageFile";
import Select from 'react-select';
import { API } from '../../../helpers/axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ImageViewer from "react-simple-image-viewer";
import { useCalendar, shortDayNames } from "react-calendar-hook";
import LoadBtn from "../../Utils/LoadBtn";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import defaultEmprendimientoFoto from "../../../assets/images/etmmeet/general/default.png";
import defaultSolicitante from "../../../assets/images/etmmeet/general/default.png";
import logo_emprendedor from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";

import {
    isMobile, swal_etm, getLocalStorage, capitalize, spanishDate, selectCustomStyle,
    cleanFacebook, cleanInstagram, cleanLinkedin, cleanTwitter, cleanYoutube, getDate
} from '../../../helpers/etmmeetUtils';

const ModalChallengeRequest = (props) => {
    const ls = getLocalStorage()
    const flecha = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;
    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate() + 3);
    strikeDate.setDate(strikeDate.getDate() + 2);

    const [loading, setLoading] = useState({ data: false, btn: false });
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const [challengesRequest, setChallengesRequest] = useState([]);
    const [request, setRequest] = useState(0);
    const [challengesRequestDescription, setChallengesRequestDescription] = useState('');
    const [startups, setStartups] = useState([]);
    const [dataUser, setDataUser] = useState({
        short_name: ""
        , name: ""
        , company: ""
        , company_img: ""
        , profile: ""
        , profile_img: ""
        , photo: ""
        , description: ""
        , duration: ""
        , facebook: ""
        , instagram: ""
        , linkedin: ""
        , twitter: ""
        , youtube: ""
    });
    const [images, setImages] = useState(null);
    const [listTeamParticipants, setListTeamParticipants] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();
    const [showSection, setShowSection] = useState({ startup: true, emprendedor: false, solicitar: false, solicitarOptions: false });
    const [selectedHour, setSelectedHour] = useState(null);
    const [listHours, setListHours] = useState([]);
    const [selectedParticipants, setSelectedParticipants] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState("");
    const [validateButton, setValidateButton] = useState(false);

    const [activeTab, setActiveTab] = useState('1');
    const [showButton, setShowButton] = useState({ listado: false, agenda: false, solicita: false });

    useEffect(() => {
        if (props.modal) {
            console.log('modal solicitudes desafio')
            getChallengesRequest()
            getHours()
            getListTeamParticipants()
        }
    }, [props.modal]);

    useEffect(() => {
        validarButtonSolicitar();
    }, [selectedDate, selectedHour, selectedParticipants]);

    const validarButtonSolicitar = async () => {
        if (getDate(selectedDate) >= getDate(nextFreeDay) && selectedHour !== null && selectedHour.value.length > 0 && selectedParticipants !== null && selectedParticipants.value.length > 0) {
            setValidateButton(true);
        } else {
            setValidateButton(false);
        }
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const esChile = (pais) => {
        return pais.name === 'Chile'
    }

    const stepNumber = (e, title) => {
        return <Label className="h5"><b className="etm_text_blue mr-2">{e}. </b>{title}</Label>
    }

    const changeDay = (day) => {
        setSelectedDate(day);
        if (getDate(day) < getDate(nextFreeDay)) {
            setShowSection({ startup: false, emprendedor: false, solicitar: true, solicitarOptions: false });
        } else {
            setShowSection({ startup: false, emprendedor: false, solicitar: true, solicitarOptions: true });
        }
    }

    const getHours = async (e) => {
        const res = await API.get('/meeting/getHoursList');
        let list = [];
        if (res.data.state) {
            res.data.hours.map((e, i) => {
                list.push({ value: e.HORA_INICIO, label: `${e.HORA_INICIO} - ${e.HORA_FIN}` });
            });
        }
        setListHours(list);
    }

    const preMakeMeeting = () => {
        setLoading({ data: true, btn: true });

        if (selectedHour === null || selectedHour.value.length < 1) {
            swal_etm('Debes seleccionar un horario válido');
            setLoading({ data: false, btn: false });
            return;
        }

        if (selectedParticipants === null || selectedParticipants.value.length < 1) {
            swal_etm('Selecciona un participante valido para la reunion');
            setLoading({ data: false, btn: false });
            return;
        }

        postMakeMeeting();
    }

    const postMakeMeeting = async () => {
        const res = await API.post('/challenger/makeMeeting', {
            request
            , expertoToken: selectedParticipants.value
            , emprendedorToken: dataUser.token
            , startupId: dataUser.startupId
            , day: getDate(selectedDate)
            , hour: selectedHour.value
            , selectedParticipants: (challengesRequestDescription.length > 0) ? challengesRequestDescription : ''
            , meetingType: 'COMERCIAL'
        });

        if (res.data.state) {
            swal_etm(res.data.msg, 'success');
        } else {
            swal_etm(res.data.msg);
        }
        setLoading({ data: false, btn: false })
        props.getChallenges();
        props.setModal(false);
    }

    const separator = (data, type) => {
        let new_data = data.reduce((prev, cur) => {
            return prev ? <Fragment>
                {prev}
                {type}
                {cur.name}
            </Fragment>
                : <Fragment>{cur.name}</Fragment>
        }, '')
        return new_data;
    }

    const changeTab = (tab, datos = null) => {
        setActiveTab(tab)
        if (tab === '1') {
            setShowButton({ listado: false, agenda: false, solicita: false })
        } else if (tab === '2') {
            if (datos !== null) {
                getEmprendedorData(datos.token, datos.startup)
                setChallengesRequestDescription(datos.descripcion)
                setRequest(datos.request)
                setSelectedDate(new Date())
                setSelectedHour(null)
                setSelectedDescription("")
            }
            setShowButton({ listado: true, agenda: true, solicita: false })
        } else if (tab === '3') {
            setShowButton({ listado: true, agenda: true, solicita: true })
        }
    }

    const getEmprendedorData = async (emprendedorToken, startupId) => {
        if (emprendedorToken !== null && startupId !== null) {
            setLoading({ data: true, btn: true });
            const res = await API.get('/search/getEntrepreneurData', { params: { token: emprendedorToken, startupId: startupId, meetingType: 'COMERCIAL' } });
            if (res.data.state) {
                setDataUser({
                    short_name: res.data.data.user.nombre_corto
                    , name: res.data.data.user.nombre
                    , profile: "Emprendedor"
                    , profile_img: logo_emprendedor
                    , photo: res.data.data.user.foto
                    , description: res.data.data.user.descripcion
                    , duration: res.data.data.user.duracion
                    , facebook: res.data.data.user.rrss.facebook
                    , instagram: res.data.data.user.rrss.instagram
                    , linkedin: res.data.data.user.rrss.linkedin
                    , twitter: res.data.data.user.rrss.twitter
                    , youtube: res.data.data.user.rrss.youtube
                    , token: emprendedorToken
                    , startupId
                });

                if (res.data.data.startups.length !== null && res.data.data.startups.length > 0) {
                    formatoComercial(res.data.data.startups);
                }
            }

            setTimeout(function () {
                setLoading({ data: false, btn: false });
            }, 1000);
        }
    }

    const getListTeamParticipants = async () => {
        const res = await API.get('/challenger/getListTeamParticipants', { params: { token: ls.token } });
        let list_obj = [];
        if (res.data.participants.length !== null && res.data.participants.length > 0) {
            res.data.participants.map((e, i) => {
                list_obj.push({ value: e.TOKEN, label: e.NOMBRE });
            });
        }
        setListTeamParticipants(list_obj);
    }

    const formatoComercial = (startupData) => {
        const format = [];
        const listImg = [];
        let indexImg = 0;

        if (startupData !== undefined && startupData !== null && startupData.length > 0) {
            format.push(
                <div key={-1}>
                    <Label className="h5 text-truncate mb-0 text-wrap text-muted">
                        {(startupData.length > 1) ? "Acerca de sus proyectos..." : "Acerca del proyecto..."}
                    </Label>
                    <br />
                </div>
            );

            startupData.map((e, i) => {
                const listImgHtml = [];

                if (e.images !== null && e.images !== undefined && e.images.length > 0) {
                    e.images.map((src, index) => {
                        listImg.push(src.IMG);
                        listImgHtml.push(
                            <img
                                src={src.IMG}
                                onClick={() => openImageViewer(indexImg)}
                                width="200"
                                key={indexImg}
                                style={{ margin: '2px' }}
                                alt=""
                                className="pointer etm_img_border etm_sombra m-2"
                            />
                        )
                        indexImg++;
                    });
                }

                format.push(
                    <div key={i}>
                        <Label className="h2 text-truncate etm_text_blue mb-0 text-wrap">
                            {capitalize(e.NOMBRE)}
                        </Label>
                        <br />
                        <Row>
                            {
                                (e.WEB !== undefined && e.WEB !== null && e.WEB !== "") ?
                                    <Col md={12}>
                                        <li className="list-inline-item mr-2">
                                            <Label className="h5">
                                                <a href={e.WEB} target="_blank" className="etm_link_blue">
                                                    <i className="fas fa-external-link-alt mr-2"></i>{e.WEB}
                                                </a>
                                            </Label>
                                        </li>
                                        <br />
                                    </Col>
                                    :
                                    ""
                            }
                            {
                                (e.DESCRIPCION !== undefined && e.DESCRIPCION !== null && e.DESCRIPCION !== "") ?
                                    <Col md={12}>
                                        <br />
                                        <Label className="h4">
                                            {flecha}<span className="text-muted">{e.DESCRIPCION}</span>
                                        </Label>
                                        <br />
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INDUSTRIA !== undefined && e.INDUSTRIA !== null && e.INDUSTRIA !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Industria: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INDUSTRIA}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.TIPO_COMERCIO !== undefined && e.TIPO_COMERCIO !== null && e.TIPO_COMERCIO !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Tipo Comercio: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.TIPO_COMERCIO}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INICIO_ACTIVIDADES !== undefined && e.INICIO_ACTIVIDADES !== null && e.INICIO_ACTIVIDADES !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Inicio Actividades: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INICIO_ACTIVIDADES}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.NUMERO_EMPLEADOS !== undefined && e.NUMERO_EMPLEADOS !== null && e.NUMERO_EMPLEADOS !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Nº Colaboradores: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.NUMERO_EMPLEADOS}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.billing !== null && e.billing.YEAR !== undefined && e.billing.YEAR !== null && e.billing.YEAR !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Rango de facturación: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.billing.BILLING}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INVERSIONES_RECIBIDAS !== undefined && e.INVERSIONES_RECIBIDAS !== null && e.INVERSIONES_RECIBIDAS !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Inversiones recibidas: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INVERSIONES_RECIBIDAS}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.countries !== undefined && e.countries !== null && e.countries.length > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Países donde opera: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.countries, <br />)}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.regions !== undefined && e.regions !== null && e.regions.length > 0 && e.countries !== undefined && e.countries !== null && e.countries.length > 0 && e.countries.find(esChile)) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Regiones donde opera: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.regions, <br />)}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.POLITICAS_IGUALDAD_GENERO !== undefined && e.POLITICAS_IGUALDAD_GENERO !== null && e.POLITICAS_IGUALDAD_GENERO > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Políticas de Igualdad de Género: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INCLUSION_IGUALDAD !== undefined && e.INCLUSION_IGUALDAD !== null && e.INCLUSION_IGUALDAD > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Políticas de Inclusión: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.LIDERADO_X_MUJERES !== undefined && e.LIDERADO_X_MUJERES !== null && e.LIDERADO_X_MUJERES > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Proyectos liderados por Mujeres: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.impacts !== undefined && e.impacts !== null && e.impacts.length > 0) ?
                                    <Col md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Causa de Impacto Social: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.impacts, " | ")}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.DESARROLLO_SOSTENIBLE !== undefined && e.DESARROLLO_SOSTENIBLE !== null && e.DESARROLLO_SOSTENIBLE !== "") ?
                                    <Col md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Desarrollo Sostenible: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.DESARROLLO_SOSTENIBLE}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                        </Row>
                        {listImgHtml}
                        <hr />
                    </div>
                );
            });
        }

        setStartups(format);
        setImages(listImg);
    }

    const getChallengesRequest = async () => {
        setLoading({ data: true, btn: true });
        const res = await API.get("/challenger/getChallengersRequest", { params: { challenge: props.challenge.id } })
        let list = [];
        if (res.data.state) {
            res.data.request.map((e, i) => {
                list.push({
                    correlative:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {i + 1}
                            </Label>
                        </Fragment>
                    , solicitante:
                        <Fragment>
                            {
                                (e.FOTO_SOLICITANTE === undefined && e.FOTO_SOLICITANTE === null) ?
                                    <div className="text-center">
                                        <img
                                            src={defaultSolicitante}
                                            alt={e.SOLICITANTE || ''}
                                            className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"
                                            key={i}
                                        />
                                    </div>
                                    :
                                    <div className="text-center">
                                        <img
                                            src={e.FOTO_SOLICITANTE}
                                            alt={e.SOLICITANTE || ''}
                                            className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"
                                            key={i}
                                        />
                                    </div>
                            }
                            <br />
                            <Label className='h4 mt-2'> {e.SOLICITANTE || ''}</Label>
                        </Fragment>
                    , proyecto:
                        <Fragment>
                            {
                                (e.FOTO_PROYECTO === undefined || e.FOTO_PROYECTO === null) ?
                                    <img
                                        src={defaultEmprendimientoFoto}
                                        alt={e.PROYECTO || ''}
                                        className="img-fluid w-50 etm_img_border etm_sombra"
                                        key={i}
                                    />
                                    :
                                    <img
                                        src={e.FOTO_PROYECTO}
                                        alt={e.PROYECTO || ''}
                                        className="img-fluid w-50  etm_img_border etm_sombra"
                                        key={i}
                                    />
                            }
                            <br />
                            <Label className='h4 mt-2'> {e.PROYECTO || ''}</Label>
                        </Fragment>
                    , industria:
                        <Fragment>
                            <Label className='h4 mb-0'>
                                {e.INDUSTRIA}
                            </Label>
                        </Fragment>
                    , estado:
                        <Fragment>
                            <Label className='h4 mb-0'>
                                <Badge color={(e.ESTADO === 'PENDIENTE') ? 'warning' : 'success'}>
                                    {e.ESTADO}
                                </Badge>
                            </Label>
                        </Fragment>
                    , fecha:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {`${e.FECHA_SOLICITUD}`}
                            </Label>
                        </Fragment>
                    , responsable:
                        <Fragment>
                            {
                                (e.RESPONSABLE === '-') ?
                                    ''
                                    :
                                    <div className="text-center">
                                        <img
                                            src={e.FOTO_RESPONSABLE}
                                            alt={e.RESPONSABLE || ''}
                                            className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"
                                            key={i}
                                        />
                                    </div>
                            }
                            <br />
                            <Label className='h4 mt-2'> {e.RESPONSABLE || ''}</Label>
                        </Fragment>
                    , opciones:
                        <Fragment>
                            <Row>
                                {
                                    (e.ESTADO === 'PENDIENTE') ?
                                        <Col md={12} className="mt-1">
                                            <Button
                                                block={true}
                                                color="primary"
                                                onClick={() => changeTab('2', { request: e.ID, token: e.SOLICITANTE_TOKEN, startup: e.PROYECTO_ID, descripcion: e.POSTULACION })}
                                                className=""
                                                id={`btn_1_${i}`}
                                            >
                                                <i className="fas fa-check-circle fa-lg mr-2"></i> GESTIONAR
                                            </Button>
                                        </Col>
                                        : ""
                                }
                            </Row>
                        </Fragment>
                });
            });
        }
        setChallengesRequest(list);
        setLoading({ data: false, btn: false });
    }

    const columns = [{
        dataField: 'correlative'
        , text: '#'
        , width: "1%"
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'solicitante'
        , text: 'SOLICITANTE'
        , width: "15%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'proyecto'
        , text: 'EMPRENDIMIENTO'
        , width: "16%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'industria'
        , text: 'INDUSTRIA'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'estado'
        , text: 'ESTADO'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'fecha'
        , text: 'FECHA SOLICITUD'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'responsable'
        , text: 'RESPONSABLE'
        , width: "15%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'opciones'
        , text: 'OPCIONES'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }];

    return (
        <Fragment>
            <Modal isOpen={props.modal} size="xl" scrollable={true}>
                <ModalHeader className="etm_modal_header text-white">
                    <Label className="text-white">{props.challenge.name}</Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    <Row>
                        <Col lg={12} md={12} sm={12} className="text-lg-left text-center animate__animated animate__fadeIn">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Card>
                                        <CardBody>
                                            <ToolkitProvider
                                                keyField="ID"
                                                data={challengesRequest}
                                                columns={columns}
                                            >
                                                {
                                                    props => (
                                                        <div>
                                                            <h3 className="etm_text_blue mb-4"><i className="fas fa-graduation-cap mr-2"></i>Listado de Solicitudes</h3>
                                                            {/* <SearchBar placeholder="Buscar..." className="etm_input_text" {...props.searchProps} /> */}
                                                            <BootstrapTable
                                                                keyField="ID"
                                                                pagination={paginationFactory()}
                                                                {...props.baseProps}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    {
                                        (loading.data) ?
                                            'Cargando...'
                                            :
                                            <Row>
                                                <Col sm={4} className="text-lg-left text-center animate__animated animate__fadeIn">
                                                    <Container className='ml-3 mr-3'>
                                                        <Row className="mt-lg-2">
                                                            <Col lg={12} md={12} xs={12} className="text-center">
                                                                <Label className="h5 text-muted">Acerca del Emprendedor/a...</Label>
                                                                <div className="mb-2 mt-2">
                                                                    <img src={dataUser.photo} className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg" alt="" />
                                                                </div>
                                                                <Label className="h4 text-truncate etm_text_blue mb-0">{capitalize(dataUser.name)}</Label>
                                                                <ul className="list-inline mt-lg-4 mt-2">
                                                                    {
                                                                        (dataUser.facebook.length > 0) ?
                                                                            <li className="list-inline-item mr-2">
                                                                                <Label className="h4">
                                                                                    <a href={cleanFacebook(dataUser.facebook)} target="_blank">
                                                                                        <i className="fab fa-facebook-square facebook"></i>
                                                                                    </a>
                                                                                </Label>
                                                                            </li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        (dataUser.instagram.length > 0) ?
                                                                            <li className="list-inline-item mr-2">
                                                                                <Label className="h4">
                                                                                    <a href={cleanInstagram(dataUser.instagram)} target="_blank">
                                                                                        <i className="fab fa-instagram instagram"></i>
                                                                                    </a>
                                                                                </Label>
                                                                            </li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        (dataUser.linkedin.length > 0) ?
                                                                            <li className="list-inline-item mr-2">
                                                                                <Label className="h4">
                                                                                    <a href={cleanLinkedin(dataUser.linkedin)} target="_blank">
                                                                                        <i className="fab fa-linkedin linkedin"></i>
                                                                                    </a>
                                                                                </Label>
                                                                            </li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        (dataUser.twitter.length > 0) ?
                                                                            <li className="list-inline-item mr-2">
                                                                                <Label className="h4">
                                                                                    <a href={cleanTwitter(dataUser.twitter)} target="_blank">
                                                                                        <i className="fab fa-twitter-square twitter"></i>
                                                                                    </a>
                                                                                </Label>
                                                                            </li>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        (dataUser.youtube.length > 0) ?
                                                                            <li className="list-inline-item mr-2">
                                                                                <Label className="h4">
                                                                                    <a href={cleanYoutube(dataUser.youtube)} target="_blank">
                                                                                        <i className="fab fa-youtube youtube"></i>
                                                                                    </a>
                                                                                </Label>
                                                                            </li>
                                                                            : ""
                                                                    }
                                                                </ul>
                                                                <Row className="mt-lg-2">
                                                                    <Col lg={2} md={2} xs={5} className="text-right">
                                                                        <img src={dataUser.profile_img} className="avatar-xs rounded-circle fix_img shadow-lg" alt="" />
                                                                    </Col>
                                                                    <Col lg={10} md={4} xs={7} className="text-left">
                                                                        <Label className="h5 mt-1 text-truncate font-weight-medium">{dataUser.profile}</Label>
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    (dataUser.description.length > 0) ?
                                                                        <Label className="h5">{flecha}<span className="text-muted">{dataUser.description}</span></Label>
                                                                        : ""
                                                                }
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                </Col>
                                                <Col sm={8} className="text-lg-left text-center animate__animated animate__fadeIn">
                                                    <Row>
                                                        <Col md={12}>
                                                            <Container>
                                                                {startups}
                                                                <br />
                                                                {isViewerOpen && (
                                                                    <ImageViewer
                                                                        src={images}
                                                                        currentIndex={currentImage}
                                                                        onClose={closeImageViewer}
                                                                        disableScroll={false}
                                                                        backgroundStyle={{
                                                                            backgroundColor: "rgba(0,0,0,0.9)"
                                                                        }}
                                                                        closeOnClickOutside={true}
                                                                    />
                                                                )}
                                                            </Container>
                                                        </Col>
                                                        <Col md={12} className="mb-2">
                                                            <Label className="h3 text-truncate etm_text_blue mb-0 text-wrap">
                                                                Descripción de la Postulación:
                                                            </Label>
                                                            <br />
                                                            {
                                                                (challengesRequestDescription.length > 0) ?
                                                                    <Label className="h5">{flecha}<span className="text-muted">{challengesRequestDescription}</span></Label>
                                                                    : ""
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                    }
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row>
                                        <Col sm={12} className="text-lg-left text-center animate__animated animate__fadeIn">
                                            <Row>
                                                <Col lg={6} md={12}>
                                                    <div className="mt-2 text-center">
                                                        {stepNumber(1, "Solicítale una reunión el día y hora que desees")}
                                                    </div>
                                                    <br />
                                                    <Row>
                                                        <Col xs={12} className="mt-3">
                                                            <div className="calendar text-center" hidden={(isViewerOpen)}>

                                                                {/* Controls */}
                                                                <div className="controls">
                                                                    <div>
                                                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                                                        <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                                                    </div>
                                                                </div>

                                                                {/* Calendar head with day names */}
                                                                <div className="calendar_head">
                                                                    {
                                                                        shortDayNames.map(day => (
                                                                            <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                                                {spanishDate(day)}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                                {/* Calendar */}
                                                                <div className="calendar_body">
                                                                    {
                                                                        items.map(day => {
                                                                            const activeClass = !day.active ? "inactive" : "";
                                                                            let selectedClass = day.selected ? "selected" : "";
                                                                            const strike = (day.fullDate < strikeDate) ? "etm_calendar_strike_text" : "";
                                                                            return (
                                                                                <div
                                                                                    className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                                                    key={day.fullDate}
                                                                                    onClick={() => {
                                                                                        selectDate(day.fullDate);
                                                                                        // setSelectedDate(day.fullDate);
                                                                                        changeDay(day.fullDate);
                                                                                    }}
                                                                                >
                                                                                    {day.date}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} className="mt-md-5 mt-3 text-center">
                                                            <Label className="h6 text-muted"><i className="fas fa-globe-americas mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg={6} md={12}>
                                                    <Container>
                                                        <div hidden={!showSection.solicitarOptions} className="animate__animated animate__fadeIn">
                                                            {stepNumber(2, "Selecciona un horario disponible")}
                                                            <FormGroup className="mb-4" check>
                                                                <Label className="h6 text-muted"><i className="fas fa-clock etm_text_blue mr-2"></i>Recuerda que la reunión dura {dataUser.duration}</Label>
                                                                <br />
                                                                <br />
                                                                <Row>
                                                                    <Col md={6} sm={12}>
                                                                        <Select
                                                                            placeholder="Elige uno de los horarios disponibles"
                                                                            value={selectedHour}
                                                                            onChange={setSelectedHour}
                                                                            options={listHours}
                                                                            className="etm_input_select"
                                                                            styles={selectCustomStyle()}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                            </FormGroup>
                                                            {stepNumber(3, "Selecciona quién del equipo participará a la reunión")}
                                                            <FormGroup className="mt-3" check>
                                                                <Select
                                                                    placeholder="Indícanos el objetivo de esta reunión"
                                                                    value={selectedParticipants}
                                                                    onChange={setSelectedParticipants}
                                                                    options={listTeamParticipants}
                                                                    className="etm_input_select"
                                                                    styles={selectCustomStyle()}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                        <Col lg={12} md={12} sm={12} className="text-right mt-2">
                            {
                                (loading.data) ?
                                    ''
                                    :
                                    <Row>
                                        <Col hidden={(!showButton.listado)}>
                                            <Button
                                                color="danger"
                                                onClick={() => changeTab('1')}
                                                className="etm_btn_outline pl-5 pr-5"
                                                size="lg"
                                                block={true}
                                                outline={true}
                                            >
                                                <i className={`fas fa-arrow-left mr-2`}></i>
                                                Volver al Listado
                                            </Button>
                                        </Col>
                                        <Col hidden={(!showButton.agenda)}>
                                            <Button
                                                color="primary"
                                                onClick={() => changeTab((showButton.agenda && !showButton.solicita) ? '3' : '2')}
                                                className="etm_btn pl-5 pr-5"
                                                size="lg"
                                                block={true}
                                                outline={(showButton.agenda && !showButton.solicita) ? false : true}
                                            >
                                                <i className={`fas ${(showButton.agenda && !showButton.solicita) ? 'fa-user-friends' : 'fa-arrow-left'} mr-2`}></i>
                                                {(showButton.agenda && showButton.solicita) ? `Volver a información` : `¡Agenda una reuion!`}
                                            </Button>
                                        </Col>
                                        <Col hidden={(!showButton.solicita)}>
                                            <Button
                                                color="success"
                                                onClick={() => preMakeMeeting()}
                                                className={`etm_btn pl-5 pr-5 ${(validateButton) ? `btn-lg` : ``} `}
                                                size="lg"
                                                block={true}
                                                disabled={(validateButton) ? false : true}
                                                outline={(showButton.solicita) ? false : true}
                                            >
                                                <i className={`fas fa-check-circle mr-2`}></i>
                                                ¡ SOLICITA LA REUNIÓN !
                                            </Button>
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-times mr-2"></i> Cerrar</u></a>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}


export default ModalChallengeRequest;