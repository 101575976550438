import React, { useState }from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Input } from "reactstrap";
import { API } from '../../helpers/axios';
import swal from 'sweetalert';
import { getDate, validateRol } from '../../helpers/etmmeetUtils';
import LoadBtn from "../../components/Utils/LoadBtn";

const PendingListModalCancel = ( props ) => {
    
    const[motivo, setMotivo]    = useState("");
    const[loading, setLoading]  = useState(false);
    
    const cancelPending = async () => {
        setLoading(true);
        if(motivo.length < 3){
            swal("Ups!", "Ingresa un motivo válido!", "warning");
            setLoading(false);
            return false;
        }
        postCancelPending();
    };

    const postCancelPending = async () => {
        const res = await API.put('/cancelPending', { request_id: props.requestId, motivo });
        if(res.data.state){
            swal("Solicitud Cancelada!", res.data.msg, "success");
        }else{
            swal("Ups!", res.data.msg, "warning");
        }
        props.getPending();
        setLoading(false);
        props.setModal(!props.modal);
    };

    return (
        <React.Fragment>
            <Modal isOpen={ props.modal } size={ 'md' } scrollable={ true } toggle={ props.showModal }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <FormGroup className="mb-5">
                                <h4 className="etm_text_blue text-center mt-5 mb-5"><b>¿Estás seguro de cancelar esta solicitud?</b></h4>
                            </FormGroup>
                            <FormGroup className="mt-5">
                                <h6 className="text-muted">Ingresa el <b className="etm_text_blue">motivo</b> de por qué quieres cancelar esta solicitud<b className="etm_required_asterisco ml-1">*</b></h6>
                                <Input type="textarea" name="motivo" rows="5" onChange={ (e) => setMotivo(e.target.value) } placeholder="Máximo 300 caractéres" maxLength="300" className="etm_textarea"></Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <Button color="light" onClick={ props.showModal } className="etm_btn"><i className="fas fa-times"></i> Volver</Button>{' '}
                    <LoadBtn color="danger" handle={ cancelPending } loading={ loading } title="Cancelar Solicitud" icon="trash"></LoadBtn>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default PendingListModalCancel;