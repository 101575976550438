import React, { useState, Fragment } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import LoadBtn from '../Utils/LoadBtn';
import { validateEmail } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';

const Contact = (props) => {
    const [name, setName]                       = useState("");
    const [lastname, setLastname]               = useState("");
    const [email, setEmail]                     = useState("");
    const [message, setMessage]                 = useState("");
    const [errorName, setErrorName]             = useState(false);
    const [errorLastname, setErrorLastname]     = useState(false);
    const [errorEmail, setErrorEmail]           = useState(false);
    const [errorMessage, setErrorMessage]       = useState(false);
    const [successMessage, setSuccessMessage]   = useState(false);
    const [loading, setLoading]                 = useState(false);

    const changeName = (e) => {
        setSuccessMessage(false);
        setErrorName(false);
        setName(e);
    }

    const changeLastname = (e) => {
        setSuccessMessage(false);
        setErrorLastname(false);
        setLastname(e);
    }

    const changeEmail = (e) => {
        setSuccessMessage(false);
        setErrorEmail(false);
        setEmail(e);
    }

    const changeMessage = (e) => {
        setSuccessMessage(false);
        setErrorMessage(false);
        setMessage(e);
    }

    const sendContact = (e) => {
        setLoading(true);
        e.preventDefault();

        if (name.length < 2) {
            setErrorName(true);
            setLoading(false);
            return false;
        }
        if (lastname.length < 2) {
            setErrorLastname(true);
            setLoading(false);
            return false;
        }
        if (!validateEmail(email)) {
            setErrorEmail(true);
            setLoading(false);
            return false;
        }
        if (message.length < 10) {
            setErrorMessage(true);
            setLoading(false);
            return false;
        }
        postSendContact( name, lastname, email, message );
    }

    const postSendContact = async ( name, lastname, email, message ) => {
        const res = await API.post('/contact_us', { name, lastname, email, message });
        setLoading(false);
        setSuccessMessage(true);
        setName("");
        setLastname("");
        setEmail("");
        setMessage("");
    }

    return (
        <Fragment>
            <Row className="contact pt-5 pb-5" id="contact">
                <Container>
                    <Row>
                        <Col md={6} sm={12} className="text-center my-auto">
                            <p className="h1 contact_title">¿Tienes dudas?</p>
                            <p className="h1 contact_subtitle">¡Escríbenos!</p>
                        </Col>
                        <Col md={6} sm={12} className="pt-md-0 pt-3 ml-md-0 ml-3 mr-md-0 mr-3">
                            <Container>
                                <Form>
                                    <FormGroup className="mb-4">
                                        <Label className="h4 contact_title">Tu nombre</Label>
                                        {
                                            (errorName) ? <b className="text-danger ml-3"><u>¡Debes ingresar un Nombre válido!</u></b> : ""
                                        }
                                        <Input onChange={ (e) => changeName(e.target.value) } className="contact_input" name="name" id="name" type="text" placeholder="Ingresa tu nombre" required/>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Label className="h4 contact_title">Tu Apellido</Label>
                                        {
                                            (errorLastname) ? <b className="text-danger ml-3"><u>¡Debes ingresar un Apellido válido!</u></b> : ""
                                        }
                                        <Input onChange={ (e) => changeLastname(e.target.value) } className="contact_input" name="lastname" id="lastname" type="text" placeholder="Ingresa tu apellido" required/>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Label className="h4 contact_title">Tu Email</Label>
                                        {
                                            (errorEmail) ? <b className="text-danger ml-3"><u>¡Debes ingresar un Email válido!</u></b> : ""
                                        }
                                        <Input onChange={ (e) => changeEmail(e.target.value) } className="contact_input" name="email" id="email" type="email" placeholder="Ingresa tu email" required/>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Label className="h4 contact_title">¿Por qué nos escribes?</Label>
                                        {
                                            (errorMessage) ? <b className="text-danger ml-3"><u>¡Debes ingresar tu mensaje de al menos 10 caracteres!</u></b> : ""
                                        }
                                        <Input onChange={ (e) => changeMessage(e.target.value) } className="contact_input" name="message" id="message" type="textarea" placeholder="Escríbenos tu mensaje" required/>
                                    </FormGroup>
                                    <div className="col d-flex justify-content-center mt-5">
                                        <LoadBtn handle={ sendContact } loading={ loading } title="Enviar" className={ "pl-5 pr-5 contact_btn"}/>
                                    </div>
                                    {
                                        (successMessage) ? 
                                            <div className="col d-flex justify-content-center mt-2">
                                                <h4 className="formtitle"><b className="alert_message mt-3">Correo enviado!</b></h4>
                                            </div>
                                        :   ""
                                    }
                                    
                                </Form>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Fragment>
    );
}
export default (Contact);


