import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { withRouter } from "react-router-dom";
import etm_video_permisos_camera_micro from "../../assets/images/conecTime/permisos.png";

 const ModalShareData = ( props ) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => { 
        setShowModal(props.showModal);
    }, [props.showModal]);

    const handleModal = () => {
        setShowModal(!showModal);
    }
   
    return (
        <Fragment>
            <Modal isOpen={ showModal } size="lg" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="p-4 text-center">
                                <h3 className="font-16 m-b-15">Permite que <span className="etm_text_blue"><b>EtM</b><i className="etm_text_blue">meet</i></span> utilice tu cámara y tu micrófono</h3>
                                <h4 className="mt-3"><small><span className="etm_text_blue"><b>EtM<i className="etm_text_blue">meet</i></b></span> necesita acceder a tu cámara y micrófono para que los otros participantes te puedan ver y oír. Una vez otorgues los permisos te recomendamos <span className="etm_text_blue"><b>recargar esta pestaña.</b></span></small></h4>
                            </div>
                            <div className="p-1 text-center">
                                <img src={ etm_video_permisos_camera_micro } className="etm_video_permisos_camera_micro"></img>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <h5 className="etm_link_blue pointer" onClick={ handleModal }>Cerrar</h5>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalShareData);