import React, { useState, useEffect, Fragment } from 'react';

import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardSubtitle, Label, UncontrolledTooltip, FormGroup, Input } from "reactstrap";
import { API } from '../../helpers/axios';
import "../../assets/scss/etm.scss";
import Select from 'react-select';

import { getLocalStorage, swal_etm } from "../../helpers/etmmeetUtils";

import LoadBtn from "../../components/Utils/LoadBtn";
import ModalChalleng from "../Challenges/modals/ModalChalleng";

const CardsChallenges = (props) => {
    const ls = getLocalStorage();
    const textFilter = "Selecciona o escribe";

    const [searchKey, setSearchKey] = useState("");
    const [searchIndustry, setSearchIndustry] = useState({ value: "", label: "" });
    const [searchManagement, setSearchManagement] = useState([]);

    const [industries, setIndustries] = useState([]);
    const [managements, setManagements] = useState([]);

    const [challenges, setChallenges] = useState([]);
    const [modalCrateChallenges, setModalCrateChallenges] = useState(false);
    const [modalType, setModalType] = useState('');

    const [loading, setLoading] = useState(false);
    const [loadscreen, setLoadscreen] = useState(false);
    const [dataSearch, setDataSearch] = useState([]);
    const [dataSearchFilter, setDataSearchFilter] = useState([]);
    const [dataSearchMore, setDataSearchMore] = useState([]);

    useEffect(() => {
        getIndustries();
        getManagements();
        filter();
    }, []);

    useEffect(() => {
        getMoreResult();
    }, [dataSearchMore]);

    const getIndustries = async () => {
        const res = await API.get('/search/experto/getMacroIndustries', { params: { type: "COMERCIAL" } });
        if (res.data.state) {
            setIndustries([]);
            let list = [];
            list.push({ value: "", label: " " + textFilter });
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRY });
            });
            setIndustries(list);
        }
    }

    const getManagements = async () => {
        const res = await API.get('/search/experto/getManagements');
        if (res.data.state) {
            setManagements([]);
            let list = [];
            list.push({ value: "", label: " " + textFilter });
            res.data.managements.map((e, i) => {
                list.push({ value: e.ID, label: e.MANAGEMENT });
            });
            setManagements(list);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setLoadscreen(true);
        setLoading(true);
        filter();
    };

    const filter = async () => {
        const params = {
            key: searchKey
            , industry: (searchIndustry === null) ? "" : searchIndustry.value
            , management: (searchManagement === null) ? "" : searchManagement.value
            , start: 0
            , token: ls.token
        }
        const res = await API.get("/challenger/getChallengersCards", { params })

        setDataSearch([]);
        setDataSearchFilter([]);
        if (res.data.state) {
            setDataSearch(res.data.challengers);
            setDataSearchFilter(params);
            getDesafios(res.data.challengers);
        }else{
            setChallenges([]);
            swal_etm(res.data.msg, "warning", "Ups!");
        }
        cleanFilter();
        setLoading(false);
        setLoadscreen(false);
    };

    const showMore = async (start, search) => {
        document.getElementById("div_2_" + start).hidden = false;
        document.getElementById("div_3_" + start).hidden = true;
        const params = {
            key: search.key
            , industry: search.industry
            , management: search.management
            , start: start
            , token: ls.token
        }
        const res = await API.get('/challenger/getChallengersCards', { params });
        setDataSearchMore([]);

        if (res.data.state) {
            setDataSearchMore(res.data.result);
        }
    };

    const getMoreResult = () => {
        if (dataSearch.length > 0) {
            if (dataSearchMore.length > 0) {
                const list = getDesafios(dataSearchMore);
                setChallenges(challenge => challenge.concat(list));
            }
        }
    }

    const cleanFilter = () => {
        setSearchKey("");
        setSearchIndustry(null);
        setSearchManagement(null);
    }

    const openModal = (type, id = 0) => {
        setModalType({ type: type, id: id })
        setModalCrateChallenges(true)
    }

    const getDesafios = async (desafios) => {
        let list = [];
        let totalCard = 0;
        let filtered = 0;

        desafios.map((e, i) => {
            totalCard = e.TOTAL;
            filtered = e.ROWNUM;
            list.push(
                <Col lg={3} md={4} sm={12} key={i}>
                    <Card
                        color="light"
                        style={{ borderRadius: "20px", height: "250px" }}
                        className="mb-5 etm_sombra pointer"
                        onClick={() => openModal('detalle', e.ID)}
                    >
                        <CardHeader className="p-0 m-0">
                            <div className="profile-user-wid text-center">
                                <img 
                                    src={e.FOTO} 
                                    alt={e.DESAFIO}
                                    className="img-fluid fix_img etm_home_challenge_img"
                                    style={{ borderStartEndRadius: "20px", borderStartStartRadius: "20px" }}
                                />
                            </div>
                        </CardHeader>
                        <CardBody className="mt-0 mb-0">
                            <CardTitle className="mt-0 mb-0 text-black">{e.DESAFIO}</CardTitle>
                            <CardSubtitle className="mt-0 mb-0">{e.DESCRIPCION_CORTA}</CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
            );
        });
        if (totalCard > filtered) {
            list = list.concat(
                <Col xl={12} id={"div_1_" + filtered} key={"div_" + filtered} className="mb-3 mt-3">
                    <div className="text-center" id={"div_2_" + filtered} hidden={true}>
                        <div className="spinner-grow m-1" role="status" style={{ color: "#5ac7e5" }}></div>
                        <div className="spinner-grow m-1" role="status" style={{ color: "#1b9dd9" }}></div>
                        <div className="spinner-grow m-1" role="status" style={{ color: "#2857b4" }}></div>
                        <div className="spinner-grow m-1" role="status" style={{ color: "#3c518f" }}></div>
                    </div>
                    <Col className="text-center mb-2" id={"div_3_" + filtered}>
                        <button className="btn btn-primary etm_btn pl-5 pr-5" onClick={() => showMore(filtered, dataSearchFilter)}>Ver más</button>
                    </Col>
                </Col>);
        }

        setChallenges(list);
    };

    // SELECT 2 STYLE 
    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "$fff" : 0,
            borderColor: state.isFocused ? "#fff" : "#fff", "&:hover": {
                borderColor: state.isFocused ? "#fff" : "#fff"
            }
        })
    };

    return (
        <Fragment>
            <Card>
                <CardTitle className="mt-3 ml-3  mr-3 mb-0 text-center">
                    <form>
                        <Row className="mt-xl-2 mt-lg-2 mt-0">
                            <Col lg={12} className="text-center mb-0">
                                <h4>BUSCA ALGUN DESAFÍO</h4>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mt-xl-2 mt-lg-2 mt-0">
                            <Col lg={4}>
                                <FormGroup>
                                    <i className="fas fa-info-circle ml-3 mr-1 etm_text_blue mt-0 mb-0" id="input_key">
                                        <UncontrolledTooltip
                                            innerClassName="bg-info"
                                            delay={0}
                                            placement="right"
                                            target="input_key"
                                        >
                                            Escribe una <b>PALABRA CLAVE</b> para buscar resultados
                                        </UncontrolledTooltip>
                                    </i>
                                    <Label className="ml-1 mt-0 mb-1">Palabra Clave</Label>
                                    <Input name="input_key" className="form-control etm_input_text" type="text" disabled={loadscreen} placeholder={(loadscreen) ? "Cargando..." : "Ingrese una palabra"} value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <i className="fas fa-info-circle ml-3 mr-1 etm_text_blue mt-0 mb-0" id="input_company">
                                        <UncontrolledTooltip
                                            innerClassName="bg-info"
                                            delay={0}
                                            placement="right"
                                            target="input_company"
                                        >
                                            Selecciona 1 de la lista de <b>INDUSTRIAS</b>
                                        </UncontrolledTooltip>
                                    </i>
                                    <Label className="ml-1 mt-0 mb-0">Industria </Label>
                                    <Select
                                        placeholder={(loadscreen) ? "Cargando..." : textFilter}
                                        value={(loadscreen) ? "Cargando..." : (searchIndustry === null) ? textFilter : searchIndustry}
                                        onChange={setSearchIndustry}
                                        options={industries}
                                        className="etm_input_select text-left"
                                        styles={customStyles}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <i className="fas fa-info-circle ml-3 mr-1 etm_text_blue mt-0 mb-0" id="input_management">
                                        <UncontrolledTooltip
                                            innerClassName="bg-info"
                                            delay={0}
                                            placement="right"
                                            target="input_management"
                                        >
                                            Selecciona 1 de la lista de<b>GERENCIAS</b>
                                        </UncontrolledTooltip>
                                    </i>
                                    <label className="ml-1 mt-0 mb-0">Gerencia</label>
                                    <Select
                                        placeholder={(loadscreen) ? "Cargando..." : textFilter}
                                        value={(loadscreen) ? "Cargando..." : (searchManagement === null) ? textFilter : searchManagement}
                                        onChange={setSearchManagement}
                                        options={managements}
                                        className="etm_input_select  text-left"
                                        styles={customStyles}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="d-flex justify-content-center">
                                <LoadBtn
                                    handle={handleSearch}
                                    loading={loading}
                                    title="Buscar"
                                    className="pl-5 pr-5"
                                    icon="search">
                                </LoadBtn>
                            </Col>
                        </Row>
                    </form>
                    <hr />
                </CardTitle>
                <CardBody>
                    <Row className="m-0 p-0">
                        {
                            (challenges.length > 0) ?
                                challenges
                                : ""
                        }
                    </Row>
                </CardBody>
            </Card>
            <ModalChalleng
                modal={modalCrateChallenges}
                setModal={setModalCrateChallenges}
                type={modalType}
            />
        </Fragment>
    );
}

export default CardsChallenges;