import React, { useState } from 'react';
import { Container, Row, Col, FormGroup, Card } from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';
import QRCode from "qrcode.react";
import { isMobile } from "../../helpers/etmmeetUtils";

const QRtester = () => {
    
    const [stringQR, setStringQR]       = useState("https://www.etmmeet.org/");
    const [qrSize, setQrSize]           = useState((isMobile()) ? 256 : 400);

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'QR Tester'}/>
                <Container fluid>
                    <Card>
                        <Col xs={12} className="pt-3">
                            <Row>
                                <Col xs={12}>
                                    <h4 className="etm_text_blue mb-3"><i className="fas fa-qrcode mr-2"></i>QR Tester</h4>
                                </Col>
                                <Col md={4} xs={12} className="pt-5">
                                    <Container>
                                        <FormGroup>
                                            <label>Ingresa el valor del QR que deseas validar</label>
                                            <input name="qr_input" id="qr_input" className="form-control etm_input_text" type="text" placeholder="Ingrese el valor del QR" onKeyUp={(e) => setStringQR(e.target.value)}/>
                                        </FormGroup>
                                    </Container>
                                </Col>
                                <Col md={8} xs={12} className="p-3 text-center">
                                    <QRCode id="qr" value={ stringQR } size={ qrSize } level="H" />
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(QRtester);