import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Row, Col, FormGroup, Label, Input, Container, InputGroup } from "reactstrap";
import ReactInputVerificationCode from 'react-input-verification-code';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { API } from '../../helpers/axios';
import { validateRut, rutInput, validateEmail, swal_etm, getDate, cleanLinkedin, cleanFacebook, cleanInstagram, cleanTwitter, selectCustomStyle, getLocalStorage } from "../../helpers/etmmeetUtils";
import LoadBtn from "../Utils/LoadBtn";
import LoadScreen from "../Utils/LoadScreen";
import { setSessionUpdateUser } from '../../helpers/authUtils';
import { useHistory } from "react-router-dom";

const PersonalData = (props) => {
    const history = useHistory();
    const arrow = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;

    const default_chile = { value: 81, label: "Chile" };
    const date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia = getDate(primerDia, true).replace(/\-/g, '/');
    let primerDiaFormat = getDate(primerDia).replace(/\-/g, '/');

    const [startDate, setStartDate] = useState(primerDia);
    const [gettingData, setGettingData] = useState(true);
    const [loading, setLoading] = useState({ save: false, saveNext: false, whatsapp: false, sms: false });
    const [showMessageAge, setShowMessageAge] = useState(false);
    const [showActualCity, setShowActualCity] = useState(false);
    const [listCountries, setListCountries] = useState([]);
    const [listCities, setListCities] = useState([]);
    const [oldPhone, setOldPhone] = useState({ phone: null, equals: true });

    const [email, setEmail] = useState({ value: "", valid: false, invalid: false });
    const [dni, setDni] = useState({ rut: "", pasaporte: "", type: "RUT", valid: false, invalid: false });
    const [name, setName] = useState({ value: "", valid: false, invalid: false });
    const [lastname, setLastname] = useState({ value: "", valid: false, invalid: false });
    const [gender, setGender] = useState('MASCULINO');
    const [age, setAge] = useState(primerDiaFormat);
    const [country, setCountry] = useState(default_chile); //  POR DEFECTO CHILE
    const [actualCountry, setActualCountry] = useState(default_chile);    //  POR DEFECTO CHILE
    const [actualCity, setActualCity] = useState(null);
    const [phone, setPhone] = useState("");
    const [validatePhoneCode, setValidatePhoneCode] = useState({ value: '', validate: false });
    const [rrss, setRrss] = useState({ linkedin: "", facebook: "", instagram: "", twitter: "", youtube: "" });
    const [description, setDescription] = useState({ value: "", valid: false, invalid: false });

    useEffect(() => {
        getPersonalData();
        getCountries();
    }, []);

    useEffect(() => {
        getCities();
    }, [actualCountry]);

    useEffect(() => {
        if (getDate(age) !== getDate(primerDiaFormat)) {
            if (validateAge()) {
                setShowMessageAge(false);
            } else {
                setShowMessageAge(true);
            }
        }
    }, [age]);

    useEffect(() => {
        if (phone !== undefined && phone !== null && phone.length > 1) {
            setOldPhone({
                phone: parseInt(oldPhone.phone)
                , equals: (oldPhone.phone !== null && parseInt(oldPhone.phone) === parseInt(phone)) ? true : false
            });
            setValidatePhoneCode({
                value: validatePhoneCode.value
                , validate: (oldPhone.phone !== null && parseInt(oldPhone.phone) === parseInt(phone)) ? true : false
            });
        }
    }, [phone]);

    const getPersonalData = async () => {
        const res = await API.get('/profile/getPersonalData', { params: { token: getLocalStorage().token } });
        if (res.data.state) {
            changeData("EMAIL", res.data.data.email);
            changeData("NAME", res.data.data.nombre);
            changeData("LASTNAME", res.data.data.apellidos);
            changeData(res.data.data.dni.tipo, res.data.data.dni.dni);
            changeData("DESCRIPTION", (res.data.data.descripcion !== null) ? res.data.data.descripcion : "");
            setGender((res.data.data.genero !== null) ? res.data.data.genero : "MASCULINO");
            setStartDate((res.data.data.fecha_nacimiento !== null) ? getDate(res.data.data.fecha_nacimiento, true).replace(/\-/g, '/') : primerDia);
            setAge((res.data.data.fecha_nacimiento !== null) ? getDate(res.data.data.fecha_nacimiento) : primerDiaFormat);
            setOldPhone({
                phone: (res.data.data.telefono !== null) ? res.data.data.telefono : null
                , equals: true
            });
            setPhone((res.data.data.telefono !== null) ? res.data.data.telefono : "");

            setValidatePhoneCode({
                value: validatePhoneCode.value
                , validate: true
            });
            setRrss({
                linkedin: ((res.data.data.rrss.linkedin !== null) ? res.data.data.rrss.linkedin : "")
                , facebook: ((res.data.data.rrss.facebook !== null) ? res.data.data.rrss.facebook : "")
                , instagram: ((res.data.data.rrss.instagram !== null) ? res.data.data.rrss.instagram : "")
                , twitter: ((res.data.data.rrss.twitter !== null) ? res.data.data.rrss.twitter : "")
                , youtube: ((res.data.data.rrss.youtube !== null) ? res.data.data.rrss.youtube : "")
            });

            (res.data.data.pais_origen.id !== null) ?
                setCountry({
                    value: res.data.data.pais_origen.id
                    , label: res.data.data.pais_origen.nombre
                })
                : setCountry(null);

            (res.data.data.pais_actual.id !== null) ?
                setActualCountry({
                    value: res.data.data.pais_actual.id
                    , label: res.data.data.pais_actual.nombre
                })
                : setActualCountry(null);

            (res.data.data.ciudad_actual.id !== null) ?
                setActualCity({
                    value: res.data.data.ciudad_actual.id
                    , label: res.data.data.ciudad_actual.nombre
                })
                : setActualCity(null);
        }
        setGettingData(false);
    }

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        let list = [];
        if (res.data.state) {
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS, key: i });
            });
        }
        setListCountries(list);
    }

    const getCities = async () => {
        if (actualCountry !== null) {
            const res = await API.get("/general/getCities", { params: { country_id: actualCountry.value } });
            let list = [];
            if (res.data.state) {
                res.data.cities.map((e, i) => {
                    list.push({ value: e.ID, label: e.CIUDAD, key: i });
                });
                setShowActualCity(true);
            } else {
                setShowActualCity(false);
            }
            setListCities(list);
        }
    }

    const validateAge = () => {
        const today = new Date()
        const birth = new Date(age)
        const edad = today.getFullYear() - birth.getFullYear()
        if (edad < 18) {
            return false;
        } else {
            return true;
        }
    }

    const changeAge = (event, picker) => {
        setAge(getDate(picker.startDate));
    }

    const enviarCodigo = async (type) => {
        if (phone.length < 5) {
            swal_etm(`Debes ingresar un número válido`);
            return false;
        }

        if (type === "WHATSAPP") {
            setLoading({ save: false, saveNext: false, whatsapp: true, sms: false });
        } else {
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: true });
        }

        const res = await API.post("/profile/sendPhoneCode", {
            phone,
            token: getLocalStorage().token,
            type,
            name: getLocalStorage().name
        });

        if (res.data.state) {
            swal_etm(`Código enviado, revisa tu celular.`, `success`);
        } else {
            swal_etm(`Ocurrió un problema al enviar el código, Inténtalo otro vez`);
        }
        setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
    }

    const validarCodigo = async (valor) => {
        const code = valor.replace(/\·/g, "");

        if (code.length > 0) {
            setValidatePhoneCode({ value: code, validate: false });
        }

        if (code.length >= 6 && code.indexOf("·") === -1) {
            const res = await API.post("/profile/validatePhoneCode", { token: getLocalStorage().token, code });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
                setValidatePhoneCode({ value: code, validate: true });
            } else {
                swal_etm(res.data.msg);
                setValidatePhoneCode({ value: code, validate: false });
            }
        }
    }

    const changeData = (type, value) => {
        switch (type) {
            case "EMAIL":
                setEmail({
                    value: value
                    , valid: (validateEmail(value)) ? true : false
                    , invalid: (validateEmail(value)) ? false : true
                });
                break;
            case "RUT":
                setDni({
                    rut: value
                    , pasaporte: ""
                    , type: "RUT"
                    , valid: (validateRut(value)) ? true : false
                    , invalid: (validateRut(value)) ? false : true
                });
                break;
            case "PASAPORTE":
                setDni({
                    rut: ""
                    , pasaporte: value
                    , type: "PASAPORTE"
                    , valid: (value.length < 5) ? false : true
                    , invalid: (value.length < 5) ? true : false
                });
                break;
            case "NAME":
                setName({
                    value: value
                    , valid: (value.length < 2) ? false : true
                    , invalid: (value.length < 2) ? true : false
                });
                break;
            case "LASTNAME":
                setLastname({
                    value: value
                    , valid: (value.length < 2) ? false : true
                    , invalid: (value.length < 2) ? true : false
                });
                break;
            case "DESCRIPTION":
                setDescription({
                    value: value
                    , valid: (value.length < 10) ? false : true
                    , invalid: (value.length < 10) ? true : false
                });
                break;
        }
    }

    const save = (e) => {
        preSavePersonalData(e, false);
    }

    const saveContinue = (e) => {
        preSavePersonalData(e, true);
    }

    const preSavePersonalData = (e, nextStep) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading({
            save: (nextStep) ? false : true
            , saveNext: (nextStep) ? true : false
            , whatsapp: false
            , sms: false
        });

        if (!validateEmail(email.value)) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">Correo</b> válido.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if ((dni.type === "RUT") && (!validateRut(dni.rut))) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">RUT</b> válido.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if ((dni.type === "PASAPORTE") && (dni.pasaporte.length < 5)) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">Pasaporte</b> válido.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (name.value.length < 2) {
            swal_etm(`Debes ingresar tu <b class="etm_text_blue">Nombre</b> válido.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (lastname.value.length < 2) {
            swal_etm(`Debes ingresar tus <b class="etm_text_blue">Apellidos</b> válido.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (!validateAge()) {
            swal_etm(`Debes ser mayor de <b class="etm_text_blue">18 años</b>.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (country === null || (country !== null && country.value === 0)) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">país de origen</b>.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (actualCountry === null || (actualCountry !== null && actualCountry.value === 0)) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">país de residencia</b>.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (actualCountry !== null && actualCountry.value === default_chile.value && actualCity !== null && actualCity.value === 0) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">ciudad de residencia</b>.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        if (phone === null || phone.length <= 5 || parseInt(phone) !== parseInt(oldPhone.phone)) {
            if (validatePhoneCode.validate === false) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">teléfono</b> válido y verificado.`);
                setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
                return;
            }
        }

        if (description === null || description.value.length < 15) {
            swal_etm(`Debes ingresar una <b class="etm_text_blue">descripción</b> de al menos 15 caracteres.`);
            setLoading({ save: false, saveNext: false, whatsapp: false, sms: false });
            return;
        }

        postSavePersonalData(nextStep);
    }

    const postSavePersonalData = async (nextStep) => {
        const res = await API.put("/profile/updatePersonalData", {
            email: email.value
            , dni_type: dni.type
            , dni: (dni.type === "RUT") ? dni.rut : dni.pasaporte
            , name: name.value
            , lastname: lastname.value
            , birthdate: age
            , gender
            , linkedin: rrss.linkedin
            , facebook: rrss.facebook
            , instagram: rrss.instagram
            , twitter: rrss.twitter
            , youtube: rrss.youtube
            , phone
            , description: description.value
            , country: country.value
            , actualCountry: actualCountry.value
            , actualCity: (actualCity !== null) ? actualCity.value : 0
            , token: getLocalStorage().token
        });

        if (res.data.state) {
            setSessionUpdateUser(res.data.user);
            swal_etm(`Datos personales actualizados!`, `success`);
            setLoading({
                save: false
                , saveNext: false
                , whatsapp: false
                , sms: false
            });

            setTimeout(function () {
                if (nextStep) {
                    history.push(`/profile/organization`);
                } else {
                    window.location.reload();
                }
            }, 1000);
        } else {
            swal_etm(res.data.msg);
            setLoading({
                save: false
                , saveNext: false
                , whatsapp: false
                , sms: false
            });
        }
    }

    return (
        <Fragment>
            {
                (gettingData) ?
                    <LoadScreen text="Obteniendo datos personales..." className="mt-5" />
                    : <div className="animate__animated animate__fadeIn">
                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Datos Personales</p>
                                    </Label>
                                    <br />
                                    <Label className="h5 mt-0 mb-0 pt-0 pb-0">
                                        <p className="text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Tus datos de contacto o información sensible no serán compartido con otros usuarios y jamás será expuestos publicamente</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">Tu email {props.asterisco}</Label>
                                                <Input
                                                    onChange={(e) => changeData("EMAIL", e.target.value)}
                                                    name="email"
                                                    id="email"
                                                    value={email.value}
                                                    className="form-control etm_input_text"
                                                    type="email"
                                                    placeholder="Ingresa tu correo electrónico"
                                                    required
                                                    valid={email.valid}
                                                    invalid={email.invalid} />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                {
                                                    (dni.type === "RUT") ?
                                                        <Fragment>
                                                            <Label className="h5">Tu RUT {props.asterisco}</Label>
                                                            <Input
                                                                onChange={(e) => (rutInput(e), changeData("RUT", e.target.value))}
                                                                name="rut"
                                                                id="rut"
                                                                className="form-control etm_input_text"
                                                                type="text"
                                                                placeholder="Ingresa tu RUT"
                                                                required
                                                                valid={dni.valid}
                                                                invalid={dni.invalid}
                                                                value={(dni.rut !== null) ? dni.rut : ""}
                                                            />
                                                            <Label className="ml-3 etm_link_blue h5 pointer mt-1" onClick={() => setDni({ rut: dni.rut, pasaporte: dni.pasaporte, type: "PASAPORTE", valid: dni.valid, invalid: dni.invalid })}>No tengo RUT, soy extranjero</Label>
                                                        </Fragment>
                                                        : <Fragment>
                                                            <Label className="h5">Tu pasaporte {props.asterisco}</Label>
                                                            <Input
                                                                onChange={(e) => changeData("PASAPORTE", e.target.value)}
                                                                name="pasaporte"
                                                                id="pasaporte"
                                                                className="form-control etm_input_text"
                                                                type="text"
                                                                placeholder="Ingresa el número de tu pasaporte"
                                                                required
                                                                valid={dni.valid}
                                                                invalid={dni.invalid}
                                                                value={dni.pasaporte}
                                                            />
                                                            <Label className="ml-3 etm_link_blue h5 pointer mt-1" onClick={() => setDni({ rut: dni.rut, pasaporte: dni.pasaporte, type: "RUT", valid: dni.valid, invalid: dni.invalid })}>Tengo RUT</Label>
                                                        </Fragment>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">Tu nombre {props.asterisco}</Label>
                                                <Input
                                                    onChange={(e) => changeData("NAME", e.target.value)}
                                                    name="name"
                                                    value={name.value}
                                                    className="form-control etm_input_text"
                                                    type="text"
                                                    placeholder="Ingresa tu nombre"
                                                    required
                                                    valid={name.valid}
                                                    invalid={name.invalid}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">Tus apellidos {props.asterisco}</Label>
                                                <Input
                                                    onChange={(e) => changeData("LASTNAME", e.target.value)}
                                                    name="lastname"
                                                    value={lastname.value}
                                                    className="form-control etm_input_text"
                                                    type="text"
                                                    placeholder="Ingresa tus apellidos"
                                                    required
                                                    valid={lastname.valid}
                                                    invalid={lastname.invalid}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Selecciona tu género {props.asterisco}</Label>
                                                <select onChange={(e) => setGender(e.target.value)} name="gender" className="form-control etm_input_select" required value={gender}>
                                                    <option value="MASCULINO">Masculino</option>
                                                    <option value="FEMENINO">Femenino</option>
                                                    <option value="INDEFINIDO">Prefiero no indicarlo</option>
                                                    <option value="OTRO">Otro</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Indicanos tu fecha de nacimiento {props.asterisco}</Label>
                                                <DateRangePicker
                                                    onApply={changeAge}
                                                    initialSettings={{
                                                        singleDatePicker: true,
                                                        autoApply: true,
                                                        showDropdowns: true,
                                                        startDate: startDate,
                                                        locale: {
                                                            format: "DD-MM-YYYY",
                                                            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                                                            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                                                        }
                                                    }} >
                                                    <input onChange={(e) => setAge(e)} name="birthdate" className="form-control etm_input_text" type="text" placeholder="YYYY-MM-DD / YYYY-MM-DD" required readOnly value={age} />
                                                </DateRangePicker>
                                                <Label className="text-danger ml-2 h5 animate__animated animate__fadeIn" hidden={!showMessageAge}>Debes ser mayor de 18 años</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Selecciona tu país de origen {props.asterisco}</Label>
                                                <Select
                                                    placeholder={"Selecciona un país"}
                                                    value={country}
                                                    onChange={setCountry}
                                                    options={listCountries}
                                                    className="etm_input_select"
                                                    styles={(selectCustomStyle())}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={200}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">País residencia actual {props.asterisco}</Label>
                                                <Select
                                                    placeholder={"Selecciona un país"}
                                                    value={actualCountry}
                                                    onChange={setActualCountry}
                                                    options={listCountries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={200}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12} hidden={!showActualCity}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Selecciona tu ciudad residencia actual {props.asterisco}</Label>
                                                <Select
                                                    placeholder={"Selecciona una ciudad"}
                                                    value={actualCity}
                                                    onChange={setActualCity}
                                                    options={listCities}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={200}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">Tú teléfono<br /><small className="text-muted">Muy importante para poder comunicarnos contigo</small></Label>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">Tu teléfono {props.asterisco}</Label>
                                                <PhoneInput
                                                    country={'cl'}
                                                    value={phone}
                                                    onChange={phone => setPhone(phone)}
                                                    inputClass="form-control etm_input_phone mt-0"
                                                    buttonClass="etm_input_phone_flag"
                                                />
                                                <br />
                                                <div hidden={oldPhone.equals} className="animate__animated animate__fadeIn">
                                                    <Label className="h5">{arrow}Debemos validar este nuevo teléfono. Elige como quieres recibir el código de <b>6 dígitos</b></Label>
                                                    <Row>
                                                        <Col lg={6} md={12}>
                                                            <LoadBtn className="btn-lg" color="success" familyIcon="fab fa" icon="whatsapp" handle={() => enviarCodigo("WHATSAPP")} loading={loading.whatsapp} title="WhatsApp" text="Enviando..." />
                                                        </Col>
                                                        <Col lg={6} md={12}>
                                                            <LoadBtn className="btn-lg" icon="sms" handle={() => enviarCodigo("SMS")} loading={loading.sms} title="SMS" text="Enviando..." />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check hidden={oldPhone.equals} className="animate__animated animate__fadeIn">
                                                <Label className="h5">{arrow}Ingresa tu código de <b>6 dígitos</b></Label>
                                                <div className="etm_onboarding_verification_phone_input mt-3" style={{ marginLeft: "30px" }}>
                                                    <ReactInputVerificationCode
                                                        onChange={(e) => validarCodigo(e)}
                                                        value={validatePhoneCode.value}
                                                        length={6}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>

                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">¿ Tienes redes sociales ?<br /><small className="text-muted">( Opcionales, ingrésalas solo si quieres. Puedes ingresar las de tu proyecto también )</small></Label>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">LinkedIn</Label>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-linkedin linkedin"></i></span>
                                                    </div>
                                                    <Input
                                                        onChange={(e) => setRrss({ linkedin: cleanLinkedin(e.target.value), facebook: rrss.facebook, instagram: rrss.instagram, twitter: rrss.twitter, youtube: rrss.youtube })}
                                                        name="linkedin"
                                                        className="form-control etm_input_text"
                                                        type="url"
                                                        required
                                                        placeholder="Ingresa el link de tu Linkedin"
                                                        value={rrss.linkedin}
                                                    />
                                                    <div className="input-group-prepend" hidden={(rrss.linkedin.length > 5) ? false : true}>
                                                        <span className="input-group-text etm_input_group"><a href={rrss.linkedin} target="_blank"><i className="fas fa-eye linkedin"></i></a></span>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <Label className="h5">Facebook</Label>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-facebook facebook"></i></span>
                                                    </div>
                                                    <Input
                                                        onChange={(e) => setRrss({ linkedin: rrss.linkedin, facebook: cleanFacebook(e.target.value), instagram: rrss.instagram, twitter: rrss.twitter, youtube: rrss.youtube })}
                                                        className="form-control etm_input_text"
                                                        type="url"
                                                        placeholder="Ingresa el link de tu Facebook"
                                                        value={rrss.facebook}
                                                    />
                                                    <div className="input-group-prepend" hidden={(rrss.facebook.length > 5) ? false : true}>
                                                        <span className="input-group-text etm_input_group"><a href={rrss.facebook} target="_blank"><i className="fas fa-eye facebook"></i></a></span>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Instagram</Label>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-instagram instagram"></i></span>
                                                    </div>
                                                    <Input
                                                        onChange={(e) => setRrss({ linkedin: rrss.linkedin, facebook: rrss.facebook, instagram: cleanInstagram(e.target.value), twitter: rrss.twitter, youtube: rrss.youtube })}
                                                        className="form-control etm_input_text"
                                                        type="url"
                                                        placeholder="Ingresa el link de tu Instagram"
                                                        value={rrss.instagram}
                                                    />
                                                    <div className="input-group-prepend" hidden={(rrss.instagram.length > 5) ? false : true}>
                                                        <span className="input-group-text etm_input_group"><a href={rrss.instagram} target="_blank"><i className="fas fa-eye instagram"></i></a></span>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Twitter</Label>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-twitter twitter"></i></span>
                                                    </div>
                                                    <Input
                                                        onChange={(e) => setRrss({ linkedin: rrss.linkedin, facebook: rrss.facebook, instagram: rrss.instagram, twitter: cleanTwitter(e.target.value), youtube: rrss.youtube })}
                                                        className="form-control etm_input_text"
                                                        type="url"
                                                        placeholder="Ingresa el link de tu Twitter"
                                                        value={rrss.twitter}
                                                    />
                                                    <div className="input-group-prepend" hidden={(rrss.twitter.length > 5) ? false : true}>
                                                        <span className="input-group-text etm_input_group"><a href={rrss.twitter} target="_blank"><i className="fas fa-eye twitter"></i></a></span>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup className="mt-3" check>
                                                <Label className="h5">Youtube</Label>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-youtube youtube"></i></span>
                                                    </div>
                                                    <Input
                                                        onChange={(e) => setRrss({ linkedin: rrss.linkedin, facebook: rrss.facebook, instagram: rrss.instagram, twitter: rrss.twitter, youtube: e.target.value })}
                                                        className="form-control etm_input_text"
                                                        type="url"
                                                        placeholder="Ingresa el link de tu Youtube"
                                                        value={rrss.youtube}
                                                    />
                                                    <div className="input-group-prepend" hidden={(rrss.youtube.length > 5) ? false : true}>
                                                        <span className="input-group-text etm_input_group"><a href={rrss.youtube} target="_blank"><i className="fas fa-eye youtube"></i></a></span>
                                                    </div>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>

                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">Preséntate a tí mismo<br /><small className="text-muted">( máximo 400 caracteres )</small></Label>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label className="h5">Esta descripción será parte de tu tarjeta visible para los otros usuarios.</Label>
                                                <br />
                                                <Input
                                                    className="form-control etm_textarea"
                                                    type="textarea"
                                                    maxLength="400"
                                                    rows="4"
                                                    onChange={(e) => changeData("DESCRIPTION", e.target.value)}
                                                    placeholder="Ejemplo. Hola! Soy Alan el encargado del hacer el match ..."
                                                    valid={description.valid}
                                                    invalid={description.invalid}
                                                    value={description.value}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Guardar" text="Guardando..." handle={save} loading={loading.save}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Container>
                            <Row hidden>
                                <Col lg={4} md={2}></Col>
                                <Col lg={4} md={8}>
                                    <LoadBtn handle={save} loading={loading.save} className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={2}></Col>
                            </Row>
                            {/* <Row>
                                <Col lg={2} md={2}></Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={save} loading={ loading.save } className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={saveContinue} loading={ loading.saveNext } className="btn-lg" color="primary" familyIcon="fas fa" icon="check-circle" title="Guardar y continuar" text="Guardando..." />
                                </Col>
                                <Col lg={2} md={2}></Col>
                            </Row> */}
                        </Container>
                    </div>
            }
        </Fragment>
    );
}

export default (PersonalData);