import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import "../../assets/scss/etm.scss";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Iframe from 'react-iframe';

 const AdminPanel = () => {

    const dataStudioPages = [{
            title:  "Status últimos días"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_s91vgkieoc"
        ,   height: 1500
    },  {
            title:  "EtMday"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/zmObC"
        ,   height: 1700
    },  {
            title:  "EtMmeet Resumen"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_xb8k2ra6nc"
        ,   height: 1100
    },  {
            title:  "Resumen Usuarios"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_1zasgoxxnc"
        ,   height: 1600
    },  {
            title:  "Industrias"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_2qvh9juync"
        ,   height: 1300
    },  {
            title:  "Empresas Expertos"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_47rgdjwync"
        ,   height: 1400
    },  {
            title:  "Emprendimientos"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_nkol6uh6nc"
        ,   height: 1100
    },  {
            title:  "Gerencias"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_t9m2qjwync"
        ,   height: 1400
    },  {
            title:  "Listado Usuarios"
        ,   url:    "https://datastudio.google.com/embed/reporting/543e241e-3f41-4550-8cb4-4e55d25a6156/page/p_wz0qglk5nc"
        ,   height: 1100
    }]

    const[actualPage, setActualPage] = useState(dataStudioPages[0]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Panel Administrador'}/>
                    <Row>
                        <Col xl={4} lg={6} md={6} sm={12}>
                            <label className="h4 etm_text_blue">Página del reporte</label>
                            <select className="form-control etm_input_select" onChange={(e) => setActualPage(dataStudioPages[e.target.value])}>
                                {
                                    dataStudioPages.map((e, i) => {
                                        return <option value={i}>{e.title}</option>
                                    })
                                }
                            </select>
                        </Col>
                        <Col xs={12} className="mt-3">
                            <Iframe 
                                src={ actualPage.url }
                                height={ actualPage.height }
                                width="100%"
                                frameborder="0"
                                allowfullscreen
                                className=""
                                display="initial"
                                position="relative"
                                scrolling="no"
                                styles={{ justifyContentCenter: 'center', border: 'none' }} 
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(AdminPanel);