import React, { Fragment, useState, useEffect} from 'react';
import { Modal, ModalBody } from "reactstrap";
import moment from 'moment';
import Logo from "../../../assets/images/etmday/logo_etmday.png";
import { capitalize, swal_etm } from "../../../helpers/etmmeetUtils";
import { API } from '../../../helpers/axios';

const ModalEtMday = ( props ) => {
    const ls                    = JSON.parse(localStorage.getItem('user'));
    const[modal, setModal]      = useState(false);
    const[loading, setLoading]  = useState(false);
    
    const fecha1    = moment(new Date());
    const fecha2    = moment('2021-12-11');
    const dias      = fecha2.diff(fecha1, 'days');

    useEffect(() => {
        validateInscriptionEtmday();
    }, [])

    const validateInscriptionEtmday = async () => {
        const res = await API.get('/validateInscriptionEtmday', { params: { email: ls.email } });
        if(res.data.state){
            setModal(false);
        }else{
            setModal(true);
        }
    }

    const sendInscription = async () => {
        setLoading(true);
        const res = await API.post('/etmdayInscriptionFromMeet', { user_id: ls.id });
        if(res.data.state){
            swal_etm(`Hemos recibido tu registro para el <b>EtM</b><i class="etm_text_green">day</i> correctamente!<br/>Te enviaremos toda la información a tu correo registrado en <b>EtM</b><i class="etm_text_blue">meet</i>`, 'success', '');
            setModal(false);
        }else{
            swal_etm(`No hemos podido registrar tu cuenta en el <b>EtM</b><i class="etm_text_green">day</i>.<br/>Favor contacta con el equipo de Emprende tu Mente para ayudarte`);
            setModal(false);
        }
        setLoading(false);
    }
    
    const refuseEtmdayInscription = async () => {
        const res = await API.put('/refuseEtmdayInscription', { user_id: ls.id });
        setModal(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={ modal } size="xl" scrollable={true}>
                <ModalBody className="etm_modal_etmday">
                    <div className="animate__animated animate__fadeInUp text-center">
                        <p className="text-white text-right mb-0"><i className="fas fa-times fa-2x pointer mr-2" onClick={(e) => refuseEtmdayInscription() }></i></p>
                        <img src={ Logo } alt="" className="img-fluid w-25 mt-0 logo_etmday"/> 
                        <p className="text-white footer_etmday mt-1">QUEDAN SÓLO { dias } DÍAS</p>
                        <p className="text-white subtitle_etmday mt-5">Hola { capitalize(ls.name) }! por ser parte de <b>EtM</b><i className="mr-1">meet</i> te queremos invitar como público general al</p>
                        <p className="text-white title_etmday mt-4">ENCUENTRO INTERNACIONAL DE EMPRENDIMIENTO E INNOVACIÓN</p>
                        <p className="text-white subtitle_etmday mt-3">Evento abierto y gratuito<br />para todo el Ecosistema de Emprendimiento e Innovación de Chile.<br /><br />10 y 11 de Diciembre<br />Estadio Corfo, Las Condes</p>
                        <button className="btn btn-md etm_btn btn_etmday text-white pt-1 pb-1 pl-5 pr-5" onClick={(e) => sendInscription() } disabled={ loading }>{ (loading) ? <Fragment><i className="fas fa-sync fa-spin mr-2"></i>Realizando registro...</Fragment> : "Inscríbete y obtén tu cupo en un click"}</button>
                        <p className="text-white footer_etmday mt-4 mb-0">Para más información visita</p>
                        <a className="text-white footer_etmday mt-0 footer_etmday" href="https://www.etmday.org/" target="_blank"><u>www.etmday.org</u></a>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default ModalEtMday;