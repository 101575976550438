import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, Button } from "reactstrap";

import { API } from '../../helpers/axios';
import { Link, withRouter } from "react-router-dom";
import LoadScreen from "../../components/Utils/LoadScreen";

const Webpay = ({ props }) => {
    
    const[userId, setUserId] = useState(0);
    const[loading, setLoading] = useState(true);
    const[response, setResponse] = useState({ text1: "", text2: "" });

    useEffect(() => {
        getResponse();
    }, []);

    const getResponse = async () => {
        if(props.location.pathname.length > 0){
            const data      = props.location.pathname.split('/');
            const result    = data[2];
            if(result.toUpperCase() === "SUCCESS"){
                setResponse({
                        text1:  <h4>Donación realizada correctamente</h4>
                    ,   text2:  <h1 className="etm_text_blue">¡Muchas gracias por apoyarnos!<i className="fas fa-smile-wink ml-2"></i></h1>
                });
            }else{
                setResponse({
                        text1:  <h4>Se produjo un error en la Donación. Es posible que los datos ingresados sean incorrectos o que no se pueda establecer contacto con Transbank.</h4>
                    ,   text2:  <h1 className="text-danger">No se pudo concretar la transaccion<i className="far fa-frown-open ml-2"></i></h1>
                });
            }

            const ls = JSON.parse(localStorage.getItem('user'));
            if(ls !== null && ls !== undefined){
                setUserId(ls.id);
            }
            
            // const res   = await API.post('/donation_webpay_confirm', { buy_order: token });
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Card>
                <Col xs={12} className="pt-3">
                    <Row>
                        <Container>
                            {
                                (loading) ? 
                                    <LoadScreen />
                                :   <Fragment>
                                        <Col xs={12} className="text-center mt-5">
                                            { response.text1 }
                                        </Col>
                                        <Col xs={12} className="text-center mt-5">
                                            { response.text2 }
                                        </Col>
                                        <Col xs={12} className="text-center mt-5 pb-5">
                                            <h5>Para nosotros es fundamental tu participación en el ecosistema y por ello te invitamos a seguir teniendo reuniones en <b>EtM</b><i className="etm_text_blue">meet</i> y participar de todas las actividades que realizamos durante el año</h5>
                                        </Col>
                                        {
                                            (userId > 0) ?
                                                <Row>
                                                    <Col md={6} sm={12} className="text-md-right text-center pb-md-5 pb-3">
                                                        <Link to={"/donation"}>
                                                            <Button color="success" size="md" className="etm_btn pl-5 pr-5"><i className="fas fa-arrow-alt-circle-left mr-2"></i>Realizar otra donación</Button>
                                                        </Link>
                                                    </Col>
                                                    <Col md={6} sm={12} className="text-md-left text-center pb-md-5 pb-3">
                                                        <Link to={"/home"}>
                                                            <Button color="primary" size="md" className="etm_btn pl-5 pr-5"><i className="fas fa-arrow-alt-circle-left mr-2"></i>Volver al home</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            :   ""
                                        }
                                    </Fragment>
                            }
                        </Container>
                    </Row>
                </Col>
            </Card>
        </React.Fragment>
    );
}

export default withRouter(Webpay);