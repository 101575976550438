import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import { API } from '../../helpers/axios';
import { capitalize, linkProfile } from "../../helpers/etmmeetUtils";
import { Link, withRouter } from "react-router-dom";
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import LoadScreen from "../Utils/LoadScreen";
import Modal from "./PendingListModalCancel";

const PendingList = ( props ) => {

    const ls = JSON.parse(localStorage.getItem('user'));

    const [loadscreen, setLoadscreen]       = useState(false);
    const [countPending, setCountPending]   = useState(0); 
    const [pending, setPending]             = useState([]); 
    const [modal, setModal]                 = useState(false);
    const [requestId, setRequestId]         = useState(0);

    useEffect(() => {
        getPending();
    }, []);

    const getPending = async () => {
        setLoadscreen(true);
        const res = await API.get('/getMeetByStatus', { params: { status: 'PENDIENTE', user_id: ls.id }});
        setPending([]);
        var count = 0;
        let list = [];
        if(res.data.state){
            res.data.meets.map((e, i) => {
                count++;
                const profile_link  = linkProfile(e.manager_hash);
                let info_adicional  = "";
                let espertise       =   <Col lg={3} md={4} sm={12} xs={12} className="text-center mt-3"></Col>;
                let rol_img         = "";
                let rol             = "";

                switch(e.manager_rol){
                    case "ADMINISTRADOR":
                        rol_img     = gerente;
                        rol         = "Gerente-Ejecutivo";
                        break;
                    case "GERENTE/EJECUTIVO":
                        rol_img     = gerente;
                        rol         = "Gerente-Ejecutivo";
                        break;
                    case "INVERSIONISTA":
                        rol_img     = inversionista;
                        rol         = "Inversionista";
                        break;
                    case "MENTOR":
                        rol_img     = mentor;
                        rol         = "Mentor";
                        espertise   =   <Col lg={3} md={4} sm={12} xs={12} className="text-center mt-3">
                                            <h5 className="text-muted">{ (e.manager_expertise !== undefined && e.manager_expertise !== null) ? <span><b>Expertiz:</b><br/><span className="etm_text_blue">{ e.manager_expertise }</span></span> : <span className="text-white">-</span> }</h5>
                                        </Col>;
                        break;
                    case "EMPRENDEDOR":
                        rol_img = emprendedor;
                        rol     = "Emprendedor";
                        break;
                }

                info_adicional =    <Row className="mt-4">
                                        <Col xs={12} className="text-center">
                                            <img src={ e.manager_company_picture } alt="" className="etm_admin_company_logo fix_img"/> 
                                        </Col>
                                        <Col  xs={12} className="text-center mt-3">
                                            <h6 className="etm_text_blue">{ e.manager_charge }</h6>
                                            <h6 className="etm_text_blue">{ e.manager_company }</h6>
                                        </Col>
                                    </Row>;

                list.push(  <Fragment key={ i + 1 }>
                                <Col xs={12}>
                                    <Card className="etm_card_next_meet">
                                        <Link to={ profile_link } target="_blank">
                                            <Row>
                                                <Col lg={3} md={6} sm={12}>
                                                    <div className="text-center">
                                                        <img src={ e.manager_picture } alt="" className="rounded-circle etm_waiting_room_avatar fix_img mb-3 mt-3"/>
                                                        <h5 className="etm_text_blue mb-3">{ capitalize(e.manager_name) }</h5>
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={6} sm={12}>
                                                    { info_adicional }
                                                </Col>
                                                <Col lg={7} md={12} sm={12}>
                                                    <div className="pt-lg-4 text-center">
                                                        <Row>
                                                            <Col lg={3} md={4} sm={6} xs={12} className="text-center">
                                                                <h5 className="text-muted mt-4"><b>Día:</b><br/><span className="etm_text_blue">{ e.day }</span></h5>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={6} xs={12} className="text-center">
                                                                <h5 className="text-muted mt-4"><b>Hora:</b><br/><span className="etm_text_blue">{ e.time }</span></h5>
                                                            </Col>
                                                            { espertise }
                                                            <Col lg={2} md={4} sm={12} xs={12} className="text-center mt-3">
                                                                <div>
                                                                    <img src={rol_img} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img"/>
                                                                </div>
                                                                <h6 className="text-truncate font-weight-medium">{rol}</h6>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Link>
                                        <Row>
                                            <Col xs={12} className="text-center mt-2 mb-2">
                                                <button className="btn btn-danger etm_btn btn-md" onClick={ () => showModal( e.request_id ) }><i className="fas fa-times-circle"></i> Cancelar Solicitud</button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Fragment>
                );
            });
        }
        setPending(list);
        setCountPending(count);
        setLoadscreen(false);
    };

    const showModal = (e) => {
        setRequestId(e);
        setModal(!modal);
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Row className="etm_meeting_summary_border mb-3">
                        <Col md={6} sm={12}>
                            <Col xs={12} className="d-flex justify-content-center mt-2">
                                <h5>MIS SOLICITUDES</h5>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">
                                <Row>
                                    <Col lg={12}>
                                        <Card className="etm_box_summary etm_box_blue">
                                            <CardBody className="text-center">
                                                <h4 className="text-white">{countPending}</h4>
                                            </CardBody>
                                        </Card>
                                        
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center">
                                <h6 className="font-weight-medium">Pendientes</h6>
                            </Col>
                        </Col>
                        <Col md={6} sm={12}>
                            <div className="text-center mb-3 mt-lg-5 mt-sm-3 mt-xs-3">
                                <h4 className="etm_text_blue">Solicitudes Pendientes</h4>
                                <label><i className="fas fa-info-circle etm_text_blue mr-2"></i> Aquí verás todas las Solicitudes que enviaste a los siguientes perfiles</label>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                {
                    (loadscreen) ? 
                        <Col>   
                            <LoadScreen />
                        </Col> 
                        :
                        (pending.length > 0) ? 
                            pending 
                            : 
                            <Col><h5><i className="fas fa-info-circle etm_text_blue mr-2"></i>No tienes ninguna solicitud en este momento</h5></Col> 
                }
            </Row>
            <Modal modal={ modal } showModal={ showModal } requestId={ requestId } getPending={ getPending } setModal={ setModal }/>
        </React.Fragment>
        );
    }
export default withRouter(PendingList);
 