import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Faq from "../../assets/images/etmmeet/landing/faq.png";
import Container from 'reactstrap/lib/Container';
import { API } from '../../helpers/axios';
import Select from 'react-select';

const Faqs = (props) => {
    const [keyword, setKeyword] = useState("");
    const [faqs, setFaqs] = useState([]);
    const [faqSelected, setFaqSelected] = useState({ value: "", label: "", answer: "" }); 

    useEffect(() => {
        getFaqs();
    }, [props.type]);

    const getFaqs = async () => {
        if(props.type !== "" && props.type !== null && props.type !== undefined){
            const res = await API.get('/getFaqs', { params: { type: props.type }});

            if(res.data.state){
                setFaqs({ value: "", label: "", answer: "" });
                let list = [];
                res.data.data.map((e, i) => {     
                    list.push( { value: i, label: e.PREGUNTA, answer: e.RESPUESTA });
                });
                setFaqs(list);
            }
        }
    }

    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "$fff" : 0,
            borderColor: state.isFocused ? "#fff" : "#fff", "&:hover": {
                borderColor: state.isFocused ? "#fff" : "#fff"
            }
        })
    };

    const changeFaq = (selectedOption) => {
        setFaqSelected({ question: selectedOption.value, label: selectedOption.label, answer: selectedOption.answer });
    };

    return (
        <Fragment>
            <Container>
                <Row className="pb-5 pt-3">
                    <Col xs={12} className="text-center">
                        <img className="landing_profile_faq_img img-fluid" src={ Faq } alt="pic1"/>
                    </Col>
                    <Col xs={12} className="text-center pt-5 pb-3">
                        <p className="h1 landing_profile_faq_title">Preguntas Frecuentes</p>
                    </Col>
                    <Col xs={12} className="text-center pt-3">
                        <InputGroup className='mt-1 ml-md-5 ml-0'>
                            <div style={{width: '85%'}}>
                                <Select
                                    placeholder={ "Selecciona tu pregunta" }
                                    value={ (faqSelected.value === "") ? "Selecciona tu pregunta" : faqSelected.value }
                                    onChange={ changeFaq }
                                    options={ faqs }
                                    className="landing_profile_faq_input"
                                    styles={ customStyles }
                                    LoadingMessage="No encontramos tu pregunta"
                                />
                            </div>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText className="landing_profile_faq_input_addon"><i className="fas fa-search fa-2x etm_text_blue pointer"></i></InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    {
                        (faqSelected.label !== "") ?
                            <Col xs={12} className="text-center pt-5 animate__animated animate__fadeInUp">
                                <div className="landing_profile_faq_response_border pt-3 pb-3">
                                    <p className="h1 landing_profile_faq_response_title">{faqSelected.label}</p>
                                    <hr/>
                                    <div className="h4 landing_profile_faq_response_subtitle mt-5 ml-3 mr-3" dangerouslySetInnerHTML={{__html: faqSelected.answer}} />
                                </div>
                            </Col>
                        :   ""
                    }
                </Row>
            </Container>
        </Fragment>
    );
}

export default withNamespaces()(Faqs);