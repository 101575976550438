import { API } from './axios';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';


import logo_emprendedor from "../assets/images/conecTime/design/Perfil-emprendedor.png";
import logo_inversionista from "../assets/images/conecTime/design/Perfil-inversionista.png";
import logo_mentor from "../assets/images/conecTime/design/Perfil-mentor.png";
import logo_gerente from "../assets/images/conecTime/design/Perfil-gerente.png";

/*** V2 ***/

// Valida RUT. Retorna true o false
const validateRut = (rut) => {
    if (rut !== undefined && rut !== null) {
        // Despejar Puntos y Guión
        var valor = rut.replace(/\./g, '').replace(/\-/g, '');
        // Aislar Cuerpo y Dígito Verificador
        var cuerpo = valor.slice(0, -1);
        var dv = valor.slice(-1).toUpperCase();

        // Si no cumple con el mínimo ej. (n.nnn.nnn)
        if (cuerpo.length < 7) {
            return false;
        }
        // Calcular Dígito Verificador
        var suma = 0;
        var multiplo = 2;
        // Para cada dígito del Cuerpo
        for (var i = 1; i <= cuerpo.length; i++) {
            // Obtener su Producto con el Múltiplo Correspondiente
            var index = multiplo * valor.charAt(cuerpo.length - i);
            // Sumar al Contador General
            suma = suma + index;
            // Consolidar Múltiplo dentro del rango [2,7]
            if (multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
        }
        // Calcular Dígito Verificador en base al Módulo 11
        var dvEsperado = 11 - (suma % 11);
        // Casos Especiales (0 y K)
        dv = (dv == 'K') ? 10 : dv;
        dv = (dv == 0) ? 11 : dv;
        // Validar que el Cuerpo coincide con su Dígito Verificador
        if (dvEsperado != dv) {
            return false;
        }
        // Si todo sale bien, eliminar errores (decretar que es válido)
        return true;
    }
}

// Transforma input en formato RUT. Recibe elemento input con value e id y retorna el valor al id del input
function rutInput(rut) {
    if (rut !== undefined) {
        // Despejar Puntos y Guión
        var valor = rut.target.value.replace(/\./g, '').replace(/\-/g, '');
        // Aislar Cuerpo y Dígito Verificador
        var cuerpo = valor.slice(0, -1);
        var dv = valor.slice(-1).toUpperCase();

        if (!isNaN(cuerpo)) {
            cuerpo = cuerpo.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            cuerpo = cuerpo.split('').reverse().join('').replace(/^[\.]/, '');
        } else {
            cuerpo = cuerpo.replace(/[^\d\.]*/g, '');
        }
        if (cuerpo.length < 1) {
            document.getElementById(rut.target.id).value = cuerpo + '-' + dv;
            return false;
        } else {
            document.getElementById(rut.target.id).value = cuerpo + '-' + dv;
        }
    }
}

// Validar contraseña segura. 5 caracteres, 1 mayuscula, 1 minuscula, 1 numero
function validatePassword(password) {
    if (password !== undefined) {
        //validar longitud contraseña
        if (password.length < 5) {
            return false;
        }
        //validar letra
        if (!password.match(/[a-z]/)) {
            return false;
        }
        //validar letra mayúscula
        if (!password.match(/[A-Z]/)) {
            return false;
        }
        //validar numero
        if (!password.match(/\d/)) {
            return false;
        }
        return true;
    }
}

// Validar formato de email válido. user@domain.com
const validateEmail = (email) => {
    var validateEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (validateEmail.test(email.trim())) {
        return true;
    } else {
        return false;
    }
}

// SweetAlert implementado para EtM
const swal_etm = (body, icon = "warning", title = "") => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = `<br/>${body}`;
    swal({
        title: title,
        content: wrapper,
        icon: icon,
        dangerMode: (icon === "warning") ? true : false,
    });
}

// Recibe una fecha o utiliza la del sistema. Si TYPE = false retorna YYYY-MM-DD, si es true retorna DD-MM-YYYY
const getDate = (date = new Date(), type = false) => {
    const new_date = new Date(date);
    const year = new_date.getFullYear();
    let month = '' + (new_date.getMonth() + 1);
    let day = '' + new_date.getDate();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (type) {
        return [day, month, year].join('-');
    } else {
        return [year, month, day].join('-');
    }
}

// Validar los roles del usuario logueado. Recibe un array con todos los nombre de roles a validar. Requiere iniciar sesión
const validateRol = (rol) => {
    const ls = getLocalStorage();
    if (ls !== null && ls !== undefined) {
        for (var x = 0; x < rol.length; x++) {
            for (var z = 0; z < ls.rol.length; z++) {
                if (ls.rol[z].toUpperCase() == rol[x].toUpperCase()) {
                    return true;
                }
            }
        }
    }
    return false;
}

// Limpia posibles input de linkedin
const cleanLinkedin = (url) => {
    if (url !== undefined && url !== null) {
        // console.log(`IN: ${url}`)
        url = url.trim().toLowerCase();
        url = url.replace(" ", "-").replace("@", "").replace("http://www.linkedin.com/", "").replace("https://www.linkedin.com/", "").replace("http://linkedin.com/", "").replace("https://linkedin.com/", "").replace("www.linkedin.com/", "").replace("linkedin.com/", "");
        url = url.replace("in/in/", "in/").replace("company/company/", "company/");
        if ((url.indexOf("in/") < 0) && (url.indexOf("company/")) < 0) {
            url = `in/${url}`;
        }
        url = `https://www.linkedin.com/${url}`;
        // console.log(`OUT: ${url}`)
        return url;
    } else {
        return '';
    }
}

// Limpia posibles input de facebook
const cleanFacebook = (url) => {
    if (url !== undefined && url !== null) {
        // console.log(`IN: ${url}`)
        url = url.trim().toLowerCase();
        url = url.replace(" ", ".").replace("@", "").replace("http://web.facebook.com/", "").replace("https://web.facebook.com/", "").replace("http://m.facebook.com/", "").replace("https://m.facebook.com/", "").replace("http://www.facebook.com/", "").replace("https://www.facebook.com/", "").replace("http://facebook.com/", "").replace("https://facebook.com/", "").replace("www.facebook.com/", "").replace("www.web.facebook.com/", "").replace("web.facebook.com/", "").replace("facebook.com/", "");
        url = url.replace("http://es-la.facebook.com/", "").replace("http://es-la.", "").replace("https://es-la.", "");
        url = `https://www.facebook.com/${url}`;
        // console.log(`OUT: ${url}`)
        return url;
    } else {
        return '';
    }
}

// Limpia posibles input de instagram
const cleanInstagram = (url) => {
    if (url !== undefined && url !== null) {
        // console.log(`IN: ${url}`)
        url = url.trim().toLowerCase();
        url = url.replace(" ", ".").replace("@", "").replace("http://www.instagram.com/", "").replace("https://www.instagram.com/", "").replace("http://instagram.com/", "").replace("https://instagram.com/", "").replace("www.instagram.com/", "").replace("instagram.com/", "");
        url = `https://www.instagram.com/${url}`;
        // console.log(`OUT: ${url}`)
        return url;
    } else {
        return '';
    }
}

// Limpia posibles input de twitter
const cleanTwitter = (url) => {
    if (url !== undefined && url !== null) {
        // console.log(`IN: ${url}`)
        url = url.trim().toLowerCase();
        url = url.replace(" ", ".").replace("@", "").replace("http://twitter.com/", "").replace("https://twitter.com/", "").replace("http://www.twitter.com/", "").replace("https://www.twitter.com/", "").replace("www.twitter.com/", "").replace("twitter.com/", "");
        url = `https://www.twitter.com/${url}`;
        // console.log(`OUT: ${url}`)
        return url;
    } else {
        return '';
    }
}

// Limpia posibles input de youtube
const cleanYoutube = (url) => {
    if (url !== undefined && url !== null) {
        // console.log(`IN: ${url}`)
        url = url.trim().toLowerCase();
        url = url.replace(" ", ".").replace("@", "").replace("http://youtube.com/", "").replace("https://youtube.com/", "").replace("http://www.youtube.com/", "").replace("https://www.youtube.com/", "").replace("www.youtube.com/", "").replace("youtube.com/", "");
        url = `https://www.youtube.com/${url}`;
        // console.log(`OUT: ${url}`)
        return url;
    } else {
        return '';
    }
}


// Retorna nombres con primera letra en Mayuscula y el resto minuscula.
const capitalize = (string) => {
    if (string !== undefined && string !== null) {
        string = string.toLowerCase();
        let capitalizeString = "";
        let listString = string.split(" ");
        listString.map((e, i) => {
            if (e !== null && e !== "") {
                capitalizeString = capitalizeString + e[0].toUpperCase() + e.slice(1) + " ";
            }
        });
        capitalizeString = capitalizeString.substr(0, capitalizeString.length - 1);
        return capitalizeString;
    } else {
        return '';
    }
}

// Retorna el directorio de S3 para archivos e imágenes
const pathS3 = (string) => {
    let path = "";

    switch (string) {
        case "PROFILE":
            path = "avatar/profile/";
            break;
        case "COMPANY":
            path = "avatar/company/";
            break;
        case "STARTUP":
            path = "startup/files/";
            break;
        case "CHALLENGES/PICTURE":
            path = "challenges/picture/";
            break;
        case "CHALLENGES/FILES":
            path = "challenges/files/";
            break;
    }

    return path;
}

// Estilo por defecto para react-select
const selectCustomStyle = () => {
    return {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "$fff" : 0,
            borderColor: state.isFocused ? "#fff" : "#fff", "&:hover": {
                borderColor: state.isFocused ? "#fff" : "#fff"
            }
        })
    };
}

// Recupera el token hash_id del usuario logueado
const getLocalStorage = (item = 'user') => {
    const ls = JSON.parse(localStorage.getItem(item));
    return ls;
}

// SweetAlert de confirmación implementado para EtM
const swal_etm_confirm = (body, title = "¿ Estás seguro ?") => {
    return new Promise((resolve, reject) => {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = `<br/>${body}`;
        swal({
            title: title,
            content: wrapper,
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: [
                'Cancelar',
                'Confirmar'
            ],
        }).then((willDelete) => {
            if (willDelete) {
                resolve(true);
            } else {
                reject(false);
            }
        });
    });
}

const selectPerfilPicture = (perfil) => {
    let picture = '';
    switch (perfil) {
        case "ADMINISTRADOR":
            picture = logo_emprendedor;
            break;
        case "GERENTE/EJECUTIVO":
            picture = logo_gerente;
            break;
        case "INVERSIONISTA":
            picture = logo_inversionista;
            break;
        case "MENTOR":
            picture = logo_mentor;
            break;
        case "EMPRENDEDOR":
            picture = logo_emprendedor;
            break;
        default:
            picture = logo_emprendedor;
            break;

    }

    return picture
}


const ordinales = (number, gen = 'a') => {
    const ordinales = [
        'Cero'
        , `primer${gen}`
        , `segund${gen}`
        , `tercer${gen}`
        , `cuart${gen}`
        , `quint${gen}`
    ]
    return ordinales[number]
}

/*** FIN V2 ***/




const getDatetime = (date = new Date(), type = false) => {
    const new_date = new Date(date);
    const year = new_date.getFullYear();
    let month = '' + (new_date.getMonth() + 1);
    let day = '' + new_date.getDate();
    let hour = '' + new_date.getHours();
    let minute = '' + new_date.getMinutes();
    let second = '' + new_date.getSeconds();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    if (second.length < 2)
        second = '0' + second;

    if (type) {
        return [day, month, year].join('-') + ' ' + [hour, minute, second].join(':');
    } else {
        return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
    }
}

const getNameDate = (date = new Date()) => {
    date = date.replace(/\-/g, '/');
    const new_date = new Date(date);
    // new_date.setDate(new_date.getDate() + 1);
    const year = new_date.getFullYear();
    const month = capitalize(new_date.toLocaleDateString('es-ES', { month: 'long' }));
    const weekday = capitalize(new_date.toLocaleDateString('es-ES', { weekday: 'long' }));
    const day = new_date.toLocaleDateString('es-ES', { day: 'numeric' });
    return weekday + " " + day + " de " + month;
}

const rot47 = (string) => {
    var s = [];
    for (var i = 0; i < string.length; i++) {
        var j = string.charCodeAt(i);
        if ((j >= 33) && (j <= 126)) {
            s[i] = String.fromCharCode(33 + ((j + 14) % 94));
        } else {
            s[i] = String.fromCharCode(j);
        }
    }
    return s.join('');
}

const calculatePage = (amount, current) => {
    if (amount < 8) {
        let list = [];
        for (let i = 1; i <= amount; i++) {
            list.push(i);
        }
        return list;
    } else if (current < 5) {
        return [1, 2, 3, 4, 5, 0, amount];
    } else if (current > amount - 4) {
        return [1, 0, amount - 4, amount - 3, amount - 2, amount - 1, amount]
    } else {
        return [1, 0, current - 1, current, current + 1, -1, amount]
    }
}





function formatRut(rut) {
    if (rut !== undefined && rut !== null) {
        var valor = rut.replace('.', '');
        valor = valor.replace('-', '');

        var cuerpo = valor.slice(0, -1);
        var dv = valor.slice(-1).toUpperCase();

        cuerpo = cuerpo.replace(/\./g, '');
        if (!isNaN(cuerpo)) {
            cuerpo = cuerpo.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            cuerpo = cuerpo.split('').reverse().join('').replace(/^[\.]/, '');
        } else {
            cuerpo = cuerpo.replace(/[^\d\.]*/g, '');
        }
        if (cuerpo.length < 1) {
            return cuerpo + '-' + dv;
        } else {
            return cuerpo + '-' + dv;
        }
    }
}

function randomFileName(string = "") {
    const date = new Date();
    const y = date.getFullYear().toString();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    const h = date.getHours();
    const i = date.getMinutes();
    const s = date.getSeconds();

    m = (m < 10) ? '0' + m.toString() : m;
    d = (d < 10) ? '0' + d.toString() : d;

    let random_string = "";
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (var x = 0; x < 30; x++) {
        random_string += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    const name = `${string}${y}${m}${d}_${h}_${i}_${s}_${random_string}`.replace(' ', '_');
    return name;
}

function spanishDate(date) {
    let res;
    switch (date.toUpperCase()) {
        case "SUN":
            res = "Dom";
            break;
        case "MON":
            res = "Lun";
            break;
        case "TUE":
            res = "Mar";
            break;
        case "WED":
            res = "Mie";
            break;
        case "THU":
            res = "Jue";
            break;
        case "FRI":
            res = "Vie";
            break;
        case "SAT":
            res = "Sab";
            break;

        case "SUNDAY":
            res = "Domingo";
            break;
        case "MONDAY":
            res = "Lunes";
            break;
        case "TUESDAY":
            res = "Martes";
            break;
        case "WEDNESDAY":
            res = "Miércoles";
            break;
        case "THURSDAY":
            res = "Jueves";
            break;
        case "FRIDAY":
            res = "Viernes";
            break;
        case "SATURDAY":
            res = "Sábado";
            break;

        case "JANUARY":
            res = "Enero";
            break;
        case "FEBRUARY":
            res = "Febrero";
            break;
        case "MARCH":
            res = "Marzo";
            break;
        case "APRIL":
            res = "Abril";
            break;
        case "MAY":
            res = "Mayo";
            break;
        case "JUNE":
            res = "Junio";
            break;
        case "JULY":
            res = "Julio";
            break;
        case "AUGUST":
            res = "Agosto";
            break;
        case "SEPTEMBER":
            res = "Septiembre";
            break;
        case "OCTOBER":
            res = "Octubre";
            break;
        case "NOVEMBER":
            res = "Noviembre";
            break;
        case "DECEMBER":
            res = "Diciembre";
            break;
    }
    return res;
}

const linkProfile = (hash) => {
    return `/view_profile/${hash}`;
}

const isLogged = async (history) => {
    const ls = JSON.parse(localStorage.getItem('user'));
    let type = "PC";
    if (isMobile()) {
        type = "MOBILE";
    }
    const res = await API.get('/getSessionStart', { params: { user_id: ls.id, type } });
    if (res.data.state) {
        if (!res.data.session) {
            history.push('/logout')
        }
    }
}

const isMobile = () => {
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}

const validateNavigator = () => {
    var android = navigator.userAgent.match(/Android/i);
    var ios = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i));
    var agente = window.navigator.userAgent.toLowerCase();

    //  Si es Android
    if (android !== null) {
        if (agente.indexOf('safari') != -1) {
            if (agente.indexOf('edg') > -1) {
                // console.log(`ANDROID EDGE`)
                return true;    //  Edge
            } else if (agente.indexOf('chrome') > -1) {
                // console.log(`ANDROID CHROME`)
                return true;    //  Chrome
            } else {
                // console.log(`ANDROID SAFARI`)
                return false;   //  Safari
            }
        } else if (agente.indexOf('firefox') > -1) {
            // console.log(`ANDROID FIREFOX`)
            return true;    //  Firefox
        } else {
            // console.log(`ANDROID OTRO`)
            return false;   //  Otro - Desconocido
        }
    }

    //  Si es IOs
    if (ios !== null) {
        if (agente.indexOf('safari') != -1) {
            if (agente.indexOf('edg') > -1) {
                // console.log(`IOS EDGE`)
                return false;    //  Edge
            } else if (agente.indexOf('chrome') > -1) {
                // console.log(`IOS CHROME`)
                return false;    //  Chrome
            } else {
                // console.log(`IOS SAFARI`)
                return true;   //  Safari
            }
        } else if (agente.indexOf('firefox') > -1) {
            // console.log(`IOS SAFARI`)
            return false;    //  Firefox
        } else {
            // console.log(`IOS OTRO`)
            return false;   //  Otro - Desconocido
        }
    }

    //  Desde PC
    if (agente !== null) {
        if (agente.indexOf('safari') != -1) {
            if (agente.indexOf('edg') > -1) {
                // console.log(`PC EDGE`)
                return true;    //  Edge
            } else if (agente.indexOf('chrome') > -1) {
                // console.log(`PC CHROME`)
                return true;    //  Chrome
            } else {
                // console.log(`PC SAFARI`)
                return true;   //  Safari
            }
        } else if (agente.indexOf('firefox') > -1) {
            // console.log(`PC FIREFOX`)
            return true;    //  Firefox
        } else {
            // console.log(`PC OTRO`)
            return false;   //  Otro - Desconocido
        }
    } else if (agente.indexOf('firefox') > -1) {
        return true;    //  Firefox
    } else {
        return false;   //  Otro - Desconocido
    }
}

const isSafari = () => {
    var ios = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i));
    var agente = window.navigator.userAgent.toLowerCase();

    //  Si es IOs
    if (ios !== null) {
        if (agente.indexOf('safari') != -1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const isAndroid = () => {
    return (
        (navigator.userAgent.match(/Android/i))
    );
}

const isIos = () => {
    return (
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i))
    );
}

const getAgentData = () => {
    const iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
    const navegadores = ["Chrome", "Firefox", "Edge", "Safari"];
    let so = "Otro";
    let browser = "Otro";
    let browserVersion = "Otro";

    if (window.navigator.platform.toLowerCase().includes('mac')) so = 'MacOS';
    if (iosPlatforms.includes(window.navigator.platform.toLowerCase())) so = 'iOS';
    if (window.navigator.platform.toLowerCase().includes('win')) so = 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) so = 'Android';
    if (/linux/.test(window.navigator.platform.toLowerCase())) so = 'Linux';

    var agente = window.navigator.userAgent.toLowerCase();
    if (agente.indexOf('safari') != -1) {
        if (agente.indexOf('edg') > -1) {
            browser = "Edge";
            browserVersion = (agente.substr(agente.indexOf('edg')).indexOf(' ') > -1) ? agente.substr(agente.indexOf('edg'), agente.substr(agente.indexOf('edg')).indexOf(' ')) : agente.substr(agente.indexOf('edg'));
        } else if (agente.indexOf('chrome') > -1) {
            browser = "Chrome";
            browserVersion = (agente.substr(agente.indexOf('chrome')).indexOf(' ') > -1) ? agente.substr(agente.indexOf('chrome'), agente.substr(agente.indexOf('chrome')).indexOf(' ')) : agente.substr(agente.indexOf('chrome'));
        } else {
            browser = "Safari";
            browserVersion = (agente.substr(agente.indexOf('safari')).indexOf(' ') > -1) ? agente.substr(agente.indexOf('safari'), agente.substr(agente.indexOf('safari')).indexOf(' ')) : agente.substr(agente.indexOf('safari'));
        }
    } else if (agente.indexOf('firefox') > -1) {
        browser = "Firefox";
        browserVersion = (agente.substr(agente.indexOf('firefox')).indexOf(' ') > -1) ? agente.substr(agente.indexOf('firefox'), agente.substr(agente.indexOf('firefox')).indexOf(' ')) : agente.substr(agente.indexOf('firefox'));
    } else {
        browser = "Otro";
        browserVersion = "Otro";
    }

    return { so, browser, browserVersion };
}

const getRol = () => {
    const ls = JSON.parse(localStorage.getItem('user'));
    if (ls.rol !== null && ls.rol !== undefined) {
        for (var x = 0; x < ls.rol.length; x++) {
            if (ls.rol[x] !== "ADMINISTRADOR") {
                return ls.rol[x]
            }
        }
    }
    return "";
}





const emptyInput = (e) => {
    console.log(e.target)
    if (e.target !== null) {
        if (e.target.value.toString().length < 1) {
            e.target.classList.remove("etm_input_text");
            e.target.classList.add("is-invalid");
        } else {
            e.target.classList.add("etm_input_text");
            e.target.classList.remove("is-invalid");
        }
    }
}

const emptyInputNumber = (e) => {
    if (e.target !== null) {
        if (e.target.value < 1) {
            e.target.classList.remove("etm_input_text");
            e.target.classList.add("is-invalid");
        } else {
            e.target.classList.add("etm_input_text");
            e.target.classList.remove("is-invalid");
        }
    }
}

const emptyTextArea = (e) => {
    if (e.target !== null) {
        if (e.target.value.toString().length < 1) {
            e.target.classList.remove("etm_textarea");
            e.target.classList.add("is-invalid");
        } else {
            e.target.classList.add("etm_textarea");
            e.target.classList.remove("is-invalid");
        }
    }
}

const success_msg = (msg) => {
    toast.info(msg, {
        position: "top-right"
        , autoClose: 3000
        , hideProgressBar: false
        , closeOnClick: true
        , pauseOnHover: true
        , draggable: true
        , progress: undefined
        , theme: 'colored'
    });
}

const getNavigatorAgent = () => {
    if ((navigator.userAgent.match(/Android/i))) {
        return "Android";
    }
    if ((navigator.userAgent.match(/webOS/i))) {
        return "webOS";
    }
    if ((navigator.userAgent.match(/iPhone/i))) {
        return "iPhone";
    }
    if ((navigator.userAgent.match(/iPod/i))) {
        return "iPod";
    }
    if ((navigator.userAgent.match(/iPad/i))) {
        return "iPad";
    }
    if ((navigator.userAgent.match(/BlackBerry/i))) {
        return "BlackBerry";
    }
    return "PC"
}

const formatNumber = (n) => {
    var num = n.toString().replace(/\./g, '');
    if (!isNaN(num)) {
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
        num = num.split('').reverse().join('').replace(/^[\.]/, '');
        n = num;
    }

    else {
        n = n.replace(/[^\d\.]*/g, '');
    }
    return n;
}

const iconFileByExtension = (extension) => {
    let icon = '';
    switch (extension) {
        case 'docm':
        case 'dotx':
        case 'doc':
        case 'docx':
            icon = 'far fa-file-word';
            break;
        case 'ppt':
        case 'pptm':
        case 'pptx':
            icon = 'far fa-file-powerpoint';
            break;
        case 'pdf':
            icon = 'far fa-file-pdf';
            break;
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'png':
        case 'tiff':
        case 'tif':
        case 'RAW':
        case 'bmp':
        case 'psd':
        case 'pic':
        case 'eps':
            icon = 'far fa-file-image';
            break;
        case 'rar':
        case 'zip':
        case '7z':
            icon = 'far fa-file-archive';
            break;
        default:
            icon = 'far fa-file';
            break;
    }
    return icon
}

export {
    validateRut
    , rutInput
    , validatePassword
    , validateEmail
    , swal_etm
    , getDate
    , validateRol
    , cleanLinkedin
    , cleanFacebook
    , cleanInstagram
    , cleanTwitter
    , cleanYoutube
    , capitalize
    , pathS3
    , selectCustomStyle
    , getLocalStorage
    , swal_etm_confirm
    , selectPerfilPicture
    , iconFileByExtension
    , ordinales

    , rot47
    , calculatePage

    , formatRut
    , randomFileName
    , spanishDate
    , isLogged
    , linkProfile
    , getNameDate
    , isMobile
    , validateNavigator
    , isAndroid
    , isIos
    , getAgentData
    , getDatetime
    , getRol


    , emptyInput
    , emptyInputNumber
    , emptyTextArea
    , success_msg
    , getNavigatorAgent
    , isSafari
    , formatNumber
}