import React from 'react';
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Percentage from "../../components/Home/Percentage";
import { capitalize, cleanLinkedin, cleanFacebook, cleanInstagram, cleanTwitter } from "../../helpers/etmmeetUtils";

const ShortProfile = ( props ) => {

    const ls    = JSON.parse(localStorage.getItem('user'));
    ls.date     = new Date(ls.created_at).toLocaleDateString("en-US");
    
    return (
        <React.Fragment>
            <Row className="pt-5">
                <Col lg={12}>
                    <div className="profile-user-wid mb-4 text-center">
                        <img src={ ls.profile_picture } alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"/> 
                    </div>
                </Col>
                <Col lg={12} className="text-center">
                    <h5 className="etm_text_blue">{ capitalize(ls.name) } { capitalize(ls.last_name)}</h5>
                    <h5 className="etm_text_blue">{ capitalize(ls.rol[0]) }</h5>
                </Col>
                <Col lg={12} className="text-center mt-2">
                    <ul className="list-inline mb-1">
                        {
                            (ls.facebook !== undefined && ls.facebook !== null && ls.facebook !== '') ?  <li className="list-inline-item ">
                                                            <h4>
                                                                <a href={ cleanFacebook(ls.facebook) } target="_blank">
                                                                    <i className="fab fa-facebook-square facebook"></i>    
                                                                </a>
                                                            </h4>
                                                        </li> : ""
                        }

                        {
                            (ls.instagram !== undefined && ls.instagram !== null && ls.instagram !== '') ?  <li className="list-inline-item ">
                                                            <h4>
                                                                <a href={ cleanInstagram(ls.instagram) } target="_blank">
                                                                    <i className="fab fa-instagram instagram"></i>
                                                                </a>
                                                            </h4>
                                                        </li> : ""
                        }

                        {
                            (ls.linkedin !== undefined && ls.linkedin !== null && ls.linkedin !== '') ?  <li className="list-inline-item ">
                                                            <h4>
                                                                <a href={ cleanLinkedin(ls.linkedin) } target="_blank">
                                                                    <i className="fab fa-linkedin linkedin"></i>
                                                                </a>
                                                            </h4>
                                                        </li> : ""
                        }

                        {
                            (ls.twitter !== undefined && ls.twitter !== null && ls.  twitter !== '') ?  <li className="list-inline-item ">
                                                            <h4>
                                                                <a href={ cleanTwitter(ls.twitter) } target="_blank">
                                                                    <i className="fab fa-twitter-square twitter"></i>
                                                                </a>
                                                            </h4>
                                                        </li> : ""
                        }
                        
                    </ul>
                </Col>
            </Row>
            {/* <Percentage /> */}
            {
                props.showBtn ? 
                <Row className="step-tour-profile">
                    <Col sm="5">
                        <div className="mt-3">
                            <Link to="/qr_profile" className="btn btn-primary etm_btn btn-block">QR <i className="fas fa-qrcode ml-1"></i></Link>
                        </div>
                    </Col>
                    <Col sm="7">
                        <div className="mt-3">
                            <Link to="/profile" className="btn btn-primary etm_btn btn-block">Completar Perfil <i className="fas fa-arrow-right ml-1"></i></Link>
                        </div>
                    </Col>
                </Row>
                : ""
            }
        </React.Fragment>
        );
    }
export default ShortProfile;
 