import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import Logo from "../../assets/images/conecTime/logo_conectime.png";

const TermsConditions = ( props ) => {

    const etmmeet = <Fragment><b>EtM</b><i className="etm_text_blue">meet</i></Fragment>

    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Col sm={12} className="etm_policies_terms mt-5 mb-5 text-left animate__animated animate__fadeIn">
                            <h1>Términos y Condiciones de { etmmeet }</h1>
                            <h2>Versión 1.0</h2>
                            <h2>Última actualización: 13 de noviembre de 2021</h2>
                            <hr />
                            <br />
                            <h2>1. Introducción</h2>
                            <p>Los siguientes Términos y Condiciones de Uso de { etmmeet }, en adelante los “Términos y Condiciones de Uso”, serán aplicables a aquellas personas que instalen { etmmeet } en sus dispositivos móviles o que utilicen la versión web, en adelante indistintamente “usuario”. Si usted no está de acuerdo con estos Términos y Condiciones de Uso, deberá abstenerse de utilizar la plataforma { etmmeet }.</p>
                            <p>Corporación Emprende tu Mente es el proveedor de la { etmmeet } que es una aplicación que permite conectar a Emprendedores con Empresarios, Inversionistas, Mentores y diferentes tomadores de decisión.</p>
                            <p>Corporación Emprende tu Mente se reserva el derecho de revisar y modificar periódicamente los presentes Términos y Condiciones de Uso, produciendo dichas modificaciones efectos legales a contar del quinto hábil contado desde la fecha de publicación de la modificación.</p>
                            <br />
                            <h2>2. Requisitos para el uso de { etmmeet }</h2>
                            <p>{ etmmeet } está disponible para personas mayores de 18 años y para ser utilizada sólo en Chile. El acceso y utilización de { etmmeet } requiere que el usuario disponga de acceso a Internet, ya sea por un plan, bolsa de datos, mediante conexión WiFi u otro medio disponible, requiere dispositivos compatibles (Windows, MacOS, Android e iOS) y determinados softwares (podrían aplicarse tarifas); se podrían requerir actualizaciones periódicas. Los servicios de la App podrían verse afectados por el rendimiento de estos factores.</p>
                            <br />
                            <h2>3. Entrega de datos personales</h2>
                            <ul>
                                <li>Entrega de datos personales del usuario o cliente y uso de claves y registro
                                    <ul>
                                        <li>La obtención de datos personales en { etmmeet } se realiza de manera voluntaria, con la finalidad de aportar en la búsqueda de usuarios con intereses y necesidades mutuas. </li>
                                        <li>Para acceder a { etmmeet }, el usuario deberá registrarse previamente ingresando los datos personales que le sean solicitados. El proceso de registro consta de un paso: el usuario debe ingresar sus datos personales para crear su perfil. En ese mismo paso deberá registrar un mail, una contraseña y su celular. </li>
                                        <li>El usuario dispondrá de una contraseña o clave personal de acceso que le permitirá el acceso personalizado a { etmmeet } y que adicionalmente lo habilita a crear, editar y eliminar proyectos para difundir; además, los usuarios podrán navegar a través de { etmmeet } revisando otros proyectos. Por último, los usuarios podrán conectar entre sí a través de un chat interno de la aplicación. </li>
                                        <li>La clave personal de acceso que { etmmeet } entregue al usuario está destinada a su uso personal, por lo tanto, su uso será de su exclusiva responsabilidad. En consecuencia, el extravío, robo, hurto, mal uso o cualquiera otra circunstancia que afecte la clave personal de acceso del cliente será de su responsabilidad. Asimismo, cualquier instrucción o declaración que se ejecute a través de la clave personal de acceso se entenderá que ha emanado válida, legítima y auténticamente de un acto personal ejecutado por el usuario. En caso que exista mal uso de la clave personal, el usuario deberá dar aviso e informar a { etmmeet } apenas tenga conocimiento de la situación.</li>
                                        <li>El usuario o cliente podrá en cualquier momento cambiar su clave personal de acceso, directamente a través de { etmmeet }.</li>
                                    </ul>
                                </li>
                                <li>Cookies
                                    <ul>
                                        <li>{ etmmeet } dispone de cookies con el fin de entregar mejores condiciones de servicio, y para recoger información para propósitos estadísticos.</li>
                                        <li>Se hace presente que esta tecnología no es utilizada por { etmmeet } para la lectura de datos del disco duro de los usuarios, ni de los archivos cookie creados por otros proveedores de servicios de Internet.</li>
                                        <li>Es responsabilidad del usuario consultar las instrucciones de su sistema navegador, especialmente en lo relativo a configuraciones, donde se permite escoger la funcionalidad que comunica la existencia de cookies y/o impide la instalación de cookies, si es el deseo del usuario o cliente.</li>
                                    </ul>
                                </li>
                            </ul>
                            <br />
                            <h2>4. Veracidad de la información</h2>
                            <ul>
                                <li>Toda la información que facilite el usuario a { etmmeet } por cualquier medio deberá ser exacta, verdadera, completa y actualizada.</li>
                                <li>A estos efectos, el usuario garantiza la autenticidad de todos aquellos datos que comunique a { etmmeet } como consecuencia del llenado de los formularios necesarios para su registro en la App. De igual forma, será responsabilidad del usuario mantener toda la información entregada a { etmmeet } permanentemente actualizada de forma que responda, en cada momento, a su situación real.</li>
                                <li>{ etmmeet } podrá suspender temporalmente los servicios de la App, cuando tenga dudas sobre la veracidad, exactitud o actualización de la información proporcionada por un usuario, mientras la información suministrada no haya sido confirmada por el mismo. El usuario será el único responsable de las manifestaciones falsas o inexactas que realice, y de los perjuicios que pudiere causar a { etmmeet } o a terceros por la información inexacta que proporcione.</li>
                            </ul>
                            <br />
                            <h2>5. Uso de los datos Personales</h2>
                            <ul>
                                <li>{ etmmeet } se preocupa por la protección de datos de carácter personal de sus usuarios, por lo cual, asegura que su tratamiento se efectuará con estricta sujeción a la legislación vigente.</li>
                                <li>Según lo dispuesto en la ley N° 19.628, sobre Protección de datos de carácter personal, { etmmeet } no pondrá a disposición del resto de la comunidad externa a la aplicación los datos personales de sus usuarios, salvo autorización expresa de sus titulares. La información que se reciba o transmita a través de { etmmeet } será confidencial y solo podrá utilizarse por los usuarios de la aplicación para efectos de generar contactos entre ellos. </li>
                                <li>El usuario autoriza conforme lo indicado en el artículo 4 de la Ley 19.628 el tratamiento de sus datos personales por { etmmeet } y sus empresas relacionadas, filiales o matrices con finalidades de marketing, comunicar ofertas y promociones y con el objeto de entregar información y/o beneficios a los usuarios y clientes. Los referidos datos personales podrán en casos concretos ser comunicados a terceros, para cumplir con las finalidades recién mencionadas.</li>
                                <li>Por lo anterior, y por el solo hecho de estar incorporados e inscritos en { etmmeet }, sus usuarios autorizan expresamente a { etmmeet } para poner a disposición de todos los usuarios, la información que entreguen para dichos efectos, con Ia finalidad que, eventualmente, sean contactados en relación bienes y/o servicios que proveen.</li>
                                <li>El usuario que no desee continuar recibiendo mensajes publicitarios o de marketing, podrá solicitarlo a través de la misma vía por la cual el mensaje fue enviado.</li>
                                <li>El usuario puede solicitar la información, rectificación, eliminación y/o cancelación de sus datos cuando lo estime conveniente, en conformidad a la Ley N° 19.628.</li>
                                <li>De vez en cuando se podrá solicitar información a personas que nos hayan enviado los datos de contacto a través de encuestas. La participación en éstas es totalmente voluntaria, por lo que será su decisión revelar o no esta información. La encuesta se utilizará exclusivamente con propósitos de seguimiento o para mejorar el rendimiento de { etmmeet }. En caso de comunicarse los resultados de dicha encuesta, se omitirá cualquier señal que permita identificar a las personas consultadas.</li>
                                <li>En { etmmeet }, usaremos sus datos de localización para identificar su ubicación con respecto a los intereses de los usuarios que deseen conectar con proyectos de una zona determinada del país. De todas formas, nunca se entregará esa geolocalización de manera exacta, sino que solo de manera general, clasificando a los usuarios por regiones, industrias, necesidades comerciales, estados de desarrollos de productos, entre otros. </li>
                            </ul>
                            <br />
                            <h2>6. Propiedad Intelectual e Industrial</h2>
                            <ul>
                                <li>Todo contenido u obra que el usuario vea, lea o a la cual acceda en { etmmeet } (imágenes, fotografías, videos, ilustraciones, iconos, textos, código fuente, diseño o cualquier otro elemento de contenido), se encuentra protegido bajo la legislación nacional y los tratados internacionales sobre propiedad intelectual e industrial, salvo que expresamente se señale lo contrario. El usuario no podrá vender, reproducir, distribuir, modificar, publicar o cualquier otra actividad semejante, con cualquier elemento de contenido de { etmmeet }, ni tampoco usarlo en ningún otro portal en Internet, para propósito alguno.</li>
                                <li>{ etmmeet } no concede ninguna licencia, derecho o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual, o sobre cualquier otra propiedad o derecho relacionado con la App o con los servicios que ofrece, salvo la licencia de que trata la sección siguiente. { etmmeet } podrá terminar inmediatamente el derecho del usuario para acceder a la App, en caso de infracción del usuario a estas normas. El usuario será responsable de todo daño o perjuicio causado a Corporación Emprende tu Mente y/o a terceros, que deriven de las infracciones legales en que incurra.</li>
                                <li>Se deja expresa constancia que todo elemento de contenido, tal como artículos, publicaciones, noticias e información publicada, exhibida, transmitida o comunicada en { etmmeet }, será de responsabilidad de su autor o de la persona que las haya proveído en conformidad a las leyes sobre propiedad intelectual e industrial.</li>
                                <li>Corporación Emprende tu Mente es titular de la marca registrada y/o nombres de dominio " { etmmeet } ".</li>
                                <li>{ etmmeet } puede contener vínculos, links y/o referencias a sitios de terceros fabricantes, productores y/o proveedores de bienes y/o servicios. { etmmeet } reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, y su sola mención o aparición en { etmmeet } no implica la existencia de derechos o responsabilidad alguna de Corporación Emprende tu Mente sobre los mismos. { etmmeet } no recomienda ni avala ningún producto o servicio ofrecido en dichos sitios, asimismo, Corporación Emprende tu Mente no es responsable de las prácticas de seguridad o privacidad, o el contenido y disponibilidad de los mismos.</li>
                            </ul>
                            <br />
                            <h2>7. Licencia</h2>
                            <ul>
                                <li>{ etmmeet } se licencia y no se vende. La licencia para usar { etmmeet }, está sujeta a la aceptación previa de estos Términos y Condiciones de Uso por usted. Esta licencia es concedida en forma gratuita para el uso de { etmmeet } y se limita a una licencia no transferible para usar { etmmeet } en dispositivos compatibles que usted posea o controle. Esta licencia no permite usar { etmmeet } en cualquier dispositivo que no sea de su propiedad o control.</li>
                                <li>Usted no puede distribuir ni poner { etmmeet } disponible en una red donde pueda ser utilizada por múltiples dispositivos al mismo tiempo. Usted no puede alquilar, arrendar, ceder, prestar, vender, transferir redistribuir o sublicenciar { etmmeet }. Usted no puede copiar (salvo lo expresamente permitido por esta licencia), descompilar, realizar ingeniería inversa, desmontar, intentar descifrar el código fuente, modificar o crear trabajos derivados de la aplicación licenciada, las actualizaciones, o cualquier parte de la misma (excepto y únicamente en la medida en que cualquier restricción anterior esté prohibida por la ley aplicable). Cualquier intento de llevar a cabo las conductas anteriores, se entenderá como una violación de los derechos de Corporación Emprende tu Mente. Los términos de esta licencia se aplican a cualquier actualización proporcionada por Corporación Emprende tu Mente, a menos que dicha actualización esté acompañada por una licencia independiente, en cuyo caso los términos de dicha licencia prevalecerán.</li>
                                <li>La licencia estará vigente hasta que se le dé término por usted o por { etmmeet }. Los derechos otorgados por esta licencia terminarán automáticamente sin previo aviso por parte de { etmmeet }, en caso que usted no de cumplimiento a cualquier término (s) de la presente Licencia. A la terminación de la Licencia, usted deberá cesar el uso de la aplicación licenciada y destruir todas las copias, totales o parciales, de la misma.</li>
                            </ul>
                            <br />
                            <h2>8. Responsabilidad</h2>
                            <ul>
                                <li>Corporación Emprende tu Mente no garantiza ni declara que el uso de los servicios de { etmmeet } será sin interrupciones o libre de errores, usted acepta que cada cierto tiempo Corporación Emprende tu Mente podrá retirar los servicios de { etmmeet } por períodos indefinidos de tiempo, o cancelar tales servicios en cualquier momento, sin necesidad de previo aviso.</li>
                                <li>Usted acepta expresamente que el uso, o la imposibilidad de uso, de los servicios de { etmmeet } es bajo su exclusivo riesgo. Los servicios { etmmeet } son proporcionados "tal cual" sin soporte ni mantenimiento y disponible para su uso, sin garantías de ningún tipo, ya sean expresas o implícitas, incluyendo todas las garantías implícitas de comercialización, idoneidad para un propósito particular, derechos y no infracción.</li>
                                <li>En ningún caso Corporación Emprende tu Mente, sus directores, empleados, afiliados, agentes, contratistas, gerentes o licenciantes, serán responsables por cualquier daño derivado del uso de { etmmeet }. Corporación Emprende tu Mente realizará esfuerzos razonables para proteger la información entregada por usted en relación con { etmmeet }, sin embargo, usted acepta que la entrega de dicha información es bajo su propio riesgo y Corporación Emprende tu Mente no será en ningún caso responsable ante usted por cualquier pérdida o responsabilidad relacionada con dicha información. Corporación Emprende tu Mente no declara ni garantiza que la { etmmeet } será libre de pérdidas, corrupción, ataques, virus, interferencias, intromisiones en la seguridad por hacking o de otro tipo.</li>
                            </ul>
                            <br />
                            <h2>9. Varios</h2>
                            <ul>
                                <li>El usuario se obliga a utilizar la { etmmeet } de conformidad con la legislación vigente y estos Términos y Condiciones de Uso, debiendo darle un uso consistente con las exigencias que imponen el orden público, la moral, las buenas costumbres generalmente aceptadas y principalmente el respeto de terceros.</li>
                                <li>A tal efecto, el usuario se compromete a no utilizar { etmmeet } de forma perjudicial de los derechos e intereses de terceros, o que de cualquier forma pueda dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de { etmmeet }, los equipos informáticos o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo informático de Corporación Emprende tu Mente, de otros usuarios o de cualquier usuario de Internet.</li>
                                <li>Entre otras obligaciones, de manera ejemplar y sin que implique limitación alguna, al usuario se le prohíbe:
                                    <ul>
                                        <li>reproducir, copiar, distribuir o permitir el acceso a { etmmeet } a través de cualquier forma de comunicación pública, transformar o modificar los servicios, salvo que se cuente con la autorización del titular de los correspondientes derechos de propiedad intelectual o industrial, o ello resulte legalmente permitido;</li>
                                        <li>suprimir, eludir o manipular el derecho de propiedad intelectual e industrial y demás datos identificadores de los derechos de Corporación Emprende tu Mente o de otros titulares, incorporados a los servicios, así como los dispositivos técnicos de protección, las huellas digitales, medidas de autenticación biométrica o cualesquiera mecanismos de información que pudieren contener y/o solicitar los servicios;</li>
                                        <li>ejecutar, inducir o promover actos delictivos, denigrantes, difamatorios, o que en general sean contrarios a la ley, la moral, al orden público, o la seguridad nacional o que fueren discriminatorios por razón de sexo, raza, religión, creencias, edad o condición;</li>
                                        <li>no respetar la privacidad de los usuarios;</li>
                                        <li>usar los servicios con propósitos comerciales, incluyendo la promoción de cualquier bien o servicio;</li>
                                        <li>obstaculizar, entorpecer, restringir o limitar de cualquier modo el uso de los servicios por parte de otros usuarios; y</li>
                                        <li>proporcionar información que no sea exacta, verdadera, completa y actualizada o que de cualquier manera menoscabe a Corporación Emprende tu Mente o a terceros.</li>
                                    </ul>
                                </li>
                                <li>{ etmmeet } puede contener enlaces o links a sitios mantenidos por terceros y eventualmente pueden proporcionar material de terceros desde los sitios de éstos. Corporación Emprende tu Mente no es responsable por la funcionalidad, eficacia de los servicios y por los contenidos de aquellos sitios.</li>
                                <li>Corporación Emprende tu Mente no es responsable por los reclamos, pérdidas, acciones, daños, demandas judiciales o procedimientos que surjan o estén relacionados de alguna manera con ocasión de los enlaces con sitios de terceros. La presente exención de responsabilidad se extiende a los enlaces con sitios mantenidos por terceros, donde se realiza cualquier tipo de transacción entre y/o mediante el usuario y el tercero responsable del sitio.</li>
                            </ul>
                            <br />
                            <h2>Contáctenos</h2>
                            <p>Si tiene alguna pregunta sobre estos términos y condiciones, puede contactarnos:</p>
                            <ul>
                                <li>Por correo electrónico: <a href="mailto:contacto@emprendetumente.org?subject=Contacto%20desde%20Landing%20|%20{ etmmeet }"><b>contacto@emprendetumente.org</b></a></li>
                                <li>Visitando esta página en nuestro sitio web: <a href="https://emprendetumente.org/"><b>www.emprendetumente.org</b></a></li>
                            </ul>
                        </Col>
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(TermsConditions);