import React, { useState, useEffect } from "react";
import ImageUploader from "react-images-upload";
import { randomFileName, swal_etm } from "../../helpers/etmmeetUtils";
import { FormAPI } from '../../helpers/axios';
import Logo from "../../assets/images/conecTime/user_profile.png"

var mime = require("mime-types");

const UploadImageFile = props => {
    
    const [imgExtension, setImgExtension]           = useState( (props.imgExtension === undefined)      ?   [".jpg", ".jpeg", ".png"]                       :   props.imgExtension );
    const [label, setLabel]                         = useState( (props.label === undefined)             ?   "(Tamaño máximo 10mb. Solo jpg, jpeg o png)"    :   props.label );
    const [fileTypeError, setFileTypeError]         = useState( (props.fileTypeError === undefined)     ?   "Formato incorrecto, debe ser jpg, jpeg o png"  :   props.fileTypeError );
    const [withIcon, setWithIcon]                   = useState( (props.withIcon === undefined)          ?   true                                            :   props.withIcon );
    const [withPreview, setWithPreview]             = useState( (props.withPreview === undefined)       ?   true                                            :   props.withPreview );
    const [withLabel, setWithLabel]                 = useState( (props.withLabel === undefined)         ?   true                                            :   props.withLabel );
    const [singleImage, setSingleImage]             = useState( (props.singleImage === undefined)       ?   true                                            :   props.singleImage );
    const [buttonText, setButtonText]               = useState( (props.buttonText === undefined)        ?   "Seleccione imágenes"                           :   props.buttonText );
    const [buttonClassName, setButtonClassName]     = useState( (props.buttonClassName === undefined)   ?   "etm_bg_blue"                                   :   props.buttonClassName );
    const [avatarIcon, setAvatarIcon]               = useState( (props.avatarIcon === undefined)        ?   true                                            :   props.avatarIcon );
    const [pathS3, setPathS3]                       = useState( (props.pathS3 === undefined)            ?   ""                                              :   props.pathS3 );
    const [maxFileNumber, setMaxFileNumber]         = useState( (props.maxFileNumber === undefined)     ?   10                                              :   props.maxFileNumber );
    const [defaultImages, setDefaultImages]         = useState( (props.defaultImages === undefined || props.defaultImages === null)     ?   []              :   [props.defaultImages] );
    const [maxFileSize, setMaxFileSize]             = useState( (props.setMaxFileSize === undefined)    ?   10242880                                        :   props.setMaxFileSize );
    const [fileSizeError, setFileSizeError]         = useState( (props.fileSizeError === undefined)     ?   "Imagen demasiado grande"                       :   props.fileSizeError );

    useEffect(() => {
        changeIcon();
    }, [avatarIcon]);

    useEffect(() => {
        if(props.defaultImages === undefined || props.defaultImages === null){
            setDefaultImages([]);
        }else{
            setDefaultImages([props.defaultImages]);
        }
    }, [props.defaultImages]);

    useEffect(() => {
        if(props.avatarIcon === undefined){
            setAvatarIcon(true);
        }else{
            setAvatarIcon(props.avatarIcon);
        }
    }, [props.avatarIcon]);

    useEffect(() => {
        if(defaultImages.length > 0){
            setWithIcon(false);
            setWithLabel(false);
            changeIcon();
        }else{
            setWithIcon(true);
            setWithLabel(true);
            changeIcon();
        }
    }, [defaultImages]);

    const changeIcon = () => {
        if(avatarIcon){
            if(document.getElementsByClassName("uploadIcon")[0] !== undefined && document.getElementsByClassName("uploadIcon")[0] !== null){
                document.getElementsByClassName("uploadIcon")[0].src = Logo;
            }
        }
    }
  
    const onDrop = picture => {
        if(pathS3.length < 3){
            swal_etm("No se pudo cargar la imagen. Carpeta no definida");
            return
        }else{
            if(picture.length <= maxFileNumber){
                if(picture.length > 0){
                    setWithIcon(false);
                    setWithLabel(false);
                    changeIcon();
                    postPicture(picture);
                }else{
                    setWithIcon(true);
                    setWithLabel(true);
                    changeIcon();
                    props.handle([]);
                }
            }
        }
    };

    const postPicture = async (picture) => {
        let formData = new FormData();
        picture.map((e, i) => {
            formData.append(`dir`, pathS3);
            formData.append(`image`, e, `${randomFileName(pathS3)}.${mime.extension(e.type)}`);
        });

        const res = await FormAPI.put('/general/UploadiFile', formData);
        if(res.data.state){
            if(props.handle !== null && props.handle !== undefined){
                props.handle(res.data.url);
            }
        }
    }
  
  return (
    <ImageUploader
      {...props}
      withIcon={ withIcon }
      imgExtension={ imgExtension }
      maxFileSize={ maxFileSize }
      withPreview={ withPreview }
      withLabel={ withLabel }
      label={ label }
      fileSizeError={ fileSizeError }
      fileTypeError={ fileTypeError }
      onChange={onDrop}
      singleImage={ singleImage }
      buttonText={ buttonText }
      defaultImages={ defaultImages }
      buttonClassName={ buttonClassName }
    />
  );
};

export default UploadImageFile;