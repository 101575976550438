import React, { Fragment, useState, useEffect } from 'react';
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, CustomInput, InputGroup } from "reactstrap";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { swal_etm, pathS3, selectCustomStyle, rutInput, getDate, getLocalStorage, validateRut } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import LoadScreen from "../Utils/LoadScreen";
import UploadImageFile from "../Utils/UploadImageFile";
import UploadFiles from "../Utils/UploadFiles";
import Creatable from 'react-select/creatable';

const ModalStartup = (props) => {

    const date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia = getDate(primerDia, true).replace(/\-/g, '/');
    let primerDiaFormat = getDate(primerDia, true);
    const chileData = { value: 81, label: "Chile" };
    const yyyy = date.getFullYear();

    /*** BILLING YEAR ***/
    const billing_year = {
        year1: yyyy - 2
        , year2: yyyy - 1
    }

    const [startDate, setStartDate] = useState(primerDiaFormat);
    const [loading, setLoading] = useState({ data: true, btn: false });
    const [stages, setStages] = useState({ option1: [], option2: [] });
    const [vistaDatos, setVistaDatos] = useState({ option1: false, option2: false });
    const [showLeader, setShowLeader] = useState({ leader2: false, leader3: false });
    const [showRegions, setShowRegions] = useState(false);

    const [projectStage, setProjectStage] = useState("");
    const [projectName, setProjectName] = useState({ show: false, name: "" });
    const [projectIndustry, setProjectIndustry] = useState(null);
    const [projectDescription, setProjectDescription] = useState("");
    const [projectRut, setProjectRut] = useState("");
    const [projectCommercial, setProjectCommercial] = useState("");
    const [projectActivityDate, setProjectActivityDate] = useState("");
    const [projectEmployees, setProjectEmployees] = useState(null);
    const [projectWebsite, setProjectWebsite] = useState({ is_website: true, url: "" });
    const [proyectPictures, setProyectPictures] = useState([]);
    const [projectCountries, setProjectCountries] = useState([chileData]);
    const [projectRegions, setProjectRegions] = useState(null);
    const [projectInvestment, setProjectInvestment] = useState(null);
    const [projectInvoices, setProjectInvoices] = useState(null);
    const [projectBilling1, setProjectBilling1] = useState(null);
    const [projectBilling2, setProjectBilling2] = useState(null);
    const [projectLeaders, setProjectLeaders] = useState({ leader1: null, leader2: null, leader3: null });
    const [projectImpacts, setProjectImpacts] = useState(null);
    const [projectOds, setProjectOds] = useState(null);
    const [projectWomenLeader, setProjectWomenLeader] = useState(0);
    const [projectGenderEquality, setProjectGenderEquality] = useState(0);
    const [projectDiversityInclusion, setProjectDiversityInclusion] = useState(0);
    const [projectTag, setProjectTag] = useState(null);
    const [projectCommercialType, setProjectCommercialType] = useState(null);

    const [listStages, setListStages] = useState([]);
    const [listIndustries, setListIndustries] = useState([]);
    const [listEmployees, setListEmployees] = useState([]);
    const [listCountries, setListCountries] = useState([]);
    const [listRegions, setListRegions] = useState([]);
    const [listInvestment, setListInvestment] = useState([]);
    const [listInvoices, setListInvoices] = useState([]);
    const [listBillings, setListBillings] = useState([]);
    const [listImpacts, setListImpacts] = useState([]);
    const [listOds, setListOds] = useState([]);
    const [listTags, setListTags] = useState([]);
    const [listCommercialType, setListCommercialType] = useState([]);

    useEffect(() => {
        getStartupData();
    }, [props.startupId]);

    useEffect(() => {
        changeView();
    }, [projectStage]);

    useEffect(() => {
        showChileanRegions();
    }, [projectCountries]);

    useEffect(() => {
        getOds();
        getImpacts();
        getInvoices();
        getInvestment();
        getProjectStage();
        getEmployees();
        getInduistries();
        getTags();
        getCountries();
        getRegions();
        getBillings();
        getCommercialType();
    }, []);

    const getStartupData = async () => {
        cleanData();
        if (props.startupId < 1) {
            setLoading({ data: false, btn: false });
        } else {
            setLoading({ data: true, btn: false });
            const res = await API.get('/profile/getStartupData', { params: { startupId: props.startupId } });
            if (res.data.state) {
                setLoading({ data: false, btn: false });
                if (res.data.data.startup !== null) {
                    setProjectStage(res.data.data.startup.ETAPA);
                    const element = document.getElementById(`radioEtapa_${res.data.data.startup.ETAPA}`);
                    if (element !== null && element !== undefined) {
                        element.click();
                    }

                    setProjectRut(res.data.data.startup.DNI);
                    setProjectName({ show: true, name: res.data.data.startup.NOMBRE });
                    setProjectCommercial(res.data.data.startup.GIRO);
                    setProjectDescription(res.data.data.startup.DESCRIPCION);
                    setProjectWebsite({ is_website: true, url: res.data.data.startup.SITIO_WEB });
                    setProjectWomenLeader(res.data.data.startup.MUJER_LIDER);
                    setProjectGenderEquality(res.data.data.startup.EQUIDAD);
                    setProjectDiversityInclusion(res.data.data.startup.INCLUSION);
                    setStartDate((res.data.data.startup.INICIO_ACTIVIDADES !== null) ? res.data.data.startup.INICIO_ACTIVIDADES.split('-').reverse().join('-') : primerDiaFormat);
                    setProjectActivityDate((res.data.data.startup.INICIO_ACTIVIDADES !== undefined && res.data.data.startup.INICIO_ACTIVIDADES !== null && res.data.data.startup.INICIO_ACTIVIDADES.length > 0) ? res.data.data.startup.INICIO_ACTIVIDADES.split('-').reverse().join('-') : primerDiaFormat);
                }

                if (res.data.data.industry !== null) {
                    setProjectIndustry({ value: res.data.data.industry.ID, label: res.data.data.industry.NOMBRE });
                }

                if (res.data.data.employees !== null) {
                    setProjectEmployees({ value: res.data.data.employees.ID, label: res.data.data.employees.NOMBRE });
                }

                if (res.data.data.investment !== null) {
                    setProjectInvestment({ value: res.data.data.investment.ID, label: res.data.data.investment.NOMBRE });
                }

                if (res.data.data.invoices !== null) {
                    setProjectInvoices({ value: res.data.data.invoices.ID, label: res.data.data.invoices.NOMBRE });
                }

                if (res.data.data.ods !== null) {
                    setProjectOds({ value: res.data.data.ods.ID, label: res.data.data.ods.NOMBRE });
                }

                if (res.data.data.ods !== null) {
                    setProjectOds({ value: res.data.data.ods.ID, label: res.data.data.ods.NOMBRE });
                }

                if (res.data.data.billings.year1 !== null) {
                    setProjectBilling1({ value: res.data.data.billings.year1[0].ID, label: res.data.data.billings.year1[0].NOMBRE });
                }

                if (res.data.data.billings.year2 !== null) {
                    setProjectBilling2({ value: res.data.data.billings.year2[0].ID, label: res.data.data.billings.year2[0].NOMBRE });
                }

                if (res.data.data.tags !== null) {
                    const list = [];
                    res.data.data.tags.map((e, i) => {
                        list.push({ value: e.ID, label: e.NOMBRE });
                    });
                    setProjectTag(list);
                }

                if (res.data.data.countries !== null) {
                    const list = [];
                    res.data.data.countries.map((e, i) => {
                        list.push({ value: e.ID, label: e.NOMBRE });
                    });
                    setProjectCountries(list);
                }

                if (res.data.data.regions !== null) {
                    const list = [];
                    res.data.data.regions.map((e, i) => {
                        list.push({ value: e.ID, label: e.NOMBRE });
                    });
                    setProjectRegions(list);
                }

                if (res.data.data.impact !== null) {
                    const list = [];
                    res.data.data.impact.map((e, i) => {
                        list.push({ value: e.ID, label: e.NOMBRE });
                    });
                    setProjectImpacts(list);
                }

                if (res.data.data.leaders !== null) {
                    let l1 = null;
                    let l2 = null;
                    let show2 = false;
                    let l3 = null;
                    let show3 = false;
                    res.data.data.leaders.map((e, i) => {
                        if (i === 0) {
                            l1 = e.NOMBRE;
                        } else if (i === 1) {
                            show2 = true;
                            l2 = e.NOMBRE;
                        } else {
                            show3 = true;
                            l3 = e.NOMBRE;
                        }
                    });
                    setProjectLeaders({ leader1: l1, leader2: l2, leader3: l3 });
                    setShowLeader({ leader2: show2, leader3: show3 })
                }

                if (res.data.data.commercial_type !== null) {
                    const list = [];
                    res.data.data.commercial_type.map((e, i) => {
                        list.push({ value: e.ID, label: e.NOMBRE });
                    });
                    setProjectCommercialType(list);
                }

                if (res.data.data.files !== null) {
                    const list = [];
                    res.data.data.files.map((e, i) => {
                        list.push(e.FOTO);
                    });
                    setProyectPictures(list);
                }
            }
        }

    };

    const cleanData = () => {
        setVistaDatos({ option1: false, option2: false });
        setShowLeader({ leader2: false, leader3: false });
        setShowRegions(false);
        setProjectStage("");
        setProjectName({ show: false, name: "" });
        setProjectIndustry(null);
        setProjectDescription("");
        setProjectRut("");
        setProjectCommercial("");
        setProjectActivityDate("");
        setProjectEmployees(null);
        setProjectWebsite({ is_website: true, url: "" });
        setProyectPictures([]);
        setProjectCountries([chileData]);
        setProjectRegions(null);
        setProjectInvestment(null);
        setProjectInvoices(null);
        setProjectBilling1(null);
        setProjectBilling2(null);
        setProjectLeaders({ leader1: null, leader2: null, leader3: null });
        setProjectImpacts(null);
        setProjectOds(null);
        setProjectWomenLeader(0);
        setProjectGenderEquality(0);
        setProjectDiversityInclusion(0);
        setProjectTag(null);
    }

    const getTags = async () => {
        const res = await API.get('/general/getTags');
        if (res.data.tags.length > 0) {
            let list = [];
            res.data.tags.map((e, i) => {
                list.push({ value: e.ID, label: e.TAG });
            });
            setListTags(list);
        }
    }

    const getOds = async () => {
        const res = await API.get('/general/getOds');
        if (res.data.ods.length > 0) {
            let list = [];
            res.data.ods.map((e, i) => {
                list.push({ value: e.ID, label: e.ODS });
            });
            setListOds(list);
        }
    }

    const getImpacts = async () => {
        const res = await API.get('/general/getImpacts');
        if (res.data.impacts.length > 0) {
            let list = [];
            res.data.impacts.map((e, i) => {
                list.push({ value: e.ID, label: e.IMPACTO });
            });
            setListImpacts(list);
        }
    }

    const showChileanRegions = () => {
        if (projectCountries !== null) {
            if (projectCountries.filter(e => e.label.toString().toUpperCase() === 'CHILE').length > 0) {
                setShowRegions(true);
            } else {
                setShowRegions(false);
            }
        }
    }

    const getBillings = async (e) => {
        const res = await API.get('/general/getBillings', {});

        if (res.data.billings.length > 0) {
            let list = [];
            res.data.billings.map((e, i) => {
                list.push({ value: e.ID, label: e.FACTURACION });
            });
            setListBillings(list);
        }
    }

    const getInvoices = async () => {
        const res = await API.get('/general/getInvoices');
        if (res.data.invoices.length > 0) {
            let list = [];
            res.data.invoices.map((e, i) => {
                list.push({ value: e.ID, label: e.FACTURAS });
            });
            setListInvoices(list);
        }
    }

    const getInvestment = async () => {
        const res = await API.get('/general/getInvestment');
        if (res.data.investment.length > 0) {
            let list = [];
            res.data.investment.map((e, i) => {
                list.push({ value: e.ID, label: e.INVERSION });
            });
            setListInvestment(list);
        }
    }

    const getRegions = async (e) => {
        const res = await API.get('/general/getRegions', {});
        let list = [];
        if (res.data.state) {
            res.data.regions.map((e, i) => {
                list.push({ value: e.ID, label: e.REGION });
            });
            setListRegions(list);
        }
    }

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        let list = [];
        if (res.data.state) {
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS });
            });
        }
        setListCountries(list);
    }

    const getEmployees = async () => {
        const res = await API.get('/general/getEmployees');
        if (res.data.employees.length > 0) {
            let list = [];
            res.data.employees.map((e, i) => {
                list.push({ value: e.ID, label: e.EMPLEADOS });
            });
            setListEmployees(list);
        }
    }

    const changeView = () => {
        if (stages.option1.includes(projectStage)) {
            setVistaDatos({ option1: true, option2: false })
            setProjectName({ show: false, name: "SIN NOMBRE DEFINIDO" });
        } else if (stages.option2.includes(projectStage)) {
            setVistaDatos({ option1: false, option2: true })
            setProjectName({ show: true, name: "" });
        }
    }

    const getProjectStage = async () => {
        const res = await API.get('/general/getProjectStage');
        if (res.data.stage.length > 0) {
            let list = [];
            let stageOption1 = [];
            let stageOption2 = [];
            res.data.stage.map((e, i) => {
                if (!e.ETAPA_ACTIVA) {
                    stageOption1.push(e.ID);
                } else {
                    stageOption2.push(e.ID);
                }
                list.push(
                    <Col lg={3} md={6} sm={12} key={i}>
                        <FormGroup check>
                            <Label className="h5">
                                <Input name="radioEtapa" type="radio" id={`radioEtapa_${e.ID}`} onClick={() => setProjectStage(e.ID)} value={e.ID} />{' '}{e.ETAPA}
                            </Label>
                            <Label className="m-2">
                                <span dangerouslySetInnerHTML={{ __html: e.TITULO }} ></span>
                                <br />
                                <br />
                                <i className="fas fa-arrow-right etm_text_blue mr-2"></i><span dangerouslySetInnerHTML={{ __html: e.SUBTITULO }} ></span>
                            </Label>
                        </FormGroup>
                    </Col>
                );
            });
            setListStages(list);
            setStages({ option1: stageOption1, option2: stageOption2 });
        }
    }

    const getInduistries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
    }

    const getCommercialType = async (e) => {
        const res = await API.get('/general/getCommercialType', {});

        if (res.data.types.length > 0) {
            let list = [];
            res.data.types.map((e, i) => {
                list.push({ value: e.ID, label: `${e.TIPO} (${e.DESCRIPCION})` });
            });
            setListCommercialType(list);
        }
    }

    const changeActivityDate = (event, picker) => {
        setProjectActivityDate(getDate(picker.startDate, true));
    }

    const preSave = () => {
        setLoading({ data: false, btn: true });

        if (vistaDatos.option2) {
            if (!validateRut(projectRut)) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">RUT</b> válido.`);
                setLoading({ data: false, btn: false });
                return;
            }
        }

        if (projectName.name.length < 2) {
            swal_etm(`Debes ingresar el <b class="etm_text_blue">Nombre del proyecto</b> válido.`);
            setLoading({ data: false, btn: false });
            return;
        }

        if (projectIndustry === null || projectIndustry.length <= 1) {
            swal_etm(`Debes ingresar una <b class="etm_text_blue">Categoria/Industria</b> válida.`);
            setLoading({ data: false, btn: false });
            return;
        }

        if (vistaDatos.option2) {
            if (projectCommercial === "" || projectCommercial.length < 2) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Giro Comercial</b> válido.`);
                setLoading({ data: false, btn: false });
                return;
            }

            let diaActual = getDate(date, false);
            let diaIndicado = projectActivityDate.split('-').reverse().join('-')

            if (diaIndicado > diaActual) {
                swal_etm(`Debes ingresar una <b class="etm_text_blue">Fecha de inicio de actividades</b> anterior a hoy.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectEmployees === null || projectEmployees.length < 1) {
                swal_etm(`Debes indicar cuantos <b class="etm_text_blue">Colaboradores</b> hay en el proyecto.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectCommercialType === null || projectCommercialType.length < 1) {
                swal_etm(`Debes indicar al menos un <b class="etm_text_blue">Tipo de comercio</b> para tu proyecto.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectWebsite.url === '') {
                if (projectWebsite.is_website) {
                    swal_etm(`Debes ingresar un <b class="etm_text_blue">Sitio WEb</b> </br>Recuerda que si no tienes <b class="etm_text_blue">Sitio WEb</b> puedes ingresar una <b class="etm_text_blue">Red Social</b>.`);
                    setLoading({ data: false, btn: false });
                    return;
                } else {
                    swal_etm(`Debes ingresar una <b class="etm_text_blue">Red Social</b> <br />Recuerda que si no tienes una <b class="etm_text_blue">Red Social</b> puedes ingresar un <b class="etm_text_blue">Sitio WEb</b>.`);
                    setLoading({ data: false, btn: false });
                    return;
                }
            }
        }

        if (projectDescription === "" || projectDescription.length < 15) {
            swal_etm(`Describe tu <b class="etm_text_blue">Proyecto</b> en al menos 15 caracteres.`);
            setLoading({ data: false, btn: false });
            return;
        }

        if (vistaDatos.option2) {
            if (projectCountries === null || projectCountries.length < 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Pais</b> en el que opere tu proyecto.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectCountries.filter(e => e.value === chileData.value).length >= 1) {
                if (projectRegions === null || projectRegions.length < 1) {
                    swal_etm(`Seleccionar al menos 1 <b class="etm_text_blue">región de los emprendimientos</b> de tu interés`);
                    setLoading({ data: false, btn: false });
                    return;
                }
            }

            if (projectInvestment === null || projectInvestment.length <= 1) {
                swal_etm(`Debes indicar si tu proyecto ah recibido <b class="etm_text_blue">inversiones públicas o privadas</b>.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectInvoices === null || projectInvoices.length <= 1) {
                swal_etm(`Debes indicar la cantidad aproximada de <b class="etm_text_blue">facturas emitidas</b>.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectBilling1 === null || projectBilling1.length <= 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Rango de facturación</b> <br /> aproximado que generó tu emprendimiento durante el <b class="etm_text_blue">${billing_year.year1}</b>.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectBilling2 === null || projectBilling2.length <= 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Rango de facturación</b> <br /> aproximado que generó tu emprendimiento durante el <b class="etm_text_blue">${billing_year.year2}</b>.`);
                setLoading({ data: false, btn: false });
                return;
            }

            if (projectLeaders.leader1 === null) {
                swal_etm(`Debes ingresar almenos un <b class="etm_text_blue">líder del proyecto</b>.`);
                setLoading({ data: false, btn: false });
                return;
            }
        }

        if (projectTag === null || projectTag.length < 1) {
            swal_etm(`Debes ingresar al menos una <b class="etm_text_blue">Etiqueta</b>.`);
            setLoading({ data: false, btn: false });
            return;
        }

        save();
    }

    const save = async () => {
        const data = {
            projectStage
            , projectRut
            , projectName: projectName.name
            , projectIndustry: projectIndustry.value
            , projectCommercial
            , projectActivityDate: projectActivityDate.split('-').reverse().join('-')
            , projectEmployees: (projectEmployees !== null) ? projectEmployees.value : ''
            , projectWebsite: projectWebsite.url
            , projectDescription
            , proyectPictures         // array con url de las imagenes
            , projectCountries          // objeto con indice y valor
            , projectRegions            // objeto con indice y valor
            , projectInvestment: (projectInvestment !== null) ? projectInvestment.value : ''
            , projectInvoices: (projectInvoices !== null) ? projectInvoices.value : ''
            , projectBilling1: (projectBilling1 !== null) ? { value: projectBilling1.value, year: billing_year.year1 } : ''
            , projectBilling2: (projectBilling2 !== null) ? { value: projectBilling2.value, year: billing_year.year2 } : ''
            , projectLeaders          // objeto con indice y valor
            , projectImpacts            // objeto con indice y valor
            , projectOds: (projectOds !== null) ? projectOds.value : ''
            , projectWomenLeader
            , projectGenderEquality
            , projectDiversityInclusion
            , projectTag            // objeto con indice y valor
            , projectCommercialType
            , token: getLocalStorage().token
            , startupId: props.startupId
        };

        let res = {};
        if (props.startupId < 1) {
            res = await API.post("/profile/saveProyect", data);
        } else {
            res = await API.put("/profile/updateProyect", data);
        }

        if (res.data.state) {
            swal_etm(res.data.msg, "success");
            props.setModal(false);
            props.getStartupsData();
        } else {
            swal_etm(res.data.msg);
        }
        setLoading({ data: false, btn: false });
    }

    const stepNumber = (e, title) => {
        return <Label className="h5 mt-4"><b className="etm_text_blue mr-2">{e}. </b><span dangerouslySetInnerHTML={{ __html: title }} ></span></Label>
    }

    return (
        <Modal isOpen={props.modal} size="xl" scrollable={true}>
            <ModalHeader>
                <FormGroup check>
                    <Label className="h3">Agregar nuevo proyecto</Label>
                </FormGroup>
            </ModalHeader>
            <ModalBody>
                <FormGroup check>
                    <Label className="h5">
                        <p className="mb-0 text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Crear un nuevo emprendimiento es bastante sencillo, sólo debes completar los siguientes datos y ya estará disponible.</p>
                    </Label>
                    <hr />
                </FormGroup>

                {
                    (loading.data) ?
                        <LoadScreen text="Cargando datos..." />
                        : <FormGroup check>
                            {
                                (listStages.length > 0) ?
                                    <Fragment>
                                        {stepNumber(1, "¿ En qué etapa se encuentra tu proyecto o emprendimiento ?")}
                                        <FormGroup check>
                                            <Row className="mt-4">
                                                {listStages}
                                            </Row>
                                        </FormGroup>
                                    </Fragment>
                                    : ""
                            }


                            {/* EMPRENDIMIENTOS NO CONSTITUIDOS */}
                            {
                                (vistaDatos.option1) ?
                                    <Fragment>
                                        {stepNumber(2, "Cuéntanos sobre tu proyecto. ¿ Tu proyecto tiene nombre ?")}
                                        <FormGroup check>
                                            <Label className="h5 mt-3 ml-5">
                                                <Input defaultChecked={!projectName.show} name="radioProyecto" type="radio" onClick={(e) => setProjectName({ show: false, name: e.target.value })} value="SIN NOMBRE DEFINIDO" />{' '}Aún no lo defino
                                            </Label>
                                            <br />
                                            <Label className="h5 ml-5">
                                                <Input defaultChecked={projectName.show} name="radioProyecto" type="radio" onClick={(e) => setProjectName({ show: true, name: "" })} value="DEFINIDO" />{' '}SI
                                            </Label>
                                            <Row hidden={!projectName.show} className="animate__animated animate__fadeIn">
                                                <Col md={6}>
                                                    <Input className="form-control etm_register_input" type="text" onChange={(e) => setProjectName({ show: projectName.show, name: e.target.value })} value={projectName.name} placeholder="Ingresa el nombre de tu proyecto o emprendimiento" />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        {stepNumber(3, "¿ En qué categoría o industria lo clasificarías ?")}
                                        <FormGroup check>
                                            <FormGroup check>
                                                <Select
                                                    placeholder={"Selecciona una categoría o industria"}
                                                    value={projectIndustry}
                                                    onChange={setProjectIndustry}
                                                    options={listIndustries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </FormGroup>
                                        {stepNumber(4, `¿ Qué tipo de comercio aplica para tu proyecto ? <small className="text-muted">( opcional, selecciona sólo los que correspondan )</small>`)}
                                        <FormGroup check>
                                            <FormGroup check>
                                                <Select
                                                    placeholder={"Selecciona un tipo de comercio"}
                                                    value={projectCommercialType}
                                                    onChange={setProjectCommercialType}
                                                    options={listCommercialType}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </FormGroup>
                                        {stepNumber(5, `Descríbenos tu proyecto <small className="text-muted">( máximo 400 caracteres )</small>`)}
                                        <FormGroup check>
                                            <FormGroup check>
                                                <br />
                                                <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setProjectDescription(e.target.value)} value={projectDescription} placeholder="Mi proyecto se trata de ..." />
                                            </FormGroup>
                                        </FormGroup>
                                        {stepNumber(6, `¿ Tu proyecto tiene sitio web ? <small>Si no lo tiene, deja el link de alguna de las redes sociales de tu proyecto</small>`)}
                                        <FormGroup check>
                                            <FormGroup check>
                                                <div className="ml-3">
                                                    <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch2" label="Tengo sitio web" bsSize="lg" checked={projectWebsite.is_website} onChange={() => setProjectWebsite({ is_website: !projectWebsite.is_website, url: projectWebsite.url })} />
                                                </div>
                                                <Input onChange={(e) => setProjectWebsite({ is_website: projectWebsite.is_website, url: e.target.value })} value={projectWebsite.url} className="form-control etm_register_input" type="url" placeholder={(projectWebsite.is_website) ? "Ingresa la URL de tu sitio web" : "Ingresa la URL de Instagram o Facebook"} />
                                            </FormGroup>
                                        </FormGroup>
                                        {stepNumber(7, `¿ Tienes alguna imagen asociada al proyecto ? <small className="text-muted">( opcional, pero te recomendamos subir al menos una  )</small>`)}
                                        <FormGroup check>
                                            <UploadImageFile
                                                buttonText={"Click aquí para subir imágenes"}
                                                pathS3={pathS3("STARTUP")}
                                                singleImage={false}
                                                maxFileNumber={4}
                                                handle={setProyectPictures}
                                            />
                                        </FormGroup>
                                    </Fragment>
                                    : ""
                            }


                            {/* EMPRENDIMIENTOS CONSTITUIDOS */}
                            {
                                (vistaDatos.option2) ?
                                    <Fragment>
                                        {stepNumber(2, "Acerca de tu proyecto")}
                                        <FormGroup check>
                                            <Row>
                                                <Col md={6} className="mt-3">
                                                    <FormGroup check>
                                                        <Label className="h5">Ingresa el RUT de tu emprendimiento</Label>
                                                        <br />
                                                        <Input onChange={(e) => (rutInput(e), setProjectRut(e.target.value))} value={projectRut} name="rut" id="rut" className="form-control etm_register_input" type="text" placeholder="Ingresa el RUT de tu emprendimiento" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">Nombre o Razón Social de tu emprendimiento</Label>
                                                        <br />
                                                        <Input onChange={(e) => (setProjectName({ show: projectName.show, name: e.target.value }))} value={projectName.name} className="form-control etm_register_input" type="text" placeholder="Ingresa el Nombre o Razón Social de tu emprendimiento" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Cuál es la Industria de tu emprendimiento ?</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona una categoría o industria"}
                                                            value={projectIndustry}
                                                            onChange={setProjectIndustry}
                                                            options={listIndustries}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Cuál es el Giro Comercial del emprendimiento ?</Label>
                                                        <br />
                                                        <Input onChange={(e) => setProjectCommercial(e.target.value)} value={projectCommercial} className="form-control etm_register_input" type="text" placeholder="Ingresa Giro Comercial del emprendimiento" />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">Fecha de inicio de actividades</Label>
                                                        <br />
                                                        <DateRangePicker
                                                            onApply={changeActivityDate}
                                                            initialSettings={{
                                                                singleDatePicker: true,
                                                                autoApply: true,
                                                                showDropdowns: true,
                                                                startDate: startDate,
                                                                locale: {
                                                                    format: "DD-MM-YYYY",
                                                                    daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                                                                    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
                                                                }
                                                            }}>
                                                            <input
                                                                onChange={(e) => setProjectActivityDate(e)}
                                                                value={projectActivityDate}
                                                                className="form-control etm_register_input"
                                                                type="text"
                                                                placeholder="DD-MM-YYYY / DD-MM-YYYY"
                                                                required readOnly
                                                            />
                                                        </DateRangePicker>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Cuántos colaboradores hay en el proyecto ?</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona un rango de colaboradores"}
                                                            value={projectEmployees}
                                                            onChange={setProjectEmployees}
                                                            options={listEmployees}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Qué tipo de comercio aplica para tu proyecto ? <small className="text-muted">( ( selecciona sólo los que correspondan ) )</small></Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona un tipo de comercio"}
                                                            value={projectCommercialType}
                                                            onChange={setProjectCommercialType}
                                                            options={listCommercialType}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                            isMulti
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">Descríbenos tu proyecto <small className="text-muted">( máximo 400 caracteres )</small></Label>
                                                        <br />
                                                        <br />
                                                        <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setProjectDescription(e.target.value)} value={projectDescription} placeholder="Mi proyecto se trata de ..." />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto tiene sitio web ?</Label>
                                                        <br />
                                                        <Label className="h5"><small>Si no lo tiene, deja el link de alguna de las redes sociales de tu proyecto</small></Label>
                                                        <br />
                                                        <br />
                                                        <div className="ml-3">
                                                            <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch2" label="Tengo sitio web" bsSize="lg" checked={projectWebsite.is_website} onChange={() => setProjectWebsite({ is_website: !projectWebsite.is_website, url: projectWebsite.url })} />
                                                        </div>
                                                        <Input onChange={(e) => setProjectWebsite({ is_website: projectWebsite.is_website, url: e.target.value })} value={projectWebsite.url} className="form-control etm_register_input" type="url" placeholder={(projectWebsite.is_website) ? "Ingresa la URL de tu sitio web" : "Ingresa la URL de Instagram o Facebook"} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <br />
                                        </FormGroup>

                                        {stepNumber(3, "Imágenes")}
                                        <FormGroup check>
                                            <Row>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tienes alguna imagen asociada al proyecto ? <small className="text-muted">( opcional, pero te recomendamos subir al menos una  )</small></Label>
                                                        <UploadImageFile
                                                            buttonText={"Click aquí para subir imágenes"}
                                                            pathS3={pathS3("STARTUP")}
                                                            singleImage={false}
                                                            maxFileNumber={4}
                                                            handle={setProyectPictures}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        {stepNumber(4, "Datos para el match")}
                                        <FormGroup check>
                                            <Row>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ En qué países opera tu proyecto o emprendimiento ? <small className="text-muted">( selecciona todos los países que quieras )</small></Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona países"}
                                                            value={projectCountries}
                                                            onChange={setProjectCountries}
                                                            options={listCountries}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                            isMulti
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" hidden={!showRegions}>
                                                    <FormGroup check>
                                                        <Label className="h5">¿ En qué regiones de Chile opera tu proyecto o emprendimiento ? <small className="text-muted">( selecciona todas los regiones que quieras )</small></Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona regiones"}
                                                            value={projectRegions}
                                                            onChange={setProjectRegions}
                                                            options={listRegions}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                            isMulti
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" >
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto ha recibido inversiones públicas o privadas ?</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona una opción"}
                                                            value={projectInvestment}
                                                            onChange={setProjectInvestment}
                                                            options={listInvestment}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" >
                                                    <FormGroup check>
                                                        <Label className="h5">Cantidad aproximada de facturas emitidas a la fecha</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona un rango de facturación"}
                                                            value={projectInvoices}
                                                            onChange={setProjectInvoices}
                                                            options={listInvoices}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" >
                                                    <FormGroup check>
                                                        <Label className="h5">Rango de facturación aproximado que generó tu emprendimiento durante el {billing_year.year1}</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona un rango de facturación"}
                                                            value={projectBilling1}
                                                            onChange={setProjectBilling1}
                                                            options={listBillings}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" >
                                                    <FormGroup check>
                                                        <Label className="h5">Rango de facturación aproximado que generó tu emprendimiento durante el {billing_year.year2}</Label>
                                                        <br />
                                                        <Select
                                                            placeholder={"Selecciona un rango de facturación"}
                                                            value={projectBilling2}
                                                            onChange={setProjectBilling2}
                                                            options={listBillings}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={180}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4" >
                                                    <FormGroup check>
                                                        <Label className="h5">Ingresa el nombre de algún líder del proyecto <small>( mínimo 1 - máximo 3 )</small></Label>
                                                        <br />
                                                        <InputGroup>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text etm_input_group text-muted">Nombre líder 1</span>
                                                            </div>
                                                            <Input onChange={(e) => setProjectLeaders({ leader1: e.target.value, leader2: projectLeaders.leader2, leader3: projectLeaders.leader3 })} value={projectLeaders.leader1} className="form-control etm_register_input" type="text" placeholder="Nombre del primer líder" />
                                                        </InputGroup>
                                                        <Label hidden={showLeader.leader2} className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => setShowLeader({ leader2: true, leader3: false })}>Agregar segundo líder +</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} hidden={!showLeader.leader2}>
                                                    <FormGroup check>
                                                        <br />
                                                        <InputGroup>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text etm_input_group text-muted">Nombre líder 2</span>
                                                            </div>
                                                            <Input onChange={(e) => setProjectLeaders({ leader1: projectLeaders.leader1, leader2: e.target.value, leader3: projectLeaders.leader3 })} value={projectLeaders.leader2} className="form-control etm_register_input" type="text" placeholder="Nombre del segundo líder" />
                                                        </InputGroup>
                                                        <Label hidden={showLeader.leader3} className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => setShowLeader({ leader2: true, leader3: true })}>Agregar tercer líder +</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-3" hidden={!showLeader.leader3}>
                                                    <FormGroup check>
                                                        <InputGroup>
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text etm_input_group text-muted">Nombre líder 3</span>
                                                            </div>
                                                            <Input onChange={(e) => setProjectLeaders({ leader1: projectLeaders.leader1, leader2: projectLeaders.leader2, leader3: e.target.value })} value={projectLeaders.leader3} className="form-control etm_register_input" type="text" placeholder="Nombre del tercer líder" />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        {stepNumber(5, "Propósito e impacto social")}
                                        <FormGroup check>
                                            <Row>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto considera objetivos de <span className="etm_text_blue">Impacto Social</span> ? <small className="text-muted">( opcional, elige todas las opciones que quieras )</small></Label>
                                                        <br />
                                                        <Label className="h5"><small className="text-muted">Elige todas las que consideres de la lista o puedes crear nuevas opciones al escribirlas.</small></Label>
                                                        <br />
                                                        <Creatable
                                                            formatCreateLabel={e => `Crear nueva opción ${e}`}
                                                            placeholder={"Elige algunas opciones"}
                                                            value={projectImpacts}
                                                            onChange={setProjectImpacts}
                                                            options={listImpacts}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={150}
                                                            isMulti
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto considera algunos <span className="etm_text_blue">Objetivos de Desarrollo Sostenible</span> ( ODS ) ? <small className="text-muted">( opcional, elige una de las opciones que quieras )</small></Label>
                                                        <br />
                                                        <Label className="h5"><small className="text-muted">Puedes crear una nueva opciones al escribirla.</small></Label>
                                                        <br />
                                                        <Creatable
                                                            formatCreateLabel={e => `Crear nueva opción ${e}`}
                                                            placeholder={"Elige algunas opciones"}
                                                            value={projectOds}
                                                            onChange={setProjectOds}
                                                            options={listOds}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={150}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto es liderado por alguna <span className="etm_text_blue">Mujer</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                        <br />
                                                        <Label className="h5 mt-3 ml-5">
                                                            <Input name="radioMujer" type="radio" onClick={() => setProjectWomenLeader(0)} defaultChecked={!projectWomenLeader} />{' '}NO
                                                        </Label>
                                                        <br />
                                                        <Label className="h5 ml-5">
                                                            <Input name="radioMujer" type="radio" onClick={() => setProjectWomenLeader(1)} defaultChecked={projectWomenLeader} />{' '}SI
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto considera políticas de <span className="etm_text_blue">Equidad de Género</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                        <br />
                                                        <Label className="h5 mt-3 ml-5">
                                                            <Input name="radioEquidad" type="radio" onClick={() => setProjectGenderEquality(0)} defaultChecked={!projectGenderEquality} />{' '}NO
                                                        </Label>
                                                        <br />
                                                        <Label className="h5 ml-5">
                                                            <Input name="radioEquidad" type="radio" onClick={() => setProjectGenderEquality(1)} defaultChecked={projectGenderEquality} />{' '}SI
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} className="mt-4">
                                                    <FormGroup check>
                                                        <Label className="h5">¿ Tu proyecto considera políticas de <span className="etm_text_blue">Inclusión de Diversidad</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                        <br />
                                                        <Label className="h5 mt-3 ml-5">
                                                            <Input name="radioDiversidad" type="radio" onClick={() => setProjectDiversityInclusion(0)} defaultChecked={!projectDiversityInclusion} />{' '}NO
                                                        </Label>
                                                        <br />
                                                        <Label className="h5 ml-5">
                                                            <Input name="radioDiversidad" type="radio" onClick={() => setProjectDiversityInclusion(1)} defaultChecked={projectDiversityInclusion} />{' '}SI
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Fragment>
                                    : ""
                            }

                            {
                                (vistaDatos.option1 === true || vistaDatos.option2 === true) ?
                                    <Fragment>
                                        {stepNumber((vistaDatos.option1) ? 8 : 6, "Etiquetas")}
                                        <FormGroup check>
                                            <Row>
                                                <Col md={12}>
                                                    <FormGroup check>
                                                        <br />
                                                        <Label className="h5">Para mejorar el match entre los expertos y los emprendimientos, puedes agregar <span className="etm_text_blue">etiquetas</span> a tus emprendimientos. Esto funciona similar a los hashtag de las redes sociales y facilitará generar las reuniones.</Label>
                                                        <br />
                                                        <Label className="h5"><small className="text-muted">Elige al menos 1 etiqueta ya existente o crea nuevas si necesitas. Solo escribe la etiqueta y listo.</small></Label>
                                                        <br />
                                                        <Creatable
                                                            formatCreateLabel={e => `Crear etiqueta ${e}`}
                                                            placeholder={"Elige tus etiquetas"}
                                                            value={projectTag}
                                                            onChange={(e) => (e !== null) ? ((e.length <= 10) ? setProjectTag(e) : null) : setProjectTag(e)}
                                                            options={listTags}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={125}
                                                            isMulti
                                                        />
                                                        <br />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <br />
                                        </FormGroup>
                                    </Fragment>
                                    : ""
                            }
                        </FormGroup>
                }
            </ModalBody>
            <ModalFooter>
                <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                <LoadBtn className="pl-5 pr-5" color="primary" block={false} title="Guardar nuevo proyecto" text="Guardando..." handle={preSave} loading={loading.btn} icon="check" />
            </ModalFooter>
        </Modal>
    );
}

export default (ModalStartup);