import React, { useEffect, useState, Fragment } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Col, Container, Row, Modal, ModalBody, ModalFooter, Input, Button, FormGroup } from "reactstrap";
import { API } from '../../helpers/axios';
import Logo from "../../assets/images/conecTime/logo_conectime.png";
import LoadScreen from "../../components/Utils/LoadScreen";
import { getDate, spanishDate, swal_etm, capitalize, isMobile } from "../../helpers/etmmeetUtils";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import LoadBtn from "../../components/Utils/LoadBtn";
import AgendarAhora from "../../assets/images/etmmeet/qr_meeting/agendar_ahora.png";
import AgendarLuego from "../../assets/images/etmmeet/qr_meeting/agendar_luego.png";

registerLocale('es', es);

const QRmeeting = ( props ) => {

    const history = useHistory();

    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate());
    strikeDate.setDate(strikeDate.getDate() -1);
    
    const[modal, setModal]  = useState({
            option: false
        ,   now:    false
        ,   later:  false
    });
    const[selectedDate, setSelectedDate]        = useState(new Date()); 
    const[listHoursStart, setListHoursStart]    = useState([]);
    const[listUser, setListUser]                = useState([]);
    const[selectedUser, setSelectedUser]        = useState({ value: "", label: ""}); 
    const[startTime, setStartTime]              = useState("06:00");
    const[hideStart, setHideStart]              = useState(true);
    const[strikeClass, setStrikeClass]          = useState("etm_calendar_strike_text");

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    const [loading, setLoading]                     = useState(true);
    const [token, setToken]                         = useState("");
    const [msg, setMsg]                             = useState("");
    const [userId, setUserId]                       = useState(0);
    const [managerId, setManagerId]                 = useState(0);
    const [managerName, setManagerName]             = useState("");
    const [managerShortName, setManagerShortName]   = useState("");
    const [manager, setManager]                     = useState("");

    useEffect(() => {
        getUsersByToken();
        getHoursList();
        setStrikeClass("etm_calendar_strike_text");
    }, []);
    
    const getUsersByToken = async () => {
        if(props.location.pathname.length > 0){
            const data  = props.location.pathname.split('/');
            const token = data[2];
            if(token.length > 1){
                setToken(token);
                const res = await API.get('/getUsersByToken', { params: { token } });
                if (res.data.state) {
                    setUserId(res.data.data.USER_ID);
                    setManagerId(res.data.data.MANAGER_ID);
                    setManagerName(res.data.data.MANAGER_NOMBRE);
                    setManagerShortName(res.data.data.MANAGER_NOMBRE_CORTO);
                    setManager( 
                                <div className="etm_card etm_bg_blue">
                                    <Row>
                                        <Col md={12} sm={6}>
                                            <div className="text-center">
                                                <img src={ res.data.data.MANAGER_FOTO } alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg mt-3"/> 
                                                <h3 className="text-white mt-3 mb-0">{ capitalize(res.data.data.MANAGER_NOMBRE) }</h3>
                                                <h4 className="text-white mt-0">{ res.data.data.CARGO } EN { res.data.data.EMPRESA }</h4>
                                                <h5><span className="bg-white badge badge-pills pl-3 pr-3 etm_text_blue">{ res.data.data.PERFIL }</span></h5>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                    );
                    setModal({
                            option: true
                        ,   now:    false
                        ,   later:  false
                    });
                }else{
                    setMsg(res.data.msg);
                }
            }
            setLoading(false);
        }
    }

    const getHoursList = async (e) => {
        const res = await API.get('/getHoursList');
        if(res.data.state){
            let listStart = [];
            res.data.hours.map((e, i) => {
                listStart.push(<option key={ `start_${i}` } value={ e.hour }> { e.hour } </option>);
            });
            setListHoursStart(listStart);
        }
    }

    const handleStart = (e) => {
        let end = new Date("1970-01-01 " + e + ":00");
        end = moment(end).add(30, 'm').toDate();
        end = `${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`;
        setStartTime(e);
    }

    const changeDay = ( day ) => {
        if(getDate(day) < getDate(nextFreeDay)){
            swal_etm("Fecha inválida");
            return;
        }
        setHideStart(false);
    }

    const programLater = async () => {
        setLoading(true);

        const res = await API.post("/markQrMeeting", { token });
        if(res.data.state){
            swal_etm(`<b>${capitalize(managerShortName)}</b> podrá programar la reunión durante la próxima semana según su disponibilidad y serás notificado cuando la agende.`, "success");
            setTimeout(
                function() {
                    history.push('/thankMessage/qr_meeting_created');
                }
                .bind(this),
                3000
            );
        }else{
            swal_etm(res.data.msg);
        }
        setLoading(false);
    }
    
    const programNow = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        if(getDate(selectedDate) < getDate(nextFreeDay)){
            swal_etm("Fecha inválida");
            setLoading(false);
            return;
        }
        postProgramNow();
    }

    const postProgramNow = async () => {
        const res = await API.post("/makeMeetingByQr", {
                date:       getDate(selectedDate)
            ,   startTime
            ,   managerId
            ,   userId
            ,   token
        });
    
        if(res.data.state){
            swal_etm("Reunión creada correctamente!", "success");
            setTimeout(
                function() {
                    history.push('/thankMessage/qr_meeting_created');
                }
                .bind(this),
                3000
            );
        }else{
            swal_etm(res.data.msg);
        }
        setLoading(false);
    }

    // SELECT 2 STYLE 
    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "$fff" : 0,
            borderColor: state.isFocused ? "#fff" : "#fff", "&:hover": {
                borderColor: state.isFocused ? "#fff" : "#fff"
            }
        })
    };

    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Col sm={12} className="pt-5 text-center">
                            {
                                (loading) ?
                                    <LoadScreen text="Cargando datos..." />
                                :   <h1 className="animate__animated animate__fadeInUp">{ msg }</h1>
                            }
                        </Col>
                    </Container>
                </Row>
            </div>

            <Modal isOpen={ modal.option } size="xl" centered={ true }>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 className="etm_text_blue text-center mt-3 mb-5">Elige un tipo de agendamiento</h3>
                        </Col>
                        <Col lg={6} md={12} className="text-center pb-lg-3 pb-0">
                            <div>
                                <img src={ AgendarAhora } alt="" className="avatar-xl fix_img"/> 
                            </div>
                            <Button color="primary" size="lg" className="etm_btn mt-3 pt-1 pb-1 pl-5 pr-5" onClick={() => setModal({ option: false, now: true, later: false })}>AGENDAR AHORA</Button>
                            {
                                (isMobile()) ? <hr/> : ""
                            }
                        </Col>
                        <Col lg={6} md={12} className="text-center pb-lg-3 pb-0">
                            <div style={ (isMobile()) ? {} : {  borderLeft: "3px solid #d8d8d8" } }>
                                <img src={ AgendarLuego } alt="" className="avatar-xl fix_img"/> 
                            </div>
                            <Button color="primary" size="lg" className="etm_btn mt-3 pt-1 pb-1 pl-5 pr-5" onClick={() => (setModal({ option: false, now: false, later: true }), programLater()) }>AGENDAR LUEGO</Button>
                            <h4><small className="text-muted">(la otra persona dispondrá el día de la reunión)</small></h4>
                        </Col>
                    </Row>
                </Container>
            </Modal>

            <Modal isOpen={ modal.now } size="xl" centered={ true }>
                <Container>
                    <Row>
                        <Col xs={12} className="mt-2">
                            { manager }
                        </Col>
                        <Col xs={12}>
                            <h3 className="etm_text_blue text-center mt-3">Agenda tu reunión con { capitalize(managerName) }</h3>
                            <hr/>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="calendar text-center">
                                <h5 className="mt-3 mb-3"><i className="fas fa-calendar mr-2 etm_text_blue"></i>Selecciona el día de la reunión</h5>

                                {/* Controls */}
                                <div className="controls">
                                    <div>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={ prevMonth }><i className="fas fa-angle-double-left"></i></button>
                                        <span className="ml-1 mr-1">{` ${spanishDate(month.name)} ${year} `}</span>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={ nextMonth }><i className="fas fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                                {/* Calendar head with day names */}
                                <div className="calendar_head">
                                    {
                                        shortDayNames.map(day => (
                                            <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                {spanishDate(day)}
                                            </div>
                                        ))
                                    }
                                </div>

                                {/* Calendar */}
                                <div className="calendar_body">
                                {
                                    items.map(day => {
                                        const activeClass = !day.active ? "inactive" : "";
                                        let selectedClass = day.selected ? "selected" : "";
                                        const strike = (day.fullDate < strikeDate) ? strikeClass : "";
                                        return (
                                            <div
                                                className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                key={day.fullDate}
                                                onClick={() => {
                                                    selectDate(day.fullDate);
                                                    setSelectedDate(day.fullDate);
                                                    changeDay(day.fullDate);
                                                }}
                                            >
                                                {day.date}
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </Col>
                        
                        <Col lg={6} md={12}>
                            <h5 className="mt-lg-3 mt-5 mb-3"><i className="fas fa-clock mr-2 etm_text_blue"></i>Selecciona la hora de inicio de la reunión</h5>
                            <Row>
                                <Col lg={4} md={12}>
                                    <Input type="select" name="start" onChange={(e) => handleStart(e.target.value)} className="form-control etm_input_text" value={ startTime }>
                                        { listHoursStart }
                                    </Input>
                                </Col> 
                            </Row> 
                            <Col lg={12} className="text-left mt-5">
                                <h5><b className="etm_text_blue mr-1">*</b>Cada reunión dura 20 minutos.</h5>
                                <h5><b className="etm_text_blue mr-1">*</b>Todas las reuniones tienen zona horaria <b className="etm_text_blue">GMT-3</b> (Hora de Chile).</h5>
                            </Col>
                            <Col lg={12} className="text-right mt-5 mb-5">
                                <LoadBtn handle={ programNow } loading={ loading } title="Crear reunión!" icon="plus" className="pt-1 pb-1 pl-5 pr-5 btn-lg"/>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </Fragment >
    );
}

export default withRouter(QRmeeting);