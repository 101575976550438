import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Container, FormGroup, Input, Label } from "reactstrap";
import ReactInputVerificationCode from 'react-input-verification-code';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { swal_etm, getDate, cleanLinkedin, selectCustomStyle } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";

const Step2 = (props) => {
    const date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia = getDate(primerDia, true).replace(/\-/g, '/');
    let primerDiaFormat = getDate(primerDia).replace(/\-/g, '/');

    const default_chile = { value: 81, label: "Chile" };
    const asterisco = <b className="ml-1 h5 etm_text_blue">*</b>;

    const [section1, setSection1] = useState(false);
    const [section2, setSection2] = useState(false);
    const [section3, setSection3] = useState(false);
    const [section4, setSection4] = useState(false);
    const [section5, setSection5] = useState(false);

    const [gender, setGender] = useState('MASCULINO');
    const [age, setAge] = useState(primerDiaFormat);
    const [showMessageAge, setShowMessageAge] = useState(false);
    const [country, setCountry] = useState(default_chile); //  POR DEFECTO CHILE
    const [listCountries, setListCountries] = useState([]);
    const [actualCountry, setActualCountry] = useState(default_chile);    //  POR DEFECTO CHILE
    const [actualCity, setActualCity] = useState(null);
    const [listCities, setListCities] = useState([]);
    const [showActualCity, setShowActualCity] = useState(false);
    const [linkedin, setLinkedin] = useState('');
    const [phone, setPhone] = useState('');
    const [validatePhoneCode, setValidatePhoneCode] = useState({ value: '', validate: false });
    const [language, setLanguage] = useState(null);
    const [listLanguages, setListLanguages] = useState([]);
    const [loading, setLoading] = useState({ personal: false, whatsapp: false, sms: false, number: false });

    useEffect(() => {
        getCountries();
        getLanguages();
    }, []);

    useEffect(() => {
        setActualCountry(country);
    }, [country]);

    useEffect(() => {
        getCities();
    }, [actualCountry]);

    useEffect(() => {
        delay(1, 1000);
        delay(2, 3000);
        delay(3, 5000);
    }, []);

    useEffect(() => {
        if (getDate(age) !== getDate(primerDiaFormat)) {
            if (validateAge()) {
                setShowMessageAge(false);
            } else {
                setShowMessageAge(true);
            }
        }
    }, [age]);

    const validateAge = () => {
        const today = new Date()
        const birth = new Date(age)
        const edad = today.getFullYear() - birth.getFullYear()
        if (edad < 18) {
            return false;
        } else {
            return true;
        }
    }

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        let list = [];
        if (res.data.state) {
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS, key: i });
            });
        }
        setListCountries(list);
    }

    const getCities = async () => {
        const res = await API.get("/general/getCities", { params: { country_id: actualCountry.value } });
        let list = [];
        if (res.data.state) {
            res.data.cities.map((e, i) => {
                list.push({ value: e.ID, label: e.CIUDAD, key: i });
            });
            setShowActualCity(true);
        } else {
            setShowActualCity(false);
        }
        setListCities(list);
    }

    const getLanguages = async () => {
        const res = await API.get("/general/getLanguages");
        let list = [];
        if (res.data.state) {
            res.data.languages.map((e, i) => {
                list.push({ value: e.ID, label: e.IDIOMA, key: i });
            });
        }
        setListLanguages(list);
    }

    const delay = (step, time) => {
        switch (step) {
            case 1:
                setTimeout(function () { setSection1(true) }.bind(this), time);
                break;
            case 2:
                setTimeout(function () { setSection2(true) }.bind(this), time);
                break;
            case 3:
                setTimeout(function () { setSection3(true) }.bind(this), time);
                break;
        }
    }

    const changeAge = (event, picker) => {
        setAge(getDate(picker.startDate));
    }

    const enviarCodigo = async (type) => {
        if (phone.length < 5) {
            swal_etm(`Debes ingresar un número válido`);
            return false;
        }

        if (type === "WHATSAPP") {
            setLoading({ personal: false, whatsapp: true, sms: false, number: false });
        } else {
            setLoading({ personal: false, whatsapp: false, sms: true, number: false });
        }

        const res = await API.post("/register/sendPhoneCode", {
            phone,
            token: props.getToken(),
            type,
            name: props.data.name
        });

        if (res.data.state) {
            swal_etm(`Código enviado, revisa tu celular.`, `success`);
        } else {
            swal_etm(`Ocurrió un problema al enviar el código, Inténtalo otro vez`);
        }
        setLoading({ personal: false, whatsapp: false, sms: false, number: false });
    }

    const validarCodigo = async (valor) => {
        const code = valor.replace(/\·/g, "");
        setValidatePhoneCode({ value: code, validate: false });

        if (code.length >= 6 && code.indexOf("·") === -1) {
            const res = await API.post("/register/validatePhoneCode", { token: props.getToken(), code });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
                setValidatePhoneCode({ value: code, validate: true });
            } else {
                swal_etm(res.data.msg);
                setValidatePhoneCode({ value: code, validate: false });
            }
        }
    }

    const preSavePersonalData = (e) => {
        // e.preventDefault();
        setLoading({ personal: true, whatsapp: false, sms: false, number: false });

        if (!validateAge()) {
            swal_etm(`Debes ser mayor de <b class="etm_text_blue">18 años</b>.`);
            setLoading({ personal: false, whatsapp: false, sms: false, number: false });
            return;
        }

        if (country !== null && country.value === 0) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">país de origen</b>.`);
            setLoading({ personal: false, whatsapp: false, sms: false, number: false });
            return;
        }

        if (actualCountry !== null && actualCountry.value === 0) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">país de residencia</b>.`);
            setLoading({ personal: false, whatsapp: false, sms: false, number: false });
            return;
        }

        if (actualCountry !== null && actualCountry.value === default_chile.value && actualCity !== null && actualCity.value === 0) {
            swal_etm(`Debes seleccionar tu <b class="etm_text_blue">ciudad de residencia</b>.`);
            setLoading({ personal: false, whatsapp: false, sms: false, number: false });
            return;
        }

        let array_languaje = [];
        if (language !== null) {
            language.map((e, i) => {
                array_languaje.push(e.value)
            });
        }

        postSavePersonalData(array_languaje);
    }

    const postSavePersonalData = async (array_languaje) => {
        const res = await API.put("/register/savePersonalData", {
            token: props.getToken()
            , gender
            , age
            , country: (country !== null) ? country.value : 0
            , actualCountry: (actualCountry !== null) ? actualCountry.value : 0
            , actualCity: (actualCity !== null) ? actualCity.value : 0
            , language: array_languaje
            , linkedin
        });

        setLoading({ personal: false, whatsapp: false, sms: false, number: false });
        setSection4(true);
    }

    const preSavePhone = async () => {
        setLoading({ personal: false, whatsapp: false, sms: false, number: true });
        const res = await API.put("/register/savePhoneNumber", {
            token: props.getToken()
            , phone
        });

        if (res.data.state) {
            setTimeout(function () {
                props.saveStep(2);
            }, 2000);
        } else {
            swal_etm(res.data.msg);
        }
    }

    return (
        <Fragment>
            <Row>
                <Col className="bg_onboarding_left" xl={5} lg={6} md={12}>
                    <div className="ml-5 mr-5 mb-5 mt-4">
                        <Container>
                            <Label className="h2"><p className="animate__animated animate__fadeIn" hidden={!section1}> <b className="etm_text_blue">Datos Personales</b> </p></Label>
                            <Label className="h5 ">
                                <p className="animate__animated animate__fadeIn" hidden={!section1}>Completemos tus datos personales. Esto sólo lo usaremos para conocerte y no expondremos tu información, ni datos de contacto en la plataforma</p>
                            </Label>
                            <Row hidden={!section2} className="animate__animated animate__fadeIn">
                                <Col md={12} >
                                    <FormGroup>
                                        <Label className="h5">Selecciona tu género {asterisco}</Label>
                                        <select onChange={(e) => setGender(e.target.value)} name="gender" className="form-control etm_register_input" required value={gender}>
                                            <option value="MASCULINO">Masculino</option>
                                            <option value="FEMENINO">Femenino</option>
                                            <option value="INDEFINIDO">Prefiero no indicarlo</option>
                                            <option value="OTRO">Otro</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="h5">Indicanos tu fecha de nacimiento {asterisco}</Label>
                                        <DateRangePicker
                                            onApply={changeAge}
                                            initialSettings={{
                                                singleDatePicker: true,
                                                autoApply: true,
                                                showDropdowns: true,
                                                startDate: primerDia,
                                                locale: {
                                                    format: "DD-MM-YYYY",
                                                    daysOfWeek: [
                                                        "Dom",
                                                        "Lun",
                                                        "Mar",
                                                        "Mie",
                                                        "Jue",
                                                        "Vie",
                                                        "Sab"
                                                    ],
                                                    monthNames: [
                                                        "Enero",
                                                        "Febrero",
                                                        "Marzo",
                                                        "Abril",
                                                        "Mayo",
                                                        "Junio",
                                                        "Julio",
                                                        "Agosto",
                                                        "Septiembre",
                                                        "Octubre",
                                                        "Noviembre",
                                                        "Diciembre"
                                                    ],
                                                }
                                            }} >
                                            <input onChange={(e) => setAge(e)} name="birthdate" className="form-control etm_register_input" type="text" placeholder="YYYY-MM-DD / YYYY-MM-DD" required readOnly />
                                        </DateRangePicker>
                                        <Label className="text-danger ml-2 h5 animate__animated animate__fadeIn" hidden={!showMessageAge}>Debes ser mayor de 18 años</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="h5">Selecciona tu país de origen {asterisco}</Label>
                                        <Select
                                            placeholder={"Selecciona un país"}
                                            value={country}
                                            onChange={setCountry}
                                            options={listCountries}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={200}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="h5">País residencia actual {asterisco}</Label>
                                        <Select
                                            placeholder={"Selecciona un país"}
                                            value={actualCountry}
                                            onChange={setActualCountry}
                                            options={listCountries}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={200}
                                        />
                                    </FormGroup>
                                    <FormGroup hidden={!showActualCity}>
                                        <Label className="h5">Selecciona tu ciudad residencia actual {asterisco}</Label>
                                        <Select
                                            placeholder={"Selecciona una ciudad"}
                                            value={actualCity}
                                            onChange={setActualCity}
                                            options={listCities}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={200}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="h5">¿ Hablas otro idioma además de Español ? <small>( Puedes elegir más de 1 )</small></Label>
                                        <Select
                                            placeholder={"Selecciona idiomas"}
                                            value={language}
                                            onChange={setLanguage}
                                            options={listLanguages}
                                            className="etm_input_select1"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={130}
                                            isMulti
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="h5">¿ Tienes LinkedIn ? Ingrésalo aquí</Label>
                                        <Input onChange={(e) => setLinkedin(cleanLinkedin(e.target.value))} name="linkedin" className="form-control etm_register_input" type="url" required placeholder="Ingresa el link de tu Linkedin" />
                                        <Label hidden={(linkedin.length > 5) ? false : true} className="text-info mt-2 h5">Valida tu LinkedIn en el siguiente link: <a href={linkedin} target="_blank"><u><b className="text-info">VER LINKEDIN</b></u></a></Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row hidden={!section3} className="animate__animated animate__fadeIn mt-5">
                                <Col md={12} >
                                    <FormGroup>
                                        <a href="#viewer_section">
                                            <LoadBtn className="btn-lg" handle={(e) => preSavePersonalData(e)} loading={loading.personal} title="¡ Guardar datos personales y continuar !" text="Continuar..." href="#viewer_section" />
                                        </a>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
                <Col className="bg_onboarding_right" xl={7} lg={6} md={12} id="viewer_section">
                    <div className="ml-xl-5 mr-xl-5 ml-md-3 mr-md-3 ml-2 mr-2">
                        <Card className="ml-xl-5 mr-xl-5 ml-sm-2 mr-sm-2 mt-5 animate__animated animate__fadeIn" hidden={!section4}>
                            <CardBody className="m-md-3 m-2">
                                <Row>
                                    <Col md={12} className="mb-0">
                                        <Label className="h2">
                                            <p className="animate__animated animate__fadeIn"><i className="fas fa-arrow-right etm_text_blue mr-2"></i>Validemos tu teléfono</p>
                                        </Label>
                                        <FormGroup>
                                            <Label className="h5">
                                                <p className="animate__animated animate__fadeIn mb-0">Ingresa tu número de teléfono móvil y elige entre <b className="text-success">WhatsApp</b> o <b className="etm_text_blue">SMS</b></p>
                                                <p className="animate__animated animate__fadeIn mt-0">A continuación escribe el código de <b>6 dígitos</b> que te enviamos.</p>
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <PhoneInput
                                            country={'cl'}
                                            value={phone}
                                            onChange={phone => setPhone(phone)}
                                            inputClass="form-control etm_input_phone mt-0"
                                            buttonClass="etm_input_phone_flag"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xl={6} lg={12}>
                                        <FormGroup>
                                            <LoadBtn className="btn-lg" color="success" familyIcon="fab fa" icon="whatsapp" handle={() => enviarCodigo("WHATSAPP")} loading={loading.whatsapp} title="Enviar por WhatsApp" text="Enviando..." />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6} lg={12}>
                                        <FormGroup>
                                            <LoadBtn className="btn-lg" icon="sms" handle={() => enviarCodigo("SMS")} loading={loading.sms} title="Enviar por SMS" text="Enviando..." />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup className="text-center">
                                            <div className="etm_onboarding_verification_phone_input mt-3">
                                                <ReactInputVerificationCode
                                                    onChange={(e) => validarCodigo(e)}
                                                    value={validatePhoneCode.value}
                                                    length={6}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} hidden={!validatePhoneCode.validate}>
                                        <LoadBtn className="btn-lg" handle={(e) => preSavePhone(e)} loading={loading.number} title="¡ Guardar y continuar !" text="Continuar..." />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Step2);