import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { API } from '../../helpers/axios';
import Switch from "react-switch";
import { swal_etm, capitalize } from "../../helpers/etmmeetUtils";

 const ModalShareData = ( props ) => {

    const [showModal, setShowModal] = useState(false);
    const [addPhone, setAddPhone]   = useState(false);

    useEffect(() => { 
        setShowModal(props.showModal);
    }, [props.showModal]);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const shareData = async () => {
        handleModal();
        // se comparten los datos
        const res = await API.post('/general/share_data', {
            user_id: props.user_id,
            other_participant_id: props.share_user_id,
            add_phone: addPhone
        });
        if (res.data.state) {
            if (props.datos.page === 'request') {
                swal_etm(`<h5>Enviamos tus datos a ${props.datos.OtherUsaer} por correo electronico</h5>`, `success`);
            } else {
                swal_etm(`<h5>Le enviaremos tus datos al participante a través de correo electrónico.</h5>`, `success`);
            }
        } else {
            swal_etm(`<h5>Ha ocurrido un problema. Favor inténtalo nuevamente.</h5>`);
        }
    }

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }} > {" "}No</div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }} > {" "}Si</div>
        );
    };

    return (
        <Fragment>
            <Modal isOpen={ showModal } size="md" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="p-4 text-center">
                                <h5 className="font-16 m-b-15">Compartiremos tu <b className="etm_text_blue">correo electrónico</b> con {(props.datos.page === 'request')?`${capitalize(props.datos.OtherUsaer)}`:`el otro participante`}</h5>
                            </div>
                            <div style={{ zIndex: 100 }} className="p-4 text-center">
                                <h5 className="font-16 m-b-15">¿Deseas además compartir tu <b className="etm_text_blue">número telefónico</b>?</h5>
                                <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="mr-1"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => setAddPhone( !addPhone )}
                                    checked={ addPhone }
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleModal } size="lg" className="etm_btn"><i className="fas fa-times"></i> Cancelar</Button>{' '}
                    <Button color="primary" onClick={ shareData } size="lg" className="etm_btn"><i className="fas fa-paper-plane"></i> Compartir Datos</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalShareData);