import React, { Fragment } from 'react';
import { Nav, Container, Row, Col } from "reactstrap";
import Logo from "../../assets/images/etmmeet/landing/logo_blanco.png";
import { Link } from 'react-router-dom';

const Navmenu = (props) => {
    return (
        <Fragment>
            <header>
                <Nav className="navbar navbar-expand-lg fixed-top navbar-dark">
                    <Container>
                        <Col sm={6} xs={2}>
                            <div className="text-left">
                                <Link to={`/landing/${props.reference}`}>
                                    <img src={ Logo } className="navbar_logo_etm" height="auto" alt="EmprendeTuMente" loading="lazy"/>
                                </Link>
                            </div>
                        </Col>
                        <Col sm={6} xs={10} className="text-right">
                            <Row> 
                                <Col xl={11} lg={10} md={9} sm={8} xs={9} className="my-auto">
                                    <Link to={`/login/${props.reference}`} target="_blank"><button className="btn btn-primary etm_btn pt-2 pb-2 pl-sm-3 pl-2 pr-sm-3 pr-2"><i className="far fa-user mr-sm-2 mr-1"></i>Ingresa ahora</button></Link>
                                </Col>
                                <Col xl={1} lg={2} md={3} sm={4} xs={3} className="my-auto">
                                    <div className="nav-link pointer" onClick={() => props.setMenu( (props.menu === null) ? true : !props.menu)}><i className="navbar_bar_icon fas fa-bars fa-2x mt-2"></i></div>
                                </Col>
                            </Row> 
                        </Col>
                    </Container>
                </Nav>
            </header>
        </Fragment>
    );
}
export default (Navmenu);