import React, { useEffect, useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import { API } from '../../helpers/axios';

//Import Breadcrumb
import Logo from "../../assets/images/conecTime/logo_conectime.png";
import LoadScreen from "../../components/Utils/LoadScreen";
import ModalParticipant from "../../components/Meet/ModalParticipant";

const QRProfile = ( props ) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        getUrlUser();
    }, []);
    
    const getUrlUser = async () => {
        if(props.location.pathname.length > 0){
            const data  = props.location.pathname.split('/');
            const hash  = data[2];
            const res   = await API.get('/getUserIdByHash', { params: { hash } });
            if (res.data.state) {
                setUserId(res.data.data);
                setLoading(false);
            }
        }
    }

    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                    {
                        (loading) ?
                            <Col sm={12} className="pt-5">
                                <LoadScreen text="Cargando datos..." />
                            </Col>
                        :   <ModalParticipant showModal={ true } userId={ userId } />
                    }
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(QRProfile);