import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label, Container } from "reactstrap";
import { API } from '../../../helpers/axios';
import { capitalize, cleanFacebook, cleanInstagram, cleanTwitter, cleanLinkedin, validateRol, swal_etm, getDate, spanishDate, cleanYoutube, selectCustomStyle, getLocalStorage } from '../../../helpers/etmmeetUtils';
import LoadBtn from "../../Utils/LoadBtn";
import LoadScreen from "../../Utils/LoadScreen";

import logo_inversionista from "../../../assets/images/conecTime/design/Perfil-inversionista.png";
import logo_mentor from "../../../assets/images/conecTime/design/Perfil-mentor.png";
import logo_gerente from "../../../assets/images/conecTime/design/Perfil-gerente.png";

import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import Select from 'react-select';

const ModalSolicitarExperto = (props) => {

    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate());
    strikeDate.setDate(strikeDate.getDate() - 1);

    const [loading, setLoading] = useState({ data: true, btn: false });
    const [showOptions, setShowOptions] = useState(false);
    const [availableDays, setAvailableDays] = useState([]);
    const [availableHours, setAvailableHours] = useState([]);
    const [listObjectives, setListObjectives] = useState([]);
    const [listStartups, setListStartups] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [selectedStartup, setSelectedStartup] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [validateButton, setValidateButton] = useState(false);

    const [motivo, setMotivo] = useState("");

    const [dataUser, setDataUser] = useState({
        short_name: ""
        , name: ""
        , company: ""
        , company_img: ""
        , profile: ""
        , profile_img: ""
        , photo: ""
        , description: ""
        , duration: ""
        , facebook: ""
        , instagram: ""
        , linkedin: ""
        , twitter: ""
        , youtube: ""
    });

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    useEffect(() => {
        validarButtonSolicitar();
    }, [selectedDate, selectedHour, selectedObjective, selectedStartup, selectedDescription]);

    useEffect(() => {
        getExpertData();
    }, [props.expertToken]);

    useEffect(() => {
        setMotivo("");
        getStartups();
    }, []);

    const validarButtonSolicitar = async () => {
        if (getDate(selectedDate) >= getDate(nextFreeDay) && selectedHour !== null && selectedHour > 0 && selectedObjective !== null && selectedObjective.value > 0 && selectedDescription !== null && selectedDescription.length >= 10) {
            setValidateButton(true);
        } else {
            setValidateButton(false);
        }
    }

    const getStartups = async () => {
        if (validateRol(["EMPRENDEDOR"])) {
            const res = await API.get('/search/getStartupsByUserToken', { params: { token: getLocalStorage().token } });
            let list = [];
            if (res.data.state) {
                res.data.startups.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
            }
            setListStartups(list);
        }
    }

    const getExpertData = async () => {
        if (props.expertToken !== null) {
            setLoading({ data: true, btn: false });
            const res = await API.get('/search/getExpertData', { params: { token: props.expertToken, meetingType: props.meetingType } });
            if (res.data.state) {

                let profile = "";
                let profile_img = "";

                if (res.data.data.user.perfil === "INVERSIONISTA") {
                    profile = "Inversionista";
                    profile_img = logo_inversionista;
                } else if (res.data.data.user.perfil === "MENTOR") {
                    profile = "Mentor";
                    profile_img = logo_mentor;
                } else {
                    profile = "Gerente-Ejecutivo";
                    profile_img = logo_gerente;
                }

                setDataUser({
                    short_name: res.data.data.user.nombre_corto
                    , name: res.data.data.user.nombre
                    , token: res.data.data.user.token
                    , company: res.data.data.user.empresa.nombre
                    , company_img: res.data.data.user.empresa.foto
                    , profile
                    , profile_img
                    , photo: res.data.data.user.foto
                    , description: res.data.data.user.descripcion
                    , duration: res.data.data.user.duracion
                    , facebook: res.data.data.user.rrss.facebook
                    , instagram: res.data.data.user.rrss.instagram
                    , linkedin: res.data.data.user.rrss.linkedin
                    , twitter: res.data.data.user.rrss.twitter
                    , youtube: res.data.data.user.rrss.youtube
                });

                setAvailableDays((res.data.data.dates !== null) ? res.data.data.dates : []);

                let list_obj = [];
                if (res.data.data.objectives.length !== null && res.data.data.objectives.length > 0) {
                    res.data.data.objectives.map((e, i) => {
                        list_obj.push({ value: e.ID, label: e.DESCRIPCION });
                    });
                }
                setListObjectives(list_obj);

                // let list_start = [];
                // if (validateRol(["EMPRENDEDOR"])) {
                //     if (res.data.data.startups.length !== null && res.data.data.startups.length > 0) {
                //         res.data.data.startups.map((e, i) => {
                //             list_start.push({ value: e.ID, label: e.NOMBRE });
                //         });
                //     }
                // }
                // setListStartups(list_start);
            }

            setTimeout(function () {
                setLoading({ data: false, btn: false });
            }, 1000);
        }
    }

    const changeDay = (day) => {
        if (availableDays.includes(getDate(day))) {
            getHoursByDay(getDate(day));
        } else {
            setShowOptions(false);
        }
    }

    const changeHour = (hour, element_id) => {
        setSelectedHour(hour);
        const elements = document.getElementsByClassName("hours_list");
        if (elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove("active");
            }
        }
        document.getElementById(element_id).classList.add("active");
    }

    const getHoursByDay = async (day) => {
        setSelectedDate(day);
        const res = await API.get('/search/getHourByDay', { params: { day, token: props.expertToken } });
        if (res.data.state) {
            let list = [];
            res.data.data.map((e, i) => {
                list.push(
                    <Col xl={6} lg={12} md={12} className="text-center" key={i}>
                        <div className="ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border hours_list" id={`hour_${e.ID}`} onClick={() => changeHour(e.ID, `hour_${e.ID}`)}>
                            <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>{e.HORA}</Label>
                        </div>
                    </Col>
                );
            });
            setAvailableHours(list);
        }
        setShowOptions(true);
    };

    const stepNumber = (e, title) => {
        return <Label className="h5"><b className="etm_text_blue mr-2">{e}. </b>{title}</Label>
    }

    const preMakeMeeting = () => {
        setLoading({ data: false, btn: true });

        if (selectedHour === null || selectedHour < 1) {
            swal_etm('Debes seleccionar un horario válido');
            setLoading({ data: false, btn: false });
            return;
        }

        if (selectedObjective === null || selectedObjective.value < 1) {
            swal_etm('Debes seleccionar un objetivo válido');
            setLoading({ data: false, btn: false });
            return;
        }

        if (validateRol(["EMPRENDEDOR"])) {
            if (selectedStartup === null || selectedStartup.value < 1) {
                swal_etm('Debes seleccionar un proyecto de la lista');
                setLoading({ data: false, btn: false });
                return;
            }
        }

        if (selectedDescription === null || selectedDescription < 10) {
            swal_etm('Descríbenos el objetivo de esta reunión en al menos 10 caracteres');
            setLoading({ data: false, btn: false });
            return;
        }

        postMakeMeeting();
    }

    const postMakeMeeting = async () => {
        const res = await API.post('/meeting/expert/makeMeeting', {
            token: getLocalStorage().token
            , meet_id: selectedHour
            , objective: selectedObjective.value
            , startup: (validateRol(["EMPRENDEDOR"])) ? selectedStartup.value : 0
            , description: selectedDescription
            , meetingType: (props.meetingType === "COMERCIAL_EXPERTO") ? "COMERCIAL" : props.meetingType
        });

        if (res.data.state) {
            swal_etm(res.data.msg, 'success');
        } else {
            swal_etm(res.data.msg);
        }
        props.setUrlUserType(null);
        props.setUrlUserToken(null);
        props.setExpertToken(null);
        setLoading({ data: false, btn: false })
        props.setModal(false);
    }

    const closeModal = () => {
        props.setModal(false);
        props.setUrlUserType(null);
        props.setUrlUserToken(null);
        props.setExpertToken(null);
        setMotivo("");
        setLoading({ data: false, btn: false })
    }

    const solicitar = () => {
        setLoading({ data: false, btn: true });
        if (motivo.length < 10) {
            swal_etm("El motivo que ingreses debe contar al menos con 10 caracteres");
            setLoading({ data: false, btn: false });
            return false;
        }
        postSolicitar();
    };

    const postSolicitar = async () => {
        const res = await API.post('/search/makeRequestGiveTime', { token: getLocalStorage().token, manager_token: dataUser.token, motivo });
        if (res.data.state) {
            swal_etm(`Te notificaremos por correo apenas <b>${capitalize(dataUser.shortname)}</b> done tiempo.`, 'success', 'Solicitud realizada correctamente!');
            setLoading({ data: false, btn: false });
            closeModal()
        } else {
            swal_etm(`Ya solicitaste tiempo a este usuario en menos de <b>7 días</b>.<br>Podrás volver a solicitarle la próxima semana.`);
            setLoading({ data: false, btn: false });
            closeModal()
        }
    };

    return (
        <Fragment>
            <Modal isOpen={props.modal} size={'xl'} scrollable={true} >
                <ModalHeader className="etm_modal_header">
                    <Label
                        className="h4 ml-3 text-white"
                    >
                        {
                            (availableDays.length > 0) ?
                                `Selecciona el día y hora para reunirte con `
                                :
                                `Solicitando que done tiempo a `
                        }
                        <b>{capitalize(dataUser.short_name)} experto</b>
                    </Label>
                    <Label className="etm_modal_header_close_solicitud_xl pointer" onClick={closeModal}><i className="far fa-times-circle fa-lg"></i></Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    {
                        (loading.data) ?
                            <LoadScreen text="Buscando horarios disponibles..." className="mt-5 mb-5" />
                            : <Row>
                                <Col lg={4} md={12} sm={12} className="text-lg-left text-center animate__animated animate__fadeIn">
                                    <Container>
                                        <div>
                                            <div className="mb-2 mt-2">
                                                <img src={dataUser.photo} className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg" alt="" />
                                            </div>
                                            <Label className="h4 text-truncate etm_text_blue mb-0">{capitalize(dataUser.name)}</Label>
                                            <br />
                                            <Label className="h5 text-truncate mt-0">{dataUser.company}</Label>
                                            <div>
                                                <img src={dataUser.company_img} className="company_home fix_img" alt="" />
                                            </div>
                                            <Row className="mt-lg-5 mt-4">
                                                <Col lg={2} md={3} xs={5} className="text-right">
                                                    <Label className="h5 text-truncate"><i className="fas fa-clock fa-2x etm_text_blue"></i></Label>
                                                </Col>
                                                <Col lg={10} md={3} xs={7} className="text-left">
                                                    <Label className="h5 mt-1 text-truncate">{dataUser.duration}</Label>
                                                </Col>
                                                <Col lg={2} md={2} xs={5} className="text-right">
                                                    <img src={dataUser.profile_img} className="avatar-xs rounded-circle fix_img shadow-lg" alt="" />
                                                </Col>
                                                <Col lg={10} md={4} xs={7} className="text-left">
                                                    <Label className="h5 mt-1 text-truncate font-weight-medium">{dataUser.profile}</Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <ul className="list-inline mt-lg-4 mt-2">
                                            {
                                                (dataUser.facebook.length > 0) ?
                                                    <li className="list-inline-item mr-2">
                                                        <Label className="h4">
                                                            <a href={cleanFacebook(dataUser.facebook)} target="_blank">
                                                                <i className="fab fa-facebook-square facebook"></i>
                                                            </a>
                                                        </Label>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                (dataUser.instagram.length > 0) ?
                                                    <li className="list-inline-item mr-2">
                                                        <Label className="h4">
                                                            <a href={cleanInstagram(dataUser.instagram)} target="_blank">
                                                                <i className="fab fa-instagram instagram"></i>
                                                            </a>
                                                        </Label>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                (dataUser.linkedin.length > 0) ?
                                                    <li className="list-inline-item mr-2">
                                                        <Label className="h4">
                                                            <a href={cleanLinkedin(dataUser.linkedin)} target="_blank">
                                                                <i className="fab fa-linkedin linkedin"></i>
                                                            </a>
                                                        </Label>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                (dataUser.twitter.length > 0) ?
                                                    <li className="list-inline-item mr-2">
                                                        <Label className="h4">
                                                            <a href={cleanTwitter(dataUser.twitter)} target="_blank">
                                                                <i className="fab fa-twitter-square twitter"></i>
                                                            </a>
                                                        </Label>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                (dataUser.youtube.length > 0) ?
                                                    <li className="list-inline-item mr-2">
                                                        <Label className="h4">
                                                            <a href={cleanYoutube(dataUser.youtube)} target="_blank">
                                                                <i className="fab fa-youtube youtube"></i>
                                                            </a>
                                                        </Label>
                                                    </li>
                                                    : ""
                                            }
                                        </ul>
                                        {
                                            (dataUser.description.length > 0) ?
                                                <Label className="h5 mt-3"><i className="fas fa-arrow-right etm_text_blue mr-2"></i>{dataUser.description}</Label>
                                                : ""
                                        }
                                    </Container>
                                </Col>
                                {
                                    (availableDays.length > 0) ?
                                        <>
                                            <Col lg={4} md={6} sm={12} className="etm_modal_solicitar_border_left mt-lg-0 mt-4">
                                                <Row className="animate__animated animate__fadeIn animate__delay-1s">
                                                    <Col xs="12" className="text-left mt-2">
                                                        {stepNumber(1, "Selecciona uno de los días disponibles")}
                                                    </Col>
                                                    <Col xs="12" className="mt-md-5 mt-3">
                                                        <div className="text-center">
                                                            <Label className="h5 mb-3">Tiene <span className="etm_text_blue">{availableDays.length}</span> días disponibles</Label>
                                                        </div>
                                                        <div className="calendar text-center">

                                                            {/* Controls */}
                                                            <div className="controls">
                                                                <div>
                                                                    <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                                                    <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                                                    <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                                                </div>
                                                            </div>

                                                            {/* Calendar head with day names */}
                                                            <div className="calendar_head">
                                                                {
                                                                    shortDayNames.map(day => (
                                                                        <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                                            {spanishDate(day)}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                            {/* Calendar */}
                                                            <div className="calendar_body">
                                                                {
                                                                    items.map(day => {
                                                                        const activeClass = !day.active ? "inactive" : "";  //  dias fuera del mes
                                                                        let selectedClass = day.selected ? "selected" : ""; //  dia seleccionado o por defecto
                                                                        const strike = (day.fullDate < strikeDate) ? "inactive" : "inactive";   //  dias anteriores al primero disponible
                                                                        const available = (availableDays.includes(getDate(day.fullDate))) ? "available" : "";   //  primer dia disponible
                                                                        return (
                                                                            <div
                                                                                className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike} ${available}`}
                                                                                key={day.fullDate}
                                                                                onClick={() => {
                                                                                    selectDate(day.fullDate);
                                                                                    // setSelectedDate(day.fullDate);
                                                                                    changeDay(day.fullDate);
                                                                                }}
                                                                            >
                                                                                {day.date}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} className="mt-md-5 mt-3 text-center">
                                                        <Label className="h6 text-muted"><i className="fas fa-globe-americas mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className="etm_modal_solicitar_border_left mt-lg-0 mt-4">
                                                <div hidden={!showOptions} className="animate__animated animate__fadeIn">
                                                    <Container>
                                                        <Row>
                                                            <Col xs={12} className="text-left mt-2">
                                                                {stepNumber(2, "Selecciona un horario disponible")}
                                                            </Col>
                                                            {availableHours}
                                                        </Row>
                                                        <div className="">
                                                            <FormGroup className="mt-5">
                                                                {stepNumber(3, "¿ Cuál es el objetivo de esta reunión ?")}
                                                                <Select
                                                                    placeholder="Indícanos el objetivo de esta reunión"
                                                                    value={selectedObjective}
                                                                    onChange={setSelectedObjective}
                                                                    options={listObjectives}
                                                                    className="etm_input_select"
                                                                    styles={selectCustomStyle()}
                                                                />
                                                            </FormGroup>
                                                            {
                                                                (validateRol(["EMPRENDEDOR"])) ?
                                                                    <FormGroup className="mt-5">
                                                                        {stepNumber(4, "¿ Qué proyecto vas a presentar ?")}
                                                                        <Select
                                                                            placeholder="Selecciona un proyecto"
                                                                            value={selectedStartup}
                                                                            onChange={setSelectedStartup}
                                                                            options={listStartups}
                                                                            className="etm_input_select"
                                                                            styles={selectCustomStyle()}
                                                                        />
                                                                    </FormGroup>
                                                                    : ""
                                                            }

                                                            <FormGroup className="mt-5">
                                                                {
                                                                    (validateRol(["EMPRENDEDOR"])) ?
                                                                        stepNumber(5, "Describe con tus palabras lo que buscas de esta reunión")
                                                                        : stepNumber(4, "Describe con tus palabras lo que buscas de esta reunión")
                                                                }
                                                                <Input onChange={(e) => setSelectedDescription(e.target.value)} type="textarea" name="review" rows="5" placeholder="Esta descripción será enviada a la contraparte" maxLength="400" className="etm_textarea mt-2"></Input>
                                                            </FormGroup>
                                                        </div>
                                                    </Container>
                                                </div>
                                            </Col>
                                        </> :
                                        <>
                                            <Row>
                                                {/* <Col>
                                                    <h5>SOLICITA QUE DONE TIEMPO</h5>
                                                </Col>
                                                <Col className="text-right">
                                                    <h5 className="etm_modal_close pointer text-muted" onClick={props.toggleModal}>Cerrar <i className="far fa-times-circle fa-1x"></i></h5>
                                                </Col> */}
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup className="mt-4">
                                                        <h5 className="mb-2 text-muted">
                                                            Cuéntanos, ¿cuál es el motivo por el cuál quieres reunirte con <b className="etm_text_blue">{capitalize(dataUser.short_name)}</b>?
                                                        </h5>
                                                        <Input
                                                            type="textarea"
                                                            name="motivo"
                                                            rows="5"
                                                            onChange={(e) => setMotivo(e.target.value)}
                                                            placeholder={`Describe por qué te gustaría poder juntarte con ${capitalize(dataUser.short_name)}`}
                                                            maxLength="300"
                                                            className="etm_textarea"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="mt-3">
                                                        <label className="text-muted">
                                                            <b className="etm_text_blue mr-2">*</b>
                                                            Podrás solicitarle tiempo a {capitalize(dataUser.short_name)} 1 vez por semana y serás notificado por
                                                            correo la próxima vez que done tiempo.
                                                        </label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                }

                            </Row>
                    }
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={closeModal}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                    {
                        (availableDays.length > 0) ?
                            <LoadBtn
                                className={`pl-5 pr-5 ${(validateButton) ? `btn-lg` : ``} `}
                                color="success"
                                block={false}
                                title="¡ SOLICITA LA REUNIÓN !"
                                icon="check-circle"
                                text="Creando solicitud..."
                                loading={loading.btn}
                                handle={preMakeMeeting}
                                disabled={(validateButton) ? false : true}
                                outline={(validateButton) ? false : true}
                            />
                            :
                            <LoadBtn
                                handle={solicitar}
                                loading={loading.btn}
                                title="Enviar Solcitud"
                                icon="paper-plane"
                            />
                    }

                </ModalFooter>
            </Modal>
        </Fragment>
    );
}


export default ModalSolicitarExperto;