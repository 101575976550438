import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Container, FormGroup, Input, UncontrolledTooltip, Modal, ModalBody, ModalFooter, Label, CardImg } from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../../assets/scss/etm_login.scss";
import bg_image from "../../assets/images/etmmeet/login/Fondo-azul.png";
import { validateRut, rutInput, validatePassword, validateEmail, swal_etm, formatRut } from "../../helpers/etmmeetUtils";
import LoadBtn from "../../components/Utils/LoadBtn";
import TermsConditions from "../../pages/Public/TermsConditions";
import PrivacyPolicies from "../../pages/Public/PrivacyPolicies";
import CreatedBy from "../../components/Utils/CreatedBy";
import { API } from '../../helpers/axios';

import logo from "../../assets/images/conecTime/logo_full_negro.png";

const SignUp = () => {
    const history = useHistory();
    const asterisco = <b className="ml-1 etm_text_blue h4">*</b>;
    const query = new URLSearchParams(useLocation().search);

    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState({ acept: false, terms: false, privacy: false });
    const [name, setName] = useState({ value: "", valid: false, invalid: false });
    const [lastname, setLastname] = useState({ value: "", valid: false, invalid: false });
    const [email, setEmail] = useState({ value: "", valid: false, invalid: false });
    const [dni, setDni] = useState({ rut: "", pasaporte: "", type: "RUT", valid: false, invalid: false });
    const [referred, setReferred] = useState("");
    const [password, setPassword] = useState({
        value: ""
        , type: "password"
        , valid: false
        , invalid: false
    });
    const [repassword, setRepassword] = useState({
        value: ""
        , type: "password"
        , valid: false
        , invalid: false
    });
    const [securePassword, setSecurePassword] = useState({
        caracteres: false
        , minusculas: false
        , mayusculas: false
        , numeros: false
    });

    useEffect(() => {
        document.body.style.backgroundImage = "url(" + bg_image + ")";
        document.body.style.height = "100%";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundSize = "cover";
        document.body.style.overflowX = "hidden";
        return () => {
            document.body.style.backgroundImage = null;
            document.body.style.height = null;
            document.body.style.backgroundPosition = null;
            document.body.style.backgroundRepeat = null;
            document.body.style.backgroundSize = null;
            document.body.style.overflowX = null;
        }
    }, []);

    useEffect(() => {
        query.forEach(function (value, key) {
            if (value !== null) {
                changeData(idForType(key), value)
            }
        });
    }, []);

    const idForType = (id) => {
        const types = {
            't': 'TOKEN',
            'n': 'NAME',
            'l': 'LASTNAME',
            'e': 'EMAIL',
            'i': 'RUT',
        }
        return types[id]
    }

    const changeData = (type, value) => {
        switch (type) {
            case "NAME":
                setName({
                    value: value
                    , valid: (value.length < 2) ? false : true
                    , invalid: (value.length < 2) ? true : false
                });
                break;
            case "LASTNAME":
                setLastname({
                    value: value
                    , valid: (value.length < 2) ? false : true
                    , invalid: (value.length < 2) ? true : false
                });
                break;
            case "EMAIL":
                setEmail({
                    value: value
                    , valid: (validateEmail(value)) ? true : false
                    , invalid: (validateEmail(value)) ? false : true
                });
                break;
            case "RUT":
                setDni({
                    rut: formatRut(value)
                    , pasaporte: ""
                    , type: "RUT"
                    , valid: (validateRut(value)) ? true : false
                    , invalid: (validateRut(value)) ? false : true
                });
                break;
            case "PASAPORTE":
                setDni({
                    rut: ""
                    , pasaporte: value
                    , type: "PASAPORTE"
                    , valid: (value.length < 5) ? false : true
                    , invalid: (value.length < 5) ? true : false
                });
                break;
            case "PASSWORD":
                setPassword({
                    value: value
                    , type: password.type
                    , valid: (validatePassword(value)) ? true : false
                    , invalid: (validatePassword(value)) ? false : true
                });
                setSecurePassword({
                    caracteres: (value.length < 5) ? false : true
                    , minusculas: (!value.match(/[a-z]/)) ? false : true
                    , mayusculas: (!value.match(/[[A-Z]/)) ? false : true
                    , numeros: (!value.match(/\d/)) ? false : true
                });
                break;
            case "REPASSWORD":
                setRepassword({
                    value: value
                    , type: repassword.type
                    , valid: (value === password.value && value.length >= 5) ? true : false
                    , invalid: (value === password.value && value.length >= 5) ? false : true
                });
                break;
            case "TOKEN":
                setReferred(value)
                break;
        }
    }

    const showPassword = (type) => {
        if (type === "PASSWORD") {
            setPassword({
                value: password.value
                , type: (password.type === "password") ? "text" : "password"
                , valid: password.valid
                , invalid: password.invalid
            });
        } else {
            setRepassword({
                value: repassword.value
                , type: (repassword.type === "password") ? "text" : "password"
                , valid: repassword.valid
                , invalid: repassword.invalid
            });
        }
    }

    const preRegister = (e) => {
        e.preventDefault();

        if (!terms.acept) {
            swal_etm(`Debes aceptar nuestros <b class="etm_text_blue">términos y condiciones</b>.`);
            setLoading(false);
            return;
        }

        setLoading(true);

        if (name.value.length < 2) {
            swal_etm(`Debes ingresar tu <b class="etm_text_blue">Nombre</b> válido.`);
            setLoading(false);
            return;
        }
        if (lastname.value.length < 2) {
            swal_etm(`Debes ingresar tus <b class="etm_text_blue">Apellidos</b> válido.`);
            setLoading(false);
            return;
        }
        if (!validateEmail(email.value)) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">Correo</b> válido.`);
            setLoading(false);
            return;
        }
        if ((dni.type === "RUT") && (!validateRut(dni.rut))) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">RUT</b> válido.`);
            setLoading(false);
            return;
        }
        if ((dni.type === "PASAPORTE") && (dni.pasaporte.length < 5)) {
            swal_etm(`Debes ingresar un <b class="etm_text_blue">Pasaporte</b> válido.`);
            setLoading(false);
            return;
        }
        if (!validatePassword(password.value)) {
            swal_etm(`Contraseña poco segura. Debe ser al menos <b class="etm_text_blue">5 caracteres</b>, <b class="etm_text_blue">1 mayúscula</b>, <b class="etm_text_blue">1 minúscula</b> y <b class="etm_text_blue">1 número</b>.`);
            setLoading(false);
            return;
        }
        if (password.value !== repassword.value) {
            swal_etm(`Tus <b class="etm_text_blue">Contraseñas</b> deben coincidir.`);
            setLoading(false);
            return;
        }

        postRegister();
    }

    const postRegister = async () => {
        const res = await API.post("/register/registerUser", {
            name: name.value
            , lastname: lastname.value
            , email: email.value
            , dni_type: dni.type
            , dni: (dni.type === "RUT") ? dni.rut : dni.pasaporte
            , password: password.value
            , device: "PC"
            , referred
        });

        if (res.data.state) {
            history.push(`/onboarding/${res.data.token}`);
        } else {
            swal_etm(res.data.msg);
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className="pt-5 etm_register_bg">
                <Container>
                    <Card className="ml-md-5 ml-2 mr-md-5 mr-2 mb-5">
                        <CardBody className="mt-3 ml-md-3 ml-1 mr-md-3 mr-1 mb-5">
                            <Row>
                                <Col md={6} xs={6} className="text-left">
                                    <Link to={{ pathname: '/login', state: { prevPath: useLocation().pathname.concat(useLocation().search) } }} className="etm_link_gray"><i className="fas fa-chevron-left mr-2"></i>Volver</Link>
                                </Col>
                                <Col md={6} xs={6} className="text-right">
                                    <Link to={`/login`} className="etm_link_gray"><i className="far fa-times-circle fa-2x"></i></Link>
                                </Col>
                                <Col sm={12} className="text-center">
                                    <div className="text-center m-2 pb-4">
                                        <img src={logo} alt="" className="img-fluid" width="40%" />
                                    </div>
                                </Col>
                                <Col sm={12} className="text-center">
                                    <Label className="h4 etm_etm_text_gray"><b>REGíSTRATE</b></Label>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <Label className="ml-3 h4 etm_etm_text_gray">Nombre {asterisco}</Label>
                                        <Input
                                            onChange={(e) => changeData("NAME", e.target.value)}
                                            name="name"
                                            className="form-control etm_register_input"
                                            type="text"
                                            placeholder="Ingresa tu nombre"
                                            required
                                            valid={name.valid}
                                            invalid={name.invalid}
                                            value={name.value}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <Label className="ml-3 h4 etm_etm_text_gray">Apellidos {asterisco}</Label>
                                        <Input
                                            onChange={(e) => changeData("LASTNAME", e.target.value)}
                                            name="lastname"
                                            className="form-control etm_register_input"
                                            type="text"
                                            placeholder="Ingresa tus apellidos"
                                            required
                                            valid={lastname.valid}
                                            invalid={lastname.invalid}
                                            value={lastname.value}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <Label className="ml-3 h4 etm_etm_text_gray">Email {asterisco}</Label>
                                        <Input
                                            onChange={(e) => changeData("EMAIL", e.target.value)}
                                            name="email"
                                            id="email"
                                            className="form-control etm_register_input"
                                            type="email"
                                            placeholder="Ingresa tu correo electrónico"
                                            required
                                            valid={email.valid}
                                            invalid={email.invalid}
                                            value={email.value}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    {
                                        (dni.type === "RUT") ?
                                            <FormGroup className="animate__animated animate__fadeIn">
                                                <Label className="ml-3 h4 etm_etm_text_gray">RUT </Label>{asterisco}
                                                <Input
                                                    onChange={(e) => (rutInput(e), changeData("RUT", e.target.value))}
                                                    name="rut"
                                                    id="rut"
                                                    className="form-control etm_register_input"
                                                    type="text"
                                                    placeholder="Ingresa tu RUT"
                                                    required
                                                    valid={dni.valid}
                                                    invalid={dni.invalid}
                                                    value={dni.rut}
                                                />
                                                <Label
                                                    className="ml-3 etm_link_blue h5 pointer mt-1"
                                                    onClick={() => setDni({
                                                        rut: dni.rut,
                                                        pasaporte: dni.pasaporte,
                                                        type: "PASAPORTE",
                                                        valid: dni.valid,
                                                        invalid: dni.invalid
                                                    })}>No tengo RUT, soy extranjero
                                                </Label>
                                            </FormGroup>
                                            : <FormGroup className="animate__animated animate__fadeIn">
                                                <Label className="ml-3 h4 etm_etm_text_gray">Pasaporte </Label>{asterisco}
                                                <Input
                                                    onChange={(e) => changeData("PASAPORTE", e.target.value)}
                                                    name="pasaporte"
                                                    id="pasaporte"
                                                    className="form-control etm_register_input"
                                                    type="text"
                                                    placeholder="Ingresa el número de tu pasaporte"
                                                    required
                                                    valid={dni.valid}
                                                    invalid={dni.invalid}
                                                    value={dni.pasaporte} />
                                                <Label
                                                    className="ml-3 etm_link_blue h5 pointer mt-1"
                                                    onClick={() => setDni({
                                                        rut: dni.rut,
                                                        pasaporte: dni.pasaporte,
                                                        type: "RUT",
                                                        valid: dni.valid,
                                                        invalid: dni.invalid
                                                    })}>Tengo RUT
                                                </Label>
                                            </FormGroup>
                                    }
                                </Col>

                                <Col lg={6} md={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <Label className="ml-3 h4 etm_etm_text_gray">Contraseña {asterisco}</Label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group pointer etm_text_blue" id="password_eye" onClick={() => showPassword("PASSWORD")}><i className="fas fa-eye"></i></span>
                                                <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target="password_eye">Mostrar constraseña</UncontrolledTooltip>
                                            </div>
                                            <Input onChange={(e) => changeData("PASSWORD", e.target.value)} name="password" id="password" className="form-control etm_register_input" type={password.type} placeholder="Al menos 5 dígitos, una mayúscula y una minúscula" required valid={password.valid} invalid={password.invalid} />
                                        </div>
                                        <Label hidden={(password.value.length > 0) ? false : true} className="ml-3 mt-2 h6 animate__animated animate__fadeIn"><i className="fas fa-info-circle etm_text_blue mr-2"></i><span className={(securePassword.caracteres) ? "etm_text_blue" : "text-danger"}>5 caracteres</span> | <span className={(securePassword.mayusculas) ? "etm_text_blue" : "text-danger"}>mayúsculas</span> | <span className={(securePassword.minusculas) ? "etm_text_blue" : "text-danger"}>minúsculas</span> | <span className={(securePassword.numeros) ? "etm_text_blue" : "text-danger"}>números</span></Label>
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <Label className="ml-3 h4 etm_etm_text_gray">Confirma la contraseña {asterisco}</Label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text etm_input_group pointer etm_text_blue" id="repassword_eye" onClick={() => showPassword("REPASSWORD")}><i className="fas fa-eye"></i></span>
                                                <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target="repassword_eye">Mostrar constraseña</UncontrolledTooltip>
                                            </div>
                                            <Input onChange={(e) => changeData("REPASSWORD", e.target.value)} name="repassword" className="form-control etm_register_input" type={repassword.type} placeholder="Reingresa la contraseña" required valid={repassword.valid} invalid={repassword.invalid} />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <hr />
                                <Col lg={12} className="mt-3 text-center">
                                    <div className="custom-control custom-checkbox">
                                        <Input type="checkbox" className="custom-control-input input-mini" id="terms" onClick={() => setTerms({ acept: !terms.acept, terms: terms.show, privacy: false })} />
                                        <Label htmlFor="terms" className="custom-control-label">{""}</Label>
                                        <Label>¿ Aceptas nuestros <span className="etm_link_blue pointer" onClick={() => setTerms({ acept: terms.acept, terms: true, privacy: false })}><b>términos y condiciones</b></span> ?</Label>
                                    </div>
                                    <Label>¿ Quieres leer nuestras <span className="etm_link_blue pointer" onClick={() => setTerms({ acept: terms.acept, terms: false, privacy: true })}><b>políticas de privacidad</b></span> ?</Label>
                                </Col>
                                <Col lg={12} className="mt-3 text-center">
                                    <LoadBtn className="btn-lg" handle={preRegister} loading={loading} title="¡ COMENCEMOS !" text="Creando cuenta..." />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <CreatedBy full={false} />
                </Container>
            </div>
            <Modal isOpen={(terms.terms || terms.privacy) ? true : false} size="lg" scrollable={true} >
                <ModalBody>
                    <Container>
                        {
                            (terms.terms) ?
                                <TermsConditions />
                                : <PrivacyPolicies />
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Link className="h5 pl-5 pr-5 text-danger" onClick={(e) => setTerms({ acept: terms.acept, terms: false, privacy: false })}><u><i className="fas fa-arrow-left mr-2"></i>Cerrar</u></Link>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default (SignUp);