import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import logo from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

import logo from "../../assets/images/conecTime/logo_full_blanco.png";
import logo_stgo_innova from "../../assets/images/conecTime/Santiago-Innova-logo.png";

//i18n
import { withNamespaces } from 'react-i18next';

const Header = (props) => {

	const [isSearch, setSearch] = useState(false);


	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}
	return (
		<Fragment>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							{/* <Link to="/home" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logo} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<img src={logoDark} alt="" height="17" />
								</span>
							</Link> */}

							{/* <Link to="/home" className="logo logo-light">
								<span className="logo-sm">
									<img src={logoLightSvg} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<img src={logoLight} alt="" height="19" />
								</span>
							</Link> */}

							<Link to="/home" className="logo logo-light">
								<span className="logo-xs">
									<img src={logo} alt="" height="50" />
								</span>
							</Link>
							
						</div>

						{/* <button
							type="button"
							className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
							data-toggle="collapse"
							onClick={() => { props.toggleLeftmenu(!props.leftMenu); }}
							data-target="#topnav-menu-content">
							<i className="fa fa-fw fa-bars"></i>
						</button> */}
					</div>

					{/* <div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/home" className="logo logo-light">
								<span className="logo-xs">
									<img src={logo_stgo_innova} alt="" height="50" />
								</span>
							</Link>
						</div>
					</div> */}

					<div className="d-flex">
						<div className="dropdown d-inline-block d-lg-none ml-2">
							{/* <button
								type="button"
								className="btn header-item noti-icon waves-effect"
								id="page-header-search-dropdown"
								onClick={() => setSearch(!isSearch)}>
								<i className="mdi mdi-magnify"></i>
							</button> */}
							<div
								className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
								aria-labelledby="page-header-search-dropdown"
							>
								<form className="p-3">
									<div className="form-group m-0">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												placeholder={props.t('Search') + "..."}
												aria-label="Recipient's username"
											/>
											<div className="input-group-append">
												<button className="btn btn-primary etm_btn " type="submit">
													<i className="mdi mdi-magnify"></i>
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						{/* <LanguageDropdown />  */}

						{/* <div className="dropdown d-none d-lg-inline-block ml-1">
							<button
								type="button"
								className="btn header-item noti-icon waves-effect"
								onClick={() => { toggleFullscreen() }}
								data-toggle="fullscreen"
							>
								<i className="bx bx-fullscreen"></i>
							</button>
						</div> */}

						<NotificationDropdown />

						<ProfileMenu />

					</div>
				</div>
			</header>
		</Fragment>
	);
}

const mapStatetoProps = state => {
	const { layoutType, showRightSidebar, leftMenu } = state.Layout;
	return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, { showRightSidebarAction, toggleLeftmenu })(withNamespaces()(Header));


