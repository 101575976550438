import React, { Fragment, useState, useEffect } from 'react';

import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalFooter, Button, FormGroup, Input } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';

import { API } from '../../helpers/axios';
import swal from 'sweetalert';
import "../../assets/scss/etm.scss";
import LoadScreen from "../../components/Utils/LoadScreen";
import LoadBtn from "../../components/Utils/LoadBtn";
import Datatable from "../../components/Utils/Datatable";
import { swal_etm } from "../../helpers/etmmeetUtils";

const GivenHoursList = ({ history }) => {

    const ls = JSON.parse(localStorage.getItem('user'));

    const [hours, setHours] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loadscreen, setLoadscreen] = useState(false);
    const [modal, setModal] = useState(false);
    const [meetId, setMeetId] = useState(0);
    const [motivo, setMotivo] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [checks, setChecks] = useState([]);
    const [msg, setMsg] = useState({
        manual: "Eliminaremos este bloque de la plataforma <br>y tu calendario."
        , seleccion: "Se eliminarán todos los bloques seleccionados que <br><b>no tengan reuniones confirmadas</b>."
        , todos: "Se eliminarán todos los bloques que <br><b>no tengan reuniones confirmadas</b>."
    });

    useEffect(() => {
        getHoursByUserId(true);
    }, []);

    useEffect(() => {
        setInterval(function () {
            getHoursByUserId(false);
        }, 30000);
    }, []);

    useEffect(() => {
        getHoursByUserId(false);
    }, [checkAll]);

    const getHoursByUserId = async (start) => {
        if (start) {
            setLoadscreen(true);
        }

        const res = await API.get('/getHoursByUserId', { params: { user_id: ls.id } });
        setHours([]);
        let list = [];

        if (res.data.state) {
            setColumns([
                { field: 'number', text: '#' }
                , { field: 'dia', text: 'DÍA' }
                , { field: 'horario', text: 'HORARIO' }
                , { field: 'estado', text: 'ESTADO' }
                , { field: 'opciones', text: 'OPCIONES' }
            ]);

            res.data.hours.map((e, i) => {
                let color = "";
                let ocultarBtn = false;
                switch (e.ESTADO) {
                    case 'LIBRE':
                        color = "text-muted";
                        break;
                    case 'CANCELADO':
                        color = "text-danger";
                        ocultarBtn = true;
                        break;
                    default:
                        color = "etm_text_blue";
                        break;
                }
                list.push({
                    id: e.ID
                    , number: (i + 1)
                    , dia: e.TEXTO
                    , horario: <Fragment><i className="fas fa-clock etm_text_blue"></i> {e.INICIO} - {e.FIN}</Fragment>
                    , estado: <span className={color}><b>{e.ESTADO}</b></span>
                    , opciones: <div className="text-center"><button hidden={ocultarBtn} className="btn btn-danger etm_btn btn-sm ml-2" onClick={() => showModal(e.ID, e.ESTADO)}><i className="fas fa-trash"></i> Eliminar bloque</button></div>
                });
            });
        }
        setHours(list);
        setLoadscreen(false);
        setModal(false);
    }

    const showModal = (id, estado) => {
        if (estado === "LIBRE") {
            const wrapper = document.createElement('div');
            const body = msg.manual;
            wrapper.innerHTML = `<br/>${body}`;
            swal({
                title: "¿Está seguro?",
                content: wrapper,
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancelar", "Eliminar bloque"],
            }).then((willDelete) => {
                if (willDelete) {
                    setLoadscreen(true);
                    postDeleteHour([id], 'MANUAL');
                }
            });
        } else {
            setMeetId(id);
            setModal(true);
        }
    }

    const deleteHour = () => {
        if (motivo.length < 3) {
            swal_etm("Ingresa un motivo válido!");
            return false;
        }
        setLoadscreen(true);
        postDeleteHour([meetId], 'MANUAL', motivo);
    };

    const deleteAll = async () => {
        if (checkAll === true || checks.length > 0) {
            const wrapper = document.createElement('div');
            const body = (checkAll) ? msg.todos : msg.seleccion;
            wrapper.innerHTML = `<br/>${body}`;
            swal({
                title: "¿Está seguro?",
                content: wrapper,
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttons: ["Cancelar", "Eliminar bloques"],
            }).then((willDelete) => {
                if (willDelete) {
                    setLoadscreen(true);
                    postDeleteHour(checks, (checkAll) ? 'TODOS' : 'SELECCION');
                }
            });
        } else {
            swal_etm("Debes seleccionar al menos un bloque para eliminar!");
        }
    }

    const postDeleteHour = async (meet_id, type, motivo = "") => {
        const res = await API.delete('/deleteGivenTime', { params: { list_id: meet_id, type, user_id: ls.id, motivo } });
        if (res.data.state) {
            swal_etm(res.data.msg, "success", "");
        } else {
            swal_etm(res.data.msg);
        }
        setTimeout(function () {
            window.location.reload();
        }, 1000);
        getHoursByUserId(true);
        setLoadscreen(false);
        setModal(false);
    };

    return (
        <Fragment>
            <div className="page-content">
                <Card>
                    <CardBody>
                        <Container fluid>
                            <Breadcrumbs title={'Tiempo Donado'} />
                            {
                                (loadscreen) ?
                                    <LoadScreen />
                                    :
                                    <Fragment>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <h3 className="etm_text_blue mb-3"><i className="fas fa-clipboard-list mr-2"></i>Tiempo Donado</h3>
                                            </Col>
                                            <Col md={6} sm={12} className="text-md-right text-left">
                                                <button className="btn btn-danger etm_btn btn-md pl-3 pr-3" onClick={() => deleteAll()}><i className="fas fa-trash"></i> Eliminar seleccionados</button>
                                            </Col>
                                        </Row>
                                        <Datatable
                                            data={hours}
                                            columns={columns}
                                            color="secondary"
                                            showCheckbox={true}
                                            headerHandle={checkAll}
                                            setHeaderHandle={setCheckAll}
                                            rowHandle={checks}
                                            setRowHandle={setChecks}
                                        />
                                    </Fragment>
                            }
                        </Container>
                    </CardBody>
                </Card>
            </div>
            <Modal isOpen={modal} size={'md'} scrollable={true} toggle={() => setModal(false)}>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <FormGroup className="mb-5">
                                <h4 className="etm_text_blue text-center mt-5 mb-5"><b>¿Estás seguro de eliminar este bloque?</b></h4>
                            </FormGroup>
                            <FormGroup className="mt-5">
                                <h6 className="text-muted">Ingresa el <b className="etm_text_blue">motivo</b> de por qué quieres eliminar este bloque<b className="etm_required_asterisco ml-1">*</b></h6>
                                <Input type="textarea" name="motivo" rows="5" onChange={(e) => setMotivo(e.target.value)} placeholder="Máximo 300 caractéres" maxLength="300" className="etm_textarea"></Input>
                            </FormGroup>
                            <FormGroup className="mt-5">
                                <h6 className="text-muted"><i className="fas fa-exclamation-triangle etm_text_blue mr-2"></i>{msg.manual}</h6>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <Button color="light" onClick={() => setModal(false)} className="etm_btn"><i className="fas fa-times"></i> Cancelar</Button>
                    <LoadBtn color="danger" handle={deleteHour} loading={loading} title="Eliminar Bloque" icon="trash"></LoadBtn>
                </ModalFooter>
            </Modal>
        </Fragment>


    );
}

export default withNamespaces()(GivenHoursList);