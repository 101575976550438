import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import Logo from "../../assets/images/conecTime/logo_conectime.png";

const PrivacyPolicies = ( props ) => {
    
    const etmmeet = <Fragment><b>EtM</b><i className="etm_text_blue">meet</i></Fragment>

    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <Col sm={12} className="etm_policies_terms mt-5 mb-5 text-left animate__animated animate__fadeIn">
                            <h1>Política de privacidad { etmmeet }</h1>
                            <h3>Versión 1.0</h3>
                            <h3>Última actualización: 13 de noviembre de 2021</h3>
                            <hr />
                            <p>Esta Política de privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando use { etmmeet }.</p>
                            <p>Usamos sus datos personales para proporcionar y mejorar el servicio. Al utilizar { etmmeet }, acepta la recopilación y el uso de información de acuerdo con esta política de privacidad.</p>
                            <h1>Interpretación y definiciones</h1>
                            <h3>Interpretación</h3>
                            <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos en las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p>
                            <h3>Definiciones</h3>
                            <p>A los efectos de esta política de privacidad:</p>
                            <ul>
                                <li><b>Cuenta</b> significa una cuenta única creada para que usted acceda a nuestro servicio o partes de nuestro servicio.</li>
                                <li><b>Compañía</b> (denominada "la Compañía", "Nosotros" o "Nuestro" en este acuerdo) se refiere a Corporación Emprende tu Mente, ubicada en Cerro El Plomo #5420, Las Condes, Chile.</li>
                                <li><b>Las cookies</b> son pequeños archivos que un sitio web coloca en su computadora, dispositivo móvil o cualquier otro dispositivo, que contienen los detalles de su historial de navegación en ese sitio web, entre sus muchos usos.</li>
                                <li><b>País</b> se refiere a Chile</li>
                                <li><b>Dispositivo</b> significa cualquier dispositivo que pueda acceder al servicio, como una computadora, un teléfono celular o una tableta digital.</li>
                                <li><b>Los datos personales</b> son cualquier información que se relacione con una persona identificada o identificable.</li>
                                <li><b>El servicio</b> se refiere al sitio web y la aplicación móvil.</li>
                                <li><b>Proveedor de servicios</b> significa cualquier persona física o jurídica que procesa los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por la Compañía para facilitar el Servicio, para proporcionar el Servicio en nombre de la Compañía, para realizar servicios relacionados con el Servicio o para ayudar a la Compañía a analizar cómo se utiliza el Servicio.</li>
                                <li><b>Los Datos de uso</b> se refieren a los datos recopilados automáticamente, ya sea generados por el uso del servicio o por la propia infraestructura del servicio (por ejemplo, la duración de una visita a la página).</li>
                                <li><b>El sitio web</b> se refiere a { etmmeet }, accesible desde <a href="https://www.etmmeet.org"><b>www.etmmeet.org</b></a></li>
                                <li><b>Usted se</b> refiere a la persona que accede o utiliza el servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el servicio, según corresponda.</li>
                            </ul>
                            <h1>Recopilación y uso de sus datos personales</h1>
                            <h3>Tipos de datos recopilados</h3>
                            <h3>Información personal</h3>
                            <p>Mientras usamos { etmmeet }, podemos pedirle que nos proporcione cierta información de identificación personal que pueda usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:</p>
                            <ul>
                                <li>Dirección de correo electrónico</li>
                                <li>Nombre y apellido</li>
                                <li>R.U.T.</li>
                                <li>Número de teléfono</li>
                                <li>Género</li>
                                <li>Fecha de Nacimiento</li>
                                <li>RRSS</li>
                                <li>Datos de uso</li>
                            </ul>
                            <h3>Datos de uso</h3>
                            <p>Los datos de uso se recopilan automáticamente cuando se utiliza el servicio.</p>
                            <p>Los datos de uso pueden incluir información como la dirección de Protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro servicio que visita, la hora y fecha de su visita, el tiempo que pasó en esas páginas, dispositivo único identificadores y otros datos de diagnóstico.</p>
                            <p>Cuando accede al servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su dispositivo móvil sistema operativo, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivo únicos y otros datos de diagnóstico.</p>
                            <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al servicio a través de un dispositivo móvil.</p>
                            <h1>Tecnologías de seguimiento y cookies</h1>
                            <p>Usamos cookies y tecnologías de seguimiento similares para rastrear la actividad en nuestro servicio y almacenar cierta información. Las tecnologías de seguimiento utilizadas son etiquetas y scripts para recopilar y rastrear información y para mejorar y analizar Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:</p>
                            <ul>
                                <li>Cookies o cookies del navegador. Una cookie es un pequeño archivo que se coloca en su dispositivo. Puede indicarle a su navegador que rechace todas las cookies o que indique cuándo se está enviando una cookie. Sin embargo, si no acepta las cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro servicio puede utilizar cookies.</li>
                            </ul>
                            <p>Las cookies pueden ser cookies "persistentes" o de "sesión". Las cookies persistentes permanecen en su computadora personal o dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan pronto como cierra su navegador web. </p>
                            <h1>Uso de sus datos personales</h1>
                            <p>La Compañía puede utilizar los Datos Personales para los siguientes propósitos:</p>
                            <ul>
                                <li>Para proporcionar y mantener nuestro servicio, incluso para supervisar el uso de nuestro servicio.</li>
                                <li>Para administrar su Cuenta: para administrar su registro como usuario del servicio. Los datos personales que proporcione pueden darle acceso a diferentes funcionalidades del servicio que están disponibles para usted como usuario registrado.</li>
                                <li>Para contactarlo: Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</li>
                                <li>Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha comprado o sobre los que ha consultado, a menos que haya optado por no recibir dicha información.</li>
                                <li>Para gestionar sus solicitudes: Para atender y gestionar sus solicitudes para nosotros.</li>
                                <li>Para otros fines : podemos utilizar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la eficacia de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</li>
                            </ul>
                            <p>Podemos compartir su información personal en las siguientes situaciones:</p>
                            <ul>
                                <li>Con otros usuarios: cuando comparte información personal o interactúa de otro modo en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior.</li>
                                <li>Con su consentimiento: podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
                            </ul>
                            <h1>Conservación de sus datos personales</h1>
                            <p>La Compañía retendrá sus datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta política de privacidad. Retendremos y usaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.</p>
                            <p>La Compañía también retendrá los datos de uso con fines de análisis interno. Los datos de uso generalmente se conservan por un período de tiempo más corto, excepto cuando estos datos se usan para fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>
                            <h1>Transferencia de sus datos personales</h1>
                            <p>Su información, incluidos los datos personales, se procesa en las oficinas operativas de la Compañía y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</p>
                            <p>Su consentimiento a esta política de privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.</p>
                            <p>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de forma segura y de acuerdo con esta política de privacidad y no se realizará ninguna transferencia de sus datos personales a una organización o país a menos que existan controles adecuados establecidos, incluida la seguridad de tus datos y otra información personal.</p>
                            <h1>Seguridad de sus datos personales</h1>
                            <p>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</p>
                            <h1>Privacidad de los niños</h1>
                            <p>Nuestro Servicio no se dirige a ninguna persona menor de 18 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 18 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado datos personales, por favor contáctenos. Si nos damos cuenta de que hemos recopilado datos personales de cualquier persona menor de 18 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.</p>
                            <p>Si necesitamos depender del consentimiento como base legal para procesar su información y su país requiere el consentimiento de uno de los padres, es posible que necesitemos el consentimiento de sus padres antes de recopilar y utilizar esa información.</p>
                            <h1>Enlaces a otros sitios web</h1>
                            <p>Nuestro servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la política de privacidad de cada sitio que visite.</p>
                            <p>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.</p>
                            <h1>Cambios a esta política de privacidad</h1>
                            <p>Podemos actualizar nuestra política de privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando la nueva política de privacidad en esta página.</p>
                            <p>Le informaremos por correo electrónico y / o un aviso destacado en nuestro servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta política de privacidad.</p>
                            <p>Se le recomienda que revise esta política de privacidad periódicamente para ver si hay cambios. Los cambios a esta política de privacidad entran en vigencia cuando se publican en esta página.</p>
                            <h1>Contáctenos</h1>
                            <p>Si tiene alguna pregunta sobre esta política de privacidad, puede contactarnos:</p>
                            <ul>
                                <li>Por correo electrónico: <a href="mailto:contacto@emprendetumente.org?subject=Contacto%20desde%20Landing%20|%20EtMmeet"><b>contacto@emprendetumente.org</b></a></li>
                                <li>Visitando esta página en nuestro sitio web: <a href="https://emprendetumente.org/"><b>www.emprendetumente.org</b></a></li>
                            </ul>
                        </Col>
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(PrivacyPolicies);