import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, InputGroup, CustomInput } from "reactstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { validateRut, rutInput, swal_etm, getDate, cleanFacebook, cleanInstagram, cleanTwitter, pathS3, selectCustomStyle } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import UploadImageFile from "../Utils/UploadImageFile";
import AvatarDefault from "../Utils/AvatarDefault";
import { useHistory } from "react-router-dom";
import { setSession } from '../../helpers/authUtils';

const Step4Emprendedor = (props) => {
    const history = useHistory();

    const date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia = getDate(primerDia, true).replace(/\-/g, '/');

    const yyyy = date.getFullYear();

    /*** BILLING YEAR ***/
    const billing_year = {
        year1: yyyy - 2
        , year2: yyyy - 1
    }

    const chileData = { value: 81, label: "Chile" };

    const [projectName, setProjectName] = useState({ show: false, name: "" });
    const [projectIndustry, setProjectIndustry] = useState(null);
    const [projectDescription, setProjectDescription] = useState("");
    const [proyectPictures, setProyectPictures] = useState([]);
    const [projectTag, setProjectTag] = useState(null);

    const [projectRut, setProjectRut] = useState("");
    const [projectCommercial, setProjectCommercial] = useState("");
    const [projectActivityDate, setProjectActivityDate] = useState("");
    const [projectEmployees, setProjectEmployees] = useState(null);
    const [projectWebsite, setProjectWebsite] = useState({ is_website: true, url: "" });
    const [projectCountries, setProjectCountries] = useState([chileData]);
    const [projectRegions, setProjectRegions] = useState(null);
    const [projectInvestment, setProjectInvestment] = useState(null);
    const [projectBilling1, setProjectBilling1] = useState(null);
    const [projectBilling2, setProjectBilling2] = useState(null);
    const [projectInvoices, setProjectInvoices] = useState(null);
    const [projectLeaders, setProjectLeaders] = useState({ leader1: null, leader2: null, leader3: null });
    const [projectImpacts, setProjectImpacts] = useState(null);
    const [projectOds, setProjectOds] = useState(null);
    const [projectGenderEquality, setProjectGenderEquality] = useState(0);
    const [projectDiversityInclusion, setProjectDiversityInclusion] = useState(0);
    const [projectWomenLeader, setProjectWomenLeader] = useState(0);
    const [projectCommercialType, setProjectCommercialType] = useState(null);
    const [personalDescription, setPersonalDescription] = useState("");
    const [personalProfilePicture, setPersonalProfilePicture] = useState([]);
    const [personalNickname, setPersonalNickname] = useState({ show: false, name: "" });
    const [personalRrss, setPersonalRrss] = useState({ facebook: "", instagram: "", twitter: "", youtube: "" });

    const [showLeader, setShowLeader] = useState({ leader2: false, leader3: false });
    const [stages, setStages] = useState({ option1: [], option2: [] });
    const [showRegions, setShowRegions] = useState(false);
    const [avatarType, setAvatarType] = useState(true);
    const [loading, setLoading] = useState(false);
    const [etapaSelect, setEtapaSelect] = useState("");
    const [vistaDatos, setVistaDatos] = useState({ option1: false, option2: false });

    const [listOds, setListOds] = useState([]);
    const [listImpacts, setListImpacts] = useState([]);
    const [listStages, setListStages] = useState([]);
    const [listInvoices, setListInvoices] = useState([]);
    const [listInvestment, setListInvestment] = useState([]);
    const [listEmployees, setListEmployees] = useState([]);
    const [listIndustries, setListIndustries] = useState([]);
    const [listTags, setListTags] = useState([]);
    const [listCountries, setListCountries] = useState([]);
    const [listRegions, setListRegions] = useState([]);
    const [listBillings, setListBillings] = useState([]);
    const [listCommercialType, setListCommercialType] = useState([]);

    useEffect(() => {
        changeView();
    }, [etapaSelect]);

    useEffect(() => {
        showChileanRegions();
    }, [projectCountries]);

    useEffect(() => {
        getOds();
        getImpacts();
        getInvoices();
        getInvestment();
        getProjectStage();
        getEmployees();
        getInduistries();
        getTags();
        getCountries();
        getRegions();
        getBillings();
        getCommercialType();
    }, []);

    const getOds = async () => {
        const res = await API.get('/general/getOds');
        if (res.data.ods.length > 0) {
            let list = [];
            res.data.ods.map((e, i) => {
                list.push({ value: e.ID, label: e.ODS });
            });
            setListOds(list);
        }
    }

    const getImpacts = async () => {
        const res = await API.get('/general/getImpacts');
        if (res.data.impacts.length > 0) {
            let list = [];
            res.data.impacts.map((e, i) => {
                list.push({ value: e.ID, label: e.IMPACTO });
            });
            setListImpacts(list);
        }
    }

    const getInvoices = async () => {
        const res = await API.get('/general/getInvoices');
        if (res.data.invoices.length > 0) {
            let list = [];
            res.data.invoices.map((e, i) => {
                list.push({ value: e.ID, label: e.FACTURAS });
            });
            setListInvoices(list);
        }
    }

    const getInvestment = async () => {
        const res = await API.get('/general/getInvestment');
        if (res.data.investment.length > 0) {
            let list = [];
            res.data.investment.map((e, i) => {
                list.push({ value: e.ID, label: e.INVERSION });
            });
            setListInvestment(list);
        }
    }

    const getProjectStage = async () => {
        const res = await API.get('/general/getProjectStage');
        if (res.data.stage.length > 0) {
            let list = [];
            let stageOption1 = [];
            let stageOption2 = [];
            res.data.stage.map((e, i) => {
                if (!e.ETAPA_ACTIVA) {
                    stageOption1.push(e.ID);
                } else {
                    stageOption2.push(e.ID);
                }
                list.push(
                    <Col lg={3} md={6} sm={12} key={i}>
                        <FormGroup check>
                            <Label className="h5">
                                <Input name="radioEtapa" type="radio" id="radioIdea" onClick={() => setEtapaSelect(e.ID)} value={e.ID} />{' '}{e.ETAPA}
                            </Label>
                            <Label className="m-2">
                                <span dangerouslySetInnerHTML={{ __html: e.TITULO }} ></span>
                                <br />
                                <br />
                                <i className="fas fa-arrow-right etm_text_blue mr-2"></i><span dangerouslySetInnerHTML={{ __html: e.SUBTITULO }} ></span>
                            </Label>
                        </FormGroup>
                    </Col>
                );
            });
            setListStages(list);
            setStages({ option1: stageOption1, option2: stageOption2 });
        }
    }

    const showChileanRegions = () => {
        if (projectCountries !== null) {
            if (projectCountries.filter(e => e.label.toString().toUpperCase() === 'CHILE').length > 0) {
                setShowRegions(true);
            } else {
                setShowRegions(false);
            }
        }
    }

    const changeView = () => {
        if (stages.option1.includes(etapaSelect)) {
            setVistaDatos({ option1: true, option2: false })
            setProjectName({ show: false, name: "SIN NOMBRE DEFINIDO" });
        } else if (stages.option2.includes(etapaSelect)) {
            setVistaDatos({ option1: false, option2: true })
            setProjectName({ show: true, name: "" });
        }
    }

    const getEmployees = async () => {
        const res = await API.get('/general/getEmployees');
        if (res.data.employees.length > 0) {
            let list = [];
            res.data.employees.map((e, i) => {
                list.push({ value: e.ID, label: e.EMPLEADOS });
            });
            setListEmployees(list);
        }
    }

    const getInduistries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
    }

    const getTags = async () => {
        const res = await API.get('/general/getTags');
        if (res.data.tags.length > 0) {
            let list = [];
            res.data.tags.map((e, i) => {
                list.push({ value: e.ID, label: e.TAG });
            });
            setListTags(list);
        }
    }

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        let list = [];
        if (res.data.state) {
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS });
            });
        }
        setListCountries(list);
    }

    const getRegions = async (e) => {
        const res = await API.get('/general/getRegions', {});
        let list = [];
        if (res.data.state) {
            res.data.regions.map((e, i) => {
                list.push({ value: e.ID, label: e.REGION });
            });
            setListRegions(list);
        }
    }

    const getBillings = async (e) => {
        const res = await API.get('/general/getBillings', {});

        if (res.data.billings.length > 0) {
            let list = [];
            res.data.billings.map((e, i) => {
                list.push({ value: e.ID, label: e.FACTURACION });
            });
            setListBillings(list);
        }
    }
    
    const getCommercialType = async (e) => {
        const res = await API.get('/general/getCommercialType');

        if (res.data.types.length > 0) {
            let list = [];
            res.data.types.map((e, i) => {
                list.push({ value: e.ID, label: `${e.TIPO} (${e.DESCRIPCION})` });
            });
            setListCommercialType(list);
        }
    }

    const changeActivityDate = (event, picker) => {
        setProjectActivityDate(getDate(picker.startDate));
    }

    const stepNumber = (e, title) => {
        return <Label className="h4"><span className="etm_onboarding_bg_step_number mr-2">{e}</span>{title}</Label>
    }

    const preSaveData = () => {
        setLoading(true);

        if (vistaDatos.option2) {
            if (!validateRut(projectRut)) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">RUT</b> válido.`);
                setLoading(false);
                return;
            }
        }
        if (projectName.name.length < 2) {
            swal_etm(`Debes ingresar el <b class="etm_text_blue">Nombre del proyecto</b> válido.`);
            setLoading(false);
            return;
        }
        if (projectIndustry === null || projectIndustry.length <= 1) {
            swal_etm(`Debes ingresar una <b class="etm_text_blue">Categoria/Industria</b> válida.`);
            setLoading(false);
            return;
        }


        if (vistaDatos.option2) {
            if (projectCommercial === "" || projectCommercial.length < 2) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Giro Comercial</b> válido.`);
                setLoading(false);
                return;
            }
            let diaActual = getDate(date, false);
            if (projectActivityDate > diaActual) {
                swal_etm(`Debes ingresar una <b class="etm_text_blue">FEcha de inicio de actividades</b> anterior a hoy.`);
                setLoading(false);
                return;
            }
            if (projectEmployees === null || projectEmployees.length < 1) {
                swal_etm(`Debes indicar cuantos <b class="etm_text_blue">Colaboradores</b> hay en el proyecto.`);
                setLoading(false);
                return;
            }
        }

        if (vistaDatos.option2) {
            if (projectCommercialType === null || projectCommercialType.length < 1) {
                swal_etm(`Debes indicar al menos un <b class="etm_text_blue">Tipo de comercio</b> para tu proyecto.`);
                setLoading(false);
                return;
            }
        }

        if (projectDescription === "" || projectDescription.length < 15) {
            swal_etm(`Describe tu <b class="etm_text_blue">Proyecto</b> en al menos 15 caracteres.`);
            setLoading(false);
            return;
        }

        if (projectWebsite.url === '') {
            if (projectWebsite.is_website) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Sitio WEb</b> </br>Recuerda que si no tienes <b class="etm_text_blue">Sitio WEb</b> puedes ingresar una <b class="etm_text_blue">Red Social</b>.`);
                setLoading(false);
                return;
            } else {
                swal_etm(`Debes ingresar una <b class="etm_text_blue">Red Social</b> <br />Recuerda que si no tienes una <b class="etm_text_blue">Red Social</b> puedes ingresar un <b class="etm_text_blue">Sitio WEb</b>.`);
                setLoading(false);
                return;
            }
        }

        if (vistaDatos.option2) {
            if (projectCountries === null || projectCountries.length < 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">País</b> en el que opere tu proyecto.`);
                setLoading(false);
                return;
            }
            if (projectCountries.filter(e => e.value === chileData.value).length >= 1) {
                if (projectRegions === null || projectRegions.length < 1) {
                    swal_etm(`Seleccionar al menos 1 <b class="etm_text_blue">región</b> donde opere tu proyecto`);
                    setLoading(false);
                    return;
                }
            }
            if (projectInvestment === null || projectInvestment.length <= 1) {
                swal_etm(`Debes indicar si tu proyecto ah recibido <b class="etm_text_blue">inversiones públicas o privadas</b>.`);
                setLoading(false);
                return;
            }
            if (projectInvoices === null || projectInvoices.length <= 1) {
                swal_etm(`Debes indicar la cantidad aproximada de <b class="etm_text_blue">facturas emitidas</b>.`);
                setLoading(false);
                return;
            }
            if (projectBilling1 === null || projectBilling1.length <= 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Rango de facturación</b> <br /> aproximado que generó tu emprendimiento durante el <b class="etm_text_blue">${billing_year.year1}</b>.`);
                setLoading(false);
                return;
            }
            if (projectBilling2 === null || projectBilling2.length <= 1) {
                swal_etm(`Debes ingresar un <b class="etm_text_blue">Rango de facturación</b> <br /> aproximado que generó tu emprendimiento durante el <b class="etm_text_blue">${billing_year.year2}</b>.`);
                setLoading(false);
                return;
            }
            if (projectLeaders.leader1 === null) {
                swal_etm(`Debes ingresar almenos un <b class="etm_text_blue">líder del proyecto</b>.`);
                setLoading(false);
                return;
            }
        }
        if (projectTag === null || projectTag.length < 1) {
            swal_etm(`Debes ingresar al menos una <b class="etm_text_blue">Etiqueta</b>.`);
            setLoading(false);
            return;
        }
        if (personalNickname.show === true && personalNickname.name.length < 2) {
            swal_etm(`Indícanos un <b class="etm_text_blue">apodo</b> o <b class="etm_text_blue">nickname</b> válido`);
            return;
        }
        if (personalDescription === null || personalDescription.length < 15) {
            swal_etm(`Debes ingresar una <b class="etm_text_blue">Descripción personal</b> de al menos 15 caracteres.`);
            setLoading(false);
            return;
        }
        if (personalProfilePicture === null || personalProfilePicture.length < 1) {
            swal_etm(`Debes seleccionar un <b class="etm_text_blue">Avatar</b> o subir una <b class="etm_text_blue">Foto de perdil</b>.`);
            setLoading(false);
            return;
        }
        saveData();
    }

    const saveData = async () => {
        const res = await API.post('/register/saveProyectEntrepreneur', {
            etapaSelect
            , projectRut
            , projectName: projectName.name
            , projectIndustry: projectIndustry.value
            , projectCommercial
            , projectActivityDate
            , projectEmployees: (projectEmployees !== null) ? projectEmployees.value : ''
            , projectWebsite: projectWebsite.url
            , projectDescription
            , proyectPictures         // array con url de las imagenes
            , projectCountries          // objeto con indice y valor
            , projectRegions            // objeto con indice y valor
            , projectInvestment: (projectInvestment !== null) ? projectInvestment.value : ''
            , projectInvoices: (projectInvoices !== null) ? projectInvoices.value : ''
            , projectBilling1: (projectBilling1 !== null) ? { value: projectBilling1.value, year: billing_year.year1 } : ''
            , projectBilling2: (projectBilling2 !== null) ? { value: projectBilling2.value, year: billing_year.year2 } : ''
            , projectLeaders          // objeto con indice y valor
            , projectImpacts            // objeto con indice y valor
            , projectOds: (projectOds !== null) ? projectOds.value : ''
            , projectWomenLeader
            , projectGenderEquality
            , projectDiversityInclusion
            , projectTag            // objeto con indice y valor
            , personalNickname: (personalNickname.show) ? personalNickname.name : props.data.name
            , personalDescription
            , personalRrss              //objeto con indice y valor
            , personalProfilePicture: personalProfilePicture[0]   // url
            , projectCommercialType
            , token: props.getToken()
            , device: "PC"
        });

        if(res.data.state){
            swal_etm("Perfecto! Ya estamos listo!", "success");
            setTimeout(
                function() {
                    props.saveStep(4);
                    setSession(res.headers, res.data.user);
                    history.push('/home');
                }
                .bind(this),
                3000
            );
        }else{
            swal_etm("Ups!", res.data.msg);
        }
        setLoading(false);
    }

    return (
        <Col className="mb-2 text-left" >
            <Container>
                <Card className="animate__animated animate__fadeIn">
                    <CardBody>
                        <Label className="h5">
                            <p>Los <b className="etm_text_blue">EMPRENDEDORES</b> y <b className="etm_text_blue">EMPRENDEDORAS</b> pueden solicitar reuniones comerciales y de mentorías a los diferentes expertos de <b>EtM</b><i className="etm_text_blue">meet</i>.</p>
                            <p>Cada reunión dura <b className="etm_text_blue">20 minutos</b> y finalizará automáticamente, por tanto es muy importante obtener algún dato de contacto de los expertos para mantener la relación.</p>
                        </Label>
                        <Label className="h5">
                            <p className="mb-0">Para acceder a estas reuniones y hacer un buen match necesitamos conocer tu emprendimiento, sus caracteristicas y necesidades.</p>
                            <p>A continuación configuraremos tu proyecto paso a paso.</p>
                        </Label>
                        <Label className="h5">
                            <p className="etm_text_blue mb-0"><i className="fas fa-info-circle mr-2"></i>Toda esta información solo será compartida con los usuarios que tú solicites y jamás será expuesta publicamente</p>
                        </Label>
                    </CardBody>
                </Card>

                {
                    (listStages.length > 0) ?
                        <Card className="animate__animated animate__fadeIn mt-5">
                            <CardBody>
                                {stepNumber(1, "¿ En qué etapa se encuentra tu proyecto o emprendimiento ?")}
                                <Row className="mt-4">
                                    {listStages}
                                </Row>
                            </CardBody>
                        </Card>
                        : ""
                }

                {/* EMPRENDIMIENTOS NO CONSTITUIDOS */}
                {
                    (vistaDatos.option1) ?
                        <Fragment>
                            <Card className="animate__animated animate__fadeIn mt-5" >
                                <CardBody>
                                    {stepNumber(2, "Cuéntanos sobre tu proyecto")}
                                    <Row className="mt-3 pb-3">
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h5">¿ Tu proyecto tiene nombre ?</Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input defaultChecked={!projectName.show} name="radioProyecto" type="radio" onClick={(e) => setProjectName({ show: false, name: e.target.value })} value="SIN NOMBRE DEFINIDO" />{' '}Aún no lo defino
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioProyecto" type="radio" onClick={(e) => setProjectName({ show: true, name: "" })} value="DEFINIDO" />{' '}SI
                                                </Label>
                                                <Row hidden={!projectName.show} className="animate__animated animate__fadeIn">
                                                    <Col md={6}>
                                                        <Input className="form-control etm_register_input" type="text" onChange={(e) => setProjectName({ show: projectName.show, name: e.target.value })} placeholder="Ingresa el nombre de tu proyecto o emprendimiento" />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">¿ En qué categoría o industria lo clasificarías ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una categoría o industria"}
                                                    value={projectIndustry}
                                                    onChange={setProjectIndustry}
                                                    options={listIndustries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Qué tipo de comercio aplica para tu proyecto ? 
                                                    <br/>
                                                    <small className="text-muted">( opcional, selecciona sólo los que correspondan )</small>
                                                </Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un tipo de comercio"}
                                                    value={projectCommercialType}
                                                    onChange={setProjectCommercialType}
                                                    options={listCommercialType}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4 mb-3">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    Descríbenos tu proyecto 
                                                    <br/>
                                                    <small className="text-muted">( máximo 400 caracteres )</small>
                                                </Label>
                                                <br />
                                                <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setProjectDescription(e.target.value)} placeholder="Mi proyecto se trata de ..." />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto tiene sitio web ?
                                                    <br />
                                                    <small className="text-muted"> ( opcional. Si no lo tiene, deja el link de alguna de las redes sociales de tu proyecto )</small>
                                                </Label>
                                                <br />
                                                <div className="ml-3">
                                                    <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch2" label="Tengo sitio web" bsSize="lg" checked={projectWebsite.is_website} onChange={() => setProjectWebsite({ is_website: !projectWebsite.is_website, url: projectWebsite.url })} />
                                                </div>
                                                <Input onChange={(e) => setProjectWebsite({ is_website: projectWebsite.is_website, url: e.target.value })} className="form-control etm_register_input" type="url" placeholder={(projectWebsite.is_website) ? "Ingresa la URL de tu sitio web" : "Ingresa la URL de Instagram o Facebook"} />
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                        : ""
                }

                {/* EMPRENDIMIENTOS CONSTITUIDOS */}
                {
                    (vistaDatos.option2) ?
                        <Fragment>
                            <Card className="animate__animated animate__fadeIn mt-5">
                                <CardBody>
                                    {stepNumber(2, "Acerca de tu proyecto")}
                                    <Row className="mt-3">
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5">Ingresa el RUT de tu emprendimiento</Label>
                                                <br />
                                                <Input onChange={(e) => (rutInput(e), setProjectRut(e.target.value))} value={projectRut} name="rut" id="rut" className="form-control etm_register_input" type="text" placeholder="Ingresa el RUT de tu emprendimiento" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 pb-3">
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5">Nombre o Razón Social de tu emprendimiento</Label>
                                                <br />
                                                <Input onChange={(e) => (setProjectName({ show: projectName.show, name: e.target.value }))} value={projectName.name} className="form-control etm_register_input" type="text" placeholder="Ingresa el Nombre o Razón Social de tu emprendimiento" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5">¿ Cuál es la Industria de tu emprendimiento ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una categoría o industria"}
                                                    value={projectIndustry}
                                                    onChange={setProjectIndustry}
                                                    options={listIndustries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">¿ Cuál es el Giro Comercial del emprendimiento ?</Label>
                                                <br />
                                                <Input onChange={(e) => setProjectCommercial(e.target.value)} value={projectCommercial} className="form-control etm_register_input" type="text" placeholder="Ingresa Giro Comercial del emprendimiento" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">Fecha de inicio de actividades</Label>
                                                <br />
                                                <DateRangePicker
                                                    onApply={changeActivityDate}
                                                    initialSettings={{
                                                        singleDatePicker: true,
                                                        autoApply: true,
                                                        showDropdowns: true,
                                                        startDate: primerDia,
                                                        locale: {
                                                            format: "DD-MM-YYYY",
                                                            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
                                                            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
                                                        }
                                                    }}>
                                                    <input onChange={(e) => setProjectActivityDate(e)} className="form-control etm_register_input" type="text" placeholder="YYYY-MM-DD / YYYY-MM-DD" required readOnly />
                                                </DateRangePicker>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">¿ Cuántos colaboradores hay en el proyecto ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un rango de colaboradores"}
                                                    value={projectEmployees}
                                                    onChange={setProjectEmployees}
                                                    options={listEmployees}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Qué tipo de comercio aplica para tu proyecto ? 
                                                    <br />
                                                    <small className="text-muted">( selecciona sólo los que correspondan )</small>
                                                </Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un tipo de comercio"}
                                                    value={projectCommercialType}
                                                    onChange={setProjectCommercialType}
                                                    options={listCommercialType}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    Descríbenos tu proyecto 
                                                    <br />
                                                    <small className="text-muted">( máximo 400 caracteres )</small>
                                                </Label>
                                                <br />
                                                <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setProjectDescription(e.target.value)} placeholder="Mi proyecto se trata de ..." />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto tiene sitio web ?
                                                    <br />
                                                    <small className="text-muted">( opcional. Si no lo tiene, deja el link de alguna de las redes sociales de tu proyecto )</small>    
                                                </Label>
                                                <br />
                                                <div className="ml-3">
                                                    <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch2" label="Tengo sitio web" bsSize="lg" checked={projectWebsite.is_website} onChange={() => setProjectWebsite({ is_website: !projectWebsite.is_website, url: projectWebsite.url })} />
                                                </div>
                                                <Input onChange={(e) => setProjectWebsite({ is_website: projectWebsite.is_website, url: e.target.value })} className="form-control etm_register_input" type="url" placeholder={(projectWebsite.is_website) ? "Ingresa la URL de tu sitio web" : "Ingresa la URL de Instagram o Facebook"} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                    : ""
                }

                {
                    (vistaDatos.option1 === true || vistaDatos.option2 === true) ?
                        <Fragment>
                            <Card className="animate__animated animate__fadeIn mt-5" >
                                <CardBody>
                                    {stepNumber(3, "Imágenes")}
                                    <Row className="mt-3">
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tienes alguna imagen asociada al proyecto ? 
                                                    <br />
                                                    <small className="text-muted">( opcional, pero te recomendamos subir al menos una  )</small>
                                                </Label>
                                                <br />
                                                <UploadImageFile
                                                    buttonText={ "Click aquí para subir imágenes" }
                                                    pathS3={ pathS3("STARTUP") }
                                                    singleImage={ false }
                                                    maxFileNumber={ 4 }
                                                    handle={ setProyectPictures }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                    :   ""
                }

                {/* EMPRENDIMIENTOS CONSTITUIDOS */}
                {
                    (vistaDatos.option2) ?
                        <Fragment>
                            <Card className="animate__animated animate__fadeIn mt-5" >
                                <CardBody>
                                    {stepNumber(4, "Datos para el match")}
                                    <Row className="mt-3">
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ En qué países opera tu proyecto o emprendimiento ? 
                                                    <br />
                                                    <small className="text-muted">( selecciona todos los países que quieras )</small>
                                                </Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona países"}
                                                    value={projectCountries}
                                                    onChange={setProjectCountries}
                                                    options={listCountries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4" hidden={!showRegions}>
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ En qué regiones de Chile opera tu proyecto o emprendimiento ? 
                                                    <br />
                                                    <small className="text-muted">( selecciona todas los regiones que quieras )</small>
                                                </Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona regiones"}
                                                    value={projectRegions}
                                                    onChange={setProjectRegions}
                                                    options={listRegions}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4" >
                                            <FormGroup check>
                                                <Label className="h5">¿ Tu proyecto ha recibido inversiones públicas o privadas ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una opción"}
                                                    value={projectInvestment}
                                                    onChange={setProjectInvestment}
                                                    options={listInvestment}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4" >
                                            <FormGroup check>
                                                <Label className="h5">Cantidad aproximada de facturas emitidas a la fecha</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un rango de facturación"}
                                                    value={projectInvoices}
                                                    onChange={setProjectInvoices}
                                                    options={listInvoices}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4" >
                                            <FormGroup check>
                                                <Label className="h5">Rango de facturación aproximado que generó tu emprendimiento durante el {billing_year.year1}</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un rango de facturación"}
                                                    value={projectBilling1}
                                                    onChange={setProjectBilling1}
                                                    options={listBillings}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-4" >
                                            <FormGroup check>
                                                <Label className="h5">Rango de facturación aproximado que generó tu emprendimiento durante el {billing_year.year2}</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona un rango de facturación"}
                                                    value={projectBilling2}
                                                    onChange={setProjectBilling2}
                                                    options={listBillings}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4" >
                                            <FormGroup check>
                                                <Label className="h5">
                                                    Ingresa el nombre de algún líder del proyecto 
                                                    <br />
                                                    <small className="text-muted">( mínimo 1 - máximo 3 )</small>
                                                    </Label>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group text-muted">Nombre líder 1</span>
                                                    </div>
                                                    <Input onChange={(e) => setProjectLeaders({ leader1: e.target.value, leader2: projectLeaders.leader2, leader3: projectLeaders.leader3 })} className="form-control etm_register_input" type="text" placeholder="Nombre del primer líder" />
                                                </InputGroup>
                                                <Label hidden={showLeader.leader2} className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => setShowLeader({ leader2: true, leader3: false })}>Agregar segundo líder +</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} hidden={!showLeader.leader2}>
                                            <FormGroup check>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group text-muted">Nombre líder 2</span>
                                                    </div>
                                                    <Input onChange={(e) => setProjectLeaders({ leader1: projectLeaders.leader1, leader2: e.target.value, leader3: projectLeaders.leader3 })} className="form-control etm_register_input" type="text" placeholder="Nombre del segundo líder" />
                                                </InputGroup>
                                                <Label hidden={showLeader.leader3} className="etm_link_blue mt-2 ml-4 pointer" onClick={(e) => setShowLeader({ leader2: true, leader3: true })}>Agregar tercer líder +</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-3" hidden={!showLeader.leader3}>
                                            <FormGroup check>
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group text-muted">Nombre líder 3</span>
                                                    </div>
                                                    <Input onChange={(e) => setProjectLeaders({ leader1: projectLeaders.leader1, leader2: projectLeaders.leader2, leader3: e.target.value })} className="form-control etm_register_input" type="text" placeholder="Nombre del tercer líder" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="animate__animated animate__fadeIn mt-5" >
                                <CardBody>
                                    {stepNumber(5, "Propósito e impacto social")}
                                    <Row className="mt-3">
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto considera objetivos de <span className="etm_text_blue">Impacto Social</span> ?
                                                    <br />
                                                    <small className="text-muted">( opcional, elige todas las que consideres de la lista o puedes crear nuevas opciones al escribirlas. )</small>
                                                </Label>
                                                <br />
                                                <Creatable
                                                    formatCreateLabel={e => `Crear nueva opción ${e}`}
                                                    placeholder={"Elige algunas opciones"}
                                                    value={projectImpacts}
                                                    onChange={setProjectImpacts}
                                                    options={listImpacts}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={150}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto considera algunos <span className="etm_text_blue">Objetivos de Desarrollo Sostenible</span> ( ODS ) ? 
                                                    <br />
                                                    <small className="text-muted">( opcional, elige una de las opciones o puedes crear una nueva opción al escribirla. )</small>
                                                </Label>
                                                <br />
                                                <Creatable
                                                    formatCreateLabel={e => `Crear nueva opción ${e}`}
                                                    placeholder={"Elige algunas opciones"}
                                                    value={projectOds}
                                                    onChange={setProjectOds}
                                                    options={listOds}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={150}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto es liderado por alguna <span className="etm_text_blue">Mujer</span> ? 
                                                    <br />
                                                    <small className="text-muted">( opcional )</small>
                                                </Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioMujer" type="radio" onClick={(e) => setProjectWomenLeader(e.target.value)} value={0} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioMujer" type="radio" onClick={(e) => setProjectWomenLeader(e.target.value)} value={1} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto considera políticas de <span className="etm_text_blue">Equidad de Género</span> ? 
                                                    <br />
                                                    <small className="text-muted">( opcional )</small>
                                                </Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioEquidad" type="radio" onClick={(e) => setProjectGenderEquality(e.target.value)} value={0} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioEquidad" type="radio" onClick={(e) => setProjectGenderEquality(e.target.value)} value={1} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tu proyecto considera políticas de <span className="etm_text_blue">Inclusión de Diversidad</span> ? 
                                                    <br />
                                                    <small className="text-muted">( opcional )</small>
                                                </Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioDiversidad" type="radio" onClick={(e) => setProjectDiversityInclusion(e.target.value)} value={0} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioDiversidad" type="radio" onClick={(e) => setProjectDiversityInclusion(e.target.value)} value={1} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                        : ""
                }

                {
                    (vistaDatos.option1 === true || vistaDatos.option2 === true) ?
                        <Fragment>
                            <Card className="animate__animated animate__fadeIn mt-5 mb-5">
                                <CardBody>
                                    {
                                        (vistaDatos.option1) ?
                                            stepNumber(4, "Etiquetas")
                                            : stepNumber(6, "Etiquetas")
                                    }
                                    <Row className="mt-3 mb-5">
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h5">
                                                    Para mejorar el match entre los expertos y los emprendimientos, puedes agregar <span className="etm_text_blue">etiquetas</span> a tus emprendimientos. Esto funciona similar a los hashtag de las redes sociales y facilitará generar las reuniones.
                                                    <br />
                                                    <small className="text-muted">Elige al menos 1 etiqueta ya existente o crea nuevas si necesitas. Solo escribe la etiqueta y listo.</small>
                                                </Label>
                                                <br />
                                                <Creatable
                                                    formatCreateLabel={e => `Crear etiqueta ${e}`}
                                                    placeholder={"Elige tus etiquetas"}
                                                    value={projectTag}
                                                    onChange={(e) => (e !== null) ? ((e.length <= 10) ? setProjectTag(e) : null) : setProjectTag(e)}
                                                    options={listTags}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={125}
                                                    isMulti
                                                />
                                                <br />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="animate__animated animate__fadeIn mt-5 mb-5">
                                <CardBody>
                                    {
                                        (vistaDatos.option1) ?
                                            stepNumber(5, "Por último...")
                                            : stepNumber(7, "Por último...")
                                    }
                                    <Row className="mt-3 mb-3">
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Te gustaría usar un apodo o nickname en <b>EtM</b><i className="etm_text_blue">meet</i> ?
                                                    <br />
                                                    <small className="text-muted">Esto solo será para las comunicaciones que te enviemos y no será visible para otros usuarios</small>
                                                </Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioNickname" type="radio" onClick={(e) => setPersonalNickname({ show: false, name: "" })} value="SIN NICKNAME" />{' '}No, solo por mi Nombre
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioNickname" type="radio" onClick={(e) => setPersonalNickname({ show: true, name: "" })} value="CON NICKNAME" />{' '}Si
                                                </Label>
                                                <Row hidden={!personalNickname.show} className="animate__animated animate__fadeIn">
                                                    <Col md={6}>
                                                        <Input className="form-control etm_register_input" type="text" onChange={(e) => setPersonalNickname({ show: personalNickname.show, name: e.target.value })} placeholder="Ingresa tu apodo o nickname" />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    Preséntate a tí mismo. 
                                                    <br />
                                                    <small className="text-muted">( máximo 400 caracteres. Esta descripción será parte de tu tarjeta visible para los otros usuarios. )</small>
                                                </Label>
                                                <br />
                                                <Input className="form-control etm_textarea" type="textarea" maxLength="400" rows="4" onChange={(e) => setPersonalDescription(e.target.value)} placeholder="Ejemplo. Hola! Soy Alan el encargado del hacer el match ..." />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5">
                                                    ¿ Tienes redes sociales ? 
                                                    <br />
                                                    <small className="text-muted">( opcional, ingrésalas solo si quieres. Puedes ingresar las RRSS de tu proyecto )</small>
                                                </Label>
                                                <br />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5"><small className="text-muted">Facebook</small></Label>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-facebook facebook"></i></span>
                                                    </div>
                                                    <Input onChange={(e) => setPersonalRrss({ facebook: cleanFacebook(e.target.value), instagram: personalRrss.instagram, twitter: personalRrss.twitter, youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Facebook" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5"><small className="text-muted">Instagram</small></Label>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-instagram instagram"></i></span>
                                                    </div>
                                                    <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: cleanInstagram(e.target.value), twitter: personalRrss.twitter, youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Instagram" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5"><small className="text-muted">Twitter</small></Label>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-twitter twitter"></i></span>
                                                    </div>
                                                    <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: personalRrss.instagram, twitter: cleanTwitter(e.target.value), youtube: personalRrss.youtube })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Twitter" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormGroup check>
                                                <Label className="h5"><small className="text-muted">Youtube</small></Label>
                                                <br />
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group"><i className="fab fa-youtube youtube"></i></span>
                                                    </div>
                                                    <Input onChange={(e) => setPersonalRrss({ facebook: personalRrss.facebook, instagram: personalRrss.instagram, twitter: personalRrss.twitter, youtube: e.target.value })} className="form-control etm_register_input" type="url" placeholder="Ingresa el link de tu Youtube" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">Sube una foto de tí o elige uno de nuestros avatares.</Label>
                                                <br />
                                                <div className="text-center">
                                                    <Label className="h5"><small className="mr-2">USAR AVATAR</small> | <small className="ml-2">CARGAR FOTO</small></Label>
                                                    <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="" bsSize="lg" checked={avatarType} onChange={() => setAvatarType(!avatarType)} />
                                                </div>
                                                <div className="animate__animated animate__fadeIn text-center" hidden={avatarType}>
                                                    <AvatarDefault setAvar={setPersonalProfilePicture} />
                                                </div>
                                                <div className="animate__animated animate__fadeIn text-center" hidden={!avatarType}>
                                                    <UploadImageFile
                                                        buttonText={ "Click aquí para subir una foto" }
                                                        pathS3={ pathS3("PROFILE") }
                                                        singleImage={ true }
                                                        maxFileNumber={ 1 }
                                                        handle={ setPersonalProfilePicture }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="animate__animated animate__fadeIn mt-5 mb-5">
                                <CardBody>
                                    {
                                        (vistaDatos.option1) ?
                                            stepNumber(6, "¡ Listo !")
                                            : stepNumber(8, "¡ Listo !")
                                    }
                                    <Row className="mt-3 mb-3">
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h4"><p>Estás a un solo paso de expandir tus redes y conectarte con pares improbables!</p></Label>
                                                <Label className="h5"><p className="etm_text_blue"><i className="fas fa-info-circle mr-2"></i>Todos los datos ingresados pueden ser modificados luego en el menú <b>Mi Perfil</b>.</p></Label>
                                                <br />
                                                <LoadBtn className="btn-lg"
                                                    handle={preSaveData}
                                                    loading={loading} title="¡ COMENZEMOS !" text="Guardando configuración..."
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Fragment>
                        : ""
                }

            </Container>
        </Col>
    );
}

export default (Step4Emprendedor);