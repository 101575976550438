import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col} from "reactstrap";
import "../../assets/scss/etm.scss";
import { API } from '../../helpers/axios';
import 'react-toastify/dist/ReactToastify.css';
import LoadScreen from "../../components/Utils/LoadScreen";
import { getLocalStorage } from "../../helpers/etmmeetUtils";
import QrScaner from "../../components/ControlAccess/QrScaner";
import AccreditorIdentifier from "../../components/ControlAccess/Accreditor_identifier";

const Index = () => {
    const ls = getLocalStorage('accreditor');

    const [loading, setLoading] = useState(true);
    const [accreditingUser, setAccreditingUser] = useState(null)

    useEffect(() => {
        setAccreditingUser(ls)
        setLoading(false)
    }, []);

    useEffect(() => {
        getAccreditingUserSession(ls)
    }, []);

    const getAccreditingUserSession = async (ls) => {
        if(ls !== undefined && ls !== null && ls.TOKEN !== '') {
            const res = await API.get('/controlAccess/getAccreditingUserSession', { params: { token: ls.TOKEN } });
            if (!res.data.state) {
                setAccreditingUser(null)
            }
        }
    }

    return (
        <Fragment>
            <div className="page-content pt-4">
                <Container fluid>
                    <Row>
                        <Col lg="12" className="pr-0 pl-0">
                            {

                                (loading) ?
                                    <LoadScreen />
                                    :
                                    ""
                            }
                            {
                                (accreditingUser === null) ?
                                    <AccreditorIdentifier
                                        setAccreditingUser={setAccreditingUser}
                                        setLoading={setLoading}
                                    />
                                    :
                                    <QrScaner
                                        setAccreditingUser={setAccreditingUser}
                                        setLoading={setLoading}
                                    />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment >
    );
}

export default Index;