import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import HoursList from "../../components/GiveHours/HoursList";
import { ToastContainer } from 'react-toastify';
import "../../assets/scss/etm.scss";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';

const GiveHours = () => {

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Desafís'} />
                    <ToastContainer />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="12" className="table-responsive">
                                    <HoursList showBtn={true} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </Fragment >
    );
}

export default withNamespaces()(GiveHours);