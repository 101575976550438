import React, { Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Apanio from "../../assets/images/etmmeet/landing/partners/Apanio.svg";
import Asech from "../../assets/images/etmmeet/landing/partners/Asech.svg";
import CapitalReputacional from "../../assets/images/etmmeet/landing/partners/CapitalReputacional.svg";
import Chile_Global from "../../assets/images/etmmeet/landing/partners/Chile_Global.svg";
import Chiletec from "../../assets/images/etmmeet/landing/partners/Chiletec.svg";
import Chrysalis from "../../assets/images/etmmeet/landing/partners/Chrysalis.png";
import Club_Innovación from "../../assets/images/etmmeet/landing/partners/Club_Innovación.svg";
import CORFO from "../../assets/images/etmmeet/landing/partners/CORFO.svg";
import CPC from "../../assets/images/etmmeet/landing/partners/CPC.svg";
import Digevo from "../../assets/images/etmmeet/landing/partners/Digevo.svg";
import Endeavor from "../../assets/images/etmmeet/landing/partners/Endeavor.svg";
import Foro_Innovación from "../../assets/images/etmmeet/landing/partners/Foro_Innovación.svg";
import FundacionChile from "../../assets/images/etmmeet/landing/partners/FundacionChile.svg";
import G100 from "../../assets/images/etmmeet/landing/partners/G100.svg";
import Icare from "../../assets/images/etmmeet/landing/partners/Icare.svg";
import Imagine from "../../assets/images/etmmeet/landing/partners/Imagine.svg";
import JRConsultores from "../../assets/images/etmmeet/landing/partners/JR_Consultores.svg";
import Marca_Chile from "../../assets/images/etmmeet/landing/partners/Marca_Chile.svg";
import MentoresChile from "../../assets/images/etmmeet/landing/partners/Mentores_por_Chile.svg";
import Mi_Colab from "../../assets/images/etmmeet/landing/partners/Mi_Colab.svg";
import Mujeres_Empresarias from "../../assets/images/etmmeet/landing/partners/Mujeres_Empresarias.svg";
import NND from "../../assets/images/etmmeet/landing/partners/NND.svg";
import OIM from "../../assets/images/etmmeet/landing/partners/OIM.svg";
import Ovalle from "../../assets/images/etmmeet/landing/partners/Ovalle.svg";
import People_Partners from "../../assets/images/etmmeet/landing/partners/People_Partners.svg";
import Prochile from "../../assets/images/etmmeet/landing/partners/Prochile.svg";
import SantiagoInnova from "../../assets/images/etmmeet/landing/partners/Santiago_Innova.png";
import SNA from "../../assets/images/etmmeet/landing/partners/SNA.svg";
import StartupChile from "../../assets/images/etmmeet/landing/partners/Startup_Chile.svg";
import Startups_Chilenas from "../../assets/images/etmmeet/landing/partners/Startups_Chilenas.svg";
import Vendomatica from "../../assets/images/etmmeet/landing/partners/Vendomatica.svg";
import DigevoVentures from "../../assets/images/etmmeet/landing/partners/DigevoVentures.svg";
import { isMobile } from '../../helpers/etmmeetUtils';

const SliderPartners = (props) => {
    const settingsPartners = {
        dots: true,
        infinite: true,
        slidesToShow: (isMobile()) ? 2 : 5,
        speed: (window.innerWidth <= 768) ? 4000 : 5000,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: "linear",
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
          ),
          customPaging: i => (
            <div></div>
          )
    };

    return (
        <Fragment>
            <Slider {...settingsPartners} className="sponsor_slider">
                <img className="sponsor_img_apanio" src={ Apanio } alt="pic1"/>
                <img className="sponsor_img_asech" src={ Asech } alt="pic1"/>
                <img className="sponsor_img_capital_reputacional" src={ CapitalReputacional } alt="pic1"/>  
                <img className="sponsor_img_chile_global" src={ Chile_Global } alt="pic1"/>
                <img className="sponsor_img_chiletec" src={ Chiletec } alt="pic1"/>
                <img className="sponsor_img_chrysalis" src={ Chrysalis } alt="pic1"/>
                <img className="sponsor_img_club_innovacion" src={ Club_Innovación } alt="pic1"/>
                <img className="sponsor_img_corfo" src={ CORFO } alt="pic1"/>
                <img className="sponsor_img_cpc" src={ CPC } alt="pic1"/>
                <img className="sponsor_img_digevo" src={ Digevo } alt="pic1"/>
                <img className="sponsor_img_digevo_ventures" src={ DigevoVentures } alt="pic1"/>
                <img className="sponsor_img_endeavor" src={ Endeavor } alt="pic1"/>
                <img className="sponsor_img_foro_innovacion" src={ Foro_Innovación } alt="pic1"/>
                <img className="sponsor_img_fundacion_chile" src={ FundacionChile } alt="pic1"/>
                <img className="sponsor_img_g100" src={ G100 } alt="pic1"/>
                <img className="sponsor_img_icare" src={ Icare } alt="pic1"/>
                <img className="sponsor_img_imagine" src={ Imagine } alt="pic1"/>
                <img className="sponsor_img_jrconsultores" src={ JRConsultores } alt="pic1"/>
                <img className="sponsor_img_marca_chile" src={ Marca_Chile } alt="pic1"/>
                <img className="sponsor_img_mentores_chile" src={ MentoresChile } alt="pic1"/>
                <img className="sponsor_img_mi_colab" src={ Mi_Colab } alt="pic1"/>
                <img className="sponsor_img_mujeres_empresarias" src={ Mujeres_Empresarias } alt="pic1"/>
                <img className="sponsor_img_nnd" src={ NND } alt="pic1"/>
                <img className="sponsor_img_oim" src={ OIM } alt="pic1"/>
                <img className="sponsor_img_ovalle_cl" src={ Ovalle } alt="pic1"/>
                <img className="sponsor_img_people_partners" src={ People_Partners } alt="pic1"/>
                <img className="sponsor_img_prochile" src={ Prochile } alt="pic1"/>
                <img className="sponsor_img_santiago_innova" src={ SantiagoInnova } alt="pic1"/>
                <img className="sponsor_img_sna" src={ SNA } alt="pic1"/>
                <img className="sponsor_img_startup_chile" src={ StartupChile } alt="pic1"/>
                <img className="sponsor_img_startups_chilenas" src={ Startups_Chilenas } alt="pic1"/>
                <img className="sponsor_img_vendomatica" src={ Vendomatica } alt="pic1"/>
            </Slider>
        </Fragment>
    );
}

export default (SliderPartners);