import React, { Fragment, useState } from 'react';
import { Row, Col } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Nav from "../../components/Landing/Nav";
import Sponsor from "../../components/Landing/Sponsor";
import Contact from "../../components/Landing/Contact";
import Footer from "../../components/Landing/Footer";
import Menu from "../../components/Landing/Menu";
import SliderStartup from "../../components/Landing/SliderStartup";
import HowDoIt from "../../components/Landing/HowDoIt";
import Experience from "../../components/Landing/Experience";
import Faqs from "../../components/Landing/Faqs";
import Container from 'reactstrap/lib/Container';

const LandingEmprendedores = (props) => {
    const[menu, setMenu] = useState(null);

    return (
        <Fragment>
            <Nav menu={ menu } setMenu={ setMenu }/>
            <Menu menu={ menu } setMenu={ setMenu } selectedMenu={ "emprendedor" }/>
            <Row className="landing_profile_header">
                <Col xs={12} className="pt-3">
                    <HowDoIt type={ "emprendedor" }/>
                </Col>
                <Col xs={12}>
                    <Experience type={ "emprendedor" }/>
                </Col>
            </Row>
            <Row className="landing_profile_testimonials">
                <Col xs={12} className="text-center pt-5">
                    <Container>
                        <p className="h1 testimonials_title">¡La fórmula sí funciona!</p>
                    </Container>
                </Col>
                <Col xs={12} className="text-center pt-3 pb-md-5 pb-0">
                    <Row className="pb-5">
                        <Col>
                            <SliderStartup />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="landing_profile_faq">
                <Col xs={12} className="pt-md-3 pt-0">
                    <Faqs type={ "emprendedor" }/>
                </Col>
            </Row>
            <Sponsor/>
            <Contact/>
            <Footer/>
        </Fragment>
    );
}

export default withNamespaces()(LandingEmprendedores);