import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import QRCode from "qrcode.react";
import { getLocalStorage } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadScreen from '../../components/Utils/LoadScreen';

const QR_profile = () => {
    const [stringQR, setStringQR] = useState("");
    const [qrSize, setQrSize] = useState(256);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getDataQR();
    }, []);

    const getDataQR = async () => {
        const res = await API.get('/getQrMobile', { params: { user_id: getLocalStorage().id } });
        if(res.data.state){
            setStringQR(res.data.url);
            // setStringQR(JSON.stringify(res.data.url));
        }
        setLoading(false);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'QR Tester'}/>
                <Container fluid>
                    <Card>
                        <Col xs={12} className="pt-3">
                            <Row>
                                <Col xs={12}>
                                    <h4 className="etm_text_blue mb-3"><i className="fas fa-qrcode mr-2"></i>QR | EtM<i>pass</i></h4>
                                    <h5 className="mb-3">Con este QR podrás acceder a todas las acitvidades de Emprende tu Mente</h5>
                                </Col>
                                <Col xs={12} className="p-3 text-center">
                                    {
                                        (loading) ?
                                            <LoadScreen text="Generando QR..."/>
                                        :   <QRCode id="qr" value={ stringQR } size={ qrSize } level="H" />
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(QR_profile);