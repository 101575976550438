import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, UncontrolledTooltip, Input, Label, Button, Card, CardTitle, CardText } from "reactstrap";

import LoadScreen from "../../Utils/LoadScreen";
import { getLocalStorage, validateRol } from "../../../helpers/etmmeetUtils";
import LoadBtn from "../../Utils/LoadBtn";
import { API } from '../../../helpers/axios';

import Cards from "../Cards/Cards"

const SearchGeneral = (props) => {

    const ls = getLocalStorage()

    const [searchKey, setSearchKey] = useState("");
    const [searchProfile, setSearchProfile] = useState({ emprendedor: true, emprendimiento: true, mentor: true, gerente: (ls.constituido === "SI") ? true : false, inversionista: (ls.constituido === "SI") ? true : false });

    const [dataSearch, setDataSearch] = useState([]);

    const [loadscreen, setLoadscreen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchStart, setSearchStart] = useState(false);

    useEffect(() => {
        setLoadscreen(true);
        filter();
    }, []);

    console.log(ls)

    const handleSearch = (e) => {
        e.preventDefault();
        setLoadscreen(true);
        setLoading(true);
        setCurrentPage(1);
        setSearchStart(true);
        filter();
    };

    const filter = async () => {
        let data = {
            searchKey,
            searchProfile,
            token: ls.token,
            start: 0,
            size: 99
        }

        const res = await API.post('/search/cloudsearch', data);
        if (res.data.state) {
            setDataSearch(res.data.data)
        } else {
            setDataSearch([])
        }

        setLoadscreen(false);
        setLoading(false);
    }

    return (
        <>
            <Row className="mr-1 ml-1">
                <Col lg={12} className="etm_search_border step-tour-filter">
                    <Form>
                        <Row className="mt-xl-2 mt-lg-2 mt-0 p-2" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Col lg={3} className="mb-2">
                                <FormGroup>
                                    <i className="fas fa-info-circle ml-3 mr-1 etm_text_blue mt-0 mb-0" id="input_key">
                                        <UncontrolledTooltip
                                            innerClassName="bg-info"
                                            delay={0}
                                            placement="bottom"
                                            target="input_key"
                                        >
                                            Filtra por alguna <b>PALABRA CLAVE</b>
                                        </UncontrolledTooltip>
                                    </i>
                                    <Label className="ml-1 mt-0 mb-0">Palabra Clave</Label>
                                    <Input name="input_key" className="form-control etm_input_text mt-0 mb-0" type="text" disabled={loadscreen} placeholder={(loadscreen) ? "Cargando..." : "Ingrese una palabra"} value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col lg={9} >
                                <FormGroup>
                                    <i className="fas fa-info-circle ml-3 mr-1 etm_text_blue mt-0 mb-0" id="input_profile">
                                        <UncontrolledTooltip
                                            innerClassName="bg-info"
                                            delay={0}
                                            placement="bottom"
                                            target="input_profile"
                                        >
                                            Filtra por algún <b>PERFIL</b>
                                        </UncontrolledTooltip>
                                    </i>
                                    <Label className="ml-1 mt-0 mb-0">
                                        Selecciona 1 o mas perfiles
                                        <br />
                                        {(validateRol(ls.rol) && ls.constituido === "SI") ? 
                                            `` 
                                            : <span style={{color:'#ffc107'}}> 
                                                Perfil <b>Gerente / Ejecutivo</b> e <b>Inversionista</b> desabilitados hasta que tengas un Emprendimiento constituido en
                                                <a href="/profile"><strong> Mi Perfil</strong></a>
                                                </span>}
                                    </Label>
                                    <br />
                                    <div 
                                        className="pt-2"
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat( auto-fit, minmax(min(100%, 10rem), 5fr) )',
                                            gridColumnGap: '1%',
                                            gridRowGap: '2%'
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                        }}>
                                            <FormGroup check>
                                                <Input
                                                    id="chkEmprendedor"
                                                    type="checkbox"
                                                    onChange={() => setSearchProfile({
                                                        emprendedor: !searchProfile.emprendedor,
                                                        emprendimiento: searchProfile.emprendimiento,
                                                        mentor: searchProfile.mentor,
                                                        gerente: searchProfile.gerente,
                                                        inversionista: searchProfile.inversionista
                                                    })}
                                                    checked={searchProfile.emprendedor}
                                                />
                                                {' '}
                                                <Label check for="chkEmprendedor">
                                                    Emprendedor
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                        }}>
                                            <FormGroup check>
                                                <Input
                                                    id="chkEmprendimiento"
                                                    type="checkbox"
                                                    onChange={() => setSearchProfile({
                                                        emprendedor: searchProfile.emprendedor,
                                                        emprendimiento: !searchProfile.emprendimiento,
                                                        mentor: searchProfile.mentor,
                                                        gerente: searchProfile.gerente,
                                                        inversionista: searchProfile.inversionista
                                                    })}
                                                    checked={searchProfile.emprendimiento}
                                                />
                                                {' '}
                                                <Label check for="chkEmprendimiento">
                                                    Emprendimiento
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                        }}>
                                            <FormGroup check>
                                                <Input
                                                    id="chkMentor"
                                                    type="checkbox"
                                                    onChange={() => setSearchProfile({
                                                        emprendedor: searchProfile.emprendedor,
                                                        emprendimiento: searchProfile.emprendimiento,
                                                        mentor: !searchProfile.mentor,
                                                        gerente: searchProfile.gerente,
                                                        inversionista: searchProfile.inversionista
                                                    })}
                                                    checked={searchProfile.mentor}
                                                />
                                                {' '}
                                                <Label check for="chkMentor">
                                                    Mentor
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                        }}>
                                            <FormGroup check>
                                                <Input
                                                    id="chkGerente"
                                                    type="checkbox"
                                                    onChange={() => setSearchProfile({
                                                        emprendedor: searchProfile.emprendedor,
                                                        emprendimiento: searchProfile.emprendimiento,
                                                        mentor: searchProfile.mentor,
                                                        gerente: !searchProfile.gerente,
                                                        inversionista: searchProfile.inversionista
                                                    })}
                                                    checked={searchProfile.gerente}
                                                    disabled={(validateRol(ls.rol) && ls.constituido === "SI") ? false : true}
                                                />
                                                {' '}
                                                <Label check for="chkGerente">
                                                    Gerente / Ejecutivo
                                                </Label>
                                            </FormGroup>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'left',
                                        }}>
                                            <FormGroup check>
                                                <Input
                                                    id="chkInversionista"
                                                    type="checkbox"
                                                    onChange={() => setSearchProfile({
                                                        emprendedor: searchProfile.emprendedor,
                                                        emprendimiento: searchProfile.emprendimiento,
                                                        mentor: searchProfile.mentor,
                                                        gerente: searchProfile.gerente,
                                                        inversionista: !searchProfile.inversionista
                                                    })}
                                                    checked={searchProfile.inversionista}
                                                    disabled={(validateRol(ls.rol) && ls.constituido === "SI") ? false : true}
                                                />
                                                {' '}
                                                <Label check for="chkInversionista">
                                                    Inversionista
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="d-flex justify-content-center">
                                <LoadBtn
                                    handle={handleSearch}
                                    loading={loading}
                                    title="Buscar"
                                    className="pl-5 pr-5"
                                    icon="search">
                                </LoadBtn>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col xl={12} className="scrolling_div mt-3 step-tour-search">
                    {
                        (loadscreen) ?
                            <LoadScreen text={"Buscando Gerentes, Ejecutivos e Inversionistas disponibles..."} />
                            :
                            (dataSearch.length > 0) ?
                                <Cards
                                    dataSearch={dataSearch}
                                    // searchStart={searchStart}
                                    // showMore={showMore}
                                    // dataSearchFilter={dataSearchFilter}
                                    // dataSearchMore={dataSearchMore}
                                    // loadingMoreBtn={loadingMoreBtn}
                                    urlUserType={props.urlUserType}
                                    setUrlUserType={props.setUrlUserType}
                                    urlUserToken={props.urlUserToken}
                                    setUrlUserToken={props.setUrlUserToken}
                                />
                                : <Card body >
                                    <CardTitle tag="h5">
                                        Sin resultados
                                    </CardTitle>
                                    <CardText>
                                        No se encontraorn resultados para la busqueda: {searchKey}
                                    </CardText>
                                </Card>
                    }
                </Col>
            </Row>
        </>
    );
}

export default SearchGeneral;