import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap";
import "../../assets/scss/etm.scss";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadScreen from "../../components/Utils/LoadScreen";
import { getLocalStorage, validateRol } from "../../helpers/etmmeetUtils";
import CardsChallenges from "../../components/Challenges/CardsChallenges";

const ListAll = () => {
    const ls = getLocalStorage();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
    }, []);


    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Desafís'} />
                    <ToastContainer />
                    {
                        (!loading) ?
                            <LoadScreen />
                            :
                            <CardsChallenges />
                    }                    
                </Container>
            </div>
        </Fragment >
    );
}

export default withNamespaces()(ListAll);