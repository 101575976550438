import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Button, Row, Col, Label, Container, Input, FormGroup, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { API } from '../../helpers/axios';
import { getLocalStorage, capitalize, swal_etm } from '../../helpers/etmmeetUtils';
import LoadScreen from "../Utils/LoadScreen";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import startupsDefault from "../../assets/images/etmmeet/general/default.png";

const ModalListParticipants = (props) => {

    const { SearchBar } = Search;
    const [loading, setLoading] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [totalParticipants, setTotalParticipants] = useState(0);

    useEffect(() => {
        if (props.section.list) {
            getListParticipants()
        }
    }, [props.section.list]);

    const closeSection = () => {
        props.setSection({ scanner: true, data: false, list: false })
    }

    const getListParticipants = async () => {
        const res = await API.get("/controlAccess/getListAttendee", {});
        let list = [];
        if (res.data.state) {
            setTotalParticipants(res.data.asistentes.length);
            res.data.asistentes.map((e, i) => {
                list.push({
                    correlative: i + 1
                    , attendee: <Fragment>
                        <div className="text-center">
                            <Label className='h5 mb-0 mt-2'>
                                {e.NOMBRE}
                                <br />
                                {props.colorByRol(e.PERFIL)}
                                <br />
                                <small>
                                    {
                                        (e.EMPRESA !== undefined && e.EMPRESA !== null && e.EMPRESA !== '') ?
                                            e.EMPRESA
                                            : ""
                                    }
                                </small>
                            </Label>
                        </div>
                    </Fragment>
                    , checkIn: <Fragment>
                        {e.FECHA}
                        <br /><br />
                        <Label className="h5">{e.HORA}</Label>
                    </Fragment>
                    , nombre: e.NOMBRE
                    , perfil: e.PERFIL
                    , empresa: e.EMPRESA
                });
            });
            setParticipants(list)
        }
    }

    const columns = [{
        dataField: 'correlative'
        , text: '#'
        , width: "1%"
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'attendee'
        , text: 'INVITADO'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'checkIn'
        , text: 'INGRESO'
        , width: "16%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'empresa'
        , text: 'EMPRESA'
        , hidden: true
        , headerStyle: () => {
            return { textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: { verticalAlign: 'middle' }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'perfil'
        , text: 'PERFIL'
        , hidden: true
        , headerStyle: () => {
            return { textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: { verticalAlign: 'middle' }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'nombre'
        , text: 'NOMBRE'
        , hidden: true
        , headerStyle: () => {
            return { textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: { verticalAlign: 'middle' }
        , headerClasses: 'etm_datatable_header'
    }];

    return (
        <Fragment>
            <Card hidden={!props.section.list}>
                <CardBody>
                    <div xs={12} className="d-flex justify-content-between mr-2 ml-2 mt-2 mb-0">
                        <Label className="h6"></Label>
                        <i
                            className="fas fa-times fa-2x text-danger"
                            onClick={() => closeSection()}
                        ></i>
                    </div>
                    {
                        (loading) ?
                            <LoadScreen text="Cargando solicitudes..." />
                            :
                            <Fragment>
                                <ToolkitProvider
                                    keyField="ID"
                                    data={participants}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <h4 className="mb-4 etm_text_blue" hidden={(totalParticipants > 0) ? false : true}>
                                                    <i className="fas fa-users mr-2"></i>
                                                    Cantidad actual de asistentes: <Badge color="primary" pill className="ml-2">{totalParticipants}</Badge>
                                                </h4>
                                                <SearchBar placeholder="Buscar..." className="etm_input_text" {...props.searchProps} />
                                                <BootstrapTable
                                                    keyField="correlative"
                                                    pagination={paginationFactory()}
                                                    {...props.baseProps}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Fragment>
                    }
                </CardBody>
            </Card>
        </Fragment >
    );
}

export default ModalListParticipants;