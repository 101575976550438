import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Input, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { API } from '../../helpers/axios';
import Switch from "react-switch";
import RatingTooltip from "react-rating-tooltip";
import { capitalize, swal_etm } from "../../helpers/etmmeetUtils";

 const ModalEvaluation = ( props ) => {
    
    const [showModal, setShowModal]                 = useState(false);
    const [nombrePlataforma, setNombrePlataforma]   = useState(<span>la plataforma <b>EtM<i className="etm_text_blue">meet</i></b></span>);
    const [meetId, setMeetId]                       = useState(0);
    const [isManager, setIsManager]                 = useState(0);
    
    const [managerData, setManagerData] = useState({
            id:         0
        ,   shortname:  ''
        ,   name:       ''
        ,   profile:    ''
        ,   photo:      ''
        ,   company:    ''
        ,   jobtitle:   ''
    });
    const [userData, setUserData]       = useState({
            id:         0
        ,   shortname:  ''
        ,   name:       ''
        ,   profile:    ''
        ,   photo:      ''
        ,   company:    ''
        ,   jobtitle:   ''
        ,   startup:    ''
    });

    const [contact, setContact]                 = useState(true);
    const [tooltipContent, setTooltipContent]   = useState(["Nota 1", "Nota 2", "Nota 3", "Nota 4", "Nota 5", "Nota 6", "Nota 7"]);
    const [ratingUser, setRatingUser]           = useState(0);
    const [feedback, setFeedback]               = useState(0);
    const [ratingMeet, setRatingMeet]           = useState(0);
    const [comments, setComments]               = useState('');
   
    useEffect(() => { 
        getMeetData();
    }, [props.meetData]);

    useEffect(() => { 
        setShowModal(props.showModal);
    }, [props.showModal]);

    const getMeetData = () => {
        if(props.meetData.MEET_ID !== undefined && props.meetData.MEET_ID !== null){
            setMeetId(props.meetData.MEET_ID);
            setIsManager(props.meetData.IS_MANAGER);
            setManagerData({
                    id:         props.meetData.MANAGER_ID
                ,   shortname:  (props.meetData.MANAGER_SHORTNAME.length > 0)   ? props.meetData.MANAGER_SHORTNAME  : ""
                ,   name:       (props.meetData.MANAGER_NAME.length > 0)        ? props.meetData.MANAGER_NAME       : ""
                ,   profile:    (props.meetData.MANAGER_PROFILE.length > 0)     ? props.meetData.MANAGER_PROFILE    : ""
                ,   photo:      (props.meetData.MANAGER_PHOTO.length > 0)       ? props.meetData.MANAGER_PHOTO      : ""
                ,   company:    (props.meetData.MANAGER_COMPANY.length > 0)     ? props.meetData.MANAGER_COMPANY    : ""
                ,   jobtitle:   (props.meetData.MANAGER_JOBTITLE.length > 0)    ? props.meetData.MANAGER_JOBTITLE   : ""
            });
            setUserData({
                    id:         props.meetData.USER_ID
                ,   shortname:  (props.meetData.USER_SHORTNAME.length > 0)   ? props.meetData.USER_SHORTNAME  : ""
                ,   name:       (props.meetData.USER_NAME.length > 0)       ? props.meetData.USER_NAME      : ""
                ,   profile:    (props.meetData.USER_PROFILE.length > 0)    ? props.meetData.USER_PROFILE   : ""
                ,   photo:      (props.meetData.USER_PHOTO.length > 0)      ? props.meetData.USER_PHOTO     : ""
                ,   company:    (props.meetData.USER_COMPANY.length > 0)    ? props.meetData.USER_COMPANY   : ""
                ,   jobtitle:   (props.meetData.USER_JOBTITLE.length > 0)   ? props.meetData.USER_JOBTITLE  : ""
                ,   startup:    (props.meetData.STARTUP.length > 0)         ? props.meetData.STARTUP        : ""
            });
        }
    }

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }} > {" "}No</div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }} > {" "}Si</div>
        );
    };
    
    const handleQuestions = async () => {
        handleModal();
        const res = await API.post('/send_responses', {
                user_id:            (isManager === 1) ? managerData.id : userData.id
            ,   meet_id:            meetId
            ,   type:               isManager
            ,   personal_rating:    ratingUser
            ,   contact:            contact
            ,   platform_rating:    ratingMeet
            ,   comments:           comments
            ,   feedback:           feedback
        });
        if (res.data.state) {
            swal_etm(`<p>Hemos recibido tus respuestas las cuales nos servirán para seguir mejorando <b>EtM<i style="color: #1b9dd9;">meet</i></b></p>`, `success`, `Gracias por tu opinión`);
        } else {
            swal_etm(`<p>Ha ocurrido un problema al registrar tus respuesta.</p><p>Favor contacta con el equipo de <b>EtM<i style="color: #1b9dd9;">meet</i></b></p>`, `warning`, ``);
        }
    }

    return (
        <Fragment>
            <Modal isOpen={ showModal } size="lg" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                {
                                    isManager == 1 ?
                                        <Fragment>
                                            <h4>Hola <b>{ capitalize(managerData.shortname) }</b>!</h4>
                                            <h4>Cuéntanos, ¿cómo te fue en tu reunión con <b>{ capitalize(userData.name) }</b>?</h4>
                                            <div className="profile-user-wid text-center pt-4">
                                                <img src={ userData.photo } alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"/> 
                                                <h3 className="mt-1 mb-1 etm_text_blue">{ capitalize(userData.name) }</h3>
                                                {
                                                    (userData.profile === "EMPRENDEDOR") ?
                                                        <h5 className="mt-0 mb-0 text-muted">{ capitalize(userData.startup) }</h5>
                                                    :   <h5 className="mt-0 mb-0 text-muted">{ `${capitalize(userData.jobtitle)} en ${capitalize(userData.company)}` }</h5>
                                                }
                                                <h5 className="mt-0 mb-0 text-muted">{ capitalize(userData.profile) }</h5>
                                            </div>
                                        </Fragment>
                                    :   <Fragment>
                                            <h4>Hola <b>{ capitalize(userData.shortname) }</b>!</h4>
                                            <h4>Cuéntanos, ¿cómo te fue en tu reunión con <b>{ capitalize(managerData.name) }</b>?</h4>
                                            <div className="profile-user-wid text-center pt-4">
                                                <img src={ managerData.photo } alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"/> 
                                                <h3 className="mt-1 mb-1 etm_text_blue">{ capitalize(managerData.name) }</h3>
                                                <h5 className="mt-0 mb-0 text-muted">{ `${capitalize(managerData.jobtitle)} en ${capitalize(managerData.company)}` }</h5>
                                                <h5 className="mt-0 mb-0 text-muted">{ capitalize(managerData.profile) }</h5>
                                            </div>
                                        </Fragment>
                                }
                            </div>
                            <hr />
                        </Col>
                        <Col lg={12}>
                            
                            <div style={{ zIndex: 100 }} className="p-3 text-center">
                                {
                                    isManager == 1 ? 
                                        <h5 className="font-16">Tú o algún referido tuyo, ¿Mantendrán contacto con <b>{ capitalize(userData.name) }</b>?</h5>
                                    :   <h5 className="font-16">Mantendrás contacto con <b>{ capitalize(managerData.name) }</b> o algún referido de él?</h5>
                                }
                                <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="mr-1"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => setContact(!contact)}
                                    checked={contact}
                                />
                            </div>

                            <div className="p-3 text-center">
                                {
                                    isManager == 1 ? 
                                        <h5 className="font-16">¿Cómo evalúas el Pitch de <b>{ capitalize(userData.name) }</b>?</h5>
                                    :   <h5 className="font-16">¿Cómo evalúas la participación de <b>{ capitalize(managerData.name) }</b>?</h5>
                                }
                                <RatingTooltip
                                    max={5} 
                                    tooltipContent={tooltipContent}
                                    ratingValue={ratingUser}
                                    onChange={value => setRatingUser(value)}
                                    ActiveComponent={
                                        <i
                                            key={"active_6"}
                                            className="mdi mdi-star text-primary"
                                        />
                                    }
                                    InActiveComponent={
                                        <i
                                            key={"active_06"}
                                            className="mdi mdi-star-outline text-muted"
                                        />
                                    }
                                />
                            </div>

                            {
                                isManager == 1 ? 
                                    <div className="p-3 text-center">
                                        <h5 className="font-16 m-b-15">¿Con qué nota evalúas la escalabilidad del proyecto de <b>{ capitalize(userData.name) }</b>?</h5>
                                        <RatingTooltip
                                            max={5} 
                                            tooltipContent={tooltipContent}
                                            ratingValue={ratingUser}
                                            onChange={value => setFeedback(value)}
                                            ActiveComponent={
                                                <i
                                                    key={"active_6"}
                                                    className="mdi mdi-star text-primary"
                                                />
                                            }
                                            InActiveComponent={
                                                <i
                                                    key={"active_06"}
                                                    className="mdi mdi-star-outline text-muted"
                                                />
                                            }
                                        />
                                    </div>
                                :   ""
                            }

                            <div className="p-3 text-center">
                                <h5 className="font-16 m-b-15">Con qué nota evaluarías { nombrePlataforma }?</h5>
                                <RatingTooltip
                                    max={5}
                                    tooltipContent={tooltipContent}
                                    ratingValue={ratingMeet}
                                    onChange={value => setRatingMeet(value)}
                                    ActiveComponent={
                                        <i
                                            key={"active_6"}
                                            className="mdi mdi-star text-primary"
                                        />
                                    }
                                    InActiveComponent={
                                        <i
                                            key={"active_06"}
                                            className="mdi mdi-star-outline text-muted"
                                        />
                                    }
                                />
                            </div>

                            <div className="p-3 text-center">
                                <h5 className="font-16 m-b-15">Dános tu feedback sobre { nombrePlataforma }</h5>
                                <Input
                                    type="textarea"
                                    onChange={(e) => setComments(e.target.value)}
                                    maxLength="225"
                                    rows="2"
                                    className="etm_textarea"
                                />
                            </div>

                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleModal } size="lg" className="etm_btn pl-3 pr-3"><i className="fas fa-times mr-2"></i>Omitir Encuesta</Button>
                    <Button color="primary" onClick={ handleQuestions } size="lg" className="etm_btn pl-3 pr-3"><i className="fas fa-paper-plane mr-2"></i>Enviar Respuestas</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalEvaluation);