import React, { Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BCI from "../../assets/images/etmmeet/landing/sponsor/BCI.svg";
import CLA from "../../assets/images/etmmeet/landing/sponsor/CLA.svg";
import EY from "../../assets/images/etmmeet/landing/sponsor/EY.svg";
import GarcesFruit from "../../assets/images/etmmeet/landing/sponsor/GarcesFruit.svg";
import SBPay from "../../assets/images/etmmeet/landing/sponsor/SBPay.svg";
import Transbank from "../../assets/images/etmmeet/landing/sponsor/Transbank.svg";
import Vantrust from "../../assets/images/etmmeet/landing/sponsor/Vantrust.svg";
import WindCopec from "../../assets/images/etmmeet/landing/sponsor/WindCopec.svg";
import BCIseguros from "../../assets/images/etmmeet/landing/sponsor/BCIseguros.svg";
import { isMobile } from '../../helpers/etmmeetUtils';

const SliderSponsor = (props) => {
    const settingsSponsor = {
        dots: true,
        infinite: true,
        slidesToShow: (isMobile()) ? 1 : 3,
        speed: (window.innerWidth <= 768) ? 4000 : 5000,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: "linear",
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
          ),
          customPaging: i => (
            <div></div>
          )
    };

    return (
        <Fragment>
            <Slider {...settingsSponsor} className="sponsor_slider">
                <img className="sponsor_img_bci" src={ BCI } />
                <img className="sponsor_img_cla" src={ CLA }/>
                <img className="sponsor_img_ey" src={ EY }/>
                <img className="sponsor_img_garces" src={ GarcesFruit }/>
                <img className="sponsor_img_sbpay" src={ SBPay }/>
                <img className="sponsor_img_transbank" src={ Transbank }/>
                <img className="sponsor_img_vantrust" src={ Vantrust }/>
                <img className="sponsor_img_copec" src={ WindCopec }/>
                <img className="sponsor_img_bci_seguros" src={ BCIseguros } />
            </Slider>
        </Fragment>
    );
}

export default (SliderSponsor);


