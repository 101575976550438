import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input } from "reactstrap";
import { capitalize, swal_etm, getDate, spanishDate, selectCustomStyle, getLocalStorage, validateRol } from '../../../helpers/etmmeetUtils';
import { API } from '../../../helpers/axios';
import LoadBtn from '../../Utils/LoadBtn';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import Select from 'react-select';

const ModalReagendar = (props) => {
    const flecha = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;
    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate() + 3);
    strikeDate.setDate(strikeDate.getDate() + 2);

    const [loading, setLoading] = useState(false);
    const [listHours, setListHours] = useState([]);
    const [listReason, setListReason] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedReason, setSelectedReason] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [data, setData] = useState({ title: "", text: "", icon: "", textLoading: "", color: "" });
    const [showRebooking, setShowRebooking] = useState(false);
    const [showReason, setShowReason] = useState(false);

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    useEffect(() => {
        getRebookingReason();
        getHours();
    }, []);

    useEffect(() => {
        if (props.datos.new_meeting === true && props.datos.usu_token !== null) {
            setShowRebooking(true);
            setShowReason(false);
        } else {
            setShowRebooking(false);
            setShowReason(true);
        }
    }, [props.datos.usu_token, props.datos.new_meeting]);

    useEffect(() => {
        if (props.datos.cancel) {
            setShowRebooking(false);
            setShowReason(true);
        } else {
            setShowRebooking(true);
            setShowReason(true);
        }
    }, [props.datos.cancel]);

    useEffect(() => {
        if (props.datos.cancel) {
            if (showRebooking) {
                setData({ title: "Reagendar reunión", text: "Reagendar", icon: "calendar-alt fa-lg", textLoading: "Reagendando...", color: "warning" });
            } else {
                setData({ title: "Cancelar reunión", text: "Cancelar", icon: "times fa-lg", textLoading: "Cancelando...", color: "danger" });
            }
        } else {
            if (props.datos.new_meeting) {
                setData({ title: "Nueva reunión", text: "Agendar nueva reunión", icon: "calendar-alt fa-lg", textLoading: "Agendando...", color: "success" });
            } else {
                setData({ title: "Reagendar reunión", text: "Reagendar", icon: "calendar-alt fa-lg", textLoading: "Reagendando...", color: "warning" });
            }
        }
    }, [props.datos.cancel, showRebooking, props.datos.usu_token]);

    const getHours = async (e) => {
        const res = await API.get('/meeting/getHoursList');
        let list = [];
        if (res.data.state) {
            res.data.hours.map((e, i) => {
                list.push({ value: e.HORA_INICIO, label: e.HORA_INICIO });
            });
        }
        setListHours(list);
    }

    const getRebookingReason = async (e) => {
        const res = await API.get('/meeting/getRebookingReasonList');
        let list = [];
        if (res.data.state) {
            res.data.reasons.map((e, i) => {
                list.push(
                    <FormGroup check key={i}>
                        <Label className="h5">
                            <Input type="radio" name="reason_check" onClick={() => setSelectedReason(e.MOTIVO_ID)} key={i} />{' '}{e.MOTIVO}
                        </Label>
                    </FormGroup>
                );
            });
        }
        setListReason(list);
    }

    const changeDay = (day) => {
        setSelectedDate(day);
    }

    const preRebook = () => {
        setLoading(true);

        if (showRebooking) {
            if (getDate(selectedDate) < getDate(nextFreeDay)) {
                swal_etm('Debes seleccionar uno de los <b class="etm_text_blue">días válidos</b>');
                setLoading(false);
                return;
            }

            if (selectedHour === null || selectedHour.value.length < 1) {
                swal_etm('Debes seleccionar un <b class="etm_text_blue">horario válido</b>');
                setLoading(false);
                return;
            }
        }

        if (!props.datos.new_meeting) {
            if (selectedReason === null || selectedReason === 0) {
                swal_etm(`Debes seleccionar un <b class="etm_text_blue">motivo válido</b> para ${(props.datos.cancel) ? "cancelar" : "reagendar"}`);
                setLoading(false);
                return;
            }
        }else{
            if(selectedDuration === null && selectedDuration < 20){
                swal_etm(`Debes seleccionar una <b class="etm_text_blue">duración de reunión válida</b> para la nueva reunión`);
                setLoading(false);
                return;
            }
        }

        postRebook();
    }

    const postRebook = async () => {
        let res = "";

        if (props.datos.new_meeting) {
            res = await API.post(`/meeting/makeNewMeeting`, {
                token: getLocalStorage().token
                , day: getDate(selectedDate)
                , hour: selectedHour.value
                , duration: selectedDuration
                , user_token: props.datos.usu_token
                , meetingType: (validateRol(['INVERSIONISTA', 'GERENTE/EJECUTIVO'])) ? "COMERCIAL" : "MENTORIA"
            });
        }else{
            res = await API.put(`/meeting/rebookMeeting`, {
                token: getLocalStorage().token
                , meet_id: props.datos.meet
                , request_id: props.datos.request
                , day: getDate(selectedDate)
                , hour: (selectedHour !== null) ? selectedHour.value : "" 
                , reason: selectedReason
                , cancel: (props.datos.cancel) ? 1 : 0
                , rebooking: (showRebooking) ? 1 : 0
            });
        }

        if (res.data.state) {
            swal_etm(res.data.msg, 'success');
        } else {
            swal_etm(res.data.msg);
        }
        setLoading(false)
        props.getUsers();
        props.setModal(false);
    }

    return (
        <Fragment>
            <Modal isOpen={props.modal} size="lg" scrollable={true} >
                <ModalHeader className="etm_modal_header">
                    <Label className="h4 ml-3 text-white">{data.title}</Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    <Container>
                        <Row>
                            <Col sm={12} className="mb-4" hidden={(showReason && !props.datos.new_meeting) ? false : true}>
                                <FormGroup check>
                                    <Label className="h4">
                                        {flecha}
                                        Indícanos el motivo por el cual necesitas <span className="etm_text_blue">{data.text}</span> la reunión.<br /><span className="ml-4">Nosotros le notificaremos al otro participante.</span>
                                    </Label>
                                    <Row>
                                        <Col sm={12}>
                                            <br />
                                            <div className="ml-4">
                                                {listReason}
                                            </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col sm={12} className="mb-5" hidden={(showReason && props.datos.cancel) ? false : true}>
                                <FormGroup check>
                                    <hr />
                                    <div className="custom-control custom-checkbox">
                                        <Input type="checkbox" className="custom-control-input input-mini" id="section" onChange={() => setShowRebooking(!showRebooking)} checked={showRebooking} />
                                        <Label htmlFor="section" className="custom-control-label">{""}</Label>
                                        <Label className="h4">
                                            <span className="etm_link_gray pointer" onClick={() => setShowRebooking(!showRebooking)}>¿ Quieres reagendar esta reunión ?</span>
                                            <br />
                                            <small>Sujeto a disponibilidad del otro participante</small>
                                        </Label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col lg={7} md={12} hidden={(showRebooking) ? false : true}>
                                <FormGroup check>
                                    <Label className="h4">{flecha}Elige el día y hora para la nueva fecha</Label>
                                </FormGroup>
                                <br />
                                <div className="calendar text-center">

                                    {/* Controls */}
                                    <div className="controls">
                                        <div>
                                            <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                            <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                            <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                        </div>
                                    </div>

                                    {/* Calendar head with day names */}
                                    <div className="calendar_head">
                                        {
                                            shortDayNames.map(day => (
                                                <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                    {spanishDate(day)}
                                                </div>
                                            ))
                                        }
                                    </div>

                                    {/* Calendar */}
                                    <div className="calendar_body">
                                        {
                                            items.map(day => {
                                                const activeClass = !day.active ? "inactive" : "";
                                                let selectedClass = day.selected ? "selected" : "";
                                                // const strike = (day.fullDate < strikeDate) ? "inactive" : "inactive";
                                                const strike = (day.fullDate < strikeDate) ? "etm_calendar_strike_text" : "";
                                                return (
                                                    <div
                                                        className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                        key={day.fullDate}
                                                        onClick={() => {
                                                            selectDate(day.fullDate);
                                                            // setSelectedDate(day.fullDate);
                                                            changeDay(day.fullDate);
                                                        }}
                                                    >
                                                        {day.date}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <br />
                                <div className="text-center">
                                    <Label className="h6 text-muted"><i className="fas fa-globe-americas mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                </div>
                            </Col>
                            <Col lg={5} md={12} className="mb-5" hidden={(showRebooking) ? false : true}>
                                <FormGroup check className="mb-5">
                                    <Label className="h4">{flecha}Elige un horario</Label>
                                    <br />
                                    <br />
                                    <Select
                                        placeholder="Elige uno de los horarios disponibles"
                                        value={selectedHour}
                                        onChange={setSelectedHour}
                                        options={listHours}
                                        className="etm_input_select mb-5"
                                        styles={selectCustomStyle()}
                                    />
                                </FormGroup>
                                <FormGroup check className="mb-5" hidden={(props.datos.new_meeting) ? false : true}>
                                    <Label className="h4">
                                        {flecha}Elige la duración de la reunión<br/>
                                        <small>Sujeto a disponibilidad del otro participante</small>
                                    </Label>
                                    <br />
                                    <br />
                                    <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 20)? `active` : ``}`} onClick={() => setSelectedDuration(20)}>
                                        <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>20 minutos</Label>
                                    </div>
                                    <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 30)? `active` : ``}`} onClick={() => setSelectedDuration(30)}>
                                        <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>30 minutos</Label>
                                    </div>
                                    <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 45)? `active` : ``}`} onClick={() => setSelectedDuration(45)}>
                                        <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>45 minutos</Label>
                                    </div>
                                    <div className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center ${(selectedDuration === 60)? `active` : ``}`} onClick={() => setSelectedDuration(60)}>
                                        <Label className="h5 mt-2 pointer"><i className="fas fa-clock text-muted mr-2 pointer"></i>1 hora</Label>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                    <LoadBtn loading={loading} color={data.color} handle={preRebook} className="pl-4 pr-4 btn-lg" icon={data.icon} title={data.text} text={data.textLoading} />
                </ModalFooter>
            </Modal >
        </Fragment >
    );
}


export default ModalReagendar;