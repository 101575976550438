import React, { Fragment, useEffect, useState } from 'react';
import IframeResizer from 'iframe-resizer-react'
import { isMobile } from '../../helpers/etmmeetUtils';

const Header = (props) => {
    const domain    = "https://ubiqq.com/";
    const url       = "https://ubiqq.com/etm/live?v=";
    const vh        =   (isMobile()) ? '130vh' : '150vh'

    const[externalHtml, setExternalHtml] = useState("");
    const[renderHtml, setRenderHtml] = useState("");

    // useEffect(() => {
    //     getExternalHtml();
    // }, []);
    
    // useEffect(() => {
    //     createNewHtml();
    // }, [externalHtml]);

    // const getExternalHtml = async () => {
    //     fetch(externalUrl)
    //         .then(function(response) {
    //             return response.text()
    //         })
    //         .then(function(html) {
    //             var parser  = new DOMParser();
    //             var doc     = parser.parseFromString(html, "text/html");
    //             var doc     = doc.querySelector('html').innerHTML;
    //             setExternalHtml(doc);
    //         })
    //         .catch(function(err) {  
    //             console.log('Failed to fetch page: ', err);  
    //         });
    // }

    // const createNewHtml = () => { 
        
    // }

    return (
        <Fragment >
            <IframeResizer
                autoResize={ true }
                src={ url }
                heightCalculationMethod="lowestElement"
                style={{ width: '100%', minWidth: '100%', height: vh, border: 'none', display: 'block', justifyContentCenter: 'center' }}
                display="initial"
                position="relative"
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                allow={`camera ${domain}; microphone ${domain}; fullscreen ${domain}`}
            />
        </Fragment>
        // <Fragment >
        //     <Row className="header_title mt-xl-3 mt-lg-0 mt-0 ">
        //         <Container className="header_body my-auto">
        //             <Row className="justify-content-center ">
        //                 <Col lg={6} md={12} className="my-auto text-lg-left text-center animate__animated animate__zoomIn pt-lg-0 pt-md-3 pt-0">
        //                     <p className="h2 header_txt_title mt-lg-0 mt-5 pt-lg-0 pt-5 mt-0 pb-lg-0 pb-md-3 pb-3">Generamos oportunidades conectando al ecosistema del emprendimiento</p>
        //                     <p className="h5 header_txt_subtitle pb-lg-0 pb-3"><b>Agenda reuniones GRATIS y descubre redes</b></p>
        //                 </Col>
        //                 <Col lg={6} md={12} className="my-auto text-center animate__animated animate__fadeInRight">
        //                     <img className="header_img img-fluid mt-lg-0 mt-md-3 mt-5 pb-lg-0 pb-5 ml-md-5 ml-0" src={ Logo } alt="etmmeet"/>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </Row>
        // </Fragment>
    );
}

export default (Header);


