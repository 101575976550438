import React, { useState, useEffect, Fragment } from 'react';
import { QrReader } from 'react-qr-reader';

import { Row, Col, Card, CardBody, CardTitle, Badge, CardSubtitle, Label, UncontrolledTooltip, FormGroup, Input, Button } from "reactstrap";
import { API } from '../../helpers/axios';
import "../../assets/scss/etm.scss";
import usuario from "../../assets/images/conecTime/design/Perfiles/PERFIL_ADMINISTRADOR.png";

import { capitalize, swal_etm, formatRut, isMobile } from "../../helpers/etmmeetUtils";

import AttendeeData from "../ControlAccess/AttendeeData";
import ListParticipants from "../ControlAccess/ListParticipants";

const QrScaner = (props) => {
    const ls = JSON.parse(localStorage.getItem('accreditor'));

    const [loading, setLoading] = useState(false);
    const [userToken, setUserToken] = useState(null)
    const [attendeeRun, setAttendeeRun] = useState('')
    const [selectFacingMode, setSelectFacingMode] = useState('user')
    const [listCams, setListCams] = useState('user')
    const [camId, setCamId] = useState('')
    const [section, setSection] = useState({ scanner: true, data: false, list: false });
    const [textCard, setTextCard] = useState({ title: "", btn: "" })
    const [data, setData] = useState({
        nombre: ''
        , apellido: ''
        , nombre_completo: ''
        , dni: ''
        , email: ''
        , telefono: ''
        , foto: ''
        , rol: ''
        , compañia: ''
        , compañia_foto: ''
        , cargo: ''
        , emprendimientos: ''
        , isEtmMeet: 0
        , isInvited: 0
        , token_event: ''
        , token_meet: ''
    });

    

    useEffect(() => {
        if (!section.data) {
            setData({
                nombre: ''
                , apellido: ''
                , nombre_completo: ''
                , dni: ''
                , email: ''
                , telefono: ''
                , foto: ''
                , rol: ''
                , empresa: ''
                , empresa_foto: ''
                , cargo: ''
                , emprendimientos: ''
                , isEtmMeet: 0
                , isInvited: 0
                , token_event: ''
                , token_meet: ''
            })
            setUserToken(null)
            setAttendeeRun('')
        }
    }, [section.data]);

    useEffect(() => {
        validateCamPermision()
    }, []);

    useEffect(() => {
        if (userToken !== null) {
            preFindUserByToken()
        }
    }, [userToken]);

    const validateCamPermision = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        let list = [<option value="">Select camera</option>]
        videoDevices.map(videoDevice => {
            list.push(<option key={videoDevice.deviceId} value={videoDevice.deviceId}>{videoDevice.label}</option>);
        });

        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(function (mediaStream) {
            console.log("Permisos de video y audio ok");
        }).catch(function (err) {
            console.log(err);
            console.log("Permisos de video y audio incorrectos");
        });

        setListCams(list)
    }
    
    const preFindUserByToken = () => {
        props.setLoading(true)
        setLoading(true)
        findUserByToken()
    }

    const findUserByToken = async () => {
        const res = await API.get('/controlAccess/getUserByToken', { params: { token: userToken } });
        if (res.data.state) {
            setTextCard({ title: "", btn: "CHECK IN" });
            setData({
                nombre: res.data.usuario.NOMBRE
                , apellido: res.data.usuario.APELLIDO
                , nombre_completo: res.data.usuario.NOMBRE_COMPLETO
                , dni: res.data.usuario.DNI
                , email: res.data.usuario.EMAIL
                , telefono: res.data.usuario.TELEFONO
                , foto: res.data.usuario.FOTO
                , rol: res.data.usuario.ROL
                , empresa: res.data.usuario.COMPANIA
                , empresa_foto: res.data.usuario.COMPANIA_FOTO
                , cargo: res.data.usuario.CARGO
                , emprendimientos: res.data.usuario.EMPRENDIMIENTOS
                , isEtmMeet: res.data.usuario.isEtmMeet
                , isInvited: res.data.usuario.isInvited
                , token_event: res.data.usuario.token_event
                , token_meet: res.data.usuario.token_meet
            });
        } else {
            setTextCard({ title: "RUT no registrado, acredítalo aquí", btn: "REGISTRAR" });
        }
        setSection({ scanner: false, data: true, list: false });
        props.setLoading(false)
    }

    const terminar = () => {
        props.setAccreditingUser(null)
        localStorage.removeItem('accreditor');
    }

    const findAttendee = () => {
        setLoading(true)
        const json = JSON.stringify({ run: attendeeRun })
        setUserToken(json)
    }

    const selectCam = (cam) => {
        console.log('cam: ', cam)
        setCamId(cam)
    }

    const colorByRol = (rol) => {
        let color = ''
        switch (rol) {
            case 'EMPRENDEDOR':
                color = "success"
                break;
            case 'GERENTE':
                color = "primary"
                break;
            case 'INVERSIONISTA':
                color = "primary"
                break;
            case 'EJECUTIVO':
                color = "primary"
                break;
            case 'MENTOR':
                color = "primary"
                break;
            case 'PRENSA':
                color = "info"
                break;
            case 'ESTUDIANTE':
                color = "info"
                break;
            case 'STAND EMPRENDEDOR':
                color = "success"
                break;
            case 'STAND ECOSISTEMA':
                color = "warning"
                break;
            case 'SPEAKER':
                color = "danger"
                break;
            case 'AUSPICIADOR':
                color = "danger"
                break;
            default:
                color = "secondary"
                break;
        }

        return <Badge
            color={color}
            pill
        >
            {capitalize(rol)}
        </Badge>
    }

    const keyPressSearchRut = async (e) => {
        if(e.key === 'Enter'){
            findAttendee();
        }
    }

    return (
        <Fragment>
            <Card hidden={!section.scanner}>
                <CardTitle className="mt-3 ml-3  mr-3 mb-0 text-left">
                    <Row className="d-flex justify-content-between">
                        <Col xs={12}>
                            <Label className="h6">
                                <img
                                    src={usuario}
                                    alt={ls.NOMBRE_COMPLETO}
                                    className="img-fluid avatar-sm img-thumbnail rounded-circle mr-2 "
                                    style={{ border: "solid 1px" }}
                                />
                                {ls.NOMBRE_COMPLETO}
                            </Label>
                        </Col>
                        <Col xs={12}>
                            <Input
                                type="select"
                                name="cams"
                                onChange={(e) => selectCam(e.target.value)}
                                className="etm_input_select"
                                hidden
                            >
                                {listCams}
                            </Input>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-between">
                        <Col xs={8} className="pl-3 pr-1">
                            <Button
                                block
                                name="listado"
                                id="listado"
                                onClick={() => setSection({ scanner: false, data: false, list: true })}
                                color="info"
                                className="btn_etm btn-sm"
                            >
                                <i className="fas fa-list mr-2"></i>Listado asistentes
                            </Button>
                        </Col>
                        <Col xs={4} className="pl-1 pr-3">
                            <Button
                                block
                                outline
                                name="terminar"
                                id="terminar"
                                onClick={() => terminar()}
                                color="danger"
                                className="btn btn-sm"
                            >
                                <i className="fas fa-times mr-2"></i>Cerrar
                            </Button>
                        </Col>
                    </Row>
                </CardTitle>
                <CardBody className="pt-0">
                    <Row>
                        <Col sm={6} className="text-center">
                            <QrReader
                                onResult={(result, error) => {
                                    if (!!result) {
                                        setUserToken(result.text)
                                    }
                                }}
                                style={{ width: '100%' }}
                                constraints={{ facingMode: 'environment', deviceId: camId }}
                            // constraints={{ facingMode: selectFacingMode }}
                            // videoStyle={{ width: '250px' }}
                            // containerStyle={{ width: '250px' }}
                            // scanDelay={10000}
                            />
                        </Col>
                        <Col sm={6} className="text-left pt-2">
                            <Label className="h6 text-break">
                                Escanea el <b>código QR</b> del usuario o el QR del <b>reverso de su cédula de identidad</b> para registrar su asistencia.
                            </Label>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label className="h6 text-break">
                                            En caso de no tener el QR a mano digita su <b>RUT</b>:
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control etm_register_input mr-3"
                                            name="run"
                                            id="run"
                                            placeholder="Ingresa el rut del asistente acá"
                                            required
                                            value={attendeeRun}
                                            onChange={(e) => setAttendeeRun(formatRut(e.target.value))}
                                            onKeyPress={ (e) => keyPressSearchRut(e) }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Button
                                        block
                                        name="ingresar"
                                        id="ingresar"
                                        onClick={() => findAttendee()}
                                        color="success"
                                        className="btn btn-sm text-nowrap"
                                    >
                                        <i className="fas fa-search mr-2"></i>Buscar asistente
                                    </Button>
                                </Col>
                            </Row>

                            <hr className="mt-4 mb-4" />
                            <FormGroup>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        block
                                        name="registrar"
                                        id="registrar"
                                        onClick={() => (setTextCard({ title: "Registra al asistente", btn: "REGISTRAR" }), setSection({ scanner: false, data: true, list: false }))}
                                        color="primary"
                                        className="btn btn-sm text-nowrap"
                                    >
                                        ¿No encuentras al usuario? <br /> Registro manual
                                    </Button>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <AttendeeData
                section={section}
                setSection={setSection}
                data={data}
                attendeeRun={attendeeRun}
                setAttendeeRun={setAttendeeRun}
                setUserToken={setUserToken}
                colorByRol={colorByRol}
                textCard={textCard}
            />

            <ListParticipants
                section={section}
                setSection={setSection}
                colorByRol={colorByRol}
            />
        </Fragment>
    );
}

export default QrScaner;