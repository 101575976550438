import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormGroup } from "reactstrap";
import UploadFiles from "../../Utils/UploadFiles";
import UploadImageFile from "../../Utils/UploadImageFile";
import Select from 'react-select';
import { API } from '../../../helpers/axios';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import { iconFileByExtension, isMobile, pathS3, getDate, selectCustomStyle, swal_etm, getLocalStorage, validateRol } from '../../../helpers/etmmeetUtils';

const ModalChalleng = (props) => {
    const ls = getLocalStorage()
    const date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia = getDate(primerDia, true).replace(/\-/g, '/');

    const reactQuillOption = {
        modules: {
            toolbar: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link']
            ],
        },
        formats: [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
        ]
    }

    const reactQuillOptionDetalle = {
        modules: {
            toolbar: [

            ],
        },
        formats: [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link'
        ]
    }

    const [challengeName, setChallengeName] = useState('');
    const [challengePicture, setChallengePicture] = useState([]);
    const [challengeFile, setChallengeFile] = useState([]);
    const [challengeStarDate, setChallengeStarDate] = useState('');
    const [challengeEndDate, setChallengeEndDate] = useState('');
    const [challengeIndustry, setChallengeIndustry] = useState({});
    const [challengeManagement, setChallengeManagement] = useState({});
    const [challengeShortDescription, setChallengeShortDescription] = useState('');
    const [challengeDescription, setChallengeDescription] = useState('');

    const [listIndustries, setListIndustries] = useState([]);
    const [listManagements, setListManagements] = useState([]);

    const [applyStartup, setApplyStartup] = useState([]);
    const [applyReview, setApplyReview] = useState('');

    const [postular, setPostular] = useState({ hidden: true, btnText: 'Postular al desafio', type: 'prePostular' });
    const textFilter = "Selecciona una opción";
    const [listStartups, setListStartups] = useState([]);

    const [modalEvent, setModalEvent] = useState({});

    useEffect(() => {
        getInduistries()
        getManagements()
        findStartups()
    }, []);

    useEffect(() => {
        setPostular({ hidden: true, btnText: 'Postular al desafio', type: 'prePostular' });
        if (props.type.type === 'nuevo') {
            setModalEvent(props.type)
            limpiarModal()
        } else if (props.type.type === 'detalle') {
            setModalEvent(props.type)
            buscarDesafio(props.type.id)
        } else if (props.type.type === 'editar') {
            setModalEvent(props.type)
            buscarDesafio(props.type.id)
        }
    }, [props.type]);

    const getInduistries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            list.push({ value: "", label: " " + textFilter });
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
    }

    const getManagements = async () => {
        const res = await API.get('/general/getManagements');
        if (res.data.state) {
            let list = [];
            list.push({ value: "", label: " " + textFilter });
            res.data.managements.map((e, i) => {
                list.push({ value: e.ID, label: e.GERENCIA });
            });
            setListManagements(list);
        }
    }

    const changeStarDate = (event, picker) => {
        setChallengeStarDate(getDate(picker.startDate));
    }

    const changeEndDate = (event, picker) => {
        setChallengeEndDate(getDate(picker.startDate));
    }
    const limpiarModal = () => {
        setChallengeName('');
        setChallengePicture([]);
        setChallengeFile([]);
        setChallengeStarDate('');
        setChallengeEndDate('');
        setChallengeIndustry({});
        setChallengeManagement({});
        setChallengeShortDescription('');
        setChallengeDescription('');
    }

    const buscarDesafio = async (id) => {
        const res = await API.get('/challenger/findChallengerById', { params: { id, token: ls.token } });
        if (res.data.state) {
            setChallengePicture([res.data.challenger.FOTO])
            setChallengeName(res.data.challenger.DESAFIO)
            setChallengeManagement({ value: res.data.challenger.GERENCIA_ID, label: res.data.challenger.GERENCIA })
            setChallengeShortDescription(res.data.challenger.DESCRIPCION_CORTA)
            setChallengeIndustry({ value: res.data.challenger.INDUTRIA_ID, label: res.data.challenger.INDUTRIA })
            setChallengeStarDate(res.data.challenger.FECHA_INICIO)
            setChallengeEndDate(res.data.challenger.FECHA_FIN)
            setChallengeDescription(res.data.challenger.DESCRIPCION)
            setChallengeFile([{
                url: res.data.challenger.ARCHIVO,
                name: res.data.challenger.ARCHIVO_NOMBRE,
                extension: res.data.challenger.ARCHIVO_EXTENSION
            }])
            if(res.data.challenger.POSTULADO > 0){                
                setPostular({ hidden: true, btnText: 'Ya has postulado a este desafío!', type: 'postulado' })
            }
        } else {
            swal_etm(res.data.msg, "warning", "Ups!");
        }
    }

    const preCreateChallenge = () => {
        if (challengePicture === undefined || challengePicture.length < 1) {
            swal_etm('Debes ingresar una "Imagen para tu desafío"');
            return;
        }
        if (challengeName === undefined || challengeName.length < 3) {
            swal_etm('Debe ingresar un "Nombre para tu desafío" valido');
            return;
        }
        if (challengeManagement === undefined || challengeManagement.length < 1) {
            swal_etm('Debe ingresar una "Gerencia" para clasificar tu desafío');
            return;
        }
        if (challengeShortDescription === undefined || challengeShortDescription.length < 1) {
            swal_etm('Debe ingresar una "Descripcion cota" para tu desafio');
            return;
        }
        if (challengeIndustry === undefined || challengeIndustry.length < 1) {
            swal_etm('Debe ingresar una "Industria" para tu desafío');
            return;
        }
        if (challengeStarDate === undefined || challengeStarDate.length < 1) {
            swal_etm('Debe ingresar una "Fecha de inicio" para tu desafío');
            return;
        }
        if (challengeEndDate === undefined || challengeEndDate.length < 1) {
            swal_etm('Debe ingresar una "Fecha de Termino" para tu desafío');
            return;
        }
        if (challengeDescription === undefined || challengeDescription.length < 10) {
            swal_etm('Debe ingresar una "Descripcion" mas detallada para tu desafio');
            return;
        }
        if (modalEvent.type === 'editar') {
            editChallenge(modalEvent.id)
        } else if (modalEvent.type === 'nuevo') {
            crearChallenge()
        }
    }

    const crearChallenge = async () => {
        const datos = {
            token: ls.token
            , challengePicture: challengePicture[0]
            , challengeName
            , challengeManagement: challengeManagement.value
            , challengeShortDescription
            , challengeIndustry: challengeIndustry.value
            , challengeStarDate
            , challengeEndDate
            , challengeDescription
            , challengeFile: (challengeFile.length < 1) ? '' : challengeFile[0]
        }

        const res = await API.post('/challenger/makeChalleng', datos);

        if (res.data.state) {
            swal_etm(res.data.msg, "success", "Perfecto!");
            props.setModal(false)
        } else {
            swal_etm("Ups!", res.data.msg);
        }
    }

    const editChallenge = async (id) => {
        const datos = {
            token: id
            , challengePicture: challengePicture[0]
            , challengeName
            , challengeManagement: challengeManagement.value
            , challengeShortDescription
            , challengeIndustry: challengeIndustry.value
            , challengeStarDate
            , challengeEndDate
            , challengeDescription
            , challengeFile: (challengeFile.length < 1) ? '' : challengeFile[0]
            , user: ls.token
        }

        const res = await API.put('/challenger/editChalleng', datos);

        if (res.data.state) {
            swal_etm(res.data.msg, "success", "Perfecto!");
            props.setModal(false)
        } else {
            swal_etm("Ups!", res.data.msg);
        }
    }

    const prePostular = () => {
        // let appliedRes = applied()
        // console.log(appliedRes)
        // if (appliedRes.state === true) {
            
        // } else {
            applied()
            if (postular.type === 'prePostular') {
                if (listStartups.length === 0) {
                    swal_etm('Para postular debes tener almenos 1 emprendimiento registrado. Regístralo en <a href="/profile/startups" target="_blank">MI PERFIL</a>', "warning", "Ups!");
                    return
                }
                setPostular({ hidden: false, btnText: 'Enviar Postulacion', type: 'postular' })
            } else if (postular.type === 'postular') {
                if (applyStartup === undefined || applyStartup.length < 1) {
                    swal_etm('Debe ingresar un "Proyecto" para postular');
                    return;
                }
                if (applyReview === undefined || applyReview.length < 10) {
                    swal_etm('Debe ingresar una "Descripcion" mas detallada para tu postulacion');
                    return;
                }
                sendApply()
            }
        // }
    }

    const applied = async () => {
        const res = await API.get('/challenger/appliedChallenger', { params: { token: ls.token, challenge: props.type.id } });
        if(res.data.state){
            swal_etm(res.data.msg)
            setPostular({ hidden: true, btnText: 'Ya has postulado a este desafío!', type: 'postulado' })
            return false
        }
    }

    const sendApply = async () => {
        let datos = {
            applyStartup
            , applyReview
            , challenge: props.type.id
            , Token: ls.token
        };
        const res = await API.post('/challenger/applyChallenger', datos);
        if (res.data.state) {
            swal_etm(res.data.msg, "success", "Perfecto!");
            setPostular({ hidden: true, btnText: 'Postular al desafio', type: 'prePostular' })
            props.setModal(false)
        } else {
            swal_etm("Ups!", res.data.msg);
        }
    }

    const findStartups = async () => {
        const res = await API.get('/search/getStartupsByUserToken', { params: { token: ls.token } });
        if (res.data.startups.length > 0) {
            let list = [];
            res.data.startups.map((e) => {
                list.push({ value: e.ID, label: e.NOMBRE });
            });
            setListStartups(list);
        }
    }

    return (
        <Fragment>
            <Modal isOpen={props.modal} size="lg" scrollable={true}>
                <ModalHeader className="etm_modal_header text-white">
                    <Label className="text-white">Ingresa los datos solicitados para crear tu desafío:</Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    <Row>
                        <Col lg={12} md={12} sm={12} className="text-lg-left text-center animate__animated animate__fadeIn">
                            <Row className="mt-3">
                                <Col md={6} className="text-center">
                                    {
                                        (modalEvent.type === 'detalle') ?
                                            <FormGroup>
                                                <Label className="h5">
                                                    Imegan del desafío
                                                </Label>
                                                <br />
                                                <img
                                                    src={challengePicture}
                                                    alt={challengeName || ''}
                                                    className="img-fluid w-50 etm_img_border etm_sombra"
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup>
                                                <Label className="h5">
                                                    Carga una imegan para tu desafío
                                                </Label>
                                                <br />
                                                <UploadImageFile
                                                    buttonText={"Click aquí para subir una foto"}
                                                    pathS3={pathS3("CHALLENGES/PICTURE")}
                                                    singleImage={true}
                                                    maxFileNumber={1}
                                                    handle={setChallengePicture}
                                                    defaultImages={(challengePicture === undefined || challengePicture.length < 1) ? null : challengePicture}
                                                />
                                            </FormGroup>
                                    }
                                </Col>
                                <Col lg={6} md={12} sm={12} className="text-lg-left text-center">
                                    <FormGroup className="mt-4">
                                        <Label className="h5">Nombre del desafío</Label>
                                        <Input
                                            onChange={(e) => setChallengeName(e.target.value)}
                                            name="nombre"
                                            type="text"
                                            className="etm_register_input"
                                            placeholder="Nombre del desafío"
                                            value={challengeName}
                                            required
                                            readOnly={(modalEvent.type === 'detalle') ? true : false}
                                        />
                                    </FormGroup>
                                    {
                                        (modalEvent.type === 'detalle') ?
                                            <FormGroup className="mt-4">
                                                <Label className="h5 mt-4">Gerencia</Label>
                                                <br />
                                                <Input
                                                    type="text"
                                                    className="etm_register_input"
                                                    placeholder=""
                                                    value={challengeManagement.label}
                                                    required
                                                    readOnly={(modalEvent.type === 'detalle') ? true : false}
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup className="mt-4">
                                                <Label className="h5 mt-4">¿ En qué gerencia lo clasificarías ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una categoría o industria"}
                                                    value={challengeManagement}
                                                    onChange={setChallengeManagement}
                                                    options={listManagements}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                    }

                                </Col>
                                <Col lg={12} md={12} sm={12} className="text-lg-left text-center">
                                    <FormGroup>
                                        <Label className="h5">
                                            Describe brevemente el desafío
                                            <small className="text-muted"> ( máximo 100 caracteres )</small>
                                        </Label>
                                        <br />
                                        <Label className="h5">
                                            <small className="text-muted">Esta descripción será parte de tu tarjeta visible para los otros usuarios.</small>
                                        </Label>
                                        <br />
                                        <br />
                                        <Input
                                            className="form-control etm_textarea"
                                            type="textarea"
                                            maxLength="100"
                                            rows="2"
                                            onChange={(e) => setChallengeShortDescription(e.target.value)}
                                            placeholder="Ejemplo. El objetivo prinncipal ..."
                                            value={challengeShortDescription}
                                            readOnly={(modalEvent.type === 'detalle') ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="text-lg-left text-center">
                                    {
                                        (modalEvent.type === 'detalle') ?
                                            <FormGroup className="mt-2">
                                                <Label className="h5 mt-4">Categoría o industria</Label>
                                                <br />
                                                <Input
                                                    type="text"
                                                    className="etm_register_input"
                                                    placeholder=""
                                                    value={challengeIndustry.label}
                                                    required
                                                    readOnly={(modalEvent.type === 'detalle') ? true : false}
                                                />
                                            </FormGroup>
                                            :
                                            <FormGroup className="mt-2">
                                                <Label className="h5">¿ En qué categoría o industria lo clasificarías ?</Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona una categoría o industria"}
                                                    value={challengeIndustry}
                                                    onChange={setChallengeIndustry}
                                                    options={listIndustries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                />
                                            </FormGroup>
                                    }


                                </Col>
                                <Col lg={6} md={6} sm={12} className="text-lg-left text-center mt-4">
                                    <FormGroup>
                                        <Label className="h5">Fecha Inicio</Label>
                                        {

                                            (modalEvent.type === 'detalle') ?
                                                <Input
                                                    type="text"
                                                    className="etm_register_input"
                                                    placeholder=""
                                                    value={challengeStarDate}
                                                    required
                                                    readOnly={(modalEvent.type === 'detalle') ? true : false}
                                                />
                                                :
                                                <DateRangePicker
                                                    onApply={changeStarDate}
                                                    initialSettings={{
                                                        singleDatePicker: true,
                                                        autoApply: true,
                                                        showDropdowns: true,
                                                        locale: {
                                                            format: "DD-MM-YYYY",
                                                            daysOfWeek: [
                                                                "Dom",
                                                                "Lun",
                                                                "Mar",
                                                                "Mie",
                                                                "Jue",
                                                                "Vie",
                                                                "Sab"
                                                            ],
                                                            monthNames: [
                                                                "Enero",
                                                                "Febrero",
                                                                "Marzo",
                                                                "Abril",
                                                                "Mayo",
                                                                "Junio",
                                                                "Julio",
                                                                "Agosto",
                                                                "Septiembre",
                                                                "Octubre",
                                                                "Noviembre",
                                                                "Diciembre"
                                                            ],
                                                        }
                                                    }} >
                                                    <input
                                                        onChange={(e) => setChallengeStarDate(e)}
                                                        name="startDate"
                                                        className="form-control etm_register_input"
                                                        type="text"
                                                        placeholder="Fecha de inicio"
                                                        required
                                                        value={challengeStarDate}
                                                        readOnly
                                                    />
                                                </DateRangePicker>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="text-lg-left text-center mt-4">
                                    <FormGroup>
                                        <Label className="h5">Fecha Término</Label>
                                        {
                                            (modalEvent.type === 'detalle') ?
                                                <Input
                                                    type="text"
                                                    className="etm_register_input"
                                                    placeholder=""
                                                    value={challengeEndDate}
                                                    required
                                                    readOnly={(modalEvent.type === 'detalle') ? true : false}
                                                />
                                                :
                                                <DateRangePicker
                                                    onApply={changeEndDate}
                                                    initialSettings={{
                                                        singleDatePicker: true,
                                                        autoApply: true,
                                                        showDropdowns: true,
                                                        locale: {
                                                            format: "DD-MM-YYYY",
                                                            daysOfWeek: [
                                                                "Dom",
                                                                "Lun",
                                                                "Mar",
                                                                "Mie",
                                                                "Jue",
                                                                "Vie",
                                                                "Sab"
                                                            ],
                                                            monthNames: [
                                                                "Enero",
                                                                "Febrero",
                                                                "Marzo",
                                                                "Abril",
                                                                "Mayo",
                                                                "Junio",
                                                                "Julio",
                                                                "Agosto",
                                                                "Septiembre",
                                                                "Octubre",
                                                                "Noviembre",
                                                                "Diciembre"
                                                            ],
                                                        }
                                                    }} >
                                                    <input
                                                        onChange={(e) => setChallengeEndDate(e)}
                                                        name="endDate"
                                                        className="form-control etm_register_input"
                                                        type="text"
                                                        placeholder="Fecha de termino"
                                                        required
                                                        value={challengeEndDate}
                                                        readOnly
                                                    />
                                                </DateRangePicker>
                                        }

                                    </FormGroup>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="text-lg-left text-center mt-4 mb-5">
                                    <FormGroup>
                                        <Label className="h5">
                                            Describe el desafío
                                            <small className="text-muted"> ( máximo 800 caracteres )</small>
                                        </Label>
                                        <br />
                                        <Label className="h5">
                                            <small className="text-muted">Esta descripción se vera cuando vean el detalle del desafio.</small>
                                        </Label>
                                        <br />
                                        <ReactQuill
                                            theme='snow'
                                            value={challengeDescription}
                                            onChange={setChallengeDescription}
                                            style={{ minHeight: '80px', "height": "80px" }}
                                            className="quill_editor etm_textarea"
                                            modules={(modalEvent.type === 'detalle') ? reactQuillOptionDetalle.modules : reactQuillOption.modules}
                                            formats={(modalEvent.type === 'detalle') ? reactQuillOptionDetalle.formats : reactQuillOption.formats}
                                            readOnly={(modalEvent.type === 'detalle') ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={12} sm={12} className="text-lg-center text-center animate__animated animate__fadeIn">
                            {
                                (modalEvent.type === 'detalle') ?
                                    <FormGroup>
                                        <Label className="mb-4">
                                            Archivo Cargado
                                        </Label>
                                        <br />
                                        <a href={(challengeFile.length > 0) ? challengeFile[0].url : ''} target="_blank">
                                            <i className={`${iconFileByExtension((challengeFile.length > 0) ? challengeFile[0].extension : '')} mr-2 fa-lg`}></i>
                                            {(challengeFile.length > 0) ? challengeFile[0].name : ''}
                                            <i className='fas fa-download fa-lg ml-2'></i>
                                        </a>
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label className="mb-4">
                                            Carga un archivo para tu desafío (pdf o word)
                                        </Label>
                                        <br />
                                        <UploadFiles
                                            extensionAccept=".pdf,.doc,.docx"
                                            pathS3="CHALLENGES/FILES"
                                            setFile={setChallengeFile}
                                            multiple={false}
                                            defaulFile={(challengeFile.length > 0) ? challengeFile : null}
                                        />
                                    </FormGroup>
                            }
                        </Col>
                        <Col lg={12} md={12} sm={12} className="text-lg-center text-center animate__animated animate__fadeIn" hidden={postular.hidden}>
                            <hr />
                            <Row>
                                <Col lg={12} md={12} sm={12} className="text-center mt-4 mb-1">
                                    <Label className="h3"> <i className="fas fa-arrow-right etm_text_blue mr-2"></i> Ingresa Los Siguientes datos para postular</Label>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="text-lg-left text-center mt-2 mb-2">
                                    <FormGroup>
                                        <Label className="h5">¿ Con que proyecto quieres postular ?</Label>
                                        <br />
                                        <br />
                                        <Select
                                            placeholder={"Selecciona un proyecto"}
                                            value={applyStartup}
                                            onChange={setApplyStartup}
                                            options={listStartups}
                                            className="etm_input_select"
                                            styles={selectCustomStyle()}
                                            noOptionsMessage={() => 'No hay mas opciones'}
                                            maxMenuHeight={180}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={12} md={12} sm={12} className="text-lg-left text-center mt-2 mb-2">
                                    <FormGroup>
                                        <Label className="h5">
                                            Describe por que tu deberias ser seleccionado para este desafío
                                            <small className="text-muted"> ( máximo 800 caracteres )</small>
                                        </Label>
                                        <br />
                                        <br />
                                        <Input
                                            className="form-control etm_textarea"
                                            type="textarea"
                                            maxLength="800"
                                            rows="4"
                                            onChange={(e) => setApplyReview(e.target.value)}
                                            placeholder="Ejemplo. El objetivo prinncipal ..."
                                            value={applyReview}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} md={12} sm={12} className="text-lg-center text-center animate__animated animate__fadeIn mt-3">
                            {
                                (validateRol(['EMPRENDEDOR']) && modalEvent.type === 'detalle') ?
                                    <Button
                                        block={isMobile()}
                                        color="primary"
                                        onClick={() => prePostular()}
                                        className="etm_btn pl-5 pr-5"
                                        size="lg"
                                    >
                                        <i className="fas fa-check-circle fa-lg mr-2"></i> {postular.btnText}
                                    </Button>
                                    : ""
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                    <Button
                        block={isMobile()}
                        color="success"
                        onClick={() => preCreateChallenge()}
                        className="etm_btn btn-lg"
                        hidden={(modalEvent.type === 'detalle') ? true : false}
                    >
                        <i className="fas fa-check-circle fa-lg mr-2"></i> {(modalEvent.type === 'editar') ? 'Editar Desafio' : 'Crear Desafio'}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}


export default ModalChalleng;