import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Input, Label, Container, Button } from "reactstrap";
import { API } from '../../../helpers/axios';
import { capitalize, cleanFacebook, cleanInstagram, cleanTwitter, cleanLinkedin, validateRol, swal_etm, getDate, spanishDate, cleanYoutube, selectCustomStyle, getLocalStorage } from '../../../helpers/etmmeetUtils';
import LoadBtn from "../../Utils/LoadBtn";
import LoadScreen from "../../Utils/LoadScreen";
import logo_emprendedor from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import Select from 'react-select';
import ImageViewer from "react-simple-image-viewer";

const ModalSolicitar = (props) => {
    const flecha = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;
    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate() + 3);
    strikeDate.setDate(strikeDate.getDate() + 2);

    const [loading, setLoading] = useState({ data: true, btn: false });
    const [listObjectives, setListObjectives] = useState([]);
    const [listHours, setListHours] = useState([]);
    const [startups, setStartups] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState("");
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [showSection, setShowSection] = useState({ startup: true, emprendedor: false, solicitar: false, solicitarOptions: false });
    const [images, setImages] = useState(null);
    const [validateButton, setValidateButton] = useState(false);

    const [dataUser, setDataUser] = useState({
        short_name: ""
        , name: ""
        , company: ""
        , company_img: ""
        , profile: ""
        , profile_img: ""
        , photo: ""
        , description: ""
        , duration: ""
        , facebook: ""
        , instagram: ""
        , linkedin: ""
        , twitter: ""
        , youtube: ""
    });

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    useEffect(() => {
        validarButtonSolicitar();
    }, [selectedDate, selectedHour, selectedObjective, selectedDescription]);

    useEffect(() => {
        getEmprendedorData();
    }, [props.emprendedorToken, props.startupId]);

    useEffect(() => {
        getHours();
    }, []);

    const validarButtonSolicitar = async () => {
        if (getDate(selectedDate) >= getDate(nextFreeDay) && selectedHour !== null && selectedHour.value.length > 0 && selectedObjective !== null && selectedObjective.value > 0 && selectedDescription !== null && selectedDescription.length >= 10) {
            setValidateButton(true);
        } else {
            setValidateButton(false);
        }
    }

    const getHours = async (e) => {
        const res = await API.get('/meeting/getHoursList');
        let list = [];
        if (res.data.state) {
            res.data.hours.map((e, i) => {
                list.push({ value: e.HORA_INICIO, label: `${e.HORA_INICIO} - ${e.HORA_FIN}` });
            });
        }
        setListHours(list);
    }

    const esChile = (pais) => {
        return pais.name === 'Chile'
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const getEmprendedorData = async () => {
        if (props.emprendedorToken !== null && props.startupId !== null) {
            setLoading({ data: true, btn: false });
            const res = await API.get('/search/getEntrepreneurData', { params: { token: props.emprendedorToken, startupId: props.startupId, meetingType: props.meetingType } });
            if (res.data.state) {

                setDataUser({
                    short_name: res.data.data.user.nombre_corto
                    , name: res.data.data.user.nombre
                    , profile: "Emprendedor"
                    , profile_img: logo_emprendedor
                    , photo: res.data.data.user.foto
                    , description: res.data.data.user.descripcion
                    , duration: res.data.data.user.duracion
                    , facebook: res.data.data.user.rrss.facebook
                    , instagram: res.data.data.user.rrss.instagram
                    , linkedin: res.data.data.user.rrss.linkedin
                    , twitter: res.data.data.user.rrss.twitter
                    , youtube: res.data.data.user.rrss.youtube
                });

                let list_obj = [];
                if (res.data.data.objectives.length !== null && res.data.data.objectives.length > 0) {
                    res.data.data.objectives.map((e, i) => {
                        list_obj.push({ value: e.ID, label: e.DESCRIPCION });
                    });
                }
                setListObjectives(list_obj);

                if (res.data.data.startups.length !== null && res.data.data.startups.length > 0) {
                    if (props.meetingType === "MENTORIA") {
                        formatoMentoria(res.data.data.startups);
                    } else {
                        formatoComercial(res.data.data.startups);
                    }
                }
            }

            setTimeout(function () {
                setLoading({ data: false, btn: false });
            }, 1000);
        }
    }

    const separator = (data, type) => {
        let new_data = data.reduce((prev, cur) => {
            return prev ? <Fragment>
                {prev}
                {type}
                {cur.name}
            </Fragment>
                : <Fragment>{cur.name}</Fragment>
        }, '')
        return new_data;
    }

    const projectNumber = (e) => {
        return <Label className="h4 mt-2"><span className="etm_onboarding_bg_step_number mr-2">{e}</span></Label>
    }

    const formatoMentoria = (startupData) => {
        const format = [];
        const listImg = [];
        let indexImg = 0;

        if (startupData !== undefined && startupData !== null && startupData.length > 0) {
            format.push(
                <div key={-1}>
                    <Label className="h5 text-truncate mb-0 text-wrap text-muted">
                        {(startupData.length > 1) ? "Acerca de sus proyectos..." : "Acerca del proyecto..."}
                    </Label>
                    <br />
                </div>
            );

            startupData.map((e, i) => {
                const listImgHtml = [];

                if (e.images !== null && e.images !== undefined && e.images.length > 0) {
                    e.images.map((src, index) => {
                        listImg.push(src.IMG);
                        listImgHtml.push(
                            <img
                                src={src.IMG}
                                onClick={() => openImageViewer(indexImg)}
                                width="200"
                                key={indexImg}
                                style={{ margin: '2px' }}
                                alt=""
                                className="pointer etm_img_border etm_sombra m-2"
                            />
                        )
                        indexImg++;
                    });
                }

                format.push(
                    <div key={i}>
                        <Label className="h2 text-truncate etm_text_blue mb-0 text-wrap">
                            {projectNumber(i + 1)}
                            {capitalize(e.NOMBRE)}
                        </Label>
                        <br />
                        <Row>
                            {
                                (e.WEB !== undefined && e.WEB !== null && e.WEB !== "") ?
                                    <Col md={12}>
                                        <li className="list-inline-item mr-2">
                                            <Label className="h5">
                                                <a href={e.WEB} target="_blank" className="etm_link_blue">
                                                    <i className="fas fa-external-link-alt mr-2"></i>{e.WEB}
                                                </a>
                                            </Label>
                                        </li>
                                        <br />
                                    </Col>
                                    :
                                    ""
                            }
                            {
                                (e.DESCRIPCION !== undefined && e.DESCRIPCION !== null && e.DESCRIPCION !== "") ?
                                    <Col md={12}>
                                        <br />
                                        <Label className="h4">
                                            {flecha}<span className="text-muted">{e.DESCRIPCION}</span>
                                        </Label>
                                        <br />
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.ETAPA !== undefined && e.ETAPA !== null && e.ETAPA !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Etapa: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.ETAPA}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INDUSTRIA !== undefined && e.INDUSTRIA !== null && e.INDUSTRIA !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Industria: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INDUSTRIA}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.TIPO_COMERCIO !== undefined && e.TIPO_COMERCIO !== null && e.TIPO_COMERCIO !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Tipo Comercio: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.TIPO_COMERCIO}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                        </Row>
                        {listImgHtml}
                        <hr />
                    </div>
                );
            });
        }

        setStartups(format);
        setImages(listImg);
    }

    const formatoComercial = (startupData) => {
        const format = [];
        const listImg = [];
        let indexImg = 0;

        if (startupData !== undefined && startupData !== null && startupData.length > 0) {
            format.push(
                <div key={-1}>
                    <Label className="h5 text-truncate mb-0 text-wrap text-muted">
                        {(startupData.length > 1) ? "Acerca de sus proyectos..." : "Acerca del proyecto..."}
                    </Label>
                    <br />
                </div>
            );

            startupData.map((e, i) => {
                const listImgHtml = [];

                if (e.images !== null && e.images !== undefined && e.images.length > 0) {
                    e.images.map((src, index) => {
                        listImg.push(src.IMG);
                        listImgHtml.push(
                            <img
                                src={src.IMG}
                                onClick={() => openImageViewer(indexImg)}
                                width="200"
                                key={indexImg}
                                style={{ margin: '2px' }}
                                alt=""
                                className="pointer etm_img_border etm_sombra m-2"
                            />
                        )
                        indexImg++;
                    });
                }

                format.push(
                    <div key={i}>
                        <Label className="h2 text-truncate etm_text_blue mb-0 text-wrap">
                            {projectNumber(i + 1)}
                            {capitalize(e.NOMBRE)}
                        </Label>
                        <br />
                        <Row>
                            {
                                (e.WEB !== undefined && e.WEB !== null && e.WEB !== "") ?
                                    <Col md={12}>
                                        <li className="list-inline-item mr-2">
                                            <Label className="h5">
                                                <a href={e.WEB} target="_blank" className="etm_link_blue">
                                                    <i className="fas fa-external-link-alt mr-2"></i>{e.WEB}
                                                </a>
                                            </Label>
                                        </li>
                                        <br />
                                    </Col>
                                    :
                                    ""
                            }
                            {
                                (e.DESCRIPCION !== undefined && e.DESCRIPCION !== null && e.DESCRIPCION !== "") ?
                                    <Col md={12}>
                                        <br />
                                        <Label className="h4">
                                            {flecha}<span className="text-muted">{e.DESCRIPCION}</span>
                                        </Label>
                                        <br />
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INDUSTRIA !== undefined && e.INDUSTRIA !== null && e.INDUSTRIA !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Industria: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INDUSTRIA}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.TIPO_COMERCIO !== undefined && e.TIPO_COMERCIO !== null && e.TIPO_COMERCIO !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Tipo Comercio: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.TIPO_COMERCIO}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INICIO_ACTIVIDADES !== undefined && e.INICIO_ACTIVIDADES !== null && e.INICIO_ACTIVIDADES !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Inicio Actividades: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INICIO_ACTIVIDADES}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.NUMERO_EMPLEADOS !== undefined && e.NUMERO_EMPLEADOS !== null && e.NUMERO_EMPLEADOS !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Nº Colaboradores: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.NUMERO_EMPLEADOS}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.billing !== undefined && e.billing !== null && e.billing.YEAR !== undefined && e.billing.YEAR !== null && e.YEAR !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Rango de facturación: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.billing.BILLING}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INVERSIONES_RECIBIDAS !== undefined && e.INVERSIONES_RECIBIDAS !== null && e.INVERSIONES_RECIBIDAS !== "") ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Inversiones recibidas: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.INVERSIONES_RECIBIDAS}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.countries !== undefined && e.countries !== null && e.countries.length > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Países donde opera: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.countries, <br />)}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.regions !== undefined && e.regions !== null && e.regions.length > 0 && e.countries !== undefined && e.countries !== null && e.countries.length > 0 && e.countries.find(esChile)) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Regiones donde opera: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.regions, <br />)}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.POLITICAS_IGUALDAD_GENERO !== undefined && e.POLITICAS_IGUALDAD_GENERO !== null && e.POLITICAS_IGUALDAD_GENERO > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Políticas de Igualdad de Género: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.INCLUSION_IGUALDAD !== undefined && e.INCLUSION_IGUALDAD !== null && e.INCLUSION_IGUALDAD > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Políticas de Inclusión: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.LIDERADO_X_MUJERES !== undefined && e.LIDERADO_X_MUJERES !== null && e.LIDERADO_X_MUJERES > 0) ?
                                    <Col lg={4} md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Proyectos liderados por Mujeres: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">SI</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.impacts !== undefined && e.impacts !== null && e.impacts.length > 0) ?
                                    <Col md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Causa de Impacto Social: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{separator(e.impacts, " | ")}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                            {
                                (e.DESARROLLO_SOSTENIBLE !== undefined && e.DESARROLLO_SOSTENIBLE !== null && e.DESARROLLO_SOSTENIBLE !== "") ?
                                    <Col md={12}>
                                        <Label className="h5 text-truncate mt-0">
                                            <b className="mr-2">Desarrollo Sostenible: </b>
                                            <br />
                                            <FormGroup check>
                                                <span className="text-muted">{e.DESARROLLO_SOSTENIBLE}</span>
                                            </FormGroup>
                                        </Label>
                                        <br />
                                    </Col>
                                    : ""
                            }
                        </Row>
                        {listImgHtml}
                        <hr />
                    </div>
                );
            });
        }

        setStartups(format);
        setImages(listImg);
    }

    const changeDay = (day) => {
        setSelectedDate(day);
        if (getDate(day) < getDate(nextFreeDay)) {
            setShowSection({ startup: false, emprendedor: false, solicitar: true, solicitarOptions: false });
        } else {
            setShowSection({ startup: false, emprendedor: false, solicitar: true, solicitarOptions: true });
        }
    }

    const stepNumber = (e, title) => {
        return <Label className="h5"><b className="etm_text_blue mr-2">{e}. </b>{title}</Label>
    }

    const preMakeMeeting = () => {
        setLoading({ data: false, btn: true });

        if (selectedHour === null || selectedHour.value.length < 1) {
            swal_etm('Debes seleccionar un horario válido');
            setLoading({ data: false, btn: false });
            return;
        }

        if (selectedObjective === null || selectedObjective.value < 1) {
            swal_etm('Debes seleccionar un objetivo válido');
            setLoading({ data: false, btn: false });
            return;
        }

        if (selectedDescription === null || selectedDescription < 10) {
            swal_etm('Descríbenos el objetivo de esta reunión en al menos 10 caracteres');
            setLoading({ data: false, btn: false });
            return;
        }

        postMakeMeeting();
    }

    const postMakeMeeting = async () => {
        const res = await API.post('/meeting/entrepreneur/makeMeeting', {
            token: getLocalStorage().token
            , emprendedorToken: props.emprendedorToken
            , startupId: (props.startupId !== undefined && props.startupId !== null) ? props.startupId : 0
            , day: getDate(selectedDate)
            , hour: selectedHour.value
            , objective: selectedObjective.value
            , description: selectedDescription
            , meetingType: props.meetingType
        }); 

        if (res.data.state) {
            swal_etm(res.data.msg, 'success');
        } else {
            swal_etm(res.data.msg);
        }
        props.setUrlUserType(null);
        props.setUrlUserToken(null);
        props.setEmprendedorToken(null);
        props.setStartupId(null);
        setLoading({ data: false, btn: false })
        props.setModal(false);
    }

    const closeModal = () => {
        props.setModal(false);
        props.setUrlUserType(null);
        props.setUrlUserToken(null);
        props.setEmprendedorToken(null);
        props.setStartupId(null);
        setLoading({ data: false, btn: false })
    }

    return (
        <Fragment>
            <Modal isOpen={props.modal} size={'xl'} scrollable={true} >
                <ModalHeader className="etm_modal_header">
                    <Label className="h4 ml-3 text-white">Selecciona el día y hora para reunirte con emprendedor <b>{capitalize(dataUser.short_name)}</b></Label>
                    <Label className="etm_modal_header_close_solicitud_xl pointer" onClick={closeModal}><i className="far fa-times-circle fa-lg"></i></Label>
                </ModalHeader>
                <ModalBody className="mt-3 pt-0">
                    {
                        (loading.data) ?
                            <LoadScreen text="Buscando horarios disponibles..." className="mt-5 mb-5" />
                            : <Fragment>
                                <Row>
                                    <Col sm={12} className="text-lg-left text-center animate__animated animate__fadeIn" hidden={!showSection.startup}>
                                        <Container>
                                            {startups}
                                            <br />
                                            {isViewerOpen && (
                                                <ImageViewer
                                                    src={images}
                                                    currentIndex={currentImage}
                                                    onClose={closeImageViewer}
                                                    disableScroll={false}
                                                    backgroundStyle={{
                                                        backgroundColor: "rgba(0,0,0,0.9)"
                                                    }}
                                                    closeOnClickOutside={true}
                                                />
                                            )}
                                        </Container>
                                    </Col>
                                    <Col sm={12} className="text-lg-left text-center animate__animated animate__fadeIn" hidden={!showSection.emprendedor}>
                                        <Container>
                                            <Label className="h5 text-muted">Acerca del Emprendedor/a...</Label>
                                            <div className="mb-2 mt-2">
                                                <img src={dataUser.photo} className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg" alt="" />
                                            </div>
                                            <Label className="h4 text-truncate etm_text_blue mb-0">{capitalize(dataUser.name)}</Label>
                                            <ul className="list-inline mt-lg-4 mt-2">
                                                {
                                                    (dataUser.facebook.length > 0) ?
                                                        <li className="list-inline-item mr-2">
                                                            <Label className="h4">
                                                                <a href={cleanFacebook(dataUser.facebook)} target="_blank">
                                                                    <i className="fab fa-facebook-square facebook"></i>
                                                                </a>
                                                            </Label>
                                                        </li>
                                                        : ""
                                                }
                                                {
                                                    (dataUser.instagram.length > 0) ?
                                                        <li className="list-inline-item mr-2">
                                                            <Label className="h4">
                                                                <a href={cleanInstagram(dataUser.instagram)} target="_blank">
                                                                    <i className="fab fa-instagram instagram"></i>
                                                                </a>
                                                            </Label>
                                                        </li>
                                                        : ""
                                                }
                                                {
                                                    (dataUser.linkedin.length > 0) ?
                                                        <li className="list-inline-item mr-2">
                                                            <Label className="h4">
                                                                <a href={cleanLinkedin(dataUser.linkedin)} target="_blank">
                                                                    <i className="fab fa-linkedin linkedin"></i>
                                                                </a>
                                                            </Label>
                                                        </li>
                                                        : ""
                                                }
                                                {
                                                    (dataUser.twitter.length > 0) ?
                                                        <li className="list-inline-item mr-2">
                                                            <Label className="h4">
                                                                <a href={cleanTwitter(dataUser.twitter)} target="_blank">
                                                                    <i className="fab fa-twitter-square twitter"></i>
                                                                </a>
                                                            </Label>
                                                        </li>
                                                        : ""
                                                }
                                                {
                                                    (dataUser.youtube.length > 0) ?
                                                        <li className="list-inline-item mr-2">
                                                            <Label className="h4">
                                                                <a href={cleanYoutube(dataUser.youtube)} target="_blank">
                                                                    <i className="fab fa-youtube youtube"></i>
                                                                </a>
                                                            </Label>
                                                        </li>
                                                        : ""
                                                }
                                            </ul>
                                            {
                                                (dataUser.description.length > 0) ?
                                                    <Label className="h5">{flecha}<span className="text-muted">{dataUser.description}</span></Label>
                                                    : ""
                                            }
                                            <Row className="mt-lg-2">
                                                <Col lg={2} md={2} xs={5} className="text-right">
                                                    <img src={dataUser.profile_img} className="avatar-xs rounded-circle fix_img shadow-lg" alt="" />
                                                </Col>
                                                <Col lg={10} md={4} xs={7} className="text-left">
                                                    <Label className="h5 mt-1 text-truncate font-weight-medium">{dataUser.profile}</Label>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col sm={12} className="text-lg-left text-center animate__animated animate__fadeIn" hidden={!showSection.solicitar}>
                                        <Row>
                                            <Col lg={6} md={12}>
                                                <div className="mt-2 text-center">
                                                    {stepNumber(1, "Solicítale una reunión el día y hora que desees")}
                                                </div>
                                                <br />
                                                <Row>
                                                    <Col xs={12} className="mt-3">
                                                        <div className="calendar text-center" hidden={(isViewerOpen)}>

                                                            {/* Controls */}
                                                            <div className="controls">
                                                                <div>
                                                                    <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                                                    <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                                                    <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                                                </div>
                                                            </div>

                                                            {/* Calendar head with day names */}
                                                            <div className="calendar_head">
                                                                {
                                                                    shortDayNames.map(day => (
                                                                        <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                                            {spanishDate(day)}
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                            {/* Calendar */}
                                                            <div className="calendar_body">
                                                                {
                                                                    items.map(day => {
                                                                        const activeClass = !day.active ? "inactive" : "";  //  dias fuera del mes
                                                                        let selectedClass = day.selected ? "selected" : ""; //  dia seleccionado o por defecto
                                                                        const strike = (day.fullDate < strikeDate) ? "inactive" : "";   //  dias anteriores al primero disponible
                                                                        return (
                                                                            <div
                                                                                className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                                                key={day.fullDate}
                                                                                onClick={() => {
                                                                                    selectDate(day.fullDate);
                                                                                    // setSelectedDate(day.fullDate);
                                                                                    changeDay(day.fullDate);
                                                                                }}
                                                                            >
                                                                                {day.date}
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} className="mt-md-5 mt-3 text-center">
                                                        <Label className="h6 text-muted"><i className="fas fa-globe-americas mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={6} md={12}>
                                                <Container>
                                                    <div hidden={!showSection.solicitarOptions} className="animate__animated animate__fadeIn">
                                                        {stepNumber(2, "Selecciona un horario disponible")}
                                                        <FormGroup className="mb-4" check>
                                                            <Label className="h6 text-muted"><i className="fas fa-clock etm_text_blue mr-2"></i>Recuerda que la reunión dura {dataUser.duration}</Label>
                                                            <br />
                                                            <br />
                                                            <Row>
                                                                <Col md={6} sm={12}>
                                                                    <Select
                                                                        placeholder="Elige uno de los horarios disponibles"
                                                                        value={selectedHour}
                                                                        onChange={setSelectedHour}
                                                                        options={listHours}
                                                                        className="etm_input_select"
                                                                        styles={selectCustomStyle()}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <br />
                                                        </FormGroup>
                                                        {stepNumber(3, "¿ Cuál es el objetivo de esta reunión ?")}
                                                        <FormGroup className="mb-5" check>
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Select
                                                                        placeholder="Indícanos el objetivo de esta reunión"
                                                                        value={selectedObjective}
                                                                        onChange={setSelectedObjective}
                                                                        options={listObjectives}
                                                                        className="etm_input_select"
                                                                        styles={selectCustomStyle()}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                        {stepNumber(4, "Describe con tus palabras lo que buscas de esta reunión")}
                                                        <FormGroup className="mt-3" check>
                                                            <Input onChange={(e) => setSelectedDescription(e.target.value)} type="textarea" value={selectedDescription} name="review" rows="5" placeholder="Esta descripción será enviada a la contraparte" maxLength="400" className="etm_textarea mt-2" />
                                                        </FormGroup>
                                                    </div>
                                                </Container>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Fragment>
                    }
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <a className="h5 pl-5 pr-5 text-danger" onClick={closeModal}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>
                    <Button
                        className={`pl-5 pr-5 ${(showSection.startup) ? `etm_btn` : `etm_btn_outline`}`}
                        color="primary"
                        outline={(showSection.startup) ? false : true}
                        block={false}
                        onClick={() => setShowSection({ startup: true, emprendedor: false, solicitar: false, solicitarOptions: false })}
                    >
                        <i className="fas fa-suitcase mr-2"></i>ACERCA DEL PROYECTO
                    </Button>
                    <Button
                        className={`pl-5 pr-5 ${(showSection.emprendedor) ? `etm_btn` : `etm_btn_outline`}`}
                        color="primary"
                        outline={(showSection.emprendedor) ? false : true}
                        block={false}
                        onClick={() => setShowSection({ startup: false, emprendedor: true, solicitar: false, solicitarOptions: false })}
                    >
                        <i className="fas fa-user mr-2"></i>ACERCA DEL EMPRENDEDOR/A
                    </Button>
                    <Button
                        className={`pl-5 pr-5 ${(showSection.solicitar) ? `etm_btn` : `etm_btn_outline`}`}
                        color="success"
                        outline={(showSection.solicitar) ? false : true}
                        block={false}
                        onClick={() => (
                            setShowSection({ startup: false, emprendedor: false, solicitar: true, solicitarOptions: showSection.solicitarOptions })
                            , setSelectedHour(null)
                            , setSelectedObjective(null)
                            , setSelectedDescription("")
                        )}
                        hidden={showSection.solicitar}
                    >
                        <i className="fas fa-calendar-alt mr-2"></i>SOLICITAR REUNIÓN
                    </Button>
                    <div hidden={!showSection.solicitar}>
                        <LoadBtn
                            className={`pl-5 pr-5 ${(validateButton) ? `btn-lg` : ``} `}
                            color="success"
                            block={false}
                            title="¡ SOLICITA LA REUNIÓN !"
                            icon="check-circle"
                            text="Creando solicitud..."
                            loading={loading.btn}
                            handle={preMakeMeeting}
                            disabled={(validateButton) ? false : true}
                            outline={(validateButton) ? false : true}
                        />
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}


export default ModalSolicitar;