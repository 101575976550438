import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap";
import "../../assets/scss/etm.scss";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadScreen from "../../components/Utils/LoadScreen";
import { getLocalStorage, validateRol } from "../../helpers/etmmeetUtils";
import TableDesafios from "../../components/Challenges/tables/TableDesafios";

const Index = () => {
    const ls = getLocalStorage();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
    }, []);


    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Desafís'} />
                    <ToastContainer />
                    <Card>
                        {/* <CardTitle className="mt-3 ml-3 mb-0 text-center">
                            <Container>
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                    <p>HOLA ACA IRAN METRICAS</p>
                                </Label>
                            </Container>
                            <hr />
                        </CardTitle> */}
                        <CardBody>
                            <Row>
                                <Col lg="12" className="table-responsive">
                                    {
                                        (!loading) ?
                                            <LoadScreen />
                                            :
                                            <TableDesafios />
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </Fragment >
    );
}

export default withNamespaces()(Index);