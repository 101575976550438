import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from "reactstrap";

import ModalInfoConstituido from "./ModalInfoConstituido";
import ModalInfoNoConstituido from "./ModalInfoNoConstituido";
import LoadScreen from "../../../components/Utils/LoadScreen";
import { capitalize, swal_etm, isMobile, cleanFacebook, cleanInstagram, cleanLinkedin, cleanTwitter, cleanYoutube, selectPerfilPicture } from '../../../helpers/etmmeetUtils';
import { API } from '../../../helpers/axios';


const ModalRequest = (props) => {
    const flecha = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listImages, setListImages] = useState({ images: null, imgHtml: null });
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    useEffect(() => {
        if (props.modal) {
            getUserData();
        }
    }, [props.modal]);

    useEffect(() => {
        processImg();
    }, [userData]);

    const getUserData = async () => {
        setLoading(true);
        const res = await API.get('/meeting/getUserByToken', { params: { token: props.datos.token, meet: props.datos.meet, request: props.datos.request } });
        if (res.data.state) {
            setUserData(res.data.datos)
        } else {
            swal_etm(res.data.msg)
            props.setModal(false)
        }
        setLoading(false);
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const processImg = async () => {
        let listHtml = [];
        let list = [];
        if (userData !== null && userData.images !== undefined && userData.images !== null) {
            userData.images.map((src, index) => {
                listHtml.push(
                    <img
                        src={src.IMG}
                        onClick={() => openImageViewer(index)}
                        width="200"
                        key={index}
                        style={{ margin: '2px' }}
                        alt=""
                        className="pointer etm_img_border etm_sombra m-2"
                    />

                )
                list.push(src.IMG)
            })
        }
        setListImages({ images: list, imgHtml: listHtml })
    }

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
        ),
        customPaging: i => (
            <div></div>
        )
    };

    return (
        <Fragment>
            {
                (loading) ? <LoadScreen text="Cargando datos..." />
                    :
                    <Modal isOpen={props.modal} size="xl" scrollable={true} >
                        <ModalHeader className="etm_modal_header">
                            <Label className="h4 ml-3 text-white">Detalle de la solicitud de <b>{(userData !== null) ? capitalize(userData.user.NOMBRE_CORTO) : ""}</b></Label>
                        </ModalHeader>
                        <ModalBody className="mt-3 pt-0">
                            {
                                (userData === null) ?
                                    <LoadScreen text="Cargando datos..." />
                                    :
                                    <Row>
                                        <Col lg={5} md={12} sm={12} className="text-lg-left text-center animate__animated animate__fadeIn">
                                            <Container>
                                                <Row>
                                                    <Col lg={3} md={12} sm={12} className="mb-2 mt-2 text-center">
                                                        <img
                                                            src={(userData.user.FOTO_PPERFIL !== null) ? userData.user.FOTO_PPERFIL : ""}
                                                            className="avatar-lg rounded-circle fix_img shadow-lg align-middle"
                                                            alt=""
                                                        />
                                                    </Col>
                                                    <Col lg={9} md={12} sm={12} className="mb-2 mt-2">
                                                        <Label className="h4 text-truncate etm_text_blue mb-0">{(userData.user.NOMBRE_COMPLETO !== null) ? capitalize(userData.user.NOMBRE_COMPLETO) : ""}</Label>
                                                        <br />
                                                        {
                                                            (userData.user.ROL !== 'EMPRENDEDOR') ?
                                                                <Label className="h5 text-truncate mt-0">{(userData.user.CARGO !== null) ? capitalize(userData.user.CARGO) : ""} en {(userData.user.COMPANY !== null) ? capitalize(userData.user.COMPANY) : ""}</Label>
                                                                :
                                                                ""
                                                        }
                                                        <br />
                                                        <ul className="list-inline mt-lg-2 mt-2">
                                                            {
                                                                (userData.user.FACEBOOK !== undefined && userData.user.FACEBOOK !== null && userData.user.FACEBOOK !== '') ?
                                                                    <li className="list-inline-item mr-2">
                                                                        <Label className="h4">
                                                                            <a href={cleanFacebook(userData.user.FACEBOOK)} target="_blank">
                                                                                <i className="fab fa-facebook-square facebook"></i>
                                                                            </a>
                                                                        </Label>
                                                                    </li>
                                                                    : ""
                                                            }
                                                            {
                                                                (userData.user.INSTAGRAM !== undefined && userData.user.INSTAGRAM !== null && userData.user.INSTAGRAM !== '') ?
                                                                    <li className="list-inline-item mr-2">
                                                                        <Label className="h4">
                                                                            <a href={cleanInstagram(userData.user.INSTAGRAM)} target="_blank">
                                                                                <i className="fab fa-instagram instagram"></i>
                                                                            </a>
                                                                        </Label>
                                                                    </li>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                (userData.user.LINKEDIN !== undefined && userData.user.LINKEDIN !== null && userData.user.LINKEDIN !== '') ?
                                                                    <li className="list-inline-item mr-2">
                                                                        <Label className="h4">
                                                                            <a href={cleanLinkedin(userData.user.LINKEDIN)} target="_blank">
                                                                                <i className="fab fa-linkedin linkedin"></i>
                                                                            </a>
                                                                        </Label>
                                                                    </li>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                (userData.user.TWITTER !== undefined && userData.user.TWITTER !== null && userData.user.TWITTER !== '') ?
                                                                    <li className="list-inline-item mr-2">
                                                                        <Label className="h4">
                                                                            <a href={cleanTwitter(userData.user.TWITTER)} target="_blank">
                                                                                <i className="fab fa-twitter-square twitter"></i>
                                                                            </a>
                                                                        </Label>
                                                                    </li>
                                                                    :
                                                                    ""
                                                            }
                                                            {
                                                                (userData.user.YOUTUBE !== undefined && userData.user.YOUTUBE !== null && userData.user.YOUTUBE !== '') ?
                                                                    <li className="list-inline-item mr-2">
                                                                        <Label className="h4">
                                                                            <a href={cleanYoutube(userData.user.YOUTUBE)} target="_blank">
                                                                                <i className="fab fa-youtube youtube"></i>
                                                                            </a>
                                                                        </Label>
                                                                    </li>
                                                                    :
                                                                    ""
                                                            }
                                                        </ul>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} className="mb-2 mt-2">
                                                        <div>
                                                            <img src={(userData.user.COMPANY_PICTURE !== null) ? userData.user.COMPANY_PICTURE : ""} className="company_home fix_img" alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} className="mb-2 mt-3">
                                                        <Row>
                                                            <Col lg={2} md={3} xs={5} className="text-center">
                                                                <img src={selectPerfilPicture((userData.user.ROL !== null) ? userData.user.ROL : '')} className="avatar-sm rounded-circle fix_img etm_sombra" alt="" />
                                                            </Col>
                                                            <Col lg={10} md={3} xs={7}>
                                                                <Label className="mt-3 h5">{(userData.user.ROL !== null) ? userData.user.ROL : ''}</Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} className="mt-3">
                                                        <Row>
                                                            <Col lg={2} md={3} xs={5} className="text-center">
                                                                <Label className="h5">
                                                                    <i className="fas fa-calendar-alt fa-2x etm_text_blue"></i>
                                                                </Label>
                                                            </Col>
                                                            <Col lg={10} md={3} xs={7}>
                                                                <Label className="h4 mt-2">
                                                                    {(userData.meeting.REUNION_FECHA !== null) ? userData.meeting.REUNION_FECHA : ''}
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={12} className="mt-3">
                                                        <Row>
                                                            <Col lg={2} md={3} xs={5} className="text-center">
                                                                <Label className="h5">
                                                                    <i className="fas fa-clock fa-2x etm_text_blue "></i>
                                                                </Label>
                                                            </Col>
                                                            <Col lg={10} md={3} xs={7}>
                                                                <Label className="h4 mt-2">
                                                                    {(userData.meeting.REUNION_HORA !== null) ? userData.meeting.REUNION_HORA : ''}
                                                                </Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {
                                                        (userData.startup !== undefined && userData.startup !== null) ?
                                                            <Col lg={12} md={12} sm={12} className="mb-2 mt-3">
                                                                <Label className="h5 mt-3">
                                                                    <small>Acerca de {capitalize(userData.user.NOMBRE_CORTO)}...</small>
                                                                    <br />
                                                                    <br />
                                                                    {flecha}
                                                                    {(userData.user.DESCRIPCION !== null) ? userData.user.DESCRIPCION : ""}
                                                                </Label>
                                                            </Col>
                                                            : ""
                                                    }
                                                </Row>
                                            </Container>
                                        </Col>
                                        <Col lg={7} md={6} sm={12} className="etm_modal_solicitar_border_left mt-lg-0 mt-4">
                                            {
                                                (props.tableType === 'EMPRENDEDOR') ?
                                                    ''
                                                    :
                                                    (userData.startup !== undefined && userData.startup !== null) ?
                                                        <Fragment>
                                                            {
                                                                (userData.startup.ETAPA === 'CONSTITUIDO') ?
                                                                    <ModalInfoConstituido
                                                                        startup={userData.startup}
                                                                        closeImageViewer={closeImageViewer}
                                                                        isViewerOpen={isViewerOpen}
                                                                        currentImage={currentImage}
                                                                        listImages={listImages}
                                                                        countries={userData.countries}
                                                                        regions={userData.regions}
                                                                        billing={userData.billing}
                                                                    />
                                                                    :
                                                                    <ModalInfoNoConstituido
                                                                        startup={userData.startup}
                                                                        closeImageViewer={closeImageViewer}
                                                                        isViewerOpen={isViewerOpen}
                                                                        currentImage={currentImage}
                                                                        listImages={listImages}
                                                                    />
                                                            }
                                                            <hr />
                                                        </Fragment>
                                                        : ""
                                            }
                                            <Row>
                                                {
                                                    (userData.startup === undefined || userData.startup === null) ?
                                                        <Col sm={12} className="mb-2 mb-5">
                                                            <Label className="h5">
                                                                <small>Acerca de {capitalize(userData.user.NOMBRE_CORTO)}...</small>
                                                                <br />
                                                                <br />
                                                                {flecha}
                                                                {(userData.user.DESCRIPCION !== null) ? userData.user.DESCRIPCION : ""}
                                                            </Label>
                                                        </Col>
                                                        : ""
                                                }
                                                <Col md={12} sm={12} className="mb-2 mt-2">
                                                    <Label className="h5 text-truncate mt-0 text-wrap">
                                                        {flecha}<b>Objetivo de la Reunion: </b><br />
                                                        {(userData.meeting.OBJETIVO !== null) ? capitalize(userData.meeting.OBJETIVO) : ""}
                                                    </Label>
                                                </Col>
                                                <Col md={12} sm={12} className="mb-2 mt-2">
                                                    <Label className="h5 text-truncate mt-0 text-wrap">
                                                        {flecha}<b>Presentación: </b><br />
                                                        {(userData.meeting.PRESENTACION !== null) ? capitalize(userData.meeting.PRESENTACION) : ""}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            }
                        </ModalBody>
                        <ModalFooter className="modal_solicitar_footer">
                            <a className="h5 pl-5 pr-5 text-danger" onClick={() => props.setModal(false)}><u><i className="fas fa-arrow-left mr-2"></i> Volver</u></a>

                            {/* <Button block={isMobile()} color="success" onClick={() => props.aceptarReunion(props.datos.meet, props.datos.request, props.datos.reagendada)} className="etm_btn btn-lg pl-4 pr-4">
                                <i className="fas fa-check-circle fa-lg mr-2"></i>ACEPTAR REUNIÓN
                            </Button>
                            <Button block={isMobile()} color="danger" onClick={() => props.rechazarReunion(props.datos.meet, props.datos.request, props.datos.reagendada)} className="etm_btn btn-lg pl-4 pr-4">
                                <i className="fas fa-times-circle fa-lg mr-2"></i>RECHAZAR REUNIÓN
                            </Button>
                            <Button hidden={!props.datos.is_private} block={isMobile()} color="warning" onClick={() => props.reagendarReunion(props.datos.meet, props.datos.request, props.datos.is_private)} className="etm_btn btn-lg pl-4 pr-4" >
                                <i className="fas fa-calendar-alt fa-lg mr-2"></i>REAGENDAR
                            </Button>

                            <Button block={isMobile()} color="danger" onClick={() => props.cancelarReunion(props.datos.meet, props.datos.request, props.datos.is_private)} className="etm_btn btn-lg pl-4 pr-4">
                                <i className="fas fa-times-circle fa-lg mr-2"></i>RECHAZAR REUNIÓN
                            </Button>
                            <Button block={isMobile()} color="success" onClick={() => props.agendarNuevaReunion(props.datos.meet, props.datos.request, props.datos.is_private)} className="etm_btn btn-lg pl-4 pr-4">
                                <i className="fas fa-times-circle fa-lg mr-2"></i>RECHAZAR REUNIÓN
                            </Button> */}

                        </ModalFooter>
                    </Modal>
            }

        </Fragment>
    );
}


export default ModalRequest;