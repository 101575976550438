import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Button, UncontrolledTooltip } from "reactstrap";

import { withRouter } from "react-router-dom";
import { API } from '../../../helpers/axios';

import { capitalize, swal_etm, cleanFacebook, cleanInstagram, cleanLinkedin, cleanTwitter, success_msg } from "../../../helpers/etmmeetUtils";
import emprendedor from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../../assets/images/conecTime/design/Perfil-gerente.png";
import LoadScreen from "../../../components/Utils/LoadScreen";

 const ModalFullData = ( props ) => {
    const[card, setCard]           = useState([]);
    const[loading, setLoading]      = useState(false);

    useEffect(() => { 
        getFullDataUser(props.userId);
    }, [props.userId]);

    const handleModal = () => {
        props.setShowData(!props.showModal);
    }

    const getFullDataUser = async ( userId ) => {
        setLoading(true);
        if(userId > 0){
            const percentage = await API.get('/getPercentageProfile', { params: { user_id: userId } });
            const res = await API.get('/getAdminDataUser', { params: { userId } });
            if (res.data.state) {

                let perfil = "";
                let perfil_img = "";
                switch(res.data.data.user.PERFIL){
                    case "ADMINISTRADOR":
                        perfil      = "Gerente/Ejecutivo";
                        perfil_img  = gerente;
                        break;
                    case "GERENTE/EJECUTIVO":
                        perfil      = "Gerente/Ejecutivo";
                        perfil_img  = gerente;
                        break;
                    case "INVERSIONISTA":
                        perfil      = "Inversionista";
                        perfil_img  = inversionista;
                        break;
                    case "MENTOR":
                        perfil      = "Mentor";
                        perfil_img  = mentor;
                        break;
                    case "EMPRENDEDOR":
                        perfil      = "Emprendedor";
                        perfil_img  = emprendedor;
                        break;
                }
        
                let card =
                    <Row className="mb-3 mt-3" key={ 0 }>
                        <Col lg={4} md={12}>
                            <div className="etm_card">
                                <Row>
                                    <Col sm={12}>
                                        <div className="profile-user-wid mb-3 text-center">
                                            <img src={ res.data.data.user.FOTO } alt="" className="img-thumbnail avatar-xl rounded-circle fix_img shadow-lg"/> 
                                        </div>
                                    </Col>
                                    <Col sm={12} className="text-center">
                                        <h3 className="etm_text_blue mb-2">{ capitalize(res.data.data.user.NOMBRE) }</h3>
                                        { 
                                            (res.data.data.user.PERFIL !== "EMPRENDEDOR")  ?
                                                <Fragment>
                                                    <h5 className="text-muted">{ res.data.data.user.CARGO }</h5>
                                                    <h5 className="text-muted">{ res.data.data.user.GERENCIA }</h5>
                                                    <h4 className="text-muted">{ res.data.data.user.EMPRESA }</h4>
                                                    { 
                                                        (res.data.data.user.FOTO_EMPRESA !== null && res.data.data.user.FOTO_EMPRESA !== undefined) ? 
                                                            <div className="profile-user-wid mt-md-4 mt-3 mb-md-4 mb-3 text-center">
                                                                <img src={ res.data.data.user.FOTO_EMPRESA } alt="" className="company_home fix_img"/>
                                                            </div>
                                                        :   ""
                                                    }
                                                    <h5 className="text-muted">{ res.data.data.user.INDUSTRIA }</h5>
                                                </Fragment>
                                            :   "" 
                                        }
                                        <h5 className="text-muted">{ res.data.data.user.SITIO_WEB }</h5>
                                    </Col>
                                    <Col xs={12}>
                                        <ul className="list-inline text-center mt-3">
                                            <li className="list-inline-item mr-2">
                                                {
                                                    (res.data.data.user.FACEBOOK !== null && res.data.data.user.FACEBOOK !== "") ? 
                                                        <h4>
                                                            <a href={cleanFacebook(res.data.data.user.FACEBOOK)} target="_blank">
                                                                <i className="fab fa-facebook-square fa-1x facebook"></i>
                                                            </a>
                                                        </h4>
                                                        : 
                                                        <h6 className="text-white">-</h6>
                                                }
                                            </li> 
                                            <li className="list-inline-item mr-2">
                                                {
                                                    (res.data.data.user.INSTAGRAM !== null && res.data.data.user.INSTAGRAM !== "") ? 
                                                        <h4>
                                                            <a href={cleanInstagram(res.data.data.user.INSTAGRAM)} target="_blank">
                                                                <i className="fab fa-instagram fa-1x instagram"></i>
                                                            </a>
                                                        </h4>
                                                        : 
                                                        <h6 className="text-white">-</h6>
                                                }
                                            </li> 
                                            <li className="list-inline-item mr-2">
                                                {
                                                    (res.data.data.user.LINKEDIN !== null && res.data.data.user.LINKEDIN !== "") ? 
                                                        <h4>
                                                            <a href={cleanLinkedin(res.data.data.user.LINKEDIN)} target="_blank">
                                                                <i className="fab fa-linkedin fa-1x linkedin"></i>
                                                            </a>
                                                        </h4>
                                                        : 
                                                        <h6 className="text-white">-</h6>
                                                }
                                            </li> 
                                            <li className="list-inline-item mr-2">
                                                {
                                                    (res.data.data.user.TWITTER !== null && res.data.data.user.TWITTER !== "") ? 
                                                        <h4>
                                                            <a href={cleanTwitter(res.data.data.user.TWITTER)} target="_blank">
                                                                <i className="fab fa-twitter-square fa-1x twitter"></i>
                                                            </a>
                                                        </h4>
                                                        : 
                                                        <h6 className="text-white">-</h6>
                                                }
                                            </li> 
                                        </ul>
                                    </Col>
                                    <Col lg={12}>
                                        <h5 className="text-muted text-center"><span className={`text-white badge rounded-pill pl-3 pr-3 ${(percentage.data.percentage < 50) ? `bg-danger` : (percentage.data.percentage < 99) ? `bg-warning` : `bg-success` }`}>Perfil al { percentage.data.percentage } %</span></h5>
                                        <hr/>
                                        <Row className="pt-2 pb-2">
                                            <Col xs={6} className="text-right">
                                                <div>
                                                    <img src={ perfil_img } className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg" alt="img"/>
                                                </div>
                                            </Col>
                                            <Col xs={6} className="text-left my-auto">
                                                <h6 className="text-truncate font-weight-medium"><b>Perfil</b></h6>
                                                <h6 className="text-truncate font-weight-medium">{ perfil }</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={8} md={12}>
                            <Row>
                                <Col xs={12}>
                                    <h5 className="text-muted ml-md-5 ml-3 pt-lg-0 pt-4"><i className="fas fa-user mr-2 etm_text_blue"></i>DATOS PERSONALES</h5>
                                    <hr/>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Email:<span className="text-muted ml-2">{ res.data.data.user.EMAIL }</span><span className="ml-2 pointer etm_link_blue" id="full_data_email" onClick={() =>  (navigator.clipboard.writeText( res.data.data.user.EMAIL ), success_msg('Email copiado'))} ><i className="fas fa-copy mr-2"></i></span></h5>
                                    <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="right" target="full_data_email">Copiar email </UncontrolledTooltip>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Región:<span className="text-muted ml-2">{ res.data.data.user.REGION }</span></h5>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Comuna:<span className="text-muted ml-2">{ res.data.data.user.CIUDAD }</span></h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">RUT:<span className="text-muted ml-2">{ res.data.data.user.RUT }</span></h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Teléfono:<span className="text-muted ml-2">{ res.data.data.user.TELEFONO }</span><span className="ml-2 pointer etm_link_blue" id="full_data_telefono" onClick={() =>  (navigator.clipboard.writeText( res.data.data.user.TELEFONO ), success_msg('Teléfono copiado'))} ><i className="fas fa-copy mr-2"></i></span></h5>
                                    <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="right" target="full_data_telefono">Copiar teléfono </UncontrolledTooltip>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Fecha de Nacimiento:<span className="text-muted ml-2">{ res.data.data.user.FECHA_NACIMIENTO }</span></h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Género:<span className="text-muted ml-2">{ res.data.data.user.GENERO }</span></h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Fecha de registro:<span className="text-muted ml-2">{ res.data.data.user.FECHA_CREACION }</span></h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Vigencia:
                                        { 
                                            (res.data.data.user.VIGENCIA) ?
                                                <span className="ml-2 text-white badge bg-success rounded-pill pl-3 pr-3">Vigente</span>
                                            :   <span className="ml-2 text-white badge bg-danger rounded-pill pl-3 pr-3">No Vigente</span>
                                        }
                                    </h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Sesión iniciada en Navegador:
                                        { 
                                            (res.data.data.user.SESION) ?
                                                <span className="ml-2 text-white badge bg-success rounded-pill pl-3 pr-3">Si</span>
                                            :   <span className="ml-2 text-white badge bg-danger rounded-pill pl-3 pr-3">No</span>
                                        }
                                    </h5>
                                </Col>
                                <Col lg={6} md={12}>
                                    <h5 className="etm_text_blue mt-2 mb-2">Sesión iniciada en Móvil:
                                        { 
                                            (res.data.data.user.SESION_MOBILE) ?
                                                <span className="ml-2 text-white badge bg-success rounded-pill pl-3 pr-3">Si</span>
                                            :   <span className="ml-2 text-white badge bg-danger rounded-pill pl-3 pr-3">No</span>
                                        }
                                    </h5>
                                </Col>
                                <Col xs={12}>
                                    <h5 className="text-muted mt-3 pt-3 pb-5">{ res.data.data.user.DESCRIPCION }</h5>
                                    {
                                        (res.data.data.user.PERFIL !== "EMPRENDEDOR") ?
                                            (res.data.data.expertise) ? 
                                                <Fragment>
                                                    <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-clipboard-list mr-2 etm_text_blue"></i>EXPERTIZ</h5>
                                                    <hr/>
                                                    {
                                                        res.data.data.expertise.map((e, i) => {
                                                            return <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2"></i>{ e.EXPERTIZ }</h5>
                                                        })
                                                    }
                                                    <br/>
                                                </Fragment>
                                            :   ""
                                        :   ""
                                    }
                                </Col>
                            </Row>
                        </Col>

                        {
                            (res.data.data.user.PERFIL !== "EMPRENDEDOR") ?
                                <Fragment>
                                    <Col xs={12}>
                                        <br/>
                                        <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-search mr-2 etm_text_blue"></i>DATOS DE INTERÉS</h5>
                                        <hr/>
                                    </Col>
                                    <Col lg={6} md={12}>
                                        {
                                            (res.data.data.interest.industry) ? 
                                                <Fragment>
                                                    <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Industrias de interés</h5>
                                                    <br/>
                                                    {
                                                        res.data.data.interest.industry.map((e, i) => {
                                                            return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ e.INDUSTRIA }</h5>
                                                        })
                                                    }
                                                    <br/>
                                                </Fragment>
                                            :   ""
                                        }
                                    </Col>
                                    <Col lg={6} md={12}>
                                        {
                                            (res.data.data.interest.age_range) ? 
                                                <Fragment>
                                                    <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Antigüedad de las empresas de interés</h5>
                                                    <br/>
                                                    {
                                                        res.data.data.interest.age_range.map((e, i) => {
                                                            return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ e.ANTIGUEDAD }</h5>
                                                        })
                                                    }
                                                    <br/>
                                                </Fragment>
                                            :   ""
                                        }
                                    </Col>
                                    <Col lg={6} md={12}>
                                        {
                                            (res.data.data.interest.billing) ? 
                                                <Fragment>
                                                    <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Rangos de facturación de interés</h5>
                                                    <br/>
                                                    {
                                                        res.data.data.interest.billing.map((e, i) => {
                                                            return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ e.FACTURACION }</h5>
                                                        })
                                                    }
                                                    <br/>
                                                </Fragment>
                                            :   ""
                                        }
                                    </Col>
                                    <Col lg={6} md={12}>
                                        {
                                            (res.data.data.interest.region) ? 
                                                <Fragment>
                                                    <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Regiones de interés</h5>
                                                    <br/>
                                                    {
                                                        res.data.data.interest.region.map((e, i) => {
                                                            return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ e.REGION }</h5>
                                                        })
                                                    }
                                                    <br/>
                                                </Fragment>
                                            :   ""
                                        }
                                    </Col>
                                </Fragment>
                            :   ""
                        }

                        {
                            (res.data.data.user.PERFIL === "EMPRENDEDOR") ?
                                <Fragment>
                                    <Col xs={12}>
                                        {
                                            (res.data.data.startup) ? 
                                                <Fragment>
                                                    <h5 className="text-muted pt-3 ml-md-5 ml-3"><i className="fas fa-suitcase mr-2 etm_text_blue"></i>EMPRENDIMIENTOS</h5>
                                                    {
                                                        res.data.data.startup.map((e, i) => {
                                                            return  <Fragment>
                                                                        <hr/>
                                                                        <Row>
                                                                            <Col lg={4} md={12}>
                                                                                <div className="profile-user-wid mt-md-4 mt-3 mb-md-4 mb-3 text-center">
                                                                                    <img src={ e.FOTO} alt="" className="company_home fix_img"/>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={4} md={12}>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Nombre:<span className="text-muted ml-2">{ e.NOMBRE }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">RUT:<span className="text-muted ml-2">{ e.RUT }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Industria:<span className="text-muted ml-2">{ e.INDUSTRIA }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Giro:<span className="text-muted ml-2">{ e.GIRO }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Sitio web:<span className="text-muted ml-2">{ e.SITIO_WEB }</span></h5>
                                                                            </Col>
                                                                            <Col lg={4} md={12}>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Inicio actividades:<span className="text-muted ml-2">{ e.INICIO_ACTIVIDADES }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Cantidad empleados:<span className="text-muted ml-2">{ e.EMPLEADOS }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Ha recibido inversión pública:<span className="text-muted ml-2">{ e.INVERSION_PUBLICA }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Ha recibido inversión privada:<span className="text-muted ml-2">{ e.INVERSION_PRIVADA }</span></h5>
                                                                                <h5 className="etm_text_blue mt-2 mb-2">Facturas emitidas:<span className="text-muted ml-2">{ e.FACTURAS }</span></h5>
                                                                            </Col>
                                                                            <Col xs={12} className="text-center">
                                                                                <h5 className="text-muted mt-3 pt-3 pb-3">{ e.DESCRIPCION }</h5>
                                                                            </Col>
                                                                            <Col lg={4} md={12}>
                                                                                {
                                                                                    (e.region) ? 
                                                                                        <Fragment>
                                                                                            <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Regiones donde opera</h5>
                                                                                            <br/>
                                                                                            {
                                                                                                e.region.map((x, z) => {
                                                                                                    return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ x.REGION }</h5>
                                                                                                })
                                                                                            }
                                                                                            <br/>
                                                                                        </Fragment>
                                                                                    :   ""
                                                                                }
                                                                            </Col>
                                                                            <Col lg={4} md={12}>
                                                                                {
                                                                                    (e.leader) ? 
                                                                                        <Fragment>
                                                                                            <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Líderes de proyectos</h5>
                                                                                            <br/>
                                                                                            {
                                                                                                e.leader.map((x, z) => {
                                                                                                    return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ x.NOMBRE }</h5>
                                                                                                })
                                                                                            }
                                                                                            <br/>
                                                                                        </Fragment>
                                                                                    :   ""
                                                                                }
                                                                            </Col>
                                                                            <Col lg={4} md={12}>
                                                                                {
                                                                                    (e.billing) ? 
                                                                                        <Fragment>
                                                                                            <h5 className="text-muted"><i className="fas fa-arrow-alt-circle-right mr-2 etm_text_blue"></i>Rangos de facturación</h5>
                                                                                            <br/>
                                                                                            {
                                                                                                e.billing.map((x, z) => {
                                                                                                    return <h5 className="text-muted ml-md-5 ml-3"><i className="fas fa-long-arrow-alt-right mr-2"></i>{ x.FACTURACION }</h5>
                                                                                                })
                                                                                            }
                                                                                            <br/>
                                                                                        </Fragment>
                                                                                    :   ""
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Fragment>  
                                                        })
                                                    }
                                                </Fragment>
                                            :   ""
                                        }
                                    </Col>
                                </Fragment>
                            :   ""
                        }
                           
                    </Row>;
                setCard(card);
            } else {
                swal_etm(`<p>No se pudo recuperar los datos de este usuario.</p>`, `warning`, ``);
            }
        }
        setLoading(false);
    }

    return (
        <Fragment>
            <Modal isOpen={ props.showModal } size="xl" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={12}>
                            {
                                (loading) ?
                                    <LoadScreen text="Cargando perfil..."/>
                                :   card
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleModal } size="md" className="etm_btn pl-3 pr-3"><i className="fas fa-times mr-2"></i>Volver</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalFullData);