import React from 'react';
import { Container, Row, Col } from "reactstrap";
import PendingList from "../../components/Inbox/PendingList";
import "../../assets/scss/etm.scss";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';

const Pending = () => {

    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title={'Mis Reuniones'} />
                <Row>
                    <Col lg="12">
                        <PendingList showBtn={ true }/>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
    );
}

export default withNamespaces()(Pending);