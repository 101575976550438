import React, { useState, useEffect, Fragment } from 'react';

import { Card, CardImg, CardBody, CardTitle, CardHeader, CardSubtitle, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { API } from '../../helpers/axios';
import "../../assets/scss/etm.scss";

import { getLocalStorage } from "../../helpers/etmmeetUtils";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ModalChalleng from "../Challenges/modals/ModalChalleng";

const Challenges = (props) => {
    const ls = getLocalStorage();
    const numberCard = (window.innerWidth > 1024) ? 6 : (window.innerWidth >= 768) ? 2 : 1;
    const [challenges, setChallenges] = useState([]);
    const [modalCrateChallenges, setModalCrateChallenges] = useState(false);
    const [modalType, setModalType] = useState('');
    const [showCardNumber, setShowCardNumber] = useState(numberCard);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: showCardNumber,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        appendDots: dots => (
            <div></div>
        ),
        customPaging: i => (
            <div></div>
        )
    };

    useEffect(() => {
        getDesafios();
    }, []);

    const openModal = (type, id = 0) => {
        setModalType({ type: type, id: id })
        setModalCrateChallenges(true)
    }

    const getDesafios = async () => {
        const res = await API.get("/challenger/getChallengersCards", { params: { token: ls.token, start: 0 } })
        let list = [];

        if (res.data.state) {
            let rellenar = false;

            if(numberCard === 6){
                if(res.data.challengers.length >= 6){
                    setShowCardNumber(6);
                }else if(res.data.challengers.length >= 3){
                    setShowCardNumber(res.data.challengers.length);
                }else{
                    setShowCardNumber(3);
                    rellenar = true;
                }
            }else if(numberCard === 2){
                if(res.data.challengers.length >= 2){
                    setShowCardNumber(2);
                }else{
                    setShowCardNumber(1);
                }
            }else{
                setShowCardNumber(1);
            }

            res.data.challengers.map((e, i) => {
                list.push(
                    <div className={`pl-1 pr-1 carousel-item ${(i === 0) ? `active` : ``}`} key={i}>
                        <div className="ml-0 mr-0">
                            <a onClick={() => openModal('detalle', e.ID)} className="pointer">
                                <Card>
                                    <CardHeader className="p-0 m-0">
                                        <div className="profile-user-wid text-center">
                                            <img src={e.FOTO} alt={e.DESAFIO} className="img-fluid fix_img etm_home_challenge_img" />
                                        </div>
                                    </CardHeader>
                                    <CardBody className="mt-0 mb-0 pt-0 pb-0">
                                        <CardTitle className="mt-0 mb-0 pt-0 pb-0 text-black">{e.DESAFIO}</CardTitle>
                                        <CardSubtitle className="mt-0 mb-0 pt-0 pb-0">{e.DESCRIPCION_CORTA}</CardSubtitle>
                                    </CardBody>
                                </Card>
                            </a>
                        </div>
                    </div>
                );
            });
            
            if(rellenar){
                if(list.length === 2){
                    list.push(list[0]);
                }else{
                    list.push(list[0]);
                    list.push(list[0]);
                }
            }
        }
        setChallenges(list);
    };

    return (
        (challenges.length > 0) ?
            <Fragment>

                <Slider {...settings}>
                    {challenges}
                </Slider>
                <div className="text-center mb-2 mt-0">
                    <Link to="/challenges_searchfor"><Label className="h4 etm_link_blue pointer"><i className="fas fa-arrow-right etm_text_blue mr-2"></i><u>Ver todos los desafíos</u> </Label></Link>
                </div>

                <ModalChalleng
                    modal={modalCrateChallenges}
                    setModal={setModalCrateChallenges}
                    type={modalType}
                />
            </Fragment>
            : ""
    );
}

export default Challenges;