import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Label } from "reactstrap";

import { capitalize } from '../../../helpers/etmmeetUtils';

import ImageViewer from "react-simple-image-viewer";


const ModalInfoConstituido = (props) => {

    const [loading, setLoading] = useState(false);
    const [paises, setPaises] = useState('');

    const separarPorComas = (countries) => {
        let paises = countries.reduce((prev, cur) => {
            return prev ? prev + ', ' + cur.name : cur.name
        }, '')
        return paises
    }

    const esChile = (pais) => {
        return pais.name === 'Chile'
    }

    return (
        <Row className="animate__animated animate__fadeIn animate__delay-1s">
            <Col md={12}>
                <Row>
                    <Col md={12} className="mb-0">
                        <Row>
                            <Col md={6} sm={12} className="mb-2 mt-2">
                                <Label className="h2 text-truncate etm_text_blue mb-0 text-wrap">
                                    {capitalize(props.startup.NOMBRE)}
                                </Label>
                                {
                                    (props.startup.WEB !== undefined && props.startup.WEB !== null && props.startup.WEB !== '') ?
                                        <Fragment>
                                            <br />
                                            <li className="list-inline-item mr-2">
                                                <Label className="h5">
                                                    <a href={props.startup.WEB} target="_blank" className="etm_link_blue">
                                                        <i className="fas fa-external-link-alt"></i> {props.startup.WEB}
                                                    </a>
                                                </Label>
                                            </li>
                                        </Fragment>
                                        :
                                        ""
                                }
                                <br />
                                <Label className="h5 text-truncate mt-0">
                                    <b>Etapa: </b>{(props !== null) ? capitalize(props.startup.ETAPA) : ""}
                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0">
                                    <b>Industria: </b>{(props !== null) ? capitalize(props.startup.INDUSTRIA) : ""}
                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0">
                                    <b>Tipo Comercio: </b>{(props !== null) ? capitalize(props.startup.TIPO_COMERCIO) : ""}
                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0">
                                    <b>Inicio Actividades:</b> {(props !== null) ? capitalize(props.startup.INICIO_ACTIVIDADES) : ""}
                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    <b>Nº Empleados: </b>{(props !== null) ? props.startup.NUMERO_EMPLEADOS : ""}
                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    {
                                        (props.countries !== null && props.countries.length > 1) ?
                                            <Fragment><b>Paises Operación: </b>{(props !== null) ? separarPorComas(props.countries) : ""}</Fragment>
                                            :
                                            <Fragment><b>Pais Operación: </b>{(props !== null) ? separarPorComas(props.countries) : ""}</Fragment>

                                    }

                                </Label>
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    {
                                        (props.regions !== null && props.countries !== null && props.countries.length > 1 && props.countries.find(esChile) !== undefined) ?
                                            (props.regions.length > 1) ?
                                                <Fragment><b>Regiones Operación: </b>{(props.regions !== null) ? separarPorComas(props.regions) : ""}</Fragment>
                                                :
                                                <Fragment><b>Region Operación: </b>{(props.regions !== null) ? separarPorComas(props.regions) : ""}</Fragment>
                                            :
                                            ""
                                    }

                                </Label>
                                <br />
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    <b>Rango Facturacion ultimo Año ({(props !== null) ? props.billing.YEAR : ""}): </b>{(props !== null) ? props.billing.BILLING : ""}
                                </Label>

                            </Col>
                            <Col md={6} sm={12} className="mb-2 mt-2">
                                {
                                    (props !== null && props.startup.POLITICAS_IGUALDAD_GENERO > 0) ?
                                        <Fragment>
                                            <br />
                                            <Label className="h5 text-truncate mt-0 text-wrap">
                                                <b>Politicas de Igualdad: </b> SI
                                            </Label>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    (props !== null && props.startup.INCLUSION_IGUALDAD > 0) ?
                                        <Fragment>
                                            <br />
                                            <Label className="h5 text-truncate mt-0 text-wrap">
                                                <b>Inclusion: </b> SI
                                            </Label>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    (props !== null && props.startup.LIDERADO_X_MUJERES > 0) ?
                                        <Fragment>
                                            <br />
                                            <Label className="h5 text-truncate mt-0 text-wrap">
                                                <b>Liderado por mujeres: </b> SI
                                            </Label>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    (props !== null) ?
                                        <Fragment>
                                            <br />
                                            <Label className="h5 text-truncate mt-0 text-wrap">
                                                <b>Impacto social: </b>{props.startup.IMPACTO_SOCIAL}
                                            </Label>
                                        </Fragment>
                                        : ""
                                }
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    <b>Inversiones Recibidas: </b>{(props !== null) ? props.startup.INVERSIONES_RECIBIDAS : ""}
                                </Label>
                                <Label className="h5 text-truncate mt-0 text-wrap">
                                    <b>Desarrollo Sostenible: </b>
                                    {(props !== null) ? capitalize(props.startup.DESARROLLO_SOSTENIBLE) : ""}
                                </Label>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className="mb-4">
                        <Label className="h4">
                            <i className="fas fa-arrow-right etm_text_blue mr-2"></i>{(props !== null) ? props.startup.DESCRIPCION : ""}
                        </Label>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                {props.listImages.imgHtml}
                {props.isViewerOpen && (
                    <ImageViewer
                        src={props.listImages.images}
                        currentIndex={props.currentImage}
                        onClose={props.closeImageViewer}
                        disableScroll={false}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)"
                        }}
                        closeOnClickOutside={true}
                    />
                )}
            </Col>
        </Row>
    );
}


export default ModalInfoConstituido;