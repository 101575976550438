import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { API } from '../../helpers/axios';
import { capitalize, cleanFacebook, cleanInstagram, cleanLinkedin, cleanTwitter } from "../../helpers/etmmeetUtils";
import { withRouter } from "react-router-dom";
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";

const ModalParticipant = ( props ) => {
    
    const [showModal, setShowModal] = useState(false);
    const[card, setCard]            = useState([]);
    
    useEffect(() => { 
        setShowModal(props.showModal);
    }, [props.showModal]);

    useEffect(() => {
        getParticipantCard();
    }, [props.showModal]);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    const getParticipantCard = async () => {
        if(props.showModal){
            const res = await API.get('/getParticipantCard', { params: { user_id: props.userId }});
            setCard([]);
            if(res.data.state){
                let perfil = "";
                let perfil_img = "";
                switch(res.data.participant.rol){
                    case "ADMINISTRADOR":
                        perfil = "Gerente/Ejecutivo";
                        perfil_img = gerente;
                        break;
                    case "GERENTE/EJECUTIVO":
                        perfil = "Gerente/Ejecutivo";
                        perfil_img = gerente;
                        break;
                    case "INVERSIONISTA":
                        perfil = "Inversionista";
                        perfil_img = inversionista;
                        break;
                    case "MENTOR":
                        perfil = "Mentor";
                        perfil_img = mentor;
                        break;
                    case "EMPRENDEDOR":
                        perfil = "Emprendedor";
                        perfil_img = emprendedor;
                        break;
                }

                let newcard =
                    <Row className="mb-3 mt-3">
                        <Col lg={4}>
                            <div className="etm_card h-100">
                                <Row>
                                    <Col sm={12}>
                                        <div className="profile-user-wid text-center">
                                            <img src={ res.data.participant.profile_picture } alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"/> 
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className="progress mt-3 ml-3 mr-3" id="progress_bar">
                                            <div className={ res.data.participant.percentage.bg_color + " progress-bar progress-bar-animated progress-bar-striped" } role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: res.data.participant.percentage.value + '%' }}> { res.data.participant.percentage.value } %</div>
                                        </div>
                                    </Col> 
                                    <Col sm={12} className="text-center mt-3">
                                        <h4 className="etm_text_blue mb-2">{ capitalize(res.data.participant.name) } { capitalize(res.data.participant.last_name)}</h4>
                                        { 
                                            (res.data.participant.rol !== "EMPRENDEDOR")  ?
                                                <Fragment>
                                                    <h5 className="text-muted">{ res.data.participant.job_title }</h5>
                                                    <h5 className="text-muted">{ res.data.participant.management }</h5>
                                                    <h5 className="text-muted">{ res.data.participant.company.company }</h5> 
                                                    { 
                                                        (res.data.participant.company.company_picture !== null && res.data.participant.company.company_picture !== undefined) ? 
                                                            <div className="profile-user-wid mt-3 mb-3 text-center">
                                                                <img src={ res.data.participant.company.company_picture } alt="" className="company_home fix_img"/>
                                                            </div>
                                                        :   ""
                                                    }
                                                    <h5 className="text-muted">{ res.data.participant.industry }</h5>
                                                </Fragment>
                                            :   "" 
                                        }
                                        <h5 className="text-muted">{ res.data.participant.web_page }</h5>
                                    </Col>
                                    <Col lg="12" className="pt-3">
                                        <hr/>
                                        <Row>
                                            <Col xs={6} className="text-right">
                                                <div>
                                                    <img src={ perfil_img } className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img"/>
                                                </div>
                                            </Col>
                                            <Col xs={6} className="text-left">
                                                <h6 className="text-truncate font-weight-medium"><b>Perfil</b></h6>
                                                <h6 className="text-truncate font-weight-medium">{ perfil }</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <h5 className="text-muted mt-3">{ capitalize(res.data.participant.description) }</h5>
                            <br/>
                            {
                                (res.data.participant.rol !== "EMPRENDEDOR")  ?
                                    <Fragment>
                                        <h5 className="etm_text_blue">Experticias<br/><span className="text-muted">{ res.data.participant.expertise }</span></h5>
                                        <h5 className="etm_text_blue">Industrias de Interés<br/><span className="text-muted">{ res.data.participant.interest.categories }</span></h5>
                                        <h5 className="etm_text_blue">Antigüedad de empresas que le interesan<br/><span className="text-muted">{ res.data.participant.interest.age_rage }</span></h5>
                                        <h5 className="etm_text_blue">Rango de Facturación que le interesan<br/><span className="text-muted">{ res.data.participant.interest.billing_1 } - { res.data.participant.interest.billing_2 }</span></h5>
                                    </Fragment>
                                :   ""
                            }
                            <ul className="list-inline text-center mt-3">
                                <li className="list-inline-item mr-2">
                                    {
                                        (res.data.participant.rrss.facebook !== null && res.data.participant.rrss.facebook !== "") ? 
                                            <h4>
                                                <a href={cleanFacebook(res.data.participant.rrss.facebook)} target="_blank">
                                                    <i className="fab fa-facebook-square fa-2x facebook"></i>
                                                </a>
                                            </h4>
                                            : 
                                            <h6 className="text-white">-</h6>
                                    }
                                </li> 
                                <li className="list-inline-item mr-2">
                                    {
                                        (res.data.participant.rrss.instagram !== null && res.data.participant.rrss.instagram !== "") ? 
                                            <h4>
                                                <a href={cleanInstagram(res.data.participant.rrss.instagram)} target="_blank">
                                                    <i className="fab fa-instagram fa-2x instagram"></i>
                                                </a>
                                            </h4>
                                            : 
                                            <h6 className="text-white">-</h6>
                                    }
                                </li> 
                                <li className="list-inline-item mr-2">
                                    {
                                        (res.data.participant.rrss.linkedin !== null && res.data.participant.rrss.linkedin !== "") ? 
                                            <h4>
                                                <a href={cleanLinkedin(res.data.participant.rrss.linkedin)} target="_blank">
                                                    <i className="fab fa-linkedin fa-2x linkedin"></i>
                                                </a>
                                            </h4>
                                            : 
                                            <h6 className="text-white">-</h6>
                                    }
                                </li> 
                                <li className="list-inline-item mr-2">
                                    {
                                        (res.data.participant.rrss.twitter !== null && res.data.participant.rrss.twitter !== "") ? 
                                            <h4>
                                                <a href={cleanTwitter(res.data.participant.rrss.twitter)} target="_blank">
                                                    <i className="fab fa-twitter-square fa-2x twitter"></i>
                                                </a>
                                            </h4>
                                            : 
                                            <h6 className="text-white">-</h6>
                                    }
                                </li> 
                            </ul>
                            
                            {
                                (res.data.participant.rol === "EMPRENDEDOR") ?
                                    (res.data.participant.startups !== null && res.data.participant.startups !== undefined && res.data.participant.startups > 0) ?
                                            <Fragment>
                                                <hr/>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="profile-user-wid mt-2 text-center">
                                                            <img src={ res.data.participant.startups[0].photo } alt="" className="img-thumbnail avatar-lg fix_img shadow-lg"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6} className="text-lg-left text-center">
                                                        <h4 className="etm_text_blue">{ res.data.participant.startups[0].name }</h4>
                                                        <h5 className="text-muted">{ res.data.participant.startups[0].industry }</h5>
                                                        <h5 className="text-muted">{ res.data.participant.startups[0].description }</h5>
                                                        <h5 className="text-muted">{ res.data.participant.startups[0].web_page }</h5>
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                    :    ""
                                :    ""
                            }
                        </Col>
                    </Row>;
                setCard(newcard);
            }
        }
    };
   
    return (
        <Fragment>
            <Modal isOpen={ showModal } size="xl" centered={ true } toggle={ handleModal }>
                <ModalBody>
                    { card }
                </ModalBody>
                <ModalFooter>
                    <h5 className="etm_link_blue pointer" onClick={ handleModal }>Cerrar</h5>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
    }
export default withRouter(ModalParticipant);
 