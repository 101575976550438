import React, { useEffect, useRef, useState, Fragment } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { Link, withRouter } from 'react-router-dom';
import { Col, CardBody, Card, Container, Row } from "reactstrap";
import { API } from '../../helpers/axios';
import swal from '@sweetalert/with-react';
import Iframe from 'react-iframe';
import moment from 'moment';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/etm.scss";
import { capitalize, linkProfile, validateNavigator, getAgentData, getDatetime, validateRol } from "../../helpers/etmmeetUtils";
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import ModalParticipant from "../../components/Meet/ModalParticipant";
import ModalEvaluation from '../../components/Meet/ModalEvaluation';
import ModalShareData from '../../components/Meet/ModalShareData';
import ModalPermissions from '../../components/Meet/ModalPermissions';
import ModalPassword from '../../components/Meet/ModalPassword';
import ModalReserveHours from '../../components/Meet/ModalReserveHours';
import TableExperto from "../../components/Requests/tables/tableExperto";
import TableEmprendedor from "../../components/Requests/tables/tableEmprendedor";

const publicIp = require('public-ip');

const Meet = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    // const urlzoom = "https://master.ddx8uj6zu0njg.amplifyapp.com/";
    // const urlzoom = "https://master.dt8hn5aaohuia.amplifyapp.com/";
    const urlTwilio = "https://main.d1s8307hvnqoes.amplifyapp.com/";

    const [nameRoom, setNameRoom] = useState("");
    const [show, setShow] = useState(false);
    const [manager, setManager] = useState(0);
    const [modalParticipant, setModalParticipant] = useState(false);
    const [modalPermissions, setModalPermissions] = useState(false);
    const [modalReserveHours, setModalReserveHours] = useState(false);
    const [zoomHeightLess, setZoomHeightLess] = useState(320);
    const [zoomHeight, setZoomHeight] = useState(window.innerHeight - zoomHeightLess);
    const [modalPassword, setModalPassword] = useState(false);
    const [zoomPassword, setZoomPassword] = useState("");
    const [zoomID, setZoomID] = useState("");

    const [compatibilityAlert, setCompatibilityAlert] = useState(false);
    const [compatibilityMessage, setCompatibilityMessage] = useState("");
    const [compatibilityColor, setCompatibilityColor] = useState("text-danger");
    const [compatibilityUrl, setCompatibilityUrl] = useState("");
    const [meetID, setMeetID] = useState(0);
    const [meeting, setMeeting] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [meets, setMeets] = useState([]);
    const [waitingMeet, setWaitingMeet] = useState(false);
    const [userName, setUserName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState(0);
    const [questionsModal, setQuestionsModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [idOtherParticipant, setIdOtherParticipant] = useState(null);
    const [buttonReport, setButtonReport] = useState(false);
    const [cardManagerId, setCardManagerId] = useState(0);
    const [cardUserId, setCardUserId] = useState(0);
    const [meetData, setMeetData] = useState({});
    const [finishComingSoon, setFinishComingSoon] = useState(false);

    const meetShowRef = useRef(show);

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        }
    }, [zoomHeight]);

    useEffect(() => {
        saveNetworkInfo("Sala de Espera");
    }, []);

    useEffect(() => {
        alertFinishComingSoon();
    }, [finishComingSoon]);

    useEffect(() => {
        getCompatibility();
    }, [compatibilityUrl, show]);

    useEffect(() => {
        meetShowRef.current = show;
    }, [show]);

    useEffect(() => {
        getCurrentMeet();
        setInterval(function () {
            if (!meetShowRef.current) {
                getCurrentMeet();
            }
        }, 10 * 1000);
    }, []);



    const updateHeight = () => {
        setZoomHeight(window.innerHeight - zoomHeightLess);
    };

    const saveNetworkInfo = async (type) => {
        const datetime = await getDatetime();
        const ip = await publicIp.v4();
        const res = await API.post('/saveNetworkInfo', {
            user_id: user.id
            , type
            , operativeSystem: getAgentData().so
            , browser: getAgentData().browser
            , version: getAgentData().browserVersion
            , ip
            , datetime
        });
    }

    const getCompatibility = () => {
        let msg = "";
        let link = "";

        if (!validateNavigator()) {
            setCompatibilityAlert(true);
            msg = <div className="m-5 pt-5 pb-5" style={{ border: "1px solid white" }}>
                <h4 className="text-white"><i className="fas fa-exclamation-triangle mr-2 text-danger"></i>Las reuniones dentro de EtM<i className="etm_text_blue">meet</i> solo funcionan en los navegadores:<br />
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome</span>, <span className="etm_text_blue">Mozilla Firefox</span> y <span className="etm_text_blue">Microsoft Edge</span> para <u className="text-warning">PC Windows</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome</span> para <u className="text-warning">Android</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome y Safari 12+</span> para <u className="text-warning">MacOS</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Safari</span> para <u className="text-warning">iOS (iPhone / iPad)</u>
                </h4>
            </div>;
            setCompatibilityMessage(msg);
        }
    };

    const getCurrentMeet = async () => {
        const res = await API.get('/current_meet', { params: { user_id: user.id } });
        if (res.data.state) {
            let { id, user_id, meet_id, meet_password, user_name, manager_name, manager_id, manager_rol, start_time, duration, meet_join_url, meet_url_start, name_room } = res.data.meet;
            if (user.id == manager_id) {
                setManager(1);
                setIdOtherParticipant(user_id);

                const resData = await API.post('/getDataForEvaluationByMeet', { type: "MANAGER", meet_id: id });
                if (resData.data.state) {
                    setMeetData(resData.data.evaluation);
                }
            } else {
                setManager(0);
                setIdOtherParticipant(manager_id);

                const resData = await API.post('/getDataForEvaluationByMeet', { type: "USER", meet_id: id });
                if (resData.data.state) {
                    setMeetData(resData.data.evaluation);
                }
            };

            if (user.id === manager_id) {
                setCompatibilityUrl(`${urlTwilio}room/${name_room}/${manager_name}`);
                // setCompatibilityUrl(meet_join_url);
            } else {
                setCompatibilityUrl(`${urlTwilio}room/${name_room}/${user_name}`);
                // setCompatibilityUrl(meet_url_start); 
            }

            setFinishComingSoon(false);
            setZoomPassword(meet_password);
            setZoomID(meet_id);
            setCardManagerId(manager_id);
            setCardUserId(user_id);
            setCompatibilityColor("text-white");
            setWaitingMeet(true);
            setMsg('Iniciando meet...');
            setMeetID(id);
            setNameRoom(name_room);
            setMeeting(meet_id);
            setPassword(meet_password);
            setUserName(user_name);
            setManagerName(manager_name);
            setTime(start_time);
            saveNetworkInfo("Twilio");

            const now = new Date();
            if (moment(start_time).add(300, 'seconds').toDate() < now) {
                setButtonReport(true);
            }

            // se calcula la duración de la reunión
            let d = (duration * 60) - 20;
            setDuration(d);
            setTimeout(function () {
                setShow(true);
                setMsg('');
                setWaitingMeet(false);
            }, 3000);

            // console.log("--- VALIDANDO PERMISOS DE VIDEO Y AUDIO ---");
            navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(function (mediaStream) {
                // console.log("Permisos de video y audio ok");
                setModalPermissions(false);
            }).catch(function (err) {
                // console.log(err);
                // console.log("Permisos de video y audio incorrectos");
                setModalPermissions(true);
            });
            navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        } else {
            setMsg(res.data.msg);
            setWaitingMeet(false);
            getNextMeets();
            setCompatibilityUrl("");
            setCompatibilityColor("text-danger");
        }
    }

    const preFinishMeet = async () => {
        swal({
            title: "¿Estás seguro?",
            text: "Se cerrará y finalizará la video llamada y no podrás volver acceder a ella.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancelar", "finalizar"],
        }).then((res) => {
            if (res) {
                finishMeet();
            }
        });
    }

    const finishMeet = async () => {
        setShow(false);
        setCardManagerId(0);
        setCardUserId(0);
        setCompatibilityColor("text-danger");
        setQuestionsModal(true);

        const res = await API.post('/close_meet', {
            meet_id: meeting
        });
    }

    const enableReport = async () => {
        setButtonReport(true);
    }

    const getNextMeets = async () => {
        const res = await API.get('/next_meets', { params: { user_id: user.id } });
        const user_id = user.id;
        let list = [];
        if (res.data.state) {
            res.data.meets.map((e, i) => {
                let rol_img = "";
                let rol = "";
                let picture = "";
                let profile_link = "";
                let user = "";
                let espertise = "";
                let company_pic = "";
                let company = "";
                let charge = "";
                let info_adicional = "";

                let cronometro = <h5 className="etm_text_blue" hidden={true}><i className="fas fa-clock mr-2 mt-3"></i>02:10</h5>; //    YILO 

                if (user_id === e.manager_id) {
                    switch (e.user_rol) {
                        case "ADMINISTRADOR":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            company_pic = (e.user_company_picture !== null) ? e.user_company_picture : '';
                            company = (e.user_company !== null) ? e.user_company : '';
                            charge = (e.user_charge !== null) ? e.user_charge : '';
                            espertise = "";
                            break;
                        case "GERENTE/EJECUTIVO":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            company_pic = (e.user_company_picture !== null) ? e.user_company_picture : '';
                            company = (e.user_company !== null) ? e.user_company : '';
                            charge = (e.user_charge !== null) ? e.user_charge : '';
                            espertise = "";
                            break;
                        case "INVERSIONISTA":
                            rol_img = inversionista;
                            rol = "Inversionista";
                            company_pic = (e.user_company_picture !== null) ? e.user_company_picture : '';
                            company = (e.user_company !== null) ? e.user_company : '';
                            charge = (e.user_charge !== null) ? e.user_charge : '';
                            espertise = "";
                            break;
                        case "MENTOR":
                            rol_img = mentor;
                            rol = "Mentor";
                            company_pic = (e.user_company_picture !== null) ? e.user_company_picture : '';
                            company = (e.user_company !== null) ? e.user_company : '';
                            charge = (e.user_charge !== null) ? e.user_charge : '';
                            espertise = <Col lg={3} md={4} sm={12} xs={12} className="text-center mt-3">
                                <h5 className="text-muted">{(e.user_expertise !== undefined && e.user_expertise !== null) ? <span><b>Expertiz:</b><br /><span className="etm_text_blue">{e.user_expertise}</span></span> : <span className="text-white">-</span>}</h5>
                            </Col>;
                            break;
                        case "EMPRENDEDOR":
                            rol_img = emprendedor;
                            rol = "Emprendedor";
                            company_pic = "";
                            company = "";
                            charge = "";
                            espertise = "";
                            break;
                    }
                    picture = (e.user_picture !== null) ? e.user_picture : '';
                    profile_link = linkProfile(e.user_hash);
                    user = e.user_name;
                } else {
                    switch (e.manager_rol) {
                        case "ADMINISTRADOR":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            company_pic = (e.manager_company_picture !== null) ? e.manager_company_picture : '';
                            company = (e.manager_company !== null) ? e.manager_company : '';
                            charge = (e.manager_charge !== null) ? e.manager_charge : '';
                            espertise = "";
                            break;
                        case "GERENTE/EJECUTIVO":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            company_pic = (e.manager_company_picture !== null) ? e.manager_company_picture : '';
                            company = (e.manager_company !== null) ? e.manager_company : '';
                            charge = (e.manager_charge !== null) ? e.manager_charge : '';
                            espertise = "";
                            break;
                        case "INVERSIONISTA":
                            rol_img = inversionista;
                            rol = "Inversionista";
                            company_pic = (e.manager_company_picture !== null) ? e.manager_company_picture : '';
                            company = (e.manager_company !== null) ? e.manager_company : '';
                            charge = (e.manager_charge !== null) ? e.manager_charge : '';
                            espertise = "";
                            break;
                        case "MENTOR":
                            rol_img = mentor;
                            rol = "Mentor";
                            company_pic = (e.manager_company_picture !== null) ? e.manager_company_picture : '';
                            company = (e.manager_company !== null) ? e.manager_company : '';
                            charge = (e.manager_charge !== null) ? e.manager_charge : '';
                            espertise = <Col lg={3} md={4} sm={12} xs={12} className="text-center mt-3">
                                <h5 className="text-muted">{(e.manager_expertise !== undefined && e.manager_expertise !== null) ? <span><b>Expertiz:</b><br /><span className="etm_text_blue">{e.manager_expertise}</span></span> : <span className="text-white">-</span>}</h5>
                            </Col>;
                            break;
                        case "EMPRENDEDOR":
                            rol_img = emprendedor;
                            rol = "Emprendedor";
                            company_pic = "";
                            company = "";
                            charge = "";
                            espertise = "";
                            break;
                    }

                    picture = (e.manager_picture !== null) ? e.manager_picture : '';
                    profile_link = linkProfile(e.manager_hash);
                    user = e.manager_name;
                }

                if (company_pic.length > 0 || company.length > 0 || charge.length > 0) {
                    info_adicional = <Row className="mt-4">
                        <Col xs={12} className="text-center">
                            <img src={company_pic} alt="" className="etm_admin_company_logo fix_img" />
                        </Col>
                        <Col xs={12} className="text-center mt-3">
                            <h6 className="etm_text_blue">{charge}</h6>
                            <h6 className="etm_text_blue">{company}</h6>
                        </Col>
                    </Row>;
                }

                list.push(
                    <Fragment key={i + 1}>
                        <Col xs={12}>
                            <Link to={profile_link} target="_blank">
                                <Card className="etm_card_next_meet">
                                    <Row>
                                        <Col lg={1} md={4} sm={12}>
                                            {
                                                (i < 1) ?
                                                    <div className="text-center mt-5">
                                                        <label className="text-danger">Siguiente<br />reunión <br /><i className="fas fa-angle-double-right fa-beat"></i></label>
                                                        {cronometro}
                                                    </div>
                                                    :
                                                    <div className="text-center mt-5">
                                                        <Row>
                                                            <Col className="mt-4">
                                                                {cronometro}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                            }
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                            <div className="text-center">
                                                <img src={picture} alt="" className="rounded-circle etm_waiting_room_avatar fix_img mb-3 mt-3" />
                                                <h5 className="etm_link_blue mb-3">{capitalize(user)}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={2} md={4} sm={12}>
                                            {info_adicional}
                                        </Col>
                                        <Col lg={7} md={12} sm={12}>
                                            <div className="pt-lg-4 text-center">
                                                <Row>
                                                    <Col lg={3} md={4} sm={6} xs={12} className="text-center">
                                                        <h5 className="text-muted"><b>Fecha:</b><br /><span className="etm_text_blue">{`${e.day} ${e.time}`}</span></h5>
                                                        <h5 className="text-muted mt-4"><b>Comenzará en:</b><br /><span className="etm_text_blue">
                                                            <Countdown date={moment(`${e.day.split("-").reverse().join("-")} ${e.time}`)} renderer={rendererFull} />
                                                        </span></h5>
                                                    </Col>
                                                    <Col lg={4} md={4} sm={6} xs={12} className="text-center">
                                                        <h5 className="text-muted">{(e.EMPRENDIMIENTO !== undefined && e.EMPRENDIMIENTO !== null) ? <span><b>Emprendimiento:</b><br /><span className="etm_text_blue">{capitalize(e.EMPRENDIMIENTO)}</span></span> : <span className="text-white">-</span>}</h5>
                                                        <h5 className="text-muted mt-4">{(e.EMPRENDIMIENTO_RUBRO !== undefined && e.EMPRENDIMIENTO_RUBRO !== null) ? <span><b>Rubro:</b><br /><span className="etm_text_blue">{e.EMPRENDIMIENTO_RUBRO}</span></span> : <span className="text-white">-</span>}</h5>
                                                    </Col>
                                                    {espertise}
                                                    <Col lg={2} md={4} sm={12} xs={12} className="text-center mt-3">
                                                        <div>
                                                            <img src={rol_img} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img" />
                                                        </div>
                                                        <h6 className="text-truncate font-weight-medium">{rol}</h6>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={12} className="text-center mt-2 mb-2">
                                            <label className="h5 text-muted ml-3 mr-3">{e.review}</label>
                                        </Col>
                                    </Row>
                                </Card>
                            </Link>
                        </Col>
                    </Fragment>
                );
            });
        }
        setMeets(list);
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const rendererFull = ({ days, hours, minutes, seconds, completed }) => {
        let hours_add = 24 * days;
        if (hours_add > 0) {
            hours += hours_add;
        }

        return (
            <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const reportUser = async () => {
        swal({
            title: "Terminar y reportar reunión",
            text: "Al reportar al participante la reunión finalizará y no podrás volver acceder a ella.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancelar", "Reportar"],
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await API.post('/report_meeting', {
                    user_id: user.id,
                    other_participant_id: idOtherParticipant,
                    meet_id: meetID,
                    reported_at: Date.now()
                });
                if (res.data.state) {
                    swal("Reporte exitoso", `Hemos recibido tu feedback, trabajaremos para que no vuelvas a pasar por esta situación.`, "success").then((value) => {
                        window.location.reload(true);
                    });
                } else {
                    swal("Ups!", `Ha ocurrido un problema al procesar tu reporte`, "warning");
                }
            } else {
                swal("No se ha realizado ningún reporte");
            }
        });
    }

    const enableFinishComingSoon = async () => {
        setFinishComingSoon(true);
    }

    const alertFinishComingSoon = () => {
        if (finishComingSoon) {
            swal("", `Quedan 2 minutos de reunión. Recuerda compartir tus datos o coordinar una nueva reunión`, "warning");
        }
    }

    return (
        <Fragment>
            <div className="page-content">
                {
                    // CUANDO INICIA VIDEO POR ZOOM
                    (show) ?
                        <Col className="etm_videocall_frame theme_bg">
                            <Row className="mb-0 pb-0">
                                <Col md={12}>
                                    <Row className="mt-2 ml-1 text-center">
                                        <Col sm={12}>
                                            <label className="text-white etm_video_txt_btn"><i className="fas fa-user-tie fa-1x mr-2"></i> Participante: <b className="ml-2 mr-2">{(manager == 1) ? capitalize(userName) : capitalize(managerName)}</b><button onClick={() => setModalParticipant(!modalParticipant)} className="btn btn-success btn-primary etm_btn btn-sm pt-0 pb-0 pl-3 pr-3"><span className="etm_video_txt_btn">Ver perfil</span></button></label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12}>
                                    <h1 className="text-white mt-lg-2 mr-xl-3 text-center">
                                        <span hidden={true}>
                                            <Countdown date={moment(time).add((duration - 120), 'seconds').toDate()} renderer={renderer} onComplete={enableFinishComingSoon} />
                                        </span>
                                        <Countdown date={moment(time).add(duration, 'seconds').toDate()} intervalDelay={0} precision={1} onComplete={finishMeet} renderer={props =>
                                            <Fragment>
                                                <label>
                                                    {props.minutes.toString().padStart(2, 0)}
                                                    <span className="ms-1 me-1">:</span>
                                                    {props.seconds.toString().padStart(2, 0)}
                                                    <span className="ms-1 me-1">:</span>
                                                    {props.milliseconds.toString().substring(0, 1)}
                                                </label ><i className="ml-2 fas fa-clock text-danger"></i>
                                            </Fragment>
                                        } />
                                    </h1>
                                </Col>
                            </Row>
                            <Row className="pl-1 pr-1 d-xl-flex d-lg-flex d-none">
                                <Col>
                                    {
                                        manager == 1 ?
                                            <div className="text-center">
                                                <label className="text-white etm_video_txt_btn ml-lg-4 mr-lg-4 pl-lg-4 pr-lg-4">¿Quieres compartir tus datos con el otro participante?</label>
                                                <br />
                                                <button onClick={() => setShareModal(!shareModal)} className="btn btn-success etm_btn btn-lg pt-1 pb-1 pl-3 pr-3"><i className="fas fa-share mr-2"></i><span className="etm_video_txt_btn">Compartir Datos</span></button>
                                            </div>
                                            : <br />
                                    }
                                </Col>
                                <Col>
                                    {
                                        manager === 1 ?
                                            <div className="text-center">
                                                <label className="text-white etm_video_txt_btn ml-lg-4 mr-lg-4 pl-lg-4 pr-lg-4">
                                                    ¿Quieres coordinar otra reunión?
                                                </label>
                                                <br />
                                                <button
                                                    onClick={() => setModalReserveHours(!modalReserveHours)}
                                                    className="btn btn-info etm_btn btn-lg pt-1 pb-1 pl-3 pr-3"
                                                >
                                                    <i className="fas fa-calendar-alt mr-2"></i>
                                                    <span className="etm_video_txt_btn">Agendar reunion</span>
                                                </button>
                                            </div>
                                            : <br />
                                    }
                                </Col>
                                <Col>
                                    <div className="text-center">
                                        <label className="text-white etm_video_txt_btn ml-lg-5 mr-lg-5 pl-lg-4 pr-lg-4">¿Tienes algún problemas con la reunión?</label>
                                        <br />
                                        <button className="btn btn-primary etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" onClick={() => setModalPassword(!modalPassword)}><i className="fas fa-video mr-2"></i><span className="etm_video_txt_btn">Ingresar directo desde <b>Twilio</b></span></button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-center">
                                        <label className="text-white etm_video_txt_btn ml-lg-5 mr-lg-5 pl-lg-5 pr-lg-5">¿El otro participante no llegó?{ }</label>
                                        <br />
                                        <button onClick={() => reportUser()} className="btn btn-danger etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" disabled={!buttonReport}>
                                            <span className="etm_video_txt_btn">
                                                <span hidden={buttonReport}>
                                                    <i className="fas fa-clock mr-2"></i>
                                                    <Countdown date={moment(time).add(300, 'seconds').toDate()} renderer={renderer} onComplete={enableReport} /> -
                                                </span> Reportar inasistencia
                                            </span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pl-1 pr-1">
                                <Col lg={12}>
                                    <div className="justify-content-center mt-1 text-center">
                                        {
                                            (compatibilityAlert) ?
                                                compatibilityMessage
                                                :
                                                <Iframe
                                                    id="etm_iframe_video"
                                                    url={compatibilityUrl}
                                                    className="myClassname etm_iframe_video"
                                                    display="initial"
                                                    position="relative"
                                                    sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                                                    allow={`camera ${urlTwilio}; microphone ${urlTwilio}; fullscreen ${urlTwilio}; display-capture ${urlTwilio}`}
                                                    styles={{ justifyContentCenter: 'center' }}
                                                />
                                        }
                                    </div>
                                </Col>
                                <Col lg={12} className="text-center">
                                    <button onClick={() => preFinishMeet()} className="btn btn-danger etm_btn btn-lg pt-1 pb-1 pl-5 pr-5">Finalizar Reunión</button>
                                </Col>
                            </Row>
                            <Row className="pl-1 pr-1 d-xl-none d-lg-none d-flex">
                                <Col xs={12} className="mt-2">
                                    {
                                        manager == 1 ?
                                            <div className="text-center">
                                                <button onClick={() => setShareModal(!shareModal)} className="btn btn-success etm_btn btn-lg pt-1 pb-1 pl-3 pr-3"><i className="fas fa-share mr-2"></i><span className="etm_video_txt_btn">Compartir Datos</span></button>
                                            </div>
                                            : <br />
                                    }
                                </Col>
                                <Col xs={12} className="mt-2">
                                    {
                                        manager == 1 ?
                                            <div className="text-center">
                                                <button
                                                    onClick={() => setModalReserveHours(!modalReserveHours)}
                                                    className="btn btn-info etm_btn btn-lg pt-1 pb-1 pl-3 pr-3"
                                                >
                                                    <i className="fas fa-calendar-alt mr-2"></i>
                                                    <span className="etm_video_txt_btn">Agendar reunion</span>
                                                </button>
                                            </div>
                                            : <br />
                                    }
                                </Col>
                                <Col xs={12} className="mt-2">
                                    <div className="text-center">
                                        <button className="btn btn-primary etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" onClick={() => setModalPassword(!modalPassword)}><i className="fas fa-video mr-2"></i><span className="etm_video_txt_btn">Ingresar directo desde <b>Twilio</b></span></button>
                                    </div>
                                </Col>
                                <Col xs={12} className="mt-2">
                                    <div className="text-center">
                                        <button onClick={() => reportUser()} className="btn btn-danger etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" disabled={!buttonReport}><span className="etm_video_txt_btn"><i className="fas fa-clock mr-2"></i><Countdown date={moment(time).add(300, 'seconds').toDate()} renderer={renderer} onComplete={enableReport} /> - Reportar inasistencia</span></button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <Container fluid>
                            <Breadcrumbs title={'Sala de Espera'} />
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg="12" className="table-responsive">
                                            {
                                                (validateRol(['EMPRENDEDOR'])) ?
                                                    <TableEmprendedor type='confirmadas' vista='meet' />
                                                    :
                                                    <TableExperto type='confirmadas' vista='meet' />
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                }
            </div >

            <ModalEvaluation showModal={questionsModal} meetData={meetData} />
            <ModalShareData showModal={shareModal} user_id={user.id} share_user_id={idOtherParticipant}  datos={{
                page: 'meet',
                user: '',
                OtherUsaer: ''
            }} />
            <ModalPermissions showModal={modalPermissions} />
            <ModalPassword showModal={modalPassword} zoomID={zoomID} zoomPassword={zoomPassword} compatibilityUrl={compatibilityUrl} />
            <ModalParticipant showModal={modalParticipant} userId={(manager == 1) ? cardUserId : cardManagerId} />
            <ModalReserveHours modal={modalReserveHours} setModal={setModalReserveHours} userName={userName} meetID={meetID} />

        </Fragment >
    );
}

export default withRouter(Meet);