import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import PublicProfile from "../../components/Home/PublicProfile";
import "../../assets/scss/etm.scss";
import { Link, withRouter } from "react-router-dom";
import { API } from '../../helpers/axios';
import Logo from "../../assets/images/conecTime/logo_conectime.png";

 const Profile = ( match ) => {
   
    const[dataUser, setDataUser]    = useState([]);
    const[loading, setLoading]      = useState(true);

    useEffect(() => {
        getParams();
    }, []);

    const getParams = async () => {
        let user    = 0;
        let start   = 0;
        const variables = match.location.pathname.split('/');
        if(variables[2] !== undefined){
            if(variables[2].length > 0){
                const res = await API.get('/getPublicProfile', { params: { hash: variables[2] }});
                if(res.data.state){
                    setDataUser(res.data.user);
                }
                setLoading(false);  
            }
        }
    }

    return (
        <React.Fragment>
            <div className="etm_public_profile_body">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xl="12">
                        <PublicProfile dataUser={ dataUser } logo={ Logo } loading={ loading }/>
                    </Col>
                </Row>
            </div>
            <div className="text-center etm_public_profile_footer">
                <h3 className="text-primary"> 
                    <Link to="/login" target="_blank"><button className="btn btn-primary etm_btn">Ingresa a <b>EtM<i>meet</i></b>!</button></Link>
                </h3>
            </div>
        </React.Fragment>
    );
}

// export default withNamespaces()(ViewProfile);
export default withRouter(Profile);