import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Label } from "reactstrap";

import { capitalize } from '../../../helpers/etmmeetUtils';

const ModalInfoNoConstituido = (props) => {

    const [loading, setLoading] = useState(false);

    return (
        <Row className="animate__animated animate__fadeIn animate__delay-1s">
            <Col md={12}>
                <Row>
                    <Col md={12} className="mb-0">
                        <Row>
                            <Col md={12} sm={12} className="mb-2 mt-2">
                                <Label className="h2 text-truncate etm_text_blue mb-0 text-wrap">
                                    {capitalize(props.startup.NOMBRE)}
                                </Label>
                                {
                                    (props.startup.WEB !== undefined && props.startup.WEB !== null && props.startup.WEB !== '') ?
                                        <Fragment>
                                            <br />
                                            <li className="list-inline-item mr-2">
                                                <Label className="h5">
                                                    <a href={props.startup.WEB} target="_blank" className="etm_link_blue">
                                                        <i className="fas fa-external-link-alt"></i> {props.startup.WEB}
                                                    </a>
                                                </Label>
                                            </li>
                                        </Fragment>
                                        :
                                        ""
                                }
                            </Col>
                            <Col md={12} className="mb-4">
                                <Row>
                                    <Col md={4} className="mb-4">
                                        <Label className="h5 text-truncate mt-0">
                                            <b>Etapa: </b>{(props !== null) ? capitalize(props.startup.ETAPA) : ""}
                                        </Label>
                                        <br />
                                        <Label className="h5 text-truncate mt-0">
                                            <b>Industria: </b>{(props !== null) ? capitalize(props.startup.INDUSTRIA) : ""}
                                        </Label>
                                        <br />
                                        <Label className="h5 text-truncate mt-0">
                                            <b>Tipo Comercio: </b>{(props !== null) ? capitalize(props.startup.TIPO_COMERCIO) : ""}
                                        </Label>
                                    </Col>
                                    <Col md={8} className="mb-4">
                                        <Label className="h4">
                                            <i className="fas fa-arrow-right etm_text_blue mr-2"></i>{(props !== null) ? props.startup.DESCRIPCION : ""}
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}


export default ModalInfoNoConstituido;