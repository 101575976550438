import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Label } from "reactstrap";
import Select from 'react-select';
import { API } from '../../helpers/axios';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage, capitalize, selectCustomStyle } from "../../helpers/etmmeetUtils";
import MeetingEvaluation from './MeetingEvaluation';

const DataMeeting = (props) => {

    const ls = getLocalStorage('speed_dating')
    const textFilter = "Selecciona o escribe";

    const [listDating, setListDating] = useState([])
    const [selectedTheme, setSelectedTheme] = useState(null)
    const [seeEntrepreneur, setSeeEntrepreneur] = useState(false)
    const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null)
    const [selected, setSelected] = useState(false)
    const [entrepreneurDatos, setEntrepreneurDatos] = useState(null)
    const [listEntrepreneur, setListEntrepreneur] = useState([])

    useEffect(() => {
        findSpeedDating()
    }, [])

    useEffect(() => {
        if (selectedTheme !== null && selectedTheme.value !== null) {
            setSeeEntrepreneur(false)
            findEntrepreneur()
        }
    }, [selectedTheme])

    useEffect(() => {
        if (selectedEntrepreneur !== null && selectedEntrepreneur.value !== null) {
            selectEntrepreneur()
        }
    }, [selectedEntrepreneur])

    useEffect(() => {
        if (!selected) {
            setSelectedEntrepreneur(null)
        }
    }, [selected])

    const findSpeedDating = async () => {
        const res = await API.get('/quickMeetings/findSpeedDating', { params: { email: ls.EMAIL } })
        setListDating([])
        if (res.data.state) {
            let list = [];
            res.data.ruedas.map((e, i) => {
                list.push({ value: e.ID, label: e.TEMA });
            });
            setListDating(list)
        }
    }

    const findEntrepreneur = async () => {
        const res = await API.get('/quickMeetings/findEntrepreneursBySpeedDatingAndExpert', { params: { speedDating: selectedTheme.value, expert: ls.EMAIL } });
        setListEntrepreneur([])
        if (res.data.state) {
            let list = [];
            res.data.entrepreneurs.map((e, i) => {
                list.push({ value: e.ID, label: e.NOMBRE });
            });
            setListEntrepreneur(list)
            setSeeEntrepreneur(true)
        }
    }

    const selectEntrepreneur = async () => {
        const res = await API.get('/quickMeetings/findEntrepreneurById', { params: { token: selectedEntrepreneur.value, expert: ls.ID, speedDAting: selectedTheme.value } });
        if (res.data.state) {
            setEntrepreneurDatos(res.data.entrepreneur)
            setSelected(true)
        }
    }

    const terminar = () => {
        setSelected(false)
        setSelectedEntrepreneur(null)
        props.setExperto(null)
        localStorage.removeItem('speed_dating');
    }

    const clickSelectedEntrepreneur = () => {
        findEntrepreneur()
    }

    return (
        <Fragment>
            <Card className="mt-3">
                <CardTitle className="mt-3 ml-3  mr-3 mb-2 text-left">
                    <Row className="d-flex justify-content-between">
                        <Col xs={8} className="pl-3 pr-1">
                            <i className="fas fa-user mr-2"></i>
                            {capitalize(ls.NOMBRE_COMPLETO)}
                        </Col>
                        <Col xs={4} className="pl-1 pr-3">
                            <Button
                                block
                                outline
                                name="terminar"
                                id="terminar"
                                onClick={() => terminar()}
                                color="danger"
                                className="btn btn-sm"
                            >
                                <i className="fas fa-times mr-2"></i>Cerrar
                            </Button>
                        </Col>
                    </Row>
                </CardTitle>
            </Card>
            <Card className="mt-3">
                <CardBody>
                    {
                        (!selected) ?
                            <>
                                <FormGroup>
                                    <Label for="selectTema" className="h5">
                                        Temática
                                    </Label>
                                    <Select
                                        placeholder={textFilter}
                                        value={selectedTheme}
                                        onChange={setSelectedTheme}
                                        options={listDating}
                                        className="etm_input_select"
                                        styles={selectCustomStyle()}
                                        noOptionsMessage={() => 'No hay mas opciones'}
                                    />
                                </FormGroup>
                                <FormGroup hidden={!seeEntrepreneur}>
                                    <Label for="selectEmprendedor" className="h5 mt-3">
                                        Seleccione un emprendedor
                                    </Label>
                                    <Select
                                        placeholder={textFilter}
                                        value={selectedEntrepreneur}
                                        onChange={setSelectedEntrepreneur}
                                        onMenuOpen={clickSelectedEntrepreneur}
                                        options={listEntrepreneur}
                                        className="etm_input_select"
                                        styles={selectCustomStyle()}
                                        noOptionsMessage={() => 'No hay mas opciones'}
                                    />
                                </FormGroup>
                            </>
                            :
                            <MeetingEvaluation
                                theme={selectedTheme}
                                data={entrepreneurDatos}
                                setSelected={setSelected}
                                findEntrepreneur={findEntrepreneur}
                            />
                    }

                </CardBody>
            </Card>
        </Fragment>
    );
}

export default DataMeeting;