import React from "react";
import { Redirect } from "react-router-dom";

//	V2
import SignUp from "../pages/Authentication/sign-up";
import Onboarding from "../pages/Authentication/onboarding";
import VerifyAccount from "../pages/Authentication/verify-account";
import Profile from "../pages/Profile/profile";
import Requests from "../pages/MyRequests/index";
import RequestsHistory from "../pages/MyRequests/historial";
import Challenges from "../pages/Challenges/index";
import FindChallenges from "../pages/Challenges/listAll";
import ControlAccess from "../pages/ControlAccess/index";
import QuickMeetings from "../pages/QuickMeetings/index";
 

// Paginas relativas a la autenticación
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import EventRegister from "../pages/Authentication/EventRegister";

// Dashboard
import Pending from "../pages/Inbox/Pending";
import Confirmed from "../pages/MyRequests/Confirmed";
import PublicProfile from "../pages/Public/Profile";
import GiveHours from "../pages/GiveHours/index";
import GivenTime from "../pages/GiveHours/GivenHoursList";
import Home from "../pages/Home/index";
import Meet from "../pages/Meet";
import Landing from "../pages/Landing/Landing";
import LandingEmprendedores from "../pages/Landing/LandingEmprendedores";
import LandingExpertos from "../pages/Landing/LandingExpertos";

import AdminPending from "../pages/Admin/AdminPending";
import AdminConfirm from "../pages/Admin/AdminConfirm";
import AdminUser from "../pages/Admin/AdminUser";
import AdminPanel from "../pages/Admin/AdminPanel";
import AdminExport from "../pages/Admin/AdminExport";
import Banner from "../pages/Public/Banner";
import Evaluation from "../pages/Public/Evaluation";
import Dashboard from "../pages/Sponsor/Dashboard";
import MeetMobile from "../pages/Public/MeetMobile";
import Live from "../pages/Public/Live";
import QRProfile from "../pages/Public/QRProfile";
import ThankMessage from "../pages/Public/ThankMessage";
import QRtester from "../pages/Admin/QRtester";
import QRmeeting from "../pages/Public/QRmeeting";
import PrivacyPolicies from "../pages/Public/PrivacyPolicies";
import QRDownloadApp from "../pages/Public/QRDownloadApp";
import Terms from "../pages/Public/TermsConditions";
import Donation from "../pages/Donation/Donation";
import DonationResponse from "../pages/Donation/DonationResponse";
import PdfViewer from "../pages/PdfViewer/PdfViewer";
import QR_profile from "../pages/Profile/QR_profile";

const userRoutes = [
	{ path: "/profile", component: Profile },
	{ path: "/profile/:param1?", component: Profile },
	{ path: "/home", component: Home },
	{ path: "/startups", component: () => <Redirect to="/profile/startups" /> },
	{ path: "/requests", component: Requests },
	{ path: "/requests_history", component: RequestsHistory },
	{ path: "/challenges", component: Challenges },
	{ path: "/challenges_searchFor", component: FindChallenges },



	// { path: "/dashboard_template", component: DashboardTemplate },	

	{ path: "/pending", component: Pending },
	{ path: "/confirmed", component: Confirmed },
	{ path: "/giveHours", component: GiveHours },
	
	
	
	{ path: "/meet", component: Meet },
	{ path: "/admin_pending", component: AdminPending },
	{ path: "/admin_confirm", component: AdminConfirm },
	{ path: "/admin_user", component: AdminUser },
	{ path: "/givenTime", component: GivenTime },
	{ path: "/admin_panel", component: AdminPanel },
	{ path: "/admin_export", component: AdminExport },
	
	
	{ path: "/dashboard", component: Dashboard },
	{ path: "/dashboard/:param1?", component: Dashboard },
	{ path: "/qr_tester", component: QRtester },
	{ path: "/qr_profile", component: QR_profile },

	
	// esta ruta debe estar al final de todas las demás
	{ path: "/", exact: true, component: () => <Redirect to="/landing" /> }
];

const authRoutes = [
	{ path: "/sign-up", component: SignUp },
	{ path: "/onboarding", component: Onboarding },
	{ path: "/onboarding/:param1?", component: Onboarding },
	{ path: "/verify-meet-account/:param1?", component: VerifyAccount },
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/landing", component: Landing },
	{ path: "/landing_emprendedores", component: LandingEmprendedores },
	{ path: "/landing_expertos", component: LandingExpertos },

	
	
	
	// { path: "/event_register", component: EventRegister },
	
	
	{ path: "/view_profile/:param1?", component: PublicProfile },
	// { path: "/view_profile/:id", component: ViewProfile },

	{ path: "/banner", component: Banner },
	{ path: "/evaluation/:param1?/:param2?", component: Evaluation },
	{ path: "/meetMobile/:param1?/:param2?", component: MeetMobile },
	{ path: "/qrProfile/:param1?", component: QRProfile },
	{ path: "/live/", component: Live },
	{ path: "/thankMessage/:param1?", component: ThankMessage },
	{ path: "/creating_qrmeeting/:param1?", component: QRmeeting },
	{ path: "/privacyPolicies", component: PrivacyPolicies },
	{ path: "/downloadApp", component: QRDownloadApp },
	{ path: "/terms", component: Terms },
	{ path: "/donation", component: Donation },
	{ path: "/donation/:param1?", component: Donation },
	{ path: "/donation_response/:param1?", component: DonationResponse },
	{ path: "/donation_response/:param1?/:param2?", component: DonationResponse },
	{ path: "/donation_response/:param1?/:param2?/:param3?", component: DonationResponse },
	{ path: "/lineup/:param1?", component: PdfViewer },
	
	{ path: "/access_control", component: ControlAccess },
	{ path: "/speed_dating", component: QuickMeetings },
];

export { userRoutes, authRoutes };
