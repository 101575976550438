import React, { Fragment, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, FormGroup, Input, Label, Container } from "reactstrap";
import "../../assets/scss/etm.scss";
import { API } from '../../helpers/axios';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage, swal_etm, capitalize } from "../../helpers/etmmeetUtils";
import userDefault from "../../assets/images/conecTime/user_profile.png";

import RatingTooltip from "react-rating-tooltip";

const MeetingEvaluation = (props) => {

    const ls = getLocalStorage('speed_dating')

    const number = (num) => {
        return (
            <Label className="h5"><span className="etm_onboarding_bg_step_number mr-2">{num}</span></Label>
        )
    }

    const questionOne = <Label className="h5">
        {number(1)}¿Cómo evaluarías el pitch que te acaban de presentar?
    </Label>
    const questionTwo = <Label className="h5">
        {number(2)}¿Crees que el proyecto es potencialmente escalable?
    </Label>
    const questionThree = <Label className="h5">
        {number(3)}¿Te interesa mantener contacto con el emprendedor a través de <b>EtM</b><i className="etm_text_blue">meet</i>? (Reunión digital, donde tú escoges la fecha)
    </Label>

    const tooltipContent = ["Nota 1", "Nota 2", "Nota 3", "Nota 4", "Nota 5", "Nota 6", "Nota 7"];
    const [answerThree, setAnswerThree] = useState("");
    const [answerOne, setAnswerOne] = useState(0);
    const [answerTwo, setAnswerTwo] = useState(0);
    const [loading, setLoading] = useState(false);

    const sendEvaluation = async () => {
        setLoading(true)
        const evaluation = {
            questionOne: questionOne.props.children[1]
            , answerOne
            , questionTwo: questionTwo.props.children[1]
            , answerTwo
            , questionThree: `${questionThree.props.children[1]}${questionThree.props.children[2].props.children}${questionThree.props.children[3].props.children}${questionThree.props.children[4]}`
            , answerThree
            , user_id: props.data.ID
            , expert_mail: ls.EMAIL
            , theme: props.theme.value
            , expert: ls.ID
        }

        const res = await API.post('/quickMeetings/registerEvaluation', { evaluation });
        if (res.data.state) {
            swal_etm(res.data.msg, 'success')
            props.findEntrepreneur()
            props.setSelected(false)
        } else {
            swal_etm(res.data.msg)
        }
        setLoading(false)
    }

    return (
        <Fragment>
            <Container>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <div className="mb-2">
                            <img
                                src={(props.data.FOTO === '') ? userDefault : props.data.FOTO}
                                alt={props.data.NOMBRE_COMPLETO}
                                className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg"
                            />
                        </div>
                    </Col>
                    <Col xs={12} className="text-center mt-2">
                        <Label className="h5">
                            {`${capitalize(props.data.NOMBRE_COMPLETO)}`}
                        </Label>
                        <br />
                        <Label className="h4">
                            {props.data.ORGANIZACION}
                        </Label>
                        <hr />
                    </Col>
                    <Col xs={12} className="text-left mt-2">
                        <Row>
                            <Col xs={12} className="text-center">
                                <Label className="h2">
                                    Evalúa a {`${capitalize(props.data.NOMBRE)}`}:
                                </Label>
                            </Col>
                            <Col xs={12} className="mb-3 mt-3">
                                {questionOne}
                                <RatingTooltip
                                    max={7}
                                    tooltipContent={tooltipContent}
                                    ratingValue={answerOne}
                                    onChange={value => setAnswerOne(value)}
                                    ActiveComponent={
                                        <i
                                            key={"active_6"}
                                            className="mdi mdi-checkbox-blank-circle text-primary mr-2 ml-2"
                                        />
                                    }
                                    InActiveComponent={
                                        <i
                                            key={"active_06"}
                                            className="mdi mdi-checkbox-blank-circle-outline text-muted mr-2 ml-2"
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={12} className="mb-3 mt-4" hidden={(answerOne === 0) ? true : false}>
                                {questionTwo}
                                <RatingTooltip
                                    max={7}
                                    tooltipContent={tooltipContent}
                                    ratingValue={answerTwo}
                                    onChange={value => setAnswerTwo(value)}
                                    ActiveComponent={
                                        <i
                                            key={"active_6"}
                                            className="mdi mdi-checkbox-blank-circle text-primary mr-2 ml-2"
                                        />
                                    }
                                    InActiveComponent={
                                        <i
                                            key={"active_06"}
                                            className="mdi mdi-checkbox-blank-circle-outline text-muted mr-2 ml-2"
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={12} className="mb-3 mt-3" hidden={(answerTwo === 0) ? true : false}>
                                {questionThree}
                                <Row className="mt-3">
                                    <Col xs={6}>
                                        <Button
                                            color="primary"
                                            onClick={() => setAnswerThree("SI")}
                                            block={true}
                                            outline={(answerThree === "SI") ? false : true}
                                            className={(answerThree === "SI") ? `etm_btn` : `etm_btn_outline`}
                                        >
                                            SI
                                        </Button>
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            color="primary"
                                            onClick={() => setAnswerThree("NO")}
                                            block={true}
                                            outline={(answerThree === "NO") ? false : true}
                                            className={(answerThree === "NO") ? `etm_btn` : `etm_btn_outline`}
                                        >
                                            NO
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} className="d-flex justify-content-between mt-3">
                        <Button
                            className="etm_btn"
                            outline
                            onClick={() => props.setSelected(false)}
                        >
                            <i className="fas fa-arrow-left fa-x2 mr-2"></i>Volver
                        </Button>
                        <Button
                            className="etm_btn"
                            color="primary"
                            onClick={() => sendEvaluation()}
                            disabled={(answerThree === "" || loading) ? true : false}
                        >
                            {
                                (loading) ?
                                    <span><i className="fas fa-sync fa-spin fa-x2 mr-2"></i>Guardando evaluación...</span>
                                    :
                                    <span><i className="fas fa-check fa-x2 mr-2"></i>Guardar evaluación</span>
                            }


                        </Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default MeetingEvaluation;