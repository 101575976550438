import React, { Fragment, useState, useEffect } from 'react';

import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { API } from '../../helpers/axios';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import { getDate, spanishDate, swal_etm, capitalize, validateRol, getLocalStorage, ordinales } from "../../helpers/etmmeetUtils";
import LoadBtn from "../../components/Utils/LoadBtn";
import { withRouter } from 'react-router-dom';
import LoadScreen from "../Utils/LoadScreen";

registerLocale('es', es);

const GiveHours = ({ history }) => {

    let nextFreeDay = new Date();
    let strikeDate = new Date();
    let endDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate() + 3);
    strikeDate.setDate(strikeDate.getDate() + 2);
    endDate.setMonth(endDate.getMonth() + 6)

    const percentageMessage = `Debes completar al <strong>100%</strong> tu perfil para poder donar tiempo. Te recordamos que Alan se encarga de hacer el match y para eso necesita conocer bien a los usuarios.<br><br>Haz click en <a href="/profile"><strong>Completar Perfil</strong></a>`;

    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [listHoursStart, setListHoursStart] = useState([]);
    const [listHoursEnd, setListHoursEnd] = useState([]);
    const [startTime, setStartTime] = useState("06:00");
    const [endTime, setEndTime] = useState("06:30");
    const [repeatList, setRepeatList] = useState([]);
    const [repeat, setRepeat] = useState("NO");
    const [hideEnd, setHideEnd] = useState(true);
    const [hideStart, setHideStart] = useState(true);
    const [repeatEnd, setRepeatEnd] = useState(new Date());
    const [strikeClass, setStrikeClass] = useState("etm_calendar_strike_text");
    const [loadscreen, setLoadscreen] = useState(false);
    const [meetingType, setMeetingType] = useState((validateRol(["MENTOR"])) ? "MENTORIA" : "COMERCIAL");

    const [hideOption, setHideOption] = useState({ end: false, personalized: false });
    // const [repetirDia, setRepetirDia] = useState(0);

    const [maxEndTime, setMaxEndTime] = useState(endDate);
    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [numberOfWeek, setNumberOfWeek] = useState(0);
    const [personalizedOption, setPersonalizedOption] = useState('semana');
    const [optionMonth, setOptionMonth] = useState('dia');

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();

    useEffect(() => {
        // getPercentage();
        refresh();
        setStrikeClass("etm_calendar_strike_text");
    }, []);

    const getPercentage = async () => {
        const res = await API.get('/getPercentageProfile', { params: { user_id: getLocalStorage().id } });
        if (res.data.percentage < 100) {
            swal_etm(percentageMessage, "warning", "Perfil incompleto");
            return false;
        }
    }

    const refresh = () => {
        getHoursList();
    }

    const getHoursList = async (e) => {
        const res = await API.get('/getHoursList');
        setListHoursStart([]);
        setListHoursEnd([]);
        let listStart = [];
        let listEnd = [];

        if (res.data.state) {
            res.data.hours.map((e, i) => {
                listStart.push(<option key={`start_${i}`} value={e.hour}> {e.hour} </option>);
                if (i > 0) {
                    listEnd.push(<option key={`end_${i}`} value={e.hour}> {e.end} </option>);
                }
            });
        }
        setListHoursStart(listStart);
        setListHoursEnd(listEnd);
    }

    const handleAdd = async (e) => {
        e.preventDefault();

        // const res = await API.get('/getPercentageProfile', { params: { user_id: getLocalStorage().id } });
        // if (res.data.percentage < 100) {
        //     swal_etm(percentageMessage, "warning", "Perfil incompleto");
        //     return false;
        // }

        setLoading(true);
        if (repeat !== 'NO') {
            if (repeatEnd.length < 1 || getDate(repeatEnd) == getDate()) {
                swal_etm("La fecha de término de la repetición debe ser posterior a hoy!");
                setLoading(false);
                return;
            }
        }

        if (getDate(selectedDate) < getDate(nextFreeDay)) {
            swal_etm("Para donar tiempo se requieren al menos 48 horas de anticipación.");
            setLoading(false);
            return;
        }

        if (parseInt(endTime.replace(":", "")) <= parseInt(startTime.replace(":", ""))) {
            swal_etm("La hora de término debe ser mayor a la de inicio");
            setLoading(false);
            return;
        }

        if (hideOption.personalized && personalizedOption.value === 'semana' && !monday && !tuesday && !wednesday && !thursday && !friday && !saturday) {
            swal_etm("Debes seleccionar al menos un día entre lunes y sabado");
            setLoading(false);
            return;
        }

        postAddHour();
    }

    const postAddHour = async () => {
        let days = {
            L: monday,
            M: tuesday,
            X: wednesday,
            J: thursday,
            V: friday,
            S: saturday
        }
        const res = await API.post("/meeting/expert/giveHour", {
            date: getDate(selectedDate),
            start_time: startTime,
            end_time: endTime,
            repeat,

            personalizedOption,
            days,
            optionMonth,

            repeatEnd: getDate(repeatEnd),
            token: getLocalStorage().token,
            meetingType
        });

        if (res.data.state) {
            const res_notify = await API.post("/meeting/expert/notifyTime", { token: getLocalStorage().token });
            swal_etm("Horario registrado!", "success");
        } else {
            swal_etm(res.data.msg);
        }
        setLoading(false);
        refresh();
    }

    const handleChangeRepeat = (e) => {
        setRepeat(e);
        setRepeatEnd(new Date());
        switch (e) {
            case "NO":
                setHideOption({ end: false, personalized: false });
                break;
            case "SEMANA":
            case "MESULTIMO":
            case "MES":
                setHideOption({ end: true, personalized: false });
                break;
            case "PERSONALIZADA":
                setHideOption({ end: true, personalized: true });
                break;
            default:
                setHideOption({ end: false, personalized: false });
                break;
        }
    }

    const handleChangeRepeatEnd = (e) => {
        setRepeatEnd(e);
    }

    const handleStart = (e) => {
        let end = new Date("1970-01-01 " + e + ":00");
        end = moment(end).add(30, 'm').toDate();
        end = `${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`;
        setStartTime(e);
        setEndTime(end);
    }

    const handleEnd = (e) => {
        changeSteps(3);
        setEndTime(e);
    }

    const toggleRepeat = () => {
        if (hideEnd) {
            setRepeat('SEMANA');
        } else {
            setRepeat('NO');
        }
        setRepeatEnd(new Date());
        setHideEnd(!hideEnd);
    }

    const changeSteps = (e) => {
        document.getElementById("step_arrow_large_" + e).classList.add("active");
        document.getElementById("step_arrow_large_" + e).classList.remove("inactive");
        document.getElementById("step_arrow_short_" + e).classList.add("active");
        document.getElementById("step_arrow_short_" + e).classList.remove("inactive");
    }

    const changeDay = (dia) => {
        cleanDay()
        if (getDate(dia) < getDate(nextFreeDay)) {
            swal_etm("Para donar tiempo se requieren al menos 48 horas de anticipación.");
            return;
        }
        const semana = ['D', 'L', 'M', 'X', 'J', 'V', 'S']
        let fecha = new Date(dia)
        selectDay(semana[fecha.getDay()], true)
        var weekOfWeek = (0 | getDate(dia).split('-')[2] / 7) + 1;
        setNumberOfWeek(weekOfWeek)

        setHideStart(false);
        changeSteps(2);
    }
    const cleanDay = () => {
        setMonday(false)
        setTuesday(false)
        setWednesday(false)
        setThursday(false)
        setFriday(false)
        setSaturday(false)
    }

    const selectDay = (day, set = null) => {
        switch (day) {
            case 'L':
                (set === null) ?
                    setMonday(!monday)
                    :
                    setMonday(set)
                break;
            case 'M':
                (set === null) ?
                    setTuesday(!tuesday)
                    :
                    setTuesday(set)
                break;
            case 'X':
                (set === null) ?
                    setWednesday(!wednesday)
                    :
                    setWednesday(set)
                break;
            case 'J':
                (set === null) ?
                    setThursday(!thursday)
                    :
                    setThursday(set)
                break;
            case 'V':
                (set === null) ?
                    setFriday(!friday)
                    :
                    setFriday(set)
                break;
            case 'S':
                (set === null) ?
                    setSaturday(!saturday)
                    :
                    setSaturday(set)
                break;
            default:
                console.log(`Valor no considerado: ${day}`)
                break;
        }
    }

    return (
        <Fragment>
            {
                (loadscreen) ?
                    <LoadScreen />
                    :
                    <Row>
                        <Col lg={12} className="text-center mb-3" >
                            <ul id="step_arrow">
                                <li className="step_arrow_element step_arrow_element_short active" id="step_arrow_short_1">Día</li>
                                <li className="step_arrow_element step_arrow_element_short inactive" id="step_arrow_short_2">Hora</li>
                                <li className="step_arrow_element step_arrow_element_short inactive" id="step_arrow_short_3">Dona!</li>
                                <li className="step_arrow_element step_arrow_element_large active" id="step_arrow_large_1">Elige un día</li>
                                <li className="step_arrow_element step_arrow_element_large inactive" id="step_arrow_large_2">Elige hora de inicio y fin</li>
                                <li className="step_arrow_element step_arrow_element_large inactive" id="step_arrow_large_3">Dona Tiempo!</li>
                            </ul>
                        </Col>

                        <Col lg={6} >
                            <div className="calendar text-center">
                                {/* Controls */}
                                <div className="controls ml-2 mr-2">
                                    <div>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={prevMonth}><i className="fas fa-angle-double-left"></i></button>
                                        <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={nextMonth}><i className="fas fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                                {/* Calendar head with day names */}
                                <div className="calendar_head">
                                    {
                                        shortDayNames.map(day => (
                                            <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                {spanishDate(day)}
                                            </div>
                                        ))
                                    }
                                </div>

                                {/* Calendar */}
                                <div className="calendar_body">
                                    {
                                        items.map(day => {
                                            const activeClass = !day.active ? "inactive" : "";
                                            let selectedClass = day.selected ? "selected" : "";
                                            const strike = (day.fullDate < strikeDate) ? strikeClass : "";
                                            return (
                                                <div
                                                    className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                    key={day.fullDate}
                                                    onClick={() => {
                                                        selectDate(day.fullDate);
                                                        setSelectedDate(day.fullDate);
                                                        changeDay(day.fullDate);
                                                    }}
                                                >
                                                    {day.date}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} className="mt-2">
                            <Row className="mt-3" hidden={hideStart}>
                                <Col lg={12} className="mb-3">
                                    <FormGroup >
                                        <Label className="h4">
                                            <b className="etm_text_blue mr-1">
                                                <i className="fas fa-calendar-alt"></i>
                                            </b><span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col md={3} xs={6}>
                                            <FormGroup >
                                                <Label className="h4"><b className="etm_text_blue mr-3"><i className="fas fa-clock"></i></b> Desde</Label>
                                                <Input type="select" name="start" onChange={(e) => handleStart(e.target.value)} className="form-control etm_input_select" value={startTime}>
                                                    {listHoursStart}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <FormGroup >
                                                <Label className="h4"><b className="etm_text_blue mr-3"><i className="fas fa-clock"></i></b> Hasta</Label>
                                                <Input type="select" name="end" onChange={(e) => handleEnd(e.target.value)} className="form-control etm_input_select" value={endTime}>
                                                    {listHoursEnd}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    (validateRol(["GERENTE/EJECUTIVO", "INVERSIONISTA"])) ?
                                        (validateRol(["MENTOR"])) ?
                                            <Col md={6} xs={12}>
                                                <FormGroup className="mt-2">
                                                    <Label className="h4"><i className="fas fa-arrow-right etm_text_blue mr-2"></i>¿ Qué tipo de reunión quieres donar ?</Label>
                                                    <Input type="select" name="end" onChange={(e) => setMeetingType(e.target.value)} className="form-control etm_input_select" value={meetingType}>
                                                        <option value="COMERCIAL">REUNIONES COMERCIALES</option>
                                                        <option value="MENTORIA">REUNIONES DE MENTORÍAS</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            : ""
                                        : ""
                                }
                                <Col lg={8} className="mt-3">
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <FormGroup >
                                                <Label className="h4">¿ Quieres repetir este horario ?</Label>
                                                <Input
                                                    type="select"
                                                    name="repeat"
                                                    onChange={(e) => handleChangeRepeat(e.target.value)}
                                                    className="form-control etm_input_select"
                                                >
                                                    <option value="NO"> No repetir </option>
                                                    <option value="SEMANA"> Cada semana el {capitalize(day.name)}  </option>
                                                    {
                                                        (numberOfWeek > 4) ?
                                                            <Fragment>
                                                                <option value="MES"> Cada mes la {ordinales(numberOfWeek - 1)} semana, el dia {capitalize(day.name)} </option>
                                                                <option value="MESULTIMO"> Cada mes, el ultimo {day.name} </option>
                                                            </Fragment>
                                                            :
                                                            <option value="MES"> Cada mes la {ordinales(numberOfWeek)} semana, el dia {capitalize(day.name)} </option>
                                                    }
                                                    <option value="PERSONALIZADA"> Personalizada </option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} hidden={!hideOption.personalized} className="mt-4">
                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Label className="h4"><b className="etm_text_blue mr-3"><i className="fas fa-arrow-right"></i></b> Repetir</Label>
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col xs={6}>
                                                    <FormGroup>
                                                        <Input
                                                            type="number"
                                                            className="form-control etm_input_select text-center"
                                                            value={repetirDia}
                                                            onChange={(e) => setRepetirDia(e.target.value)}
                                                            min="0"
                                                            max="5" />
                                                    </FormGroup>
                                                </Col> */}
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <Input
                                                            type="select"
                                                            name="repeat"
                                                            onChange={(e) => setPersonalizedOption(e.target.value)}
                                                            value={personalizedOption}
                                                            className="form-control etm_input_select"
                                                        >
                                                            <option value="semana"> Semanal </option>
                                                            <option value="mes"> Mensual </option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className="text-center" hidden={(personalizedOption === 'semana' && hideOption.personalized) ? false : true}>
                                    <Row className="text-center">
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(monday) ? `active` : ``}`}
                                                onClick={() => selectDay('L')}
                                            >
                                                <Label className="h5 mt-2 pointer">L</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(tuesday) ? `active` : ``}`}
                                                onClick={() => selectDay('M')}
                                            >
                                                <Label className="h5 mt-2 pointer">M</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(wednesday) ? `active` : ``}`}
                                                onClick={() => selectDay('X')}
                                            >
                                                <Label className="h5 mt-2 pointer">X</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(thursday) ? `active` : ``}`}
                                                onClick={() => selectDay('J')}
                                            >
                                                <Label className="h5 mt-2 pointer">J</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(friday) ? `active` : ``}`}
                                                onClick={() => selectDay('V')}
                                            >
                                                <Label className="h5 mt-2 pointer">V</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border text-center rounded-circle ${(saturday) ? `active` : ``}`}
                                                onClick={() => selectDay('S')}
                                            >
                                                <Label className="h5 mt-2 pointer">S</Label>
                                            </div>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-1">
                                            <div
                                                className={`ml-1 mr-1 mt-2 pointer etm_modal_solicitar_hora_border disabled text-center rounded-circle`}
                                                onClick={() => console.log('D')}
                                                style={{ cursor: "not-allowed" }}
                                            >
                                                <Label className="h5 mt-2 pointer" style={{ cursor: "not-allowed" }}>D</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={8} className="text-center mt-2" hidden={(personalizedOption === 'mes' && hideOption.personalized) ? false : true}>
                                    <FormGroup >
                                        <Input
                                            type="select"
                                            name="repeat"
                                            value={optionMonth}
                                            onChange={(e) => setOptionMonth(e.target.value)}
                                            className="form-control etm_input_select"
                                        >
                                            <option value="dia"> Todos los meses, el dia {day.number}  </option>
                                            {
                                                (numberOfWeek > 4) ?
                                                    <Fragment>
                                                        <option value="semana"> Todos los meses, el {ordinales(numberOfWeek - 1, 'o')} {day.name} </option>
                                                        <option value="semanaUltimo"> Todos los meses, el ultimo {day.name} </option>
                                                    </Fragment>
                                                    :
                                                    <option value="semana"> Todos los meses, el {(numberOfWeek === 1) ? ordinales(numberOfWeek, '') : ordinales(numberOfWeek, 'o')} {day.name} </option>
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg={(hideOption.personalized) ? 8 : 4} className="mt-3" hidden={!hideOption.end}>
                                    <Row className="mt-3">
                                        <Col xs={12} className={(hideOption.personalized) ? `mt-2` : `mt-0`}>
                                            <FormGroup>
                                                <Label className="h4">
                                                    <b className="etm_text_blue mr-3">
                                                        <i className="fas fa-calendar-alt"></i>
                                                    </b>
                                                    Repetir hasta
                                                </Label>
                                                <DatePicker
                                                    locale="es"
                                                    dateFormat="dd/MM/yyyy"
                                                    className={`form-control etm_input_text ${(hideOption.personalized) ? "mt-2" : ""}`}
                                                    selected={repeatEnd}
                                                    onChange={(date) => handleChangeRepeatEnd(date)}
                                                    popperPlacement="top"
                                                    minDate={new Date()}
                                                    maxDate={maxEndTime}
                                                />

                                                <Label className="h5 text_muted"><small>* Maximo 6 meses</small></Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={12} className="text-left mt-5">
                                    <Label className="h6 text-muted"><i className="fas fa-clock etm_text_blue mr-2"></i>Cada reunión dura 20 minutos con 10 minutos de entre cada una.</Label>
                                    <Label className="h6 text-muted"><i className="fas fa-globe-americas etm_text_blue mr-2"></i>Todas las reuniones tienen zona horaria GMT-4 (Hora de Chile).</Label>
                                </Col>
                                <Col lg={12} className="text-right mt-5">
                                    <LoadBtn handle={handleAdd} loading={loading} title="Donar Tiempo!" icon="plus" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
        </Fragment>
    );
}
export default withRouter(GiveHours);
