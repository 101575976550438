import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Label } from "reactstrap";
import PersonalData from "./personalData";
import OrganizationData from "./organizationData";
import InterestData from "./interestData";
import StartupData from "./startupData";
import AccountData from "./accountData";
import LoadScreen from "../Utils/LoadScreen";
import { validateRol } from '../../helpers/etmmeetUtils';

const Multistep = (props) => {
    const [step, setStep] = useState(0);
    const asterisco = <b className="ml-1 h5 etm_text_blue">*</b>;
    const flecha = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;

    useEffect(() => {
        getStep();
    }, []);

    const getStep = () => {
        if (props.match.location.pathname.length > 0) {
            const data = props.match.location.pathname.split('/');
            const actualStep = data[2];
            if (actualStep !== undefined) {
                switch (actualStep.toString().toUpperCase()) {
                    case "PERSONAL":
                        setStep(1);
                        break;
                    case "ORGANIZATION":
                        setStep(2);
                        break;
                    case "INTEREST":
                        setStep(3);
                        break;
                    case "STARTUPS":
                        setStep(4);
                        break;
                    case "ACCOUNT":
                        setStep(5);
                        break;
                    default:
                        setStep(1);
                        break;
                }
            } else {
                setStep(1);
            }
        }
    }

    return (
        <Fragment>
            <Row className="text-center " hidden>
                <Col md={12} className="mb-4">
                    <Label className="h4 etm_text_blue">Mi Perfil</Label>
                    <br />
                    <Label className="h5">Aquí podrás editar todos los parámetros de tu cuenta</Label>
                </Col>
                <Col className="p-0">
                    <div className="my_profile_profile_nav nav_left">
                        <span className={`my_profile_profile_nav_badge ${(step === 1) ? `active` : ``}`} onClick={() => setStep(1)}>
                            <i className="fas fa-user-check fa-lg"></i>
                        </span>
                    </div>
                    <br />
                    <Label className="h5 mt-2 etm_link_blue pointer" onClick={() => setStep(1)}>
                        <p className="mb-0">{flecha}Datos Personales</p>
                        {/* <p className="text-muted">5 / 10</p> */}
                    </Label>
                </Col>
                {
                    validateRol(["GERENTE/EJECUTIVO", "INVERSIONISTA", "MENTOR", "ADMINISTRADOR"]) ?
                        <Fragment>
                            <Col className="p-0 ">
                                <div className="my_profile_profile_nav">
                                    <span className={`my_profile_profile_nav_badge ${(step === 2) ? `active` : ``}`} onClick={() => setStep(2)}>
                                        <i className="fas fa-industry fa-lg"></i>
                                    </span>
                                </div>
                                <br />
                                <Label className="h5 mt-2 etm_link_blue pointer" onClick={() => setStep(2)}>
                                    <p className="mb-0">{flecha}Datos de mi Organización</p>
                                    {/* <p className="text-muted">5 / 10</p> */}
                                </Label>
                            </Col>
                            <Col className="p-0 ">
                                <div className="my_profile_profile_nav">
                                    <span className={`my_profile_profile_nav_badge ${(step === 3) ? `active` : ``}`} onClick={() => setStep(3)}>
                                        <i className="fas fa-people-arrows fa-lg"></i>
                                    </span>
                                </div>
                                <br />
                                <Label className="h5 mt-2 etm_link_blue pointer" onClick={() => setStep(3)}>
                                    <p className="mb-0">{flecha}Datos de Interés y Match</p>
                                    {/* <p className="text-muted">5 / 10</p> */}
                                </Label>
                            </Col>
                        </Fragment>
                        : ""
                }
                {
                    validateRol(["EMPRENDEDOR"]) ?
                        <Col className="p-0">
                            <div className="my_profile_profile_nav">
                                <span className={`my_profile_profile_nav_badge ${(step === 4) ? `active` : ``}`} onClick={() => setStep(4)}>
                                    <i className="fas fa-lightbulb fa-lg"></i>
                                </span>
                            </div>
                            <br />
                            <Label className="h5 mt-2 etm_link_blue pointer" onClick={() => setStep(4)}>
                                <p className="mb-0">{flecha}Mis Emprendimientos</p>
                                {/* <p className="text-muted">5 / 10</p> */}
                            </Label>
                        </Col>
                        : ""
                }

                <Col className="p-0">
                    <div className="my_profile_profile_nav nav_right">
                        <span className={`my_profile_profile_nav_badge ${(step === 5) ? `active` : ``}`} onClick={() => setStep(5)}>
                            <i className="fas fa-users-cog fa-lg"></i>
                        </span>
                    </div>
                    <br />
                    <Label className="h5 mt-2 etm_link_blue pointer" onClick={() => setStep(5)}>
                        <p className="mb-0">{flecha}Parámetros de mi Cuenta</p>
                        {/* <p className="text-muted">5 / 10</p> */}
                    </Label>
                </Col>
            </Row>

            {(step === 0) ? <LoadScreen text="Cargando perfil..." className="mt-5" /> : ""}
            {
                (step === 1) ?
                    <>
                        <PersonalData asterisco={asterisco} step={step} setStep={setStep} />
                        {
                            (validateRol(["GERENTE/EJECUTIVO", "INVERSIONISTA", "MENTOR", "ADMINISTRADOR"])) ?
                                <>
                                    <OrganizationData asterisco={asterisco} step={step} setStep={setStep} />
                                    <InterestData asterisco={asterisco} step={step} setStep={setStep} />
                                </>
                                : ''
                        }
                        {
                            (validateRol(["EMPRENDEDOR"])) ?
                                <StartupData asterisco={asterisco} step={step} setStep={setStep} />
                                : ''
                        }
                        <AccountData asterisco={asterisco} step={step} setStep={setStep} />
                    </>
                    : ""
            }
            {/* {(step === 2) ? <OrganizationData asterisco={asterisco} step={step} setStep={setStep} /> : ""}
            {(step === 3) ? <InterestData asterisco={asterisco} step={step} setStep={setStep} /> : ""}
            {(step === 4) ? <StartupData asterisco={asterisco} step={step} setStep={setStep} /> : ""}
            {(step === 5) ? <AccountData asterisco={asterisco} step={step} setStep={setStep} /> : ""} */}

        </Fragment>
    );
}

export default (Multistep);