import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Collapse, FormGroup } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { API } from '../../helpers/axios';
import { capitalize, linkProfile, getNameDate, cleanLinkedin } from '../../helpers/etmmeetUtils';
import "../../assets/scss/etm.scss";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import CardHeader from 'reactstrap/lib/CardHeader';
import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import { Fragment } from 'react';
import LoadScreen from "../../components/Utils/LoadScreen";

 const AdminConfirm = () => {
    
    /*** BILLING YEAR ***/
    const billing_year = {
            year1: 2019
        ,   year2: 2020
    }

    const ls = JSON.parse(localStorage.getItem('user'));
    
    const[meetStatus, setMeetStatus]            = useState("CONFIRMADA");
    const[countPending, setCountPending]        = useState("");
    const[listPendingUser, setListPendingUser]  = useState([]);
    const[countDays, setCountDays]              = useState("");
    const[listDays, setListDays]                = useState([]);
    const[countRequest, setCountRequest]        = useState("");
    const[listRequest, setListRequest]          = useState([]);
    const[hideDays, setHideDays]                = useState(true);
    const[hideRequest, setHideRequest]          = useState(true);
    const[title, setTitle]                      = useState('');
    const[subtitle, setSubtitle]                = useState('');
    const [filter, setFilter]                   = useState(7);
    const [anfitrionView, setAnfitrionView]     = useState(true);
    const [diasView, setDiasView]               = useState(false);
    const [solicitudesView, setSolicitudesView] = useState(false);
    const [anfitrion, setAnfitrion]             = useState("");
    const [searchKey, setSearchKey]             = useState("");
    const [loadscreen, setLoadscreen]           = useState(false);

    useEffect(() => {
        getPending();
    }, [filter]);

    const changeFilter = async (e) => {
        document.getElementById("input_key").value = "";
        setFilter(e);
        if(e !== null){
            const elements = document.getElementsByClassName("filter");
            if(elements.length > 0){
                for(var i = 0; i < elements.length; i++){
                    elements[i].classList.remove("etm_admin_filter_active");
                }
            }
            document.getElementById("filter_" + e).classList.add("etm_admin_filter_active");
        }
        setAnfitrionView(true);
        setHideDays(true);
        setDiasView(false);
        setSolicitudesView(false);
    }
    
    const getPending = async (e = "") => {
        setLoadscreen(true);
        const res = await API.get("/getPendingAdmin", {params: { meetStatus, key: e, filter, user_id: ls.id }});
        let list = [];
        let count = 0;
        if(res.data.state){ 
            res.data.users.map((e, i) => {
                count++;
                list.push(  <Col lg={3} md={6} xs={12} key={ i + 1 }>
                                <Card className="pointer admin_anfitriones" onClick={() => getDaysByUser(e.USER_ID, true, capitalize(e.USER), "anfitrion_" + i)} id={ "anfitrion_" + i }>
                                    <CardBody className="text-center">
                                        <h5 className="pointer etm_text_blue">{ capitalize(e.USER) }</h5>
                                        <img src={ e.FOTO } alt="" className="rounded-circle etm_admin_avatar fix_img mb-3 mt-3"/>
                                        <h6 className="pointer"><b>{ e.PERFIL }</b></h6>
                                        <h6 className="pointer">{ (e.EMPRESA !== undefined && e.EMPRESA !== null) ? <span>{ capitalize(e.EMPRESA) }</span> : <span className="text-white">-</span> }</h6>
                                        <h6 className="pointer">{ (e.GERENCIA !== undefined && e.GERENCIA !== null) ? <span><b>Gerencia:</b> { capitalize(e.GERENCIA) } </span>: <span className="text-white">-</span> }</h6>
                                        <h6 className="pointer">{ (e.CARGO !== undefined && e.CARGO !== null) ? <span><b>Cargo:</b> { capitalize(e.CARGO) } </span>: <span className="text-white">-</span> }</h6>
                                        <h6 className="pointer">{ (e.RUBRO !== undefined && e.RUBRO !== null) ? <span><b>Rubro:</b> { capitalize(e.RUBRO) } </span>: <span className="text-white">-</span> }</h6>
                                        {
                                            (e.PERFIL === "MENTOR") ? 
                                                <h6 className="pointer">{ (e.EXPERTIZ !== undefined && e.EXPERTIZ !== null) ? <span><b>Expertiz:</b> { capitalize(e.EXPERTIZ).replace(/,/g, " \| ") } </span>: <span className="text-white">-</span> }</h6>
                                                : ""
                                        }
                                        <h6 className="pointer">{ (e.LINKEDIN !== undefined && e.LINKEDIN !== null) ? <a href={ cleanLinkedin(e.LINKEDIN) } target="_blank"><i className="fab fa-linkedin linkedin fa-2x"></i></a>: <span className="text-white">-</span> }</h6>
                                        {
                                            (e.SPONSOR) ? 
                                                <h5><span className="badge badge-pills etm_bg_blue text-white"><i className="fas fa-star mr-2"></i>AUSPICIADOR<i className="fas fa-star ml-2"></i></span></h5>
                                                : ""
                                        }
                                    </CardBody>
                                </Card>
                            </Col>);
            });
        }
        setCountPending(count);
        setListPendingUser(list);
        setLoadscreen(false);
    }

    const getDaysByUser = async (user_id, clean, user_name, element_id = null) => {
        setAnfitrionView(false);
        setDiasView(true);
        if(element_id !== null){
            const anfitriones = document.getElementsByClassName("admin_anfitriones");
            if(anfitriones.length > 0){
                for(var i = 0; i < anfitriones.length; i++){
                    anfitriones[i].classList.remove("admin_anfitriones_active");
                }
            }
            document.getElementById(element_id).classList.add("admin_anfitriones_active");
        }
        
        const res = await API.get("/getDaysUserAdmin", { params: {meetStatus, user_id, filter} });
        let list = [];
        let count = 0;
        if(res.data.state){
            const dias = document.getElementsByClassName("admin_dias");
            if(dias.length > 0){
                for(var i = 0; i < dias.length; i++){
                    dias[i].classList.remove("admin_dias_active");
                }
            }

            res.data.days.map((e, i) => {
                count++;
                list.push(  <Col md={3} xs={6} key={ i + 1 }>
                                <Card className="pointer admin_dias" onClick={() => getRequestByDay(e.ID, user_id, user_name, getNameDate(e.DAY) + ' a las ' + e.HOUR_TEXT, "dia_" + i)} id={ "dia_" + i }>
                                    <CardBody className="text-center">
                                        <label className="pointer">{ getNameDate(e.DAY) }</label><br/>
                                        <label className="pointer">{ e.HOUR_TEXT }</label><br/>
                                        <label className="pointer"><span className="badge badge-pill badge-warning">{ e.PENDING } Pendientes</span> | <span className="badge badge-pill badge-success">{ e.CONFIRM } Confirmadas</span></label>
                                    </CardBody>
                                </Card>
                            </Col>);
            });
        }
        setCountDays(count);
        setHideDays(false);
        setListDays(list);
        setTitle(user_name);
        setSubtitle("");
        if(clean){
            setHideRequest(true);
        }
    }

    const getRequestByDay = async (meet_id, user_id, user_name, day_text, element_id = null) => {
        setDiasView(false);
        setSolicitudesView(true);
        if(element_id !== null){
            const dias = document.getElementsByClassName("admin_dias");
            if(dias.length > 0){
                for(var i = 0; i < dias.length; i++){
                    dias[i].classList.remove("admin_dias_active");
                }
            }
            document.getElementById(element_id).classList.add("admin_dias_active");
        }

        const res = await API.get("/getRequestDayAdmin", { params: {meetStatus, meet_id} });
        let list = [];
        let count = 0;
        if(res.data.state){
            res.data.request.map((e, i) => {
                count++;
                const user_profile = linkProfile(e.USER_HASH);
                let status = "";
                let show_btn = false;

                if(e.STATUS === 'PENDIENTE'){
                    status = 'badge badge-pill badge-warning';
                    show_btn = true;
                }else if(e.STATUS === 'CONFIRMADA'){
                    status = 'badge badge-pill badge-success';
                }else{
                    status = 'badge badge-pill badge-danger';
                }
                const btn_confirm_id = `btn_confirm_${i}`;
                const btn_refuse_id = `btn_refuse_${i}`;

                let rol_img = '';
                let rol = '';
                switch(e.rol){
                    case "ADMINISTRADOR":
                        rol_img = gerente;
                        rol = "Gerente-Ejecutivo";
                        break;
                    case "GERENTE/EJECUTIVO":
                        rol_img = gerente;
                        rol = "Gerente-Ejecutivo";
                        break;
                    case "INVERSIONISTA":
                        rol_img = inversionista;
                        rol = "Inversionista";
                        break;
                    case "MENTOR":
                        rol_img = mentor;
                        rol = "Mentor";
                        break;
                    case "EMPRENDEDOR":
                        rol_img = emprendedor;
                        rol = "Emprendedor";
                        break;
                }

                list.push(  <Fragment key={ i + 1 }>
                                <Col xs={12}>
                                    <Card className="etm_admin_emprendedores">
                                        <Row>
                                            <Col md={4} sm={6} xs={12}>
                                                <div className="text-center">
                                                    <img src={ e.profile_picture } alt="" className="rounded-circle etm_admin_avatar fix_img mb-3 mt-3"/>
                                                    <Link to={user_profile} target="_blank">
                                                        <h5 className="etm_link_blue mb-3">{ e.USER }</h5>
                                                    </Link>
                                                    <Row>
                                                        <Col xs={12} className="text-center">
                                                            <img src={ e.company_picture } alt="" className="etm_admin_company_logo fix_img"/> 
                                                        </Col>
                                                        <Col  xs={12} className="text-center mt-3">
                                                            <h6 className="etm_text_blue">{ e.charge }</h6>
                                                            <h6 className="etm_text_blue">{ e.company }</h6>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <div className="pt-3 text-center">
                                                    <Row>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO !== undefined && e.EMPRENDIMIENTO !== null) ? <span><b>Emprendimiento:</b><br/>{ capitalize(e.EMPRENDIMIENTO) }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_RUBRO !== undefined && e.EMPRENDIMIENTO_RUBRO !== null) ? <span><b>Rubro:</b><br/>{ e.EMPRENDIMIENTO_RUBRO.replace(/,/g, " \| ") }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_REGION !== undefined && e.EMPRENDIMIENTO_REGION !== null) ? <span><b>Regiones:</b><br/>{ e.EMPRENDIMIENTO_REGION.replace(/,/g, " \| ") }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_INICIO !== undefined && e.EMPRENDIMIENTO_INICIO !== null) ? <span><b>Inicio Actividades:</b><br/>{ e.EMPRENDIMIENTO_INICIO }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_EMPLEADOS !== undefined && e.EMPRENDIMIENTO_EMPLEADOS !== null) ? <span><b>Cantidad Empleados:</b><br/>{ e.EMPRENDIMIENTO_EMPLEADOS }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_FACTURACION !== undefined && e.EMPRENDIMIENTO_FACTURACION !== null) ? <span><b>Facturación { billing_year.year2 }:</b><br/>{ e.EMPRENDIMIENTO_FACTURACION.replace(/,/g, " \| ") }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        <Col md={4} xs={12} className="text-center mt-3">
                                                            <h6 className="text-muted">{ (e.EMPRENDIMIENTO_WEB !== undefined && e.EMPRENDIMIENTO_WEB !== null) ? <span><b>Página Web:</b><br/>{ e.EMPRENDIMIENTO_WEB }</span> : <span className="text-white">-</span> }</h6>
                                                        </Col>
                                                        {
                                                            (e.rol === "MENTOR" || e.rol === "ADMINISTRADOR") ?
                                                                <Col md={4} xs={12} className="text-center mt-3">
                                                                    <h6 className="text-muted">{ (e.expertise !== undefined && e.expertise !== null) ? <span><b>Expertiz:</b><br/>{ e.expertise.replace(/,/g, " \| ") }</span> : <span className="text-white">-</span> }</h6>
                                                                </Col>
                                                            : ""
                                                        }
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xs={12} className="text-center mt-3">
                                                <label className="h5 text-muted ml-3 mr-3">{ e.REVIEW }</label>
                                            </Col>
                                            <Col xs={12} className="mt-3">
                                                <Row>
                                                    <Col xs={12} className="text-center">
                                                        <button onClick={() => preRefuseConfirm(e.MEET_ID, e.REQUEST_ID, user_id, btn_refuse_id)} className="btn btn-danger etm_btn btn-md"><div id={btn_refuse_id}><i className="fas fa-times mr-2"></i>Cancelar Reunión</div></button>
                                                    </Col>
                                                    <hr className="lineGray"></hr>
                                                    <Col xs={6} className="text-right">
                                                        <div>
                                                            <img src={rol_img} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img"/>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} className="text-left mt-3">
                                                        <h6 className="text-truncate font-weight-medium">{rol}</h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Fragment>);
            });
        }
        setCountRequest(count);
        setHideRequest(false);
        setListRequest(list);
        setTitle(user_name);
        setSubtitle(day_text);
    }

    const preRefuseConfirm = (e, r, user_id, btn_id) => {  
        swal({
            title:      "¿Está seguro?",
            text:       "Se cancelará esta reunión ya confirmada",
            icon:       "warning",
            buttons:    true,
            dangerMode: true,
            buttons: [
                'Cancelar',
                'Rechazar!'
              ],
          })
          .then((willDelete) => {
            if (willDelete) {
                refuseConfirm(e, r, user_id, btn_id);
            } 
        });
    }

    const refuseConfirm = async (e, r, user_id, btn_id) => {
        let btn = document.getElementById(btn_id);
        btn.innerHTML = '';
        btn.classList.add("spinner-border");
        btn.classList.add("spinner-border-sm");
        
        const res = await API.post('/cancelConfirmedAdmin', { meet_id: e, request_id: r });
        if(res.data.state){
            getDaysByUser(user_id, false);
            getRequestByDay(e);
            swal("Solicitud Cancelada!", res.data.msg, "success");
            getPending();
        }else{
            swal("Ups!", res.data.msg, "warning");
        }
        btn.classList.remove("spinner-border");
        btn.classList.remove("spinner-border-sm");
        document.getElementById(btn_id).innerHTML = 'Cancelar Reunión';
    };

    const toggleAnfitrion = () => setAnfitrionView(!anfitrionView);
    const toggleDias = () => setDiasView(!diasView);
    const toggleSolicitudes = () => setSolicitudesView(!solicitudesView);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Gestionar Horas Solicitadas'}/>
                    <Row>
                        <Col xs={12}>
                            <h4 className="etm_text_blue mb-3">Gestionar Horas Confirmadas</h4>
                        </Col>
                        <Col xs={4} >
                            <Card className="text-center pointer filter etm_admin_filter_active" onClick={() => changeFilter(7)} id="filter_7">
                                <CardBody>
                                    <label className="pointer">Próximos 7 días</label>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={4} >
                            <Card className="text-center pointer filter" onClick={() => changeFilter(15)} id="filter_15">
                                <CardBody>
                                    <label className="pointer">Próximos 15 días</label>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={4} >
                            <Card className="text-center pointer filter" onClick={() => changeFilter(30)} id="filter_30">
                                <CardBody>
                                    <label className="pointer">Próximos 30 días</label>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={ 12 } className="mb-2 text-center">
                            <h4 className="etm_text_blue">{ title }</h4>
                            <h4 className="etm_text_blue"><small>{ subtitle }</small></h4>
                        </Col>
                        {/* ANFITRIONES */}
                        <Col xs={ 12 } >
                            <Card>
                                <CardHeader className="bg-secondary text-center pointer etm_admin_header_collapse" onClick={ toggleAnfitrion }>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">Tomadores de Decisiones ({countPending})</h5>
                                        </div>
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">
                                                {
                                                    (anfitrionView) ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                            <Collapse isOpen={ anfitrionView }>
                                <Row className="mb-2">
                                    <Col lg={4} md={6} xs={12}>
                                        <FormGroup>
                                            <input name="input_key" id="input_key" className="form-control etm_input_text" type="text" placeholder="Ingrese un nombre o apellido para filtrar resultados" onKeyUp={(e) => getPending(e.target.value)}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {
                                    (loadscreen) ?
                                        <LoadScreen/>
                                        :
                                        <Row>
                                            {
                                                (countPending > 0) ?
                                                listPendingUser
                                                :   <Col xs={12}>
                                                        <Card>
                                                            <CardBody className="text-center">
                                                                <label>No hay Tomadores de Decisiones con solicitudes pendientes</label>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                            }
                                        </Row>
                                }
                            </Collapse>
                        </Col>
                        {/* DIAS */}
                        <Col xs={ 12 } hidden={hideDays}>
                            <Card>
                                <CardHeader className="bg-secondary text-center pointer etm_admin_header_collapse" onClick={ toggleDias }>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">Días con reuniones ({countDays})</h5>
                                        </div>
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">
                                                {
                                                    (diasView) ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                            <Collapse isOpen={ diasView }>
                                <Row>
                                    { listDays }
                                </Row>
                            </Collapse>
                        </Col>
                        {/* SOLICITUDES */}
                        <Col xs={ 12 } hidden={hideRequest}>
                            <Card>
                                <CardHeader className="bg-secondary text-center pointer etm_admin_header_collapse" onClick={ toggleSolicitudes }>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">Reuniones confirmadas ({countRequest})</h5>
                                        </div>
                                        <div>
                                            <h5 className="text-white d-flex justify-content-center">
                                                {
                                                    (solicitudesView) ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                            <Collapse isOpen={ solicitudesView }>
                                <Row>
                                    { listRequest }
                                </Row>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(AdminConfirm);