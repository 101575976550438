import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Row, Col, FormGroup, Label, Input, Container, Button } from "reactstrap";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { swal_etm, getLocalStorage, selectCustomStyle, validateRol } from "../../helpers/etmmeetUtils";
import { API } from '../../helpers/axios';
import LoadBtn from "../Utils/LoadBtn";
import LoadScreen from "../Utils/LoadScreen";
import { useHistory } from "react-router-dom";

const InterestData = (props) => {
    const history = useHistory();
    const arrow = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;
    const chileData = { value: 81, label: "Chile" };

    const date = new Date();
    const yyyy = date.getFullYear();

    /*** BILLING YEAR ***/
    const billing_year = {
        year1: yyyy - 2
        , year2: yyyy - 1
    }

    const [loadingInterestData, setLoadingInterestData] = useState(true);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingRegions, setLoadingRegions] = useState(true);
    const [loadingIndustries, setLoadingIndustries] = useState(true);
    const [loadingImpacts, setLoadingImpacts] = useState(true);
    const [loadingOds, setLoadingOds] = useState(true);
    const [loadingAgeRanges, setLoadingAgeRanges] = useState(true);
    const [loadingInvestments, setLoadingInvestments] = useState(true);
    const [loadingBillings, setLoadingBillings] = useState(true);
    const [loadingInvoices, setLoadingInvoices] = useState(true);
    const [loadingProjectStage, setLoadingProjectStage] = useState(true);
    const [loading, setLoading] = useState({ save: false, saveNext: false });
    const [showRegions, setShowRegions] = useState(true);

    const [interestAgeRange, setInterestAgeRange] = useState([]);
    const [interestInvestment, setInterestInvestment] = useState([]);
    const [interestBilling, setInterestBilling] = useState([]);
    const [interestInvoices, setInterestInvoices] = useState([]);
    const [interestIndustries, setInterestIndustries] = useState(null);
    const [interestCountry, setInterestCountry] = useState([chileData]);
    const [interestRegion, setInterestRegion] = useState(null);
    const [interestImpacts, setInterestImpacts] = useState(null);
    const [interestOds, setInterestOds] = useState(null);
    const [interestStages, setInterestStages] = useState([]);
    const [interestWomenLeader, setInterestWomenLeader] = useState(0);
    const [interestGenderEquality, setInterestGenderEquality] = useState(0);
    const [interestDiversityInclusion, setInterestDiversityInclusion] = useState(0);

    const [listAgeRange, setListAgeRange] = useState([]);
    const [listInvestment, setListInvestment] = useState([]);
    const [listBilling, setListBilling] = useState([]);
    const [listInvoices, setListInvoices] = useState([]);
    const [listCountries, setListCountries] = useState([]);
    const [listRegions, setListRegions] = useState([]);
    const [listImpacts, setListImpacts] = useState([]);
    const [listOds, setListOds] = useState([]);
    const [listStages, setListStages] = useState([]);
    const [listIndustries, setListIndustries] = useState([]);

    useEffect(() => {
        getProjectStage();
        getCountries();
        getRegions();
        getIndustries();
        getImpacts();
        getOds();
        getAgeRange();
        getInvestment();
        getBillings();
        getInvoices();
    }, []);

    useEffect(() => {
        validateLoadingFinish();
    }, [loadingCountries, loadingRegions, loadingIndustries, loadingImpacts, loadingOds, loadingAgeRanges, loadingInvestments, loadingBillings, loadingInvoices, loadingProjectStage]);

    const validateLoadingFinish = () => {
        if (loadingProjectStage === false &&
            loadingCountries === false &&
            loadingRegions === false &&
            loadingIndustries === false &&
            loadingImpacts === false &&
            loadingOds === false &&
            loadingAgeRanges === false &&
            loadingInvestments === false &&
            loadingBillings === false &&
            loadingInvoices === false
        ) {
            getInterestData();
        }
    }

    const getInterestData = async () => {
        const res = await API.get('/profile/getInterestData', { params: { token: getLocalStorage().token } });
        setLoadingInterestData(false);

        if (res.data.state) {

            if (res.data.data.stages !== null) {
                const list = [];
                res.data.data.stages.map((e, i) => {
                    list.push(e.ID);
                    const element = document.getElementById(`stage_${e.ID}`);
                    if (element !== null && element !== undefined) {
                        element.click();
                    }
                });
                setInterestStages(list);
            }

            if (res.data.data.age_ranges !== null) {
                const list = [];
                res.data.data.age_ranges.map((e, i) => {
                    list.push(e.ID);
                    const element = document.getElementById(`age_rage_${e.ID}`);
                    if (element !== null && element !== undefined) {
                        element.click();
                    }
                });
                setInterestAgeRange(list);
            }

            if (res.data.data.investments !== null) {
                const list = [];
                res.data.data.investments.map((e, i) => {
                    list.push(e.ID);
                    const element = document.getElementById(`investment_${e.ID}`);
                    if (element !== null && element !== undefined) {
                        element.click();
                    }
                });
                setInterestInvestment(list);
            }

            if (res.data.data.billings !== null) {
                const list = [];
                res.data.data.billings.map((e, i) => {
                    list.push(e.ID);
                    const element = document.getElementById(`billing_${e.ID}`);
                    if (element !== null && element !== undefined) {
                        element.click();
                    }
                });
                setInterestBilling(list);
            }

            if (res.data.data.industries !== null) {
                const list = [];
                res.data.data.industries.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setInterestIndustries(list);
            }

            if (res.data.data.countries !== null) {
                const list = [];
                res.data.data.countries.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setInterestCountry(list);
            }

            if (res.data.data.regions !== null) {
                const list = [];
                res.data.data.regions.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setInterestRegion(list);
            }

            if (res.data.data.impacts !== null) {
                const list = [];
                res.data.data.impacts.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setInterestImpacts(list);
            }

            if (res.data.data.ods !== null) {
                const list = [];
                res.data.data.ods.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setInterestOds(list);
            }

            setInterestWomenLeader(res.data.data.women_leader);
            setInterestGenderEquality(res.data.data.equality);
            setInterestDiversityInclusion(res.data.data.inclusion);
        }
    }

    const getCountries = async () => {
        const res = await API.get('/general/getCountries');
        if (res.data.state) {
            let list = [];
            res.data.countries.map((e, i) => {
                list.push({ value: e.ID, label: e.PAIS });
            });
            setListCountries(list);
        }
        setLoadingCountries(false);
    }

    const getRegions = async (e) => {
        const res = await API.get('/general/getRegions');
        if (res.data.state) {
            let list = [];
            res.data.regions.map((e, i) => {
                list.push({ value: e.ID, label: e.REGION });
            });
            setListRegions(list);
        }
        setLoadingRegions(false);
    }

    const getIndustries = async () => {
        const res = await API.get('/general/getIndustries');
        if (res.data.industries.length > 0) {
            let list = [];
            res.data.industries.map((e, i) => {
                list.push({ value: e.ID, label: e.INDUSTRIA });
            });
            setListIndustries(list);
        }
        setLoadingIndustries(false);
    }

    const getImpacts = async () => {
        const res = await API.get('/general/getImpacts');
        if (res.data.impacts.length > 0) {
            let list = [];
            res.data.impacts.map((e, i) => {
                list.push({ value: e.ID, label: e.IMPACTO });
            });
            setListImpacts(list);
        }
        setLoadingImpacts(false);
    }

    const getOds = async () => {
        const res = await API.get('/general/getOds');
        if (res.data.ods.length > 0) {
            let list = [];
            res.data.ods.map((e, i) => {
                list.push({ value: e.ID, label: e.ODS });
            });
            setListOds(list);
        }
        setLoadingOds(false);
    }

    const getAgeRange = async () => {
        const res = await API.get('/general/getAgeRange');
        if (res.data.age.length > 0) {
            let list = [];
            res.data.age.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini age_rage" id={`age_rage_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")} />
                        <label htmlFor={`age_rage_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")}>{e.EDAD}</label>
                    </div>
                );
            });

            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini age_rage" id={`age_rage_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")} />
                    <label htmlFor={`age_rage_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "age_rage")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListAgeRange(list);
        }
        setLoadingAgeRanges(false);
    }

    const getInvestment = async () => {
        const res = await API.get('/general/getInvestment');
        if (res.data.investment.length > 0) {
            let list = [];
            res.data.investment.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini investment" id={`investment_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "investment")} />
                        <label htmlFor={`investment_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "investment")}>{e.INVERSION}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini investment" id={`investment_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "investment")} />
                    <label htmlFor={`investment_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "investment")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListInvestment(list);
        }
        setLoadingInvestments(false);
    }

    const getBillings = async (e) => {
        const res = await API.get('/general/getBillings');

        if (res.data.billings.length > 0) {
            let list = [];
            res.data.billings.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini billing" id={`billing_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "billing")} />
                        <label htmlFor={`billing_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "billing")}>{e.FACTURACION}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini billing" id={`billing_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "billing")} />
                    <label htmlFor={`billing_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "billing")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListBilling(list);
        }
        setLoadingBillings(false);
    }

    const getInvoices = async (e) => {
        const res = await API.get('/general/getInvoices');

        if (res.data.invoices.length > 0) {
            let list = [];
            res.data.invoices.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini invoices" id={`invoices_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")} />
                        <label htmlFor={`invoices_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")}>{e.FACTURAS}</label>
                    </div>
                );
            });
            list.push(
                <div className="custom-control custom-checkbox mt-2" key={-1}>
                    <input type="checkbox" className="custom-control-input input-mini invoices" id={`invoices_all`} value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")} />
                    <label htmlFor={`invoices_all`} className="custom-control-label" value="TODOS" onClick={(event) => changeInterestCheckbox(event.target.value, "invoices")}><b>Seleccionar todos</b></label>
                </div>
            );
            setListInvoices(list);
        }
        setLoadingInvoices(false);
    }

    const getProjectStage = async () => {
        const res = await API.get('/general/getProjectStage');
        if (res.data.stage.length > 0) {
            let list = [];
            res.data.stage.map((e, i) => {
                list.push(
                    <div className="custom-control custom-checkbox mt-2" key={i}>
                        <input type="checkbox" className="custom-control-input input-mini stage" id={`stage_${e.ID}`} value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "stage")} />
                        <label htmlFor={`stage_${e.ID}`} className="custom-control-label" value={e.ID} onClick={(event) => changeInterestCheckbox(event.target.value, "stage")}><b>{e.ETAPA}</b></label>
                        <Label className="m-2">
                            <i className="fas fa-arrow-right etm_text_blue mr-2"></i><span dangerouslySetInnerHTML={{ __html: e.TITULO_MENTOR }} ></span>
                        </Label>
                    </div>
                );
            });
            setListStages(list);
        }
        setLoadingProjectStage(false);
    }

    const changeInterestCheckbox = (e, clase) => {
        if (e !== undefined) {
            let list = [];
            let handle = null;

            switch (clase) {
                case "age_rage":
                    list = interestAgeRange;
                    handle = setInterestAgeRange;
                    break;
                case "investment":
                    list = interestInvestment;
                    handle = setInterestInvestment;
                    break;
                case "billing":
                    list = interestBilling;
                    handle = setInterestBilling;
                    break;
                case "invoices":
                    list = interestInvoices;
                    handle = setInterestInvoices;
                    break;
                case "stage":
                    list = interestStages;
                    handle = setInterestStages;
                    break;
            }

            if (e === "TODOS") {
                const all = document.getElementById(`${clase}_all`);
                const elements = document.getElementsByClassName(clase);

                if (all.getAttribute("checked") === null) {
                    if (elements !== undefined && elements.length > 0) {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].setAttribute("checked", "checked");
                        }
                    }
                    handle(e);
                } else {
                    if (elements !== undefined && elements.length > 0) {
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].removeAttribute('checked');
                        }
                    }
                    handle([]);
                }
            } else {
                if (list.includes(e)) {
                    list.splice(list.indexOf(e), 1);
                } else {
                    list.push(e);
                }
                handle(list);
            }
        }
    }

    const save = (e) => {
        preSaveInterestData(e, false);
    }

    const saveContinue = (e) => {
        preSaveInterestData(e, true);
    }

    const preSaveInterestData = (e, nextStep) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading({
            save: (nextStep) ? false : true
            , saveNext: (nextStep) ? true : false
        });

        if (validateRol(["MENTOR", "ADMINISTRADOR"])) {
            if (interestStages.length < 1) {
                swal_etm(`Debes elegir al menos una <b class="etm_text_blue">etapa de emprendimientos</b> de tu interés`);
                setLoading({ save: false, saveNext: false });
                return;
            }
        }

        if (validateRol(["GERENTE/EJECUTIVO", "INVERSIONISTA", "ADMINISTRADOR"])) {
            if (interestAgeRange !== "TODOS" && interestAgeRange.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">antigüedad de los emprendimientos</b> de tu interés`);
                setLoading({ save: false, saveNext: false });
                return;
            }

            if (interestInvestment !== "TODOS" && interestInvestment.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">inversión de los emprendimientos</b> de tu interés`);
                setLoading({ save: false, saveNext: false });
                return;
            }

            if (interestBilling !== "TODOS" && interestBilling.length < 1) {
                swal_etm(`Debes elegir al menos 1 opción de <b class="etm_text_blue">facturación de los emprendimientos</b> de tu interés`);
                setLoading({ save: false, saveNext: false });
                return;
            }

            // if(interestInvoices !== "TODOS" && interestInvoices.length < 1){
            //     swal_etm(`Debes elegir al menos 1 opción de cantidad de <b class="etm_text_blue">facturas de los emprendimientos</b> de tu interés`);
            //     setLoading({ save: false, saveNext: false });
            //     return;
            // }
        }

        if (interestIndustries === null || interestIndustries.length < 1) {
            swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">industria</b> de tu interés`);
            setLoading({ save: false, saveNext: false });
            return;
        }

        if (interestCountry === null || interestCountry.length < 1) {
            swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">país de los emprendimientos</b> de tu interés`);
            setLoading({ save: false, saveNext: false });
            return;
        }

        //  solo considerar regiones para chile
        if (interestCountry.value === chileData.value) {
            if (interestRegion === null || interestRegion.length < 1) {
                swal_etm(`Selecciona al menos 1 <b class="etm_text_blue">región de los emprendimientos</b> de tu interés`);
                setLoading({ save: false, saveNext: false });
                return;
            }
        }

        postSaveInterestData(nextStep);
    }

    const postSaveInterestData = async (nextStep) => {

        const res = await API.put('/profile/updateInterestData', {
            token: getLocalStorage().token
            , profile: (validateRol(["ADMINISTRADOR"])) ? "ADMINISTRADOR" : (validateRol(["MENTOR"])) ? "MENTOR" : "EXPERTO"
            , billing_year: billing_year.year2
            , age_range: interestAgeRange
            , investment: interestInvestment
            , billing: interestBilling
            , industries: interestIndustries
            , country: interestCountry
            , region: interestRegion
            , impacts: interestImpacts
            , ods: interestOds
            , stages: interestStages
            , womenLeader: interestWomenLeader
            , genderEquality: interestGenderEquality
            , diversityInclusion: interestDiversityInclusion
            // , invoices: interestInvoices
        });

        if (res.data.state) {
            swal_etm(`Datos de interés actualizados!`, `success`);
            setLoading({ save: false, saveNext: false });

            setTimeout(function () {
                if (nextStep) {
                    history.push(`/profile/startups`);
                } else {
                    window.location.reload();
                }
            }, 1000);
        } else {
            swal_etm(res.data.msg);
            setLoading({ save: false, saveNext: false });
        }
    }

    return (
        <Fragment>
            {
                (loadingInterestData) ?
                    <LoadScreen text="Obteniendo datos de interés..." className="mt-5" />
                    : <div className="animate__animated animate__fadeIn">
                        <Card className="mt-3 pb-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Datos de Interés y Match</p>
                                    </Label>
                                    <br />
                                    <Label className="h5 mt-0 mb-0 pt-0 pb-0">
                                        <p className="text-muted"><i className="fas fa-info-circle etm_text_blue mr-2"></i>Esta información la utiliza Alan, el bot de encargado de hacer el match, para recomendarte perfiles que puedan ser de tu interés y seleccionar el mejor match posible para cada reunión.</p>
                                    </Label>
                                </Container>
                            </CardTitle>

                            {
                                (validateRol(["MENTOR", "ADMINISTRADOR"])) ?
                                    <>
                                        <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                            <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">Etapas de emprendimientos</Label>
                                            <hr />
                                        </CardTitle>
                                        <CardBody>
                                            <Container>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup check>
                                                            <Label className="h5">{arrow}¿ En qué etapa de los emprendimientos crees que puedes apoyarlos de mejor manera ?{props.asterisco} <small>( elige todas las opciones que quieras )</small></Label>
                                                            <br />
                                                            <br />
                                                            <div className="ml-4">
                                                                {listStages}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </CardBody>
                                    </>

                                    : ""
                            }

                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">Datos para el match</Label>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        {
                                            (validateRol(["GERENTE/EJECUTIVO", "INVERSIONISTA", "ADMINISTRADOR"])) ?
                                                <Fragment>
                                                    <Col md={12} className="mt-3">
                                                        <FormGroup check>
                                                            <Label className="h5">{arrow}¿ Cuál es la antigüedad de los emprendimientos de tu interés ?{props.asterisco} <small>( elige todas las opciones que quieras )</small></Label>
                                                            <br />
                                                            <div className="ml-4">
                                                                {listAgeRange}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12} className="mt-4">
                                                        <FormGroup check>
                                                            <Label className="h5">{arrow}¿ Te interesa juntarte con emprendimientos con fondos públicos o privados ?{props.asterisco} <small>( elige todas las opciones que quieras )</small></Label>
                                                            <br />
                                                            <div className="ml-4">
                                                                {listInvestment}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12} className="mt-4">
                                                        <FormGroup check>
                                                            <Label className="h5">{arrow}¿ Cuál es el rango de facturación de los emprendimientos de tu interés ?{props.asterisco} <small>( elige todas las opciones que quieras )</small></Label>
                                                            <br />
                                                            <div className="ml-4">
                                                                {listBilling}
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={12} className="mt-4">
                                                            <FormGroup check>
                                                                <Label className="h5">{ arrow }¿ Cuál es la cantidad de facturas emitidas de los emprendimientos de tu interés ?{ props.asterisco } <small>( elige todas las opciones que quieras )</small></Label>
                                                                <br/>
                                                                <div className="ml-4">
                                                                    { listInvoices }
                                                                </div>
                                                            </FormGroup>
                                                        </Col> */}
                                                </Fragment>
                                                : ""
                                        }

                                        <Col md={12} className="mt-5">
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Cuáles son las industrias de tu interés ?{props.asterisco} <small>( selecciona todas las industrias que quieras )</small></Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona industrias"}
                                                    value={interestIndustries}
                                                    onChange={setInterestIndustries}
                                                    options={listIndustries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-5">
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Cuáles países son de tu interés respecto a emprendimientos ?{props.asterisco} <small>( selecciona todos los países que quieras )</small></Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona países"}
                                                    value={interestCountry}
                                                    onChange={setInterestCountry}
                                                    options={listCountries}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={180}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-5" hidden={!showRegions}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Cuáles son las regiones de Chile de tu interés ?{props.asterisco} <small>( selecciona todas los regiones que quieras )</small></Label>
                                                <br />
                                                <Select
                                                    placeholder={"Selecciona regiones"}
                                                    value={interestRegion}
                                                    onChange={setInterestRegion}
                                                    options={listRegions}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={100}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>

                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">Propósito e impacto social</Label>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row className="mt-3">
                                        <Col md={12}>
                                            <FormGroup check>
                                                <Label className="h5">¿ Te interesan proyectos con algún objetivo de <span className="etm_text_blue">Impacto Social</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                <br />
                                                <Label className="h5"><small className="text-muted">Elige todas las que consideres de la lista o puedes crear nuevas opciones al escribirlas.</small></Label>
                                                <br />
                                                <Creatable
                                                    formatCreateLabel={e => `Crear nueva opción ${e}`}
                                                    placeholder={"Elige algunas opciones"}
                                                    value={interestImpacts}
                                                    onChange={setInterestImpacts}
                                                    options={listImpacts}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={150}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-5">
                                            <FormGroup check>
                                                <Label className="h5">¿ Te interesan proyectos que tengan <span className="etm_text_blue">Objetivos de Desarrollo Sostenible</span> ( ODS ) ? <small className="text-muted">( Opcional )</small></Label>
                                                <br />
                                                <Select
                                                    placeholder={"Elige algunas opciones"}
                                                    value={interestOds}
                                                    onChange={setInterestOds}
                                                    options={listOds}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={150}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-5">
                                            <FormGroup check>
                                                <Label className="h5">¿ Te interesan proyectos liderados por alguna <span className="etm_text_blue">Mujer</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioMujer" type="radio" onChange={() => setInterestWomenLeader(0)} checked={!interestWomenLeader} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioMujer" type="radio" onChange={() => setInterestWomenLeader(1)} checked={interestWomenLeader} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">¿ Te interesan proyectos con políticas de <span className="etm_text_blue">Equidad de Género</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioEquidad" type="radio" onChange={() => setInterestGenderEquality(0)} checked={!interestGenderEquality} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioEquidad" type="radio" onChange={() => setInterestGenderEquality(1)} checked={interestGenderEquality} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} className="mt-4">
                                            <FormGroup check>
                                                <Label className="h5">¿ Te interesan proyectos con políticas de <span className="etm_text_blue">Inclusión de Diversidad</span> ? <small className="text-muted">( Opcional )</small></Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioDiversidad" type="radio" onChange={() => setInterestDiversityInclusion(0)} checked={!interestDiversityInclusion} />{' '}NO
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioDiversidad" type="radio" onChange={() => setInterestDiversityInclusion(1)} checked={interestDiversityInclusion} />{' '}SI
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn handle={save} title="Guardar" text="Guardando..." loading={loading.save}  color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md"  />
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                        <Container>
                            <Row hidden>
                                <Col lg={4} md={2}></Col>
                                <Col lg={4} md={8}>
                                    <LoadBtn handle={save} loading={loading.save} className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={2}></Col>
                            </Row>
                            {/* <Row>
                                <Col lg={2} md={2}></Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={save} loading={ loading.save } className="btn-lg" color="success" familyIcon="fas fa" icon="check" title="Guardar" text="Guardando..." />
                                </Col>
                                <Col lg={4} md={12}>
                                    <LoadBtn handle={saveContinue} loading={ loading.saveNext }className="btn-lg" color="primary" familyIcon="fas fa" icon="check-circle" title="Guardar y continuar" text="Guardando..." />
                                </Col>
                                <Col lg={2} md={2}></Col>
                            </Row> */}
                        </Container>
                    </div>
            }
        </Fragment>
    );
}

export default (InterestData);