import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody,Container, FormGroup, Form, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import LoadBtn from "../../components/Utils/LoadBtn";
import CreateBy from "../../components/Utils/CreatedBy";
import "../../assets/scss/etm_login.scss";
import { validateEmail } from "../../helpers/etmmeetUtils";
import swal from 'sweetalert';
import { API } from '../../helpers/axios';
import logo from "../../assets/images/conecTime/logo_full_blanco.png";
import icono_login from "../../assets/images/conecTime/design/Ilustracion-inicio.png";
import bg_image from "../../assets/images/conecTime/design/Fondo-azul.png";

// const ForgetPasswordPage = (props) => {
const ForgetPasswordPage = ({ history }) => {

  useEffect(() => {
    document.body.style.backgroundImage = "url(" + bg_image + ")";
    document.body.style.height = "100%";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "hidden";
    return () => {
        document.body.style.backgroundImage = null;
        document.body.style.height = null;
        document.body.style.backgroundPosition = null;
        document.body.style.backgroundRepeat = null;
        document.body.style.backgroundSize = null;
        document.body.style.overflowX = null;
        document.body.style.overflowY = null;
    }
  }, []);

  const [email, setEmail]     = useState('');
  const [loading, setLoading] = useState(false);

  const handleReset = async (e) => {
    e.preventDefault();

    if(!validateEmail (email)){
      swal("Ups!", "Debe ingresar un correo válido.", "warning");
      return false;
    }else{
      setLoading(true);
      const res = await API.post("/recoveryPass", {
          email
      });
      setLoading(false);
      if (res.data.state) {
        swal("Contraseña Reestablecida", "Se ha enviado un correo a '" + email + "' con la nueva contraseña.", "success");
        history.push('/login');
      } else {
        swal("Ups!", res.data.msg, "warning");
      }
    }
  }
    
  return (
    <Fragment>
      <Row className="etm_no_scroll">
        <Col md={ 5 } className="etm_login_bg ">
          <Container className="mt-5 pt-lg-3 pt-3">
            <Card className="ml-sm-5 mr-sm-5 bg-transparent">
              <div className="text-center">
                <img src={ logo } alt="" className="img-fluid" width="40%"/>
              </div>
              <CardBody className="m-sm-5">
                <Form className="etm_login">
                  <div className="form-horizontal pb-5">
                    <FormGroup>
                      <Label className="text-white">Tu email</Label>
                      <Input onChange={(e) => setEmail(e.target.value)} name="email" className="etm_login_input" type="email" required placeholder="Ingresa tu email" />
                    </FormGroup>
                    <FormGroup className="mt-4">
                      <LoadBtn handle={ handleReset } loading={ loading } title="Recuperar contraseña"/>
                    </FormGroup>
                    <div className="mt-5 text-center">
                        <Label className="h5 text-white">¿Aún no te integras a nuestra comunidad?</Label>
                        <Link to="register">
                          <Button color="primary" size="lg" className="etm_btn pl-5 pr-5"><b>¡ REGÍSTRATE AQUÍ !</b></Button>
                        </Link>
                    </div>
                    <div className="mt-3 text-center">
                      <Link to="/login" className="etm_link_white"><i className="fas fa-chevron-left mr-2"></i>Volver</Link>
                    </div> 
                  </div> 
                </Form>
              </CardBody>
            </Card>
          </Container>
        </Col>
        <Col md={ 7 } className="text-center mt-5 etm_logo_hide_mobile">
          <div>
            <img src={ icono_login } alt="" className="img-fluid w-50 mt-5"/>
          </div>
        </Col>
        <CreateBy full={ false }/>
      </Row>
  </Fragment>
  );
}

export default (ForgetPasswordPage);
