import React, { useEffect, useState, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";

import Logo from "../../assets/images/conecTime/logo_conectime.png";
import WebpayResponse from './WebpayResponse';

const DonationResponse = ( props ) => {
    return (
        <Fragment>
            <div className="">
                <Row className="etm_public_profile_bg_logo">
                    <Col className="text-center">
                        <Link to="/home">
                            <img src={ Logo } alt="" className="img-fluid etm_public_profile_logo"/>    
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Container className="pt-3">
                        <WebpayResponse props={ props }/>
                    </Container>
                </Row>
            </div>
        </Fragment >
    );
}

export default withRouter(DonationResponse);