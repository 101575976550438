import React, { useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Card, Button } from "reactstrap";

//i18n
import { withNamespaces } from 'react-i18next';
import { API } from '../../helpers/axios';
import { formatNumber } from "../../helpers/etmmeetUtils"; 

const Webpay = ({ props }) => {
    
    const[listAmount, setListAmount] = useState([
        {
        //         label: "$ 1.000"
        //     ,   value: 1000
        // }, {
        //         label: "$ 50"
        //     ,   value: 50
        // }, {
                label: "$ 5.000"
            ,   value: 5000
        }, {
                label: "$ 10.000"
            ,   value: 10000
        }, {
                label: "$ 20.000"
            ,   value: 20000
        }, {
                label: "$ 30.000"
            ,   value: 30000
        }, {
                label: "$ 50.000"
            ,   value: 50000
        }, {
                label: "$ 100.000"
            ,   value: 100000
        }, {
                label: "$ 200.000"
            ,   value: 200000
        }, {
                label: "$ 500.000"
            ,   value: 500000
        // }, {
        //         label: "$ 1.000.000"
        //     ,   value: 1000000
        }, {
                label: "Otro monto"
            ,   value: "otro"
        }
    ]);
    const[cards, setCards] = useState([]);
    const[amount, setAmount] = useState(0);
    const[showOther, setShowOther] = useState(false);
    const[showVoucher, setShowVoucher] = useState(false);
    const[token, setToken] = useState("");
    const[url, setUrl] = useState("");

    useEffect(() => {
        getCards();
    }, []);

    const getCards = async () => {
        let list = [];
        listAmount.map((e, i) => {
            list.push(  <Col xl={4} lg={4} md={4} sm={4} xs={6} className="text-center pt-2 pb-2" key={i}>
                            <div className="etm_donation_paycard pt-3 pb-3" onClick={(x) => changeAmount(x, e.value) }>
                                { e.label }
                            </div>
                        </Col>);
        });
        setCards(list);
    }
    
    const changeAmount = (element, value) => {
        const elements = document.getElementsByClassName("etm_donation_paycard");
        if(elements.length > 0){
            for(var i = 0; i < elements.length; i++){
                elements[i].classList.remove("etm_donation_paycard_active");
            }
        }
        element.target.classList.add("etm_donation_paycard_active");
        if(value === "otro"){
            setShowOther(true);
            setAmount(0);
        }else{
            setShowOther(false);
            setAmount(value);
        }
    } 
  
    const getWebpayToken = async () => {
        let user_id = 0;
        let user_login = 0;
        
        if(props.location.pathname.length > 0){
            const data  = props.location.pathname.split('/');
            user_id     = (data[2] !== null && data[2] !== undefined && data[2] !== "") ? data[2] : 0;
        }

        if(user_id < 1){
            const ls    = JSON.parse(localStorage.getItem('user'));
            if(ls !== null && ls !== undefined){
                user_id     = ls.id;
                user_login  = 1;
            }
        }
        
        const res = await API.post("/donation_webpay_create", { user_id, amount, user_login });
        if(res.data.state){
            setToken(res.data.token)
            setUrl(res.data.url)
            setShowVoucher(!showVoucher);
        }
    }

    return (
        <React.Fragment>
            <Card>
                <Col xs={12} className="pt-3">
                    <Row>
                        <Col xs={12}>
                            <h4 className="etm_text_blue mb-3"><i className="fas fa-address-card mr-2"></i>Donación mediante Webpay Plus</h4>
                            <label className="mb-3">Selecciona el monto que deseas donar</label>
                        </Col>
                        { cards }
                        <Col xl={4} md={6} sm={12} className="mt-3 animate__animated animate__fadeInUp" hidden={ !showOther }>
                            <label>Ingresa el monto que desees (montos sin decimales)</label>
                            <input type="number" min="0" className="form-control etm_input_text" min="0" onChange={(e) => setAmount(e.target.value)}/>
                        </Col>
                        <Col xs={12} className="pt-5 pb-3 text-center">
                            <Button color={"primary"} size={"md"} className="etm_btn pl-5 pr-5" disabled={(amount > 0) ? false : true } onClick={(e) => getWebpayToken()}><i className="fas fa-arrow-alt-circle-right mr-2"></i>Donar</Button>
                        </Col>
                        <Col xs={12} className="pt-3 text-center animate__animated animate__fadeInUp" hidden={ !showVoucher }>
                            <hr />
                            <Container>
                                <label className="h5 mb-3">Usted va a donar <b>$ { formatNumber(amount) }</b> a la <span className="etm_text_blue">Corporación Emprende tu Mente</span>.<br/>¿Desea continuar?</label>
                                <form method="post" action={ url }>
                                    <FormGroup>
                                        <input type="hidden" name="token_ws" className="form-control etm_input_text" value={ token } />
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color={"primary"} size={"md"} className="etm_btn pl-5 pr-5" type={"submit"}><i className="fas fa-arrow-alt-circle-right mr-2"></i>Confirmar y proceder con la donación</Button>
                                    </FormGroup>
                                </form>
                            </Container>
                        </Col>
                    </Row>
                </Col>
            </Card>
        </React.Fragment>
    );
}

export default withNamespaces()(Webpay);