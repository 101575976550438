import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { setSession } from '../../helpers/authUtils';
import { validateRol, isMobile, getLocalStorage, swal_etm } from "../../helpers/etmmeetUtils";
import "../../assets/scss/etm.scss";
import { API } from '../../helpers/axios';
import NextMeetings from '../../components/Home/NextMeetings';
import SearchGeneral from "../../components/Home/Search/SearchGeneral";
import ModalEtMday from "../../components/Home/Modal/ModalEtMday";
import ModalChangeLog from "../../components/Home/Modal/ModalChangeLog";
import Challenges from '../../components/Home/Challenges';
import LoadScreen from "../../components/Utils/LoadScreen";

const Home = ({ history }) => {

    const query = new URLSearchParams(useLocation().search);
    const [urlUserType, setUrlUserType] = useState(null);
    const [urlUserToken, setUrlUserToken] = useState(null);

    useEffect(() => {
        query.forEach(function (value, key) {
            if (key !== undefined && key !== null) {
                if (key === 't' && value !== null && value.length > 0) {
                    setUrlUserType(value.toString().toUpperCase());
                }
                if (key === 'u' && value !== null && value.length > 0) {
                    setUrlUserToken(value.toString());
                }
            }
        });
    }, []);

    const [run, setRun] = useState(false);
    const [tour, setTour] = useState(0);
    const [user, setUser] = useState(getLocalStorage());

    const [roleData, setRoleData] = useState(null);
    const [roleDual, setRoleDual] = useState(false);
    const [modalChangeLog, setModalChangeLog] = useState(false);

    useEffect(() => {
        getTourByUser();
        haveToSeeChangeLog()
    }, []);

    const getTourByUser = async () => {
        const res = await API.get('/getTourByUser', { params: { token: user.token } });
        if (res.data.state) {
            setTour(res.data.tour);
            if (!res.data.tour) setRun(true);
        }
        postSetTutorial();
    }

    let steps = [];
    steps.push(
        {
            content: 'Bienvenido a ETMmeet! Realicemos el siguiente tour por las secciones principales de la plataforma!',
            locale: { next: 'Siguiente', skip: 'Omitir' },
            placement: 'center',
            target: 'body',
        }
    );

    steps.push(
        {
            content: 'Este es el Menú desde donde podrás acceder a todas las secciones',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: (isMobile() === null) ? '.step-tour-menu' : '.step-tour-mobile',
        },
        {
            content: (isMobile() === null) ? 'Aquí puedes completar TU PERFIL para tener mayores oportunidades para postular a reuniones!' : 'Completar TU PERFIL del menú para tener mayores oportunidades para postular a reuniones!',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: (isMobile() === null) ? '.step-tour-profile' : '.step-tour-mobile',
        },
        {
            content: 'Este es el resumen de tus reuniones!',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: '.step-tour-summary',
        },
        {
            content: 'Aquí aparecerán tus próximas reuniones agendadas!',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: '.step-tour-nextmeets',
        }
    );

    if ((validateRol(['EMPRENDEDOR', 'ADMINISTRADOR']))) {
        steps.push(
            {
                content: (isMobile() === null) ? 'Crea tus emprendimientos para postular a reuniones!' : 'Crea tus emprendimientos desde el menú Mis Emprendimientos para postular a reuniones!',
                locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
                spotlightPadding: 20,
                target: (isMobile() === null) ? '.step-tour-startups' : '.step-tour-mobile',
            }
        );
    }

    if ((validateRol(['MENTOR', 'INVERSIONISTA', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR']))) {
        steps.push(
            {
                content: (isMobile() === null) ? 'Dona todas las horas que quieras para que emprendedores puedan reunirse contigo' : 'Dona todas las horas que quieras desde el menú Donar Horas para que emprendedores puedan reunirse contigo',
                locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
                spotlightPadding: 20,
                target: (isMobile() === null) ? '.step-tour-hours' : '.step-tour-mobile',
            }
        );
    }

    steps.push(
        {
            content: 'Aquí encontrarás todos los perfiles para contactar con ellos!',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: '.step-tour-search',
        },
        {
            content: 'Utiliza los filtros de búsqueda para encontrar tu match perfecto',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: '.step-tour-filter',
        },
        {
            content: (isMobile() === null) ? 'Puedes gestionar todas tus solicitudes aquí' : 'Puedes gestionar todas tus solicitudes desde el menú Mis Solicitudes',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: (isMobile() === null) ? '.step-tour-inbox' : '.step-tour-mobile',
        },
        {
            content: 'Cuando ya tengas reuniones agendadas podrás acceder a la Sala de Espera desde el menú. Cuando sea el día y hora de tu reunión, comenzará automáticamente la videollamada desde la Sala de Espera!',
            locale: { back: 'Anterior', next: 'Siguiente', skip: 'Omitir' },
            spotlightPadding: 20,
            target: (isMobile() === null) ? '.step-tour-meet' : '.step-tour-mobile',
        },
        {
            content: 'Esperamos que disfrutes tu experiencia en ETMmeet y que logres concretar todas las reuniones posibles. Prepara tu Pitch!!!',
            locale: { back: 'Anterior', next: 'Siguiente', last: 'Terminar' },
            placement: 'center',
            target: 'body',
        }
    );

    const postSetTutorial = async () => {
        const res = await API.put('/setMeetTutorial', { user_id: user.id });
        if (res.data.state) {
            setSession(localStorage.getItem('auth'), res.data.user);
        }
    };

    const haveToSeeChangeLog = async () => {
        const res = await API.get('/general/haveToSeeChangeLog', { params: { token: user.token } });
        setModalChangeLog(res.data.state)
    }

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Home'} />
                    <Row>
                        {
                            <Fragment>
                                {
                                    (validateRol(['EMPRENDEDOR', 'ADMINISTRADOR'])) ?
                                        <Col lg={12}>
                                            <Challenges />
                                        </Col>
                                        : ""
                                }
                                <Col md={12}>
                                    <NextMeetings />
                                </Col>
                                <Col md="12">
                                    {
                                        <SearchGeneral
                                            roleDual={roleDual}
                                            urlUserType={urlUserType}
                                            setUrlUserType={setUrlUserType}
                                            urlUserToken={urlUserToken}
                                            setUrlUserToken={setUrlUserToken}
                                        />
                                    }
                                </Col>
                                <ModalEtMday />
                            </Fragment>
                        }
                    </Row>
                </Container>
                <ModalChangeLog
                    modal={modalChangeLog}
                    setModal={setModalChangeLog}
                    type='last'
                />
            </div>
        </Fragment>
    );
}

export default withRouter((Home));