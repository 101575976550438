import React from 'react';
import { Row } from "reactstrap";

const Breadcrumb = (props) => {
  return (
     <Row>
        {/* <Col xs="12">
            {
                (props.title.toUpperCase() !== "HOME") ?    <div className="mb-2">
                                                                <BackHome />
                                                            </div>  : ""
            }
            
            <div className="page-title-box d-flex align-items-center justify-content-between">
                
                <h5 className="mb-0"><img src={Logo} className="logo_breadcrumb mr-3" /> { props.title } </h5>
                <div className="page-title-right d-none d-sm-block">
                    <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                            <Link to="/home"><b>EtM<i>meet</i></b></Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <Link to="#">{props.title}</Link>
                        </BreadcrumbItem>
                </ol>
                </div>
            </div>
        </Col> */}
    </Row>
  );
}

export default Breadcrumb;