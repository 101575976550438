import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, CardBody, Card, Container, FormGroup, Label, Input } from "reactstrap";
import LoadBtn from "../Utils/LoadBtn";
import { API } from '../../helpers/axios';
import { validateEmail, swal_etm } from "../../helpers/etmmeetUtils";
import img1 from "../../assets/images/etmmeet/onboarding/step1_mail.png";
import img2 from "../../assets/images/etmmeet/onboarding/step1_spam_gmail.png";
import img3 from "../../assets/images/etmmeet/onboarding/step1_spam_outlook.png";

const Step1 = ( props ) => {
    const[verify, setVerify] = useState(false);
    const[nuevoMail, setNuevoMail] = useState({value: props.data.email, valid: false, invalid: false});
    const[resend, setResend] = useState(false);
    const[resend2, setResend2] = useState(false);
    const[section1, setSection1] = useState(false);
    const[section2, setSection2] = useState(false);
    const[section3, setSection3] = useState(false);
    const[section4, setSection4] = useState(false);
    const[section5, setSection5] = useState(false);
    const[tutorialSpam, setTutorialSpam] = useState({ validate: true, spam: false, correo: false });
    
    useEffect(() => {
        validateVerifyStatus();
    }, [props.data.name]);
    
    useEffect(() => {
        delay(1, 1000);
        delay(2, 3000);
        delay(3, 5000);
        delay(4, 7000);
        delay(5, 9000);
    }, [props.data]);

    useEffect(() => {
        if(!verify){
            setInterval(function () {
                validateVerifyStatus();
            }, 3000);
        }
    }, []);

    const validateVerifyStatus = async () => {
        const res = await API.get('/register/getVerifyStatus', { params: { token: props.getToken() } });
        if (res.data.state) {
            setVerify(true);
        }
    }

    const delay = ( step, time ) => {
        switch(step){
            case 1:
                setTimeout(function(){ setSection1(true) }.bind(this), time);
                break;
            case 2:
                setTimeout(function(){ setSection2(true) }.bind(this), time);
                break;
            case 3:
                setTimeout(function(){ setSection3(true) }.bind(this), time);
                break;
            case 4:
                setTimeout(function(){ setSection4(true) }.bind(this), time);
                break;
            case 5:
                setTimeout(function(){ setSection5(true) }.bind(this), time);
                break;
        }
    }

    const resendMail = async () => {
        setTutorialSpam({ validate: true, spam: false, correo: false})
        setResend(true);
        const res = await API.post('/register/resendWelcomeMail', { token: props.getToken() });
        if (res.data.state) {
            swal_etm(`Correo enviado.`, `success`);
        }
        setResend(false);
    }

    const changeMail = ( value ) => {
        setNuevoMail({
                value:      value
            ,   valid:      (validateEmail(value))  ? true : false
            ,   invalid:    (validateEmail(value))  ? false : true
        });
    }

    const corregirCorreoEnviado = async () => {
        setResend2(true);
        if(validateEmail(nuevoMail.value)){
            const res = await API.post('/register/updateMail', { token: props.getToken(), correo: nuevoMail.value });
            if (res.data.state) {
                props.getUserByToken();
                resendMail();
            }else{
                swal_etm(res.data.msg, `error`);
            }
        }else{
            swal_etm("Debes ingresar un correo válido");
        }
        setResend2(false);
    }
    
    return (
        <Fragment>
            <Row>
                <Col className="bg_onboarding_left" lg={5} md={12}>
                    <div className="ml-5 mr-5 mb-5 mt-4">
                        <Container>
                            <Label className="h2"><p className="animate__animated animate__fadeIn" hidden={ !section1 }>¡ Hola <b className="etm_text_blue">{ props.data.name }</b> !</p></Label>
                            <Label className="h4 mt-2">
                                <p className="animate__animated animate__fadeIn" hidden={ !section1 }>Bienvenido a { props.etm }, la plataforma gratuita de conexiones digitales de <b>Emprende tu Mente</b> donde podrás tener reuniones comerciales y de mentorías con diferentes expertos y expertas de todas las industrias.</p>
                                <p className="animate__animated animate__fadeIn" hidden={ !section2 }>Para poder hacer un buen match necesitamos conocerte mejor y por ello te pediremos unos datos adicionales, pero tranquilo, te acompañaremos en todo momento.</p>
                            </Label>
                            <hr className="animate__animated animate__fadeIn" hidden={ !section3 }/>
                            <Label className="h2"><p className="animate__animated animate__fadeIn" hidden={ !section3 }><i className="fas fa-arrow-right etm_text_blue mr-2"></i>Validemos tu cuenta</p></Label>
                            <Label className="h4 mt-2">
                                <p className="animate__animated animate__fadeIn" hidden={ !section4 }>Primero validemos tu cuenta.<br/>Ingresa a tu correo <b className="etm_text_blue">{ props.data.email }</b> y busca el asunto "<b>Bienvenido a EtMmeet</b>" y haz click en el link de verificación.</p>
                                <p className="animate__animated animate__fadeIn" hidden={ !section5 }>Si no ves este correo, revisa tu carpeta de <b>Spam</b> (si está en spam, te recomendamos desmarcarlo de ahí para evitar futuros problemas.)</p>
                                <a href="#viewer_section">
                                    <div className="animate__animated animate__fadeIn ml-4" hidden={ !section5 } onClick={() => resendMail() }>
                                        { 
                                            (resend) ? 
                                                <Fragment>
                                                    <p className="spinner-border spinner-border-sm etm_link_blue mr-2 mb-3" role="status" ></p>
                                                    <span className="etm_link_blue">Reenviando...</span>
                                                </Fragment>
                                            :   <Fragment>
                                                    <i className="fas fa-arrow-right etm_text_blue mr-2 mb-3"></i><u className="etm_link_blue pointer"><b>¿REENVIAR CORREO DE BIENVENIDA?</b></u>
                                                </Fragment>
                                        }
                                    </div>
                                    <p className="animate__animated animate__fadeIn ml-4 mb-3" hidden={ !section5 } onClick={() => setTutorialSpam({ validate: false, spam: true, correo: false }) }>
                                        <i className="fas fa-arrow-right etm_text_blue mr-2"></i>
                                        <u className="etm_link_blue pointer"><b>¿TE ENSEÑO CÓMO DESMARCARLO DE SPAM?</b></u>
                                    </p>
                                    <p className="animate__animated animate__fadeIn ml-4 mt-0" hidden={ !section5 } onClick={() => setTutorialSpam({ validate: false, spam: false, correo: true }) }>
                                        <i className="fas fa-arrow-right etm_text_blue mr-2"></i>
                                        <u className="etm_link_blue pointer"><b>¿CORREO INCORRECTO?</b></u>
                                    </p>
                                </a>
                            </Label>
                            <div  className="text-center mt-3" hidden={ (section5 && verify) ? false : true }>
                                <Label className="h1"><p className="animate__animated animate__fadeIn"><i className="fas fas fa-thumbs-up etm_text_blue mr-2"></i>¡ Perfecto ! hemos validado tu cuenta.</p></Label>
                                <LoadBtn className="btn-lg" handle={ () => props.saveStep(1) } loading={ false } title="¡ CONTINUAR !" text="Continuar..."/>
                            </div>                            
                        </Container>
                    </div>
                </Col>
                <Col className="bg_onboarding_right" lg={7} md={12} id="viewer_section">
                    <div className="m-5" hidden={ !tutorialSpam.validate }>
                        <img src={ img1 } className="img-fluid img_onboarding animate__animated animate__fadeIn" alt="" hidden={ !section4 }/>
                        <p className="animate__animated animate__fadeIn h2 text-white text-center mt-1" hidden={ !section4 }>Valida tu cuenta</p>
                    </div>
                    <div className="m-5 text-center" hidden={ !tutorialSpam.spam }>
                        <img src={ img2 } className="img-fluid img_onboarding animate__animated animate__fadeIn" alt="" width="80%" hidden={ !section4 }/>    
                        <p className="animate__animated animate__fadeIn h2 text-white mt-1 mb-5" hidden={ !section4 }>Desmarcar Spam Gmail</p>
                        <img src={ img3 } className="img-fluid img_onboarding animate__animated animate__fadeIn" alt="" width="80%" hidden={ !section4 }/>    
                        <p className="animate__animated animate__fadeIn h2 text-white mt-1" hidden={ !section4 }>Desmarcar Spam Outlook</p>
                    </div>
                    <div className="m-5 pt-5 text-left" hidden={ !tutorialSpam.correo }>
                        <Card className="ml-5 mr-5 mt-5" style={{opacity: '0.9'}}>
                            <CardBody className="m-3"> 
                                <Col xs={12}>
                                    <FormGroup className="animate__animated animate__fadeIn">
                                        <p className="animate__animated animate__fadeIn h3" hidden={ !section3 }><i className="fas fa-arrow-right etm_text_blue mr-2"></i>¿CORREO INCORRECTO?</p>
                                        <p className="animate__animated animate__fadeIn h5 mt-3" hidden={ !section3 }> Ingresa el correo correcto a continuacón</p>
                                        <Input  value={nuevoMail.value} onChange={(e) => changeMail(e.target.value)} name="email" id="email" className="form-control etm_register_input" type="email" placeholder="Ingresa tu correo electrónico" valid={nuevoMail.valid} invalid={nuevoMail.invalid}/> 
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <LoadBtn className="btn-lg" handle={ corregirCorreoEnviado } loading={ resend2 } title="¡ Actualizar y reenviar correo !" text="Reenviando correo..."/>
                                </Col>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Fragment>
  );
}

export default (Step1);