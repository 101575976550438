import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Label } from "reactstrap";
import { API } from '../../helpers/axios';
import Emprendedor from "./step4Emprendedor";
import Experto from "./step4Expertos";

const Step4 = (props) => {
    const [rol, setRol] = useState({ name: null });

    useEffect(() => {
        getRol();
    }, []);

    const getRol = async () => {
        const res = await API.get("/register/getUserRole", { params: { token: props.getToken() } });
        if (res.data.state) {
            setRol({ name: res.data.rol });
        }
    }

    return (
        <Fragment>
            <Row>
                <Col className="bg_onboarding_blue text-center" md={12}>
                    {
                        (rol.name === null) ?
                            <Row className="mt-2">                                  
                                <Col className="text-center h1" md={12}>
                                    <div className="text-white mt-5">
                                        <div className="spinner-border spinner-border-md m-1 mr-2 mt-5 text-white" role="status" /> Cargando...
                                    </div>
                                </Col>
                            </Row>
                        :  
                            <Fragment> 
                                <Label className="h1 mt-5"><p className="animate__animated animate__fadeIn"><b className="text-white">{ (rol.name!== null && rol.name.length > 1) ? `¡ SOY ${rol.name} !` : "" }</b></p></Label>
                                <Row className="mt-2">
                                    {
                                        (rol.name === 'EMPRENDEDOR') ? 
                                        <Emprendedor rol={ rol } getToken={ props.getToken } data={ props.data } saveStep={ props.saveStep }/>
                                    :   <Experto rol={ rol }  getToken={ props.getToken }  data={ props.data } saveStep={ props.saveStep }/>
                                    }
                                </Row>
                            </Fragment>
                    }
                </Col>
            </Row>
        </Fragment>
    );
}

export default (Step4);