import React, { useState, useEffect, Fragment } from 'react';

import { Row, Col, Card, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { API } from '../../helpers/axios';
import { capitalize, getNameDate } from "../../helpers/etmmeetUtils";

import emprendedor from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import administrador from "../../assets/images/conecTime/design/Perfil-administrador.png";

const NextMeetings = (props) => {

    const ls = JSON.parse(localStorage.getItem('user'));
    const [nextMeeting, setNextMeeting] = useState([]);

    useEffect(() => {
        getNextMeetings(ls.id);
    }, []);

    useEffect(() => {
        setInterval(function () {
            getNextMeetings(ls.id);
        }, 60000);
    }, []);

    const getNextMeetings = async (id) => {
        const res = await API.get('/getNextMeetings', { params: { user_id: id } });
        if (res.data.state) {
            let list = [];
            res.data.meetings.map((e, i) => {
                let rol_img = '';
                let rol = '';
                if (ls.id === e.user_id) {
                    switch (e.MANAGER_ROL.toUpperCase()) {
                        case "ADMINISTRADOR":
                            rol_img = administrador;
                            rol = "Administrador";
                            break;
                        case "GERENTE/EJECUTIVO":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            break;
                        case "INVERSIONISTA":
                            rol_img = inversionista;
                            rol = "Inversionista";
                            break;
                        case "MENTOR":
                            rol_img = mentor;
                            rol = "Mentor";
                            break;
                        case "EMPRENDEDOR":
                            rol_img = emprendedor;
                            rol = "Emprendedor";
                            break;
                    }
                } else {
                    switch (e.USER_ROL.toUpperCase()) {
                        case "ADMINISTRADOR":
                            rol_img = administrador;
                            rol = "Administrador";
                            break;
                        case "GERENTE/EJECUTIVO":
                            rol_img = gerente;
                            rol = "Gerente-Ejecutivo";
                            break;
                        case "INVERSIONISTA":
                            rol_img = inversionista;
                            rol = "Inversionista";
                            break;
                        case "MENTOR":
                            rol_img = mentor;
                            rol = "Mentor";
                            break;
                        case "EMPRENDEDOR":
                            rol_img = emprendedor;
                            rol = "Emprendedor";
                            break;
                    }
                }
                list.push(
                    <Col key={i + 1} lg={3}>
                        <Card className="next_meet shadow-lg" id={'key_' + i}>
                            <Link to="/meet">
                                <div className="ml-1 mr-1 mt-1 mb-3 p-1 text-center etm_next_meeting_bg position-relative">
                                    <img
                                        src={(ls.id === e.user_id) ? e.MANAGER_PICTURE : e.USER_PICTURE}
                                        className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg ml-2 etm_next_meeting_picture translate-middle"
                                        alt="img"
                                    />
                                    <Label className="h6">{getNameDate(e.DAY)}</Label>
                                    <br />
                                    <Label className="h6">{e.HOUR}</Label>
                                </div>
                                <Row hidden>
                                    <Col xs={8} className="text-left">
                                        <Label className="h5 text-break font-weight-medium">
                                            <b>
                                                {
                                                    (ls.id === e.user_id) ?
                                                        (e.MANAGER.length > 20) ? e.MANAGER.substring(0, 20) + "..." : e.MANAGER
                                                        : (e.USER.length > 20) ? e.USER.substring(0, 20) + "..." : e.USER
                                                }
                                            </b>
                                        </Label>
                                        <div>
                                            <Label className="h6 text-break font-weight-medium mb-0">
                                                {
                                                    (ls.id === e.user_id) ?
                                                        (e.MANAGER_CHARGE !== undefined && e.MANAGER_CHARGE !== null) ?
                                                            <Fragment>
                                                                {e.MANAGER_CHARGE.toUpperCase()}
                                                                {
                                                                    (e.MANAGER_COMPANY !== undefined && e.MANAGER_COMPANY !== null) ?
                                                                        <Fragment>
                                                                            {` `} EN {e.MANAGER_COMPANY.toUpperCase()}
                                                                        </Fragment>
                                                                        : ""
                                                                }
                                                            </Fragment>
                                                            : (e.MANAGER_COMPANY !== undefined && e.MANAGER_COMPANY !== null) ?
                                                                <Fragment>
                                                                    {e.MANAGER_COMPANY.toUpperCase()}
                                                                </Fragment>
                                                                : ""
                                                        : (e.STARTUP !== undefined && e.STARTUP !== null) ?
                                                            <Fragment>
                                                                {e.STARTUP.toUpperCase()}
                                                            </Fragment>
                                                            : ""
                                                }
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <hr />
                                    </Col>
                                    <Col xs={12}>
                                        <Label className="h6"> <b>Perfil: </b>{rol}</Label>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>
                )
            });
            setNextMeeting(list);
        } else {
            setNextMeeting([]);
        }
    }

    return (
        <Fragment>
            <Row className="step-tour-nextmeets">
                {
                    (nextMeeting.length > 0) ?
                        <Fragment>
                            <Col md="12" className="text-center mt-0">
                                <Label className="h4">Próximas Reuniones</Label>
                            </Col>
                            <Col md="12">
                                <Row>
                                    {nextMeeting}
                                </Row>
                            </Col>
                        </Fragment>
                        : ""
                }
            </Row>
        </Fragment>
    );
}

export default NextMeetings;