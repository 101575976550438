import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardTitle, Label, CardBody, FormGroup, Input, UncontrolledTooltip, CustomInput } from "reactstrap";
import LoadScreen from "../Utils/LoadScreen";
import { validatePassword, pathS3, selectCustomStyle, getLocalStorage, validateRol, swal_etm, swal_etm_confirm } from "../../helpers/etmmeetUtils";
import LoadBtn from "../Utils/LoadBtn";
import UploadImageFile from "../Utils/UploadImageFile";
import AvatarDefault from "../Utils/AvatarDefault";
import { API } from '../../helpers/axios';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import emprendimiento from "../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import { setSessionUpdateUser } from '../../helpers/authUtils';

const AccountData = (props) => {

    const arrow = <i className="fas fa-arrow-right etm_text_blue mr-2"></i>;

    const [gettingData, setGettingData] = useState(true);
    const [loading, setLoading] = useState({
        nickname: false
        , password: false
        , photo: false
        , languages: false
        , tags: false
        , profile: false
    });
    const [personalNickname, setPersonalNickname] = useState({ show: false, name: "" });
    const [avatarType, setAvatarType] = useState(true);
    const [personalProfilePicture, setPersonalProfilePicture] = useState([]);
    const [language, setLanguage] = useState(null);
    const [listLanguages, setListLanguages] = useState([]);
    const [projectTag, setProjectTag] = useState(null);
    const [listTags, setListTags] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolInfo, setRolInfo] = useState([]);
    const [rol, setRol] = useState(1); //EMPRENDEDOR
    const [rolName, setRolName] = useState("EMPRENDEDOR"); //EMPRENDEDOR
    const [activarMentoria, setActivarMentoria] = useState(false); //EMPRENDEDOR
    const [password, setPassword] = useState({
        value: ""
        , type: "password"
        , valid: false
        , invalid: false
    });
    const [repassword, setRepassword] = useState({
        value: ""
        , type: "password"
        , valid: false
        , invalid: false
    });
    const [securePassword, setSecurePassword] = useState({
        caracteres: false
        , minusculas: false
        , mayusculas: false
        , numeros: false
    });

    useEffect(() => {
        getLanguages();
        getTags();
        getRoles();
        getAccountData();
    }, []);

    const getAccountData = async () => {
        const res = await API.get('/profile/getAccountData', { params: { token: getLocalStorage().token } });
        setGettingData(false);

        if (res.data.state) {
            
            if (res.data.data.account.ALIAS !== undefined && res.data.data.account.ALIAS !== null && res.data.data.account.ALIAS.length > 0) {
                setPersonalNickname({ show: true, name: res.data.data.account.ALIAS });
            }

            if (res.data.data.account.FOTO !== undefined && res.data.data.account.FOTO !== null && res.data.data.account.FOTO.length > 0) {
                setPersonalProfilePicture([res.data.data.account.FOTO]);
            }

            if (res.data.data.languages !== null) {
                const list = [];
                res.data.data.languages.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setLanguage(list);
            }

            if (res.data.data.tags !== null) {
                const list = [];
                res.data.data.tags.map((e, i) => {
                    list.push({ value: e.ID, label: e.NOMBRE });
                });
                setProjectTag(list);
            }

            if (res.data.data.account.PERFIL_ID !== null && res.data.data.account.PERFIL_ID > 0) {
                const element = document.getElementById(`photo_${res.data.data.account.PERFIL_ID}`);
                if (element !== null && element !== undefined) {
                    element.click();
                }
            }
        }
    }

    const getLanguages = async () => {
        const res = await API.get("/general/getLanguages");
        let list = [];
        if (res.data.state) {
            res.data.languages.map((e, i) => {
                list.push({ value: e.ID, label: e.IDIOMA, key: i });
            });
        }
        setListLanguages(list);
    }

    const getTags = async () => {
        const res = await API.get('/general/getTags');
        if (res.data.tags.length > 0) {
            let list = [];
            res.data.tags.map((e, i) => {
                list.push({ value: e.ID, label: e.TAG });
            });
            setListTags(list);
        }
    }

    const getRoles = async () => {
        const res = await API.get('/general/getRoles');
        if (res.data.state) {
            let listRoles = [];
            let listRolesInfo = [];
            res.data.roles.map((e, i) => {
                let profile = (i > 0) ? "profile_register" : "profile_register profile_register_active";
                let info = (i > 0) ? true : false;
                let img = "";

                switch (e.ROL) {
                    case 'EMPRENDEDOR':
                        img = emprendimiento;
                        break;
                    case 'MENTOR':
                        img = mentor;
                        break;
                    case 'INVERSIONISTA':
                        img = inversionista;
                        break;
                    default:
                        img = gerente;
                }

                listRoles.push(
                    <Col lg={3} md={6} sm={12} className="mb-2" id={`profile_${e.ID}`} key={i}>
                        <div className={profile + " nav-link text-center"} id={`photo_${e.ID}`} onClick={() => changeRoleProfile(e.ID, e.ROL)}>
                            <img src={img} alt="" className="img-fluid w-50 rounded-circle" /> <br />
                            <Label className="h4 animate__animated animate__fadeIn mt-4">{e.ROL}</Label>
                        </div>
                    </Col>
                );
                listRolesInfo.push(
                    <Col lg={12} className="text-center info" key={i} hidden={info} id={`info_${e.ID}`}>
                        <Label className="h5 text-center animate__animated animate__fadeIn">{arrow}<span dangerouslySetInnerHTML={{ __html: e.DESCRIPCION }} ></span></Label>
                    </Col>
                );
            });

            setRoles(listRoles);
            setRolInfo(listRolesInfo);
        }
    }

    const changeRoleProfile = (e, name) => {
        setRol(e);
        setRolName(name);
        const elements = document.getElementsByClassName("nav-link");
        if (elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove("profile_register_active");
            }
        }
        document.getElementById(`photo_${e}`).classList.add("profile_register_active");

        const info_elements = document.getElementsByClassName("info");
        if (info_elements.length > 0) {
            for (var i = 0; i < info_elements.length; i++) {
                info_elements[i].setAttribute("hidden", true);
            }
        }
        document.getElementById("info_" + e).hidden = false;
    }

    const changeData = (type, value) => {
        switch (type) {
            case "PASSWORD":
                setPassword({
                    value: value
                    , type: password.type
                    , valid: (validatePassword(value)) ? true : false
                    , invalid: (validatePassword(value)) ? false : true
                });
                setSecurePassword({
                    caracteres: (value.length < 5) ? false : true
                    , minusculas: (!value.match(/[a-z]/)) ? false : true
                    , mayusculas: (!value.match(/[[A-Z]/)) ? false : true
                    , numeros: (!value.match(/\d/)) ? false : true
                });
                break;
            case "REPASSWORD":
                setRepassword({
                    value: value
                    , type: repassword.type
                    , valid: (value === password.value && value.length >= 5) ? true : false
                    , invalid: (value === password.value && value.length >= 5) ? false : true
                });
                break;
        }
    }

    const showPassword = (type) => {
        if (type === "PASSWORD") {
            setPassword({
                value: password.value
                , type: (password.type === "password") ? "text" : "password"
                , valid: password.valid
                , invalid: password.invalid
            });
        } else {
            setRepassword({
                value: repassword.value
                , type: (repassword.type === "password") ? "text" : "password"
                , valid: repassword.valid
                , invalid: repassword.invalid
            });
        }
    }

    const updateNickname = async () => {
        setLoading({
            nickname: true
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });

        if(personalNickname.show === true && personalNickname.name.length < 1){
            swal_etm(`Debes ingresar un <b class="etm_text_blue">apodo</b> válido.`);
        }else{
            const res = await API.put("/profile/updateNickname", { nickname: personalNickname.name, token: getLocalStorage().token });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
            }else{
                swal_etm(res.data.msg);
            }
        }

        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });
    }
    
    const updatePassword = async () => {
        setLoading({
            nickname: false
            , password: true
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });

        if(!validatePassword(password.value)){
            swal_etm(`Contraseña poco segura. Debe ser al menos <b class="etm_text_blue">5 caracteres</b>, <b class="etm_text_blue">1 mayúscula</b>, <b class="etm_text_blue">1 minúscula</b> y <b class="etm_text_blue">1 número</b>.`);
        }else if (password.value !== repassword.value){
            swal_etm(`Tus <b class="etm_text_blue">Contraseñas</b> deben coincidir.`);
        }else{
            const res = await API.put("/profile/updatePassword", { password: password.value, token: getLocalStorage().token });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
            }else{
                swal_etm(res.data.msg);
            }
        }

        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });
    }
    
    const updatePhoto = async () => {
        setLoading({
            nickname: false
            , password: false
            , photo: true
            , languages: false
            , tags: false
            , profile: false
        });
        
        if(personalProfilePicture.length < 1){
            swal_etm(`Debes cargar una <b class="etm_text_blue">foto</b> o seleccionar un <b class="etm_text_blue">avatar</b>.`);
        }else{
            const res = await API.put("/profile/updatePhoto", { photo: personalProfilePicture[0], token: getLocalStorage().token });
            if (res.data.state) {
                const ls = getLocalStorage();
                ls.profile_picture = personalProfilePicture[0];
                setSessionUpdateUser(ls)
                swal_etm(res.data.msg, `success`);
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }else{
                swal_etm(res.data.msg);
            }
        }

        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });
    }

    const updateLanguage = async () => {
        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: true
            , tags: false
            , profile: false
        });
        
        if(language.length < 1){
            swal_etm(`Debes elegir al menos un <b class="etm_text_blue">idioma</b>.`);
        }else{
            const res = await API.put("/profile/updateLanguage", { language: language, token: getLocalStorage().token });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
            }else{
                swal_etm(res.data.msg);
            }
        }
        
        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });
    }
    
    const updateTags = async () => {
        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: true
            , profile: false
        });

        if(projectTag.length < 1){
            swal_etm(`Debes indicar al menos una <b class="etm_text_blue">etiqueta</b> para facilitar el match.`);
        }else{
            const res = await API.put("/profile/updateTags", { tags: projectTag, token: getLocalStorage().token });
            if (res.data.state) {
                swal_etm(res.data.msg, `success`);
            }else{
                swal_etm(res.data.msg);
            }
        }

        setLoading({
            nickname: false
            , password: false
            , photo: false
            , languages: false
            , tags: false
            , profile: false
        });
    }
    
    const updateProfile = async () => {
        swal_etm_confirm(`Esto modificará algunas de tus <span class="etm_text_blue">funcionalidades</span> y también como te <span class="etm_text_blue">visualizan</span> otros usuarios.<br><br>Deberás reiniciar tu sesión.`, `¿ Seguro de cambiar tu Perfil ?`)
            .then(async (result) => {
                setLoading({
                    nickname: false
                    , password: false
                    , photo: false
                    , languages: false
                    , tags: false
                    , profile: true
                });
        
                if(rol < 1){
                    swal_etm(`Debes seleccionar uno de los <b class="etm_text_blue">perfiles</b> disponibles.`);
                }else{
                    const res = await API.put("/profile/updateProfile", { profile: rol, token: getLocalStorage().token });
                    if (res.data.state) {
                        const ls = getLocalStorage();
                        ls.rol = res.data.roles;
                        setSessionUpdateUser(ls)
                        swal_etm(res.data.msg, `success`);
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    }else{
                        swal_etm(res.data.msg);
                    }
                }
        
                setLoading({
                    nickname: false
                    , password: false
                    , photo: false
                    , languages: false
                    , tags: false
                    , profile: false
                });
            }).catch((error) => { });
    }
    
    return (
        <Fragment>
            {
                (gettingData) ?
                    <LoadScreen text="Obteniendo configuración de la cuenta..." className="mt-5" />
                    : <div className="animate__animated animate__fadeIn">
                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Cambiar mi Apodo o Nickname</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Te gustaría usar un apodo o nickname en <b>EtM</b><i className="etm_text_blue">meet</i> ?.</Label>
                                                <br />
                                                <Label className="h4"><small className="text-muted">Esto solo será para las comunicaciones que te enviemos y no será visible para otros usuarios</small></Label>
                                                <br />
                                                <Label className="h5 mt-3 ml-5">
                                                    <Input name="radioNickname" type="radio" onClick={() => setPersonalNickname({ show: false, name: "" })} value="SIN NICKNAME" defaultChecked={!personalNickname.show} />{' '}No, solo por mi Nombre
                                                </Label>
                                                <br />
                                                <Label className="h5 ml-5">
                                                    <Input name="radioNickname" type="radio" onClick={() => setPersonalNickname({ show: true, name: "" })} value="CON NICKNAME" defaultChecked={personalNickname.show} />{' '}Si
                                                </Label>
                                                <Row hidden={!personalNickname.show} className="animate__animated animate__fadeIn">
                                                    <Col md={6}>
                                                        <Input className="form-control etm_register_input" type="text" onChange={(e) => setPersonalNickname({ show: personalNickname.show, name: e.target.value })} placeholder="Ingresa tu apodo o nickname" value={personalNickname.name} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col xs={12} className="text-md-right text-center">
                                                        <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Actualizar Nickname" text="Actualizando..." handle={ updateNickname } loading={loading.nickname}/>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Cambiar mi Contraseña</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Necesitas cambiar tu contraseña ?</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <br />
                                                <Label className="h4"><small className="text-muted">Modifica tu actual contraseña a continuación.</small></Label>
                                                <br />
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group pointer etm_text_blue" id="password_eye" onClick={() => showPassword("PASSWORD")}><i className="fas fa-eye"></i></span>
                                                        <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target="password_eye">Mostrar constraseña</UncontrolledTooltip>
                                                    </div>
                                                    <Input onChange={(e) => changeData("PASSWORD", e.target.value)} name="password" id="password" className="form-control etm_register_input" type={password.type} placeholder="Al menos 5 dígitos, una mayúscula y una minúscula" required valid={password.valid} invalid={password.invalid} />
                                                </div>
                                                <Label hidden={(password.value.length > 0) ? false : true} className="ml-3 mt-2 h6 animate__animated animate__fadeIn"><i className="fas fa-info-circle etm_text_blue mr-2"></i><span className={(securePassword.caracteres) ? "etm_text_blue" : "text-danger"}>5 caracteres</span> | <span className={(securePassword.mayusculas) ? "etm_text_blue" : "text-danger"}>mayúsculas</span> | <span className={(securePassword.minusculas) ? "etm_text_blue" : "text-danger"}>minúsculas</span> | <span className={(securePassword.numeros) ? "etm_text_blue" : "text-danger"}>números</span></Label>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6} md={12}>
                                            <FormGroup check>
                                                <br />
                                                <Label className="h4"><small className="text-muted">Confirma tu contraseña</small></Label>
                                                <br />
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text etm_input_group pointer etm_text_blue" id="repassword_eye" onClick={() => showPassword("REPASSWORD")}><i className="fas fa-eye"></i></span>
                                                        <UncontrolledTooltip innerClassName="bg-info" delay={0} placement="bottom" target="repassword_eye">Mostrar constraseña</UncontrolledTooltip>
                                                    </div>
                                                    <Input onChange={(e) => changeData("REPASSWORD", e.target.value)} name="repassword" className="form-control etm_register_input" type={repassword.type} placeholder="Reingresa la contraseña" required valid={repassword.valid} invalid={repassword.invalid} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Actualizar Contraseña" text="Actualizando..." handle={ updatePassword } loading={loading.password}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Cambiar mi Foto de Perfil</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}Sube una foto de tí o elige uno de nuestros avatares.</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <div className="text-center">
                                                    <Label className="h5"><small className="mr-2">USAR AVATAR</small> | <small className="ml-2">CARGAR FOTO</small></Label>
                                                    <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="" bsSize="lg" defaultChecked={avatarType} onChange={() => setAvatarType(!avatarType)} />
                                                </div>
                                                <div className="animate__animated animate__fadeIn text-center" hidden={avatarType}>
                                                    <AvatarDefault setAvar={setPersonalProfilePicture} />
                                                </div>
                                                <div className="animate__animated animate__fadeIn text-center" hidden={!avatarType}>
                                                    <UploadImageFile
                                                        buttonText={"Click aquí para subir una foto"}
                                                        pathS3={pathS3("PROFILE")}
                                                        singleImage={true}
                                                        maxFileNumber={1}
                                                        handle={setPersonalProfilePicture}
                                                        defaultImages={(personalProfilePicture.length > 0) ? personalProfilePicture[0] : null}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn handle={ updatePhoto } title="Actualizar Foto de Perfil" text="Actualizando..." color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md"  />
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Elegir mis Idiomas</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}¿ Hablas otro idioma además de Español ?</Label><Label className="h4 ml-2"><small>( Puedes elegir más de 1 )</small></Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Select
                                                    placeholder={"Selecciona idiomas"}
                                                    value={language}
                                                    onChange={setLanguage}
                                                    options={listLanguages}
                                                    className="etm_input_select"
                                                    styles={selectCustomStyle()}
                                                    noOptionsMessage={() => 'No hay mas opciones'}
                                                    maxMenuHeight={130}
                                                    isMulti
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Actualizar Idiomas" text="Actualizando..." handle={updateLanguage}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>

                        {
                            (validateRol(['GERENTE/EJECUTIVO', 'INVERSIONISTA', 'MENTOR', 'ADMINISTRADOR'])) ?
                                <Card className="mt-3">
                                    <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                        <Container>
                                            <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                                <p>Cambiar mis Etiquetas</p>
                                            </Label>
                                        </Container>
                                        <hr />
                                    </CardTitle>
                                    <CardBody>
                                        <Container>
                                            <Row>
                                                <Col xs={12}>
                                                    <FormGroup check>
                                                        <Label className="h5">{arrow}Para mejorar el match entre los expertos y los emprendimientos, puedes agregar <span className="etm_text_blue">etiquetas</span> a tus emprendimientos.<br />Esto funciona similar a los hashtag de las redes sociales y facilitará generar las reuniones.</Label>
                                                        <br />
                                                        <Label className="h4"><small className="text-muted">Elige al menos 1 etiqueta ya existente o crea nuevas si necesitas. Solo escribe la etiqueta y listo.</small></Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12}>
                                                    <FormGroup check>
                                                        <Creatable
                                                            formatCreateLabel={e => `Crear etiqueta ${e}`}
                                                            placeholder={"Elige tus etiquetas"}
                                                            value={projectTag}
                                                            onChange={(e) => (e !== null) ? ((e.length <= 10) ? setProjectTag(e) : null) : setProjectTag(e)}
                                                            options={listTags}
                                                            className="etm_input_select"
                                                            styles={selectCustomStyle()}
                                                            noOptionsMessage={() => 'No hay mas opciones'}
                                                            maxMenuHeight={125}
                                                            isMulti
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} className="text-md-right text-center">
                                                    <br />
                                                    <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Actualizar Etiquetas" text="Actualizando..." handle={updateTags}/>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </CardBody>
                                </Card>
                            :   ""
                        }

                        <Card className="mt-3">
                            <CardTitle className="mt-3 ml-3 mb-0 text-center">
                                <Container>
                                    <Label className="h4 etm_text_blue mt-0 mb-0 pt-0 pb-0">
                                        <p>Cambiar mi Perfil</p>
                                    </Label>
                                </Container>
                                <hr />
                            </CardTitle>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Label className="h5">{arrow}Puedes cambiar tu perfil eligiendo uno a continuación</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <FormGroup check>
                                                <Row className="m-3 text-center">
                                                    {roles}
                                                </Row>
                                                <Row className="m-4">
                                                    {rolInfo}
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className="text-md-right text-center">
                                            <br />
                                            <LoadBtn color="primary" block={false} icon="check" className="pl-5 pr-5 btn-md" title="Actualizar Perfil" text="Actualizando..." handle={updateProfile} loading={loading.profile}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </CardBody>
                        </Card>
                    </div>
            }
        </Fragment>
    );
}

export default (AccountData);

{/* 
    Activar Mentorias / Comerciales
    Definir vista buscador por defecto
    Eliminar cuenta 
*/}
