import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";

const Navbar = ({ location }) => {

    return (<React.Fragment>
        <div className="topnav" hidden={true}>
            <div className="container-fluid">
                <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                    <ul className="navbar-nav mr-auto">
                        <li className={`nav-item ${location.pathname == '/Home' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/home">
                                <i className="fas fa-home mr-2"></i>{'Home'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/Emprendimientos' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/startups">
                                <i className="fas fa-suitcase mr-2"></i>{'Emprendimientos'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/Donar Horas' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/giveHours">
                                <i className="far fa-handshake mr-2"></i>{'Donar Horas'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/Agenda' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/calendar">
                                <i className="far fa-calendar-alt mr-2"></i>{'Agenda'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/Solicitudes' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/inbox">
                                <i className="fas fa-inbox mr-2"></i>{'Solicitudes'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/Bitácora' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/history">
                                <i className="fas fa-book mr-2"></i>{'Bitácora'}
                            </Link>
                        </li>
                        <li className={`nav-item ${location.pathname == '/zoom' ? 'active' : null}`}>
                            <Link className="nav-link dropdown-toggle arrow-none" to="/zoom">
                                <i className="bx bx-video-recording mr-2"></i>{'Zoom'}
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(Navbar)));

