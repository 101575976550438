import React, { Fragment, useState } from 'react';
import { Row, Col } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Step1 from "../../assets/images/etmmeet/landing/step1.png";
import Step2 from "../../assets/images/etmmeet/landing/step2.png";
import Step3 from "../../assets/images/etmmeet/landing/step3.png";
import Step4 from "../../assets/images/etmmeet/landing/step4.png";
import Step5 from "../../assets/images/etmmeet/landing/step5.png";
import Step6 from "../../assets/images/etmmeet/landing/step6.png";
import { Link } from 'react-router-dom';

const HowDoIt = (props) => {
    const[menu, setMenu] = useState(null);

    return (
        <Fragment>
            <Row className="pt-5 howdoit howdoit_full">
                <Col xs={12} className="text-center pt-5 animate__animated animate__fadeInRight">
                    <p className="h1 landing_profile_header_title pt-md-0 pt-5">¿Cómo lo hago?</p>
                </Col>
                
                <Row className="howdoit_full">
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step1 } alt="pic1"/>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step2 } alt="pic1"/>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step3 } alt="pic1"/>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step4 } alt="pic1"/>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step5 } alt="pic1"/>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step6 } alt="pic1"/>
                    </Col>

                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Regístrate como usuario y define tu tipo de perfil"
                                :   "Regístrate como usuario"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Completa tu perfil al 100% y define tus intereses"
                                :   "Completa tus datos y el de tu(s) emprendimientos al 100%"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Dona reuniones de 20 minutos cuanto tu quieras"
                                :   "Busca a los expertos/as que quieras y postula para reunirte con ellos/as"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Alan seleccionará al emprendedor ideal para ti"
                                :   "Alan revisará a todos los postulantes y seleccionará al ideal"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Ingresa a la reunión. Serán solo 20 minutos"
                                :   "Si eres seleccionado, prepara tu Pitch e ingresa a la reunión y ¡rómpela!"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Evalúa al emprendedor y la plataforma"
                                :   "¡Evalúa al experto/a y la plataforma para mejorar el match!"
                            }
                        </p>
                    </Col>
                </Row>

                <Row className="howdoit_mobile">
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step1 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Regístrate"
                                :   "Regístrate como usuario"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step2 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Completa tu perfil al 100% y define tus intereses"
                                :   "Completa tus datos y el de tu(s) emprendimientos al 100%"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step3 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "¡Dona reuniones de 20 minutos los días y horas que tu quieras!"
                                :   "Busca a los expertos/as que quieras y postula para reunirte con ellos/as"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step4 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Alan, nuestro robot desarrollado por EY seleccionará al emprendedor que más % tenga de hacer Match contigo"
                                :   "Alan revisará a todos los postulantes y seleccionará al ideal"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step5 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "Ingresa a la reunión desde tu calendario. Serán solo 20 minutos que se cortarán automáticamente"
                                :   "Si eres seleccionado, prepara tu Pitch e ingresa a la reunión y ¡rómpela!"
                            }
                        </p>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={12} className="text-center animate__animated animate__fadeInRight my-auto">
                        <img className="landing_profile_header_img img-fluid" src={ Step6 } alt="pic1"/>
                        <p className="h4 landing_profile_header_subtitle">
                            {
                                (props.type === "experto") ?
                                    "¡Evalúa al emprendedor!"
                                :   "¡Evalúa al experto/a y la plataforma para mejorar el match!"
                            }
                        </p>
                    </Col>
                </Row>

                <Col xs={12} className="mt-5 text-center animate__animated animate__fadeIn">
                    <p className="h3">
                        {
                            (props.type === "experto") ?
                                "¿Qué esperas?"
                            :   "¿Qué esperas?"
                        }
                    </p>
                    <Link to="/login" target="_blank">
                        <button className="btn btn-primary etm_btn landing_profile_header_btn pt-lg-2 pt-1 pb-lg-2 pb-1 pl-5 pr-5 mt-2 mb-2">
                            {
                                (props.type === "experto") ?
                                    "¿Qué esperas?"
                                :   "Agenda aquí"
                            }
                        </button>
                    </Link>
                </Col>
            </Row>
        </Fragment>
    );
}

export default withNamespaces()(HowDoIt);