import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Button, UncontrolledTooltip, Badge, Label, Card, CardBody } from "reactstrap";
import "../../../assets/scss/etm.scss";
import { API } from '../../../helpers/axios';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalRequest from "../Modals/ModalRequest";
import ModalReagendar from "../Modals/ModalReagendar";
import LoadScreen from "../../../components/Utils/LoadScreen";

import { getLocalStorage, capitalize, swal_etm, swal_etm_confirm } from "../../../helpers/etmmeetUtils";

import defaultEmprendimientoFoto from "../../../assets/images/etmmeet/general/logo_negro.png";

import emprendimiento from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../../assets/images/conecTime/design/Perfil-gerente.png";

const Index = (props) => {
    const ls = getLocalStorage();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [datosModal, setDatosModal] = useState({});
    const [datosReagendar, setDatosReagendar] = useState({});
    const [modalRequestState, setModalRequestState] = useState(false);
    const [modalReagendar, setModalReagendar] = useState(false);
    const [title, setTitle] = useState({ header: "", label: "" });

    let cronometro = <h5 className="etm_ornate_text_3" hidden={true}><i className="fas fa-clock mr-2 mt-3"></i>02:10</h5>; //    YILO 

    useEffect(() => {
        getTitle();
        getUsers();
    }, []);

    const getTitle = async () => {
        if (props.type === "solicitudes") {
            setTitle({
                header: "POSIBLE REUNIÓN CON"
                , label: <Label className="h3 etm_text_blue mb-4" > <i className="fas fa-inbox mr-2"></i>Solicitudes de reunión</Label>
            });
        } else if (props.type === "confirmadas") {
            setTitle({
                header: "REUNIÓN CON"
                , label: <Label className="h3 etm_text_blue mb-4"><i className="fas fa-calendar-alt mr-2"></i>Reuniones confirmadas</Label>
            });
        } else {
            setTitle({
                header: "TE REUNISTE CON"
                , label: <Label className="h3 etm_text_blue mb-4"><i className="fas fa-book mr-2"></i>Historial de reuniones</Label>
            });
        }
    }

    const openModalAceptar = async (token, meet, request, is_private, reagendada) => {
        setDatosModal({
            token
            , meet
            , request
            , is_private
            , reagendada
        })
        setModalRequestState(true)
    }

    const rendererFull = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <span>
                {(days > 0) ? `${zeroPad(days)} Días, ` : ``} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const getUsers = async () => {
        setLoading(true);
        const res = await API.get("/meeting/getRequestEntrepreneurByToken", { params: { token: ls.token, type: props.type } });
        let list = [];

        if (res.data.state) {
            res.data.request.map((e, i) => {
                list.push({
                    id: i + 1
                    , nexMeet:
                        <Fragment>
                            {
                                (i < 1) ?
                                    <div className="text-center mt-5">
                                        <label className="text-danger">Siguiente<br />reunión <br /><i className="fas fa-angle-double-right fa-beat"></i></label>
                                        {cronometro}
                                    </div>
                                    :
                                    <div className="text-center mt-5">
                                        <Row>
                                            <Col className="mt-4">
                                                {cronometro}
                                            </Col>
                                        </Row>
                                    </div>
                            }
                        </Fragment>
                    , nombre:
                        <Fragment>
                            <div className="text-center">
                                <img src={e.usu_foto_perfil} alt="" className="img-thumbnail avatar-lg rounded-circle fix_img shadow-lg" />
                            </div>
                            <Label className='h5 mb-0 mt-2'>
                                {e.usu_nombre_completo}
                                <br />
                                <small className='ml-3'>
                                {
                                        (e.usu_cargo !== undefined && e.usu_cargo !== null && e.usu_cargo !== '') ?
                                            (e.usu_cargo.toUpperCase() === 'EMPRENDEDOR' || e.usu_cargo.toUpperCase() === 'EMPRENDEDORA') ?
                                                e.usu_cargo.toUpperCase()
                                                :
                                                (e.usu_empresa !== undefined && e.usu_empresa !== null && e.usu_empresa !== '') ?
                                                    e.usu_cargo.toUpperCase().concat(' EN ', e.usu_empresa.toUpperCase())
                                                    : e.usu_cargo.toUpperCase()
                                            : ""
                                    }
                                </small>
                            </Label>
                        </Fragment>
                    , tipoReunion:
                        <div className='my-auto'>
                            <img src={(e.meet_type === 'COMERCIAL') ? inversionista : mentor} alt="" className="avatar-sm rounded-circle fix_img ml-2" key={i} />
                            <Label className='h5 m-0 ml-2'>
                                {(e.meet_type === 'COMERCIAL') ?
                                    <div>
                                        Reunión Comercial
                                        {
                                            e.desafio > 0 ?
                                                <Fragment>
                                                    <br />
                                                    <Label className='h5'>
                                                        <Badge color="danger" className="pr-5 pl-5" >
                                                            DESAFÍO
                                                        </Badge>
                                                    </Label>
                                                </Fragment>
                                                : e.meeting_private > 0 ?
                                                    <Fragment>
                                                        <br />
                                                        <Label className='h5'>
                                                            <Badge color="success" className="pr-5 pl-5" >
                                                                PRIVADA
                                                            </Badge>
                                                        </Label>
                                                    </Fragment>
                                                    : ""
                                        }
                                    </div>
                                    : <div>
                                        Reunión de Mentoría
                                        {
                                            e.desafio > 0 ?
                                                <Fragment>
                                                    <br />
                                                    <Label className='h5'>
                                                        <Badge color="danger" className="pr-5 pl-5" >
                                                            DESAFÍO
                                                        </Badge>
                                                    </Label>
                                                </Fragment>
                                                : e.meeting_private > 0 ?
                                                    <Fragment>
                                                        <br />
                                                        <Label className='h5'>
                                                            <Badge color="success" className="pr-5 pl-5" >
                                                                PRIVADA
                                                            </Badge>
                                                        </Label>
                                                    </Fragment>
                                                    : ""
                                        }
                                    </div>
                                }
                            </Label>
                        </div>
                    , empresa:
                        <Fragment>
                            {
                                (e.usu_foto_empresa === undefined && e.usu_foto_empresa === null) ?
                                    <img src={defaultEmprendimientoFoto} alt={e.usu_empresa || ""} className="img-fluid w-50 etm_img_border etm_sombra" key={i} />
                                    :
                                    <img src={e.usu_foto_empresa} alt={e.usu_empresa || ""} className="img-fluid w-50  etm_img_border etm_sombra" key={i} />
                            }
                            <br />
                            <Label className='h4 mt-2'> {e.usu_empresa || ""}</Label>
                        </Fragment>
                    , fecha:
                        <Fragment>
                            {
                                (props.vista === 'meet') ?
                                    <Fragment>
                                        <Label className='h4'>
                                            <i className="fas fa-calendar-alt etm_ornate_text_3 mr-2"></i>{e.meet_fecha}
                                        </Label>
                                        <br />
                                        <Label className='h3'>
                                            <i className="fas fa-clock etm_ornate_text_3 mr-2"></i>{e.meet_hora}
                                        </Label>
                                        <br />
                                        {/* <h5 className="text-muted"><b>Fecha:</b><br /><span className="etm_ornate_text_3">{`${e.day} ${e.time}`}</span></h5> */}
                                        <Label className="h4 text-muted mt-4">
                                            <b>Comenzará en:</b>
                                            <br />
                                            <span className="etm_ornate_text_3">
                                                <Countdown
                                                    date={moment(`${e.meet_fecha.split("-").reverse().join("-")} ${e.meet_hora}`)}
                                                    renderer={rendererFull}
                                                />
                                            </span>
                                        </Label>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Label className='h4'>
                                            <i className="fas fa-calendar-alt etm_ornate_text_3 mr-2"></i>{e.meet_fecha}
                                        </Label>
                                        <br />
                                        <Label className='h3'>
                                            <i className="fas fa-clock etm_ornate_text_3 mr-2"></i>{e.meet_hora}
                                        </Label>
                                        {
                                            (e.reagendada > 0) ?
                                                <Fragment>
                                                    <br />
                                                    <br />
                                                    <Label className='h4'>
                                                        <Badge color="danger" className="pr-5 pl-5" >
                                                            Reagendada
                                                        </Badge>
                                                    </Label>
                                                </Fragment>
                                                : ""
                                        }
                                    </Fragment>

                            }
                        </Fragment>
                    , opciones:
                        <Fragment>
                            <div className="d-flex my-auto">
                                <Row>
                                    <Col md={12} className="mb-1">
                                        <Button
                                            block={true}
                                            color="primary"
                                            onClick={() => openModalAceptar(e.usu_token, e.meet_id, e.meet_request_id, e.meeting_private, e.reagendada)}
                                            className="p-2"
                                            id={`btn_1_${i}`}
                                        >
                                            <i className="fas fa-book-reader fa-lg mr-2"></i> VER DETALLE
                                        </Button>
                                    </Col>
                                    {
                                        (props.type === 'solicitudes') ?
                                            botonesSolicitudes(i, e)
                                            : ""
                                    }
                                    {
                                        (props.type === 'confirmadas') ?
                                            botonesConfirmadas(i, e)
                                            : ""
                                    }
                                    {
                                        (props.type === 'historial') ?
                                            botonesHistorial(i, e)
                                            : ""
                                    }
                                </Row>
                            </div>
                        </Fragment>

                });
            });
        }
        setUsers(list);
        setLoading(false);
    }

    const botonesSolicitudes = (i, e) => {
        return <Fragment>
            <Col md={12} className="mb-1">
                <Button
                    block={true}
                    color="success"
                    onClick={() => aceptarReunion(e.meet_id, e.reagendada)}
                    className="p-2"
                    id={`btn_1_${i}`}
                >
                    <i className="fas fa-check-circle fa-lg mr-2"></i> ACEPTAR REUNIÓN
                </Button>
            </Col>
            {
                (e.meeting_private === 1) ?
                    <Col md={12} className="mb-1">
                        <Button
                            block={true}
                            color="warning"
                            onClick={() => reagendarReunion(e.meet_id, e.meet_request_id, false, false, null)}
                            className="p-2"
                            id={`btn_3_${i}`}
                        >
                            <i className="fas fa-calendar-alt fa-lg mr-2"></i> REAGENDAR
                        </Button>
                    </Col>
                    : ""
            }
            <Col md={12} className="mb-1">
                <Button
                    block={true}
                    color="danger"
                    onClick={() => rechazarReunion(e.meet_id)}
                    className="p-2"
                    id={`btn_2_${i}`}
                >
                    <i className="fas fa-times-circle fa-lg mr-2"></i> RECHAZAR REUNIÓN
                </Button>
            </Col>
        </Fragment>
    }

    const botonesConfirmadas = (i, e) => {
        return <Fragment>
        <Col md={12} className="mb-1">
            <Button
                block={true}
                color="danger"
                onClick={() => reagendarReunion(e.meet_id, e.meet_request_id, true, false, null)}
                className="p-2"
                id={`btn_1_${i}`}
            >
                <i className="fas fa-times fa-lg mr-2"></i> CANCELAR REUNIÓN
            </Button>
        </Col>
    </Fragment>
    }

    const botonesHistorial = (i, e) => {
        return <Fragment>
            {/* <Col md={12} className="mb-1">
                <Button
                    block={true}
                    color="success"
                    onClick={() => reagendarReunion(e.meet_id, e.meet_request_id, false, true, e.usu_token)}
                    className="p-2"
                    id={`btn_1_${i}`}
                >
                    <i className="fas fa-calendar-alt fa-lg mr-2"></i> AGENDAR REUNIÓN
                </Button>
            </Col>
            <Col md={12} className="mb-1">
                <Button
                    block={true}
                    color="secondary"
                    onClick={() => reenviarDatos(e.meet_id, e.meet_request_id, e.meeting_private)}
                    className="p-2"
                    id={`btn_2_${i}`}
                >
                    <i className="fas fa-paper-plane fa-lg mr-2"></i> REENVIAR DATOS
                </Button>
            </Col> */}
        </Fragment>
    }

    const columns = [{
        dataField: 'id'
        , text: '#'
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'nexMeet'
        , text: <i className="fas fa-calendar-alt mr-2"></i>
        , width: "1%"
        , hidden: (props.vista === 'meet') ? false : true
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'nombre'
        , text: title.header
        , width: "16%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'tipoReunion'
        , text: 'TIPO REUNIÓN'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'empresa'
        , text: 'EMPRESA / ORGANIZACIÓN'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'fecha'
        , text: 'FECHA Y HORA REUNIÓN'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'opciones'
        , text: 'OPCIONES'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }, style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }, headerClasses: 'etm_datatable_header'
    }];

    const aceptarReunion = async (meet_id, reagendada) => {
        const res = await API.put("/meeting/entrepreneur/confirmMeeting", { meet_id, reagendada });
        if (res.data.state) {
            swal_etm(res.data.msg, "success");
        } else {
            swal_etm(res.data.msg);
        }
        setModalRequestState(false);
        getUsers();
    }

    const rechazarReunion = async (meet_id) => {
        swal_etm_confirm(`¿ Estás seguro que deseas <span class="etm_text_blue">RECHAZAR</span> esta solicitud ?<br>Una vez la rechaces no podrás volver a gestionarla`)
            .then(async (result) => {
                const res = await API.put("/meeting/entrepreneur/refuseMeeting", { meet_id });
                if (res.data.state) {
                    swal_etm(res.data.msg, "success");
                } else {
                    swal_etm(res.data.msg);
                }
                setModalRequestState(false);
                getUsers();
            }).catch((error) => { });
    }

    const reagendarReunion = async (meet, request, cancel, new_meeting, usu_token) => {
        setDatosReagendar({ meet, request, cancel, new_meeting, usu_token });
        setModalReagendar(true);
    }

    const reenviarDatos = async (meet_id, meet_request_id, meeting_private) => {
        console.log('reenviar datos')
        console.log(meet_id)
        console.log(meet_request_id)
        console.log(meeting_private)
    }

    return (
        <Fragment>
            {
                (loading) ?
                    <LoadScreen text="Cargando solicitudes..." />
                    :
                    <ToolkitProvider
                        keyField="id"
                        data={users}
                        columns={columns}

                    >
                        {
                            props => (
                                <div>
                                    {title.label}
                                    {/* <SearchBar placeholder="Buscar..." className="etm_input_text" {...props.searchProps} /> */}
                                    <BootstrapTable
                                        keyField="id"
                                        pagination={paginationFactory()}
                                        {...props.baseProps}
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
            }

            <ModalRequest
                modal={modalRequestState}
                setModal={setModalRequestState}
                datos={datosModal}
                tableType="EMPRENDEDOR"
                aceptarReunion={aceptarReunion}
                rechazarReunion={rechazarReunion}
                reagendarReunion={reagendarReunion}
            />

            <ModalReagendar
                modal={modalReagendar}
                setModal={setModalReagendar}
                datos={datosReagendar}
                type="EMPRENDEDOR"
                getUsers={getUsers}
            />
        </Fragment>
    );
}

export default withNamespaces()(Index);