import React, { useState, useEffect, Fragment } from "react";
import ImageUploader from "react-images-upload";
import { Label, Card, Button } from "reactstrap";
import { randomFileName, swal_etm, iconFileByExtension } from "../../helpers/etmmeetUtils";
import { FormAPI } from '../../helpers/axios';
import Logo from "../../assets/images/conecTime/user_profile.png"

var mime = require("mime-types");

const UploadImageFile = props => {

    const [withLabel, setWithLabel] = useState((props.withLabel === undefined) ? true : props.withLabel);
    const [label, setLabel] = useState((props.label === undefined) ? "(Tamaño máximo 10mb)" : props.label);
    const [maxFileSize, setMaxFileSize] = useState((props.setMaxFileSize === undefined) ? 10242880 : props.setMaxFileSize);
    const [fileSizeError, setFileSizeError] = useState((props.fileSizeError === undefined) ? "Es demasiado grande" : props.fileSizeError);

    const [isMultiple, setIsMultiple] = useState((props.multiple === undefined) ? false : props.multiple);

    const [extensionAccept, setExtensionAccepn] = useState((props.extensionAccept === undefined) ? "*" : props.extensionAccept);
    const [fileTypeError, setFileTypeError] = useState((props.fileTypeError === undefined) ? "Formato incorrecto" : props.fileTypeError);
    const [maxFileNumber, setMaxFileNumber] = useState((props.maxFileNumber === undefined) ? 10 : props.maxFileNumber);
    const [pathS3, setPathS3] = useState((props.pathS3 === undefined) ? "" : props.pathS3);

    const [withIcon, setWithIcon] = useState((props.withIcon === undefined) ? true : props.withIcon)
    const [icon, setIcon] = useState((props.icon === undefined) ? "fas fa-upload" : props.icon);
    const [buttonClassName, setButtonClassName] = useState((props.buttonClassName === undefined) ? "btn-primary" : props.buttonClassName);

    const [buttonText, setButtonText] = useState((props.buttonText === undefined) ? "Seleccione imágenes" : props.buttonText);
    const [textFileUpload, setTextFileUpload] = useState(<span></span>);
    const [listArchivos, setListArchivos] = useState((props.defaulFile === null) ? [] : props.defaulFile);

    let showIcon = <i className={`${icon} fa-lg fa-4x mb-3 pinter`} ></i>;
    // ${icon}

    useEffect(() => {
        if (listArchivos !== undefined && listArchivos.length > 0) {
            nombresArchivos()
            setWithIcon(false);
            setWithLabel(false);
        } else {
            setWithIcon(true);
            setWithLabel(true);
            setTextFileUpload(<span></span>)
        }
        props.setFile(listArchivos);
    }, [listArchivos]);

    const onDrop = ({ target: { files } }) => {
        if (pathS3.length < 3) {
            swal_etm("No se pudo cargar la imagen. Carpeta no definida");
            return
        } else {
            if (files.length <= maxFileNumber) {
                if (files.length > 0) {
                    uploadFile(files);
                } else {
                    console.log('sin archivos');
                    // props.setFile([]);
                    // setListArchivos([]);
                }
            }
        }
    };

    const removeItem = (item) => {
        let list = listArchivos
        list = list.filter(function (e) {
            return e.url !== item;
        });
        setListArchivos(list);
    }

    const nombresArchivos = () => {
        let html = [];
        if (listArchivos.length > 0) {
            listArchivos.map((e, i) => {
                html.push(
                    <Fragment key={i}>
                        <i className={`${iconFileByExtension(e.extension)} mr-2 fa-lg`}></i>
                        <span>{e.name}</span>
                        <i className="fas fa-times ml-2 pointer text-danger" onClick={() => removeItem(e.url)}></i>
                        <br />
                    </Fragment>
                )
            })
        }
        setTextFileUpload(html);
    }

    const uploadFile = async (files) => {
        let formatos = extensionAccept.replace(/\./g, '').split(',');
        let list = [];

        if (!isMultiple && listArchivos.length >= 1) {
            swal_etm(`Solo se puede subir un archivo`);
            return
        }

        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= maxFileSize) {
                if (formatos.includes('*') || formatos.includes(mime.extension(files[i].type))) {
                    let formData = new FormData();
                    formData.append(`dir`, pathS3);
                    formData.append(`image`, files[i], `${randomFileName(pathS3)}.${mime.extension(files[i].type)}`);
                    const res = await FormAPI.put('/general/UploadiFile', formData);
                    if (res.data.state) {
                        list.push({
                            name: files[i].name,
                            extension: mime.extension(files[i].type),
                            url: res.data.url[0]
                        })
                    }
                } else {
                    swal_etm(`Archivo ${files[i].name}: ${fileSizeError}`);
                    return
                }
            } else {
                swal_etm(`Archivo ${files[i].name}: ${fileTypeError}`);
                return
            }
        }
        list = list.concat(listArchivos);
        setListArchivos(list);
    };

    return (
        <Card className="border-right border-bottom text-center mt-2" style={{ borderRadius: "10px", height: "150px" }}>
            <Label for="" className="pinter">
                {
                    (withIcon) ?
                        showIcon
                        :
                        ""
                }
                <div id="contenido">
                    {textFileUpload}
                </div>
                <br />
                {
                    (withLabel) ?
                        <Fragment>
                            <small className="mt-2 mb-2">{label}</small>
                            <br />
                        </Fragment>
                        :
                        ""
                }
                <div>
                    <Label htmlFor="contained-button-file" className="m-0 w-100">
                        <input
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            multiple={isMultiple}
                            type="file"
                            accept={extensionAccept}
                            onChange={onDrop}
                        />
                        {
                            (!isMultiple) ?
                                <span className={`btn etm_btn pl-2 pr-2 ${buttonClassName}`}>Click aquí para subir un archivo</span>
                                :
                                <span className={`btn etm_btn pl-2 pr-2 ${buttonClassName}`}>Click aquí para subir archivos</span>

                        }
                    </Label>
                </div>
            </Label>
        </Card>
    );
};

export default UploadImageFile;