import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { validateRol } from "../../helpers/etmmeetUtils";
import TableExperto from "../../components/Requests/tables/tableExperto";
import TableEmprendedor from "../../components/Requests/tables/tableEmprendedor";

const Index = () => {

    return (
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Solicitudes de Reunion'} />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="12" className="table-responsive">
                                    {
                                        (validateRol(['EMPRENDEDOR'])) ?
                                            <TableEmprendedor type='confirmadas'  vista='myRequest'/>
                                            :
                                            <TableExperto type='confirmadas' vista='myRequest' />
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </Fragment>
    );
}

export default withNamespaces()(Index);