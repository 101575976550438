import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Media, CardTitle } from "reactstrap";
import "../../assets/scss/etm.scss";
import { withRouter } from "react-router-dom";
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Gerente from "../../assets/images/conecTime/design/Perfil-gerente.png";
import Mentor from "../../assets/images/conecTime/design/Perfil-mentor.png";
import Inversionista from "../../assets/images/conecTime/design/Perfil-inversionista.png";
import LoadScreen from '../../components/Utils/LoadScreen';

const Dashboard_general = ( props ) => {
    const[loading, setLoading]                          = useState(true);
    const[doughnut, setDoughnut]                        = useState([]);
    const[bar, setBar]                                  = useState([]);
    const[pageWidth, setPageWidth]                      = useState(window.innerWidth);
    const[total, setTotal]                              = useState([]);
    // const[pie, setPie]                                  = useState([]);
    // const[line, setLine]                                = useState({ "emprendedor": {}, "tdd": {} });
    // const[barMultiple, setBarMultiple]                  = useState({"emprendedor": {}, "mentor": {}, "inversionista": {}, "gerente": {}});
    const[usuariosTotales, setUsuariosTotales]          = useState('');
    const[usuariosTotalesAnt, setUsuariosTotalesAnt]    = useState('');
    // const[donadas, setDonadas]                          = useState('');
    const[kpiHeight, setKpiHeight]                      = useState({
            "doughnut": 150
        ,   "line":     80
        ,   "bar":      80
        ,   "pie":      150
    });

    useEffect(() => {
        window.addEventListener("resize", updateWidth);
        if(pageWidth < 600){
            setKpiHeight({
                    "doughnut": 300
                ,   "line":     300
                ,   "bar":      300
                ,   "pie":      300
            });
        }else if(pageWidth < 1024){
            setKpiHeight({
                "doughnut": 200
            ,   "line":     150
            ,   "bar":      150
            ,   "pie":      200
        });
        }
        return () => {
            window.removeEventListener("resize", updateWidth);
        }
    }, [pageWidth]);

    const updateWidth = () => {
        setPageWidth(window.innerWidth);
    };

    useEffect(() => { 
        setLoading(true);
        getDataGeneral();
    }, [props.dataGeneral]);
    
    const getDataGeneral = async () => {
        if (props.dataGeneral.EMPRENDEDORES_TOTAL !== null & props.dataGeneral.EMPRENDEDORES_TOTAL !== undefined){
            setDoughnut({
                    "emprendedor":      props.dataGeneral.EMPRENDEDORES_TOTAL
                ,   "mentor":           props.dataGeneral.MENTORES_TOTAL
                ,   "inversionista":    props.dataGeneral.INVERSIONISTAS_TOTAL
                ,   "gerente":          props.dataGeneral.GERENTES_TOTAL
            });
            setUsuariosTotales(props.dataGeneral.USUARIOS_TOTAL);
            setUsuariosTotalesAnt(props.dataGeneral.USUARIOS_SEMANA_ANTERIOR);
            setBar({
                    'arica':            props.dataGeneral.REGION_ARICA
                ,   'tarapaca':         props.dataGeneral.REGION_TARAPACA
                ,   'antofagasta':      props.dataGeneral.REGION_ANTOFAGASTA
                ,   'atacama':          props.dataGeneral.REGION_ATACAMA
                ,   'coquimbo':         props.dataGeneral.REGION_COQUIMBO
                ,   'valparaiso':       props.dataGeneral.REGION_VALPARAISO
                ,   'metropolitana':    props.dataGeneral.REGION_METROPOLITANA
                ,   'ohiggins':         props.dataGeneral.REGION_OHIGGINS
                ,   'maule':            props.dataGeneral.REGION_MAULE
                ,   'nuble':            props.dataGeneral.REGION_NUBLE
                ,   'biobio':           props.dataGeneral.REGION_BIOBIO
                ,   'araucania':        props.dataGeneral.REGION_ARAUCANIA
                ,   'rios':             props.dataGeneral.REGION_RIOS
                ,   'lagos':            props.dataGeneral.REGION_LAGOS
                ,   'aisen':            props.dataGeneral.REGION_AISEN
                ,   'magallanes':       props.dataGeneral.REGION_MAGALLANES
            });
            setTotal({
                    "donadas":                  props.dataGeneral.DONADAS
                ,   "realizadas":               props.dataGeneral.REALIZADAS
                ,   "mentor_donada":            props.dataGeneral.DONADAS_MENTOR
                ,   "inversionista_donada":     props.dataGeneral.DONADAS_INVERSIONISTA
                ,   "gerente_donada":           props.dataGeneral.DONADAS_GERENTE
                ,   "mentor_realizada":         props.dataGeneral.REALIZADAS_MENTOR
                ,   "inversionista_realizada":  props.dataGeneral.REALIZADAS_INVERSIONISTA
                ,   "gerente_realizada":        props.dataGeneral.REALIZADAS_GERENTE
            });
            setLoading(false);
        }
    }

    const data_doughnut = {
        labels: ['Emprendedor', 'Mentor', 'Inversionista', 'Gerente/Ejecutivo'],
        datasets: [{
            label: '# of Votes',
            data: [doughnut.emprendedor, doughnut.mentor, doughnut.inversionista, doughnut.gerente],
            color: "#fff",
            backgroundColor: [
                '#7E9CDC',
                '#A2CD8D',
                '#E8879A',
                '#E9CE90',
            ], borderColor: [
                '#1F5FEA',
                '#2F9002',
                '#F21642',
                '#CB8D0F',
            ], borderWidth: 1,
        },],
    };

    const options_doughnut = {
        plugins: {
            datalabels: {
                labels: {
                    title: {
                        font: {
                            weight: 'bold',
                            size:   18,
                        }
                    },
                },
                color: "#fff",
            },
        },
        legend: {
            labels: {
                // fontColor: "#fff",
                fontSize: 18,
            }
        }
    };

    const data_region = {
        labels: ['Arica y Parinacota', 'Tarapacá', 'Antofagasta', 'Atacama', 'Coquimbo', 'Valparaíso', 'Libertador General Bernardo O Higgins', 'Maule', 'Ñuble', 'Bío Bío', 'La Araucanía', 'Los Ríos', 'Los Lagos', 'Aisén del General Carlos Ibáñez del Campo', 'Magallanes y de la Antártica Chilena'],
        datasets: [{
            label: '# of Votes',
            data: [bar.arica, bar.tarapaca, bar.antofagasta, bar.atacama, bar.coquimbo, bar.valparaiso, bar.ohiggins, bar.maule, bar.nuble, bar.biobio, bar.araucania, bar.rios, bar.lagos, bar.aisen, bar.magallanes],
            backgroundColor: [
                'rgba(191, 63, 63, 1)',
                'rgba(191, 127, 63, 1)',
                'rgba(191, 191, 63, 1)',
                'rgba(127, 191, 63, 1)',
                'rgba(191, 63, 191, 1)',
                'rgba(63, 63, 191, 1)',
                'rgba(63, 191, 191, 1)',
                'rgba(165, 127, 89, 1)',
                'rgba(38, 114, 38, 1)',
                'rgba(150, 191, 232, 1)',
                'rgba(120, 122, 140, 1)',
                'rgba(77, 77, 19, 1)',
                'rgba(153, 51, 51, 1)',
                'rgba(127, 63, 191, 1)',
                'rgba(232, 118, 38, 1)',
            ],
            borderColor: [
                'rgba(191, 63, 63, 1)',
                'rgba(191, 127, 63, 1)',
                'rgba(191, 191, 63, 1)',
                'rgba(127, 191, 63, 1)',
                'rgba(191, 63, 191, 1)',
                'rgba(63, 63, 191, 1)',
                'rgba(63, 191, 191, 1)',
                'rgba(165, 127, 89, 1)',
                'rgba(38, 114, 38, 1)',
                'rgba(150, 191, 232, 1)',
                'rgba(120, 122, 140, 1)',
                'rgba(77, 77, 19, 1)',
                'rgba(153, 51, 51, 1)',
                'rgba(127, 63, 191, 1)',
                'rgba(232, 118, 38, 1)',
            ],
            borderWidth: 1,
        }]
    };
      
    const options_region = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            datalabels: {
                labels: {
                    title: {
                        font: {
                            weight: 'bold',
                            size:   16
                        }
                    },
                },
                // color: "#B4B0B0",
                color: "#fff",
            }
        }, legend: {
            display: false,
        }
    };

    return (
        <Fragment>
            {
                (loading) ?
                    <LoadScreen text={ 'Actualizando Dashboard...' }/>
                :   <Fragment>
                        <Row className="mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                            <Col xl={6} lg={6} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <p className="text-muted h4">Reuniones donadas a la fecha</p>
                                                <h4 className="mb-0 text-center">{ total.donadas }</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                <span className="avatar-title etm_bg_blue">
                                                    <i className="bx bx-calendar-event font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <Media body>
                                                <p className="text-muted h4">Reuniones realizadas a la fecha</p>
                                                <h4 className="mb-0 text-center">{ total.realizadas }</h4>
                                            </Media>
                                            <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                <span className="avatar-title bg-success">
                                                    <i className="bx bx-calendar-check font-size-24"></i>
                                                </span>
                                            </div>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={4} lg={12} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <div className="profile-user-wid text-center">
                                                <img src={ Mentor } alt="" className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg"/>
                                                <h4 className="text-center etm_text_blue">Mentores</h4>
                                            </div>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Donadas</p>
                                                <h4 className="mb-0 text-center">{ total.mentor_donada }</h4>
                                            </Media>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Realizadas</p>
                                                <h4 className="mb-0 text-center">{ total.mentor_realizada }</h4>
                                            </Media>
                                        </Media>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={4} lg={12} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <div className="profile-user-wid text-center">
                                                <img src={ Inversionista } alt="" className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg"/>
                                                <h4 className="text-center etm_text_blue">Inversionistas</h4>
                                            </div>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Donadas</p>
                                                <h4 className="mb-0 text-center">{ total.inversionista_donada }</h4>
                                            </Media>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Realizadas</p>
                                                <h4 className="mb-0 text-center">{ total.inversionista_realizada }</h4>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={4} lg={12} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <div className="profile-user-wid text-center">
                                                <img src={ Gerente } alt="" className="img-thumbnail avatar-md rounded-circle fix_img shadow-lg"/>
                                                <h4 className="text-center etm_text_blue">Gerentes/Ejecutivos</h4>
                                            </div>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Donadas</p>
                                                <h4 className="mb-0 text-center">{ total.gerente_donada }</h4>
                                            </Media>
                                            <Media body>
                                                <p className="text-muted font-weight-medium text-center h5">Realizadas</p>
                                                <h4 className="mb-0 text-center">{ total.gerente_realizada }</h4>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card>
                                    <CardTitle>
                                        <h4 className="etm_text_blue text-center mt-2">Distribución de usuarios por perfil</h4>
                                    </CardTitle>
                                    <Doughnut data={ data_doughnut } options={ options_doughnut } height={ kpiHeight.doughnut } plugins={ ChartDataLabels }/>
                                    <h4 className="text-center mt-2">Total usuarios en la plataforma</h4>
                                    <Row>
                                        <Col>
                                            <h5 className="text-center mt-1">7 días atrás</h5>
                                            <h2 className="text-center text-warning mt-2"><b>{ usuariosTotalesAnt }</b></h2>
                                        </Col>
                                        <Col>
                                            <h5 className="text-center mt-1">Hoy</h5>
                                            <h2 className="text-center etm_text_blue mt-2"><b>{ usuariosTotales }</b></h2>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardTitle>
                                        <h4 className="etm_text_blue text-center mt-3">Distribución de usuarios por región</h4>
                                    </CardTitle>
                                </Card>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardBody>
                                        <Media>
                                            <div className="profile-user-wid text-center">
                                                <i className="fas fa-map-marker-alt ml-5 etm_text_blue fa-3x"></i>
                                            </div>
                                            <Media body>
                                                <h4 className="text-muted font-weight-medium text-center">Cantidad de usuarios de la <span className="etm_text_blue">Región Metropolitana</span></h4>
                                                <h4 className="mb-0 text-center">{ bar.metropolitana }</h4>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="mini-stats-wid">
                                    <CardTitle>
                                        <h4 className="text-center mt-3">Cantidad de usuarios en <span className="etm_text_blue">Regiones</span></h4>
                                    </CardTitle>
                                    <CardBody className="scroll_table_regiones">
                                        <Bar data={ data_region } options={ options_region } plugins={ChartDataLabels} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Fragment>
            }
        </Fragment>
    );



}

export default withRouter(Dashboard_general);