import React, { useState } from 'react';
import { Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getLocalStorage } from "../../helpers/etmmeetUtils";
import ValidateExpert from "../../components/QuickMeetings/ValidateExpert";
import DataMeeting from "../../components/QuickMeetings/DataMeeting";
import "../../assets/scss/etm.scss";
import image from "../../assets/images/etmmeet/login/trama-azul.png";

const Index = () => {
    const ls = getLocalStorage('speed_dating')

    const [experto, setExperto] = useState(ls)

    return (
        <div style={{
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            resizeMode: "cover",
            flex: 1,
            justifyContent: "center",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
        }}>
            <div className="page-content pt-1">
                <Container fluid>
                    <Breadcrumbs title={'Ruedas de Negocios'} />
                    {
                        (!experto) ?
                            <ValidateExpert
                                experto={experto}
                                setExperto={setExperto}
                            />
                            :
                            <DataMeeting
                                experto={experto}
                                setExperto={setExperto}
                            />
                    }
                </Container>
            </div>
        </div>
    );
}

export default Index;