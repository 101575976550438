import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, CustomInput } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import "../../assets/scss/etm.scss";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { API } from '../../helpers/axios';
import { getDate } from '../../helpers/etmmeetUtils';
import swal from 'sweetalert';
import ExportBtn from "../../components/Utils/ExportBtn";

const AdminExport = () => {
    const date      = new Date();
    let primerDia   = new Date(date.getFullYear(), date.getMonth(), 1);
    primerDia       = getDate(primerDia, true).replace(/\-/g, '/');
    let ultimoDia   = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    ultimoDia       = getDate(ultimoDia, true).replace(/\-/g, '/');
    
    const[rangeReuniones, setRangeReuniones]        = useState(`${getDate(new Date(date.getFullYear(), date.getMonth(), 1))}|${getDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))}`);
    const[rangeEvaluaciones, setRangeEvaluaciones]  = useState(`${getDate(new Date(date.getFullYear(), date.getMonth(), 1))}|${getDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))}`);
    const[loadingExport, setLoadingExport]          = useState(false);
    
    const[newsletterName, setNewsletterName]            = useState(false);
    const[newsletterPhone, setNewsletterPhone]          = useState(false);
    const[newsletterEmail, setNewsletterEmail]          = useState(true);
    const[newsletterHeader, setNewsletterHeader]        = useState(false);
    const[newsletterFormat, setNewsletterFormat]        = useState(false);
    const[newsletterSeparator, setNewsletterSeparator]  = useState(false);

    const exportarBaseEtmDay = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseEtmday");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseUsuarios = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseUsuariosExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", res.data.msg, "error");
        }
        setLoadingExport(false);
    }

    const baseReuniones = () => {
        if(rangeReuniones !== null && rangeReuniones !== undefined && rangeReuniones.length > 1){
            setLoadingExport(true);
            postBaseReuniones();
        }else{
            swal("", "Rango de fecha inválido", "error");
        }
    }

    const postBaseReuniones = async () => {
        const res = await API.post("/baseReunionesExcel", { range: rangeReuniones });
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseEvaluaciones = () => {
        if(rangeEvaluaciones !== null && rangeEvaluaciones !== undefined && rangeEvaluaciones.length > 1){
            setLoadingExport(true);
            postBaseEvaluaciones();
        }else{
            swal("", "Rango de fecha inválido", "error");
        }
    }

    const postBaseEvaluaciones = async () => {
        const res = await API.post("/baseEvaluacionesExcel", { range: rangeEvaluaciones });
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseAcumuladas = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseAcumuladasExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseIndustrias = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseIndustriasExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseEmpresas = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseEmpresasExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseExpertiz = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseExpertizExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseCargos = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseCargosExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const baseGerencias = async () => {
        setLoadingExport(true);
        const res = await API.post("/baseGerenciasExcel");
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const reunionesEvent = (event, picker) =>{
        setRangeReuniones(`${getDate(picker.startDate)}|${getDate(picker.endDate)}`);
    }

    const reunionesCallback = (start, end, label) =>{
        setRangeReuniones(`${getDate(start)}|${getDate(end)}`);
    }
    
    const evaluacionesEvent = (event, picker) =>{
        setRangeEvaluaciones(`${getDate(picker.startDate)}|${getDate(picker.endDate)}`);
    }

    const evaluacionesCallback = (start, end, label) =>{
        setRangeEvaluaciones(`${getDate(start)}|${getDate(end)}`);
    }

    const exportarBaseNewsletter = async () => {
        if(newsletterName == false && newsletterPhone == false && newsletterEmail == false){
            swal("", "Debes seleccionar al menos una cabecera", "error");
            return;
        }
        setLoadingExport(true);
        const res = await API.post("/baseNewsletter", { 
                name:           newsletterName
            ,   phone:          newsletterPhone
            ,   email:          newsletterEmail
            ,   with_header:    newsletterHeader
            ,   format:         newsletterFormat
            ,   separator:      newsletterSeparator
        });
        if(res.data.state){
            window.open(res.data.url, '_blank');
        }else{
            swal("", "Se ha producido un error al generar la base", "error");
        }
        setLoadingExport(false);
    }

    const changeNewsletter = ( type ) => {
        switch(type){
            case "NAME":
                setNewsletterName(!newsletterName);
                break;
            case "PHONE":
                setNewsletterPhone(!newsletterPhone);
                break;
            case "EMAIL":
                setNewsletterEmail(!newsletterEmail);
                break;
            case "HEADER":
                setNewsletterHeader(!newsletterHeader);
                break;
            case "FORMAT":
                setNewsletterFormat(!newsletterFormat);
                break;
            case "SEPARATOR":
                setNewsletterSeparator(!newsletterSeparator);
                break;
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={'Exportar Bases'}/>
                    <Row>
                        <Col xl={6} lg={6} md={12}>
                            <Row className="etm_card_export">
                                <Col xs={12}>
                                    <h3><b>EtM</b><i className="etm_text_green">day</i></h3>
                                    <hr />
                                </Col>
                                <Col xl={6} lg={12} md={12}>
                                    <Card >
                                        <CardBody className="pb-5">
                                            <h5><i className="fas fa-inbox align-middle mr-2 text-success"></i>Base Postulaciones</h5>
                                            <hr/>
                                        </CardBody>
                                        <Col className="text-right mb-2 pt-5">
                                            <ExportBtn handle={ exportarBaseEtmDay } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={6} lg={6} md={12}>
                            <Row className="etm_card_export">
                                <Col xs={12}>
                                    <h3><b>News</b><i className="text-danger">letter</i></h3>
                                    <hr />
                                </Col>
                                <Col xl={6} lg={12} md={12}>
                                    <Card >
                                        <CardBody className="pb-5">
                                            <h5><i className="fas fa-newspaper align-middle mr-2 text-danger"></i>Base Newsletter</h5>
                                            <hr/>
                                            <FormGroup>
                                                <CustomInput type="switch" id="exampleCustomSwitch1" name="customSwitch" label="Incluir Email" bsSize="md" checked={ newsletterEmail } onChange={ () => changeNewsletter("EMAIL")}/>
                                                <CustomInput type="switch" id="exampleCustomSwitch2" name="customSwitch" label="Incluir Nombre" bsSize="md" checked={ newsletterName } onChange={ () => changeNewsletter("NAME")}/>
                                                <CustomInput type="switch" id="exampleCustomSwitch3" name="customSwitch" label="Incluir Teléfono" bsSize="md" checked={ newsletterPhone } onChange={ () => changeNewsletter("PHONE")}/>
                                                <CustomInput type="switch" id="exampleCustomSwitch4" name="customSwitch" label="Incluir Cabeceras" bsSize="md" checked={ newsletterHeader } onChange={ () => changeNewsletter("HEADER")}/>
                                                <CustomInput type="switch" id="exampleCustomSwitch5" name="customSwitch" label="CSV | Excel" bsSize="md" checked={ newsletterFormat } onChange={ () => changeNewsletter("FORMAT")}/>
                                                <CustomInput type="switch" id="exampleCustomSwitch6" name="customSwitch" label="Separar por ; (CSV)" bsSize="md" checked={ newsletterSeparator } onChange={ () => changeNewsletter("SEPARATOR")}/>
                                            </FormGroup>
                                        </CardBody>
                                        <Col className="text-right mb-2 pt-5">
                                            <ExportBtn handle={ exportarBaseNewsletter } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                        </Col>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="etm_card_export">
                        <Col xs={12}>
                            <h3><b>EtM</b><i className="etm_text_blue">meet</i></h3>
                            <hr />
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-users align-middle mr-2 etm_text_blue"></i>Base de Usuarios</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseUsuarios } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-calendar-alt align-middle mr-2 etm_text_blue"></i>Base de Reuniones</h5>
                                    <hr></hr>
                                    <FormGroup>
                                        <label>Ingresar un rango de fechas</label>
                                        <DateRangePicker 
                                            onEvent={ reunionesEvent } onCallback={ reunionesCallback }
                                            initialSettings={{ 
                                                startDate:  primerDia, 
                                                endDate:    ultimoDia, 
                                                locale: {
                                                    format:         "DD-MM-YYYY", 
                                                    applyLabel:     "Aplicar", 
                                                    cancelLabel:    "Cancelar",
                                                    daysOfWeek: [
                                                        "Dom",
                                                        "Lun",
                                                        "Mar",
                                                        "Mie",
                                                        "Jue",
                                                        "Vie",
                                                        "Sab"
                                                    ],
                                                    monthNames: [
                                                        "Enero",
                                                        "Febrero",
                                                        "Marzo",
                                                        "Abril",
                                                        "Mayo",
                                                        "Junio",
                                                        "Julio",
                                                        "Agosto",
                                                        "Septiembre",
                                                        "Octubre",
                                                        "Noviembre",
                                                        "Diciembre"
                                                    ],
                                            }}} >
                                            <input onChange={(e) => setRangeReuniones(e) } name="range_date" className="form-control etm_input_text" type="text" placeholder="YYYY-MM-DD / YYYY-MM-DD" required readOnly/>
                                        </DateRangePicker>
                                    </FormGroup>
                                </CardBody>
                                <Col className="text-right mb-2">
                                    <ExportBtn handle={ baseReuniones } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-clipboard-list align-middle mr-2 etm_text_blue"></i>Base de Evaluaciones</h5>
                                    <hr></hr>
                                    <FormGroup>
                                        <label>Ingresar un rango de fechas</label>
                                        <DateRangePicker 
                                            onEvent={ evaluacionesEvent } onCallback={ evaluacionesCallback }
                                            initialSettings={{ 
                                                startDate:  primerDia, 
                                                endDate:    ultimoDia, 
                                                locale: {
                                                    format:         "DD-MM-YYYY", 
                                                    applyLabel:     "Aplicar", 
                                                    cancelLabel:    "Cancelar",
                                                    daysOfWeek: [
                                                        "Dom",
                                                        "Lun",
                                                        "Mar",
                                                        "Mie",
                                                        "Jue",
                                                        "Vie",
                                                        "Sab"
                                                    ],
                                                    monthNames: [
                                                        "Enero",
                                                        "Febrero",
                                                        "Marzo",
                                                        "Abril",
                                                        "Mayo",
                                                        "Junio",
                                                        "Julio",
                                                        "Agosto",
                                                        "Septiembre",
                                                        "Octubre",
                                                        "Noviembre",
                                                        "Diciembre"
                                                    ],
                                            }}} >
                                            <input onChange={(e) => setRangeEvaluaciones(e) } name="range_date" className="form-control etm_input_text" type="text" placeholder="YYYY-MM-DD / YYYY-MM-DD" required readOnly/>
                                        </DateRangePicker>
                                    </FormGroup>
                                </CardBody>
                                <Col className="text-right mb-2">
                                    <ExportBtn handle={ baseEvaluaciones } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-th-large align-middle mr-2 etm_text_blue"></i>Datos Acumulados</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseAcumuladas } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-industry align-middle mr-2 etm_text_blue"></i>Listado de Industrias</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseIndustrias } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-university align-middle mr-2 etm_text_blue"></i>Listado de Empresas (TDD)</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseEmpresas } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-user-tie align-middle mr-2 etm_text_blue"></i>Listado de Expertices</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseExpertiz } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fab fa-black-tie align-middle mr-2 etm_text_blue"></i>Listado de Cargos</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseCargos } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                        <Col xl={3} lg={6} md={12}>
                            <Card>
                                <CardBody>
                                    <h5><i className="fas fa-cubes align-middle mr-2 etm_text_blue"></i>Listado de Gerencias</h5>
                                    <hr></hr>
                                </CardBody>
                                <Col className="text-right mb-2 pt-5">
                                    <ExportBtn handle={ baseGerencias } loading={ loadingExport } title="Exportar" icon="file-excel align-middle mr-2" className="pl-3 pr-3" />
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(AdminExport);