import React, { useEffect, useRef, useState, Fragment } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import { API } from '../../helpers/axios';
import swal from '@sweetalert/with-react';
import Iframe from 'react-iframe';
import moment from 'moment';
import "../../assets/scss/etm.scss";
import { capitalize, validateNavigator, getAgentData, getDatetime } from "../../helpers/etmmeetUtils";
import ModalParticipant from "../../components/Meet/ModalParticipant";
import ModalShareData from '../../components/Meet/ModalShareData';
import ModalPermissions from '../../components/Meet/ModalPermissions';
import Logo from "../../assets/images/conecTime/logo_conectime.png";
import LoadScreen from "../../components/Utils/LoadScreen";

const publicIp = require('public-ip');

const Meet = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    let history = useHistory();

    const urlTwilio = "https://main.d1s8307hvnqoes.amplifyapp.com/";

    const [userId, setUserId] = useState(0);
    const [nameRoom, setNameRoom] = useState("");
    const [show, setShow] = useState(false);
    const [manager, setManager] = useState(0);
    const [modalParticipant, setModalParticipant] = useState(false);
    const [modalPermissions, setModalPermissions] = useState(false);
    const [zoomHeightLess, setZoomHeightLess] = useState(320);
    const [zoomHeight, setZoomHeight] = useState(window.innerHeight - zoomHeightLess);
    const [modalPassword, setModalPassword] = useState(false);
    const [zoomPassword, setZoomPassword] = useState("");
    const [zoomID, setZoomID] = useState("");

    const [compatibilityAlert, setCompatibilityAlert] = useState(false);
    const [compatibilityMessage, setCompatibilityMessage] = useState("");
    const [compatibilityColor, setCompatibilityColor] = useState("text-danger");
    const [compatibilityUrl, setCompatibilityUrl] = useState("");
    const [meetID, setMeetID] = useState(0);
    const [meeting, setMeeting] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [meets, setMeets] = useState([]);
    const [waitingMeet, setWaitingMeet] = useState(false);
    const [userName, setUserName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState(0);
    const [questionsModal, setQuestionsModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [idOtherParticipant, setIdOtherParticipant] = useState(null);
    const [buttonReport, setButtonReport] = useState(false);
    const [cardManagerId, setCardManagerId] = useState(0);
    const [cardUserId, setCardUserId] = useState(0);
    const [meetData, setMeetData] = useState({});
    const [finishComingSoon, setFinishComingSoon] = useState(false);
    const [loading, setLoading] = useState(true);

    const meetShowRef = useRef(show);

    useEffect(() => {
        getUrlUser();
    }, []);

    const getUrlUser = async () => {
        if (props.location.pathname.length > 0) {
            const data = props.location.pathname.split('/');
            const hash = data[2];
            const res = await API.get('/getUserIdByHash', { params: { hash } });
            if (res.data.state) {
                setUserId(res.data.data);
            }
        }
    }

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        }
    }, [zoomHeight]);

    useEffect(() => {
        saveNetworkInfo("Sala de Espera");
    }, [userId]);

    const saveNetworkInfo = async (type) => {
        if (userId > 0) {
            const datetime = await getDatetime();
            const ip = await publicIp.v4();
            const res = await API.post('/saveNetworkInfo', {
                user_id: userId
                , type
                , operativeSystem: getAgentData().so
                , browser: getAgentData().browser
                , version: getAgentData().browserVersion
                , ip
                , datetime
            });
        }
    }

    useEffect(() => {
        alertFinishComingSoon();
    }, [finishComingSoon]);

    useEffect(() => {
        getCompatibility();
    }, [compatibilityUrl, show]);

    useEffect(() => {
        meetShowRef.current = show;
    }, [show]);

    useEffect(() => {
        getCurrentMeet();
        setInterval(function () {
            if (!meetShowRef.current) {
                getCurrentMeet();
            }
        }, 10 * 1000);
    }, [userId]);

    const updateHeight = () => {
        setZoomHeight(window.innerHeight - zoomHeightLess);
    };

    const getCompatibility = () => {
        let msg = "";
        let link = "";

        if (!validateNavigator()) {
            setCompatibilityAlert(true);
            msg = <div className="m-5 pt-5 pb-5" style={{ border: "1px solid white" }}>
                <h4 className="text-white"><i className="fas fa-exclamation-triangle mr-2 text-danger"></i>Las reuniones dentro de EtM<i className="etm_text_blue">meet</i> solo funcionan en los navegadores:<br />
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome</span>, <span className="etm_text_blue">Mozilla Firefox</span> y <span className="etm_text_blue">Microsoft Edge</span> para <u className="text-warning">PC Windows</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome</span> para <u className="text-warning">Android</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Google Chrome y Safari 12+</span> para <u className="text-warning">MacOS</u>
                    <br /><i className="fas fa-angle-right mr-2"></i><span className="etm_text_blue">Safari</span> para <u className="text-warning">iOS (iPhone / iPad)</u>
                </h4>
            </div>;
            setCompatibilityMessage(msg);
        }
    };

    const getCurrentMeet = async () => {
        if (userId > 0) {
            const res = await API.get('/current_meet', { params: { user_id: userId } });
            if (res.data.state) {
                let { id, user_id, meet_id, meet_password, user_name, manager_name, manager_id, manager_rol, start_time, duration, meet_join_url, meet_url_start, name_room } = res.data.meet;
                if (userId == manager_id) {
                    setManager(1);
                    setIdOtherParticipant(user_id);

                    const resData = await API.post('/getDataForEvaluationByMeet', { type: "MANAGER", meet_id: id });
                    if (resData.data.state) {
                        setMeetData(resData.data.evaluation);
                    }
                } else {
                    setManager(0);
                    setIdOtherParticipant(manager_id);

                    const resData = await API.post('/getDataForEvaluationByMeet', { type: "USER", meet_id: id });
                    if (resData.data.state) {
                        setMeetData(resData.data.evaluation);
                    }
                };

                if (userId === manager_id) {
                    setCompatibilityUrl(`${urlTwilio}room/${name_room}/${manager_name}`);
                    // setCompatibilityUrl(meet_join_url);
                } else {
                    setCompatibilityUrl(`${urlTwilio}room/${name_room}/${user_name}`);
                    // setCompatibilityUrl(meet_url_start); 
                }

                setFinishComingSoon(false);
                setZoomPassword(meet_password);
                setZoomID(meet_id);
                setCardManagerId(manager_id);
                setCardUserId(user_id);
                setCompatibilityColor("text-white");
                setWaitingMeet(true);
                setMsg('Iniciando meet...');
                setMeetID(id);
                setNameRoom(name_room);
                setMeeting(meet_id);
                setPassword(meet_password);
                setUserName(user_name);
                setManagerName(manager_name);
                setTime(start_time);
                saveNetworkInfo("Twilio");

                const now = new Date();
                if (moment(start_time).add(300, 'seconds').toDate() < now) {
                    setButtonReport(true);
                }

                // se calcula la duración de la reunión
                let d = (duration * 60) - 20;
                setDuration(d);
                setTimeout(function () {
                    setShow(true);
                    setMsg('');
                    setWaitingMeet(false);
                }, 3000);

                // console.log("--- VALIDANDO PERMISOS DE VIDEO Y AUDIO ---");
                navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(function (mediaStream) {
                    // console.log("Permisos de video y audio ok");
                    setModalPermissions(false);
                }).catch(function (err) {
                    // console.log(err);
                    // console.log("Permisos de video y audio incorrectos");
                    setModalPermissions(true);
                });
                navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setLoading(false);
            } else {
                setMsg(res.data.msg);
                setWaitingMeet(false);
                setCompatibilityUrl("");
                setCompatibilityColor("text-danger");
                setLoading(false);
            }
        }
    }

    const preFinishMeet = async () => {
        swal({
            title: "¿Estás seguro?",
            text: "Se cerrará y finalizará la video llamada y no podrás volver acceder a ella.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancelar", "finalizar"],
        }).then((res) => {
            if (res) {
                finishMeet();
            }
        });
    }

    const finishMeet = async () => {
        setShow(false);
        setCardManagerId(0);
        setCardUserId(0);
        setCompatibilityColor("text-danger");
        setQuestionsModal(true);

        const res = await API.post('/close_meet', {
            meet_id: meeting
        });

        setTimeout(
            function () {
                swal.close();
                history.push('/thankMessage/end_meeting');
            }
                .bind(this),
            5000
        );
    }

    const enableReport = async () => {
        setButtonReport(true);
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const rendererFull = ({ days, hours, minutes, seconds, completed }) => {
        let hours_add = 24 * days;
        if (hours_add > 0) {
            hours += hours_add;
        }

        return (
            <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const reportUser = async () => {
        swal({
            title: "Terminar y reportar reunión",
            text: "Al reportar al participante la reunión finalizará y no podrás volver acceder a ella.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            buttons: ["Cancelar", "Reportar"],
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await API.post('/report_meeting', {
                    user_id: userId,
                    other_participant_id: idOtherParticipant,
                    meet_id: meetID,
                    reported_at: Date.now()
                });
                if (res.data.state) {
                    swal("Reporte exitoso", `Hemos recibido tu feedback, trabajaremos para que no vuelvas a pasar por esta situación.`, "success").then((value) => {
                        window.location.reload(true);
                    });
                } else {
                    swal("Ups!", `Ha ocurrido un problema al procesar tu reporte`, "warning");
                }
            } else {
                swal("No se ha realizado ningún reporte");
            }
        });
    }

    const enableFinishComingSoon = async () => {
        // setFinishComingSoon(true);
    }

    const alertFinishComingSoon = () => {
        if (finishComingSoon) {
            swal("", `Quedan 2 minutos para terminar la reunión`, "warning");
        }
    }

    return (
        <Fragment>
            {
                (nameRoom === "" || nameRoom === null) ?
                    <Fragment>
                        <div className="">
                            <Row className="etm_public_profile_bg_logo">
                                <Col className="text-center">
                                    <Link to="/home">
                                        <img src={Logo} alt="" className="img-fluid etm_public_profile_logo" />
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Container>
                                    <Col sm={12} className="pt-5 mt-5 text-center">
                                        {
                                            (loading) ?
                                                <LoadScreen text="Buscando reuniones..." />
                                                : <h1>No hay reuniones en curso de momento <i className="fas fa-smile-wink etm_text_blue ml-2"></i></h1>
                                        }
                                    </Col>
                                </Container>
                            </Row>
                        </div>
                    </Fragment>
                    : <Fragment>
                        <div className="page-content">
                            <Col className="etm_videocall_frame theme_bg pb-5">
                                <Row className="mb-0 pb-0">
                                    <Col lg={9} md={12}>
                                        <Row className="mt-2 ml-1 text-xl-left text-lg-left text-center">
                                            <Col sm={12}>
                                                <label className="text-white etm_video_txt_btn"><i className="fas fa-user-tie fa-1x mr-2"></i> Participante: <b className="ml-2 mr-2">{(manager == 1) ? capitalize(userName) : capitalize(managerName)}</b><button onClick={() => setModalParticipant(!modalParticipant)} className="btn btn-success btn-primary etm_btn btn-sm pt-0 pb-0 pl-3 pr-3"><span className="etm_video_txt_btn">Ver perfil</span></button></label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3} md={12}>
                                        <h2 className="text-white mt-lg-2 mr-xl-3 text-xl-right text-lg-right text-center">
                                            <span hidden={true}>
                                                {
                                                    (duration > 0) ?
                                                        <Countdown date={moment(time).add((duration - 120), 'seconds').toDate()} renderer={renderer} onComplete={enableFinishComingSoon} />
                                                        : ""
                                                }
                                            </span>
                                            {
                                                (duration > 0) ?
                                                    <Countdown date={moment(time).add(duration, 'seconds').toDate()} intervalDelay={0} precision={1} onComplete={finishMeet} renderer={props =>
                                                        <Fragment>
                                                            <label>
                                                                {props.minutes.toString().padStart(2, 0)}
                                                                <span className="ms-1 me-1">:</span>
                                                                {props.seconds.toString().padStart(2, 0)}
                                                                <span className="ms-1 me-1">:</span>
                                                                {props.milliseconds.toString().substring(0, 1)}
                                                            </label ><i className="ml-2 fas fa-clock text-danger"></i>
                                                        </Fragment>
                                                    } />
                                                    : ""
                                            }
                                        </h2>
                                    </Col>
                                </Row>
                                <Row className="pl-1 pr-1 pb-5">
                                    <Col lg={12}>
                                        <div className="justify-content-center mt-1 text-center">
                                            <Iframe
                                                id="etm_iframe_video"
                                                url={compatibilityUrl}
                                                className="myClassname etm_iframe_video"
                                                display="initial"
                                                position="relative"
                                                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                                                allow={`camera ${urlTwilio}; microphone ${urlTwilio}; fullscreen ${urlTwilio}`}
                                                styles={{ justifyContentCenter: 'center' }}
                                            />
                                            <Row className="etm_btn_meet_mobile pb-5">
                                                <Col xs={4}>
                                                    {
                                                        manager == 1 ?
                                                            <Fragment>
                                                                <UncontrolledTooltip innerClassName="bg-success" delay={0} placement="top" target="btn_share">¿Quieres compartir tus datos con el otro participante?</UncontrolledTooltip>
                                                                <button onClick={() => setShareModal(!shareModal)} id="btn_share" className="btn btn-success etm_btn btn-lg pt-1 pb-1 pl-3 pr-3"><i className="fas fa-share"></i></button>
                                                            </Fragment>
                                                            : ""
                                                    }
                                                </Col>
                                                <Col xs={4}>
                                                    <UncontrolledTooltip innerClassName="bg-warning" delay={0} placement="top" target="btn_report">¿El otro participante no llegó? Reporta su inasistencia</UncontrolledTooltip>
                                                    <button onClick={() => reportUser()} className="btn btn-warning etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" id="btn_report" disabled={!buttonReport}>
                                                        <span className="etm_video_txt_btn">
                                                            <span hidden={buttonReport}>
                                                                <i className="fas fa-clock mr-2"></i>
                                                                <Countdown date={moment(time).add(300, 'seconds').toDate()} renderer={renderer} onComplete={enableReport} />
                                                            </span>
                                                            <span hidden={!buttonReport}>
                                                                <i className="fas fa-exclamation-triangle"></i>
                                                            </span>
                                                        </span>
                                                    </button>
                                                </Col>
                                                <Col xs={4}>
                                                    <UncontrolledTooltip innerClassName="bg-danger" delay={0} placement="top" target="btn_finish">Finalizar Reunión</UncontrolledTooltip>
                                                    <button onClick={() => preFinishMeet()} className="btn btn-danger etm_btn btn-lg pt-1 pb-1 pl-3 pr-3" id="btn_finish"><i className="fas fa-times"></i></button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div >

                        {/* <ModalEvaluation showModal={ questionsModal } meetData={ meetData }/> */}
                        <ModalShareData showModal={shareModal} user_id={userId} share_user_id={idOtherParticipant} datos={{
                            page: 'meetMobile',
                            user: '',
                            OtherUsaer: ''
                        }} />
                        <ModalPermissions showModal={modalPermissions} />
                        {/* <ModalPassword showModal={ modalPassword } zoomID={ zoomID } zoomPassword={ zoomPassword } compatibilityUrl={ compatibilityUrl } /> */}
                        <ModalParticipant showModal={modalParticipant} userId={(manager == 1) ? cardUserId : cardManagerId} />
                    </Fragment>
            }

        </Fragment >
    );
}

export default withRouter(Meet);