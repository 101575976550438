import React, { Fragment, useState, useEffect } from 'react';

import { Row, Col, Label } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { capitalize, linkProfile, validateRol, cleanLinkedin, cleanFacebook, cleanInstagram, cleanTwitter, cleanYoutube, swal_etm, getLocalStorage } from "../../../helpers/etmmeetUtils";

import { API } from '../../../helpers/axios';

import emprendedor from "../../../assets/images/conecTime/design/Perfil-emprendedor.png";
import inversionista from "../../../assets/images/conecTime/design/Perfil-inversionista.png";
import mentor from "../../../assets/images/conecTime/design/Perfil-mentor.png";
import gerente from "../../../assets/images/conecTime/design/Perfil-gerente.png";
import defaultEmprendimientoFoto from "../../../assets/images/etmmeet/general/default.png";

const DrawCard = (props) => {

    const BtnExpero = () => {
        let btn = ''
        if (getLocalStorage().token !== undefined && getLocalStorage().token !== null && getLocalStorage().token.length > 0) {
            if (props.datos.experto_tiempo_disponible > 0) {
                btn = <button className="btn btn-primary etm_btn pl-5 pr-5" onClick={() => handleShowModal(props.datos.token, "REUNION")}>Reunámonos!</button>;
            } else {
                btn = <button className="btn btn-warning etm_btn pl-5 pr-5" onClick={() => handleShowModal(props.datos.token, "DONAR", 0, { name: props.datos.nombre, shortname: props.datos.nombre_corto })}>Solicita que done tiempo</button>;
            }
        } else {
            btn = <Link to='/login'><button className="btn btn-outline-primary etm_btn_outline">Reunámonos!</button></Link>;
        }

        return btn
    }

    const CardComercial = () => {
        let { horas_disponibles, rol_img, rol, btn } = '';

        if (props.datos.experto_tiempo_disponible > 0) {
            horas_disponibles = <Label
                className="h6 text-muted pointer"
                onClick={() => handleShowModal(props.datos.token, "REUNION")}
            >
                <span className="etm_badge_blue">Con tiempo disponible</span>
            </Label>;
        }

        switch (props.datos.perfil) {
            case "GERENTE/EJECUTIVO":
                rol_img = gerente;
                rol = "Gerente-Ejecutivo";
                break;
            case "INVERSIONISTA":
                rol_img = inversionista;
                rol = "Inversionista";
                break;
        }

        return <div className={`h-100 ${(props.datos.experto_es_sponsor > 0) ? `etm_card_sponsor` : `etm_card`}`} key={props.index}>
            <div className="profile-user-wid mb-2 text-center">
                <img
                    src={props.datos.foto_perfil}
                    alt="" className={`img-thumbnail avatar-lg rounded-circle fix_img shadow-lg ${(props.datos.experto_es_sponsor > 0) ? `avatar_sponsor` : ``}`}
                />
                {(props.datos.experto_es_sponsor > 0) ? <i className="fas fa-star etm_text_blue etm_star_avatar_sponsor"></i> : ""}
            </div>
            <Col lg={12}>
                <Row>
                    <Col lg={4}>
                        <div className="profile-user-wid mt-2 text-center">
                            <img src={props.datos.experto_empresa_foto} alt="" className="company_home fix_img" />
                        </div>
                    </Col>
                    <Col lg={8}>
                        <Label className="h5 text-break etm_text_blue etm_link_blue text-lg-left text-center">{capitalize(props.datos.nombre)}</Label>
                        <br />
                        <div className="text-lg-left text-center">
                            {
                                (props.datos.experto_cargo !== undefined && props.datos.experto_cargo !== null && props.datos.experto_cargo !== "" && props.datos.experto_empresa !== undefined && props.datos.experto_empresa !== null && props.datos.experto_empresa !== "") ?
                                    <>
                                        <Label className="h6 text-break text-muted">{props.datos.experto_cargo.toUpperCase()} EN {props.datos.experto_empresa.toUpperCase()}</Label>
                                        <br />
                                    </>
                                    : (props.datos.experto_cargo !== undefined && props.datos.experto_cargo !== null && props.datos.experto_cargo !== "") ?
                                        <>
                                            <Label className="h6 text-break text-muted">{props.datos.experto_cargo.toUpperCase()}</Label><br />
                                        </>
                                        : (props.datos.experto_empresa !== undefined && props.datos.experto_empresa !== null && props.datos.experto_empresa !== "") ?
                                            <><Label className="h6 text-break text-muted">{props.datos.experto_empresa.toUpperCase()}</Label><br /></>
                                            : <><Label className="h6 text-break text-white"><small>-</small></Label><br /></>
                            }
                            {
                                (props.datos.experto_gerencia !== undefined && props.datos.experto_gerencia !== null && props.datos.experto_gerencia !== "") ?
                                    <>
                                        <Label className="h6 text-break text-muted">{props.datos.experto_gerencia.toUpperCase()}</Label>
                                        <br />
                                    </>
                                    : <>
                                        <Label className="h6 text-break text-white"><small>-</small></Label>
                                        <br />
                                    </>
                            }
                            {
                                (props.datos.experto_gerencia !== undefined && props.datos.experto_gerencia !== null && props.datos.experto_gerencia !== "") ?
                                    <>
                                        <Label className="h6 text-break text-muted">{props.datos.experto_gerencia.toUpperCase()}</Label><br />
                                    </>
                                    : <>
                                        <Label className="h6 text-break text-white"><small>-</small></Label>
                                        <br />
                                    </>
                            }
                        </div>
                    </Col>
                    <Col lg={12}>
                        <hr />
                        <Row>
                            <Col xs={6} className="text-right">
                                <div>
                                    <img src={rol_img} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img" />
                                </div>
                            </Col>
                            <Col xs={6} className="text-left">
                                <Label className="h6 text-break font-weight-medium pb-0 mb-0"><b>Perfil</b></Label>
                                <br />
                                <Label className="h6 text-break font-weight-medium pt-0 mt-0">{rol}</Label>
                            </Col>
                        </Row>
                        <Col xs={12}>
                            <Row className="mt-1">
                                <Col xs={12} className="text-center">
                                    {horas_disponibles}
                                </Col>
                            </Row>
                            <hr />
                            <ul className="list-inline text-center">
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.facebook !== undefined && props.datos.facebook !== null && props.datos.facebook !== "") ?
                                            <Label className="h4">
                                                <a href={cleanFacebook(props.datos.facebook)} target="_blank">
                                                    <i className="fab fa-facebook-square facebook"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.instagram !== undefined && props.datos.instagram !== null && props.datos.instagram !== "") ?
                                            <Label className="h4">
                                                <a href={cleanInstagram(props.datos.instagram)} target="_blank">
                                                    <i className="fab fa-instagram instagram"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.linkedin !== undefined && props.datos.linkedin !== null && props.datos.linkedin !== "") ?
                                            <Label className="h4">
                                                <a href={cleanLinkedin(props.datos.linkedin)} target="_blank">
                                                    <i className="fab fa-linkedin linkedin"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.twitter !== undefined && props.datos.twitter !== null && props.datos.twitter !== "") ?
                                            <Label className="h4">
                                                <a href={cleanTwitter(props.datos.twitter)} target="_blank">
                                                    <i className="fab fa-twitter-square twitter"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.youtube !== undefined && props.datos.youtube !== null && props.datos.youtube !== "") ?
                                            <Label className="h4">
                                                <a href={cleanYoutube(props.datos.youtube)} target="_blank">
                                                    <i className="fab fa-youtube youtube"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                            </ul>
                        </Col>
                        <Col className="d-flex justify-content-center mb-2">
                            <BtnExpero />
                        </Col>
                    </Col>
                </Row>
            </Col>
        </div>
    }

    const CardMentor = () => {
        let { horas_disponibles } = '';

        if (props.datos.experto_tiempo_disponible > 0) {
            horas_disponibles = <Label
                className="h6 text-muted pointer"
                onClick={() => handleShowModal(props.datos.token, "REUNION")}
            >
                <span className="etm_badge_blue">Con tiempo disponible</span>
            </Label>;
        }

        return <div className={`h-100 ${(props.datos.experto_es_sponsor > 0) ? `etm_card_sponsor` : `etm_card`}`} key={props.index}>
            <div className="profile-user-wid mb-2 text-center">
                <img
                    src={props.datos.foto_perfil}
                    alt=""
                    className={`img-thumbnail avatar-lg rounded-circle fix_img shadow-lg ${(props.datos.experto_es_sponsor > 0) ? `avatar_sponsor` : ``}`}
                />
                {(props.datos.experto_es_sponsor > 0) ? <i className="fas fa-star etm_text_blue etm_star_avatar_sponsor"></i> : ""}
            </div>
            <Col lg={12}>
                <Row>
                    <Col lg={4}>
                        <div className="profile-user-wid mt-2 text-center">
                            <img src={props.datos.experto_empresa_foto} alt="" className="company_home fix_img" />
                        </div>
                    </Col>
                    <Col lg={8}>
                        <Label className="h5 text-break etm_text_blue etm_link_blue text-lg-left text-center">{capitalize(props.datos.nombre)}</Label>
                        <br />
                        <div className="text-lg-left text-center">
                            {
                                (props.datos.experto_cargo !== undefined && props.datos.experto_cargo !== null && props.datos.experto_cargo !== "" && props.datos.experto_empresa !== undefined && props.datos.experto_empresa !== null && props.datos.experto_empresa !== "") ?
                                    <Fragment>
                                        <Label className="h6 text-break text-muted">{props.datos.experto_cargo.toUpperCase()} EN {props.datos.experto_empresa.toUpperCase()}</Label>
                                        <br />
                                    </Fragment>
                                    : (props.datos.CARGO !== undefined && props.datos.experto_cargo !== null && props.datos.experto_cargo !== "") ?
                                        <Fragment>
                                            <Label className="h6 text-break text-muted">{props.datos.experto_cargo.toUpperCase()}</Label>
                                            <br />
                                        </Fragment>
                                        : (props.datos.experto_empresa !== undefined && props.datos.experto_empresa !== null && props.datos.experto_empresa !== "") ?
                                            <Fragment>
                                                <Label className="h6 text-break text-muted">{props.datos.experto_empresa.toUpperCase()}</Label>
                                                <br />
                                            </Fragment>
                                            : <Fragment>
                                                <Label className="h6 text-break text-white"><small>-</small></Label>
                                                <br />
                                            </Fragment>
                            }
                            {
                                (props.datos.experto_gerencia !== undefined && props.datos.experto_gerencia !== null && props.datos.experto_gerencia !== "") ?
                                    <Fragment>
                                        <Label className="h6 text-break text-muted">{props.datos.experto_gerencia.toUpperCase()}</Label>
                                        <br />
                                    </Fragment>
                                    : <Fragment>
                                        <Label className="h6 text-break text-white"><small>-</small></Label>
                                        <br />
                                    </Fragment>
                            }
                        </div>
                    </Col>
                    <Col lg={12}>
                        <hr />
                        <Row>
                            <Col xs={6} className="text-right">
                                <div>
                                    <img src={mentor} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img" />
                                </div>
                            </Col>
                            <Col xs={6} className="text-left">
                                <Label className="h6 text-break font-weight-medium pb-0 mb-0"><b>Perfil</b></Label>
                                <br />
                                <Label className="h6 text-break font-weight-medium pt-0 mt-0">{`Mentor`}</Label>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={4} className="text-left"></Col>
                            <Col xs={4} className="text-center">
                                <Label className="h6 text-break font-weight-medium">
                                    <b>
                                        {
                                            (props.datos.tag !== undefined && props.datos.tag !== null) ? `EXPERTICIAS` : "MENTOR"
                                        }
                                    </b>
                                </Label>
                            </Col>
                            <Col xs={4} className="text-left"></Col>
                        </Row>
                        <Col xs={12}>
                            <Row className="mt-1">
                                <Col xs={12} className="text-justify">
                                    {
                                        (props.datos.tag !== undefined && props.datos.tag !== null) ?
                                            <Fragment>{props.datos.tag}</Fragment>
                                            : <Label className="h6 text-break text-white"><small>-</small></Label>
                                    }
                                </Col>
                                <Col xs={12} className="text-center mt-1">
                                    {horas_disponibles}
                                </Col>
                            </Row>
                            <hr />
                            <ul className="list-inline text-center">
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.facebook !== undefined && props.datos.facebook !== null && props.datos.facebook !== "") ?
                                            <Label className="h4">
                                                <a href={cleanFacebook(props.datos.facebook)} target="_blank">
                                                    <i className="fab fa-facebook-square facebook"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.instagram !== undefined && props.datos.instagram !== null && props.datos.instagram !== "") ?
                                            <Label className="h4">
                                                <a href={cleanInstagram(props.datos.instagram)} target="_blank">
                                                    <i className="fab fa-instagram instagram"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.linkedin !== undefined && props.datos.linkedin !== null && props.datos.linkedin !== "") ?
                                            <Label className="h4">
                                                <a href={cleanLinkedin(props.datos.linkedin)} target="_blank">
                                                    <i className="fab fa-linkedin linkedin"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.twitter !== undefined && props.datos.twitter !== null && props.datos.twitter !== "") ?
                                            <Label className="h4">
                                                <a href={cleanTwitter(props.datos.twitter)} target="_blank">
                                                    <i className="fab fa-twitter-square twitter"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.youtube !== undefined && props.datos.youtube !== null && props.datos.youtube !== "") ?
                                            <Label className="h4">
                                                <a href={cleanYoutube(props.datos.youtube)} target="_blank">
                                                    <i className="fab fa-youtube youtube"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                            </ul>
                        </Col>
                        <Col className="d-flex justify-content-center mb-2">
                            <BtnExpero />
                        </Col>
                    </Col>
                </Row>
            </Col>
        </div>
    }

    const CardEmprendedor = () => {
        let listProyectos = [];
        let proyectos = [];
        let listWebs = [];
        let sitios_webs = [];
        let listUbicaciones = [];
        let ubicaciones = [];
        let steps = [];
        let listStep = [];
        let industrias = [];
        let listIndustrias = [];
        let btn = "";

        if (getLocalStorage().token !== undefined && getLocalStorage().token !== null && getLocalStorage().token.length > 0) {
            btn = <button className="btn btn-primary etm_btn pl-5 pr-5" onClick={() => handleShowModal(props.datos.token, "REUNION")}>Reunámonos!</button>;
        } else {
            btn = <Link to='/login'><button className="btn btn-outline-primary etm_btn_outline">Reunámonos!</button></Link>;
        }

        if (props.datos.emprendedor_proyecto_nombre !== undefined && props.datos.emprendedor_proyecto_nombre !== null && props.datos.emprendedor_proyecto_nombre !== "") {
            listProyectos = props.datos.emprendedor_proyecto_nombre.split(",");
            listProyectos = listProyectos.filter((item, index) => {
                return listProyectos.indexOf(item) === index;
            })

            listProyectos.map(async (project, n) => {
                proyectos.push(
                    <Fragment key={n}>
                        {(n > 0) ? <br /> : ""}
                        <Label className="h6 text-break font-weight-medium m-0">{project}</Label>
                    </Fragment>
                );
            });
        }

        if (props.datos.PROYECTO_SITIO_WEB !== undefined && props.datos.PROYECTO_SITIO_WEB !== null && props.datos.PROYECTO_SITIO_WEB !== "") {
            listWebs = props.datos.PROYECTO_SITIO_WEB.split(",");
            listWebs = listWebs.filter((item, index) => {
                return listWebs.indexOf(item) === index;
            })

            listWebs.map(async (web, n) => {
                sitios_webs.push(
                    <Fragment key={n}>
                        {(n > 0) ? <br /> : ""}
                        <a href={`https://${web.replace("https://", "").replace("http://", "")}`} target="_blank">
                            <i className="fas fa-external-link-alt etm_text_blue mr-2"></i>
                            {web}
                        </a>
                    </Fragment>
                );
            });
        }

        if (props.datos.emprendedor_proyecto_ubicacion !== undefined & props.datos.emprendedor_proyecto_ubicacion !== null && props.datos.emprendedor_proyecto_ubicacion !== "") {
            listUbicaciones = props.datos.emprendedor_proyecto_ubicacion.split("|");
            listUbicaciones = listUbicaciones.filter((item, index) => {
                return listUbicaciones.indexOf(item) === index;
            });

            listUbicaciones.map(async (ubicacion, n) => {
                ubicaciones.push(
                    <Fragment key={n}>
                        <Label className="h6 text-break font-weight-medium m-0">
                            <i className="fas fa-map-marker-alt etm_text_blue  mr-2"></i>
                            {ubicacion}
                        </Label>
                    </Fragment >
                );
            });
        }

        if (props.datos.proyecto_etapa !== undefined & props.datos.proyecto_etapa !== null & props.datos.proyecto_etapa !== "") {
            listStep = props.datos.proyecto_etapa.split(',');
            listStep = listStep.filter((item, index) => {
                return listStep.indexOf(item) === index;
            });

            listStep.map(async (step, n) => {
                steps.push(
                    <Fragment key={n}>
                        {(n > 0) ? <br /> : ""}
                        <Label className="h6 text-break font-weight-medium m-0">{step}</Label>
                    </Fragment>
                );
            });
        }

        if (props.datos.emprendedor_proyecto_industria !== undefined && props.datos.emprendedor_proyecto_industria !== null && props.datos.emprendedor_proyecto_industria !== "") {
            listIndustrias = props.datos.emprendedor_proyecto_industria.split(',');
            listIndustrias = listIndustrias.filter((item, index) => {
                return listIndustrias.indexOf(item) === index;
            });

            listIndustrias.map(async (industria, n) => {
                industrias.push(
                    <Fragment key={n}>
                        {(n > 0) ? <br /> : ""}
                        <Label className="h6 text-break font-weight-medium m-0">{industria}</Label>
                    </Fragment>
                );
            });
        }

        return <div className={`h-100 etm_card`} key={props.index}>
            <div className="profile-user-wid mb-2 text-center">
                <img src={props.datos.foto_perfil} alt="" className={`img-thumbnail avatar-lg rounded-circle fix_img shadow-lg`} />
            </div>
            <Col lg={12}>
                <Row>
                    <Col lg={12} className="text-center">
                        <Label className="h5 text-break etm_text_blue etm_link_blue text-center">{capitalize(props.datos.nombre)}</Label>
                        <br />
                        <Label>{
                            (props.datos.descripcion !== undefined && props.datos.descripcion !== null) ?
                                (props.datos.descripcion.length > 150) ?
                                    props.datos.descripcion.substring(0, 150) + "..."
                                    :
                                    props.datos.descripcion
                                :
                                <Label className="h6 text-break text-white"><small>-</small><br /><small>-</small></Label>
                        }</Label>
                    </Col>
                    <Col lg={12}>
                        <hr />
                        <Row>
                            <Col xs={6} className="text-right">
                                <div>
                                    <img src={emprendedor} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img" />
                                </div>
                            </Col>
                            <Col xs={6} className="text-left">
                                <Label className="h6 text-break font-weight-medium pb-0 mb-0"><b>Perfil</b></Label>
                                <br />
                                <Label className="h6 text-break font-weight-medium pt-0 mt-0">{'Emprendedor'}</Label>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            {
                                (props.datos.emprendedor_proyecto_nombre !== undefined && props.datos.emprendedor_proyecto_nombre !== null) ?
                                    <Fragment>
                                        <Col xs={12} className="text-left mb-2">
                                            <Label className="h6 text-break font-weight-medium"><b>PROYECTOS </b></Label>
                                            <br />
                                            <Label className="h6 text-break font-weight-medium">{proyectos}</Label>
                                        </Col>
                                        <Col xs={6} className="text-left">
                                            <Label className="h6 text-break font-weight-medium">{sitios_webs}</Label>
                                        </Col>
                                        <Col xs={6} className="text-left">
                                            {ubicaciones}
                                        </Col>
                                        <Col xs={6} className="text-left mt-1">
                                            <Label className="h6 text-break font-weight-medium"><b>ETAPAS DE LOS PROYECTOS</b></Label>
                                            <br />
                                            <Label className="h6 text-break font-weight-medium">{steps}</Label>
                                        </Col>
                                        <Col xs={6} className="text-left mt-1">
                                            <Label className="h6 text-break font-weight-medium"><b>INDUSTRIAS</b></Label>
                                            <br />
                                            <Label className="h6 text-break font-weight-medium">{industrias}</Label>
                                        </Col>
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Col xs={12} className="text-center">
                                            <Label className="h6 text-break font-weight-medium"><b>SIN PROYECTOS</b></Label>
                                            <Label className="h6 text-break text-white"><small>-</small><br /><small>-</small></Label>
                                            <Label className="h6 text-break text-white"><small>-</small><br /><small>-</small></Label>
                                        </Col>
                                    </Fragment>
                            }
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Col xs={12}>
                            <hr />
                            <ul className="list-inline text-center">
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.facebook !== undefined && props.datos.facebook !== null && props.datos.facebook !== "") ?
                                            <Label className="h4">
                                                <a href={cleanFacebook(props.datos.facebook)} target="_blank">
                                                    <i className="fab fa-facebook-square facebook"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.instagram !== undefined && props.datos.instagram !== null && props.datos.instagram !== "") ?
                                            <Label className="h4">
                                                <a href={cleanInstagram(props.datos.instagram)} target="_blank">
                                                    <i className="fab fa-instagram instagram"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.linkedin !== undefined && props.datos.linkedin !== null && props.datos.linkedin !== "") ?
                                            <Label className="h4">
                                                <a href={cleanLinkedin(props.datos.linkedin)} target="_blank">
                                                    <i className="fab fa-linkedin linkedin"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.twitter !== undefined && props.datos.twitter !== null && props.datos.twitter !== "") ?
                                            <Label className="h4">
                                                <a href={cleanTwitter(props.datos.twitter)} target="_blank">
                                                    <i className="fab fa-twitter-square twitter"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.youtube !== undefined && props.datos.youtube !== null && props.datos.youtube !== "") ?
                                            <Label className="h4">
                                                <a href={cleanYoutube(props.datos.youtube)} target="_blank">
                                                    <i className="fab fa-youtube youtube"></i>
                                                </a>
                                            </Label>
                                            :
                                            <Label className="h6 text-white">-</Label>
                                    }
                                </li>
                            </ul>
                        </Col>
                        <Col className="d-flex justify-content-center mb-2">
                            {btn}
                        </Col>
                    </Col>
                </Row>
            </Col>
        </div>
    }

    const CardEmprendimiento = () => {
        let web = [];
        let ubicacion = [];
        let industria = [];
        let btn = "";

        if (props.datos.startup_id !== undefined && getLocalStorage().token !== undefined && getLocalStorage().token !== null && getLocalStorage().token.length > 0) {
            btn = <button className="btn btn-primary etm_btn pl-5 pr-5" onClick={() => handleShowModal(props.datos.token, "REUNION", props.datos.startup_id)}>Reunámonos!</button>;
        } else {
            btn = <Link to='/login'><button className="btn btn-outline-primary etm_btn_outline">Reunámonos!</button></Link>;
        }

        if (props.datos.proyecto_sitio_web !== undefined && props.datos.proyecto_sitio_web !== null && props.datos.proyecto_sitio_web !== "") {
            web = <a href={`https://${props.datos.proyecto_sitio_web.replace("https://", "").replace("http://", "")}`} target="_blank">
                <i className="fas fa-external-link-alt etm_text_blue mr-2"></i>
                {props.datos.proyecto_sitio_web}
            </a>
        } else {
            web = <h6 className="text-break text-white"><small>-</small><br /><small>-</small></h6>
        }

        if (props.datos.proyecto_pais !== undefined && props.datos.proyecto_pais !== null) {
            if (props.datos.proyecto_pais !== undefined && props.datos.proyecto_region !== null) {
                ubicacion = <Fragment>
                    <i className="fas fa-map-marker-alt etm_text_blue  mr-2"></i>
                    {props.datos.proyecto_pais + ", " + props.datos.proyecto_region}
                </Fragment>
            } else {
                ubicacion = <Fragment>
                    <i className="fas fa-map-marker-alt etm_text_blue  mr-2"></i>
                    {props.datos.proyecto_pais}
                </Fragment>
            }
        } else if (props.datos.proyecto_region !== undefined && props.datos.proyecto_region !== null) {
            ubicacion = <Fragment>
                <i className="fas fa-map-marker-alt etm_text_blue  mr-2"></i>
                {props.datos.PROYECTO_REGION}
            </Fragment>
        }


        if (props.datos.proyecto_industria !== undefined && props.datos.proyecto_industria !== null & props.datos.proyecto_industria !== "") {
            industria = <span>{props.datos.proyecto_industria}</span>
        } else {
            industria = <h6 className="text-break text-white"><small>-</small><br /><small>-</small></h6>
        }

        return <div className={`h-100 etm_card`} key={props.index}>
            <div className="profile-user-wid mb-2 text-center">
                {
                    (props.datos.proyecto_foto !== undefined && props.datos.proyecto_foto !== null && props.datos.proyecto_foto !== "") ?
                        <img src={props.datos.proyecto_foto} alt="" className={`img-thumbnail avatar-lg rounded-circle fix_img shadow-lg`} />
                        :
                        <img src={defaultEmprendimientoFoto} alt="" className={`img-thumbnail avatar-lg rounded-circle fix_img shadow-lg`} />

                }
            </div>
            <Col lg={12}>
                <Row>
                    <Col lg={12} className="text-center">
                        <Label className="h5 text-break etm_text_blue etm_link_blue text-center">{capitalize(props.datos.proyecto_nombre)}</Label>
                        <br />
                        <Label>{
                            (props.datos.proyecto_descripcion !== undefined && props.datos.proyecto_descripcion !== null) ?
                                (props.datos.proyecto_descripcion.length > 300) ?
                                    props.datos.proyecto_descripcion.substring(0, 300) + "..."
                                    :
                                    props.datos.proyecto_descripcion
                                :
                                <h6 className="text-break text-white"><small>-</small><br /><small>-</small></h6>
                        }</Label>
                    </Col>
                    <Col lg={12}>
                        <hr />
                        <Row>
                            <Col xs={6} className="text-right">
                                <div>
                                    <img src={emprendedor} className="img-thumbnail avatar-sm rounded-circle fix_img shadow-lg" alt="img" />
                                </div>
                            </Col>
                            <Col xs={6} className="text-left">
                                <Label className="h6 text-break font-weight-medium pb-0 mb-0"><b>Perfil</b></Label>
                                <br />
                                <Label className="h6 text-break font-weight-medium pt-0 mt-0">{`Emprendimiento`}</Label>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            {
                                <Fragment>
                                    <Col xs={6} className="text-left">
                                        {web}
                                    </Col>
                                    <Col xs={6} className="text-left">
                                        {ubicacion}
                                    </Col>
                                    <Col xs={12} className="text-left mt-1">
                                        <h6 className="text-break font-weight-medium"><b>INDUSTRIAS</b></h6>
                                        {industria}
                                    </Col>
                                    <Col xs={12} className="text-left mt-1">
                                        <h6 className="text-break font-weight-medium">{props.datos.TAG}</h6>
                                    </Col>
                                </Fragment>

                            }

                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Col xs={12}>
                            <hr />
                            <ul className="list-inline text-center">
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.FACEBOOK !== undefined && props.datos.FACEBOOK !== null && props.datos.FACEBOOK !== "") ?
                                            <h4>
                                                <a href={cleanFacebook(props.datos.FACEBOOK)} target="_blank">
                                                    <i className="fab fa-facebook-square facebook"></i>
                                                </a>
                                            </h4>
                                            :
                                            <h6 className="text-white">-</h6>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.INSTAGRAM !== undefined && props.datos.INSTAGRAM !== null && props.datos.INSTAGRAM !== "") ?
                                            <h4>
                                                <a href={cleanInstagram(props.datos.INSTAGRAM)} target="_blank">
                                                    <i className="fab fa-instagram instagram"></i>
                                                </a>
                                            </h4>
                                            :
                                            <h6 className="text-white">-</h6>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.LINKEDIN !== undefined && props.datos.LINKEDIN !== null && props.datos.LINKEDIN !== "") ?
                                            <h4>
                                                <a href={cleanLinkedin(props.datos.LINKEDIN)} target="_blank">
                                                    <i className="fab fa-linkedin linkedin"></i>
                                                </a>
                                            </h4>
                                            :
                                            <h6 className="text-white">-</h6>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.TWITTER !== undefined && props.datos.TWITTER !== null && props.datos.TWITTER !== "") ?
                                            <h4>
                                                <a href={cleanTwitter(props.datos.TWITTER)} target="_blank">
                                                    <i className="fab fa-twitter-square twitter"></i>
                                                </a>
                                            </h4>
                                            :
                                            <h6 className="text-white">-</h6>
                                    }
                                </li>
                                <li className="list-inline-item mr-2">
                                    {
                                        (props.datos.YOUTUBE !== undefined && props.datos.YOUTUBE !== null && props.datos.YOUTUBE !== "") ?
                                            <h4>
                                                <a href={cleanYoutube(props.datos.YOUTUBE)} target="_blank">
                                                    <i className="fab fa-youtube youtube"></i>
                                                </a>
                                            </h4>
                                            :
                                            <h6 className="text-white">-</h6>
                                    }
                                </li>
                            </ul>
                        </Col>
                        <Col className="d-flex justify-content-center mb-2">
                            {btn}
                        </Col>
                    </Col>
                </Row>
            </Col>
        </div>
    }

    const handleShowModal = async (token, type, startup_id = 0, data = {}) => {
        if (!validateRol(["ADMINISTRADOR"])) {
            if (getLocalStorage().token === token) {
                swal_etm(`No puedes solicitar reuniones contigo mismo`);
                return false;
            }
        }

        if (validateRol(["EMPRENDEDOR"])) {
            const startups = await API.get('/search/getStartupsByUserToken', { params: { token: getLocalStorage().token } });
            if (type !== 'DONAR' && startups.data.startups.length < 1) {
                swal_etm(`Debes tener al menos un <strong>Emprendimiento</strong> configurado para poder postular.<br><br>Para modificarlos haz click en <a href="/profile/startups"><strong>Mis Emprendimientos</strong></a>`, "warning", "Sin emprendimientos");
                return false;
            }
        }
        let meetType = ''

        const res = await API.get('/search/getPercentageProfile', { params: { token: getLocalStorage().token } });
        if (type !== 'DONAR' && res.data.percentage < 100) {
            if (validateRol(["EMPRENDEDOR"])) {
                swal_etm(`Debes completar al <strong>100%</strong> tu perfil y tu emprendimiento para poder solicitar reuniones. Te recordamos que Alan se encarga de hacer el match y para eso necesita conocer bien a los usuarios.<br><br>Haz click en <a href="/profile"><strong>Completar Perfil</strong></a> y luego en <a href="/startups"><strong>Mis Emprendimientos</strong></a>`, "warning", "Perfil incompleto");
            } else {
                swal_etm(`Debes completar al <strong>100%</strong> tu perfil para poder solicitar reuniones. Te recordamos que Alan se encarga de hacer el match y para eso necesita conocer bien a los usuarios.<br><br>Haz click en <a href="/profile"><strong>Completar Perfil</strong></a>`, "warning", "Perfil incompleto");
            }
            return false;
        }

        switch (props.datos.perfil) {
            case 'GERENTE/EJECUTIVO':
            case 'INVERSIONISTA':
                meetType = (validateRol(['GERENTE/EJECUTIVO', 'INVERSIONISTA'])) ? 'ENTRE_EXPERTOS' : 'EXPERTO'
                break;
            case 'MENTOR':
                meetType = 'MENTOR'
                break;
            case 'EMPRENDEDOR':
                meetType = 'EMPRENDEDOR'
                break;
            case 'EMPRENDIMIENTO':
                meetType = 'EMPRENDIMIENTO'
                break;
            default:
                meetType = ''
                break;
        }
        props.setMeetingType(meetType)

        if (type === "REUNION") {
            if (props.datos.record_type === 'startup' || meetType === 'EMPRENDEDOR') {
                props.setEmprendedorToken(token);
                props.setStartupId(startup_id);
                props.setModalSolicitarEmprendedor(true);
            } else {
                props.setExpertToken(token);
                props.setModalSolicitarExperto(true);

            }
        } else {
            props.setDataModalDonar({ token: token, name: data.name, shortname: data.shortname });
            props.setModalDonar(!props.modalDonar);
        }
    }

    return (
        <>
            {
                (['GERENTE/EJECUTIVO', 'INVERSIONISTA'].includes(props.datos.perfil)) && (
                    <CardComercial />
                )
            }
            {
                (props.datos.perfil === 'MENTOR') && (
                    <CardMentor />
                )
            }
            {
                (props.datos.perfil === 'EMPRENDEDOR') && (
                    <CardEmprendedor />
                )
            }
            {
                (props.datos.record_type === 'startup') && (
                    <CardEmprendimiento />
                )

            }
        </>
    );
}

export default withRouter(DrawCard);