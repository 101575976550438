import React, { Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import logo_ey from "../../assets/images/conecTime/design/LogosPartner/ey_black_footer.png";

const CreatedBy = ({ full }) => {

    full = (full === undefined) ? true : full;

    return (
        <Fragment>
            {
                (full) ?
                    <footer className="created_by_full mt-0 pt-0">
                        <Container fluid={true}>
                            <Row>
                                <Col xs={12} sm={12} md={4}></Col>
                                <Col xs={12} sm={6} md={4}>
                                    <div className="text-right">
                                        Match system powered by <img src={logo_ey} className="etm_img_powered_ey ml-2" />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <div className="text-right mr-2">
                                        <p>
                                            {new Date().getFullYear()} © EtM. | Creado por Emprende tu mente con <i className="mdi mdi-heart text-danger"></i>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                    : <Container fluid={true} className="created_by_simple">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={4}></Col>
                            <Col xs={12} sm={6} md={6} lg={4}></Col>
                            <Col xs={12} sm={6} md={6} lg={4}>
                                    <p>{new Date().getFullYear()} © EtM. | Creado por Emprende tu mente con <i className="mdi mdi-heart text-danger"></i></p>
                            </Col>
                        </Row>
                    </Container>
            }
        </Fragment>
    );
}

export default CreatedBy;

