import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Input, Button, FormGroup } from "reactstrap";
import { withRouter } from "react-router-dom";
import { API } from '../../../helpers/axios';
import { getDate, spanishDate, swal_etm, capitalize } from "../../../helpers/etmmeetUtils";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useCalendar, shortDayNames } from "react-calendar-hook";
import LoadBtn from "../../../components/Utils/LoadBtn";
import Select from 'react-select';

registerLocale('es', es);

const ModalAdd = ( props ) => {

    const ls = JSON.parse(localStorage.getItem('user'));

    let nextFreeDay = new Date();
    let strikeDate = new Date();
    nextFreeDay.setDate(nextFreeDay.getDate());
    strikeDate.setDate(strikeDate.getDate() -1);

    const[loading, setLoading]                  = useState(false);
    const[selectedDate, setSelectedDate]        = useState(new Date()); 
    const[listHoursStart, setListHoursStart]    = useState([]);
    const[listUser, setListUser]                = useState([]);
    const[selectedUser, setSelectedUser]        = useState({ value: "", label: ""}); 
    const[startTime, setStartTime]              = useState("06:00");
    const[hideStart, setHideStart]              = useState(true);
    const[strikeClass, setStrikeClass]          = useState("etm_calendar_strike_text");

    const {
        items,
        day,
        month,
        year,
        prevMonth,
        nextMonth,
        selectDate
    } = useCalendar();
      
    useEffect(() => {
        getHoursList();
        getUserList();
        setStrikeClass("etm_calendar_strike_text");
    }, []);

    const getHoursList = async (e) => {
        const res = await API.get('/getHoursList');
        if(res.data.state){
            let listStart = [];
            res.data.hours.map((e, i) => {
                listStart.push(<option key={ `start_${i}` } value={ e.hour }> { e.hour } </option>);
            });
            setListHoursStart(listStart);
        }
    }

    const getUserList = async () => {
        const res = await API.get('/getUserList', { params: { user_id: ls.id } });
        if(res.data.state){
            let list = [];
            res.data.data.map((e, i) => {
                list.push({ value: e.ID, label: `${e.NOMBRE} | ${e.PERFIL}` });
            });
            setListUser(list);
        }
    }

    const handleStart = (e) => {
        let end = new Date("1970-01-01 " + e + ":00");
        end = moment(end).add(30, 'm').toDate();
        end = `${end.getHours().toString().padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`;
        setStartTime(e);
    }

    const handleModal = () => {
        props.setShowData(!props.showModal);
    }

    const changeDay = ( day ) => {
        if(getDate(day) < getDate(nextFreeDay)){
            swal_etm("Fecha inválida");
            return;
        }
        setHideStart(false);
    }

    const handleAdd = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        
        if(getDate(selectedDate) < getDate(nextFreeDay)){
            swal_etm("Fecha inválida");
            setLoading(false);
            return;
        }
        postAddHour();
    }

    const postAddHour = async () => {
        const res = await API.post("/makeMeetingAdmin", { 
            date:       getDate(selectedDate),
            startTime,
            managerId:  props.userId,
            userId:     selectedUser.value
        });
    
        if(res.data.state){
            swal_etm("Reunión creada correctamente!", "success");
            handleModal();
        }else{
            swal_etm(res.data.msg);
        }
        setLoading(false);
        
    }

    // SELECT 2 STYLE 
    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "$fff" : 0,
            borderColor: state.isFocused ? "#fff" : "#fff", "&:hover": {
                borderColor: state.isFocused ? "#fff" : "#fff"
            }
        })
    };

    const changeUser = (selectedOption) => {
        setSelectedUser({ value: selectedOption.value, label: selectedOption.label });
    };

    return (
        <Fragment>
            <Modal isOpen={ props.showModal } size="xl" centered={ true }>
                <ModalBody>
                    <Row>
                        <Col lg={12}>
                            <div className="text-right">
                                <label className="etm_modal_close pointer text-muted" onClick={ handleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></label>
                            </div>
                        </Col>
                        <Col lg={6} >
                            <div className="calendar text-center">

                                {/* Controls */}
                                <div className="controls">
                                    <div>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={ prevMonth }><i className="fas fa-angle-double-left"></i></button>
                                        <span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        <button className="btn btn-sm etm_btn etm_calendar_btn" onClick={ nextMonth }><i className="fas fa-angle-double-right"></i></button>
                                    </div>
                                </div>

                                {/* Calendar head with day names */}
                                <div className="calendar_head">
                                    {
                                        shortDayNames.map(day => (
                                            <div className="etm_calendar_day etm_calendar_head_item" key={`head-${spanishDate(day)}`}>
                                                {spanishDate(day)}
                                            </div>
                                        ))
                                    }
                                </div>

                                {/* Calendar */}
                                <div className="calendar_body">
                                {
                                    items.map(day => {
                                        const activeClass = !day.active ? "inactive" : "";
                                        let selectedClass = day.selected ? "selected" : "";
                                        const strike = (day.fullDate < strikeDate) ? strikeClass : "";
                                        return (
                                            <div
                                                className={`etm_calendar_day ${activeClass} ${selectedClass} ${strike}`}
                                                key={day.fullDate}
                                                onClick={() => {
                                                    selectDate(day.fullDate);
                                                    setSelectedDate(day.fullDate);
                                                    changeDay(day.fullDate);
                                                }}
                                            >
                                                {day.date}
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        </Col>
                        
                        <Col lg={6} className="mt-5">
                            <Row className="mt-3" hidden={ hideStart }>
                                <Col lg={12}className="mb-3">
                                    <FormGroup >
                                        <label>
                                            <b className="etm_text_blue mr-1"><i className="fas fa-calendar-alt"></i></b><span className="ml-1 mr-1">{` ${capitalize(day.name)} ${day.number} de ${spanishDate(month.name)} ${year} `}</span>
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <FormGroup >
                                        <label>
                                            <b className="etm_text_blue mr-3"><i className="fas fa-clock"></i></b> Selecciona la hora
                                        </label>
                                        <Input type="select" name="start" onChange={(e) => handleStart(e.target.value)} className="form-control etm_input_select" value={ startTime }>
                                            { listHoursStart }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={8} xs={12}>
                                    <FormGroup >
                                        <label>
                                            <b className="etm_text_blue mr-3"><i className="fas fa-user"></i></b> Selecciona con quién se reunirá
                                        </label>
                                        <Select
                                            placeholder="Selecciona un usuario"
                                            value={ (selectedUser.value === "") ? "Selecciona un usuario" : selectedUser }
                                            onChange={ changeUser }
                                            options={ listUser }
                                            className="etm_input_select"
                                            styles={ customStyles }
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={12} className="text-left mt-5">
                                    <label><b className="etm_text_blue mr-1">*</b>Cada reunión dura 20 minutos.</label>
                                    <label><b className="etm_text_blue mr-1">*</b>Todas las reuniones tienen zona horaria <b className="etm_text_blue">GMT-3</b> (Hora de Chile).</label>
                                </Col>
                                <Col lg={12} className="text-right mt-5">
                                    <LoadBtn handle={ handleAdd } loading={ loading } title="Crear reunión!" icon="plus"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={ handleModal } size="md" className="etm_btn pl-3 pr-3"><i className="fas fa-times mr-2"></i>Volver</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default withRouter(ModalAdd);