import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import gif from "../../assets/gif/particle_loading.svg";

const LoadScreen = ({ text, className }) => {

    if(text === undefined || text === null){
        text = "Cargando datos...";
    }
    
    return (
        <Fragment>
            <div className={`text-center ${className} etm_lockscreen`}>
                <img src={gif} width="150"/>
                <br />
                <br />
                <Label className="h4 etm_text_blue mt-3">{ text }</Label>
            </div>
        </Fragment>
    );
}

export default LoadScreen;
