import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Button, UncontrolledTooltip, Badge, Label, Card, CardBody } from "reactstrap";
import "../../../assets/scss/etm.scss";
import { API } from '../../../helpers/axios';

import { withNamespaces } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalChalleng from "../modals/ModalChalleng";
import ModalChallengeRequest from "../modals/ModalChallengeRequest";
import LoadScreen from "../../../components/Utils/LoadScreen";

import { getLocalStorage, swal_etm_confirm, swal_etm } from "../../../helpers/etmmeetUtils";

const TableDesafios = () => {
    const ls = getLocalStorage();

    const [loading, setLoading] = useState(false);
    const [challenges, setChallenges] = useState([]);
    const [modalType, setModalType] = useState({});

    const [modalCrateChallenges, setModalCrateChallenges] = useState(false);
    const [modalChallengeRequest, setModalChallengeRequest] = useState(false);

    useEffect(() => {
        getChallenges()
    }, []);

    useEffect(() => {
        if (!modalCrateChallenges && modalType.type !== 'detalle') {
            getChallenges()
        }
    }, [modalCrateChallenges]);

    const openModal = (type, id = 0) => {
        setModalType({ type: type, id: id })
        setModalCrateChallenges(true)
    }


    const openModalSolicitudes = (name, id) => {
        setModalType({ name: name, id: id })
        setModalChallengeRequest(true)
    }

    const deleteChallenge = async (id) => {
        await swal_etm_confirm(`Se eliminará este <span class="etm_text_blue">desafio</span> y ya no estará disponible.`).then(async (result) => {
            const res = await API.delete('/challenger/deleteChallenge', { params: { id: id } });
            if (res.data.state) {
                swal_etm(`Desafio eliminado!`, `success`);
                getChallenges()
            }
        }).catch((err) => {
            return false;
        });
    }

    const getChallenges = async () => {
        setLoading(true);
        const res = await API.get("/challenger/getChallengersByToken", { params: { token: ls.token } })
        let list = [];

        if (res.data.state) {
            res.data.challengers.map((e, i) => {
                list.push({
                    correlative:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {i + 1}
                            </Label>
                        </Fragment>
                    , foto: <img src={e.FOTO} alt={e.DESAFIO} className="avatar-md fix_img etm_sombra" key={i} />
                    , nombre:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {e.DESAFIO}
                            </Label>
                        </Fragment>
                    , industria:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {e.INDUTRIA}
                            </Label>
                        </Fragment>
                    , postulaciones:
                        <Fragment>
                            <Label className='h3 mb-0' onClick={() => openModalSolicitudes(e.DESAFIO, e.ID)} >
                                <Badge color={`${e.BADGE}`} className="pointer" >
                                    {e.SOLICITUDES}
                                </Badge>
                            </Label>
                        </Fragment>
                    , creador:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {e.USUARIO_CREACION}
                            </Label>
                        </Fragment>
                    , fechaVigencia:
                        <Fragment>
                            <Label className='h5 mb-0'>
                                {`INICIO: ${e.FECHA_INICIO}`}
                                <br />
                                {`FIN: ${e.FECHA_FIN}`}
                            </Label>
                        </Fragment>
                    , estado:
                        <Fragment>
                            <Label className='h4 mb-0'>
                                <Badge color={(e.ESTADO === 'ACTIVO') ? 'success' : 'danger'}>
                                    {e.ESTADO}
                                </Badge>
                            </Label>
                        </Fragment>
                    , opciones: <Fragment>
                        <div className="d-flex my-auto">
                            <Row>
                                <Col md={12} className="mt-1">
                                    <Button
                                        block={true}
                                        color="primary"
                                        onClick={() => openModal('detalle', e.ID)}
                                        className="p-2 ml-2"
                                        id={`btn_1_${i}`}
                                    >
                                        <i className="fas fa-check-circle fa-lg mr-2"></i> VER DETALLE
                                    </Button>
                                </Col>
                                {
                                    (e.PROPIETARIO === 1) ?
                                        <Fragment>
                                            <Col md={12} className="mt-1 mb-1">
                                                <Button
                                                    block={true}
                                                    color="success"
                                                    onClick={() => openModal('editar', e.ID)}
                                                    className="p-2 ml-2"
                                                    id={`btn_3_${i}`}
                                                >
                                                    <i className="fas fa-calendar-alt fa-lg mr-2"></i> EDITAR
                                                </Button>
                                            </Col>
                                            <Col md={12} className="mb-1" hidden={(e.SOLICITUDES !== '-') ? false : true}>
                                                <Button
                                                    block={true}
                                                    color="info"
                                                    onClick={() => openModalSolicitudes(e.DESAFIO, e.ID)}
                                                    className="p-2 ml-2"
                                                    id={`btn_3_${i}`}
                                                >
                                                    <i className="fas fa-users fa-lg mr-2"></i> POSTULACIONES
                                                </Button>
                                            </Col>
                                            <Col md={12}>
                                                <Button
                                                    block={true}
                                                    color="danger"
                                                    onClick={() => deleteChallenge(e.ID)}
                                                    className="p-2 ml-2"
                                                    id={`btn_2_${i}`}
                                                >
                                                    <i className="fas fa-times-circle fa-lg mr-2"></i> ELIMINAR
                                                </Button>
                                            </Col>
                                        </Fragment>
                                        : ''
                                }
                            </Row>
                        </div>
                    </Fragment>
                });
            });
        }
        setChallenges(list);
        setLoading(false);
    }

    const color_headers = { bg: '#51699E', text: '#FFF' };

    const columns = [{
        dataField: 'correlative'
        , text: '#'
        , width: "1%"
        , headerStyle: () => {
            return { width: '1%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'foto'
        , text: 'FOTO'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'nombre'
        , text: 'NOMBRE'
        , width: "16%"
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'industria'
        , text: 'INDUSTRIA'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'postulaciones'
        , text: 'POSTULACIONES'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'creador'
        , text: 'CREADOR'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'fechaVigencia'
        , text: 'FECHA VIGENCIA'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'estado'
        , text: 'ESTADO'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }, {
        dataField: 'opciones'
        , text: 'OPCIONES'
        , headerStyle: () => {
            return { width: '5%', textAlign: 'center', verticalAlign: 'middle' };
        }
        , style: {
            textAlign: 'center',
            verticalAlign: 'middle'
        }
        , headerClasses: 'etm_datatable_header'
    }];

    return (
        <Fragment>
            {
                (loading) ?
                    <LoadScreen text="Cargando solicitudes..." />
                    :
                    <Fragment>
                        <div className="text-right mt-0 pt-0 mb-3">
                            <Button
                                color="primary"
                                className="p-2 ml-2 btn-lg"
                                onClick={() => openModal('nuevo')}
                            >
                                <i className="fas fa-plus fa-lg mr-2"></i>AGREGAR NUEVO DESAFÍO
                            </Button>
                        </div>
                        <ToolkitProvider
                            keyField="ID"
                            data={challenges}
                            columns={columns}
                        >
                            {
                                props => (
                                    <div>
                                        <h3 className="etm_text_blue mb-4"><i className="fas fa-graduation-cap mr-2"></i>Listado de Desafíos</h3>
                                        {/* <SearchBar placeholder="Buscar..." className="etm_input_text" {...props.searchProps} /> */}
                                        <BootstrapTable
                                            keyField="ID"
                                            pagination={paginationFactory()}
                                            {...props.baseProps}
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </Fragment>

            }

            <ModalChalleng
                modal={modalCrateChallenges}
                setModal={setModalCrateChallenges}
                type={modalType}
            />

            <ModalChallengeRequest
                modal={modalChallengeRequest}
                setModal={setModalChallengeRequest}
                challenge={modalType}
                getChallenges={getChallenges}
            />
        </Fragment>
    );
}

export default withNamespaces()(TableDesafios);