import React, { Fragment, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-toastify/dist/ReactToastify.css';

const Datatable = ( { data = [], columns = [], color = "primary", showCheckbox = false, headerHandle = null, setHeaderHandle = null, rowHandle = [], setRowHandle = [] } ) => {
    const { SearchBar }             = Search;
    const[dtColumns, setDtColumns]  = useState([{ dataField: 'id', text: 'ID' }]);
    const[dtRows, setDtRows]        = useState([]);
    
    useEffect(() => {
        setColumns();
    }, [columns]);
    
    useEffect(() => {
        setData();
    }, [data]);

    const setData = () => {
        if(data.length > 0){
            let list = [];
            const headers = Object.keys(data[0]);

            for(let x of data){
                const obj = {};
                
                for(let z = 0; z < headers.length; z++){
                    if(showCheckbox){
                        if(headers[z] === "id" || headers[z] === "ID"){
                            obj["check"] =  <div className="">
                                                { 
                                                    (headerHandle) ? 
                                                        <input type="checkbox" className="etm_checkbox" name="checkboxes" id={`check_${x[headers[z]]}`} onChange={() => checkChange(x[headers[z]])} checked={ headerHandle }/>
                                                    :   <input type="checkbox" className="etm_checkbox" name="checkboxes" id={`check_${x[headers[z]]}`} onChange={() => checkChange(x[headers[z]])}/>
                                                } 
                                            </div> 
                        }
                    }
                    obj[headers[z]] = x[headers[z]];
                }
                list.push(obj);
            }
            setDtRows(list);
        }
    }

    const setColumns = () => {
        if(columns.length > 0){
            let list = [];
            let header_color = { bg: "", text: "" };
            
            switch(color){
                case "primary":
                    header_color = { bg: "#009fe3", text: "#FFF" };
                    break;
                case "secondary":
                    header_color = { bg: "#a5a5a5", text: "#FFF" };
                    break;
                case "success":
                    header_color = { bg: "#34c38f", text: "#FFF" };
                    break;
                case "warning":
                    header_color = { bg: "#f1b44c", text: "#FFF" };
                    break;
                case "danger":
                    header_color = { bg: "#f46a6a", text: "#FFF" };
                    break;
                default:
                    header_color = { bg: "#009fe3", text: "#FFF" };
                    break;
            }

            if(showCheckbox){
                list.push({
                        dataField:  'check'
                    ,   text:       <input type="checkbox" className="etm_checkbox" onChange={(e) => checkChangeAll(e.target.checked)} />
                    ,   headerStyle: () => {
                            return { backgroundColor: header_color.bg, color: header_color.text, width: '80px' };
                    }
                });
            }
            
            for(let x of columns){
                list.push({
                        dataField:  x.field
                    ,   text:       x.text
                    ,   headerStyle: () => {
                            return { backgroundColor: header_color.bg, color: header_color.text, textAlign: 'center' };
                    }
                });
            }
            setDtColumns(list);
        }
    }

    const checkChangeAll = ( e ) => {
        let elements = document.getElementsByName("checkboxes");
        if(elements.length > 0){
            for (let i = 0; i < elements.length; i++) {
                if(e){
                    elements[i].setAttribute("checked", true);
                }else{
                    elements[i].removeAttribute("checked");
                }
            }
        }
        setHeaderHandle(e);
        setRowHandle([]);
    }
    
    const checkChange = ( id ) => {
        let list = rowHandle;
        if(list.indexOf(id) < 0){
            list.push(id);
            let element = document.getElementById(`check_${id}`);
            element.setAttribute("checked", true);
        }else{
            list.splice(list.indexOf(id), 1);
            let element = document.getElementById(`check_${id}`);
            element.removeAttribute("checked");
        }
        setRowHandle(list);
    }

    return (
        <Fragment>
            <div className="etm_div_datatable table-responsive">
                <ToolkitProvider keyField="id" data={ dtRows } columns={ dtColumns } search>
                    {
                        props => (
                            <div>
                                <SearchBar placeholder="Buscar..." className="etm_input_text" { ...props.searchProps } />
                                <BootstrapTable keyField="id" pagination={ paginationFactory() } hideSizePerPage={true} { ...props.baseProps } />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        </Fragment>
    );
}

export default withNamespaces()(Datatable);