import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Container, Card, CardBody, Media, CardTitle, CustomInput } from "reactstrap";
import "../../assets/scss/etm.scss";
import { withRouter } from "react-router-dom";
import { API } from '../../helpers/axios';
import { capitalize } from "../../helpers/etmmeetUtils";
import LogoEtm from "../../assets/images/conecTime/logo_full_negro.png"
import Dashboard_general from './Dashboard_general';
import LoadScreen from '../../components/Utils/LoadScreen';

const Dashboard = ( match ) => {

    const[loading, setLoading]                      = useState(true);
    const[visualization, setVisualization]          = useState(true);
    const[visualizationText, setVisualizationText]  = useState(<h5><span className="text-muted mr-2">Datos generales</span> | <span className="etm_text_blue ml-2">Datos de empresa</span></h5>);
    const[managements, setManagements]              = useState([]);
    const[dataGeneral, setDataGeneral]              = useState([]);
    const[managementsTotal, setManagementsTotal]    = useState({
            given:      0
        ,   future:     0
        ,   confirmed:  0
        ,   average:    0
    });
    const[users, setUsers]              = useState([]);
    const[usersTotal, setUsersTotal]    = useState({
            given:      0
        ,   future:     0
        ,   confirmed:  0
        ,   average:    0
    });
    const[company, setCompany] = useState({
            name:           ""
        ,   img:            ""
        ,   managements:    0
        ,   users:          0
        ,   given:          0
        ,   meetings:       0
    });
    const etm  = {
            name:           <span>EtM<i className="etm_text_blue">meet</i></span>
        ,   img:            LogoEtm
    };
    
    useEffect(() => { 
        getDataCompanyByHash();
        getKpiSponsor();
    }, []);

    useEffect(() => { 
        setInterval(function () {
            getDataCompanyByHash();
            getKpiSponsor();
        }, 300000);
    }, []);

    const getKpiSponsor = async () => {
        const res = await API.get("/getKpiSponsor");
        if(res.data.state){
            setDataGeneral(res.data.kpi);
        }
    }

    const getDataCompanyByHash = async () => {
        setLoading(true);
        if(match.location.pathname.length > 0){
            const data  = match.location.pathname.split('/');
            const hash  = data[2];
            const res   = await API.get('/getDataCompanyByHash', { params: { hash } });
            if (res.data.state) {
                setCompany({
                        name:           capitalize(res.data.data.NOMBRE)
                    ,   img:            res.data.data.FOTO
                    ,   managements:    res.data.data.GERENCIAS
                    ,   users:          res.data.data.USUARIOS
                    ,   given:          res.data.data.DONADAS
                    ,   meetings:       res.data.data.REALIZADAS
                });
            }
        }
        getManagementActivity();
    }

    const getManagementActivity = async () => {
        if(match.location.pathname.length > 0){
            const data  = match.location.pathname.split('/');
            const hash  = data[2];
            const res   = await API.get('/getManagementActivity', { params: { hash } });
            if (res.data.state) {
                let list        = [];
                let given       = 0;
                let future      = 0;
                let confirmed   = 0;
                let average     = 0;
                let count_avg   = 0;

                res.data.data.map((e, i) => {
                    given       = given     + e.DONADAS;
                    future      = future    + e.DONADAS_FUTURAS;
                    confirmed   = confirmed + e.REALIZADAS;
                    average     = average   + e.PROMEDIO;
                    if(e.PROMEDIO > 0){
                        count_avg++;
                    }

                    list.push(
                        <tr key={ i }>
                            <td>{ i + 1}</td>
                            <td className="text-left">{ e.GERENCIA }</td>
                            <td>{ e.DONADAS }</td>
                            <td>{ e.DONADAS_FUTURAS }</td>
                            <td>{ e.REALIZADAS }</td>
                            <td>{ e.PROMEDIO }</td>
                        </tr>
                    );
                });
                if(count_avg > 0){
                    average = (average / count_avg);
                    average = Math.round(average * 10) / 10;
                }
                setManagementsTotal({
                        given
                    ,   future
                    ,   confirmed
                    ,   average
                });
                setManagements(list);
            }
        }
        getUserActivity();
    }

    const getUserActivity = async () => {
        if(match.location.pathname.length > 0){
            const data  = match.location.pathname.split('/');
            const hash  = data[2];
            const res   = await API.get('/getUserActivity', { params: { hash } });
            if (res.data.state) {
                let list        = [];
                let given       = 0;
                let future      = 0;
                let confirmed   = 0;
                let average     = 0;
                let count_avg   = 0;

                res.data.data.map((e, i) => {
                    given       = given     + e.DONADAS;
                    future      = future    + e.DONADAS_FUTURAS;
                    confirmed   = confirmed + e.REALIZADAS;
                    average     = average   + e.PROMEDIO;
                    if(e.PROMEDIO > 0){
                        count_avg++;
                    }

                    list.push(
                        <tr key={ i }>
                            <td>{ i + 1}</td>
                            <td className="text-left">{ capitalize(e.USUARIO) }</td>
                            <td className={`text-left ${ (e.GERENCIA.toUpperCase() === 'SIN GERENCIA REGISTRADA') ? 'text-danger' : '' }`}>{ e.GERENCIA }</td>
                            <td>{ e.DONADAS }</td>
                            <td>{ e.DONADAS_FUTURAS }</td>
                            <td>{ e.REALIZADAS }</td>
                            <td>{ e.PROMEDIO }</td>
                        </tr>
                    );
                });
                if(count_avg > 0){
                    average = (average / count_avg);
                    average = Math.round(average * 10) / 10;
                }
                setUsersTotal({
                        given
                    ,   future
                    ,   confirmed
                    ,   average
                });
                setUsers(list);
            }
        }
        setLoading(false);
    }
    
    const changeVisualization = () => {
        setVisualization(!visualization);
        if(!visualization === true){
            setVisualizationText(<h5><span className="text-muted mr-2">Datos generales</span> | <span className="etm_text_blue ml-2">Datos de empresa</span></h5>);
        }else{
            setVisualizationText(<h5><span className="etm_text_blue mr-2">Datos generales</span> | <span className="text-muted ml-2">Datos de empresa</span></h5>);
        }
    }

    return (
        <Fragment>
            <div className="page-content dashboard_sponsor">
                <Container fluid>
                    {
                        (loading) ?
                            <LoadScreen text={ 'Actualizando Dashboard...' }/>
                        :   <Fragment>
                                <Row>
                                    <Col xl="6">
                                        <Card className="overflow-hidden">
                                            <div className="">
                                                <Row>
                                                    <Col xl={7} lg={6} md={6} sm={12}>
                                                        <div className="text-primary text-xl-left text-lg-left text-md-left text-center pl-3 pt-5">
                                                            <h4>{ (visualization) ? company.name : etm.name }</h4>
                                                        </div>
                                                        <div className="lineBlue"></div>
                                                    </Col>
                                                    <Col xl={5} lg={6} md={6} sm={12} className="align-self-end text-center mt-xl-0 mt-lg-0 mt-md-0 mt-3">
                                                        <img src={ (visualization) ? company.img : etm.img } alt="" className="img-fluid etm_dashboard_img_sponsor" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>   
                                    </Col>
                                    <Col xl="6">
                                        <div className="text-center">
                                            <h4>Cambiar visualización</h4>
                                            { visualizationText }
                                            <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="" bsSize="xl" checked={ visualization } onChange={ () => changeVisualization()}/>
                                        </div>
                                    </Col>
                                </Row>
                                { 
                                    (visualization) ? 
                                        <Row>
                                            <Col xl="12" className="mt-xl-0 mt-lg-3 mt-md-3 mt-3">
                                                <Row>
                                                <Col xl={6} lg={6} md={12}>
                                                        <Card className="mini-stats-wid">
                                                            <CardBody>
                                                                <Media>
                                                                    <Media body>
                                                                        <h4 className="text-muted font-weight-medium text-center">Reuniones donadas a la fecha</h4>
                                                                        <h4 className="mb-0 text-center">{ company.given }</h4>
                                                                    </Media>
                                                                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                                        <span className="avatar-title etm_bg_blue">
                                                                            <i className="bx bx-calendar-event font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </Media>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={12}>
                                                        <Card className="mini-stats-wid">
                                                            <CardBody>
                                                                <Media>
                                                                    <Media body>
                                                                        <h4 className="text-muted font-weight-medium text-center">Reuniones realizadas a la fecha</h4>
                                                                        <h4 className="mb-0 text-center">{ company.meetings }</h4>
                                                                    </Media>
                                                                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                                        <span className="avatar-title bg-success">
                                                                            <i className="bx bx-calendar-check font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </Media>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={12}>
                                                        <Card className="mini-stats-wid">
                                                            <CardBody>
                                                                <Media>
                                                                    <Media body>
                                                                        <h4 className="text-muted font-weight-medium text-center">Gerencias</h4>
                                                                        <h4 className="mb-0 text-center">{ company.managements }</h4>
                                                                    </Media>
                                                                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                                        <span className="avatar-title bg-warning">
                                                                            <i className="fas fa-university font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </Media>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <Col xl={6} lg={6} md={12}>
                                                        <Card className="mini-stats-wid">
                                                            <CardBody>
                                                                <Media>
                                                                    <Media body>
                                                                        <h4 className="text-muted font-weight-medium text-center">Colaboradores</h4>
                                                                        <h4 className="mb-0 text-center">{ company.users }</h4>
                                                                    </Media>
                                                                    <div className="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                                                        <span className="avatar-title bg-danger">
                                                                            <i className="fas fa-users font-size-24"></i>
                                                                        </span>
                                                                    </div>
                                                                </Media>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl="12">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4">
                                                            <i className="fas fa-university font-size-24 mr-2 text-warning"></i>Actividad por Gerencias
                                                        </CardTitle>
                                                        <div className="table-responsive scroll_table_gerencias">
                                                            <table className="table table-centered table-striped table-nowrap table-condensed mb-0 text-center">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th className="bg-warning text-white">#</th>
                                                                        <th className="bg-warning text-white">Gerencia</th>
                                                                        <th className="bg-warning text-white">Reuniones Donadas</th>
                                                                        <th className="bg-warning text-white">Reuniones Donadas Futuras</th>
                                                                        <th className="bg-warning text-white">Reuniones Realizadas</th>
                                                                        <th className="bg-warning text-white">Evaluación Promedio (0-5)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    { managements }
                                                                    <tr>
                                                                        <th></th>
                                                                        <th className="text-left">TOTALES</th>
                                                                        <th>{ managementsTotal.given }</th>
                                                                        <th>{ managementsTotal.future }</th>
                                                                        <th>{ managementsTotal.confirmed }</th>
                                                                        <th>{ managementsTotal.average }</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4">
                                                            <i className="fas fa-users font-size-24 mr-2 text-danger"></i>Actividad por Usuarios
                                                        </CardTitle>
                                                        <div className="table-responsive scroll_table_usuarios">
                                                            <table className="table table-centered table-striped table-nowrap table-condensed mb-0 text-center">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th className="bg-danger text-white">#</th>
                                                                        <th className="bg-danger text-white">Usuario</th>
                                                                        <th className="bg-danger text-white">Gerencia</th>
                                                                        <th className="bg-danger text-white">Reuniones Donadas</th>
                                                                        <th className="bg-danger text-white">Reuniones Donadas Futuras</th>
                                                                        <th className="bg-danger text-white">Reuniones Realizadas</th>
                                                                        <th className="bg-danger text-white">Evaluación Promedio (0-5)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    { users }
                                                                    <tr>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th className="text-left">TOTALES</th>
                                                                        <th>{ usersTotal.given }</th>
                                                                        <th>{ usersTotal.future }</th>
                                                                        <th>{ usersTotal.confirmed }</th>
                                                                        <th>{ usersTotal.average }</th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    :   <Dashboard_general dataGeneral={ dataGeneral }/>
                                }
                            </Fragment>
                    }

                </Container>
            </div>
        </Fragment>
    );



}

export default withRouter(Dashboard);