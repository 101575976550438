import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { validateRol } from '../../../helpers/etmmeetUtils';

const ProfileMenu = (props) => {

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
  
    const [username, setusername] = useState("Admin");

    const ls_menu =JSON.parse(localStorage.getItem('user'));
    
    
    useEffect(() => {
        if(localStorage.getItem("authUser")) {
            if(process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.displayName);
            } else if((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt')) {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.username);
            }
        }
    },[props.success]);

  return (
    <React.Fragment>
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                <img className="rounded-circle header-profile-user fix_img" src={ ls_menu.profile_picture } alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ml-2 mr-1">{ ls_menu.name } { ls_menu.last_name}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem tag="a" href="/home"> <i className="fas fa-home font-size-16 align-middle mr-2 text-info"></i>{props.t('Homeasdas')}  </DropdownItem>
                <DropdownItem tag="a" href="/profile"> <i className="fas fa-user font-size-16 align-middle mr-2 text-primary"></i>{props.t('Mi Perfil')}  </DropdownItem>
                <DropdownItem tag="a" href="/password"> <i className="fas fa-key font-size-16 align-middle mr-2 text-secondary"></i>{props.t('Cambiar Contraseña')}  </DropdownItem>
                <div className="dropdown-divider"></div>

                { 
                    (validateRol(['EMPRENDEDOR', 'ADMINISTRADOR'])) ?   <DropdownItem tag="a" href="/startups"> <i className="fas fa-suitcase font-size-16 align-middle mr-2 text-info"></i>{props.t('Emprendimientos')}  </DropdownItem> : ""
                }
                
                {
                    (validateRol(['INVERSIONISTA', 'MENTOR', 'GERENTE/EJECUTIVO', 'ADMINISTRADOR'])) ?   <DropdownItem tag="a" href="/giveHours"> <i className="fas fa-handshake font-size-16 align-middle mr-2 text-warning"></i>{props.t('Donar Tiempo')}  </DropdownItem> : ""
                } 
                
                <DropdownItem tag="a" href="/inbox"> <i className="fas fa-inbox font-size-16 align-middle mr-2 text-success"></i>{props.t('Mis Solicitudes')}  </DropdownItem>
                {/* <DropdownItem tag="a" href="/meet"> <i className="fas fa-user-friends font-size-16 align-middle mr-2 text-primary"></i>{props.t('Meet')}  </DropdownItem> */}
                <div className="dropdown-divider"></div>
                <Link to="/logout" className="dropdown-item">
                    <i className="fas fa-power-off font-size-16 align-middle mr-2 text-danger"></i>
                    <span>{props.t('Cerrar Sesión')}</span>
                </Link>
            </DropdownMenu>
        </Dropdown>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
    const { error,success } = state.Profile;
    return { error,success };
}

export default withRouter(connect(mapStatetoProps, {  })(withNamespaces()(ProfileMenu)));

