import React, { useState, useEffect }from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Input } from "reactstrap";
import { API } from '../../helpers/axios';
import swal from 'sweetalert';
import { validateRol, capitalize, swal_etm } from '../../helpers/etmmeetUtils';
import LoadBtn from "../../components/Utils/LoadBtn";

const ModalDonar = ( props ) => {
    
    const[motivo, setMotivo]    = useState("");
    const[loading, setLoading]  = useState(false);
    
    useEffect(() => {
        setMotivo("");
    }, [props.manager_id]);

    const solicitar = () => {
        setLoading(true);
        if(motivo.length < 10){
            swal_etm("El motivo que ingreses debe contar al menos con 10 caracteres");
            setLoading(false);
            return false;
        }
        postSolicitar();
    };

    const postSolicitar = async() => {
        const res = await API.post('/search/makeRequestGiveTime', { token: props.token, manager_token: props.dataManager.token, motivo });
        if(res.data.state){
            swal_etm(`Te notificaremos por correo apenas <b>${capitalize(props.dataManager.shortname)}</b> done tiempo.`, 'success', 'Solicitud realizada correctamente!');
            setLoading(false);
            props.toggleModal();
        }else{
            swal_etm(`Ya solicitaste tiempo a este usuario en menos de <b>7 días</b>.<br>Podrás volver a solicitarle la próxima semana.`);
            setLoading(false);
            props.toggleModal();
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={ props.modal } size={ 'lg' } scrollable={ true } toggle={ props.toggleModal }>
                <ModalBody>
                    <Row>
                        <Col>
                            <h5>SOLICITA QUE DONE TIEMPO</h5>
                        </Col>
                        <Col className="text-right">
                            <h5 className="etm_modal_close pointer text-muted" onClick={ props.toggleModal }>Cerrar <i className="far fa-times-circle fa-1x"></i></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <FormGroup className="mt-4">
                                <h5 className="mb-2 text-muted">Cuéntanos, ¿cuál es el motivo por el cuál quieres reunirte con <b className="etm_text_blue">{ capitalize(props.dataManager.name) }</b>?</h5>
                                <Input type="textarea" name="motivo" rows="5" onChange={ (e) => setMotivo(e.target.value) } placeholder={ `Describe por qué te gustaría poder juntarte con ${capitalize(props.dataManager.shortname)}` } maxLength="300" className="etm_textarea"></Input>
                            </FormGroup>
                            <FormGroup className="mt-3">
                                <label className="text-muted"><b className="etm_text_blue mr-2">*</b>Podrás solicitarle tiempo a { capitalize(props.dataManager.shortname) } 1 vez por semana y serás notificado por correo la próxima vez que done tiempo.</label>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="modal_solicitar_footer">
                    <Button color="danger" onClick={ props.toggleModal } className="etm_btn "><i className="fas fa-times"></i> Cancelar</Button>
                    <LoadBtn handle={ solicitar } loading={ loading } title="Enviar Solcitud" icon="paper-plane"></LoadBtn>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default ModalDonar;