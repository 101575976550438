import React, { useState, Fragment } from 'react';
import { Row, Col } from "reactstrap";
import LogoEmprendedor from "../../assets/images/etmmeet/landing/inversionista.png";
import LogoTDD from "../../assets/images/etmmeet/landing/mentor.png";
import { Link } from 'react-router-dom';
import { isMobile } from '../../helpers/etmmeetUtils';

const HeaderProfile = (props) => {
    const[showStartup, setShowStartup]  = useState(false);
    const[showTdd, setShowTdd]          = useState(false);

    return (
        <Fragment>
            <Row className="profile pt-2 pb-3">
                <Col xs={12} className="text-center">
                    <p className="h1 text-white pt-2 pb-2 ml-md-0 ml-3 mr-md-0 mr-3">Elige tu perfil para saber más</p>
                </Col>
                <Col md={6} xs={12} className="text-center profile_border_right" onClick={() => setShowStartup(!showStartup)} onMouseEnter={ (isMobile()) ? false : () => setShowStartup(true)} onMouseLeave={() => setShowStartup(false)}>
                    {
                        (showStartup) ?
                            <Fragment>
                                <p className="h5 text-white mb-2 profile_transparent animate__animated animate__fadeOutLeft">Emprendedor/a</p>
                                <p className="h4 text-white mb-2 profile_text_onimage animate__animated animate__fadeIn">
                                    <b>Emprendedor/a:</b><br/>Si tienes un emprendimiento aprovecha y reúnete gratis con expertos/as que quieren conectarse con emprendedores/as como tú. Encuentra oportunidades de negocio, de mentorías o de levantamiento de capital
                                </p>
                                <div className="pt-3">
                                    <Link to="/landing_emprendedores"><button className="btn pl-3 pr-3 etm_btn profile_btn_onimage animate__animated animate__fadeIn">Ver más</button></Link>
                                </div>
                                <img className="profile_img profile_transparent img-fluid animate__animated animate__fadeOutLeft" src={ LogoEmprendedor } alt="etmmeet"/>
                            </Fragment>
                        :   <Fragment>
                                <p className="h4 text-white mb-2 animate__animated animate__fadeInLeft">Emprendedor/a</p>
                                <img className="profile_img img-fluid animate__animated animate__fadeInLeft pb-3" src={ LogoEmprendedor } alt="etmmeet"/>
                                {/* <p className="h5 text-white mb-2"><u>Solicita reuniones</u></p> */}
                            </Fragment>
                    }
                </Col>
                <Col md={6} xs={12} className="text-center profile_border_right" onClick={() => setShowTdd(!showTdd)} onMouseEnter={  (isMobile()) ? false : () => setShowTdd(true)} onMouseLeave={() => setShowTdd(false)}>
                    {
                        (showTdd) ?
                            <Fragment>
                                <p className="h5 text-white mb-2 profile_transparent animate__animated animate__fadeOutRight">Mentor/a, Gerenta/e, Ejecutiva/o, Inversionista</p>
                                <p className="h4 text-white mb-2 profile_text_onimage animate__animated animate__fadeIn">
                                    <b>Mentor/a, Gerenta/e, Ejecutiva/o, Inversionista:</b><br/>Ten reuniones de 20 minutos 1 a 1 con emprendedores/as innovadores/as, previamente seleccionados, que quieren reunirse contigo. Podrás encontrar nuevos productos y/o servicios para tu empresa, encontrar tu nueva gran inversión o ser la ayuda que un emprendedor necesita.
                                </p>
                                <div className="pt-5">
                                    <Link to="/landing_expertos" ><button className="btn pl-3 etm_btn pr-3 profile_btn_onimage animate__animated animate__fadeIn">Ver más</button></Link>
                                </div>
                                <img className="profile_img profile_transparent img-fluid animate__animated animate__fadeOutRight" src={ LogoTDD } alt="etmmeet"/>
                            </Fragment>
                        :   <Fragment>
                                <p className="h4 text-white mb-2 animate__animated animate__fadeInRight">Mentor/a, Gerenta/e, Ejecutiva/o, Inversionista</p>
                                <img className="profile_img img-fluid animate__animated animate__fadeInRight pb-3" src={ LogoTDD } alt="etmmeet"/>
                                {/* <p className="h5 text-white mb-2"><u>Dona tiempo</u></p> */}
                            </Fragment>
                    }
                </Col>
            </Row>
        </Fragment>
    );
}

export default (HeaderProfile);


